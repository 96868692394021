import React from 'react'
import PFlogo from "../../../images/PF_logo.png";
import Grid from "@mui/material/Grid";
import "../../styles/Report/Report.css";

function Index() {
  return (
    <div>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6} sx={{ position: 'absolute', left: '50%', top: '20%',transform: 'translate(-50%, -50%)'}}>
           <img 
              src={PFlogo} alt="PFlogo"
              height={350}
              width={320}
              align="center"
            />
          </Grid>
          
            <Grid item xs={12} md={6} sx={{ position: 'absolute', left: '50%', top: '35%',transform: 'translate(-50%, -50%)'}}>
           {/* <h4 align="center">FORM PF</h4> */}
            <h6 align="center">Reporting Form for Investment Advisers to<br/> 
            Private Funds and Certain Commodity Pool <br/> 
            Operators and Commodity Trading Advisors </h6>
            </Grid>
        </Grid> 
    </div>
  )
}

export default Index