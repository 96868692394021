import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

function Delegation(props) {
  const [isloading, setisloading] = useState(false);
  const [headerTable, setheaderTable] = useState();
  const [contextual, setcontextual] = useState();

  // get table heading data
  const CreateHeader = () => {
    const column = Object.keys(headerTable[0]);
    const newcolumn = column.map((data) => {
      if (data !== "id" && data !== "isNew") {
        return <TableCell key={data}>{data}</TableCell>;
      }
      return null;
    });

   
    return newcolumn;
  };

  // get table row data
  const tdData = () => {
    return headerTable.map((data,index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(headerTable[0]).map((x,index) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell key={index} component="th" scope="row">
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
    setcontextual(Object.values(props.data.reportData["GRI 2.7 Employees"])[0].note);
    // setcontextual((Object.values(props.data.reportData[" Employee "])[2]).note);
    setheaderTable(
      Object.values(props.data.reportData["GRI 2.7 Employees"])[3].answer
    );
    setisloading(true);

    //CreateHeader();
  }, [isloading,props]);

  return (
    <div>
      {isloading && (
        <>
         <Box  className="EmpBox">
         <Grid container spacing={2}>
        <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={10}  className="grid1">
          <h4><b>
            Senior executives with responsibility for delegation of
            responsibility for managing impacts
            </b>
          </h4>
          <div dangerouslySetInnerHTML={{ __html: contextual }}></div>
          {/* </Grid>
          <Grid item xs={12} md={5}  className="grid1"> */}
          <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 650, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable && CreateHeader()}
                  </TableRow>
                </TableHead>

                {headerTable && tdData()}
              </Table>
            </TableContainer>
          </div>
          </Grid>
          </Grid>
          </Box>
        </>
      )}
    </div>
  );
}

export default Delegation;
