import React, { useEffect, useState, useContext } from "react";
import {
    Grid,
    Typography,
    Button,
    TextField,

} from "@mui/material";
import UploadIcon from "images/UploadIcon.png";
import deleteIcon from "images/DeleteIcon.png";
import FileIcon from "images/FileIcon.png";
import withSnackbar from "../../components/Alert/withSnackbar";
import DownloadIcon from "@mui/icons-material/Download";
import NoteContext from "context/PageContext";
const {
    getCaseAttachments,
    downloadFile,
  } = require("../services/CaseFetch");
  
//attachments
const Evidences = ({ setAIfile,base64FileList, setbase64FileList, setNewRowData, showHeading,show,caseid,showSnackbar }) => {
    const ContextData = useContext(NoteContext);
     const [loading, setLoading] = useState(false);
    const [attachments,setAttachments]=useState([]);
    const fetchEvidences = async (caseid) => {
        
        setLoading(true)
        try {
          const [attachments] = await Promise.all([
            getCaseAttachments(caseid),
           
    
          ]);

          const attachment = typeof attachments?.data?.attachments === "string" ? JSON.parse(attachments?.data?.attachments) : attachments?.data?.attachments;
          if (attachment?.length > 0) {
            const processedAttachments = await processAttachments(attachment);
            setAttachments(processedAttachments);
            setAIfile(processedAttachments)
          }
    
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error if needed
        }
      };
    
      useEffect(() => {
    
        if (ContextData?.UserSelected) {
 
            fetchEvidences(caseid);
      
    
        }
      }, [ContextData?.UserSelected]);
      const processAttachments = async (attachments) => {
        const processedAttachments = [];
    
        for (let i = 0; i < attachments.length; i++) {
          try {
            const fileData = await downloadFile(attachments[i].url);
           // const base64 =  base64ToFile(fileData.data.data, attachments[i].FileName, attachments[i].url.split('/')[4].split('.')[1]);
            processedAttachments.push({attachment_base64:attachments[i].prefix+","+fileData.data.base64,file_name: attachments[i].filename,attachment_extension:'application/'+attachments[i].filename.split('.')[1]});
          
          } catch (error) {
            // console.error('Error processing attachment:', error);
          }
        }
    
        return processedAttachments;
      };
    const handleAddAttachment = (event) => {
        const newFiles = [...base64FileList];

        for (const file of event.target.files) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const base64String = e.target.result;
                newFiles.push({
                    file_name: file.name,
                    attachment_extension: file.name.split(".")[1],
                    attachment_base64: base64String,
                });
                //newFiles.push({ file,base64String });
                setbase64FileList(newFiles);
                if (setNewRowData != false) {
                    setNewRowData(prevData => ({
                        ...prevData,
                        sub_attachments: newFiles
                    }));
                };
            }
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...base64FileList];
        newFiles.splice(index, 1);
        setbase64FileList(newFiles);
        document.getElementById("evidenceInput").value = "";
        if (setNewRowData != false) {
        setNewRowData(prevData => ({
            ...prevData,
            sub_attachments: newFiles
        }));
    }
    };
    const downloadFileOnClick = (file) => {
  
        const link = document.createElement('a');
        link.href = file.attachment_base64; 
        link.download = file.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return (
        <>
            <Grid style={{marginTop:'10px'}}>
                {showHeading && <Typography className="checklist">Evidences</Typography>}
               { attachments?.length<=0 && <>{showHeading && <Typography className="subtypes">No Evidences</Typography>}</>}
                <Grid className="file-container">
                {attachments?.map((file, index) => (
                  <Grid key={index} className="file-card" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid className="file-info">
                      <img src={FileIcon} alt="File Icon" className="file-icon" />
                      <span className="file-name">{file.file_name}</span>
                    </Grid>
                    
                    <Button
                      onClick={() => {
                        downloadFileOnClick(file);
                      }}
                    >
                      <DownloadIcon/>
                    </Button>
                  </Grid>
                ))}
                
                    {base64FileList.map((file, index) => (
                        <Grid
                            key={index}
                            className="file-card"
                            style={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <Grid className="file-info">
                                <img
                                    src={FileIcon}
                                    alt="File Icon"
                                    className="file-icon"
                                />
                                <span className="file-name">{file.file_name}</span>
                            </Grid>
                            <Button
                                onClick={handleRemoveFile}
                            >
                                <img src={deleteIcon} />
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            {show &&    <Grid spacing={2} style={{ paddingTop: "10px",marginBottom:"15px" }}>
                    <Grid
                    //className="dotted-border-box"
                    >
                        <Grid
                            className="dotted-border"
                            onClick={() =>
                                document.getElementById("evidenceInput").click()
                            }
                        >
                            <TextField
                                type="file"
                                id="evidenceInput"
                                style={{ display: "none" }}
                                onChange={handleAddAttachment}
                                size="small"
                            />
                            <img
                                src={UploadIcon}
                                alt="Uploaded"
                                style={{ maxWidth: "100%" }}
                            />
                            <Typography className="fileuploadtext">
                                Click Here To Upload Your File
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
}  
            </Grid>
        </>
    )
}


export default withSnackbar(Evidences, "Evidences", "success");