import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TuneIcon from "@mui/icons-material/Tune";
import { ListItemButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="right"
    TransitionComponent={Zoom}
    {...props}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const menuItems = [
  { label: "Home", icon: LogoutIcon, path: "/" },
  // { label: "ESG", icon: EnergySavingsLeafIcon, path: "/" },
  // { label: "Compliance", icon: AssignmentIcon, path: "/caseManagement" },
  // // {
  // //   label: "Trade-Reporting",
  // //   icon: SignalCellularAltIcon,
  // //   path: "/RealTimeData",
  // // },
  // { label: "Utilities", icon: TuneIcon, path: "/NewCreatedTemplate" },
  // { label: "Administration", icon: ManageAccountsIcon, path: "/AdminPanel" },
  // { label: "Insurance", icon: MilitaryTechIcon, path: "/Insurance" },
];

const DrawerMenu = ({ drawerWidth, setMobileOpen, open }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(menuItems.findIndex(item => item.path === location.pathname));

  const handleListItemClick = (index, path) => {
    setSelectedIndex(index);
    navigate(path);
    setMobileOpen(false);
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: { xs: "none", sm: "block" }, // Hide on xs screens
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#25A392",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar variant="dense" />
        <List disablePadding sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
          {menuItems.map((item, index) => (
            <BootstrapTooltip title={item.label} key={index}>
              <ListItem
                disableGutters
                disablePadding
                key={index}
                className={selectedIndex === index ? "selectedIcon" : ""}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '72px' }}
              >
                <ListItemButton
                  onClick={() => handleListItemClick(index, item.path)}
                  disableTouchRipple
                  disableGutters
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <ListItemIcon sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <item.icon className={selectedIndex === index ? "selectedIcon" : "Icons"} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </BootstrapTooltip>
          ))}
        </List>
      </Drawer>

      <Drawer
        variant="temporary"
        open={open}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        <Toolbar variant="dense" />
        <Divider />
        <List disablePadding>
          {menuItems.map((item, index) => (
            <ListItem
              disablePadding
              key={index}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '72px' }}
            >
              <ListItemButton
                onClick={() => handleListItemClick(index, item.path)}
                disableTouchRipple
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <ListItemIcon sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.label} sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "16px",
                    color: "#58646E",
                  },
                }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
