import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import IndexPage from "ESG/Report/PF/IndexPage";
import "../styles/Report/Report.css";
import { PFReportData } from "../services/ReportFetch";
import SecondPage from "../Report/PF/SecondPage";
import ThirdPage from "../Report/PF/ThirdPage";
import FourthPage from "../Report/PF/FourthPage";
import FifthPage from "../Report/PF/FifthPage";
import SixthPage from "ESG/Report/PF/SixthPage";
import SeventhPage from "ESG/Report/PF/SeventhPage";
import EighthPage from "../Report/PF/EighthPage";
import NinthPage from "ESG/Report/PF/NinthPage";
import TenthPage from "ESG/Report/PF/TenthPage";
import EleventhPage from "../Report/PF/EleventhPage";
import TwelvthPage from "../Report/PF/TwelvthPage";
import ThirteenthPage from "ESG/Report/PF/ThirteenthPage";
import FourteenthPage from "ESG/Report/PF/FourteenthPage";
import FifteenthPage from "ESG/Report/PF/FifteenthPage";
import SixteenthPage from "../Report/PF/SixteenthPage";
import SeventeenthPage from "../Report/PF/SeventeenthPage";
import EighteenthPage from "../Report/PF/EighteenthPage";

const OpenPage = (props) => {
  const { children, page, index } = props;
  return (
    <div hidden={page !== index}>{page === index && <Box>{children}</Box>}</div>
  );
};
const Paginations = () => {
  const [ReportDataset,setReportDataset]=useState();
  const [activePage, setActivePage] = useState(1);
  const [Isloading,setIsloading]=useState(false);
 // const [PFReportDataset,setPFReportDataset]=useState();



  useEffect(()=>{
    PFReportData().then((x)=>{
      setReportDataset(x.data);
      setIsloading(true);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
    {Isloading ? (
      <div style={{marginTop:"65px"}}>
        <Box className="PageBox">
        <OpenPage page={activePage} defaultPage index={1}>
          <IndexPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={2}>
          <SecondPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={3}>
          <ThirdPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={4}>
          <FourthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={5}>
          <FifthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={6}>
          <SixthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={7}>
          <SeventhPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={8}>
          <EighthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={9}>
          <NinthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={10}>
          <TenthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={11}>
          <EleventhPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={12}>
          <TwelvthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={13}>
          <ThirteenthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={14}>
          <FourteenthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={15}>
          <FifteenthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={16}>
          <SixteenthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={17}>
          <SeventeenthPage  data={ReportDataset}/> 
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={18}>
          <EighteenthPage  data={ReportDataset}/> 
        </OpenPage>
        </Box>
          <Pagination
            count={18}
            page={activePage}
            onChange={(event, newPage) => setActivePage(newPage)}
            size="medium"
            color="secondary"
            variant="outlined"
          />
        
      </div>
      ) : (
         <div className="loaders">Loading...</div>
        
    )}
</>
  );
};
export default Paginations;
