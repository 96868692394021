import { useState, useEffect,useContext } from "react";
import NoteContext from "context/PageContext";
import {INSURANCE,ESG,KYC,USERNAME} from "Insurance/CaseManagement/Config.js"

const useTemplateType = () => {
  const contextData = useContext(NoteContext);
  const userData = JSON.parse(localStorage.getItem("AccountDetails"));
  const email = (userData?.endUser?.mail)?.split(".")[0].toLowerCase();
  const [templateType, setTemplateType] = useState(email === USERNAME ? INSURANCE : ESG);
  const [caseType, setCaseType] = useState(email === USERNAME ? INSURANCE : KYC);

  useEffect(() => {
    const emailPrefix = (userData?.endUser?.mail)?.split(".")[0].toLowerCase() === USERNAME;
    setTemplateType(emailPrefix ? INSURANCE : ESG);
    setCaseType(emailPrefix ? INSURANCE : KYC);

  }, [userData, contextData.UserSelected]);
  return {templateType,caseType};
};

export default useTemplateType;
