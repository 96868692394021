import React from "react";
import "../ESG/styles/Tree/Tree.css";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Box, Divider, ListItem } from "@mui/material";

function findPath(obj, target) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === target) {
        return [key]; // found the target, return its name
      } else if (typeof obj[key] === "object") {
        var result = findPath(obj[key], target); // recursive call
        if (result) {
          return [key].concat(result); // prepend current key to result
        }
      }
    }
  }
  return null; // target not found
}

const Tree = ({ state,
  selected,
  handleSelect,
  selectHandler,
  SetTreeVisibility,
  isMobile,
  ExpandedNodes,
  setExpandedNodes}) => {

  const arrdata =state && Object.entries(state);
  const data = state && arrdata[0][1].value;

  //on clicking a node which has childrens
  const onNodeToggle =(node) => {
    const path = findPath(data, node);
    setExpandedNodes((prevExpandedNodes) => {
      if (prevExpandedNodes.includes(node)) {
        return prevExpandedNodes.filter((i) => i !== node);
      } else {
        return path.filter((value, index) => !(index % 2));
      }
    });
  };

  // Function to handle changes to selected items
  const clickHandler = (id, haschildren,hasQuestion) => {
    onNodeToggle(id);
    selectHandler(id,hasQuestion);
    hasQuestion && isMobile && SetTreeVisibility();
    handleSelect(id);
  };

 const calculateSubsections = (listData) => {
  let subsectionsCount = 0;

  Object.entries(listData).forEach((item) => {
    const hasDirectSubsections = Object.keys(item[1]).some((key) => key !== 'key');
    
    if (hasDirectSubsections) {
      subsectionsCount += 1;
    }
  });

  return subsectionsCount;
};
//// Recursive function to render nested lists subsection and question
  const renderCollapse = (listData,Selected,id) => {
    // console.log(listData,Selected,id,"================")
    return (
      <Collapse in={Selected} timeout="auto" unmountOnExit  sx={{marginBottom:Selected && '10px'}}
      >
        <List component="div" sx={{px:1,backgroundColor:id===ExpandedNodes[ExpandedNodes.length-2]?'#E9F6F4':'white',
       borderLeft:id===ExpandedNodes[ExpandedNodes.length-2]?'3px solid #9BD5CD':'none',borderRadius:'0px 0px 6px 6px'
      }} 
        >
        {Object.entries(listData).map((item) => {
          const hasSubList = Object.keys(item[1].value).length !== 0;
          const isSelected = ExpandedNodes.includes(item[1].key.id);
          return (
            <React.Fragment key={item[1].key.id}>
               <ListItem key={item[1].key.id} disablePadding sx={{backgroundColor:item[1].key.id===ExpandedNodes[ExpandedNodes.length-2] ?'#E9F6F4':'',borderLeft:item[1].key.id===ExpandedNodes[ExpandedNodes.length-2] && item[1].key.id!==selected?'3px solid #9BD5CD':'none',borderRadius:'6px 6px 0px 0px' }}
               > 
              <ListItemButton 
                 disableTouchRipple
                // divider
                onClick={() => clickHandler(item[1].key.id, hasSubList,item[1].key.properties.hasOwnProperty('Question'))}
                selected={selected === item[1].key.id}
                className={'ListItem'}
              >
                <ListItemText
                  sx={{
                    ".MuiListItemText-primary": {
                      fontSize: "14px",
                      color: "#58646E",
                    },
                  }}
                  primary={
                    hasSubList
                      ? item[1].key.properties.name[0].value
                      : item[1].key.id +
                        " " +
                        (item[1].key.properties.name[0].value === "undefined" ? "" :item[1].key.properties.name[0].value)
                  }
                />
              </ListItemButton>
              </ListItem>
              {hasSubList && (
                renderCollapse(item[1].value,isSelected,item[1].key.id)
              )}
            </React.Fragment>
          );
        })}
      </List>
      </Collapse>
    );
  };


  // Recursive function to render nested lists
  const renderNestedList = (listData) => {
    return (
      <List sx={{marginRight:'5px'}}>
        {Object.entries(listData).map((item) => {
          const hasSubList = Object.keys(item[1].value).length !== 0;
          const isSelected = ExpandedNodes.includes(item[1].key.id);
          const subsectionsCount = hasSubList ? calculateSubsections(item[1].value) : 0;
          return (
            <React.Fragment key={item[1].key.id}>
              <ListItem
                key={item[1].key.id}
                sx={{
                  backgroundColor: "white",
                  marginBottom: !isSelected && '10px',
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 5px 7px #0000000A",
                  borderRadius:isSelected? "6px 6px 0px 0px": "6px",
                  opacity: 1,
                }}
                disablePadding
              >
                <ListItemButton
                  disableTouchRipple
                  onClick={() => clickHandler(item[1].key.id, hasSubList,item[1].key.properties.hasOwnProperty('Question'))}
                  className={'ListItem1'}
                >
                  <ListItemText
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "16px",
                        color: "#25A392",
                      },
                      ".MuiListItemText-secondary": {
                        color: "#B2B7BC",
                        fontSize: "14px",
                      },
                    }}
                    primary={
                      hasSubList
                        ? item[1].key.properties.name[0].value
                        : item[1].key.id +
                          " " +
                          item[1].key.properties.name[0].value 
                    }
                    secondary={`${subsectionsCount} Subsections`}
                  />
                </ListItemButton>
              </ListItem>
              { isSelected && <Divider/>}
              {hasSubList && renderCollapse(item[1].value, isSelected)}
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  return <Box className="ListContainer">{data && renderNestedList(data)}</Box>;
};

export default Tree;
