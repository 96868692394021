import React from "react";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "../styles/Forms_Styles/Form_PeriodicDate.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

export default function Form_Clock({ onChange,getAnswerDisable }) {
  const {Answer}=useContext(NoteContext);
  const data=typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : {}

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker", "TimePicker"]}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TimePicker
              label="Start Time"
              value={dayjs(data.startTime)}
              onChange={(newValue) => onChange({ startTime: newValue, endTime: data.endTime })}
              disabled={getAnswerDisable}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimePicker
              label="End Time"
              value={dayjs(data.endTime)}
              onChange={(newValue) => onChange({ startTime: data.startTime, endTime: newValue })}
              disabled={getAnswerDisable}
            />
          </Grid>
        </Grid>
      </DemoContainer>
    </LocalizationProvider>
  );
}
