import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function SeventhPage(props) {
  const [Questions, SetQuestions] = useState();
  const [Isloading, setIsloading] = useState(false);
  //const [Question1,setQuestion1]=useState();
  const [Answer, setAnswer] = useState();
  const [QuestionNum, setQuestionNumber] = useState();

  useEffect(() => {
    const FetchData = () => {
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber = [];
      //getting section 1b question in single array

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Small Businesses"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Small Businesses"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Small Businesses"])[i].answer
        );
        QuestionNumber.push(
          Object.values(props.data.reportData["Small Businesses"])[
            i
          ].qno.substr(
            4,
            Object.values(props.data.reportData["Small Businesses"])[i].qno
              .length
          )
        );
      }
      // console.log(questions_array_b);
      SetQuestions(questions_array_b);
      setAnswer(Answer_array_b);
      setQuestionNumber(QuestionNumber);
    };
    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <ul
                style={{
                  listStyleType: "square",
                  textAlign: "left",
                  fontSize: "23px",
                }}
              >
                <li>Small Business</li>
              </ul>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                <div>
                  {Questions.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {Answer[index]}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default SeventhPage;
