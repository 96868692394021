import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


function GovReport(props) {
  
  const [structure, setstructure] = useState()
  const [committees,setcommittees]=useState()
  const [pageloaded, setpageloaded] = useState(false);

  useEffect (()=>{ 
    setstructure((Object.values(props.data.reportData["GRI 2.9 Governance structure and composition"])[0]).answer)
    setcommittees((Object.values(props.data.reportData["GRI 2.9 Governance structure and composition"])[1]).answer)
    setpageloaded(true);
},[pageloaded,props])

  return (
    <div>
      {pageloaded ? (
        <>
        <Box className="EmpBox">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <h4>Apple.Inc </h4>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
              <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={5} className="grid1">
              <h3 className="hName">
                <u>Corporate governance</u>
              </h3>

              <h5 className="subhName">
                <u>Structure of highest governance body</u>
              </h5>
                <div dangerouslySetInnerHTML={{__html:structure}}></div> 
            </Grid>

            <Grid item xs={12} md={5} className="grid2">

            <h5 className="subhName">
                <u>Committees of highest governance body</u>
              </h5>
              <h6 dangerouslySetInnerHTML={{__html:committees}}></h6>
            </Grid>
          </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
  );
}

export default GovReport;
