import React, { useState, useEffect, useRef,useContext } from 'react';
import { Fab, TextField,InputAdornment,Box, List, ListItem, ListItemText, AppBar, Toolbar, IconButton, Typography, Paper, InputBase,TextareaAutosize } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { caseGenAIResponse } from "Compliance/services/CaseFetch.js";
import CircularProgress from '@mui/material/CircularProgress';
import {remark} from 'remark';
import html from 'remark-html';
import NoteContext from "context/PageContext";
import './ChatButton.css';
import { styled } from '@mui/system';
const processMarkdown = async (markdown) => {
    const result = await remark().use(html).process(markdown);
    return (result.toString());
  };
  const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '15px',
      paddingRight: 0,
      '& fieldset': {
        borderColor: '#e0e0e0',
      },
      '&:hover fieldset': {
        borderColor: '#bdbdbd',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9e9e9e',
      },
      '& .MuiInputAdornment-root': {
        alignSelf: 'flex-end',
      },
      '& .MuiIconButton-root': {
        marginBottom: '8px', // Adjust the margin as needed
      },
    },
  }));
const ChatAccordian = () => {
    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const messageEndRef = useRef(null);
    const userDetails = JSON.parse(localStorage.getItem("AccountDetails"));
    const auth = userDetails?.endUser;
    const ContextData = useContext(NoteContext);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    const fetchApiResponse = async (message) => {
      const obj = {
        input: message,
        session_id: auth?.id,
        domain:ContextData?.UserSelected
      };
      const res = await caseGenAIResponse(obj);
      return res?.data?.values;
    };
  
    const handleSend = async () => {
      if (input.trim()) {
        const newMessage = { text: input, sender: 'user' };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setInput('');
        setLoading(true);
        try {
          const apiResponse = await fetchApiResponse(input);
          const result=await processMarkdown(apiResponse?.output)
          const responseMessage = { text: result, sender: 'api' };
          setMessages((prevMessages) => [...prevMessages, responseMessage]);
        } finally {
          setLoading(false);
        }
      }
    };
  
    useEffect(() => {
      // Scroll to the bottom when messages change
      if (messageEndRef.current) {
        messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [messages]);
  
    useEffect(() => {
      // Scroll to the bottom when the chat interface opens
      if (open) {
        setTimeout(() => {
          if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100); // Small delay to ensure the messages are rendered
      }
    }, [open]);
  return(
    <>
    
<Box style={{background:'#fff'}}>
      
          <AppBar position="static">
            <Toolbar variant="dense">
               <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                Co Assist
              </Typography>
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton> 
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              flexGrow: 1,
              maxHeight: 'calc(100% - 60px)',
              overflowY: 'auto',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              wordBreak: 'break-word',
            }}
          >
            <List>
              {messages?.map((msg, index) => (
                <ListItem
                  key={index}
                  sx={{
                    justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                    display: 'flex',
                  }}
                >
                  <Paper
                    sx={{
                      p: 1,
                      bgcolor: msg.sender === 'user' ? '#cfe9ff' : '#e1f5fe',
                      maxWidth: '70%',
                      alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                    }}
                  >
                    {msg.sender === 'api' ? (
                      <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                    ) : (
                      <ListItemText primary={msg.text} />
                    )}
                  </Paper>
                </ListItem>
              ))}
              {loading && (
                <ListItem
                  sx={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                  }}
                >
                  <Paper
                    sx={{
                      p: 1,
                      bgcolor: '#e1f5fe',
                      maxWidth: '70%',
                      alignSelf: 'flex-start',
                    }}
                  >
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Paper>
                </ListItem>
              )}
              {/* Scroll anchor to make sure we scroll to the bottom */}
              {/* <div ref={messageEndRef} /> */}
            </List>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              borderTop: '1px solid #ddd',
            }}
          >
            {/* {!loading && (
              <Paper
                component="form"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  padding: '2px 4px',
                  boxShadow: 'none',
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSend();
                }}
              >
                <TextareaAutosize
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Type your message..."
                  value={input}
                  
                  onChange={(e) => setInput(e.target.value)}
                  disabled={loading}
                  style={{
                    width: '100%',
                    border: 'none',
                    resize: 'none',
                    outline: 'none',
                    padding: '8px',
                    fontSize: '12px',
                    overflowWrap: 'break-word',
                  }}
                />
                <IconButton
                  color="primary"
                  sx={{ p: '10px' }}
                  onClick={handleSend}
                  disabled={loading}
                >
                  <SendIcon />
                </IconButton>
              </Paper>
            )} */}
             <CustomTextField
                variant="outlined"
                placeholder="Ask me anything..."
                fullWidth
                multiline
                rows={2}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleSend}
                        disabled={loading}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
              />
          </Box>
   
          </Box>
    </>
  )};

  export default ChatAccordian