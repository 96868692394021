import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  Typography,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {
  answerTypesDropdown,
  addSectionQuestion,
  addSubSectionQuestion,
  getDropDownListApi,
  dropdownEdgeCreation,
  addCheckboxAndRadioButton,
  addTableData,
  fetchDisclosure,
  getTableCuurencyUrl,
  FetchGetData,
  editQuestion,
  DeleteQuestion,
} from "../services/FormCreationFetch";
import { Tooltip } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
// import { DataContext } from "../context/DataContext";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Chip from "@mui/material/Chip";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
import TextInputContainer from "./TextInputContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NoteContext from "context/PageContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { valueToPercent } from "@mui/base";
import AddIcon from "@mui/icons-material/Add";
import EditSubSection from "./EditSubSection";
import { fetchquestion } from "../services/FormCreationFetch";
import Modal from "components/Modal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AIResponse from "./AIResponse";

let disclosurename = "disclosurename";

const EditQuestion = (props) => {
  const contextData = useContext(NoteContext);
  const [formValues, setFormValues] = useState([]);
  const disclosureNameFromContext = useContext(NoteContext);
  const [editIcon, setIconEDit] = React.useState(false);
  const [showMyComponent, setShowMyComponent] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState({
    Question: "",
    QuestionType: "",
    AnswerType: "",
    HashTags: [],
  });
  // const [formFields, setFormFields] = useState([
  //   { id: 1, Name: '', Field: '' },
  // ]);
  const [formFields, setFormFields] = useState([
    { id: 1, Name: "", Field: "" },
  ]);
  const [DropdownValue, setDropdownValue] = useState();
  const [dropdownApiDataValues, setDropdownApiDataValues] = useState();
  const [dropdownApiData, setDropdownApiData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [sections, setSections] = useState([]);
  const [additionalInput, setAdditionalInput] = useState("");
  // const question_popup_from_context = useContext(DataContext);
  const [numInputs, setNumInputs] = useState("");
  const [inputValues, setInputValues] = useState([]);
  // const [DropdownValue, setDropdownValue] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = React.useState(false);
  const [openAIModal, setopenAIModal] = useState(false);

  const fetchques = async (id) => {
    await fetchquestion(id).then(async (data) => {
      console.log(id, data);
      const obj = {};
      if (contextData.disclosureName) {
        obj.DisclosureName = contextData.disclosureName;
      } else {
        console.log(localStorage.getItem("disclosurename"));
        obj.DisclosureName = JSON.parse(localStorage.getItem("disclosurename"));
      }
      data._items.map((data) => {
        if (data.label === "FieldType") {
          obj.AnswerType = data.value;
          setdata((prevstate) => {
            return { ...prevstate, AnswerType: data.value };
          });
        }
        if (data.label === "Help Text") {
          obj.questionhelpertext = data.value;
        }
        if (data.label === "Description") {
          obj.questiondescription = data.value;
        }
        if (data.label === "Question") {
          obj.question = data.value;
        }
        if (data.label === "questionId") {
          obj.questionId = data.value;
        }
        if (data.label === "pk") {
          obj.questionid = data.id.split("|")[0];
        }
        if (data.label === "SubQuestionJson") {
          console.log(JSON.parse(data.value).subQuestion);
          setFormFields(JSON.parse(data.value).subQuestion);
        }
        if (data.label === "TableJson") {
          setInputValues(JSON.parse(data.value).ColumnLabel.Columns);
        }
        // if (data.label === "prompt") {
        //   //console.log(data.value)
        //   obj.prompt = JSON.parse(data.value);

        // }
      });
      console.log(obj);
      setFormValues(obj);
    });
  };

  useEffect(() => {
    const AnswertypeData = async () => {
      await answerTypesDropdown().then((data) => {
        setDropdownValue(data?._items);
      });
    };
    const dropdownApiData = async () => {
      await getDropDownListApi().then((data) => {
        // console.log(data.values);
        setDropdownApiDataValues(data.values);
      });
    };
    // console.log('edit quest useeffect')
    AnswertypeData();
    dropdownApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const handleClose = () => {
    setOpen(false);
    setIconEDit(false);
  };

  const handleSave = async () => {
    let disclosureId;
    if (contextData.disclosureName) {
      console.log(contextData.disclosureName);
      disclosureId = contextData.disclosureName;
    } else {
      disclosureId = JSON.parse(localStorage.getItem("disclosurename"));
    }
    let AnswerValues = "";
    if (formValues?.AnswerType === "Table") {
      AnswerValues = JSON.stringify({ ColumnLabel: { Columns: inputValues } });
    } else if (formValues?.AnswerType === "Form Field") {
      AnswerValues = JSON.stringify({ subQuestion: formFields });
    }
    editQuestion(formValues, AnswerValues)
      .then((res) => {
        console.log(res);
        if (
          formValues?.AnswerType === "Check Box" ||
          formValues?.AnswerType === "Radio Button"
        ) {
          addCheckboxAndRadioButton(
            formValues.questionid,
            data.HashTags.toString()
          );
        } else if (
          formValues?.AnswerType === "Drop Down List" ||
          formValues?.AnswerType === "Drop down list"
        ) {
          dropdownEdgeCreation(formValues.questionid, dropdownApiData);
        } else if (formValues?.AnswerType === "Table") {
          addTableData(formValues.question, JSON.stringify(inputValues)).then(
            () => {
              for (const item of inputValues) {
                if (item.Type === "Drop Down List") {
                  dropdownEdgeCreation(formValues.question, item.dropdownApi);
                }
              }
            }
          );
        } else if (formValues?.AnswerType === "Form Field") {
          for (const item of formFields) {
            if (item.Field === "Drop Down List") {
              dropdownEdgeCreation(formValues.question, item.dropdownApi);
            }
          }
        }
      })
      .then(() => {
        props.handleClose();
        contextData.setReloadData(true);
        props.ReloadQuestion(props.id);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let update = formValues?.prompt;
    console.log(name, value);
    if (name === "oai") {
      update["oai"] = formValues?.prompt?.bard === "true" ? "false" : "true";
    } else if (name === "bard") {
      update["bard"] = formValues?.prompt?.bard === "true" ? "false" : "true";
    } else if (name === "prompt") {
      update["question"] = value;
    }

    if (name === "oai" || name === "bard" || name === "prompt") {
      setFormValues({
        ...formValues,
        [name]: update,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleTagChange = (event) => {
    setAdditionalInput(event.target.value);
  };

  // Handle Add HashTags
  const handleAddTag = () => {
    if (additionalInput.trim() !== "") {
      setdata((prevData) => ({
        ...prevData,
        HashTags: [...prevData.HashTags, additionalInput.trim()],
      }));
      setAdditionalInput("");
    }
  };

  //function Delete HashTags
  const handleDeleteTag = (tagToDelete) => {
    setdata((prevData) => ({
      ...prevData,
      HashTags: prevData.HashTags.filter((tag) => tag !== tagToDelete),
    }));
  };

  // functions for formField

  const addField = () => {
    const newField = {
      id: formFields.length + 1,
      Name: "",
      Field: "",
    };
    setFormFields([...formFields, newField]);
  };

  const removeField = (id) => {
    const updatedFields = formFields.filter((field) => field.id !== id);
    setFormFields(updatedFields);
  };

  const handleTextChange = (id, value) => {
    const updatedFields = formFields.map((field) =>
      field.id === id ? { ...field, Name: value } : field
    );
    setFormFields(updatedFields);
  };

  const handleDropdownChange = (id, value) => {
    //console.log(formFields);
    const updatedFields = formFields.map((field) =>
      field.id === id ? { ...field, Field: value } : field
    );
    //console.log(updatedFields);
    setFormFields(updatedFields);
  };

  const handleNumInputChange = (event) => {
    const newField = {
      id: inputValues.length + 1,
      Name: "",
      Type: "",
    };
    setInputValues([...inputValues, newField]);
  };

  const handleInputChangeTxt = async (index, property, value) => {
    console.log(index, property, value);

    if (value === "Currency Field") {
      const getTableCurrencyData = async () => {
        const data = await getTableCuurencyUrl();
        // console.log(data.values[0].values);

        const newInputValues = [...inputValues];
        newInputValues[index] = {
          ...newInputValues[index],
          [property]: value,
          values: data.values[0].values,
        };

        // Send newInputValues to the backend with the added 'values' property
        // You can make an API call or use some other method to send data to the backend here
        console.log(newInputValues);

        setInputValues(newInputValues);
        // console.log(valueForTableCurrencyDropdown);
      };
    } else if (property === "Checkboxvalues") {
      console.log(value);
      const newInputValues = [...inputValues];
      newInputValues[index] = {
        ...newInputValues[index],
        [property]: value,
      };
      setInputValues(newInputValues);
    } else {
      if (property === "values") {
        const selectedItem = dropdownApiDataValues.find(
          (item) => item.name === value
        );
        // if(value === 'Check Box' || value === 'Radio Button'){
        console.log(value);
        // }
        if (selectedItem) {
          // Update the state with the selected item's values
          const newInputValues = [...inputValues];
          newInputValues[index] = {
            ...newInputValues[index],
            [property]: selectedItem.values,
            dropdownApi: value,
          };

          // Send selectedItem.values to the backend
          // You can make an API call or use some other method to send data to the backend here
          // console.log(selectedItem.values);
          // console.log(newInputValues,value)
          setInputValues(newInputValues);
        }
      } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = {
          ...newInputValues[index],
          [property]: value,
        };

        if (value !== "Currency Field" && value !== "Dropdown") {
          delete newInputValues[index].values;
        }

        setInputValues(newInputValues);
        // console.log(newInputValues);
      }
    }
  };
  //console.log("running");
  // const handleButtonClick = () => {
  //   setShowTable(true);
  // };
  const filteredDropdownValue =
    DropdownValue &&
    DropdownValue?.filter(
      (option) =>
        option !== "Table" &&
        option !== "Form Field" &&
        option !== "Rich Text" &&
        option !== "Multiselect" &&
        option !== "Contact Information" &&
        option !== "Radio Button"
    );

  const FieldTypesForTable =
    filteredDropdownValue &&
    filteredDropdownValue?.filter(
      (option) =>
        option !== "List of Address" &&
        option !== "Date Range" &&
        option !== "TelephoneNumber" &&
        option !== "URL Field" &&
        option !== "Email Field"
    );

  const handleClickOpen = () => {
    fetchques(props.id);
    setOpen(true);
  };

  const HandleDeleteQuestion = async (id) => {
    await DeleteQuestion(id).then(() => {
      contextData.setReloadData(true);
    });
  };
  // const removeFieldForTable = (id) => {
  //   const updatedFields = inputValues.filter((field) => field.id !== id);
  //   setInputValues(updatedFields);
  // };
  // const handleOpenAIResponse = async () => {
  //   setopenAIModal(true);
  // };

  return (
    <div>
      <IconButton onClick={() => handleClickOpen()}>
        <EditIcon sx={{ fontSize: "18px" }} />
      </IconButton>
      <IconButton>
        <DeleteIcon
          sx={{ fontSize: "18px" }}
          onClick={() => setopenDeleteModal(true)}
        />
        <Modal
          open={openDeleteModal}
          setOpen={setopenDeleteModal}
          title={"Are you sure? You want to delete this?"}
          actions={
            <>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#25A392",
                  "&:hover": {
                    backgroundColor: " #25a392",
                  },
                }}
                onClick={() => HandleDeleteQuestion(props.id)}
              >
                Delete
              </Button>
            </>
          }
          maxWidth={"xs"}
        ></Modal>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Edit the question"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0.2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="question-input"
                name="question"
                label="question"
                type="text"
                value={formValues.question}
                onChange={handleInputChange}
                focused
              />
              {/* <Tooltip title="about question">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip> */}
            </Grid>
            {
              formValues.questionid?(

            <Grid item xs={6}>
              {formValues.questionid && (
                <TextField
                  fullWidth
                  id="questionid-input"
                  name="questionid"
                  value={formValues.questionid}
                  label="question id"
                  type="text"
                  focused
                  disabled
                />
              )}
            </Grid>
              ):
              null
            }
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questiondescription-input"
                name="questiondescription"
                label="question description"
                type="text"
                required
                value={formValues.questiondescription || ""}
                onChange={handleInputChange}
                focused
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questiondescription-input"
                name="questionId"
                label="Display Name"
                type="text"
                required
                value={formValues.questionId || ""}
                onChange={handleInputChange}
                focused
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questionhelpertext-input"
                name="questionhelpertext"
                label="question helpertext"
                placeholder="Question Helpertext"
                type="text"
                required
                value={formValues.questionhelpertext || ""}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              {DropdownValue && (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={DropdownValue}
                  // value={data.AnswerType}
                  value={formValues?.AnswerType || ""}
                  onChange={(e, data) => {
                    // setdata((prevstate) => {
                    //   return { ...prevstate, AnswerType: data };
                    // })
                    setFormValues((prevstate) => {
                      return { ...prevstate, AnswerType: data };
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Answer Type" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {formValues?.AnswerType === "Drop Down List" ||
              formValues?.AnswerType === "Drop Down" ? (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={dropdownApiDataValues?.map((item) => item.name)}
                  value={formValues?.dropdownApiData}
                  onChange={(e, data) => {
                    //console.log(data);
                    setFormValues((prevstate) => {
                      return { ...prevstate, FieldTypeJson: data };
                    });
                    setDropdownApiData(data);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Api" />
                  )}
                />
              ) : (
                ""
              )}
            </Grid>
            {formValues?.AnswerType === "Check Box" ||
            formValues?.AnswerType === "Radio Button" ? (
              <Grid item xs={6} md={6}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    label="Additional Input"
                    value={formValues.additionalInput}
                    // className="Input_Fields_hashTags"
                    required
                    onChange={handleTagChange}
                    style={{ flexDirection: "column" }}
                    placeholder="Additional Input"
                  />
                  <Button
                    variant="contained"
                    component="span"
                    // className="button_HashTags"
                    onClick={handleAddTag}
                    style={{ flexDirection: "column" }}
                  >
                    +
                  </Button>
                </div>
                {data?.HashTags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={`${tag}`}
                    onDelete={() => handleDeleteTag(tag)}
                  />
                ))}
              </Grid>
            ) : (
              ""
            )}
            {formValues.AnswerType === "Table" ||
            formValues.AnswerType === "table" ? (
              <div>
                {inputValues?.map((input, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <TextField
                      label={"Text Input"}
                      value={input.Name}
                      onChange={(event) =>
                        handleInputChangeTxt(index, "Name", event.target.value)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    />

                    {DropdownValue && (
                      <Autocomplete
                        options={FieldTypesForTable}
                        value={input.Type}
                        onChange={(_, newValue) =>
                          handleInputChangeTxt(index, "Type", newValue)
                        }
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 2,
                          width: 250,
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Field Type" />
                        )}
                      />
                    )}

                    {input.Type === "Drop Down List" ||
                    input.Type === "Drop down list" ? (
                      <>
                        <Autocomplete
                          id="combo-box-demo"
                          options={dropdownApiDataValues.map(
                            (item) => item.name
                          )}
                          value={input?.dropdownApi}
                          onChange={(_, Values) =>
                            handleInputChangeTxt(index, "values", Values)
                          }
                          //  handleInputChangeTxt(index, 'values',`/fillings/table/${Values}`)}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            width: 250,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Api" />
                          )}
                        />
                      </>
                    ) : (
                      // dropdownApiDataValues.map((item)=>{
                      //   console.log(item.values);
                      // })
                      ""
                    )}
                  </div>
                ))}

                <Grid item>
                  <Button
                    sx={{ margin: "5px 0px 5px 10px" }}
                    onClick={handleNumInputChange}
                  >
                    Add Field
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
            {formValues?.AnswerType === "Form Field" ? (
              <div>
                {formFields?.map((field) => (
                  <div
                    key={field.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <TextField
                      label="Text Input"
                      value={field.Name}
                      onChange={(e) =>
                        handleTextChange(field.id, e.target.value)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    />
                    <Autocomplete
                      id={`combo-box-demo-`}
                      options={filteredDropdownValue}
                      value={field.Field}
                      // onChange={(_, newValue) => handleInputChangeTxt(index, 'Type', newValue)}
                      onChange={(e, data) =>
                        handleDropdownChange(field.id, data)
                      }
                      // sx={{ width: 300 }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                        width: 250,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Field Type" />
                      )}
                    />
                    {/* <>{field?.Field}</> */}
                    {(field?.Field === "Drop Down List" ||
                      field?.Field === "Drop Down") && (
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={dropdownApiDataValues.map((item) => item.name)}
                        value={field?.dropdownApi}
                        onChange={(e, data) => {
                          const updatedFields = formFields.map((item) =>
                            item.id === field.id
                              ? {
                                  ...item,
                                  values: dropdownApiDataValues.find(
                                    (i) => i.name === data
                                  )?.values,
                                  dropdownApi: data,
                                }
                              : item
                          );
                          setFormFields(updatedFields);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Api" />
                        )}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 2,
                          width: 250,
                        }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeField(field.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
                <Grid item>
                  <Button
                    sx={{ margin: "5px 0px 5px 10px" }}
                    onClick={addField}
                  >
                    Add Field
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
            {/* <Grid item xs={12}>
            <TextField
                fullWidth
                multiline
                rows={4}
                id="AI response Promt"
                name="prompt"
                label="AI response Promt"
                type="text"
                required
                value={formValues?.prompt?.question}
                onChange={handleInputChange}
                focused
              />
            </Grid> */}
          </Grid>
          {/* <Grid item xs={6}>
          <FormGroup>
            {formValues?.prompt?.oai === "true" ? 
            (<FormControlLabel  control={<Switch  checked={true} />} name="oai"  onChange={handleInputChange} label="CHat GPT Response"  />) 
            :
            (<FormControlLabel  control={<Switch checked={false}  />} name="oai"  onChange={handleInputChange} label="CHat GPT Response"  />)}
            {formValues?.prompt?.bard === "true" ?
            ( <FormControlLabel  control={<Switch  checked={true} />} name="bard" onChange={handleInputChange} label="Bard AI Response" />) 
            : 
            ( <FormControlLabel  control={<Switch  checked={false} />} name="bard" onChange={handleInputChange} label="Bard AI Response" />)}
           
          </FormGroup>
          <Button onClick={handleOpenAIResponse}>Test AI Response</Button>
          {
              openAIModal &&
                 <AIResponse
                 open={openAIModal}
                 onClose={handleCloseAIModal}
                 Question={formValues.prompt}
               />
            }
           
            </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditQuestion;
