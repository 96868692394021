// ReusableSnackbar.js
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const ReusableSnackbar = ({ open, message, severity, onClose }) => {
  return (
    <Snackbar   TransitionComponent={Slide}
    TransitionProps={{ direction: 'right' }} // Slide down animation
    open={open} autoHideDuration={3000} onClose={onClose} >
      <MuiAlert elevation={6} variant="filled" severity={severity} sx={{ transform: 'translateY(20px)', transition: 'transform 0.3s ease-in-out' }}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default ReusableSnackbar;
