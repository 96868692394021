import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Chart from "react-apexcharts";
import CloseIcon from "@mui/icons-material/Close";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import "../styles/graph/GraphStyle.css";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// importing Api form GraphFtech file
import {
  GetGraphdata,
  GetCountryList,
  GetSocaildata,
  GETGraphRender,
} from "../../ESG/services/GraphFetch";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import { useNavigate } from "react-router";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

// Code for displaying Graph
const WorldGraph=(props)=> {
  const [value1, setValue1] = React.useState([1960, 2021]);
  const [Year, setYear] = useState();
  const [Datavalue, setDatavalue] = useState([]);
  const [Isloading, setIsloading] = useState(false);
  const [Name, setName] = useState("worldData");
  const [Countrylist, Setcountry] = useState([]);
  const [inputValue, setinputValue] = useState();
  const [Gender, setGender] = useState();
  let YearRange = [];
  // let countryarray=[];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Istrue, setIstrue] = useState(false);
  const [FromYear, setFromYear] = useState();
  const [EndYear, setEndYear] = useState();
  const [inputValue1, setinputValue1] = useState();

  const GenderList = ["male", "female"];

  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };
  //code for setting year filter for graph
  const setYearforfilter = async () => {
    await GetSocaildata(props.Name).then((Data) => {
      setFromYear(Object.keys(Data.data[0])[0]);
      setEndYear(
        Object.keys(Data.data[0])[Object.keys(Data.data[0]).length - 1]
      );
    });
  };
  //code for fetching backend data for graph
  useEffect(() => {
    if (props.GenderDep) {
      //code for fetching backend data for graph with Gender params
      GetSocaildata(props.Name, inputValue, Gender, value1[0], value1[1]).then(
        (Response) => {
          const y_axis_values = Object.values(Response.data[0]).map((Value) => {
            return Value;
          });
          setYear(Object.keys(Response.data[0]));
          setYearforfilter();
          Object.keys(Response.data[0]).forEach((Element) => {
            let a = { value: Number(Element), label: Element };
            YearRange.push(a);
          });

          setDatavalue(y_axis_values);
          setName(Response.name);
          //console.log(Response.data);
          setIsloading(true);
          setIstrue(true);
        }
      );
    } else {
      //code for fetching backend data for graph without Gender params
      GetGraphdata(props.Name, inputValue, value1[0], value1[1]).then((x) => {
        setYear(Object.keys(x.data[0]));
        Object.keys(x.data[0]).forEach((Element) => {
          YearRange.push({ value: Number(Element), label: Element });
        });
        setYearforfilter();
        setDatavalue(Object.values(x.data[0]));
        setName(x.name);
        setIsloading(true);
        setIstrue(true);
      });
    }
    //code for fetching country list for dropdown
    //Setcountry(a.CountrListdata);

    FtechCountryList();

    // if(countryarray.length===0)
    // {
    //   GetCountryList().then((response) => {
    //    // console.log(countryarray.length);
    //     countryarray.push(response);
    //   console.log(a.CountrListdata);
    //     Setcountry(response);

    //     //console.log(countryarray);

    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, Gender, value1]);
  // console.log(inputValue);
  //code for fetching country list for dropdown
  const FtechCountryList = async () => {
    GetCountryList().then((response) => {
      //console.log(Object.values(response[0]));
      Setcountry(response);
      // console.log(response);
    });
  };
  // code for displaying pop up graph
  let state = {
    options: {
      colors: [props.Gcolour],
      chart: {
        id: "basic-bar",
      },
      noData: {
        text: "There is no data available for this country",
        align: "centre",
        verticalAlign: "middle",
        offsetX: 10,
        offsetY: 10,
        style: {
          color: "red",
          fontSize: "20px",
          fontFamily: "Microsoft YaHei",
        },
      },
      dataLabels: {
        enabled: false,
        floating: false,
      },
      yaxis: {
        show: true,
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      xaxis: {
        categories: Year,
        tickAmount: 4,
      },
    },
    stroke: { show: true, curve: "stepline", lineCap: "butt", width: 0.5 },
    series: [
      {
        name: Name,
        data: Datavalue,
      },
    ],
  };

  //code for normal graph page
  let data1 = {
    options: {
      colors: [props.Gcolour],
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: false,
        floating: false,
      },
      legend: {
        show: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },

      yaxis: {
        show: true,
        labels: {
          formatter: function (value) {
            if (
              props.Name === "Unemployment" ||
              props.Name === "Employers" ||
              props.Name === "LaborTaxAndContributions"
            ) {
              return value.toFixed(2) + props.SiUint;
            } else {
              return value / props.denominator + props.SiUint;
            }
          },
        },
      },
      stroke: { show: true, curve: "smooth", lineCap: "butt", width: 0.5 },
      xaxis: {
        show: true,
        axisTicks: {
          show: false,
        },
        categories: Year,
        tickAmount: 4,
      },
    },
    series: [
      {
        name: Name,
        data: Datavalue,
      },
    ],
  };
  return (
    <div>
      <div>
        <div className="containeer">
          <IconButton
            variant="outlined"
            onClick={handleOpen}
            aria-label="delete"
            size="small"
            className="IconButton"
          >
            <ZoomOutMapIcon className="ExpandIcon" />
          </IconButton>
        </div>
        <h6 align="center" className="title">
          {Name}
        </h6>
        <Chart
          options={data1.options}
          series={data1.series}
          type="area"
          height={160}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="ModelBox">
            <Typography
              component={"span"}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              <IconButton
                onClick={handleClose}
                aria-label="delete"
                size="small"
                className="IconButton"
                variant="outlined"
              >
                <CloseIcon />
              </IconButton>
            </Typography>
            <div>
              <div>
                {Isloading ? (
                  <>
                    <h4 align="center" className="title">
                      {Name}
                    </h4>
                    <Grid container>
                      <Autocomplete
                        id="standard-basic"
                        options={Countrylist}
                        getOptionLabel={(option) => option.CountryName ?? ""}
                        isOptionEqualToValue={(option) =>
                          option.CountryName ?? ""
                        }
                        onChange={(e, newCountry) => {
                          setinputValue(() => {
                            return newCountry?.CountryCode;
                          });
                        }}
                        className="CountryBox"
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Country" />
                        )}
                      />

                      <Divider orientation="vertical" flexItem></Divider>
                      {props.GenderDep && (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={GenderList}
                          value={inputValue1}
                          defaultValue={null}
                          onInputChange={(event, newInputValue1) => {
                            setGender(newInputValue1);
                          }}
                          className="GenderBox"
                          renderInput={(params) => (
                            <TextField {...params} label="Gender" />
                          )}
                        />
                      )}
                    </Grid>
                    {Istrue && (
                      <Chart
                        options={state.options}
                        series={state.series}
                        type="bar"
                        width={"200%"}
                        height={"100%"}
                      />
                    )}

                    <Box sx={{ width: 250, m: 2 }}>
                      <Slider
                        value={value1}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                        step={1}
                        marks={YearRange}
                        min={Number(FromYear)}
                        max={Number(EndYear)}
                      />
                    </Box>
                  </>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

//code for main page
function GraphPage() {
  const navigate =useNavigate();

  const [GraphPageLoding, setGraphPageLoding] = useState(true);
  const [Graphdata, SetGraphdata] = useState([]);
  useEffect(() => {
    GETGraphRender().then((response) => {
      SetGraphdata(response.data);
      setGraphPageLoding(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {GraphPageLoding ? (
        <>
          <div className="loading-container" align="center">
            
            <PropagateLoader
              css={override}
              size={15}
              color={"#3498db"}
              loading={true}
            />
          </div>
        </>
      ) : (
        <div className="page">
          <Box sx={{ width: "100%" }}>
            
          
            <Typography sx={{fontSize:"20px", fontWeight:"600" }} p={2}>
               <ArrowBackIosIcon
                  style={{
                    marginRight: "0.2em",
                    cursor: "pointer",
                    
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                />ESG-Graphs</Typography>
            <Grid
              container
              justifyContent={"center"}
              className="top"
              xs={12}
              md={12}
            >
              <Typography className="header">Environment</Typography>
            </Grid>
            <Grid container item xs={12} spacing={0.5}>
              {Graphdata.Environment.map((n, index) => {
                return (
                  <Grid item xs={6} md={2} key={index}>
                    <Paper elevation={3} className="bgcolor">
                      <WorldGraph
                        key={index}
                        Name={n.name}
                        denominator={n.denominator}
                        SiUint={n.siUnit}
                        Gcolour={n.gColour}
                        GenderDep={n.genderDependent}
                      />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              justifyContent={"center"}
              className="top"
              xs={12}
              md={12}
            >
              <Typography className="header">Social</Typography>
            </Grid>
            <Grid container item xs={12} spacing={0.5}>
              {Graphdata.Social.map((n, index) => {
                return (
                  <Grid item xs={6} md={2} key={index}>
                    <Paper elevation={3} className="bgcolor">
                      <WorldGraph
                        key={index}
                        Name={n.name}
                        denominator={n.denominator}
                        SiUint={n.siUnit}
                        Gcolour={n.gColour}
                        GenderDep={n.genderDependent}
                      />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              justifyContent={"center"}
              className="top"
              xs={12}
              md={12}
            >
              <Typography className="header">Governance</Typography>
            </Grid>
            <Grid container item xs={12} spacing={0.5}>
              {Graphdata.Governance.map((n, index) => {
                return (
                  <Grid item xs={6} md={2} key={index}>
                    <Paper elevation={3} className="bgcolor">
                      <WorldGraph
                        key={index}
                        Name={n.name}
                        denominator={n.denominator}
                        SiUint={n.siUnit}
                        Gcolour={n.gColour}
                        GenderDep={n.genderDependent}
                      />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </div>
      )}
    </>
  );
}
export default GraphPage;
