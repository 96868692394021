import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const GovernceBody = (props)  =>{
  const [overseeing,setoverseeing]=useState();
  const[frequency,setfrequency] = useState();
  const[organizational,setorganizational]=useState();

    
    const [pageloaded, setpageloaded] = useState(false)
    
  
   
    useEffect (()=>{ 
      setoverseeing((Object.values(props.data.reportData["GRI 2.18 Evaluation of the performance of the highest governance body"])[0]).answer);
      setfrequency((Object.values(props.data.reportData["GRI 2.18 Evaluation of the performance of the highest governance body"])[1]).answer);
      setorganizational((Object.values(props.data.reportData["GRI 2.18 Evaluation of the performance of the highest governance body"])[2]).answer);
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} className="leftgrid">
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                <>
         <h5><b>Describe the processes for evaluating the performance of the highest governance body in overseeing the management of the organizations impacts on the economy, environment, and people.</b></h5>
         <div dangerouslySetInnerHTML={{__html:overseeing}}></div>
        
         </>
                </Grid>
                <Grid item xs={12} md={5}  className="grid2">
          <h4><b>
          Whether the evaluations are independent or not, and the frequency of the evaluations
            </b>
          </h4>
          <div dangerouslySetInnerHTML={{ __html: frequency }}></div>
          <br></br>
          <h6><b>
          Describe actions taken in response to the evaluations, including changes to the 
          composition of the highest governance body and organizational practices.
            </b>
          </h6>
          <div dangerouslySetInnerHTML={{ __html: organizational }}></div>
          
          
          </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default GovernceBody;
