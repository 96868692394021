import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    Typography,
    Button,
    InputLabel,
    TextField,
    IconButton,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead
  } from "@mui/material";
import Select from "react-select";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import add from "images/add.png";
import withSnackbar from "../../components/Alert/withSnackbar"; 
const {
    getIdentityList
  } = require("../../Compliance/services/CaseFetch");

const IdentificationList=({identityData,defaultidentityTypes,setDefaultIdentityTypes,setRows,rows,display,showSnackbar})=>{
  //const [identityData, setIdentityData] = useState(null);
  //const [defaultidentityTypes, setDefaultIdentityTypes] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
 
  const [loading,setLoading]= useState(false)
console.log("edit",display)
  // useEffect(() => {
  //   debugger;
  //   if (!identityData) {
  //   fetchData();
  //   }
  // }, []); //reload

  // const fetchData = async () => {
  //   try {
  //     const [idata] =
  //       await Promise.all([
  //         getIdentityList()
  //       ]);
  //     var sidtypes = idata?.data?.values;
  //     var idtypes = sidtypes.map((idtype) => ({
  //       value: idtype,
  //       label: idtype,
  //     }));
    
  //     setIdentityData(idtypes);
     
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     showSnackbar("unable to fetch data", "error");
  //   }
  // };
    const addRow = () => {
        setRows([...rows, { Type: "", IdentificationNo: "" }]);
       setDefaultIdentityTypes([...defaultidentityTypes, ""]);
      };
    
      const handleChangeType = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].Type = value.label;
        setRows(updatedRows);
    
        const updatedTypes = [...defaultidentityTypes];
        updatedTypes[index] = value;
        setDefaultIdentityTypes(updatedTypes);
      };
    
      const handleChangeNumber = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].IdentificationNo = value;
        setRows(updatedRows);
      };
    
      const deleteRow = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    
        const updatedTypes = [...defaultidentityTypes];
        updatedTypes.splice(index, 1);
        setDefaultIdentityTypes(updatedTypes);
      };
    
      const refreshRow = (index) => {
        const updatedRows = [...rows];
        updatedRows[index] = { Type: "", IdentificationNo: "" };
        setRows(updatedRows);
    
        const updatedTypes = [...defaultidentityTypes];
        updatedTypes[index] = "";
        setDefaultIdentityTypes(updatedTypes);
      };
return(
    <>
 <Grid
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                // padding:'15px'
                }}
              >
                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography className="checklist">
                    Identification Details
                  </Typography>
               {display &&   <Button
                    onClick={addRow}
                    className="addIdentity"
                    variant="contained"
                  >
                    <img
                      src={add}
                      alt="Custom Image"
                      style={{ marginRight: "8px" }}
                    />
                    Add
                  </Button>
}
                </Grid>
            { display?
           <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {rows.map((row, rowIndex) => (
                    <Grid
                      key={rowIndex}
                      style={{
                       // width: "50%",
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "row",
                        //flexWrap: "wrap",
                       // marginRight:"10px"
                      }}
                    >
                      <Grid>
                        <InputLabel
                          className="commonLabel"
                          style={{ marginRight: "10px" }}
                        >
                          Identification Type
                        </InputLabel>
                        <Select
                          value={defaultidentityTypes[rowIndex]}
                          onChange={(e) => handleChangeType(rowIndex, e)}
                          options={identityData}
                          isSearchable={true}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              minWidth: "280px",
                              marginRight: "10px",
                            }),
                          }}
                        />
                      </Grid>
                      <Grid>
                        <InputLabel
                          className="commonLabel"
                          style={{ marginRight: "10px" }}
                        >
                          Identification Number
                        </InputLabel>
                        <TextField
                          type="text"
                          size="small"
                          // fullWidth
                          value={row.IdentificationNo || ""}
                          onChange={(e) =>
                            handleChangeNumber(rowIndex, e.target.value)
                          }
                          style={{ height: "20px" }}
                        />
                      </Grid>
                      {(row.Type !== "" || row.IdentificationNo !== "") && (
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => refreshRow(rowIndex)}
                            aria-label="refresh"
                            style={{ padding: "0px" }}
                          >
                            <RefreshIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => deleteRow(rowIndex)}
                            aria-label="delete"
                            style={{ padding: "0px" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
                :
                <>
                {typeof rows === "string" &&
                JSON.parse(rows).length > 0 ? (
                  <Table className="casestable">
                    <TableHead>
                      <TableCell>ID type</TableCell>
                      <TableCell>Number</TableCell>
                    </TableHead>
                    <TableBody>
                      {JSON.parse(rows).map((item) => (
                        <TableRow key={item.Type}>
                          <TableCell>{item.Type}</TableCell>
                          <TableCell>{item.IdentificationNo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : rows &&
                rows.length > 0 ? (
                  <Table className="casestable">
                    <TableHead>
                      <TableCell>ID type</TableCell>
                      <TableCell>Number</TableCell>
                    </TableHead>
                    <TableBody>
                      {rows.map((item) => (
                        <TableRow key={item.Type}>
                          <TableCell>{item.Type}</TableCell>
                          <TableCell>{item.IdentificationNo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography>No Identification List</Typography>
                )}
                </>
                  
}
              </Grid>
    </>
)
}


export default withSnackbar(IdentificationList, "IdentificationList", "success");