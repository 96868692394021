import { TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";
import "../../styles/Report/Report.css";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";


function MTDB1(props) {
  const [isloading, setisloading] = useState(false);
  const [Question, setQuestion] = useState();
  const [opportunities, setopportunities] = useState();
  const [aggregation, setaggregation] = useState();
 

  useEffect(() => {
    let ddd = [];
    const QuestionData = [];
    const AnswerData = [];
    for (let i = 0;i < Object.keys(props.data.reportData["M.Disclosure B"]).length;i++) {
      if (
        Object.values(props.data.reportData["M.Disclosure B"])[i].answer ==="Yes" ||
        Object.values(props.data.reportData["M.Disclosure B"])[i].answer ==="No"
      ) {
        QuestionData.push(Object.keys(props.data.reportData["M.Disclosure B"])[i]);
        AnswerData.push(Object.values(props.data.reportData["M.Disclosure B"])[i].answer);
        ddd.push({Questions: Object.keys(props.data.reportData["M.Disclosure B"])[i],
          Answer: Object.values(props.data.reportData["M.Disclosure B"])[i].answer,
        });
        // console.log(Object.keys(props.data.reportData["G.Disclosure B"])[3])
        setopportunities(
          Object.values(props.data.reportData["M.Disclosure B"])[0].answer
        );
        setaggregation(
          Object.values(props.data.reportData["M.Disclosure B"])[1].answer
        );
      }
    }
    setQuestion(ddd);
    // console.log(ddd);
    setisloading(true);
  }, [isloading, props]);
  return (
    <React.Fragment>
      {isloading && (
        <>
          <Grid container spacing={1} className="border3">
            <Grid item xs={12} md={2} className="border3" >
              <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  </div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                    <li>Strategy</li>
                    <li>Risk Management</li>
                    <li style={{color:"#724272",fontSize:"20px"}}><b>Metrics and Targets</b></li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#724272"}} className="border3">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Metrics and Targets</h6>
                      <Typography style={{color:"white",paddingLeft:"10px"}}>Disclose Scope 1, Scope 2, and, if appropriate, Scope 3 
                                    greenhouse gas (GHG) emissions, and the related risks.</Typography>
                  </Grid>
              <Grid item xs={12} md={6} className="grid1">
              <h4 className="underline">M.Disclosure B</h4>
                <Typography><b>Organizations should provide the Scope 1 and Scope 2 GHG emissions 
                    independent of a materiality assessment relevant to climate-related risks and opportunities.</b>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: opportunities }}></div>
              </Grid>
              <Grid item xs={12} md={6} className="grid2">
                <h5>
                  <b>Disclosure B Y/N</b>
                </h5>
                <div style={{ justifyContent: "right", display: "flex" }}>
                  <TableContainer>
                    <Table className="Table_TCFD" aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 1,
                              ffontFamily: 'Microsoft YaHei',
                              fontSize: "15px",
                            },
                          }}
                        >
                          <TableCell>Questions</TableCell>
                          <TableCell>Yes/No</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 1,
                            fontSize: "15px",
                          },
                        }}
                      >
                        {Question.map((val, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{val.Questions}</TableCell>
                              <TableCell>{val.Answer}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <br></br>
                <Typography>
                  <b>
                 Organizations should consider providing generally accepted industry-specific GHG efficiency
                  ratios using GHG Protocol methodology which allows for aggregation and comparability across organizations and jurisdictions.
                  </b>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: aggregation }}></div>
              </Grid>
              </Grid>
            </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default MTDB1;