import React, { useEffect, useState,useContext } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
    Grid,
    Typography,
    Button,
    Table,
    TableHead,
    TableCell,
    IconButton,
    TableBody,
    TableRow,
    Tooltip,
    TableContainer
  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import withSnackbar from "../../components/Alert/withSnackbar"; 
import Visibility from "@mui/icons-material/Visibility";
import ChildFilterPopover from "Compliance/CaseManagment/ChildCaseFilter";
import InfoIcon from '@mui/icons-material/Info';
import NoteContext from "context/PageContext";
import Pagination from '@mui/material/Pagination';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from '@mui/icons-material/Add';
import FilterPopover from "Compliance/CaseManagment/CaseFilter";
import CreateChildCasePopup from "Insurance/CaseManagement/CreateChildCasePopup";
import { CASECREATOR,INSURANCE } from "Insurance/CaseManagement/Config";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
const {
  getSubCases,
  createSubCase,
 
} = require("../services/CaseFetch");
const ChildCases=({title,data,setselectedSubCase,selectedCaseID,selectedSubCase,ltype,showSnackbar})=>{
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {templateType,caseType} = useTemplateType();
  const userDetails = JSON.parse(localStorage.getItem("AccountDetails"));
  const auth = userDetails?.endUser;
  const [loading, setLoading] = useState();
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filteredRows, setfilteredRows] = useState(data);
  const [childanchorElFilter, setchildAnchorElFilter] = useState(null);
  const [currentSubPage, setCurrentSubPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [popupOpen, setPopupOpen] = useState(false);
  const [checkData,setCheckData]=useState({
    type: '',
    date_conducted: '',
    conducted_by: '',
    current_case_worker: '',  
    current_case_worker_email: '',  
    expected_closure_date: '',
    exception_description: '',
    department:'',
    subcategory:'',
    caseTitle:''
  })
  const [hasCaseCreatorRole, setHasCaseCreatorRole] = useState();
  const startSubIndex = (currentSubPage - 1) * itemsPerPage;
  const endSubIndex = startSubIndex + itemsPerPage;
  const handleSubPageChange = (event, page) => {
    setCurrentSubPage(page);
  };
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
  };
  // useEffect(() => {
  //   filterdata();
  // }, []);
  // const filterdata = () => {
  //   setfilteredRows([]);

  //   if (selectedStatus === "All") {
  //     setfilteredRows(data);
  //     setselectedSubCase(data[0])
  //   } else {
  //     setfilteredRows(data?.filter((row) => row.Status === selectedStatus));
  //     setselectedSubCase(data[0])
  //   }
  // };
  const handlechildFilterClick = (event) => {
    setchildAnchorElFilter(event.currentTarget);
  };

  const handleApplyChildFilter = async (filterOptions) => {
    try {
      showSnackbar("Case table refeshing please wait..", "info");
      await getSubCases(selectedCaseID,filterOptions).then((data) => {
        const parsedData = data?.data?.values
        // .map((obj) => {
        //   const parsedObj = { ...obj };
        //  parsedObj.hash_tag = obj.hash_tag ? JSON.parse(obj.hash_tag) : [];
        //   return parsedObj;
        // });
        setfilteredRows(parsedData);
      });
      showSnackbar("SubCase table refeshed", "success");
    } catch (error) {
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true,
      });
      showSnackbar("Feching cases error", "error");
      setLoading(false);
    }
  };
  const handlechildFilterClose = () => {
    setchildAnchorElFilter(false);
    
  };
  const handleAddChildCaseClick = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    clear()
  };
  const clear=()=>{
    setCheckData({
      type: '',
      date_conducted: '',
      conducted_by: '',
      current_case_worker: '',  
      current_case_worker_email: '',  
      expected_closure_date: '',
      exception_description: '',
      department:'',
      subcategory:'',
      caseTitle:''
    })
  }
  const handleSave = async () => {
    try {
     setLoading(true)
      const param={
        param:{
         domain:ContextData?.UserSelected,
         //case_name:ContextData.selectedCaseType+"Insurance"
         case_name:"Insurance"
        },
        body:{
         parent_id:selectedCaseID, 
         department:checkData.department,
         
       checks: [{
          type: checkData.subcategory,
          date_conducted: checkData.date_conducted,
          conducted_by:checkData.current_case_worker,
          current_case_worker: checkData.current_case_worker,  
          current_case_worker_email: checkData.current_case_worker_email,  
          expected_closure_date: checkData.expected_closure_date,
          result: "Fail",
          link_to_evidence: '',
          exception: {
            exception_type: checkData.subcategory,
            error_code: '',
            expected_resolution_by: checkData. expected_closure_date,
            exception_description: checkData.exception_description,
          },
          sub_attachments: []
        }],
        case:{
          creator_id:auth?.mail,
          creator_email:auth?.mail,
          creator:auth?.displayName,
          subcategory:checkData.subcategory,
          caseTitle:checkData.caseTitle,
        }
      }
      }
      const response = await createSubCase(param)
      if (response.status==200) {
        handleApplyChildFilter(); 
        setPopupOpen(false) 
        clear()
        setLoading(false)
        // showSnackbar("Child case added successfully!", "success");
      } else {
        setPopupOpen(false) 
        setLoading(false);
        clear()
        showSnackbar("Failed to add child case", "error");
      }
    } catch (error) {
      setPopupOpen(false) 
      showSnackbar("Error adding child case", "error");
    }
  };
  const handleChange = (e,name) => {
   // const { name, value } = e;
  
    if(e?.target?.name=='current_case_worker_email')
      {
        setCheckData(prevState => ({ ...prevState, 
          [e.target.name]: e.target.value.split(",")[0] ,
          current_case_worker:e.target.value.split(",")[1]
        }));
      }
      else if(name=='subcategory'){
        setCheckData(prevState => ({ ...prevState, [name]: e.value }));
        
      }
      else{
    setCheckData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
      }
  };

  useEffect(() => {
    setHasCaseCreatorRole(
      ContextData?.CaseRole?.name === CASECREATOR ? true : false
    );

  }, [ContextData?.CaseRole?.name, hasCaseCreatorRole]);
return(
    <>
    <Grid>
    <Grid style={{ display: 'flex', justifyContent: 'space-between',marginBottom: "10px" }}>
            <Grid>
              <p className="heading">Child Cases</p>
              <p className="subhead" style={{marginLeft:'10px'}}>Case Details</p>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center'}}>
              <Grid>
                <CreateChildCasePopup 
                 open={popupOpen}
                 handleSave={handleSave}
                 handleClose={handlePopupClose}
                 handleChange={handleChange}
                 checkdata={checkData}
                 load={loading}
                />
             { hasCaseCreatorRole && ContextData.selectedCaseLabel==INSURANCE &&  (
                <Tooltip title="Add Child Case">
                  <IconButton
                    className="filtericon"
                    onClick={handleAddChildCaseClick}
                    
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                ) }
              </Grid>
              <Grid>
                {/* {Info?.length > 0 && <> */}
                {ContextData.selectedCaseType?
                <ChildFilterPopover
                  anchorEl={childanchorElFilter}
                  onApplyFilter={handleApplyChildFilter}
                  onClose={handlechildFilterClose}
                  // CaseCategoryData={CaseCategorys}
                 // caseStatusData={caseStatus}
                />:''}
                <Tooltip title="Filter">
                  <IconButton
                    className="filtericon"
                    disabled={data.length <= 0}
                    onClick={handlechildFilterClick}
                    
                  >
                    <FilterAltIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              
            </Grid>
          </Grid>
          
        {data.length>0?
          <Grid>
          <Grid style={{margin: "0px 10px",minHeight:'40vh'}}>
          <TableContainer>
            <Table className="casestable">
              <TableHead>
                <TableRow>
                  <TableCell>{title}</TableCell>
                  <TableCell>Creator</TableCell>
                  <TableCell>Case Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date Conducted</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
             {filteredRows.slice(startSubIndex, endSubIndex).map(row => (
     
     <TableRow key={row.SubCaseId}>
     <TableCell onClick={()=>{
       setselectedSubCase(filteredRows.filter(ele=>ele.SubCaseId==row.SubCaseId).find(element => true))
     }}
     style={{
       fontWeight:selectedSubCase === row.SubCaseId? 'bold !important' : '600',
       color: selectedSubCase === row.SubCaseId ? '#25A392' : '#52aa9d',
       
     }}
     //className={selectedSubCase === row.CaseId ?'selectedsubcase':'notselectedsubcase'}
     >{row?.caseTitle?row?.caseTitle:row.SubCaseId}</TableCell>
     <TableCell>{row.ConductedBy}</TableCell>
     <TableCell>{row.Type}</TableCell>
     <TableCell>{row.Status}</TableCell>
     <TableCell>{row.DateConducted}</TableCell>
     <TableCell>
       <Tooltip title="View a case">
         <IconButton
           className="Table_Button_visibility"
           
           //navigate(`/viewCase?case_id=${btoa(row.SubCaseId)}`)
          //  onClick={() =>{
          //    templateType === INSURANCE ?
          //    navigate(`/editcase?case_id=${btoa(row.SubCaseId)}&edit=${btoa(true)}&redirect=${btoa("dashboard")}`)
          //    : 
          //   navigate(`/editcase?case_id=${btoa(row.SubCaseId)}&edit=${btoa(false)}&redirect=${btoa("dashboard")}`)
             
          // }}
          onClick={() =>{
      
           // navigate(`/createcase?case_id=${btoa(row.SubCaseId)}&edit=${btoa(row?.label=="Insurance"?true:false)}&redirect=${btoa("dashboard")}&case_type=${btoa(row?.label)}&childcase=${btoa(true)}&parent_case=${btoa(false)}`)
             navigate(`/createcase?case_id=${btoa(row.SubCaseId)}&case_type=${btoa(row.Type)}&show=${btoa(true)}&parent_case=${btoa(false)}&edit=${btoa(row?.label=="Insurance"?true:false)}&iscreatecase=${btoa(false)}&templateType=${btoa(row?.label)}&ischild=${btoa(row?.label=='Insurance'?true:false)}`)
          //navigate(`/editcase?case_id=${btoa(row.SubCaseId)}&edit=${btoa(false)}&redirect=${btoa("dashboard")}`)
            
         }}
         >
           <Visibility className="eyeBtn" />
         </IconButton>
       </Tooltip></TableCell>
   </TableRow>
             ))
  
 }
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>
          <Grid style={{display:"flex",justifyContent:"center",alignItems:"center",marginBottom:'10px'}}>
          <Grid item>
                <Pagination
                  count={Math.ceil(filteredRows.length / itemsPerPage)}
                  page={currentSubPage}
                  onChange={handleSubPageChange}
                  variant="outlined"
                  color="primary"
                  sx={{
                    marginTop: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                />
                
              </Grid>
       <Button
             className="viewallbtn"
              onClick={() => navigate(`/allsubcases?parentcaseid=${btoa(selectedCaseID)}`)
            }
          style={{marginTop:'7px'}}
            >
              View All
            </Button>
            </Grid>
       </Grid>
       :
       <Grid style={{ paddingTop: "10px", margin: "0px 10px 10px 10px" }}>
              <Grid className="rejectbox" >
                <InfoIcon className="rejectHeading" style={{ fontSize: '32px', marginRight: '10px' }}></InfoIcon>
                <Grid>
                  <Typography className="rejecttxt">No Data</Typography>
                </Grid>
              </Grid>
            </Grid>
        }
     
      </Grid>
    </>
)
}


export default withSnackbar(ChildCases, "ChildCases", "success");  
  
 