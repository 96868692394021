import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function EleventhPage(props) {
  const [Questionset1, SetQuestionset1] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [Question1, setQuestion1] = useState();
  const [Answer, setAnswer] = useState();
  const [QuestionNumSet1, setQuestionNumSet1] = useState();

  const ThData = (TableData) => {
    // get table column
    const column = Object.keys(TableData[0]);
    //console.log(column);
    return column.map((data) => {
      if (data === "id" || data === "isNew") {
      } else {
        return <th key={data}>{data}</th>;
      }
    });
  };

  // get table row data
  const tdData = (TableData) => {
    // get table column
    const column = Object.keys(TableData[0]);
    // console.log(column);
    return TableData.map((data) => {
      return (
        <tr>
          {column.map((v) => {
            if (v === "id" || v === "isNew") {
            } else {
              return <td>{data[v]}</td>;
            }
          })}
        </tr>
      );
    });
  };

  useEffect(() => {
    const FetchData = () => {
      //getting section 1b question in single array
      const questions_array_a = [];
      const Answer_array_a = [];
      const QuestionNumSet1 = [];
      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Section 2b-Item D"]).length;
        i++
      ) {
        questions_array_a.push(
          Object.keys(props.data.reportData["Section 2b-Item D"])[i]
        );
        Answer_array_a.push(
          Object.values(props.data.reportData["Section 2b-Item D"])[i].answer
        );
        QuestionNumSet1.push(
          Object.values(props.data.reportData["Section 2b-Item D"])[
            i
          ].qno.substr(
            5,
            Object.values(props.data.reportData["Section 2b-Item D"])[i].qno
              .length
          )
        );
      }
      //console.log(questions_array);
      SetQuestionset1(questions_array_a);
      setAnswer(Answer_array_a);
     // console.log(Answer_array_a);
      setQuestionNumSet1(QuestionNumSet1);
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <React.Fragment>
      {Isloading && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
                <li>Section 1a</li>
                <li>Section 1b</li>
                <li>Section 1c</li>
                <li>Section 2a</li>
                <li style={{ color: "Black", fontSize: "20px" }}>
                  <b>Section 2b</b>
                </li>
                <li>Section 3</li>
                <li>Section 4</li>
                <li>Section 5</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={10}>
              {/* ------Item D question and answers -----------*/}
              <div>
                {Questionset1.map((x, index) => {
                  return (
                    <React.Fragment key={index}>
                      <b>{QuestionNumSet1[index]}</b>
                      {"\u00A0"}
                      {"\u00A0"}
                      {x}
                      <br />
                      {/* {Answer[index]} */}
                      {index === 1 || index === 2 || index === 3 || index === 5 || index === 6 ? (
                        <table style={{ border: "1px solid black" }}>
                          <thead>
                            <tr style={{ border: "1px solid black" }}>
                              {ThData(Answer[index])}
                            </tr>
                          </thead>
                          <tbody>{tdData(Answer[index])}</tbody>
                        </table>
                      ) : index === 4 ? (
                        <h6>{Answer[index].Symbol.label}{Answer[index].Amount}</h6>
                      ) : (
                        <h6
                          dangerouslySetInnerHTML={{ __html: Answer[index] }}
                        ></h6>
                      )}
                      <br />
                    </React.Fragment>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default EleventhPage;

