import React, { useEffect, useState } from "react";
import { Modal, Backdrop, Fade, Typography } from "@mui/material";
import Typist from "react-typist";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { bard_oai } from "../services/FormCreationFetch"


function AIResponse  ({ open, onClose,Question }) {

    const [bardText,SetbardText]=useState('');
    const [gptText,setgptText]=useState('');

    useEffect(()=>{

   GetAPIResponse();

    },[])

    const GetAPIResponse=async()=>{
        console.log(Question);
    const data= await bard_oai(Question);
    console.log(data);
    SetbardText(JSON.stringify(data.bard_response));
    setgptText(JSON.stringify(data.oai_response));
    }
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            height: 600,
            backgroundColor: "white",
            color: "black",
            padding: 20,
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ alignSelf: 'flex-end', marginBottom: -10 }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <Typography
            variant="h5"
            style={{
              marginBottom: 20,
              fontFamily: "Microsoft YaHei",
              textAlign: "center",
            }}
            gutterBottom
          >
            AI Response
          </Typography>

          <div
            style={{
              width: "100%",
              backgroundColor: "#3c3c50",
              color: "white",
              padding: 10,
              borderRadius: 8,
              overflowY: "auto",
              fontFamily: "Microsoft YaHei",
              flex: 1,
            }}
          >
            {(bardText?.length > 5) ? (
              <div>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "Microsoft YaHei",
                    color: "white",
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  Bard Response
                </Typography>
                <Typist avgTypingDelay={0}>{bardText && bardText}</Typist>
              </div>
            ) : ''}
            {(gptText?.length > 5) ? (
              <div>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "Microsoft YaHei",
                    color: "white",
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  GPT Response
                </Typography>
                <Typist avgTypingDelay={10}>{gptText && gptText}</Typist>
              </div>
            ) : ''}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AIResponse;
