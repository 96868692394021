import React, { useState, useEffect, useContext } from "react";
import "../styles/Home/ESGHome.css";
import { Box, Grid, Typography, Button } from "@mui/material";
import ESGDashBoard from "../components/ESGDashboard";
import ESGDisclosures from "../components/ESGDisclosures";
import ESGEvents from "../components/ESGEvents";
import ESGGraphDashBoard from "../components/ESGGraphDashBoard";
import { useNavigate } from "react-router-dom";
import { getGriDashboard } from "../../ESG/services/Fetch";
import AddDisclosureModal from "Utilities/FormCreation/AddDisclosureModal";
import NewFilling from "components/NewFilling";
import Illustration from "../../images/Illustration.png";
import PaperComponent from "components/PaperComponent";
import NoteContext from "context/PageContext";
import CreateFilling from "Insurance/Pages/CreateFilling";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function ESGHome() {
  const contextData = useContext(NoteContext);
  const navigate = useNavigate();
  const [ESGFilingsData, setESGFilingsData] = useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const permittedPath = localStorage.getItem("permittedPath");

  const userData = JSON.parse(localStorage.getItem("AccountDetails"));

  const getDashboardData = async () => {
    try {
      const data = await getGriDashboard();
      // console.log(data.response.request.status);
      console.log(data);
      const err = data?.status;

      if (err === 200) {
        //console.log(JSON.parse(data.request.response).analytics.disclosures.length);
        if (
          JSON.parse(data.request.response).analytics.disclosures.length > 0
        ) {
          setESGFilingsData(data.data?.analytics);
        } else {
          setESGFilingsData("nocode");
        }
      } else {
        setESGFilingsData("nocode");
      }
    } catch (error) {
      console.error("error:", error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [contextData.UserSelected]);

  const HandleNewFillings = async () => {
    setOpenDialog(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F6",
        minHeight: "100vh",
        padding: "10px",
      }}
    >
      <Grid container justifyContent="space-between" sx={{ marginTop: "10px" }}>
        <Grid item mb={2}>
          <Typography className="Title_Name">
            {
              ((userData.endUser.mail).split(".")[0].toLowerCase() == "ankurjit") ? null : (

                <ArrowBackIosIcon
                  style={{
                    marginRight: "0.2em",
                    cursor: "pointer",
                    // display: isMobile && !isTreeVisible ? "none" : "block"
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
              )
            }
            {permittedPath}</Typography>
        </Grid>
        <Grid item>
          {permittedPath === "Insurance" ? (
            <Grid container flexDirection={"row"} spacing={1}>
              <Grid item>
                <CreateFilling />
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => { navigate('/NewCreatedTemplate') }} sx={{
                  backgroundColor: "#25A392",
                  '&:hover': {
                    backgroundColor: "#25A392",
                  }
                }} >Template Management</Button>
              </Grid>
              <Grid item>
                {
                  ((userData?.endUser?.mail)?.split(".")[0].toLowerCase() == "ankurjit")?(
                    <Button variant="contained" onClick={() => { navigate('/caseManagement') }} sx={{
                      backgroundColor: "#25A392",
                      '&:hover': {
                        backgroundColor: "#25A392",
                      }
                    }} >Case Management</Button>
                  ):null
                }
              </Grid>
            </Grid>
          ) : (
            <React.Fragment>
              <NewFilling
                handleClickOpen={HandleNewFillings}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
              />
              <Button
                className="NewFilingButton"
                onClick={() => navigate("/GoalSettingPage")}
              >
                Goal Setting
              </Button>
            </React.Fragment>
          )}
        </Grid>
      </Grid>

      {ESGFilingsData !== "nocode" && (
        <>
          <br />
          <ESGDashBoard
            Total={ESGFilingsData?.total}
            Completed={ESGFilingsData?.completed}
            Pending={ESGFilingsData?.pending}
          />
          <br />
        </>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          {ESGFilingsData !== "nocode" ? (
            <ESGDisclosures ESGDisclosures={ESGFilingsData?.disclosures} />
          ) : (
            <PaperComponent>
              <Box minHeight={750} sx={{ padding: "15px" }}>
                <Box sx={{ marginTop: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "25px",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    Welcome {"user"}
                  </Typography>
                  {
                    permittedPath !== "Insurance" ? (
                      <Typography sx={{ fontSize: "16px", color: "#061826" }}>
                        Explore The Endless Possibilities With Us. Start With New
                        Filling Or Goal Setting
                      </Typography>
                    ) : (
                      <Typography sx={{ fontSize: "16px", color: "#061826" }}>
                        Explore The Endless Possibilities With Us. Start With New
                        Insurance form
                      </Typography>
                    )


                  }

                  <br />
                </Box>
                <img
                  src={Illustration}
                  style={{ width: "100%", height: "100%", marginTop: "30px" }}
                  alt="No Filings Found"
                />
              </Box>
            </PaperComponent>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <ESGEvents />
        </Grid>
      </Grid>
      <br />
      {permittedPath === "Insurance" ? null : <ESGGraphDashBoard />}
    </Box>
  );
}

export default ESGHome;
