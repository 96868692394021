import React, { useState, useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Button, Grid, Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { SectionNewQuestion } from "./NewQuestions";
import {
  addSubSection,
  FetchGetData,
  fetchEditIcon,
  fetchquestion,
  DeleteSection,
} from "../services/FormCreationFetch";
import NoteContext from "context/PageContext";
import EditSection from "./EditSection";
import Modal from "components/Modal";

const NewSubSection = (props) => {
  const contextData = useContext(NoteContext);
  let session_values = JSON.parse(sessionStorage.getItem("section"));
  const [formValues, setFormValues] = useState([{ session_values }]);
  // eslint-disable-next-line no-unused-vars
  const [subSections, setSubSections] = useState([]);
  const [subSectionName, setSubSectionName] = useState();
  const [subSectionId, setSubSectionId] = useState();
  const [subSectionDescription, setSubsectionDescription] = useState();
  const [subSectionHelpertext, setSubSectionHelpertext] = useState();
  const [sectionName, setSectionName] = useState();
  const [sectionId, setSectionId] = useState();
  const [sectionDescription, setSectionDescription] = useState("");
  const [sectionHelpertext, setSectionHelpertext] = useState("");
  const [editIcon, setIconEDit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setopenDeleteModal] = React.useState(false);
  // const copyItem = formValues
  const [showMyComponent, setShowMyComponent] = useState(false);
  const [Error, setError] = useState();

  let disclosurename = contextData.disclosureName;

  const fetchEditData = async () => {
    await FetchGetData(disclosurename).then((data) => {
      // console.log("Data", data._items)
      // setFormValues(copyItem)
    });
  };

  const handleEditIcon = () => {
    //   fetchEditData()
    //   setIconEDit(true)
    // console.log(showMyComponent);
    setShowMyComponent(true);
    // console.log(showMyComponent);
    //console.log(props.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIconEDit(false);
    //console.log("Cancel button is hitting")
  };

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleAddSubSection = () => {
    // console.log(props.id);
    // const dataFromLocalStorage = JSON.parse(localStorage.getItem("myDisclosure"));
    const dataFromLocalStorage = "";
    // const disclosureId = ((dataFromLocalStorage[0].title));
    // const disclosureId = 'DisclosureTestNew9';
    let disclosureId;
    if (contextData.disclosureName) {
      disclosureId = contextData.disclosureName;
    } else {
      disclosureId = JSON.parse(localStorage.getItem("disclosurename"));
    }

    const sectionId = props.id;
    let demoData = dataFromLocalStorage
      ? dataFromLocalStorage
      : { title: disclosureId, sections: [] };
    const newSubSection = {
      title: subSectionName,
      sectionId: subSectionId,
      questions: [],
    };
    // console.log(demoData.sections);
    // eslint-disable-next-line array-callback-return
    demoData.sections.map((data) => {
      if (data.sectionId === props.id) {
        //   console.log(data.subsections);
        data.subsections.push(newSubSection);
      }
    });
    // console.log(demoData);
    // localStorage.setItem("myDisclosure", JSON.stringify(demoData));
    console.log(  disclosureId,
      sectionId,
      subSectionName,
      subSectionDescription,
      subSectionId,
      subSectionHelpertext)
    setSubSections(demoData);
    // addSubSection(
    //   disclosureId,
    //   sectionId,
    //   subSectionName,
    //   subSectionDescription,
    //   subSectionHelpertext
    // ).then((res) => {
    //   if (res?.message) {
    //     setError(res.message);
    //   } else {
    //     props.handleClose();
    //     contextData.setReloadData(true);
    //   }
    // });
  };

  // eslint-disable-next-line no-unused-vars
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // console.log("################", (formValues));
  //   sessionStorage.setItem("section", JSON.stringify(formValues));
  //   // console.log(sessionStorage.getItem("section"))
  //   // if (!Form.error)
  //   //   window.location.replace("./RegistrationResult");
  //   // Send data to the backend via POST
  //   /*DEEPAK - Uncomment after server side code is implemented */
  //   /* fetch("http://localhost:5050/register", {  // Enter your IP address here
  //       method: 'POST',
  //       mode: 'cors',
  //       href: '/RegistrationResult',
  //       body: JSON.stringify(formValues) // body data type must match "Content-Type" header
  //       }) */
  // };
  // const handleSave = async () => {
  //   // console.log("page is hitting")
  //   await fetchEditIcon(disclosurename, formValues).then((data) => {
  //     //   console.log(data)
  //     //setExisting_disclosure(data._items[0]);
  //   });
  // };

  const fetchques = async (id) => {
    await fetchquestion(id).then(async (data) => {
      data._items.map((data) => {
        if (data.label === "name") {
          setSectionName(data.value);
        }
        if (data.label === "HelpText") {
          setSectionHelpertext(data.value);
        }
        if (data.label === "Description") {
          setSectionDescription(data.value);
        }
        if (data.label === "pk") {
          setSectionId(data.id.split("|")[0]);
        }
      });
    });
  };
  useEffect(() => {
    fetchques(props.id);
  }, []);

  const HandleDeleteSection = async (id) => {
    await DeleteSection(id).then(() => {
      contextData.setReloadData(true);
    });
  };

  return (
    <div>
      {props.condtion[0].editButton && (
        <Tooltip title="Add SubSection">
          <IconButton
            size="small"
            id="add"
            aria-label="Add new SubSection"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClickOpen}
            color="inherit"
          >
            <NoteAddIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </Tooltip>
      )}
      {/* <Tooltip title="Add Question"> */}
      {props.condtion[0].editButton && (
        <IconButton
          size="small"
          id="add"
          aria-label="Add new Section"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          // onClick={}
          color="inherit"
        >
          <SectionNewQuestion id={props.id} handleClose={handleClose} condtion={props.condtion} />
          {/* <QuestionAnswerIcon sx={{ fontSize: '18px' }} /> */}
        </IconButton>
      )}
      {/* </Tooltip> */}
      {props.condtion[0].editButton && (
        <IconButton>
          <EditIcon sx={{ fontSize: "18px" }} onClick={handleEditIcon} />
        </IconButton>
      )}
      {props.condtion[0].delete && (
        <IconButton onClick={() => setopenDeleteModal(true)}>
          <DeleteIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      )}
      <Modal
        open={openDeleteModal}
        setOpen={setopenDeleteModal}
        title={"Are you sure? You want to delete this?"}
        actions={
          <>
            <Button
              sx={{
                textTransform: "capitalize",
                color: "white",
                backgroundColor: "#25A392",
                "&:hover": {
                  backgroundColor: " #25a392",
                },
              }}
              onClick={() => HandleDeleteSection(props.id)}
            >
              Delete
            </Button>
          </>
        }
        maxWidth={"xs"}
      ></Modal>
      {/* <IconButton><DisplaySettingsIcon sx={{ fontSize: '18px' }} /></IconButton> */}
      <Dialog open={open || editIcon} onClose={handleClose}>
        <DialogTitle>
          {editIcon ? "Edit the Subsection" : "Create new Subsection"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="subsectionname-input"
                name="subsectionname"
                label="SubSection Name"
                type="text"
                value={formValues.subSectionName}
                onChange={(e) => setSubSectionName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="subsectionid-input"
                name="subsectionid"
                label="subsection id"
                type="text"
                required
                error={Error}
                helperText={Error}
                value={formValues.subSectionId}
                onChange={(e) => {
                  Error && setError(null);
                  setSubSectionId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="subsectiondescription-input"
                name="subsectiondescription"
                label="subsection description"
                type="text"
                required
                value={formValues.subSectionDescription}
                onChange={(e) => setSubsectionDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="subsectionhelpertext-input"
                name="subsectionhelpertext"
                label="subsection helpertext"
                type="text"
                required
                value={formValues.subSectionHelpertext}
                onChange={(e) => setSubSectionHelpertext(e.target.value)}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={editIcon?handleSave:handleAddSubSection}>{editIcon?"Save":"Create"}</Button>
                </DialogActions> */}
        <DialogActions style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25a392",
              marginLeft: "10px",
              flexDirection: "row",
            }}
            onClick={handleAddSubSection}
          >
            Create
          </Button>
          <Button
            variant="text"
            style={{ color: "red", flexDirection: "row" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {showMyComponent && (
        <EditSection
          open={true}
          secName={sectionName}
          secId={sectionId}
          secDis={sectionDescription}
          secHelp={sectionHelpertext}
          handleClose={props.handleClose}
        />
      )}
    </div>
  );
};

export default NewSubSection;
