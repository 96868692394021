import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import withSnackbar from "../../components/Alert/withSnackbar"; 


const SectionButtons=({list,sectionType,setSectionType,showSnackbar})=>{
    const handleSectionChange = (event, sectionname) => {
        if (sectionname !== null && sectionType !== sectionname) {
          setSectionType(sectionname);
    
        }
      };
return(
    <>
  <ToggleButtonGroup
        className="toggleButtons"
        sx={{
          ".MuiToggleButtonGroup-grouped": {
            padding: "8px 18px",
            margin:"20px 0px 10px 0px",
            fontSize: "14px",
          },
        }}
        value={sectionType}
        exclusive
        onChange={handleSectionChange}
        size="small"
      >
        {list.map((item) => (
          <ToggleButton key={item.value} value={item.value}>
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

    </>
)
}


export default withSnackbar(SectionButtons, "SectionButtons", "success");