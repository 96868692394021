import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";



const Parentalleave = (props)  =>{
   const [operational,setoperational] = useState();
   const[negotiation,setnegotiation]=useState();
    const [headerTable, setheaderTable] = useState();
    const [pageloaded, setpageloaded] = useState(false)
    
    const CreateHeader = () => {
      const column = Object.keys(headerTable[0]);
      const newcolumn = column.map((data) => {
        if (data !== "id" && data !== "isNew") {
          return <TableCell key={data}>{data}</TableCell>;
        }
        return null;
      });
  
     
      return newcolumn;
    };
  
    // get table row data
    const tdData = () => {
      return headerTable.map((data,index) => {
        return (
          <TableBody key={index}>
            <TableRow
              key={data}
              sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
            >
              {Object.keys(headerTable[0]).map((x,index1) => {
                if (x !== "id" && x !== "isNew") {
                  return (
                    <TableCell key={index1} component="th" scope="row">
                      {data[x]}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableBody>
        );
      });
    };


    useEffect (()=>{ 
       setoperational((Object.values(props.data.reportData["GRI 402.1 Minimum notice periods regarding operational changes"])[0]).answer)
       setnegotiation((Object.values(props.data.reportData["GRI 402.1 Minimum notice periods regarding operational changes"])[1]).answer)
         setheaderTable(
          Object.values(props.data.reportData["GRI 401.3 Parental leave"])[0].answer
        );
       
        setpageloaded(true);
    },[pageloaded,props.data.reportData])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={10}  className="grid2">
                    <h5 className="underline">Parental leave</h5>
                        <h6><b>Provide the total number of employees that were entitled to parental leave, employees that took parental leave, employees that returned to work in the reporting period after parental leave ended, and employees that returned to work 
                            after parental leave ended that were still employed 12 months after their, by gender</b></h6>
              <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750, width: "80%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable && CreateHeader()}
                  </TableRow>
                </TableHead>

                {headerTable && tdData()}
              </Table>
            </TableContainer>
          </div>
          <h5 className="underline"> Minimum notice periods regarding operational changes</h5>
                        <h6><b>Provide the minimum number of weeks notice provided to employees and their representatives before the implementation of
                             significant operational changes that could substantially affect them.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:operational}}></div>
                        
                        <h6><b>Report whether the notice period and provisions for consultation and negotiation are specified in collective bargaining agreements.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:negotiation}}></div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default Parentalleave;