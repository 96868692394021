// DynamicTable.jsx
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import SearchIcon from '@mui/icons-material/Search';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import "../../Compliance/styles/TradeReport/TradeReport.css";
const DynamicTable = ({ data, onRowClick }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100));
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = data.filter((row) => {
    return Object.values(row).some(
      (value) =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const tableKeys = data && data.length > 0 ? Object.keys(data[0]) : [];
  return (
    <div className="tableContainer">
      <TextField
      label="Search"
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={handleSearch}
      className="searchField"
      InputProps={{
        endAdornment: (
          <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        ),
      }}
    />
      {/* <TableContainer component={Paper} className="card"> */}
        <Table>
          <TableHead>
            <TableRow>
            {tableKeys.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex} onClick={() => onRowClick(row)}>
                  {Object.values(row).map((value, columnIndex) => (
                    <TableCell key={columnIndex}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      {/* </TableContainer> */}
      <Pagination
        variant="outlined"
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        sx={{
          marginTop: 1,
          alignSelf: 'center',
          justifySelf: 'center',
        }}
      />
    </div>
  );
};

export default DynamicTable;
