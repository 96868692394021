import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Typography, IconButton } from "@mui/material";
import { CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from "@mui/material/styles";
import "../styles/Real_Time_Data/Real_Time_Data.css";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import { useContext } from "react";
import NoteContext from "context/PageContext";
// import ObjectViewer from "./Product";
function getObjectKeysAndValues(obj, prefix = '') {
  const keysAndValues = [];

  for (const key in obj) {
    if (key === 'details') {
      let value

      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          value =  {}
        } else if (obj[key] === "{}"&&obj[key].length>1 ) {
          value = JSON.parse(obj[key])
        } else {
          value = (JSON.parse(obj[key].length > 0) ? JSON.parse(obj[key]) : obj[key])
        }
        // const value = (JSON.parse(obj[key].length>0)?JSON.parse(obj[key]):obj[key]) ;
        const newPrefix = prefix ? `${key}` : key;

        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }

    else {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        // const newPrefix = prefix ? `${prefix}-->${key}` : key;
        const newPrefix = prefix ? `${prefix}->${key}` : key;
        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }
  }
  return keysAndValues;
}

function ObjectViewer(props) {
  const { data } = props;

  const keysAndValues = getObjectKeysAndValues(data);

  return (
    <Grid>
      {keysAndValues.map(({ key, value }) => (
        <Typography  gutterBottom key={key}>
          <strong>{key}: </strong>
          {value}
        </Typography>
      ))}
    </Grid>
  );
}
const columns = [
  { id: 'name', label: 'Issuer LEI', minWidth: '50px', align: 'left', },
  { id: 'FullName', label: 'Full name', minWidth: '50px', align: 'left', },
  { id: 'ShortName', label: 'Short name', minWidth: '50px', align: 'left', },
  { id: 'currancy', label: 'Notional currency', minWidth: '50px', align: 'left', },
];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

 function Trading_Table({ Search,Refresh,showSnackbar}) {
  const ContextData = useContext(NoteContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [TotalPage, setTotalPage] = useState(0);
  const [Instruments, setInstruments] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [tempData, setTempData] = useState();
  const [UpdateCompanyDetails, setUpdateCompanyDetails] = useState();
  const { GetInstruments,GetExecutingvenue } = require("../../ESG/services/Fetch");
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  const GetInstrumentsdetails = async () => {
    try {
      showSnackbar("Loading data, please wait...", "info");
      const data = await GetInstruments(page, rowsPerPage);
      if (data?.finInstrumnents) {
        setTempData(data.finInstrumnents);
        setTotalPage(data.rows);
        showSnackbar("Data loaded successfully", "success");
        if (Instruments.length > 0) {
          // ... your logic to filter out duplicates here
          setInstruments([...Instruments, ...tempData]);
          showSnackbar("Data loaded successfully", "success");
        } else {
          setInstruments(data.finInstrumnents);
        }
        showSnackbar("Data loaded successfully", "success");
      } else {
        console.error("Data is missing or invalid.");
        showSnackbar("Error loading data. Please try again.", "error")
      }
    } catch (error) {
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true
      });
      showSnackbar("Error loading data. Please try again.", "error")
      console.error("An error occurred:", error);
    }
  };
  
  useEffect(() => {
    GetInstrumentsdetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, Refresh]);

  

  // handle Company Details in Card
  const handleUpdate = (row, i) => {
    setUpdateCompanyDetails(row)
    setSelectedRowIndex(i)
  }

  //pagination for table handle change page 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

  };
  //pagination for table handle Change Rows Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Grid container spacing={0} >
      <Grid item xs={8}>
        <TableContainer className="TableContainer">
          <Table stickyHeader aria-label="sticky table" >
            <TableHead >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="Table_Head"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Instruments && Instruments.filter((val) => {
                if (Search === "" || Search === null) {
                  return val;
                } else if (
                  val.fullname
                    .toLowerCase()
                    .includes(Search.trim().toLowerCase())
                ) {
                  return val;
                }
              }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                <TableRow
                style={{ backgroundColor: i === selectedRowIndex ? '#c4c4ff' : '' }}
                onClick={() => handleUpdate(row, i)}
                key={i}
                 className={i % 2 === 0 ? "TableRowOdd" : 'TableRowEven'}
                >
                  <TableCell component="th" scope="row" >
                    {row?.issuerlei ?? 'undefined'}
                  </TableCell>
                  <TableCell align="left">{row?.fullname ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.shortname ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.notionalcurrency ?? 'undefined'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={Math.floor(TotalPage)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <Grid item xs={4}>
        {UpdateCompanyDetails && UpdateCompanyDetails ? <>
          <Typography className="More_Details">{UpdateCompanyDetails.fullname}</Typography>
          {/* {CompanyDetails?.locations?.map((CompanyDetails, i) => ( */}
          <Card className="CompanyDetails_Card" >
            <CardContent className="CardContent">
              <ObjectViewer data={UpdateCompanyDetails} />
              {/* <Typography variant="h7" component="div">
                {UpdateCompanyDetails?.shortname ?? 'undefined'}<br></br>
                {UpdateCompanyDetails?.isin ?? 'undefined'}<br></br>
                {UpdateCompanyDetails?.issuerlei ?? 'undefined'}<br></br>
                {UpdateCompanyDetails?.tradingvenue ?? 'undefined'}<br></br>
                {UpdateCompanyDetails?.updateddt ?? 'undefined'}
              </Typography> */}
            </CardContent>
            {/* <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography className='DisclosureInfoCard_Social' variant="body2" >
                  BsisPt:{data.DerivInstrmAttrbts?.BsisPt ?? 'Undefined'}
                </Typography>
                <Typography className='DisclosureInfoCard_Social' variant="body2" >
                  DlvryTp:{data.DerivInstrmAttrbts?.DlvryTp ?? 'Undefined'}
                </Typography>
                <Typography className='DisclosureInfoCard_Social' variant="body2">
                  OptionType:{data.DerivInstrmAttrbts?.OptionType ?? 'Undefined'}
                </Typography>
                <Typography className='DisclosureInfoCard_Social' variant="body2" >
                  OptnExrcStyle:{data.DerivInstrmAttrbts?.OptnExrcStyle ?? 'Undefined'}
                </Typography>
                <Typography className='DisclosureInfoCard_Social' variant="body2" >
                  PricMltplr:{data.DerivInstrmAttrbts?.PricMltplr ?? 'Undefined'}
                </Typography>
                <Typography className='DisclosureInfoCard_Social' variant="body2" >
                  StrkPric:{data.DerivInstrmAttrbts?.StrkPric ?? 'Undefined'}
                </Typography>
              </CardContent>
            </Collapse> */}
            <br></br>

            {/* <ObjectViewer data={JSON.parse(UpdateCompanyDetails?.details)} /> */}
          </Card>
          {/* ))} */}
        </> : ''}
      </Grid>
    </Grid>
  );
}

export default withSnackbar(Trading_Table, "Trading_Table paage", "success");