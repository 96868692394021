// // import React, { useState, useEffect } from "react";
// // import { TextField, Button } from "@mui/material";
// // import {
// //   Table,
// //   TableBody,
// //   TableCell,
// //   TableHead,
// //   TableRow,
// //   Select,
// //   InputLabel,
// //   MenuItem,
// //   Dialog,
// //   DialogTitle,
// //   DialogContent,
// //   DialogActions,
// // } from "@mui/material";
// // import { IconButton } from "@mui/material";
// // import EditIcon from "@mui/icons-material/Edit";
// // import DeleteIcon from "@mui/icons-material/Delete";
// // import "../styles/GoalSetting/GoalsettingPage.css";

// // import {
// //   PutGoalSetting,
// //   GetGoalSettingValues,
// //   GetMaterialTopics,
// //   updateGoalSetting,
// //   DeleteGoalData,
// // } from "../services/GraphFetch";

// // const FormComponent = ({ onSubmit, editData, onClose }) => {
// //   const [formData, setFormData] = useState({
// //     id: "",
// //     topic: "",
// //     year1: "",
// //     year2: "",
// //     year3: "",
// //     year4: "",
// //     year5: "",
// //     date: "",
// //   });
// //   const [isloading, setisloading] = useState(false);
// //   const [material, setmaterial] = useState([]);

// //   useEffect(() => {
// //     if (editData) {
// //       setFormData(editData);
// //     }
// //     fetechMatericalTopic();
// //     // eslint-disable-next-line react-hooks/exhaustive-deps
// //   }, [editData]);

// //   const fetechMatericalTopic = async () => {
// //     try {
// //       const Response = await GetMaterialTopics();
// //       setmaterial(Response);
// //       setisloading(true);
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     const date = new Date();
// //     formData.date =
// //       date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

// //     if (editData) {
// //       try {
// //         await updateGoalSetting(formData);
// //       } catch (error) {
// //         console.log(error);
// //       }
// //     } else {
// //       try {
// //         await PutGoalSetting(
// //           formData.topic,
// //           formData.id,
// //           formData.date,
// //           formData.year1,
// //           formData.year2,
// //           formData.year3,
// //           formData.year4,
// //           formData.year5
// //         );
// //       } catch (error) {
// //         console.log(error);
// //       }
// //     }

// //     onSubmit(formData);

// //     // Clear the form fields after submission
// //     setFormData({
// //       id: "",
// //       topic: "",
// //       year1: "",
// //       year2: "",
// //       year3: "",
// //       year4: "",
// //       year5: "",
// //       date: "",
// //     });

// //     onClose(); // Close the form after submission
// //   };

// //   return (
// //     <Dialog open={true} onClose={onClose}>
// //       <DialogTitle>{editData ? "Edit Data" : "Create Data"}</DialogTitle>
// //       <DialogContent>
// //         {isloading && (
// //           <form onSubmit={handleSubmit}>
// //             <TextField
// //               label="ID"
// //               name="id"
// //               value={formData.id}
// //               onChange={handleChange}
// //               fullWidth
// //               margin="normal"
// //             />
// //             <InputLabel id="topic-label">Select a Topic</InputLabel>
// //             <Select
// //               labelId="topic-label"
// //               name="topic"
// //               value={formData.topic}
// //               onChange={handleChange}
// //               label="Select a Topic"
// //               fullWidth
// //               margin="normal"
// //             >
// //               {material.map((value, index) => (
// //                 <MenuItem key={index} value={value}>
// //                   {value}
// //                 </MenuItem>
// //               ))}
// //             </Select>
// //             <TextField
// //               label="Year 1"
// //               name="year1"
// //               value={formData.year1}
// //               onChange={handleChange}
// //               fullWidth
// //               margin="normal"
// //             />
// //             <TextField
// //               label="Year 2"
// //               name="year2"
// //               value={formData.year2}
// //               onChange={handleChange}
// //               fullWidth
// //               margin="normal"
// //             />
// //             <TextField
// //               label="Year 3"
// //               name="year3"
// //               value={formData.year3}
// //               onChange={handleChange}
// //               fullWidth
// //               margin="normal"
// //             />
// //             <TextField
// //               label="Year 4"
// //               name="year4"
// //               value={formData.year4}
// //               onChange={handleChange}
// //               fullWidth
// //               margin="normal"
// //             />
// //             <TextField
// //               label="Year 5"
// //               name="year5"
// //               value={formData.year5}
// //               onChange={handleChange}
// //               fullWidth
// //               margin="normal"
// //             />
// //             <DialogActions>
// //               <Button type="submit" variant="contained" color="primary">
// //                 {editData ? "Update" : "Submit"}
// //               </Button>
// //               <Button onClick={onClose} variant="contained" color="secondary">
// //                 Cancel
// //               </Button>
// //             </DialogActions>
// //           </form>
// //         )}
// //       </DialogContent>
// //     </Dialog>
// //   );
// // };

// // const TableComponent = ({ data, onDelete, onEdit }) => {
// //   return (
// //     <div className="table-container">
// //       <Table className="table">
// //         <TableHead>
// //           <TableRow>
// //             <TableCell>ID</TableCell>
// //             <TableCell>Topic</TableCell>
// //             <TableCell>Year 1</TableCell>
// //             <TableCell>Year 2</TableCell>
// //             <TableCell>Year 3</TableCell>
// //             <TableCell>Year 4</TableCell>
// //             <TableCell>Year 5</TableCell>
// //             <TableCell>Action</TableCell>
// //           </TableRow>
// //         </TableHead>
// //         <TableBody>
// //           {data.map((item, index) => (
// //             <TableRow key={index}>
// //               <TableCell>{item.GoalId}</TableCell>
// //               <TableCell>{item.MaterialTopic}</TableCell>
// //               <TableCell>{item.y2023}</TableCell>
// //               <TableCell>{item.y2024}</TableCell>
// //               <TableCell>{item.y2025}</TableCell>
// //               <TableCell>{item.y2026}</TableCell>
// //               <TableCell>{item.y2027}</TableCell>
// //               <TableCell>
// //                 <IconButton
// //                   onClick={() => onEdit(item)} // Pass the entire data object
// //                   color="primary"
// //                   aria-label="edit"
// //                 >
// //                   <EditIcon className="edit-button" />
// //                 </IconButton>
// //                 <IconButton
// //                   onClick={() => onDelete(index)}
// //                   color="secondary"
// //                   aria-label="delete"
// //                 >
// //                   <DeleteIcon className="delete-button" />
// //                 </IconButton>
// //               </TableCell>
// //             </TableRow>
// //           ))}
// //         </TableBody>
// //       </Table>
// //     </div>
// //   );
// // };

// // const GoalSetting = () => {
// //   const [tableData, setTableData] = useState([]);
// //   const [editData, setEditData] = useState(null);
// //   const [isloaded, setisloaded] = useState(false);
// //   const [isFormOpen, setIsFormOpen] = useState(false);

// //   useEffect(() => {
// //     fetchData();
// //     // eslint-disable-next-line react-hooks/exhaustive-deps
// //   }, []);

// //   const fetchData = async () => {
// //     try {
// //       const response = await GetGoalSettingValues();
// //       setTableData(response.data.values);
// //       setisloaded(true);
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   };

// //   const handleFormSubmit = async (formData) => {
// //     if (editData !== null) {
// //       try {
// //         // console.log(formData);
// //         await updateGoalSetting(formData);
// //       } catch (error) {
// //         console.log(error);
// //       }
// //       setEditData(null);
// //     } else {
// //       setTableData([...tableData, formData]);
// //     }
// //     const delay = 5000; // 2 seconds (you can adjust this as needed)
// //     await new Promise((resolve) => setTimeout(resolve, delay));
// //     await fetchData();
// //   };

// //   const handleEdit = (data) => {
// //     const resposedata = {
// //       id: data.GoalId,
// //       topic: data.MaterialTopic,
// //       year1: data.y2023,
// //       year2: data.y2024,
// //       year3: data.y2025,
// //       year4: data.y2026,
// //       year5: data.y2027,
// //       date: data.date,
// //     };
// //     setEditData(resposedata);
// //     setIsFormOpen(true); // Open the form when editing
// //   };

// //   const handleDelete = async (index) => {
// //     try {
// //       const goalIdToDelete = tableData[index].GoalId;
// //       await deleteGoalSetting(goalIdToDelete);
// //       const updatedData = tableData.filter(
// //         (item) => item.GoalId !== goalIdToDelete
// //       );
// //       setTableData(updatedData);
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   };

// //   const deleteGoalSetting = async (goalId) => {
// //     try {
// //       await DeleteGoalData(goalId);
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   };

// //   const handleFormClose = () => {
// //     setIsFormOpen(false);
// //   };

// //   return (
// //     <div className="goal-setting-container">
// //       <br />

// //       <Button
// //         variant="contained"
// //         color="primary"
// //         onClick={() => setIsFormOpen(true)}
// //       >
// //         Create
// //       </Button>
// //       {isFormOpen && (
// //         <FormComponent
// //           onSubmit={handleFormSubmit}
// //           editData={editData}
// //           onClose={handleFormClose}
// //         />
// //       )}
// //       {isloaded && (
// //         <TableComponent
// //           data={tableData}
// //           onDelete={handleDelete}
// //           onEdit={handleEdit}
// //         />
// //       )}
// //     </div>
// //   );
// // };

// // export default GoalSetting;

// import * as React from "react";
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import { useNavigate } from "react-router-dom";
// import MaterialTopicTable from "./MaterialTopicTable";
// import MaterialTable from "./MaterialTable";
// import { AddCustOnBoard, GetCustomerOnBoard } from "ESG/services/Fetch";
// // import GanttChart from "./GanttChart";
// // import Products from "./Products";
// import Compliance from "./Compliance";
// const steps = ["Materiallity", "Materiallity plans", "Compliance"];

// export default function GoalSetting() {
//   const [activeStep, setActiveStep] = React.useState(0);
//   const [skipped, setSkipped] = React.useState(new Set());
//   const [isloading, setisloading] = React.useState(false);
//   const [formData, setFormData] = React.useState({
//     legalName: "",
//     parentOrganization: "",
//     cik: "",
//     sic: "",
//     typeOfCompany: "",
//     HQAddress: "",
//     incorporationDetail: "",
//     holding: "",
//   });
//   const navigate = useNavigate();

//   const isStepOptional = (step) => {
//     return step === 1; // Second step (Additional Questions) is optional
//   };

//   const isStepSkipped = (step) => {
//     return skipped.has(step);
//   };

//   React.useEffect(() => {
//     GetCustomerOnBoard().then((data) => {
//       //console.log(data);
//       const res = data.values[0];
//       if (data.values.length > 0) {
//         const response = {
//           legalName: res.LegalName,
//           parentOrganization: res.ParentOrganization,
//           cik: res.CIK,
//           sic: res.SIC,
//           typeOfCompany: res.TypeOfCompany,
//           HQAddress: JSON.parse(res.HQAddress),
//           incorporationDetail: res.IncorporationDetail,
//           holding: res.Holding,
//         };
//         setFormData(response);
//       }
//       setisloading(true);
//       // console.log(response);
//       // console.log(res.HQAddress);
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isloading]);

//   const handleNext = () => {
//     if (activeStep === 0) {
//       for (const key in formData) {
//         if (!formData[key]) {
//           alert(`Property "${key}" is empty!`);
//           return;
//         }
//       }
//       AddCustOnBoard(formData);
//       // console.log('submitted')
//     }
//     let newSkipped = skipped;
//     if (isStepSkipped(activeStep)) {
//       newSkipped = new Set(newSkipped.values());
//       newSkipped.delete(activeStep);
//     }

//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     setSkipped(newSkipped);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleSkip = () => {
//     if (!isStepOptional(activeStep)) {
//       throw new Error("You can't skip a step that isn't optional.");
//     }

//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     setSkipped((prevSkipped) => {
//       const newSkipped = new Set(prevSkipped.values());
//       newSkipped.add(activeStep);
//       return newSkipped;
//     });
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   const getStepContent = (step) => {
//     switch (step) {
//       case 0:
//         return <MaterialTopicTable />;
//       case 1:
//         return <MaterialTable />;
//       case 2:
//         return <Compliance />;

//       default:
//         return "Unknown step";
//     }
//   };

//   return (
//     <React.Fragment>
//       {isloading && (
//         <Box sx={{ width: "100%", marginTop: "65px" }}>
//           <Stepper activeStep={activeStep}>
//             {steps.map((label, index) => {
//               const stepProps = {};
//               const labelProps = {};
//               if (isStepSkipped(index)) {
//                 stepProps.completed = false;
//               }
//               return (
//                 <Step key={label} {...stepProps}>
//                   <StepLabel {...labelProps}>{label}</StepLabel>
//                 </Step>
//               );
//             })}
//           </Stepper>
//           <Box>
//             {activeStep === steps.length ? (
//               <React.Fragment>
//                 <Typography sx={{ mt: 2, mb: 1 }}>
//                   All steps completed - you&apos;re finished
//                 </Typography>
//                 <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
//                   <Box sx={{ flex: "1 1 auto" }} />
//                   <Button variant="contained" onClick={handleReset}>
//                     Reset
//                   </Button>
//                 </Box>
//               </React.Fragment>
//             ) : (
//               <React.Fragment>
//                 <Box sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Box>
//                 <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     disabled={activeStep === 0}
//                     onClick={handleBack}
//                     sx={{ mr: 1 }}
//                   >
//                     Back
//                   </Button>
//                   <Box sx={{ flex: "1 1 auto" }} />
//                   {isStepOptional(activeStep) && (
//                     <Button
//                       color="inherit"
//                       variant="contained"
//                       onClick={handleSkip}
//                       sx={{ mr: 1 }}
//                     >
//                       Skip
//                     </Button>
//                   )}
//                   <Button
//                     color="secondary"
//                     variant="contained"
//                     onClick={handleNext}
//                   >
//                     {activeStep === steps.length - 1 ? "Finish" : "Next"}
//                   </Button>
//                 </Box>
//               </React.Fragment>
//             )}
//           </Box>
//         </Box>
//       )}
//     </React.Fragment>
//   );
// }
import { Grid, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";
import MaterialTopicTable from "./MaterialTopicTable";
import Compliance from "./Compliance";
import MaterialTable from "./MaterialTable";
import PaperComponent from "components/PaperComponent";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router";

function GoalSettingPage() {
  const navigate =useNavigate();

  const [page, setpage] = useState("materiality");

  const handlecallpage = (pages) => {
    setpage(pages);
    
  };
  return (
    <Box sx={{ backgroundColor: "#F5F5F6" }}>
      <Grid container justifyContent="space-between" sx={{marginTop: "10px" }} px={2} py={1}>
        <Grid item>
          <Typography
            sx={{ fontSize: "20px", color: "#061826", fontWeight: "600" }}
          >
             <ArrowBackIosIcon
                  style={{
                    marginRight: "0.2em",
                    cursor: "pointer",
                    
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
            Goal Setting
          </Typography>
        </Grid>

        <Grid item>
          <Button
            size="small"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "white",
              color: "black",
              "&:hover": { backgroundColor: "white", color: "black" },
            }}
            variant="contained"
            startIcon={<TextSnippetIcon />}
          >
            Guidelines
          </Button>
        </Grid>
      </Grid>
      <Grid container px={2} py={1} spacing={2}>
        <Grid item md={2.5}>
          <Box
           
          >
            <List  sx={{borderRadius:'6px',backgroundColor:'white'}}>
              <ListItem >
                <ListItemButton disableTouchRipple
                  onClick={() => {
                    handlecallpage("materiality");
                  }}
                  selected={page==="materiality"}
                  className="ListItem"
                >
                  <ListItemText primary="Materiality" sx={{
                    ".MuiListItemText-primary": {
                      fontSize: "16px",
                      color: "#58646E",
                      paddingLeft:'5px'
                    },
                  }} />
                </ListItemButton>
              </ListItem>
              <ListItem >
                <ListItemButton disableTouchRipple
                  onClick={() => {
                    handlecallpage("Goals");
                  }}
                  selected={page==="Goals"}
                  className="ListItem"
                >
                  <ListItemText primary="Goals"  sx={{
                    ".MuiListItemText-primary": {
                      fontSize: "16px",
                      color: "#58646E",
                      paddingLeft:'5px'
                    },
                  }}/>
                </ListItemButton>
              </ListItem>
              <ListItem >
                <ListItemButton disableTouchRipple
                  onClick={() => {
                    handlecallpage("Supporting Projects");
                  }}
                  selected={page==="Supporting Projects"}
                  className="ListItem"
                >
                  <ListItemText primary="Supporting Projects"  sx={{
                    ".MuiListItemText-primary": {
                      fontSize: "16px",
                      color: "#58646E",
                      paddingLeft:'5px'
                    },
                  }}/>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item md={9.5}>
          {page === "materiality" ? (
            <PaperComponent>
              <MaterialTopicTable setpage={setpage} />
            </PaperComponent>
          ) : page === "Goals" ? (
            <PaperComponent>
              <MaterialTable setpage={setpage}/>
            </PaperComponent>
          ) : (
            <PaperComponent>
              <Compliance />
            </PaperComponent>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default GoalSettingPage;
