import React, { useEffect, useState, useContext, useRef } from "react";
import AddDisclosureModal from "./AddDisclosureModal";
import EditTitleModal from "./EditTitleModal";
import PublishModal from "./PublishModal";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import CloneDisclosureModal from "./CloneDisclosureModal"; // Import the new component
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Menu,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import FilterListIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishIcon from "@mui/icons-material/GetApp";
import { useNavigate } from "react-router-dom";

import {
  listNewDisclosures,
  companyDropdown,
  testing,
  getRolePermissions,
  GetNotification,
  CloneDisclosure,
  DeleteDisclosure
} from "../services/FormCreationFetch";
import NoteContext from "context/PageContext";
import { useRoleContext } from "context/RoleContex";


const NewCreatedDisclosure = () => {
  const navigate = useNavigate();
  const [disclosureList, setDisclosureList] = useState([]);
  const [view, setView] = useState("grid");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const contextData = useContext(NoteContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [editTitleOpen, setEditTitleOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [publishItemId, setPublishItemId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [cloneItem, setCloneItem] = useState(null);

  const currentItemRef = useRef(null);

  const [utilityPermission, setUtilityPermission] = useState({})
  const {
    selectedRole,
    updatePublishTriggered,
    editDisclosureTitleTrigered,
    updateEditDisclosureTitleTrigered,
    addDisclosureTriggered,
    updateAddDisclosureTriggered,
    editApiTriggered,
    updateEditApiTriggered,
    publishApiTriggered,
    updatePublishApiTriggered,
    unpublishApiTriggered,
    updateUnpublishApiTriggered
  } = useRoleContext();


  const [adminRole, setAdminRole] = useState(false);


  useEffect(() => {
    InitialRender()
  }, []);

  const InitialRender = () => {
    const fetchListNewDisclosures = async () => {
      try {
        const response = await listNewDisclosures("jktech.com");
        setDisclosureList(response.values);
      } catch (error) {
        console.error("Error fetching disclosures:", error);
      }
    };

    const fetchListOfCompany = async () => {
      const data = await companyDropdown();
      setCompanies(data.data);
    };

    fetchListOfCompany();
    fetchListNewDisclosures();
    if (selectedRole === "Filing-Administrator") {
      setAdminRole(true);
    }
  }


  useEffect(() => {
    if (selectedRole) {
      GetRolePermissionAPi(selectedRole)
      if (localStorage.getItem("AccountDetails")) {
        const userData = JSON.parse(localStorage.getItem("AccountDetails"));
        if (userData?.filingGroups) {
          const selectedGroup = userData?.filingGroups.filter(data => { return data.displayName == selectedRole })[0];
          console.log(selectedGroup, "selectedGroup");
          getNotificationApi(selectedGroup.id)
        }

      }
    }
    setAdminRole(false);

    if (selectedRole == "Filing-Administrator") {
      setAdminRole(true);
    }
  }, [selectedRole])

  useEffect(() => {
    if (editApiTriggered || publishApiTriggered || unpublishApiTriggered) {
      InitialRender()
    }
  }, [editApiTriggered, publishApiTriggered, unpublishApiTriggered]);

  const GetRolePermissionAPi = async () => {
    try {
      const res = await getRolePermissions(selectedRole)
      setUtilityPermission(res?.utility[0]);
    } catch (error) {
      console.log(error);
    }

  }

  const getNotificationApi = async (id) => {
    const res = await GetNotification(id)
    console.log("res56", res);
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMenuClick = (event, item) => {
    console.log("Clicked Item:", item);
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
    setMenuItem(item);
    currentItemRef.current = item;
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuItem(null);
  };

  const handleFilterMenuClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
    handleFilterMenuClose();
  };

  const handleActionClick = (action) => {
    if (menuItem) {
      console.log("Selected Item:", menuItem);
      if (action === "edit") {
        setEditTitleOpen(true);
        updateEditApiTriggered(false);
      } else if (action === "delete") {
        setDeleteDialogOpen(true);
      } else if (action === "publish" || action === "unpublish") {
        setPublishModalOpen(true);
        setPublishItemId(menuItem.id);
        updatePublishApiTriggered(false);
        updateUnpublishApiTriggered(false);
      } else if (action === "clone") {
        setCloneModalOpen(true);
        setCloneItem(menuItem);
      }
      handleMenuClose();
    }
  };

  const handleEditTitleClose = () => {
    setEditTitleOpen(false);
  };

  const handlePublishModalClose = () => {
    setPublishModalOpen(false);
    setPublishItemId(null);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async() => {
    try {
      if (selectedItem) {
        // console.log(selectedItem.id);
        const data=await DeleteDisclosure(selectedItem.id);
       // console.log(data,"delete sucessfully")
         setDisclosureList((prevList) =>
           prevList.filter((item) => item.id !== selectedItem.id)
         );
         setDeleteDialogOpen(false);
         setSelectedItem(null);
       }
    } catch (error) {
      console.log(error);
    }
   
  };

  const handleEditTitleSave = (id, newTitle) => {
    setDisclosureList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, name: newTitle } : item
      )
    );
  };

  const handlePublish = (id) => {
    setDisclosureList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, isPublished: !item.isPublished } : item
      )
    );
  };

  const handleItemClick = (item, index) => {
    localStorage.setItem("disclosurename", JSON.stringify(item.id));
    localStorage.setItem(
      "DisclosureDetails",
      JSON.stringify({
        itemID: item.id,
        itemName: item?.name ? item.name : "",
        index: index,
      })
    );
    navigate("/RegistrationResult", {
      state: { disclosure: item.id },
    });
    contextData.setDisclosureName(item.id);
  };

  const handleClone = async (item, newTitle, effectiveDate, version, enforcingAgency) => {
    const newItem = {
      disclosureId: item.id,
      disclosurename: newTitle,
      effectivedate: effectiveDate,
      version: version,

    };
    console.log(newItem);
    const data = await CloneDisclosure(newItem);
    if (data) {
      InitialRender()
    }
    console.log(data);
    //setDisclosureList((prevList) => [...prevList, newItem]);
  };

  const filteredData = disclosureList.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filter === "All" ||
        (filter === "Published" && item.isPublished) ||
        (filter === "Unpublished" && !item.isPublished))
  );

  const renderMenu = (item) => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && menuItem?.id === item.id}
      onClose={handleMenuClose}
    >
      {
        (utilityPermission?.editButton) && (
          <MenuItem onClick={() => handleActionClick("edit")}>
            <EditIcon style={{ marginRight: "8px" }} /> Edit title
          </MenuItem>

        )
      }
      {
        (utilityPermission?.delete) && (
          <MenuItem onClick={() => handleActionClick("delete")}>
            <DeleteIcon style={{ marginRight: "8px" }} /> Delete
          </MenuItem>

        )
      }
      {
        (utilityPermission?.clone) && (
          <MenuItem onClick={() => handleActionClick("clone")}>
            <FileCopyIcon style={{ marginRight: "8px" }} /> Clone
          </MenuItem>

        )
      }
      {
        adminRole && (
          <MenuItem
            onClick={() =>
              handleActionClick(item.isPublished ? "unpublish" : "publish")
            }
          >
            {item.isPublished ? (
              <UnpublishIcon style={{ marginRight: "8px" }} />
            ) : (
              <PublishIcon style={{ marginRight: "8px" }} />
            )}
            {item.isPublished ? "Unpublish" : "Publish"}
          </MenuItem>
        )
      }
    </Menu>
  );

  return (
    <Grid style={{ padding: "20px" }}>
      <Grid container justifyContent={"space-between"} mb={2}>
        <Grid item>
          <Typography
            sx={{ color: "#061826", fontSize: "26px", fontWeight: "600" }}
          >
            <ArrowBackIosIcon
              style={{
                marginRight: "0.2em",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            DashBoard
          </Typography>
        </Grid>
        <Grid item>
          {
            (utilityPermission?.createData) && (
              <Button
                variant="contained"
                sx={{ backgroundColor: "#25A392" }}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                Create New Template
              </Button>
            )

          }
        </Grid>
      </Grid>
      <AddDisclosureModal openDialog={openDialog} setOpenDialog={setOpenDialog} />

      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <TextField
          label="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Grid>
          <IconButton onClick={() => setView("grid")}>
            <ViewModuleIcon />
          </IconButton>
          <IconButton onClick={() => setView("table")}>
            <ViewListIcon />
          </IconButton>
          <IconButton onClick={handleFilterMenuClick}>
            <FilterListIcon />
          </IconButton>
          <Menu
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={handleFilterMenuClose}
          >
            <MenuItem onClick={() => handleFilterChange("All")}>All</MenuItem>
            <MenuItem onClick={() => handleFilterChange("Published")}>
              Published
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("Unpublished")}>
              Unpublished
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {view === "grid" ? (
        <React.Fragment>
          <Card sx={{ p: 2 }}>
            <Typography sx={{ fontSize: "1.4rem", fontWeight: 600 }}>Created Templates</Typography>
            <Grid container spacing={2}>
              {filteredData.map((item, index) => (
                <Grid item xs={6} key={item.id}>
                  <Card style={{ padding: "20px", border: "1px solid #D5D3F9" }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid container justifyContent="space-between">
                        <Grid container item xs={11}>
                          <Grid item>
                            <Typography onClick={() => handleItemClick(item, index)} sx={{ fontSize: "1.1em", cursor: "pointer", color: "#25A392" }}>{item.name}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}>
                          {
                            (utilityPermission?.menuButton) && (
                              <IconButton onClick={(event) => handleMenuClick(event, item)}>
                                <MoreVertIcon />
                              </IconButton>

                            )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}
                    >
                      <Grid className="rounded-pill text-light" sx={{  backgroundColor: item.isPublished ? "#25A392" : "#7393B3" }}>
                          <Typography variant="body1" sx={{mx:1,my:0.3}}>
                            {item.isPublished ? "Published" : "Unpublished"}
                          </Typography>
                      </Grid>

                    </Box>
                    {renderMenu(item)}
                  </Card>
                </Grid>
              ))}
            </Grid>

          </Card>
        </React.Fragment>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Button onClick={() => handleItemClick(item, index)}>
                      {item.name}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {item.isPublished ? "Published" : "Unpublished"}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, item)}>
                      <MoreVertIcon />
                    </IconButton>
                    {renderMenu(item)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditTitleModal
        open={editTitleOpen}
        handleClose={handleEditTitleClose}
        item={selectedItem}
        handleSave={handleEditTitleSave}
      />

      <PublishModal
        open={publishModalOpen}
        handleClose={handlePublishModalClose}
        item={disclosureList.find((item) => item.id === publishItemId)}
        handlePublish={handlePublish}
        companies={companies}
      />

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handleDeleteConfirm}
        itemName={selectedItem?.name}
        itemId={selectedItem?.id}
      />

      <CloneDisclosureModal
        open={cloneModalOpen}
        handleClose={() => setCloneModalOpen(false)}
        item={cloneItem}
        handleClone={handleClone}
      />
    </Grid>
  );
};

export default NewCreatedDisclosure;
