import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";
import { Typography } from "@mui/material";


function MTDCTable(props) {
  const [isloading, setisloading] = useState(false);
  const [headerTable, setheaderTable] = useState();
    const [target, settarget] = useState();

  // get table heading data
  const CreateHeader = () => {
    const column = Object.keys(headerTable[0]);
    const newcolumn = column.map((data) => {
         if (data !== "id" && data !== "isNew") {
      return <TableCell key={data}>{data}</TableCell>;
         }
         return null;
    });

    // console.log(newcolumn)
    return newcolumn;
  };

  // get table row data
  const tdData = () => {
    return headerTable.map((data,index) => {
      return (
        <TableBody key={index} >
          <TableRow
            key={data}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(headerTable[0]).map((x,index) => {
                if (x !== "id" && x !== "isNew") {
              return (
                <TableCell key={index} component="th" scope="row">
                  {data[x]}
                </TableCell>
              );
                }
                return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
    // console.log("hello",(Object.values(props.data.reportData["Disclosure B"])));
      settarget(Object.values(props.data.reportData["M.Disclosure C"])[2].answer);
    
    setheaderTable(
      Object.values(props.data.reportData["M.Disclosure C"])[0].answer);
      // console.log(Object.values(props.data.reportData["S.Disclosure B"]));
    setisloading(true);

    //CreateHeader();
  }, [isloading,props]);

  return (
    <React.Fragment>
      {isloading && (
        <>
          
            <Grid container spacing={1} className="border3">
            <Grid item xs={12} md={2} className="border3" >
              <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  </div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                    <li>Strategy</li>
                    <li>Risk Management</li>
                    <li style={{color:"#724272",fontSize:"20px"}}><b>Metrics and Targets</b></li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#724272"}} className="border3">
                  <Typography style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Metrics and Targets</Typography>
                      <Typography style={{color:"white",paddingLeft:"10px"}}>Disclose Scope 1, Scope 2, and, if appropriate, Scope 3 
                                    greenhouse gas (GHG) emissions, and the related risks.</Typography>
                  </Grid>
              <Grid item xs={12} md={6} className="grid1">
              <h4 className="underline">M.Disclosure C</h4>
                <Typography><b>Provide organization's time frames over which the climate-related target applies.</b></Typography>
                <div dangerouslySetInnerHTML={{ __html: target }}></div>
                 </Grid>
           <Grid item xs={12} md={6}  className="grid2"> 
          <Typography><b>Describe organization's key climate-related targets related to GHG emissions, water usage, energy usage, etc., consistent with the cross-industry, climate-related metric categories where relevant, 
            and in line with anticipated regulatory requirements or market constraints or other goals</b></Typography>
                <div style={{ justifyContent: "right", display: "flex" }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 550, width: "40%" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 1 },
                          }}
                        >
                          {headerTable && CreateHeader()}
                        </TableRow>
                      </TableHead>

                      {headerTable && tdData()}
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
              </Grid>
            </Grid>
        </>
      )}
   </React.Fragment>
  );
}

export default MTDCTable;