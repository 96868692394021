import { TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import "../../styles/Report/Report.css";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";

function RDA2(props) {
  const [isloading, setisloading] = useState(false);
  const [Question, setQuestion] = useState();
  const [potential, setpotential] = useState();
  const [terminology, setterminology] = useState();
 

  useEffect(() => {
    let ddd = [];
    const QuestionData = [];
    const AnswerData = [];
    for (let i = 0;i < Object.keys(props.data.reportData["R.Disclosure A"]).length;i++) {
      if (
        Object.values(props.data.reportData["R.Disclosure A"])[i].answer ==="Yes" ||
        Object.values(props.data.reportData["R.Disclosure A"])[i].answer ==="No"
      ) {
        QuestionData.push(Object.keys(props.data.reportData["R.Disclosure A"])[i]);
        AnswerData.push(Object.values(props.data.reportData["R.Disclosure A"])[i].answer);
        ddd.push({Questions: Object.keys(props.data.reportData["R.Disclosure A"])[i],
          Answer: Object.values(props.data.reportData["R.Disclosure A"])[i].answer,
        });
        // console.log(Object.keys(props.data.reportData["G.Disclosure B"])[3])
        setpotential(
          Object.values(props.data.reportData["R.Disclosure A"])[3].answer
        );
        setterminology(
          Object.values(props.data.reportData["R.Disclosure A"])[4].answer
        );
      }
    }
    setQuestion(ddd);
    // console.log(ddd);
    setisloading(true);
  }, [isloading, props]);
  return (
    <React.Fragment>
      {isloading && (
        <>
          <Grid container spacing={1} className="border2">
            <Grid item xs={12} md={2} className="border2" >
              <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  </div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                    <li>Strategy</li>
                    <li style={{color:"#373737",fontSize:"20px"}}><b>Risk Management</b></li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#373737"}} className="border2">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Risk Management</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the organization’s processes for 
                            identifying and assessing climate-related risks.</p>
                  </Grid>
              <Grid item xs={12} md={6} className="grid1">
              <h4 className="underline">R.Disclosure A</h4>
                <h5><b>Describe processes for assessing the potential size and scope of identified climate-related risks</b>
                </h5>
                <div dangerouslySetInnerHTML={{ __html: potential }}></div>
              </Grid>
              <Grid item xs={12} md={6} className="grid2">
                <h5>
                  <b>Disclosure B Y/N</b>
                </h5>
                <div style={{ justifyContent: "right", display: "flex" }}>
                  <TableContainer>
                    <Table className="Table_TCFD" aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 1,
                              fontFamily: 'Microsoft YaHei',
                              fontSize: "15px",
                            },
                          }}
                        >
                          <TableCell>Questions</TableCell>
                          <TableCell>Yes/No</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 1,
                            fontSize: "15px",
                          },
                        }}
                      >
                        {Question.map((val, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{val.Questions}</TableCell>
                              <TableCell>{val.Answer}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <br></br>
                <h5>
                  <b>
                  Provide definitions of risk terminology used or references to existing risk classification frameworks
                  </b>
                </h5>
                <div dangerouslySetInnerHTML={{ __html: terminology }}></div>
              </Grid>
              </Grid>
            </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default RDA2;
