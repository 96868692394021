/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  IconButton,
  Menu,
  LinearProgress,
  colors,
} from "@mui/material";
import { CircularProgress, Backdrop } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../styles/FormCreation/RegistrationResult.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import NewSection from "./NewSection";
import NewSubSection from "./NewSubSection";
import NewQuestion from "./NewQuestions";
import { Grid, Link } from "@mui/material";
import {
  fetchDisclosure,
  fetchquestion,
  getRolePermissions,
} from "../services/FormCreationFetch";
import {
  GetCurrancy,
  getDropdownValues,
  getUnits,
  getTelephoneNumber,
  putApprove
} from "../../ESG/services/Fetch";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Split from "react-split";
import Divider from "@mui/material/Divider";
import Form_Text_input from "../../ESG/forms/Form_Text_input";
import Form_Number from "../../ESG/forms/Form_Number";
import From_Currency from "../../ESG/forms/Form_Currency";
import Form_Autocomplete from "../../ESG/forms/Form_Autocomplete";
import Form_Date_input from "../../ESG/forms/Form_Date_input";
import Form_PeriodicDate from "../../ESG/forms/Form_PeriodicDate";
import Form_Address from "../../ESG/forms/Form_Address";
import Form_ContractInfo from "../../ESG/forms/Form_ContractInfo";
import Form_NumberandDropdown from "../../ESG/forms/Form_NumberandDropdown";
import Percentage from "../../ESG/forms/Form_Percentage";
import Form_EditableList from "ESG/forms/Form_EditableList";
import Form_Url from "ESG/forms/Form_Url";
import Form_Email from "ESG/forms/Form_Email";
import CheckBoxs from "../../ESG/forms/Form_CheckBox";
import Form_Radio_Group from "../../ESG/forms/Form_Radio_Group";
import Form_Table from "ESG/forms/Form_Table";
import Form_SubForm from "ESG/forms/Form_SubForm.js";
import { useLocation, useNavigate } from "react-router-dom";
import NoteContext from "../../context/PageContext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CustomInlineToolbar from "ESG/forms/Form_RTE.js";
import PaperComponent from "components/PaperComponent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import EditQuestion from "./EditQuestion";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "semantic-ui-react";
import { useRoleContext } from "context/RoleContex";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import RejectReason from "./RejectReason";

function findPath(obj, target) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === target) {
        return [key]; // found the target, return its name
      } else if (typeof obj[key] === "object") {
        var result = findPath(obj[key], target); // recursive call
        if (result) {
          return [key].concat(result); // prepend current key to result
        }
      }
    }
  }
  return null; // target not found
}

export default function RegistrationResult() {
  const contextData = useContext(NoteContext);
  const { selectedRole } = useRoleContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [Isloading, Setisloading] = useState(false);
  const [existing_disclosure, setExisting_disclosure] = useState("");
  const [ExpandedNodes, setExpandedNodes] = useState([]);
  const [DisclosurePath, setDisclosurePath] = useState();
  const [MenuIconId, setMenuIconId] = useState();
  const [DelteOpen,setDelteOpen]=useState(false);
  // eslint-disable-next-line no-unused-vars
  const [sizes, setsizes] = useState([30, 70]);
  // const [open, setOpen] = useState(true);
  const [question, setQuestion] = useState("");
  const [questionDesc, setQuestionDesc] = useState("");
  const [questionHelpertext, setQuestionHelpertext] = useState("");
 
  const [fieldType, setFieldType] = useState("");
  const [fieldValues, setFieldValues] = useState("");
  const [form, setForm] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [Answer, setAnswer] = useState({
    InitialAnswer: "",
    UpdatedAnswer: "",
  }); // set Answer
  const [RTE, setRTE] = useState({
    InitialRTE: "",
    UpdatedRTE: "",
  }); // Set Ritch text editer

  const [selectedItem, setSelectedItem] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [permisson, setpermisson] = useState();
  const accountDetails = JSON.parse(localStorage.getItem("AccountDetails"));
  const [filingGroups, setfilingGroups] = useState(
    localStorage.getItem("selectedUserRoleContext")
  );
  const [ReasonForReject,SetReasonForReject]=useState("");
  const [isApproved,setisApproved]=useState();
  const [isRejectedComment,setisRejectedComment]=useState("");
  const [CommentBy,setCommentBy]=useState();
  const[openLoader,setopenLoader]=useState(false);

  const handleClick = (event, type, id) => {
    setAnchorEl(event.currentTarget);

    setMenuIconId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let savedData = JSON.parse(localStorage.getItem("myDisclosure"));
  let dis_name = "";
  let Form;
  const handleChange = (updatedAnswer) => {
    
    setAnswer((prevstate) => {
      return { ...prevstate, UpdatedAnswer: updatedAnswer };
    });
  };

  const handleChangeRTE = (newContent) => {
    setRTE((prevstate) => {
      return { ...prevstate, UpdatedRTE: newContent };
    });
  };

  const fetchques = async (id) => {
    contextData.setAnswer({
      InitialAnswer: "",
      UpdatedAnswer: "",
    });
    //fetch the question details and the approve details 
    setForm("");
    let fieldType = "";
    let fieldValues = "";
    var path = findPath(existing_disclosure, id);
    setDisclosurePath(path.filter((value, index) => !(index % 2)));
    await fetchquestion(id).then(async (data) => {
   
      data._items.map((data) => {
       
        if (data.label === "Question") {
          setQuestion(data.value);
        } else if (data.label === "HelpText" || data.label === "Help Text") {
          setQuestionHelpertext(data.value);
        } else if (data.label === "FieldType" || data.label === "Field Type") {
          fieldType = data.value;
        } else if (data.label === "Description") {
          setQuestionDesc(data.value);
        } else if (data.label === "TableJson") {
          fieldValues = data.value;
        } else if (data.label === "FieldValues") {
          fieldValues = data.value;
        } else if (data.label === "SubQuestionJson") {
          
          fieldValues = data.value;
        }else if(data.label === "isApproved")
        {
          setisApproved(data.value);
        }else if(data.label === "approveOrRejectComment")
        {
          setisRejectedComment(data.value);
        }
        else if(data.label === "approvedOrRejectedBy")
        {
          setCommentBy(data.value);
        }

      });
    });

    if (fieldType === "Text Field" || fieldType === "Text Field") {
     
      Form = <Form_Text_input onChange={handleChange} />;
    } else if (fieldType === "Number Field" || fieldType === "Number") {
      Form = <Form_Number onChange={handleChange} />;
    } else if (fieldType === "Currency Field") {
      const response_dropdown = await GetCurrancy();
     
      Form = (
        <From_Currency
          qn={question}
          FieldValues={response_dropdown}
          Answer=""
          onChange={handleChange}
          isChecked={false}
        />
      );
    } else if (
      fieldType === "Drop Down" ||
      fieldType === "Drop down"
    ) {
      const response_dropdown = await getDropdownValues(id);
      let arr_value = response_dropdown;
      
      const FieldValues = Object.values(arr_value).toString();
      // eslint-disable-next-line react/jsx-pascal-case
      Form = (
        <Form_Autocomplete
          Answer=""
          isChecked={false}
          onChange={handleChange}
          FieldValues={FieldValues}
        />
      );
    } else if (fieldType === "Date") {
      Form = (
        <Form_Date_input Answer="" onChange={handleChange} isChecked={false} />
      );
    } else if (fieldType === "Date Range") {
      Form = (
        <Form_PeriodicDate
          Answer=""
          onChange={handleChange}
          isChecked={false}
        />
      );
    } else if (fieldType === "List of Address" || fieldType === "Address") {
      Form = (
        <Form_Address
          Answer=""
          onChange={handleChange}
          ListOfAddress={true}
          isChecked={false}
        />
      );
    } else if (fieldType === "Contact Information") {
    
      Form = (
        <Form_ContractInfo
          Answer=""
          onChange={handleChange}
          isChecked={false}
        />
      );
    } else if (
      fieldType === "Number Field with Units" ||
      fieldType === "Number Field with Drop Down"
    ) {
      const response_dropdown = await getUnits(id);
      Form = (
        <Form_NumberandDropdown
          FieldValues={response_dropdown}
          Answer=""
          onChange={handleChange}
          isChecked={false}
        />
      );
    } else if (
      fieldType === "Percentage Field" ||
      fieldType === "Number Field with Percentage"
    ) {
      Form = <Percentage Answer="" onChange={handleChange} isChecked={false} />;
    } else if (fieldType === "Editable List") {
      
      Form = (
        <Form_EditableList
          Answer=""
          onChange={handleChange}
          isChecked={false}
        />
      );
    } else if (fieldType === "TelephoneNumber") {
      const response_dropdown = await getTelephoneNumber();
      Form = (
        <Form_NumberandDropdown
          FieldValues={response_dropdown}
          Answer=""
          onChange={handleChange}
          isChecked={false}
        />
      );
    } else if (fieldType === "URL Field") {
      Form = <Form_Url Answer="" onChange={handleChange} />;
    } else if (fieldType === "Email Field") {
      Form = <Form_Email Answer="" onChange={handleChange} />;
    } else if (fieldType === "Check Box") {
     
      Form = (
        <CheckBoxs
          FieldValues={fieldValues}
          Answer=""
          onChange={handleChange}
        />
      );
    } else if (
      fieldType === "Radio Button" ||
      fieldType === "Radio Button with Dependency"
    ) {
      // const FieldValues = data.data.formProperties.FieldValues[0];
      Form = (
        <Form_Radio_Group
          FieldValues={
            fieldValues.length !== 0 && fieldValues !== null ? fieldValues : []
          }
          Answer=""
          onChange={handleChange}
        />
      );
    } else if (fieldType === "Table") {
      const TableJson = JSON.parse(fieldValues);
     
      Form = (
        <Form_Table
          TableJson={TableJson}
          questionId={id}
          onChange={handleChange}
          Answer={[]}
          cik="0000320193"
          startDate="01/01/2019"
        />
      );
    } else if (fieldType === "Form Field") {
    
      // const jsonObject = JSON.parse(fieldValues);
      // const subQuestionArray = jsonObject.subQuestion;
      
      const FormFieldJson = JSON.parse(fieldValues);
      Form = (
        <Form_SubForm
          FormFieldJson={FormFieldJson.subQuestion}
          Answer={{}}
          onChange={handleChange}
          isChecked={false}
          questionId={id}
        />
      );
    } else if (fieldType === "Rich Text" || fieldType === "RTE") {
      Form = (
        <CustomInlineToolbar
          onChange={handleChangeRTE}
          RTE={""}
          isChecked={true}
        />
      );
    }

    setForm(Form);
  };

  async function fetchData() {
   
    Setisloading(true);
    if (
      location.state === "" ||
      location.state === null ||
      location.state === undefined
    ) {
      function getQueryParameters() {
        var queryString = window.location.search.slice(1); // Remove the leading '?'
        var parts = queryString.split("=");
        var value = decodeURIComponent(parts[1]);
        localStorage.setItem("disclosurename", JSON.stringify(value));
        return value; // Return the value
      }

      const dis_name = getQueryParameters();
      // console.log(dis_name);

      const data = await fetchDisclosure(dis_name);
      // console.log(data);
      if (data && Object.keys(data._items[0]).length !== 0) {
        setExisting_disclosure(data._items[0]);
      } else {
        setExisting_disclosure(dis_name);
      }
    } else {
      const dis_name = location.state.disclosure;
    

      const data = await fetchDisclosure(dis_name);
  

      if (data && Object.keys(data._items[0]).length !== 0) {
        setExisting_disclosure(data._items[0]);
      } else {
        setExisting_disclosure(dis_name);
      }
    }
  }
  const fetchDataNew=async()=>{
    if (
      location.state === "" ||
      location.state === null ||
      location.state === undefined
    ) {
      function getQueryParameters() {
        var queryString = window.location.search.slice(1); // Remove the leading '?'
        var parts = queryString.split("=");
        var value = decodeURIComponent(parts[1]);
        localStorage.setItem("disclosurename", JSON.stringify(value));
        return value; // Return the value
      }

      const dis_name = getQueryParameters();
     

      const data = await fetchDisclosure(dis_name);
  
      if (data && Object.keys(data._items[0]).length !== 0) {
        setExisting_disclosure(data._items[0]);
      } else {
        setExisting_disclosure(dis_name);
      }
    } else {
      const dis_name = location.state.disclosure;
    

      const data = await fetchDisclosure(dis_name);
     

      if (data && Object.keys(data._items[0]).length !== 0) {
        setExisting_disclosure(data._items[0]);
      } else {
        setExisting_disclosure(dis_name);
      }
    }
  }
  useEffect(() => {
    if (contextData.ReloadData === true) {
      fetchData().then(() => {
        Setisloading(false);
      }); // Call the async function here
      contextData.setReloadData(false);
    }
  }, [contextData.ReloadData]);

  useEffect(() => {
    fetchData().then(() => {
      Setisloading(false);
    }); // Call the async function here
  }, [contextData.disclosureName]);

 

  const onNodeToggle = (node) => {
    var path = findPath(existing_disclosure, node);
    setExpandedNodes((prevExpandedNodes) => {
      if (prevExpandedNodes.includes(node)) {
        return prevExpandedNodes.filter((i) => i !== node);
      } else {
        return path.filter((value, index) => !(index % 2));
      }
    });
  };

  const clickHandler = (id, haschildren) => {
    haschildren && onNodeToggle(id);
    setSelectedItem(id);
    fetchques(id);
  };

  function RenderDisclosureName(existing_disclosure) {
    const Type = "Disclosure";
    return (
      <Box className="ListContainer">
        <List dense disablePadding>
          <ListItem
            component="div"
            disablePadding
            secondaryAction={
              <>
                {permisson && permisson[0]?.menuButton && (
                  <IconButton
                    onClick={(event) => handleClick(event, Type)}
                    edge="end"
                  >
                    <MoreVertIcon sx={{ color: "#2076B0" }} />
                  </IconButton>
                )}

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    ".MuiMenu-list": {
                      marginTop: "0px",
                    },
                  }}
                >
                  <AddMiniToolbarButtoms type={Type} />
                </Menu>
              </>
            }
          >
            <ListItemButton>
              <ListItemText
                primary={existing_disclosure}
                primaryTypographyProps={{
                  // color: 'primary',
                  fontWeight: "medium",
                  variant: "body2",
                }}
                // onClick={() => clickHandler(item[1].key.id, hasSubList)}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );
  }
 

  function Recursive(existing_disclosure) {
    return (
      <Box className="ListContainer">
        <List dense disablePadding>
          {Object.entries(existing_disclosure).map((item, key) => {
            
            const hasSubList = Object.keys(item[1].value).length !== 0;
            const isSelected = ExpandedNodes.includes(item[1].key.id);
            const Type_contains = item[1].key.properties.hasOwnProperty("Type");
            const TreeType_contains =
              item[1].key.properties.hasOwnProperty("TreeType");
            let Type = "";
            if (Type_contains === true) {
              Type = item[1].key.properties.Type[0].value;
            } else if (TreeType_contains === true) {
              Type = item[1].key.properties.TreeType[0].value;
            }
            return (
              <React.Fragment key={key}>
                <ListItem
                  component="div"
                  disablePadding
                  key={key}
                  secondaryAction={
                    <>
                      {permisson && permisson[0]?.menuButton && (
                        <IconButton
                          onClick={(event) =>
                            handleClick(event, Type, item[1].key.id)
                          }
                          edge="end"
                        >
                          <MoreVertIcon sx={{ color: "#2076B0" }} />
                        </IconButton>
                      )}
                      {MenuIconId === item[1].key.id && (
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            ".MuiMenu-list": {
                              marginTop: "0px",
                            },
                            zIndex: 150,
                          }}
                        >
                          <AddMiniToolbarButtoms
                            type={Type}
                            id={item[1].key.id}
                          />
                        </Menu>
                      )}
                    </>
                  }
                  // className={`ListItem ${ExpandedNodes.includes(item[1].key.id)?'ExpandedListItem':''}`}
                >
                  <ListItemButton
                    selected={selectedItem === item[1].key.id}
                    onClick={() => clickHandler(item[1].key.id, hasSubList)}
                    className={`ListItem ${
                      ExpandedNodes?.includes(item[1].key.id)
                        ? "ExpandedListItem"
                        : ""
                    }`}
                  >
                    {/* code for displaying section and question in tree structure */}
                    {(item[1].key?.properties?.TreeType&&item[1].key?.properties?.TreeType.length > 0 && item[1].key?.properties?.TreeType[0]?.value) ===
                      "Section" ||
                   (item[1].key?.properties?.TreeType&&item[1].key?.properties?.TreeType.length > 0 && item[1].key?.properties?.TreeType[0]?.value) ===
                      "Subsection" ? (
                      <ListItemText
                        className="listItemLabel"
                        primary={item[1].key.properties.name[0].value}
                        primaryTypographyProps={{
                          // color: 'primary',
                          fontWeight: "medium",
                          variant: "body2",
                        }}
                      />
                    ) : (item[1].key?.properties?.TreeType&&item[1].key?.properties?.TreeType.length > 0 && item[1].key?.properties?.TreeType[0]?.value) ===
                    "Question" ?( <ListItemText
                      className="listItemLabel"
                      primary={
                        item[1].key?.properties?.name?.length > 0 &&
                        item[1].key?.properties?.name[0].value !== "undefined"
                          ? item[1].key?.properties?.name[0].value
                          : item[1].key.id
                      }
                      primaryTypographyProps={{
                        color: item[1]?.key?.properties?.isApproved ? item[1]?.key?.properties?.isApproved[0]?.value ===true ? "primary":item[1]?.key?.properties?.isApproved[0]?.value ===false ?'red':"black":"black",
                        fontWeight: "medium",
                        variant: "body2",
                      }}
                    />): (
                      <ListItemText
                        className="listItemLabel"
                        primary={
                          item[1].key?.properties?.name?.length > 0 &&
                          item[1].key?.properties?.name[0].value !== "undefined"
                            ? item[1].key?.properties?.name[0].value
                            : item[1].key.id
                        }
                        primaryTypographyProps={{
                         // color: 'red',
                          fontWeight: "medium",
                          variant: "body2",
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
                {hasSubList && (
                  <Collapse
                    component="li"
                    in={isSelected}
                    timeout="auto"
                    unmountOnExit
                    className={`NestedList ${
                      item[1].key.id === ExpandedNodes[ExpandedNodes.length - 1]
                        ? "Expanded"
                        : ""
                    }`}
                  >
                   
                    {Recursive(item[1].value)}
                  </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    );
  }

  const ReloadQuestion = (id) => {
    id === selectedItem && fetchques(id);
  };
  useEffect(() => {
    GetPremission();
  }, [selectedRole]);
  const GetPremission = async () => {
    try {
      const response = await getRolePermissions(selectedRole);
     
      setpermisson(response?.utility);
    } catch (error) {
      console.log(error);
      setpermisson([]);
    }
  };
  // for menu button 
  const AddMiniToolbarButtoms = ({ type, id }) => {
    try {
     
      if (type === "Disclosure") {
        return (
          <>
            <NewSection
              id={id}
              handleClose={handleClose}
              condtion={permisson}
            />
          </>
        );
      } else if (type === "Section") {
        return (
          <>
            <NewSubSection
              id={id}
              handleClose={handleClose}
              condtion={permisson}
            />
          </>
        );
      } else if (type === "Subsection") {
       
        return (
          <>
            <NewQuestion
              id={id}
              handleClose={handleClose}
              condtion={permisson}
            />
          </>
        );
      } else if (type === "Question") {
        return (
          <>
            <EditQuestion
              id={id}
              ReloadQuestion={ReloadQuestion}
              handleClose={handleClose}
              condtion={permisson}
            />
          </>
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  function getCurrentDateFormatted() {
    const date = new Date();
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0-11
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
  }

  const handleAprove = async () => {
    setopenLoader(true);
   
    let startDate_of_disclosure;
    let disclosure_name_from_user;
    let questionId=selectedItem;
    let Approve=true;
    let filingGroups=localStorage.getItem("selectedUserRoleContext");
    let currentDate=getCurrentDateFormatted();
    let approveOrRejectComment="Approved";
    try {

      const result= await putApprove(startDate_of_disclosure,disclosure_name_from_user,questionId,filingGroups,Approve,currentDate,approveOrRejectComment);
      fetchDataNew();
      fetchques(questionId);
      setopenLoader(false);
    } catch (error) {
      console.log(error);
    }
  };
  const Spinner = ({ open }) => {
    return (
      <Backdrop open={open} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
        <Typography>Loading......</Typography>
      </Backdrop>
    );
  };

  const handleReject = async () => {
   
 
    setDelteOpen(true);
  };

  const handleDeleteReason=async()=>{
    setopenLoader(true);
    
    let startDate_of_disclosure;
    let disclosure_name_from_user;
    let questionId=selectedItem;
    let Approve=false;
    let filingGroups=localStorage.getItem("selectedUserRoleContext");
    let currentDate=getCurrentDateFormatted();
    let approveOrRejectComment=ReasonForReject;
    try {

      const result= await putApprove(startDate_of_disclosure,disclosure_name_from_user,questionId,filingGroups,Approve,currentDate,approveOrRejectComment);
      fetchDataNew();
      fetchques(questionId);
      setopenLoader(false);
    } catch (error) {
      console.log(error);
    }
    setDelteOpen(false);
  }

  const handleJustClose=()=>{
    setDelteOpen(false);
  }
  const handleRejectForAnalyst=async()=>{
    setopenLoader(true);
    let startDate_of_disclosure;
    let disclosure_name_from_user;
    let questionId=selectedItem;
    let Approve=false;
    let filingGroups=localStorage.getItem("selectedUserRoleContext");
    let currentDate=getCurrentDateFormatted();
    let approveOrRejectComment;
    try {

      const result= await putApprove(startDate_of_disclosure,disclosure_name_from_user,questionId,filingGroups,Approve,currentDate,approveOrRejectComment);
    fetchDataNew();
    fetchques(questionId);
    setopenLoader(false);
    } catch (error) {
      console.log(error);
    }

  }
  
  return (
    <React.Fragment>
      {Isloading ? (
        <LinearProgress />
      ) : (
        <Box sx={{ backgroundColor: "#F5F5F6" }}>
          <Grid container justifyContent="space-between" px={2} py={1}>
            <Grid item>
              <Typography
                sx={{ fontSize: "20px", color: "#061826", fontWeight: "600" }}
              >
                <ArrowBackIosIcon
                  style={{
                    marginRight: "0.2em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/NewCreatedTemplate");
                  }}
                />
                {
                  JSON.parse(localStorage.getItem("DisclosureDetails"))
                    ?.itemName

                }
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} px={2}>
            {/* section 1 Tree */}
            <Grid item md={4}>
              <Box className="treeSection_formCreation">
                {typeof existing_disclosure === "string"
                  ? RenderDisclosureName(existing_disclosure)
                  : Recursive(existing_disclosure)}
              </Box>
            </Grid>

            {/* section 2 Q/A  */}
            <Grid item md={5}>
              {/* Q/A */}

              <PaperComponent>
                <Box
                  sx={{
                    overflowY: "scroll",
                    height: "calc(100vh - 95px)",
                    padding: "15px",
                  }}
                >
                  <Grid container direction="column" rowSpacing={2}>
                    {/* Breadcrumbs & PrevNext Buttons */}
                    <Grid
                      item
                      container
                      wrap="nowrap"
                      justifyContent="space-between"
                      className="Discloser_path_box"
                    >
                      <Grid item xs={12}>
                        <Breadcrumbs
                          maxItems={3}
                          separator="›"
                          aria-label="breadcrumb"
                          className="Discloser_path"
                        >
                          {DisclosurePath &&
                            DisclosurePath.map((item) => {
                              return (
                                <Link
                                  className="pathlabels"
                                  title={item}
                                  key={item}
                                  aria-current="page"
                                >
                                  {item}
                                </Link>
                              );
                            })}
                        </Breadcrumbs>
                      </Grid>
                    </Grid>
                    {question && (
                      <>
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: isApproved === true ? "#061826" : isApproved === false ? "red" : "black",
                              fontWeight: "600",
                            }}
                          >
                            {question}
                          </Typography>
                        </Grid>
                        <Grid item mt={1}>
                          {form}
                        </Grid>
                        <Grid item p={2} mt={1}>
                          {form &&
                            ((filingGroups &&
                              filingGroups === "Filing Reviewer") ||
                            filingGroups === "FIling Auditor" ? (
                              <Grid
                                item
                                container
                                spacing={2}
                                justifyContent={"flex-start"}
                              >
                                <Grid item>
                                  <button onClick={() => {
                                      handleAprove();
                                    }}>Approve</button>
                                </Grid>
                                <Grid item>
                                  <button  onClick={() => {
                                      handleReject();
                                    }}>Reject</button>
                                </Grid>
                              </Grid>
                            ) : filingGroups &&
                              filingGroups === "FilingAnalyst" ? (
                              <Grid
                                item
                                container
                                justifyContent={"flex-start"}
                                spacing={2}
                              >
                                <Grid item>
                                  <DoneIcon
                                    onClick={() => {
                                      handleAprove();
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <CloseIcon
                                    onClick={() => {
                                      handleRejectForAnalyst();
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              filingGroups &&
                              filingGroups === "Filing-Administrator" && (
                                <Grid
                                  item
                                  container
                                  justifyContent={"flex-start"}
                                  spacing={2}
                                >
                                  <Grid item>
                                  <button onClick={() => {
                                      handleAprove();
                                    }}>Submit</button>
                                </Grid>
                                <Grid item>
                                  <button  onClick={() => {
                                      handleReject();
                                    }}>Reject</button>
                                </Grid>
                                </Grid>
                              )
                            ))}
                        </Grid>
                        {isRejectedComment &&
                           <Grid item xs={12} sm={6} md={4} p={2} mt={1}>
                           <Paper elevation={3} style={{ padding: '16px', backgroundColor: '#f9f9f9' }}>
                             <Typography variant="subtitle2" color="textSecondary">
                               Comment by {CommentBy}
                             </Typography>
                             <Typography variant="body1" style={{ marginTop: '8px' }}>
                               {isRejectedComment}
                             </Typography>
                           </Paper>
                         </Grid>
                        }
                      </>
                    )}
                  </Grid>
                </Box>
              </PaperComponent>
            </Grid>

            {/* section 3 Help Text */}
            <Grid item md={3}>
              <PaperComponent>
                <Box
                  sx={{
                    overflowY: "scroll",
                    height: "calc(100vh - 95px)",
                    padding: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#061826",
                      marginBottom: "5px",
                    }}
                  >
                    Help Text
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#061826",
                    }}
                  >
                    {questionHelpertext}
                  </Typography>
                </Box>
              </PaperComponent>
            </Grid>
          </Grid>
          <Spinner open={openLoader} />
          <RejectReason open={DelteOpen} handleClose={handleDeleteReason} SetReasonForReject={SetReasonForReject} handleJustClose={handleJustClose}/>
        </Box>
      )}
    </React.Fragment>
  );
}
