import React, { useState, useEffect } from "react";
import DynamicTable from "./TradeReport_DymanicTable";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardMedia from "@mui/material/CardMedia";
import ImageUrl from "../../images/No_Info.svg";

import "../../Compliance/styles/TradeReport/TradeReport.css";

const TradeReport = () => {
  const {
    Getcollaterals,
    GetExecutingvenue,
  } = require("../../ESG/services/Fetch");
  const [collaterals_data, setcollaterals_data] = useState([]);
  const [Transactons_data, setTransactons_data] = useState([]);
  useEffect(() => {
    const fetchCollaterals = async () => {
      try {
        const collaterals = await Getcollaterals();
        const Transactions = await GetExecutingvenue();
        setcollaterals_data(collaterals.collaterals);
        setTransactons_data(Transactions.venues);
        console.log(Transactions);
      } catch (error) {}
    };

    fetchCollaterals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const allowedProperties_collaterals = [
    "id",
    "mylei",
    "marketvalue",
    "quality",
    "quantity",
    "unit",
  ];
  const allowedProperties_Transactions = [
    "mic",
    "lei",
    "status",
    "lastupdatedate",
    "website",
  ];
  // Filter the data array to include only the specified properties
  const filtered_collaterals_data = collaterals_data.map((obj) => {
    const newObj = {};
    allowedProperties_collaterals.forEach((property) => {
      if (obj.hasOwnProperty(property)) {
        newObj[property] = obj[property];
      }
    });
    return newObj;
  });


  const filtered_collaterals_data_Transactions = Transactons_data.map((obj) => {
    const newObj = {};
    allowedProperties_Transactions.forEach((property) => {
      if (obj.hasOwnProperty(property)) {
        newObj[property] = obj[property];
      }
    });
    return newObj;
  });
  console.log(filtered_collaterals_data);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedRow(null);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  return (
    <div className="root">
      <Typography className="title-names" gutterBottom>
        Trade Report
      </Typography>

      {/* Tabs */}

      <div className="table-box" style={{ display: "flex" }}>
        {/* <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className="tabs"
      >
        <Tab label="Transactions" />
        <Tab label="Collaterals" />
      </Tabs> */}

        {/* Table Card */}
        <Card className="tableCard">
          <CardContent>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className="tabs"
            >
              <Tab label="Transactions" />
              <Tab label="Collaterals" />
            </Tabs>
            <DynamicTable
              data={
                selectedTab === 0
                  ? filtered_collaterals_data_Transactions ?? []
                  : filtered_collaterals_data ?? []
              }
              onRowClick={handleRowClick}
            />
          </CardContent>
        </Card>

        {/* Selected Row Info Card */}
        <Card className="selectedRowCard">
          <Typography className="title-names">Selected Row Info</Typography>
          {selectedRow === null && (
            <CardMedia
              component="img"
              height="194"
              image={ImageUrl}
              alt="No Notification"
            />
          )}
          {selectedRow === null && (
            <Typography variant="body2">No Item Selected yet</Typography>
          )}
          {selectedRow ? (
            <Card className="slected-card">
              <CardContent>
                <div>
                  {Object.entries(selectedRow).map(([key, value]) => (
                    <div key={key}>
                      <Typography variant="caption">{key}</Typography>
                      <Typography variant="body1">{value}</Typography>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          ) : null}
        </Card>
      </div>
    </div>
  );
};

export default TradeReport;
