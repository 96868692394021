import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Content = () => {
 
  return (
    <>
   
    <Box sx={{ flexGrow: 1,width:"99vw",height:"90vh",backgroundColor:"whitesmoke" }}>
      <Grid container item columnSpacing={3} justifyContent="space-between" sx={{ position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)'}} spacing={1}>
        <Grid item  xs={12} md={3}>
        <Paper elevation={5} className='border' sx={{backgroundColor:"#61c4ee", height: "40vh", width: "100%",padding: "10px",color:"white"}}>
            <h2>Governance</h2>
            <br></br>
          <p>Disclose the organization's governance around climate-related risks and opportunities</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={5}  className='border1' sx={{backgroundColor:"steelblue",height: "40vh", width: "100%",padding: "10px",color:"white"}}>
            <h2>Strategy</h2>
            <br></br>
          <p>Disclose the actual and potential impacts of climate-related risks and opportunities on the organization’s businesses, strategy, and financial planning where such information is materia</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
        <Paper elevation={5}  className='border2' sx={{backgroundColor:"#373737",height: "40vh", width: "100%",padding: "10px",color:"white"}}>
            <h2>Risk Management</h2>
            <br></br>
          <p>Disclose how the organization identifies, assesses, and manages climate-related risks.</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
        <Paper elevation={5} className='border3' sx={{backgroundColor:"#724272",height: "40vh", width: "100%",padding: "10px",color:"white"}}>
            <h2>Metrics and Targets</h2>
            <br></br>
          <p>Disclose the metrics and targets used to assess and manage relevant climate-related risks and opportunities where such information is material.</p>
          </Paper>
        </Grid>
      </Grid>
      
    </Box>

    </>
  )
}

export default Content;


