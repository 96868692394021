import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TCFDlogo from "../../../images/TCFDlogo.png";

const SDA2 = (props)  =>{
    
    const [Banks,setBanks] = useState("")
    const [Lending,setLending]=useState();
    const [pageloaded, setpageloaded] = useState(false)

    useEffect (()=>{ 
      // console.log(Object.values(props.data.reportData["S.Disclosure A"]));

        setBanks((Object.values(props.data.reportData["S.Disclosure A"])[7]).answer);
        setLending((Object.values(props.data.reportData["S.Disclosure A"])[8]).answer);
        
        setpageloaded(true);
    },[props])

    return(
    <React.Fragment>
        {pageloaded ? (
        <>
            <Grid container spacing={1} className="border1">
            <Grid item xs={12} md={2} className="border1" >
            <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  /></div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                  <li style={{color:"steelblue",fontSize:"20px"}}><b>Strategy</b></li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"steelblue"}} className="border1">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Strategy</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the impact of climate-related risks and opportunities on the
                organization’s businesses, strategy, and financial planning where such information is material.</p>
                  </Grid>
                <Grid item xs={12} md={6} className="grid1">
                        <h4 className="underline">S.Disclosure A</h4>
                        <h5 className="underline">Banks should describe significant concentrations of credit exposure to carbon-related assets.</h5>
                        <div dangerouslySetInnerHTML={{__html:Banks}}></div>
                </Grid>
                <Grid item xs={12} md={6} className="grid2" >
                  <h5 className="underline">Banks should also consider disclosing their climate-related risks in their lending and other financial intermediary business activities.</h5>
                  <div dangerouslySetInnerHTML={{__html:Lending}}></div>
                </Grid>
                </Grid>
            </Grid>
      
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </React.Fragment>
    );
}
export default SDA2;