import React, { useEffect, useState, useContext } from "react";
import NoteContext from "context/PageContext";
import {
  Grid,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import withSnackbar from "../../components/Alert/withSnackbar";
import Loader from "./Loader";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "../../Compliance/styles/Case_Managment/CreateCase.css"
import Basicinfo from "Compliance/Components/BasicInfo";
import HashTag from "Compliance/Components/HashTag";
import CheckList from "Compliance/Components/CheckList";
import Evidences from "Compliance/Components/Evidences";
import IdentificationList from "Compliance/Components/IdentificationList";
import Comments from "Compliance/Components/Comments";
import AI from "Compliance/Components/AI";
import CaseAudit from "./CaseAudit";
import Drawer from "Compliance/Components/Drawer";
import { ConstructionOutlined, Refresh } from "@mui/icons-material";
import ParentCaseInfo from "Compliance/Components/ParentCaseInfo";
import SectionButtons from "Compliance/Components/SectionButtons";
import Inquiry from "Insurance/CaseManagement/Inquiry"
import Responsebox from "Insurance/CaseManagement/Responsebox";
import InsuranceBasicInfo from "Insurance/CaseManagement/InsuranceBasicInfo";
import InsuranceParentCaseInfo from "Insurance/CaseManagement/InsuranceParentCaseInfo";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
import {INSURANCE,ESG,KYC,sectionList,insurancesectionList} from "Insurance/CaseManagement/Config.js"
import InsuranceTemplate from "./Templates/InsuranceTemplate";
import KYCTemplate from "./Templates/KYCTemplate";

const CreateCase = ({ showSnackbar, userRole }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {defaulttemplateType} = useTemplateType();
  var templateType='';
  
  const {
    CreateCases,
    getCountryList,
    getStateList,
    getUserList,
    getCheckList,
    getErrorList,
    getResult,
    downloadFile,
    getIdentityList,
    getCaseById
  } = require("../services/CaseFetch");

  const params = new URLSearchParams(window.location.search);
  if(atob((params.get("templateType")))){
    templateType=atob((params.get("templateType")))
  }
  else{
    templateType= defaulttemplateType;
  }
  const casetype = atob(params.get("case_type"));
  const casecode = atob(params.get("case_code"));
  const iscreatecase=atob((params.get("iscreatecase")));
  const caseid = params.get("case_id") ? atob(params.get("case_id")) : '';
  const is_parent_case = params.get("parent_case") ? atob(params.get("parent_case")) : '';
  const childcase=params.get("childcase") ? atob(params.get("childcase"))?JSON.parse(atob(params.get("childcase"))):'' : '';
  const edit = atob(params.get("edit"));
  const display = caseid ? false : true
  const [sectionType, setSectionType] = useState("BasicInfo");
  const [hasCaseCreatorRole, setHasCaseCreatorRole] = useState();
  const [showCardRow, setShowCardRow] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "create-case-button" : undefined;
  const [fileDataArray, setFileDataArray] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [rows, setRows] = useState([{ Type: "", IdentificationNo: "" }]);
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");
  const userDetails = JSON.parse(localStorage.getItem("AccountDetails"));
  const auth = userDetails?.endUser;
  const [selectedSubCase, setselectedSubCase] = useState({ CaseId: "" });
  const [data, setdata] = useState({
    Caseid: "",
    customer_id: "",
    EditedBy: "",
    CaseCategory: "",
    Status: "",
    case_priority: "",
    hash_tag: [],
    CaseDate: formattedDate,
    description: "",
    CaseAttachment: "",
    accountApprovalDate: "",
    accountOpeningDate: "",
    first_name: "",
    last_name: "",
    country: "",
    country_code: "",
    state: "",
    state_code: "",
    case_type: "",
    expected_closure_date: "",
    creator_email: auth?.mail,
    creator_id: auth?.mail,
    creator: auth?.displayName,
    checksdata: [],
    comment: "",
    current_case_worker: "",
    current_case_worker_email: "",
    childcases: [],
  });
  const [insurancedata, setinsurancedata] = useState({
    subcategory:"",
    caseTitle: "",
    dateOpened: "",
    dateClosed: "",
    regulatoryAuthority: "",
    Status: "",
    assignedTo: "",
    CaseDate: formattedDate,
    description: "",
    CaseAttachment: "",
    case_type: "",
    creator_email: auth?.mail,
    creator_id: auth?.mail,
    creator: auth?.displayName,
    checksdata: [],
    comment: "",
    current_case_worker: "",
    current_case_worker_email: "",
    childcases: [],
    department:"",
    response:""
  });
  const [expdate, setexpdate] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [checkErrMsg, setCheckErrMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [countryData, setCountryData] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [defaultState, setDefaultState] = useState(null);
  const [caseworkers, setcaseworkers] = useState([]);
  const [subworker, setsubworker] = useState("");
  const [subworkeremail, setsubworkeremail] = useState("");
  const [checkData, setCheckData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [resultList, setResultList] = useState([]);
  const [checkrows, setCheckRows] = useState([]);
  const [base64FileList, setbase64FileList] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [newcommentsData, setnewCommentsData] = useState([]);
  const [identityData, setIdentityData] = useState(null);
  const [defaultidentityTypes, setDefaultIdentityTypes] = useState([]);
  const [newRowData, setNewRowData] = useState({
    type: "",
    date_conducted: "",
    conducted_by: "",
    current_case_worker: "",
    current_case_worker_email: "",
    expected_closure_date: "",
    result: "",
    link_to_evidence: "",
    exception: {
      exception_type: "",
      error_code: "",
      expected_resolution_by: "",
      exception_description: "",
    },
    sub_attachments: [],
  });
  const [defaultcheckType, setDefaultCheckType] = useState();
  const [defaultResult, setDefaultResult] = useState("SUCCESS");
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());

  useEffect(() => {
    setHasCaseCreatorRole(
      ContextData?.CaseRole?.displayName === "Case Creator" ? true : false
    );

    setdata({ ...data, case_type: casetype });
  }, [ContextData?.CaseRole?.displayName, hasCaseCreatorRole]);

  useEffect(() => {
    
      fetchData();
  
  }, []);

  const fetchData = async () => {
    try {
      const cdata = await getCountryList();
      const [userslist, sdata, chdata, erdata, result,idata] =
        await Promise.all([
          getUserList(),
          getStateList(cdata.data.values[0].CountryCode),
          getCheckList(casetype),
          getErrorList(casetype),
          getResult(),
          getIdentityList()
        ]);

      var cres = cdata.data.values;
      var res = cres.map((country) => ({
        value: country.CountryCode,
        label: country.CountryName,
      }));
      setCountryData(res);
      setDefaultCountry(res[0]);
      setdata((prevState) => ({
        ...prevState,
        country: res[0].label,
        country_code: res[0].value,
      }));

      var ssdata = sdata.data.values;
      var sortedStatedata = ssdata.map((state) => ({
        value: state,
        label: state,
      }));
      setStateData(sortedStatedata);
      setDefaultState(sortedStatedata[0]);
      setdata((prevState) => ({
        ...prevState,
        state_code: sortedStatedata[0].value,
        state: sortedStatedata[0].label,
      }));
      setcaseworkers(userslist.data);
      setResultList(result?.data?.values);
      var checkdata = chdata?.data?.values.map((chktype) => ({
        value: chktype,
        label: chktype,
      }));
      setCheckData(checkdata);
      setErrorData(erdata?.data);
      var sidtypes = idata?.data?.values;
      var idtypes = sidtypes.map((idtype) => ({
        value: idtype,
        label: idtype,
      }));
    
      setIdentityData(idtypes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar("unable to fetch data", "error");
    }
  };

 

const renderTemplate=()=>{
  switch(templateType){
  case KYC:
            return <KYCTemplate caseid={caseid} edit={edit} iscreatecase={iscreatecase} childcase={childcase}/>
  case INSURANCE:
            return <InsuranceTemplate caseid={caseid} edit={edit} iscreatecase={iscreatecase} childcase={childcase}/>
  default:
      return null;
  }
}
  
  return (
    <Grid style={{ background: "#F5F5F6" }}>
     
      <Grid>
        {loading ? (
          <Loader />
        ) : (
          <>
          {renderTemplate()}
          </>
        )}
      </Grid>
    </Grid>

  );
};

export default withSnackbar(CreateCase, "Create Case", "success");
