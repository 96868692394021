import React from 'react';
import ImageCarousel from '../../components/ImageCarousel.js';
import CopyrightNotice from '../../components/CopyrightNotice';

export default function LandingPage() {
    return (
        <React.Fragment>
        <ImageCarousel/>
        <CopyrightNotice/>
        </React.Fragment>
    );
}


