import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Typography, IconButton } from "@mui/material";
import { CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from "@mui/material/styles";
import moment from 'moment';
import "../styles/Real_Time_Data/Real_Time_Data.css";
import PopupCard from "./PopupCard";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { Tabs, Tab } from '@mui/material';
import { useContext } from "react";
import NoteContext from "context/PageContext";
//Funtion for dynamicly display nested object key and value 
function getObjectKeysAndValues(obj, prefix = '') {
  const keysAndValues = [];
  for (const key in obj) {
    if (key === 'details') {
      let value
      if (obj.hasOwnProperty(key)) {
        obj[key] = ""
        if (obj[key] === "") {
          value = {}
        } else if (obj[key] === "{}" && obj[key].length > 1) {
          value = JSON.parse(obj[key])
        } else {
          value = (JSON.parse(obj[key].length < 0) ? JSON.parse(obj[key]) : obj[key])
        }
        // const value = (JSON.parse(obj[key].length>0)?JSON.parse(obj[key]):obj[key]) ;
        const newPrefix = prefix ? `${key}` : key;

        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }
    else {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        // const newPrefix = prefix ? `${prefix}-->${key}` : key;
        const newPrefix = prefix ? `${prefix}->${key}` : key;
        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }
  }
  return keysAndValues;
}
//Funtion for dynamicly display nested object key and value 
function ObjectViewer(props) {
  const { data, val } = props;
  const keysAndValues = getObjectKeysAndValues(data);
  return (
    <React.Fragment>

      {val && keysAndValues.slice(...val).map(({ key, value }) => (
        <li key={key}>
          <strong>{key}: </strong>
          {value}
        </li>
      ))}

    </React.Fragment>
  );
}
// funtion Dynamic card display
export function DynamicCard(props) {
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <React.Fragment>
      <Card className="CompanyDetails_Card" >

        <CardContent className="CardContent">
          <Typography className="Table_Head" >
            {props.Card_header}
          </Typography>
          {props.Main_card_data}
        </CardContent>
        <CardActions disableSpacing>
          <ExpandMore
            className="ExpandMore"
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" className="Collapse_More" unmountOnExit>
          <CardContent>
            {props.ExpandMore_card_data}

          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  )
}


const columns = [
  { id: 'name', label: 'Trade Id', minWidth: '0px', align: 'left', },
  { id: 'FullName', label: 'Reference', minWidth: '0px', align: 'left', },
  { id: 'TradeDate', label: 'Date', minWidth: '0px', align: 'left', },
  { id: 'ShortName', label: 'Type', minWidth: '0px', align: 'left', },
  { id: 'price', label: 'Price', minWidth: '0px', align: 'left', },
  { id: 'currancy', label: 'Status', minWidth: '20px', align: 'left', },
];


function Product({ Search, TradStatus, Refresh,showSnackbar }) {
  const ContextData = useContext(NoteContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [TotalPage, setTotalPage] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [tempData, setTempData] = useState();
  const [AllDetails, setAllDetails] = useState();
  const [TraddingData, setTraddingData] = useState([]);
  const [UpdateCompanyDetails, setUpdateCompanyDetails] = useState([]);
  const { GetTransactons, GetAddrsfininstvenues } = require("../../ESG/services/Fetch");

  // geting Transcations data
  const GetTransactions = async () => {
    try{
      showSnackbar("Loading data, please wait...", "info");
      const data = await GetTransactons(page, rowsPerPage, TradStatus);
      if (data?.transactions) {
        setTempData(data.transactions);
        showSnackbar("Data loaded successfully", "success");
        if (TraddingData?.length > 0) {
          setTraddingData([...TraddingData, ...tempData]);
          showSnackbar("Data loaded successfully", "success");
        } else {
          setTraddingData(data.transactions);
          showSnackbar("Data loaded successfully", "success");
        }
        setTotalPage(data.rows);
        showSnackbar("Data loaded successfully", "success");
      } else {
        // Handle the case when data.transactions is falsy
        showSnackbar("Error loading data. Please try again.", "error")
        console.error("Data is missing or invalid.");
      }
  }
  catch (error) {
    ContextData.setUserLogInError({
      message: error.message,
      Errors: true
    });
    // Handle errors without changing the operation
    showSnackbar("Error loading data. Please try again.", "error")
    console.error("An error occurred:", error);
  }
  };


  useEffect(() => {
    GetTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, TradStatus, Refresh])

  useEffect(() => {
    const GetDetails = async () => {
      try {
        showSnackbar("Loading data, please wait...", "info");
        const data = await GetAddrsfininstvenues(UpdateCompanyDetails?.cplei);
        setAllDetails(data);
        showSnackbar("Data loaded successfully", "success");
      } catch (error) {
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        showSnackbar("Error loading data. Please try again.", "error")
        console.error("An error occurred:", error);
      }
    };
    if (UpdateCompanyDetails) {
      GetDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UpdateCompanyDetails]);
  
  // console.log(Object.values(UpdateCompanyDetails)[0])
  // console.log(Object.keys(UpdateCompanyDetails).length)
  // handle Company Details in Card
  const handleUpdate = (row, i) => {
    setUpdateCompanyDetails(row)
    setSelectedRowIndex(i)
  }

  //pagination for table handle change page 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

  };
  //pagination for table handle Change Rows Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Grid container spacing={0}  >
      <Grid item md={7} sm={12} xs={12} lg={7}>
        <TableContainer className="TableContainer">
          <Table stickyHeader aria-label="sticky table" >
            <TableHead >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="Table_Head"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {TraddingData && TraddingData.filter((val) => {
                if (Search === "" || Search === null) {
                  return val;
                } else if (
                  val.trade.txreference
                    .toLowerCase()
                    .includes(Search.trim().toLowerCase()) || val.trade.tradestate.toLowerCase()
                      .includes(Search.trim().toLowerCase())
                ) {
                  return val;
                }
              }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                <TableRow
                  style={{ backgroundColor: i === selectedRowIndex ? '#c4c4ff' : '' }}
                  onClick={() => handleUpdate(row, i)}
                  key={i}
                  className={i % 2 === 0 ? "TableRowOdd" : 'TableRowEven'}
                >
                  <TableCell component="th" scope="row" >{row?.trade.tradeid ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.trade.txreference ?? 'undefined'}</TableCell>
                  <TableCell align="left">{moment(row?.trade.tradedate).format("DD-MM-YYYY HH:mm:ss") ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.trade.txtype ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.trade.price ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.trade.tradestate ?? 'undefined'}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={Math.floor(TotalPage)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>

      <Grid item md={5} sm={12} xs={12} lg={5} className="Card_Section">
        {UpdateCompanyDetails.length !== 0 && <PopupCard Message={UpdateCompanyDetails?.trade?.msg ?? 'undefined'} />}
        {/* <div>
      <Tabs value={activeTab} onChange={handleTabChange}>
        {tabsData.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <div>{tabsData[activeTab].content}</div>
    </div> */}
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 6, sm: 9, md: 12, lg: 12 }} elevation={15} >
          {UpdateCompanyDetails && Object.entries(UpdateCompanyDetails).map((details, i) => (
            <Grid key={i} item xs={6} sm={4.5} md={6} lg={6}  >
              <DynamicCard
                key={i}
                Card_header={details[0]}
                ExpandMore_card_data={<ObjectViewer data={details[1]} val={[3]} />}
                Main_card_data={<ObjectViewer data={details[1]} val={[0, 3]} />}
              />
            </Grid>
          ))}</Grid>
      </Grid>
    </Grid>
  );
}

export default withSnackbar(Product, "Product paage", "success");