import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid, TextField } from '@mui/material';
import { event } from 'jquery';

function RejectReason({ open, handleClose,SetReasonForReject,handleJustClose }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Give me the reason for Rejecting this.
        </DialogContentText>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Reason"
              variant="outlined"
              onChange={(event)=>{SetReasonForReject(event.target.value)}}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleJustClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="secondary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RejectReason;
