import { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import "../styles/Forms_Styles/Form_EditableList.css";
import React from "react";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { useContext } from "react";
import NoteContext from "context/PageContext";
const EditableList = ({ list, setList, setEditList }) => {
  const handleComplete = (Lists) => {
    setList(
      list?.map((item) => {
        if (item.id === Lists.id) {
          return { ...item, completed: !item.completed };
        }
        return item;
      })
    );
  };

  const handleEdit = ({ id }) => {
    const findList = list.find((Lists) => Lists.id === id);
    setEditList(findList);
  };

  const handleDelete = ({ id }) => {
    setList(list.filter((Lists) => Lists.id !== id));
  };
  // console.log(List);
  return (
    <Box>
      <List>
        {list.map((Lists) => (
          <ListItem disablePadding
            className="list-item"
            key={Lists.id}
            style={{ border: "1px solid #DFE1E3" }}
            secondaryAction={
              <>
                <IconButton onClick={() => handleEdit(Lists)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(Lists)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemButton role={undefined} dense>
              <ListItemIcon onClick={() => handleComplete(Lists)}>
                <Checkbox
                  edge="start"
                  checked={()=>{
                     try {
                      return JSON.parse(Lists.completed) || Lists.completed === true
                     } catch (error) {
                      return  false;
                     }
                  }
                    
                  }
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={Lists.title} primary={Lists.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const Inputfelid = ({
  input,
  setInput,
  List,
  setList,
  setEditList,
  EditList,
}) => {
  const updateList = (title, id, completed) => {
    const newList = List.map((Lists) =>
      Lists.id === id ? { title, id, completed } : Lists
    );
    setList(newList);
    setEditList("");
  };
  useEffect(() => {
    if (EditList) {
      setInput(EditList.title);
    } else {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInput, EditList]);

  const [errorMessage, setErrorMessage] = useState('');


  const OnInputChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);

    // Check if the input is empty
    if (inputValue.trim() === '') {
      setErrorMessage('Input cannot be empty');
    } else {
      setErrorMessage('');
      setInput(event.target.value);
    }
  };
  const onFormSubmit = (event) => {
    event.preventDefault();
    if (!EditList) {
      if (input !== "") {
        setList([...List, { id: uuidv4(), title: input, completed: false }]);
        setInput("");
        setErrorMessage('');
      }
      else {
        setErrorMessage('Input cannot be empty');
        return;
      }
    } else {
      updateList(input, EditList.id, EditList.completed);
    }
  };

  return (
    <Box sx={{ alignItems: "center", alignContent: 'center' }}>
      <TextField
        id="standard-basic"
        variant="outlined"
        placeholder="Enter a value"
        size="small"
        value={input}
        required
        onChange={OnInputChange}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
      />
      <Button
        variant="outlined"
        onClick={onFormSubmit}
        size="medium"
        sx={{
          backgroundColor: '#E9F6F4',
          border: '1px solid #CBE5E1',
          textTransform: "capitalize",
          color: '#25A392',
          borderRadius: '6px',
          padding: "5px 10px",
          '&:hover': {
            backgroundColor: '#E9F6F4',
            border: '1px solid #CBE5E1',
          },
          marginLeft: "10px"
        }}
        startIcon={EditList ? <DoneOutlinedIcon /> : <AddIcon />}
      >
        {EditList ? "Save" : "Add"}
      </Button>
    </Box>
  );
};

const Form_EditableList = ({ onChange,getAnswerDisable }) => {
  const {Answer}=useContext(NoteContext);
  let List=[]
  try {
    
     List=typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : []
  } catch (error) {
    List=[];
  }

  const [input, setInput] = useState("");

  const [EditList, setEditList] = useState(null);

  return (
    <Box>
      <Inputfelid
        input={input}
        setInput={setInput}
        List={List}
        setList={onChange}
        EditList={EditList}
        setEditList={setEditList}
        disabled={getAnswerDisable}
      />
      <Box >
        <EditableList list={List} setList={onChange} setEditList={setEditList} />
      </Box>
    </Box>
  );
};

export default Form_EditableList;
