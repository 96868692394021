import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  Typography,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {
  answerTypesDropdown,
  addSectionQuestion,
  addSubSectionQuestion,
  getDropDownListApi,
  dropdownEdgeCreation,
  addCheckboxAndRadioButton,
  addTableData,
  fetchDisclosure,
  getTableCuurencyUrl,
  FetchGetData,
  fetchEditIcon,
  fetchquestion,
  DeleteSection,
} from "../services/FormCreationFetch";
import { Tooltip } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
// import { DataContext } from "../context/DataContext";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Chip from "@mui/material/Chip";

import NoteContext from "context/PageContext";

import EditSubSection from "./EditSubSection";

import Modal from "components/Modal";

let disclosurename = "disclosurename";

const YourDialogComponent = ({
  open,
  onClose,
  formValues,
  data,
  inputValues,
  HandleGetAIPromt,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [sampleprompt, setSampleprompt] = useState(
    "Instruction: This question is about {COMPANY}. Question: {question} Field type: {fieldType}  Field value: {fieldValue} Answer in JSON format:{JSON}."
  );
  let company = "microsoft";

  const AddAIPrompt = async () => {
    const updatedPrompt = sampleprompt.replace(
      /{COMPANY}|{question}|{fieldType}|{fieldValue}|{JSON}/g,
      (match) =>
        ({
          "{COMPANY}": company,
          "{question}": formValues.question,
          "{fieldType}": data.AnswerType,
          "{fieldValue}": data.AnswerType,
          "{JSON}": JSON.stringify(inputValues, null, 2),
        }[match])
    );
    setSampleprompt(updatedPrompt);
    HandleGetAIPromt(updatedPrompt);
    // console.log(inputValues);
  };
  useEffect(() => {
    AddAIPrompt();
  }, []);
  return (
    <Modal open={open} setOpen={onClose}>
      <TextField
        fullWidth
        multiline
        rows={4}
        id="AI response Promt"
        name="questionAI"
        label="AI response Promt"
        type="text"
        required
        value={sampleprompt}
        // onChange={(e)=>{HandlechnageForAI(e)}}
      />

      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </Modal>
  );
};

//creating Sub-Section Question
const NewQuestion = (props) => {
  const contextData = useContext(NoteContext);
  const [formValues, setFormValues] = useState([]);
  const disclosureNameFromContext = useContext(NoteContext);
  const [editIcon, setIconEDit] = React.useState(false);
  const [showMyComponent, setShowMyComponent] = useState(false);
  const [Error, setError] = useState();
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState({
    Question: "",
    QuestionType: "",
    AnswerType: "",
    HashTags: [],
  });

  const [openDeleteModal, setopenDeleteModal] = React.useState(false);
  const [formFields, setFormFields] = useState([
    { id: 1, Name: "", Field: "" },
  ]);
  const [DropdownValue, setDropdownValue] = useState();
  const [dropdownApiDataValues, setDropdownApiDataValues] = useState();
  const [dropdownApiData, setDropdownApiData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [sections, setSections] = useState([]);
  const [additionalInput, setAdditionalInput] = useState("");
  // const question_popup_from_context = useContext(DataContext);

  const [inputValues, setInputValues] = useState([
    { id: 1, Name: "", Type: "" },
  ]);
  // const [DropdownValue, setDropdownValue] = useState('');

  const [SubSectionData, setSubSectionData] = useState();
  const [valueForTableCurrencyDropdown, setValueForTableCurrencyDropdown] =
    useState("");

  const [samplepromt, setsamplepromt] = useState(
    "Instruction: This question is about {COMPANY}. Question: {question} Field type: {fieldType} Answer in JSON format:{JSON}."
  );

  const [AIData, SetAIData] = useState({
    question: "",
    oai: "",
    bard: "",
    datasource: "",
  });

  const JSONData = [
    {
      "List of Address": {
        addresses: {
          newStreet: "",
          newStreet2: "",
          newCity: "",
          newState: "",
          newCountry: {
            CountryName: "",
            CountryCode: "",
          },
          newZip: "",
        },
      },
      "Boolean Field":
        "BooleanValue true or false (I just need the value without key in flower braces)",
      "Check Box":
        "{Select one from dropdown(I just need the value without key in flower braces)}",
      "Currency Field":
        "{Units+Currency Value(I just need the value without key in flower braces)}",
    },
  ];

  const fetchEditData = async () => {
    await FetchGetData(disclosurename).then((data) => {
      // console.log("Data", data._items)
      // setFormValues(copyItem)
    });
  };

  useEffect(() => {
    const AnswertypeData = async () => {
      await answerTypesDropdown().then((data) => {
        setDropdownValue(data._items);
      });
    };

    const dropdownApiData = async () => {
      await getDropDownListApi().then((data) => {
        setDropdownApiDataValues(data.values);
      });
    };
    AnswertypeData();
    dropdownApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIconEDit(false);
  };

  const handleEditIcon = () => {
    // console.log("first");
    setShowMyComponent(true);
    //console.log(showMyComponent);
    // fetchEditData();
    // setIconEDit(true)
  };
  const handleSave = async () => {
    // console.log("page is hitting");
    await fetchEditIcon(disclosurename, formValues).then((data) => {
      //  console.log(data);
      //setExisting_disclosure(data._items[0]);
    });
  };

  const handleInputChange = (e) => {
    Error && setError(null);
    let { name, value } = e.target;
    //console.log(name);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleTagChange = (event) => {
    setAdditionalInput(event.target.value);
  };

  // Handle Add HashTags
  const handleAddTag = () => {
    if (additionalInput.trim() !== "") {
      setdata((prevData) => ({
        ...prevData,
        HashTags: [...prevData.HashTags, additionalInput.trim()],
      }));
      setAdditionalInput("");
    }
  };

  //function Delete HashTags
  const handleDeleteTag = (tagToDelete) => {
    setdata((prevData) => ({
      ...prevData,
      HashTags: prevData.HashTags.filter((tag) => tag !== tagToDelete),
    }));
  };

  // functions for formField

  const addField = () => {
    const newField = {
      id: formFields.length + 1,
      Name: "",
      Field: "",
    };
    setFormFields([...formFields, newField]);
  };

  const removeField = (id) => {
    const updatedFields = formFields.filter((field) => field.id !== id);
    setFormFields(updatedFields);
  };

  const handleTextChange = (id, value) => {
    const updatedFields = formFields.map((field) =>
      field.id === id ? { ...field, Name: value } : field
    );
    setFormFields(updatedFields);
  };

  const handleDropdownChange = (id, value) => {
    const updatedFields = formFields.map((field) =>
      field.id === id ? { ...field, Field: value } : field
    );
    setFormFields(updatedFields);
  };

  const handleQuestion = async () => {
    const dataFromLocalStorage = "";
    // const disclosureId = JSON.parse(localStorage.getItem("disclosurename"));
    let disclosureId;
    if (contextData.disclosureName) {
      disclosureId = contextData.disclosureName;
    } else {
      disclosureId = JSON.parse(localStorage.getItem("disclosurename"));
    }
    //console.log(disclosureId);
    const subsectionId = props.id;
    let demoData = dataFromLocalStorage
      ? dataFromLocalStorage
      : { title: disclosureId, sections: [] };
    // const NewQuestion = {
    //   title: formValues.question,
    //   subSectionId: subsectionId,
    //   questions: [],
    // };
    //console.log(demoData);
    // eslint-disable-next-line array-callback-return
    demoData.sections.map((data) => {
      console.log(("&&&&&&&&&&&&", data));
      Object.entries(data).map((subdata) => {
        console.log(subdata[0]);
        if (subdata[0] === "subsections") {
          subdata[1].map((value) => {
            if (value.sectionId === props.id) {
              console.log("subdata");
              value.questions.push({ title: formValues.question });
            }
          });
        }
      });
    });

    let answervalue = "";
    if (data.AnswerType === "Table") {
      answervalue = JSON.stringify({ ColumnLabel: { Columns: inputValues } });
    } else if (data.AnswerType === "Form Field") {
      answervalue = JSON.stringify({ subQuestion: formFields });
    }

    addSubSectionQuestion(
      disclosureId,
      subsectionId,
      formValues.question,
      formValues.questionid,
      formValues.questiondescription,
      formValues.questionhelpertext,
      formValues.questionId,
      AIData,
      data.AnswerType,
      answervalue
    ).then((res) => {
      console.log(res);
      if (res?.message) {
        setError(res.message);
      } else {
        if (
          data.AnswerType === "Check Box" ||
          data.AnswerType === "Radio Button"
        ) {
          addCheckboxAndRadioButton(
            formValues.questionid,
            data.HashTags.toString()
          );
        } else if (
          data.AnswerType === "Drop Down List" ||
          data.AnswerType === "Drop down list"
        ) {
          dropdownEdgeCreation(formValues.question, dropdownApiData);
        } else if (data.AnswerType === "Table") {
          addTableData(formValues.question, JSON.stringify(inputValues));
          for (const item of inputValues) {
            if (item.Type === "Drop Down List") {
              dropdownEdgeCreation(formValues.question, item.dropdownApi);
            }
          }
        } else if (data.AnswerType === "Form Field") {
          for (const item of formFields) {
            if (item.Field === "Drop Down List") {
              dropdownEdgeCreation(formValues.question, item.dropdownApi);
            }
          }
        }
        props.handleClose();
        contextData.setReloadData(true);
      }
    });

    // console.log(formValues.question,dropdownApiData)
  };

  const handleNumInputChange = (event) => {
    const newField = {
      id: inputValues.length + 1,
      Name: "",
      Type: "",
    };
    setInputValues([...inputValues, newField]);
  };

  const handleInputChangeTxt = async (index, property, value) => {
    console.log(index, property, value);

    if (value === "Currency Field") {
      const getTableCurrencyData = async () => {
        const data = await getTableCuurencyUrl();
        // console.log(data.values[0].values);

        const newInputValues = [...inputValues];
        newInputValues[index] = {
          ...newInputValues[index],
          [property]: value,
          values: data.values[0].values,
        };

        // Send newInputValues to the backend with the added 'values' property
        // You can make an API call or use some other method to send data to the backend here
        console.log(newInputValues);

        setInputValues(newInputValues);
        setValueForTableCurrencyDropdown(data.values[0].values);
        // console.log(valueForTableCurrencyDropdown);
      };

      getTableCurrencyData();
    } else if (property === "Checkboxvalues") {
      console.log(value);
      const newInputValues = [...inputValues];
      newInputValues[index] = {
        ...newInputValues[index],
        [property]: value,
      };
      setInputValues(newInputValues);
    } else {
      if (property === "values") {
        const selectedItem = dropdownApiDataValues.find(
          (item) => item.name === value
        );
        // if(value === 'Check Box' || value === 'Radio Button'){
        console.log(value);
        // }
        if (selectedItem) {
          // Update the state with the selected item's values
          const newInputValues = [...inputValues];
          newInputValues[index] = {
            ...newInputValues[index],
            [property]: selectedItem.values,
            dropdownApi: value,
          };

          // Send selectedItem.values to the backend
          // You can make an API call or use some other method to send data to the backend here
          // console.log(selectedItem.values);
          // console.log(newInputValues,value)
          setInputValues(newInputValues);
          // console.log(newInputValues);
        }
      } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = {
          ...newInputValues[index],
          [property]: value,
        };

        if (value !== "Currency Field" && value !== "Dropdown") {
          delete newInputValues[index].values;
        }

        setInputValues(newInputValues);
        // console.log(newInputValues);
      }
    }
  };

  const fetchques = async (id) => {
    await fetchquestion(id).then(async (data) => {
      let Obj = {};
      data._items.map((data) => {
        if (data.label === "name") {
          Obj.SubSectionName = data?.value;
        }
        if (data.label === "HelpText") {
          Obj.SubSectionHelperText = data?.value;
        }
        if (data.label === "Description") {
          Obj.SubSectionDescription = data?.value;
        }
        if (data.label === "pk") {
          Obj.SubSectionID = data?.id.split("|")[0];
        }
      });
      setSubSectionData(Obj);
    });
  };
  useEffect(() => {
    fetchques(props.id);
  }, []);

  const HandleDeleteSection = async (id) => {
    await DeleteSection(id).then(() => {
      contextData.setReloadData(true);
    });
  };

  const filteredDropdownValue =
    DropdownValue &&
    DropdownValue?.filter(
      (option) =>
        option !== "Table" &&
        option !== "Form Field" &&
        option !== "Rich Text" &&
        option !== "Multiselect" &&
        option !== "Contact Information" &&
        option !== "Radio Button"
    );
  let FieldTypesForTable =
    filteredDropdownValue &&
    filteredDropdownValue?.filter(
      (option) =>
        option !== "List of Address" &&
        option !== "Date Range" &&
        option !== "TelephoneNumber" &&
        option !== "URL Field" &&
        option !== "Email Field" &&
        option !== "Check Box"
    );
  FieldTypesForTable?.push("Boolean");

  const UpdatetheAIField = async (data) => {
    console.log(data);
    let JsonForAI = JSONData[0][`${data}`];

    setsamplepromt(samplepromt.replace("{JSON}", JsonForAI));
    finalupdateAIData(JsonForAI);
  };
  const finalupdateAIData = async (JsonForAI) => {
    SetAIData({ ...AIData, question: samplepromt });
  };

  return (
    <div>
      {props.condtion[0].editButton && (
        <Tooltip title="Add Question">
          <IconButton
            size="small"
            id="add"
            aria-label="Add new Section"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClickOpen}
            color="inherit"
          >
            <NoteAddIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </Tooltip>
      )}
      {props.condtion[0].editButton && (
        <IconButton>
          <EditIcon sx={{ fontSize: "18px" }} onClick={handleEditIcon} />
        </IconButton>
      )}
      {props.condtion[0].delete && (
        <IconButton>
          <DeleteIcon
            sx={{ fontSize: "18px" }}
            onClick={() => setopenDeleteModal(true)}
          />

          <Modal
            open={openDeleteModal}
            setOpen={setopenDeleteModal}
            title={"Are you sure? You want to delete this?"}
            actions={
              <>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#25A392",
                    "&:hover": {
                      backgroundColor: " #25a392",
                    },
                  }}
                  onClick={() => HandleDeleteSection(props.id)}
                >
                  Delete
                </Button>
              </>
            }
            maxWidth={"xs"}
          ></Modal>
        </IconButton>
      )}
      {/* <IconButton><DisplaySettingsIcon sx={{ fontSize: '18px' }} /></IconButton> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editIcon ? "Edit the question" : "Create new question"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="question-input"
                name="question"
                label="question"
                type="text"
                value={formValues.question}
                onChange={handleInputChange}
              />
              {/* <Tooltip title="about question">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip> */}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="questionid-input"
                name="questionid"
                label="question id"
                type="text"
                required
                error={Error}
                helperText={Error}
                value={formValues.questionid}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questiondescription-input"
                name="questionId"
                label="Display Name"
                type="text"
                required
                value={formValues.questionId}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questiondescription-input"
                name="questiondescription"
                label="question description"
                type="text"
                required
                value={formValues.questiondescription}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questionhelpertext-input"
                name="questionhelpertext"
                label="question helpertext"
                placeholder="Question Helpertext"
                type="text"
                required
                value={formValues.questionhelpertext}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={6}>
              {DropdownValue && (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={DropdownValue}
                  value={formValues.AnswerType}
                  onChange={(e, data) => {
                    setdata((prevstate) => {
                      return { ...prevstate, AnswerType: data };
                    });
                    setsamplepromt(samplepromt.replace("{fieldType}", data));
                    UpdatetheAIField(data);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Answer Type" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {data.AnswerType === "Drop Down List" ||
              data.AnswerType === "Drop down list" ? (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={dropdownApiDataValues.map((item) => item.name)}
                  value={formValues.dropdownApiData}
                  onChange={(e, data) => setDropdownApiData(data)}
                  renderInput={(params) => (
                    <TextField {...params} label="Api" />
                  )}
                />
              ) : (
                ""
              )}
            </Grid>
            {data.AnswerType === "Check Box" ||
            data.AnswerType === "Radio Button" ? (
              // <TextField
              //   id="additional-input"
              //   name="additionalinput"
              //   label="Additional Input"
              //   type="text"
              //   required
              //   style={{ width: 300 }}
              //   value={formValues.additionalinput}
              //   onChange={handleInputChange}
              // />
              <Grid item xs={6} md={6}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    label="Additional Input"
                    value={formValues.additionalInput}
                    // className="Input_Fields_hashTags"
                    required
                    onChange={handleTagChange}
                    style={{ flexDirection: "column" }}
                    placeholder="Additional Input"
                  />
                  <Button
                    variant="contained"
                    component="span"
                    // className="button_HashTags"
                    onClick={handleAddTag}
                    style={{ flexDirection: "column" }}
                  >
                    +
                  </Button>
                </div>
                {data?.HashTags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={`${tag}`}
                    onDelete={() => handleDeleteTag(tag)}
                  />
                ))}
              </Grid>
            ) : (
              ""
            )}
            {data.AnswerType === "Table" || data.AnswerType === "table" ? (
              <div>
                {inputValues.map((input, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <TextField
                      label={"Text Input"}
                      value={input.Name}
                      onChange={(event) =>
                        handleInputChangeTxt(index, "Name", event.target.value)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    />

                    {DropdownValue && (
                      <Autocomplete
                        options={FieldTypesForTable}
                        value={input.Type}
                        onChange={(_, newValue) =>
                          handleInputChangeTxt(index, "Type", newValue)
                        }
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 2,
                          width: 250,
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Field Type" />
                        )}
                      />
                    )}

                    {input.Type === "Drop Down List" ||
                    input.Type === "Drop down list" ? (
                      <>
                        <Autocomplete
                          id="combo-box-demo"
                          options={dropdownApiDataValues.map(
                            (item) => item.name
                          )}
                          value={input?.dropdownApi}
                          onChange={(_, Values) =>
                            handleInputChangeTxt(index, "values", Values)
                          }
                          //  handleInputChangeTxt(index, 'values',`/fillings/table/${Values}`)}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            width: 250,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Api" />
                          )}
                        />
                      </>
                    ) : (
                      // dropdownApiDataValues.map((item)=>{
                      //   console.log(item.values);
                      // })
                      ""
                    )}
                  </div>
                ))}
                <Grid item>
                  <Button
                    sx={{ margin: "5px 0px 5px 10px" }}
                    onClick={handleNumInputChange}
                  >
                    Add Field
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
            {data.AnswerType === "Form Field" ? (
              <div>
                {formFields.map((field) => (
                  <div
                    key={field.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <TextField
                      label="Text Input"
                      value={field.Name}
                      onChange={(e) =>
                        handleTextChange(field.id, e.target.value)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    />
                    <Autocomplete
                      id={`combo-box-demo-`}
                      options={filteredDropdownValue}
                      value={field.Field}
                      // onChange={(_, newValue) => handleInputChangeTxt(index, 'Type', newValue)}
                      onChange={(e, data) =>
                        handleDropdownChange(field.id, data)
                      }
                      // sx={{ width: 300 }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                        width: 250,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Field Type" />
                      )}
                    />
                    {/* <>{field?.Field}</> */}
                    {(field?.Field === "Drop Down List" ||
                      field?.Field === "Drop down list") && (
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={dropdownApiDataValues.map((item) => item.name)}
                        value={field?.dropdownApi}
                        onChange={(e, data) => {
                          const updatedFields = formFields.map((field) =>
                            field.id === field.id
                              ? {
                                  ...field,
                                  values: dropdownApiDataValues.find(
                                    (item) => item.name === data
                                  )?.values,
                                  dropdownApi: data,
                                }
                              : field
                          );
                          setFormFields(updatedFields);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Api" />
                        )}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 2,
                          width: 250,
                        }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeField(field.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
                <Grid item>
                  <Button
                    sx={{ margin: "5px 0px 5px 10px" }}
                    onClick={addField}
                  >
                    Add Field
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={editIcon ? handleSave : handleQuestion}>
            {editIcon ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      {showMyComponent && props.condtion[0].editButton &&(
        <EditSubSection
          open={true}
          secName={SubSectionData?.SubSectionName}
          secId={SubSectionData?.SubSectionID}
          secDis={SubSectionData?.SubSectionDescription}
          secHelp={SubSectionData?.SubSectionHelperText}
          handleClose={props.handleClose}
        />
      )}
    </div>
  );
};
//creating Section Question
export const SectionNewQuestion = (props) => {
  //AI states
  const [openAI, setOpenAI] = useState(false);
  const contextData = useContext(NoteContext);
  const [formValues, setFormValues] = useState([]);
  const disclosureNameFromContext = useContext(NoteContext);
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState({
    Question: "",
    QuestionType: "",
    AnswerType: "",
    DataFromApi: "",
    HashTags: [],
    HashTagsTable: [],
  });
  const [formFields, setFormFields] = useState([
    { id: 1, Name: "", Field: "" },
  ]);

  const [DropdownValue, setDropdownValue] = useState();
  const [dropdownApiDataValues, setDropdownApiDataValues] = useState();
  const [dropdownApiData, setDropdownApiData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [sections, setSections] = useState([]);
  const [additionalInput, setAdditionalInput] = useState([]);
  const [additionalInputTable, setAdditionalInputTable] = useState([]);
  // const question_popup_from_context = useContext(DataContext);

  const [inputValues, setInputValues] = useState([
    { id: 1, Name: "", Type: "" },
  ]);
  // const [DropdownValue, setDropdownValue] = useState('');

  const [selectedItem, setSelectedItem] = useState(null);
  const [valueForTableCurrencyDropdown, setValueForTableCurrencyDropdown] =
    useState("");
  const [Error, setError] = useState();
  const [AIData, SetAIData] = useState({
    question: "",
    oai: "",
    bard: "",
    datasource: "",
  });
  const [openAIModal, setopenAIModal] = useState(false);

  useEffect(() => {
    const AnswertypeData = async () => {
      await answerTypesDropdown().then((data) => {
        if (data) {
          setDropdownValue(data._items);
        }
      });
    };

    const dropdownApiData = async () => {
      await getDropDownListApi().then((data) => {
        if (data) {
          setDropdownApiDataValues(data.values);
        }
      });
    };

    AnswertypeData();
    dropdownApiData();

    // setOpen();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setIconEDit(false);
  };

  const handleInputChange = (e) => {
    Error && setError(null);
    let { name, value } = e.target;
    if (name === "questionAI") {
      name = "prompt";
      setFormValues({
        ...formValues,
        [name]: value,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleTagChange = (event) => {
    setAdditionalInput(event.target.value);
  };

  // Handle Add HashTags
  const handleAddTag = () => {
    if (additionalInput.trim() !== "") {
      setdata((prevData) => ({
        ...prevData,
        HashTags: [...prevData.HashTags, additionalInput.trim()],
      }));
      setAdditionalInput("");
    }
  };

  const handleAddTagTable = () => {
    console.log(additionalInputTable);
    // if (additionalInputTable.trim() !== "") {
    //   setdata((prevData) => ({
    //     ...prevData,
    //     HashTagsTable: [...prevData.HashTagsTable, additionalInputTable.trim()],
    //   }));
    //   setAdditionalInputTable("");
    // }
  };

  //   const handleAddTagTable = () => {
  //   if (inputValues.trim() !== '') {
  //     setHashtagsTable((prevTags) => [...prevTags, inputValues.trim()]);
  //     setAdditionalInputTable('');
  //   }
  // };

  //function Delete HashTags
  const handleDeleteTag = (tagToDelete) => {
    setdata((prevData) => ({
      ...prevData,
      HashTags: prevData.HashTags.filter((tag) => tag !== tagToDelete),
    }));
  };

  // functions for formField

  const addField = () => {
    const newField = {
      id: formFields.length + 1,
      Name: "",
      Field: "",
    };
    setFormFields([...formFields, newField]);
  };

  const removeField = (id) => {
    const updatedFields = formFields.filter((field) => field.id !== id);
    setFormFields(updatedFields);
  };

  const handleTextChange = (id, value) => {
    const updatedFields = formFields.map((field) =>
      field.id === id ? { ...field, Name: value } : field
    );
    setFormFields(updatedFields);
  };

  const handleDropdownChange = (id, value) => {
    console.log(value, id);
    const updatedFields = formFields.map((field) =>
      field.id === id ? { ...field, Field: value } : field
    );
    console.log(updatedFields);
    setFormFields(updatedFields);
  };

  const handleQuestion = async () => {
    var dataFromLocalStorage = "";
    const sectionId = props.id;
    let disclosureId;
    if (contextData.disclosureName) {
      disclosureId = contextData.disclosureName;
    } else {
      disclosureId = JSON.parse(localStorage.getItem("disclosurename"));
    }
    localStorage.setItem("myDisclosure", JSON.stringify(dataFromLocalStorage));
    setSections(dataFromLocalStorage);

    let answervalue = "";
    if (data.AnswerType === "Table") {
      answervalue = JSON.stringify({ ColumnLabel: { Columns: inputValues } });
    } else if (data.AnswerType === "Form Field") {
      answervalue = JSON.stringify({ subQuestion: formFields });
    }
    addSectionQuestion(
      disclosureId,
      sectionId,
      formValues.question,
      formValues.questionid,
      formValues.questiondescription,
      formValues.questionhelpertext,
      formValues.questionId,
      AIData,
      data.AnswerType,
      answervalue
    ).then((res) => {
      // console.log(res);
      if (res.message) {
        setError(res.message);
      } else {
        if (
          data.AnswerType === "Check Box" ||
          data.AnswerType === "Radio Button"
        ) {
          addCheckboxAndRadioButton(
            formValues.questionid,
            data.HashTags.toString()
          );
        } else if (
          data.AnswerType === "Drop Down List" ||
          data.AnswerType === "Drop down list"
        ) {
          dropdownEdgeCreation(formValues.question, dropdownApiData);
        } else if (data.AnswerType === "Table") {
          addTableData(formValues.question, JSON.stringify(inputValues));
          for (const item of inputValues) {
            //console.log(item.Type);
            if (item.Type === "Drop Down List") {
              dropdownEdgeCreation(formValues.question, item.dropdownApi);
            }
          }
        } else if (data.AnswerType === "Form Field") {
          for (const item of formFields) {
            if (item.Field === "Drop Down List") {
              dropdownEdgeCreation(formValues.question, item.dropdownApi);
            }
          }
        }
        props.handleClose();
        contextData.setReloadData(true);
      }
    });
  };

  const handleNumInputChange = (event) => {
    const newField = {
      id: inputValues.length + 1,
      Name: "",
      Type: "",
    };
    setInputValues([...inputValues, newField]);
  };

  // const handleInputChangeTxt = (index, property, value) => {

  //   console.log(index, property, value)
  //   if(property === 'values'){
  //       const selectedItem = dropdownApiDataValues.find(item => item.name === value);

  //   if (selectedItem) {
  //     // Update the state with the selected item's name
  //     // selectedItem.values = value;
  //     setSelectedItem(selectedItem.values);

  //     // Send selectedItem.values to the backend
  //     // You can make an API call or use some other method to send data to the backend here
  //     console.log(selectedItem.values);
  //      const newInputValues = [...inputValues];
  //       newInputValues[index] = {...newInputValues[index],[property]: selectedItem.values,};
  //   }
  //   }

  //   const newInputValues = [...inputValues];
  //   newInputValues[index] = {...newInputValues[index],[property]: value,};
  //   setInputValues(newInputValues);
  // };

  // const handleInputChangeTxt = (index, property, value) => {
  //   console.log(index, property, value);
  //   if(value==='Currency Field'){
  //     const getTableCurrencyData = async () => {
  //       await getTableCuurencyUrl().then((data) => {
  //         console.log(data.values[0].values);
  //         // Assuming you want to set the 'value' property to the fetched data
  //         const newInputValues = [...inputValues];
  //         newInputValues[index] = {
  //           ...newInputValues[index],
  //           [property]: data.values[0].values,
  //         };
  //         setInputValues(newInputValues);
  //         console.log(newInputValues);
  //       });
  //     }
  //     getTableCurrencyData();
  //   }
  //   if (property === 'values') {
  //     const selectedItem = dropdownApiDataValues.find(item => item.name === value);

  //     if (selectedItem) {
  //       // Update the state with the selected item's values
  //       const newInputValues = [...inputValues];
  //       newInputValues[index] = {
  //         ...newInputValues[index],
  //         [property]: selectedItem.values,
  //       };

  //       // Send selectedItem.values to the backend
  //       // You can make an API call or use some other method to send data to the backend here
  //       console.log(selectedItem.values);

  //       // Update the state with the modified newInputValues
  //       setInputValues(newInputValues);
  //       setValueForTableDropdown(value);
  //       console.log(newInputValues);
  //     }
  //   } else {
  //     // If property is not 'values', update it with the provided value

  //     const newInputValues = [...inputValues];
  //     console.log(newInputValues);
  //     newInputValues[index] = {
  //       ...newInputValues[index],
  //       [property]: value,
  //     };

  //     // Update the state with the modified newInputValues
  //     setInputValues(newInputValues);
  //      console.log(newInputValues);
  //   }
  // };

  const handleInputChangeTxt = async (index, property, value) => {
    if (value === "Currency Field") {
      const getTableCurrencyData = async () => {
        const data = await getTableCuurencyUrl();
        // console.log(data.values[0].values);

        const newInputValues = [...inputValues];
        newInputValues[index] = {
          ...newInputValues[index],
          [property]: value,
          values: data.values[0].values,
        };

        // Send newInputValues to the backend with the added 'values' property
        // You can make an API call or use some other method to send data to the backend here
        //console.log(newInputValues);

        setInputValues(newInputValues);
        setValueForTableCurrencyDropdown(data.values[0].values);
        // console.log(valueForTableCurrencyDropdown);
      };

      getTableCurrencyData();
    } else if (property === "Checkboxvalues") {
      console.log(value);
      const newInputValues = [...inputValues];
      newInputValues[index] = {
        ...newInputValues[index],
        [property]: value,
      };
      setInputValues(newInputValues);
      setAdditionalInputTable(value);
    } else {
      if (property === "values") {
        const selectedItem = dropdownApiDataValues.find(
          (item) => item.name === value
        );
        // if(value === 'Check Box' || value === 'Radio Button'){
        console.log(value);
        // }
        if (selectedItem) {
          // Update the state with the selected item's values
          const newInputValues = [...inputValues];
          newInputValues[index] = {
            ...newInputValues[index],
            [property]: selectedItem.values,
            dropdownApi: value,
          };

          // Send selectedItem.values to the backend
          // You can make an API call or use some other method to send data to the backend here
          // console.log(selectedItem.values);
          // console.log(newInputValues,value)
          setInputValues(newInputValues);
          // console.log(newInputValues);
        }
      } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = {
          ...newInputValues[index],
          [property]: value,
        };

        if (value !== "Currency Field" && value !== "Dropdown") {
          delete newInputValues[index].values;
        }

        setInputValues(newInputValues);
        // console.log(newInputValues);
      }
    }
  };

  // const handleButtonClick = () => {
  //   setShowTable(true);
  // };

  const filteredDropdownValue =
    DropdownValue &&
    DropdownValue?.filter(
      (option) =>
        option !== "Table" &&
        option !== "Form Field" &&
        option !== "Rich Text" &&
        option !== "Multiselect" &&
        option !== "Contact Information" &&
        option !== "Radio Button"
    );
  let FieldTypesForTable =
    filteredDropdownValue &&
    filteredDropdownValue?.filter(
      (option) =>
        option !== "List of Address" &&
        option !== "Date Range" &&
        option !== "TelephoneNumber" &&
        option !== "URL Field" &&
        option !== "Email Field" &&
        option !== "Check Box"
    );

  FieldTypesForTable?.push("Boolean");

  //Ai functions

  return (
    <div>
      {
        props.condtion[0].editButton &&
      
      <Tooltip title="Add Question">
        <IconButton
          size="small"
          id="add"
          aria-label="Add new Section"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleClickOpen}
          color="inherit"
        >
          <QuestionAnswerIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      </Tooltip>
}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create new question</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="question-input"
                name="question"
                label="question"
                type="text"
                value={formValues.question}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="questionid-input"
                name="questionid"
                label="question id"
                type="text"
                required
                error={Error}
                helperText={Error}
                value={formValues.questionid}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questiondescription-input"
                name="questionId"
                label="Display Name"
                type="text"
                required
                value={formValues.questionId}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questiondescription-input"
                name="questiondescription"
                label="question description"
                type="text"
                required
                value={formValues.questiondescription}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="questionhelpertext-input"
                name="questionhelpertext"
                label="question helpertext"
                type="text"
                required
                value={formValues.questionhelpertext}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              {DropdownValue && (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={DropdownValue}
                  value={data.AnswerType}
                  onChange={(e, data) =>
                    setdata((prevstate) => {
                      return { ...prevstate, AnswerType: data };
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Answer Type" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {data.AnswerType === "Drop Down List" ||
              data.AnswerType === "Drop Down" ? (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={dropdownApiDataValues.map((item) => item.name)}
                  value={dropdownApiData}
                  onChange={(e, data) => setDropdownApiData(data)}
                  renderInput={(params) => (
                    <TextField {...params} label="Api" />
                  )}
                />
              ) : (
                ""
              )}
            </Grid>
            {data.AnswerType === "Check Box" ||
            data.AnswerType === "Radio Button" ? (
              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    fullWidth
                    label="Additional Input"
                    value={additionalInput}
                    // className="Input_Fields_hashTags"
                    required
                    onChange={handleTagChange}
                    placeholder="Additional Input"
                    style={{ flexDirection: "column", marginRight: 5 }}
                  />

                  <Button variant="contained" onClick={handleAddTag}>
                    Add
                  </Button>
                </div>
                {data?.HashTags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={`${tag}`}
                    onDelete={() => handleDeleteTag(tag)}
                    style={{ margin: "5px" }}
                  />
                ))}
              </Grid>
            ) : (
              ""
            )}
            {data.AnswerType === "Table" || data.AnswerType === "table" ? (
              <div>
                {inputValues.map((input, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <TextField
                      label={"Text Input"}
                      value={input.Name}
                      onChange={(event) =>
                        handleInputChangeTxt(index, "Name", event.target.value)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    />

                    {DropdownValue && (
                      <Autocomplete
                        options={FieldTypesForTable}
                        value={input.Type}
                        onChange={(_, newValue) =>
                          handleInputChangeTxt(index, "Type", newValue)
                        }
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 2,
                          width: 250,
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Field Type" />
                        )}
                      />
                    )}

                    {input.Type === "Drop Down List" ||
                    input.Type === "Drop down list" ? (
                      <>
                        <Autocomplete
                          id="combo-box-demo"
                          options={dropdownApiDataValues.map(
                            (item) => item.name
                          )}
                          value={input?.dropdownApi}
                          onChange={(_, Values) =>
                            handleInputChangeTxt(index, "values", Values)
                          }
                          //  handleInputChangeTxt(index, 'values',`/fillings/table/${Values}`)}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            width: 250,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Api" />
                          )}
                        />
                      </>
                    ) : (
                      // dropdownApiDataValues.map((item)=>{
                      //   console.log(item.values);
                      // })
                      ""
                    )}
                  </div>
                ))}
                <Grid item>
                  <Button
                    sx={{ margin: "5px 0px 5px 10px" }}
                    onClick={handleNumInputChange}
                  >
                    Add Field
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
            {data.AnswerType === "Form Field" ? (
              <div>
                {formFields.map((field) => (
                  <div
                    key={field.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <TextField
                      label="Text Input"
                      value={field.Name}
                      onChange={(e) =>
                        handleTextChange(field.id, e.target.value)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    />
                    <Autocomplete
                      id={`combo-box-demo-`}
                      options={filteredDropdownValue}
                      value={field.Field}
                      // onChange={(_, newValue) => handleInputChangeTxt(index, 'Type', newValue)}
                      onChange={(e, data) =>
                        handleDropdownChange(field.id, data)
                      }
                      // sx={{ width: 300 }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                        width: 250,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Field Type" />
                      )}
                    />
                    {/* <>{field?.Field}</> */}
                    {(field?.Field === "Drop Down List" ||
                      field?.Field === "Drop down list") && (
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={dropdownApiDataValues.map((item) => item.name)}
                        value={field?.dropdownApi}
                        onChange={(e, data) => {
                          const updatedFields = formFields.map((field) =>
                            field.id === field.id
                              ? {
                                  ...field,
                                  values: dropdownApiDataValues.find(
                                    (item) => item.name === data
                                  )?.values,
                                  dropdownApi: data,
                                }
                              : field
                          );
                          setFormFields(updatedFields);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Api" />
                        )}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 2,
                          width: 250,
                        }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeField(field.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
                <Grid item>
                  <Button
                    sx={{ margin: "5px 0px 5px 10px" }}
                    onClick={addField}
                  >
                    Add Field
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25a392",
              marginLeft: "10px",
              flexDirection: "row",
            }}
            onClick={handleQuestion}
          >
            Create
          </Button>
          <Button
            variant="text"
            style={{ color: "red", flexDirection: "row" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewQuestion;
