import React, { useEffect ,useState} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function Seventeen(props) {
    const [Questions, SetQuestions] = useState();
    const [Questions1, SetQuestions1] = useState();
    const [Answer, setAnswer] = useState();
    const [Answer1, setAnswer1] = useState();
    const [Isloading, setIsloading] = useState(false);
    const [QuestionNum, setQuestionNumber] = useState();
    const [QuestionNum1, setQuestionNumber1] = useState();
    useEffect(()=>{
        const FetchData = () => {
            const questions_array_b = [];
            const Answer_array_b = [];
            const QuestionNumber = [];
            //getting section 1b question in single array
      
            for (
              let i = 0;
              i < Object.keys(props.data.reportData[ "SEC Registration"]).length;
              i++
            ) {
              questions_array_b.push(
                Object.keys(props.data.reportData[ "SEC Registration"])[i]
              );
              Answer_array_b.push(
                Object.values(props.data.reportData[ "SEC Registration"])[i].answer
              );
              QuestionNumber.push(
                Object.values(props.data.reportData[ "SEC Registration"])[
                  i
                ].qno.substr(
                  7,
                  Object.values(props.data.reportData[ "SEC Registration"])[i].qno
                    .length
                )
              );
            }
            // console.log(questions_array_b);
            SetQuestions(questions_array_b);
            setAnswer(Answer_array_b);
           // console.log(Answer_array_b);
            setQuestionNumber(QuestionNumber);
          };
          const FetchData1 = () => {
            const questions_array_b = [];
            const Answer_array_b = [];
            const QuestionNumber = [];
            //getting section 1b question in single array
      
            for (
              let i = 0;
              i < Object.keys(props.data.reportData[ "Form of Organization"]).length;
              i++
            ) {
              questions_array_b.push(
                Object.keys(props.data.reportData[ "Form of Organization"])[i]
              );
              Answer_array_b.push(
                Object.values(props.data.reportData["Form of Organization"])[i].answer
              );
              QuestionNumber.push(
                Object.values(props.data.reportData[ "Form of Organization"])[
                  i
                ].qno.substr(
                  7,
                  Object.values(props.data.reportData[ "Form of Organization"])[i].qno
                    .length
                )
              );
            }
            // console.log(questions_array_b);
            SetQuestions1(questions_array_b);
            setAnswer1(Answer_array_b);
           // console.log(Answer_array_b);
            setQuestionNumber1(QuestionNumber);
          };




          FetchData1();
          FetchData();



          setIsloading(true);
    },[props])
  return (
    <div>{Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <ul
                style={{
                  listStyleType: "square",
                  textAlign: "left",
                  fontSize: "23px",
                }}
              >
                <li>SEC Registration</li>
                <li>Form of Organization</li>
              </ul>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                <div>
                  {Questions.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          <ol>{ Answer[index].map((itimes,index1)=>{
                            return <li key ={index1}>{itimes}</li> ; 
                            })}</ol>
                        <br />
                      </React.Fragment>
                    );
                  })}
                </div>
                <div>
                  {Questions1.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum1[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {index === 0 ?(<ol>{Answer1[index].map((itimes,index1)=>{
                            return <li key={index1}>{itimes}</li>
                          })}</ol>):( index === 1 ?(<React.Fragment>{Answer1[index].substr(0,10)}</React.Fragment>):
                          (<>

                            {Answer1[index].map((ele, index1) => (
  
                              <React.Fragment key={index1}>
  
                                {ele.newStreet}&nbsp;
  
                                {ele.newStreet2}&nbsp;
  
                                {ele.newCity}&nbsp;
  
                                {ele.newState}&nbsp;
  
                                {ele.newCountry.CountryName}&nbsp;
  
                                {ele.newZip}&nbsp;
  
                              </React.Fragment>
  
                            ))}
  
                          </>))}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
    )}</div>
  )
}

export default Seventeen