import Logo from 'ESG/Report/TCFD/Logo';
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import GDA1 from 'ESG/Report/TCFD/GDA1';
import { TcfdReportData } from "../services/ReportFetch";
import Content from 'ESG/Report/TCFD/Content';
import GDATable from 'ESG/Report/TCFD/GDATable';
import GDB1 from 'ESG/Report/TCFD/GDB1';
import GDBTable2 from 'ESG/Report/TCFD/GDBTable2';
import SDBTable from 'ESG/Report/TCFD/SDBTable';
import SDA1 from 'ESG/Report/TCFD/SDA1';
import SDA2 from 'ESG/Report/TCFD/SDA2';
import SDB1 from 'ESG/Report/TCFD/SDB1';
import SDB2 from 'ESG/Report/TCFD/SDB2';
import SDC1 from 'ESG/Report/TCFD/SDC1';
import SDC2 from 'ESG/Report/TCFD/SDC2';
import RDA1 from 'ESG/Report/TCFD/RDA1';
import RDA2 from 'ESG/Report/TCFD/RDA2';
import RDA3 from 'ESG/Report/TCFD/RDA3';
import RDB1 from 'ESG/Report/TCFD/RDB1';
import RDC1 from 'ESG/Report/TCFD/RDC1';
import MTDA1 from 'ESG/Report/TCFD/MTDA1';
import MTDA2 from 'ESG/Report/TCFD/MTDA2';
import MTDB1 from 'ESG/Report/TCFD/MTDB1';
import MTDBTable from 'ESG/Report/TCFD/MTDBTable';
import MTDCTable from 'ESG/Report/TCFD/MTDCTable';
import MTDC1 from 'ESG/Report/TCFD/MTDC1';




const OpenPage = (props) => {
  const { children, page, index } = props;
  return (
    <div hidden={page !== index}>{page === index && <Box>{children}</Box>}</div>
  );
};

const TCFDReportPage = () => {
  const [ReportDataset,setReportDataset]=useState();
  const [activePage, setActivePage] = useState(1);
  const [Isloading,setIsloading]=useState(false);

  useEffect(()=>{
    TcfdReportData().then((x)=>{
      setReportDataset(x.data);
       setIsloading(true);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
    {Isloading ? (
      <div>
    <div style={{marginTop:"65px"}}>
      <Box className="PageBox">
    <OpenPage page={activePage} defaultPage index={1}>
      <Logo/>
    </OpenPage>
    <OpenPage page={activePage} index={2}>
      <Content data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={3}>
      <GDA1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={4}>
      <GDATable data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={5}>
      <GDB1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={6}>
      <GDBTable2 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={7}>
      <SDA1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={8}>
      <SDA2 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={9}>
      <SDB1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={10}>
      <SDBTable data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={11}>
      <SDB2 data={ReportDataset}/>
    </OpenPage>
   <OpenPage page={activePage} index={12}>
      <SDC1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={13}>
      <SDC2 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={14}>
      <RDA1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={15}>
      <RDA2 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={16}>
      <RDA3 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={17}>
      <RDB1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={18}>
      <RDC1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={19}>
      <MTDA1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={20}>
      <MTDA2 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={21}>
      <MTDB1 data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={22}>
      <MTDBTable data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={23}>
      <MTDCTable data={ReportDataset}/>
    </OpenPage>
    <OpenPage page={activePage} index={24}>
      <MTDC1 data={ReportDataset}/>
    </OpenPage>
    

    </Box>
      <Pagination
        count={24}
        page={activePage}
        onChange={(event, newPage) => setActivePage(newPage)}
        size="medium"
        color="secondary"
        variant="outlined"
      />
  </div>
  <div>
  </div>
  </div>
  
    ): (
      <div className="loaders">Loading...</div>
    )
  }
  </>
  )
}

export default TCFDReportPage;