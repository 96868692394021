import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";



const ManagementMT = (props)  =>{
    const [potential,setpotential] = useState()
    const [business,setbusiness] = useState()
    const [regarding,setregarding]= useState()
    const [headerTable, setheaderTable] = useState();
    const [pageloaded, setpageloaded] = useState(false)
    
    const CreateHeader = () => {
      const column = Object.keys(headerTable[0]);
      const newcolumn = column.map((data) => {
        if (data !== "id" && data !== "isNew") {
          return <TableCell key={data}>{data}</TableCell>;
        }
        return null;
      });
  
     
      return newcolumn;
    };
  
    // get table row data
    const tdData = () => {
      return headerTable.map((data,index) => {
        return (
          <TableBody key={index}>
            <TableRow
              key={data}
              sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
            >
              {Object.keys(headerTable[0]).map((x,index) => {
                if (x !== "id" && x !== "isNew") {
                  return (
                    <TableCell key={index} component="th" scope="row">
                      {data[x]}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableBody>
        );
      });
    };


    useEffect (()=>{ 
        setpotential((Object.values(props.data.reportData["GRI 3.3 Management of material topics"])[0]).answer)
         setbusiness((Object.values(props.data.reportData["GRI 3.3 Management of material topics"])[1]).answer)
         setregarding((Object.values(props.data.reportData["GRI 3.3 Management of material topics"])[2]).answer)
         setheaderTable(
          Object.values(props.data.reportData["GRI 3.3 Management of material topics"])[7].answer
        );
        // setfeedback((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[4]).note)
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5 className="underline"> Management of material topics</h5>
                        <h6><b>Describe the actual and potential, negative and positive impacts on the economy, environment,
                             and people, including impacts on their human rights.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:potential}}></div>
                        <br></br>
                        <h6><b>Describe the activities or business relationships of the organization if it is involved with the negative impacts through 
                          its activities or as a result of its business relationships.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:business}}></div>
                        
                </Grid>
                <Grid item xs={12} md={5}  className="grid2">
                        <h6><b>Describe the activities or business relationships of the organization if it is involved with the negative impacts through 
                          its activities or as a result of its business relationships.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:regarding}}></div>
                        <h6><b>What are the goals, targets, and indicators used to evaluate progress for the management of material topics?</b></h6>
              <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 550, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable && CreateHeader()}
                  </TableRow>
                </TableHead>

                {headerTable && tdData()}
              </Table>
            </TableContainer>
          </div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default ManagementMT;