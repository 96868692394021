import * as React from 'react';
import { useGridApiContext } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/Forms_Styles/AutocompleteForTable.css'
import {getTableDropdownValues,AddNewDropDownValue} from 'ESG/services/Fetch';

const defaultFilterOptions = createFilterOptions();


function AutocompleteForTable(props) {

  const { id,value,field,questionId,values,startDate,domain} = props;
  const [selectedOptions, setSelectedOptions] = React.useState(value?value:null);
  const [dialogValue, setDialogValue] = React.useState();
  const [openDialog, toggleOpen] = React.useState(false);
  const apiRef = useGridApiContext();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open  && options?.length === 0;
  const islocation=values==="/filings/table/selectedLocation";

  React.useEffect(() => {
    let active = true;
    if (!open) {
      return undefined;
    }
    if(typeof values === 'string'){  //if it's an url path
     
    getTableDropdownValues(questionId,values,startDate,domain).then((res)=>{
     
      const options = islocation?JSON.parse(res.values[0]) : res.values;
       active && options && setOptions(options);
    })
    }else{
      active && values && setOptions(values);   //if it's array
    }
    return () => {
      active = false;
    };
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue
    });
  };

  const handleClose = () => {
    setDialogValue("");
    toggleOpen(false);
  };


  const PaperComponentCustom = options => {
    const { containerProps, children } = options;
    return (
      <Paper
     className="root"
      elevation={3} {...containerProps} square >
        {children}
      {typeof values === 'string' && !islocation &&
      <Button
        className="addNewValueButton"
        startIcon={<AddCircleOutlineIcon/>}
        fullWidth
        onMouseDown={(e) => {toggleOpen(true); e.stopPropagation();}}
    >
      Add a new value
    </Button>}
      </Paper>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    AddNewDropDownValue(questionId,dialogValue);
    handleClose();
  };

  return (
    <>
      <Autocomplete
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      className="autocompleteForTable"
      ListboxProps={
        {
          style:{
              maxHeight: '150px',
              fontSize:'14px',
              minWidth: "max-content",
              color: "rgba(0,0,0,.85)",
              fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"'
              ].join(","),
              WebkitFontSmoothing: "auto",
              letterSpacing: "normal",
          }
        }
      }

      PaperComponent={PaperComponentCustom}
      options={options}
      value={selectedOptions}
      renderInput={(params) => (
        <TextField {...params} placeholder="select"
        className='InputTextfield'
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
        }}
         variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}/>
      )}
      onChange={(_, newValue) => {
        handleChange(newValue);
         if (typeof newValue === "string") {
            setSelectedOptions(newValue);
         }
        }}
       renderOption={(props, option) =>typeof option==='string'?<li {...props}>{option}</li>:<li {...props}>{option.CountryName}</li>}
       getOptionLabel={(o) => (typeof o === "string" ? o :o.CountryName)}
        filterOptions={(options, state) => {
          const results = defaultFilterOptions(options, state);
          return results;
        }}
      />
      <Dialog open={openDialog} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new value</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you want to add a new value to the list? Please, add it!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="label"
              value={dialogValue}
              onChange={(event) => setDialogValue(event.target.value)}
              label="New value"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AutocompleteForTable;