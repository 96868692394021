import React, { useEffect, useState,useContext } from "react";
import {
  getTelephoneNumber,
  GetCurrancy,
  getFormDropdownValues,
} from "ESG/services/Fetch";
import Form_CheckboxWithTextField from "./Form_CheckBoxWithTextField";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Slider,
  InputAdornment,
} from '@mui/material';
import NoteContext from "context/PageContext";
import Input from "@mui/material/Input";

const Form_SubForm = ({ FormFieldJson, onChange,getAnswerDisable }) => {
  const [Form, setForm] = useState();
  const {Answer}=useContext(NoteContext);
  console.log(FormFieldJson);

  let Answers = {};
  try {
    Answers=typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : {}
  } catch (error) {
    console.log(error);
  }

  console.log(Answers)
  const handleChange = (answer, label) => {
    // Answers[label] = answer;
    // onChange(Answers);
  };

  const GetInputFieldForm = async (InputFieldData) => {
    const InputFieldType = InputFieldData.Field;
    const label = InputFieldData.Name;
  
    switch (InputFieldType) {
      case 'Text Field':
        return (
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={Answer[label]}
            onChange={(e) => handleChange(e.target.value, label)}
            disabled={getAnswerDisable}
          />
        );
  
      case 'Drop Down':
        const DropdownValues = await getFormDropdownValues(InputFieldData.values);
        return (
          <Autocomplete
            options={DropdownValues.values ?? DropdownValues}
            fullWidth
            size="small"
            value={Answer[label]}
            onChange={(_, value) => handleChange(value, label)}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
            disabled={getAnswerDisable}
          />
        );
  
      case 'Number Field':
        return (
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={Answer[label]}
            onChange={(e) => handleChange(e.target.value, label)}
            disabled={getAnswerDisable}
          />
        );
  
      case 'Date':
      case 'Date Field':
        return (
          <TextField
            variant="outlined"
            fullWidth
              size="small"
            type="date"
            value={Answer[label]}
            onChange={(e) => handleChange(e.target.value, label)}
            InputLabelProps={{ shrink: true }}
            disabled={getAnswerDisable}
          />
        );
  
      case 'Check Box':
        return (
          <FormControlLabel
          label={label}
            control={
              <Checkbox
                color="success"
                checked={Answer[label]}
                onChange={(e) => handleChange(e.target.checked, label)}
              />
            }
            disabled={getAnswerDisable}
          />
        );
  
      case 'Currency Field':
        const CurrencySymbols = await GetCurrancy();
        console.log(CurrencySymbols)
        return (<>
          <TextField
            variant="outlined"
            select
            fullWidth
            size="small"
            value={Answer[label]}
            onChange={(e) => handleChange(e.target.value, label)}
            SelectProps={{
              native: true,
            }}
            disabled={getAnswerDisable}
          >
            {CurrencySymbols.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </TextField>
          <br/><br/>
            <TextField
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={Answer[label]}
            onChange={(e) => handleChange(e.target.value, label)}
            disabled={getAnswerDisable}
          />
          </>
        );
  
      case 'Date Range':
        return (
          <Box>
            <TextField
              label="Start Date"
              type="date"
              variant="outlined"
              fullWidth
              size="small"
              value={Answer[label]?.startDate}
              onChange={(e) =>
                handleChange({ ...Answer[label], startDate: e.target.value }, label)
              }
              InputLabelProps={{ shrink: true }}
              disabled={getAnswerDisable}
            /><br/><br/>
            <TextField
              label="End Date"
              type="date"
              variant="outlined"
              fullWidth
              size="small"
              value={Answer[label]?.endDate}
              onChange={(e) =>
                handleChange({ ...Answer[label], endDate: e.target.value }, label)
              }
              InputLabelProps={{ shrink: true }}
              disabled={getAnswerDisable}
            />
          </Box>
        );
  
      case 'TelephoneNumber':
        const response_dropdown = await getTelephoneNumber();
        console.log(response_dropdown)
        return (
          <Box >
            <TextField
              select
              label="Country Code"
              variant="outlined"
              size="small"
              value={Answer[label]?.name}
              onChange={(e) =>
                handleChange({ ...Answer[label], countryCode: e.target.value }, label)
              }
              SelectProps={{
                native: true,
              }}
              focused
              disabled={getAnswerDisable}
            >
              {response_dropdown?.map((option) => (
                <option key={option?.id} value={option?.name}>
                  {option?.name}
                </option>
              ))}
            </TextField>
            <br/><br/>
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              size="small"
              value={Answer[label]?.phoneNumber}
              onChange={(e) =>
                handleChange({ ...Answer[label], phoneNumber: e.target.value }, label)
              }
              disabled={getAnswerDisable}
            />
          </Box>
        );
  
      case 'List of Address':
      case 'Address':
        return (
          <textarea style={{ width: '100%' }}/>
        );
  
        case "Check Box with Text Field":
          return (
            <Form_CheckboxWithTextField
              label={label}
              onChange={(answer) => handleChange(answer, label)}
              Answer={Answer[label]}
              getAnswerDisable={getAnswerDisable}
            />
          );
      case 'Percentage Field':
        return (
          <FormControl variant="outlined" disabled={getAnswerDisable}>
          <Input
            id="standard-adornment-weight"
            type="number"
            // value={data ?? ""}
            onChange={(e) => {
              // onChange(`${Math.min(100, Math.max(0, e.target.value))}`);
            }}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
        );
  
      case 'Email Field':
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="email"
            placeholder="Enter email"
            value={Answer[label]}
            onChange={(e) => handleChange(e.target.value, label)}
            disabled={getAnswerDisable}
          />
        );
  
      // case 'Radio Button':
      //   return (
      //     <FormControl component="fieldset">
      //       <FormLabel component="legend">{label}</FormLabel>
      //       <RadioGroup
      //         value={Answer[label]}
      //         onChange={(e) => handleChange(e.target.value, label)}
      //       >
      //         {InputFieldData.FieldValues.map((option) => (
      //           <FormControlLabel
      //             key={option}
      //             value={option}
      //             control={<Radio />}
      //             label={option}
      //           />
      //         ))}
      //       </RadioGroup>
      //     </FormControl>
      //   );
  
      case 'URL Field':
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="url"
            placeholder="Enter Url"
            value={Answer[label]}
            onChange={(e) => handleChange(e.target.value, label)}
            disabled={getAnswerDisable}
          />
        );
  
      default:
        return null;
    }
  };
  

  const createform = async () => {
    let array = [];
    for (let i = 0; i < FormFieldJson?.length; i++) {
      array.push(
        <Box
          sx={{
            padding: "15px",
            border: "1px solid lightgrey",
            borderRadius: "5px",
            marginY: "10px",
            borderLeft: "5px solid #9BD5CD",
          }}
          key={i}
        >
          <Grid container direction={"column"} rowGap={2}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#061826",
                  fontWeight: "600",
                }}
              >
                {FormFieldJson[i].Name}
              </Typography>
            </Grid>
            <Grid item>{await GetInputFieldForm(FormFieldJson[i])}</Grid>
          </Grid>
        </Box>
      );
    }
    return array;
  };

  useEffect(() => {
    FormFieldJson &&  createform().then((res) => {
      setForm(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment>{Form}</React.Fragment>;
};

export default Form_SubForm;
