import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Container,
  createFilterOptions,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteContext from "context/PageContext";
import {
  addDisclosure,
  enforcingAgency_Dropdown,
  categoryDropdown,
  CreateFromBuilderUsingUI
} from "../services/FormCreationFetch";
import { domainDropdownList } from "../../ESG/services/Fetch";
import { CircularProgress, Backdrop } from "@mui/material";
import SnackBarComponent from "./SnackBarComponent";

import { useRoleContext } from "context/RoleContex";
import { useNavigate } from "react-router-dom";


const AddDisclosureModal = ({ openDialog, setOpenDialog }) => {
  const navigate = useNavigate();
  const contextData = useContext(NoteContext);

  const { updateAddDisclosureTriggered } = useRoleContext()

  const [disclosurename, setDisclosureName] = useState("");
  const [effectivedate, seteffectivedate] = useState("");
  const [version, setversion] = useState("");
  const [enforcingagency, setenforcingagency] = useState("");
  const [enforcingagencyValue, setenforcingagencyValue] = useState(null);
  const [categoryList, setCategoryList] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [domainValues, setdomainValues] = useState();
  const defaultDomain = localStorage.getItem("UserSelectedDomain");
  const [selectedDomain, setselectedDomain] = useState(defaultDomain);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [insuranceTriggered, setInsuranceTriggered] = useState(false)
  const [formTypeValue, setFormTypeValue] = useState("");
  const formTypeOptions = ["Product Forms", "Financial Reporting Form"];
  const [Opencategory, setOpencategory] = useState(false);
  const [selectedOption, setselectedOption] = useState(false);
  const [showButton, setshowButton] = useState(true);
  const [ShowFile, setShowFile] = useState(false);

  const [aiTriggered, setAiTriggered] = useState(false);
  const [base64Data, setBase64Data] = useState('');
  const [loading, setLoading] = useState(false);

  const [snackbarData, setSnackbarData] = useState({
    variant: "contained",
    text: "",
    severity: "success"

  })

  const [openSnackbar, setOpenSnackbar] = useState(false)

  const [userData, setUserData] = useState({});



  useEffect(() => {
    console.log("007", base64Data);

  }, [base64Data])


  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    console.log(event, "007");
    const file = event.target.files[0];
    setUploadedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.substring(reader.result.indexOf(",") + 1)
        setBase64Data(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileRemove = (fileName) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((file) => file.name !== fileName)
    );
  };

  useEffect(() => {

    const fetchdomainname = async () => {
      const data = await domainDropdownList();
      setdomainValues(data);
    };

    const category_dropdown = async () => {
      await categoryDropdown().then((data) => {
        setCategoryList(data.data);
      });
    };
    fetchdomainname();
    category_dropdown();
    if (localStorage.getItem("AccountDetails")) {
      const data = JSON.parse(localStorage.getItem("AccountDetails"));
      setUserData(data)
      const newValue = "Insurance"
      if ((userData?.endUser?.mail)?.split(".")[0].toLowerCase() !== "ankurjit") {
        setCategoryValue(newValue);
        enforcingagency_dropdown(newValue);

      }
    }

  }, []);

  useEffect(() => {
    console.log("cat", categoryValue);
    if (categoryValue === "Insurance") {
      setInsuranceTriggered(true);
    }
    else {
      setInsuranceTriggered(false);
      setFormTypeValue("")
    }
  }, [categoryValue]);
  const enforcingagency_dropdown = async (newValue) => {
    if (newValue === "Insurance" || newValue === "ESG") {

      const data = await enforcingAgency_Dropdown(newValue)
      setenforcingagency(data.data);
    }
    else {
      const data = await enforcingAgency_Dropdown()
      setenforcingagency(data.data);
    }

    setOpencategory(true)
  };

  const filter = createFilterOptions(categoryList);

  const handleItemClick = (id,name, index) => {
    localStorage.setItem("disclosurename", JSON.stringify(id));
    localStorage.setItem(
      "DisclosureDetails",
      JSON.stringify({
        itemID: id,
        itemName: name ? name : "",
        index: index,
      })
    );
    navigate("/RegistrationResult", {
      state: { disclosure: id },
    });
    contextData.setDisclosureName(id);
  };

  const handleAddDisclosure = async () => {
    setLoading(true);
    localStorage.setItem("disclosurename", JSON.stringify(disclosurename));
    if (!aiTriggered) {
      addDisclosure(
        disclosurename,
        effectivedate,
        version,
        enforcingagencyValue,
        "jktech.com",
        categoryValue,
        formTypeValue,
        insuranceTriggered
      ).then(() => {
        setOpenDialog(false);
        contextData.setReloadData(true);
        
      
        updateAddDisclosureTriggered(true);
        setLoading(false);
        handleItemClick(disclosurename,disclosurename,70)

      }); //sending data to backend
    }
    if (aiTriggered) {
      const domain = localStorage.getItem("UserSelectedDomain");
      const industry = ""
      const state = ""
     

      CreateFromBuilderUsingUI(
        disclosurename,
        effectivedate,
        version,
        enforcingagencyValue,
        domain,
        categoryValue,
        industry,
        state,
        formTypeValue,
        base64Data,
        insuranceTriggered
      ).then((res) => {
        console.log("tes453", res);
        setLoading(false);
        if (res?.message) {
          setSnackbarData(prevState => ({
            ...prevState,
            text: res?.message,
            severity: "success"
          }));
          setOpenSnackbar(true)
          updateAddDisclosureTriggered(true);
          setOpenDialog(false);
          setshowButton(true);
          setselectedOption(false);
          setShowFile(false);
          handleItemClick(disclosurename,disclosurename,70)
        }
        if (res?.error) {
          console.log("test453", res?.error);
          setSnackbarData(prevState => ({
            ...prevState,
            text: res?.error,
            severity: "error"
          }));
          setOpenSnackbar(true)
        }
      }).catch((error) => {
        setLoading(false);
        console.error("tes453:", error);
      });
    }
  };

  const handleClickClose = () => {
    setOpenDialog(false);
    setshowButton(true);
    setselectedOption(false);
    setShowFile(false);
    setAiTriggered(false);
  };

  useEffect(() => {
    console.log(aiTriggered, "valp");

    return () => {

    };
  }, [aiTriggered]);

  const Spinner = ({ open }) => {
    return (
      <Backdrop open={open} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <Grid>
      <Spinner open={loading} />
      <SnackBarComponent open={openSnackbar} setOpenSnackbar={setOpenSnackbar} snackbarData={snackbarData} />

      <Dialog
        open={openDialog}
        onClose={() => handleClickClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create New Disclosure</DialogTitle>
        {showButton &&
          <Grid container justifyContent={"space-between"} p={1} spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={() => {
                setselectedOption(true);
                setshowButton(false)
              }} sx={{backgroundColor:"#25A392"}}>Manual Create New filling</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => {
                setselectedOption(true);
                setshowButton(false);
                setAiTriggered(true);
                setShowFile(true)
              }} sx={{backgroundColor:"#25A392"}}>AI Create filling</Button>
            </Grid>
          </Grid>
        }
        {selectedOption &&
          (
            <Grid>

              <DialogContent>
                <div>
                  <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        fullWidth
                        required
                        id="disclosurename-input"
                        name="disclosurename"
                        label="Disclosure Name"
                        type="text"
                        value={disclosurename}
                        onChange={(e) => setDisclosureName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        fullWidth
                        id="effectivedate-input"
                        name="effectivedate"
                        type="date"
                        required
                        value={effectivedate}
                        onChange={(e) => seteffectivedate(e.target.value)}
                        inputFormat="MM/dd/yyyy"
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        fullWidth
                        id="version-input"
                        name="version"
                        label="Version Number"
                        type="number"
                        required
                        value={version}
                        onChange={(e) => setversion(e.target.value)}
                      />
                    </Grid>
                    {
                      ((userData?.endUser?.mail)?.split(".")[0].toLowerCase() !== "ankurjit") ? (
                        <Grid item xs={6} md={6}>
                          <Autocomplete
                            value={categoryValue}
                            onChange={(event, newValue) => {
                              setOpencategory(false)
                              if (typeof newValue === "string") {
                                setCategoryValue(newValue);
                                enforcingagency_dropdown(newValue);
                              } else if (newValue && newValue.inputValue) {
                                setCategoryValue(newValue.inputValue);
                                enforcingagency_dropdown(newValue.inputValue);
                              } else {
                                setCategoryValue(newValue);
                                enforcingagency_dropdown(newValue);
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              const { inputValue } = params;
                              const isExisting = options.some(
                                (option) => inputValue === option
                              );
                              if (inputValue !== "" && !isExisting) {
                                filtered.push(inputValue);
                              }
                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={categoryList}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => <li {...props}>{option}</li>}
                            freeSolo
                            renderInput={(params) => (
                              <TextField {...params} label="Select Category" />
                            )}
                          />

                        </Grid>

                      ) : null
                    }
                    <Grid item xs={6} md={6}>
                      {Opencategory &&
                        <Autocomplete
                          fullWidth
                          value={enforcingagencyValue}
                          onChange={(event, newValue) => {
                            setenforcingagencyValue(newValue);
                          }}
                          options={enforcingagency}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField {...params} label="Enforcing Agency" />
                          )}
                        />
                      }
                    </Grid>
                    {
                      false ? (
                        <Grid item xs={6} md={6}>
                          <Autocomplete
                            value={selectedDomain}
                            fullWidth
                            onChange={(event, newValue) => {
                              setselectedDomain(newValue);
                            }}
                            options={domainValues || []}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Domain" />
                            )}
                          />
                        </Grid>
                      ) :
                        null
                    }

                    {
                      insuranceTriggered && (
                        <Grid item xs={6} md={6}>
                          <Autocomplete
                            fullWidth
                            value={formTypeValue}
                            onChange={(event, newValue) => {
                              setFormTypeValue(newValue);
                            }}
                            options={formTypeOptions}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField {...params} label="Form Type" />
                            )}
                          />
                        </Grid>
                      )
                    }
                    {ShowFile &&
                      <Grid item xs={12} md={12}>
                        <Container>
                          <input
                            accept=".pdf"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="contained-button-file">
                            <Button
                              variant="contained"
                              component="span"
                              startIcon={<CloudUploadIcon />}
                              sx={{backgroundColor: "#25A392"}}
                            >
                              Upload Files
                            </Button>
                          </label>
                          {uploadedFiles.length > 0 && (
                            <List>
                              {uploadedFiles.map((file, index) => (
                                <ListItem key={index}>
                                  <ListItemText primary={`${index + 1}. ${file.name}`} />
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleFileRemove(file.name)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItem>
                              ))}
                            </List>
                          )}
                        </Container>
                      </Grid>
                    }
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#25a392",
                    marginLeft: "10px",
                    flexDirection: "row",
                  }}
                  onClick={handleAddDisclosure}
                  disabled={
                    !(
                      categoryValue &&
                      enforcingagencyValue &&
                      version &&
                      effectivedate &&
                      disclosurename &&
                      selectedDomain && (insuranceTriggered ? (formTypeValue ? true : false) : true)
                    )
                  }
                >
                  Create
                </Button>
                <Button
                  variant="text"
                  style={{ color: "red", flexDirection: "row" }}
                  onClick={handleClickClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          )
        }
      </Dialog>

    </Grid>
  );
};

export default AddDisclosureModal;