import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TCFDlogo from "../../../images/TCFDlogo.png";

const SDC2 = (props)  =>{
    
    const [Medium,setMedium] = useState("")
    const [Long,setLong]=useState();
    const [pageloaded, setpageloaded] = useState(false)

    useEffect (()=>{ 
      // console.log(Object.values(props.data.reportData["S.Disclosure C"]));
      setMedium((Object.values(props.data.reportData["S.Disclosure C"])[5]).answer);
       setLong((Object.values(props.data.reportData["S.Disclosure C"])[6]).answer);
        
        setpageloaded(true);
    },[props])

    return(
    <React.Fragment>
        {pageloaded ? (
        <>        
            <Grid container spacing={1} className="border1">
                <Grid item xs={12} md={2} className="border1" >
                  <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  </div>
                  <h4 >Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                  <li style={{color:"steelblue",fontSize:"20px"}}><b>Strategy</b></li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid>
                <Grid item md={10} container>
                  <Grid item xs={12} md={12} style={{backgroundColor:"steelblue"}} className="border1">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Strategy</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the resilience of the organization’s 
                    strategy, taking into consideration different climate-related scenarios, including a 2°C or 
                    lower scenario..</p>
                  </Grid>
                <Grid item xs={12} md={6}  >
                        <h2 className="underline">S.Disclosure C</h2>
                        <h6><b>Insurance companies that perform climate-related scenario analysis on their underwriting activities should provide time frames used for the climate-related scenarios, including long-term milestones.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Long}}></div>
                </Grid>
                <Grid item xs={12} md={6} className="grid2">
                  <h6><b>Insurance companies that perform climate-related scenario analysis on their underwriting activities should provide time frames used for the climate-related scenarios, including medium-term milestones".</b></h6>
                  <div dangerouslySetInnerHTML={{__html:Medium}}></div>
                </Grid>
                </Grid>
                </Grid>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </React.Fragment>
    );
}
export default SDC2;