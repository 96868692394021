import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    Typography,
  } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import withSnackbar from "../../components/Alert/withSnackbar"; 


const SelectedChildCase=({selectedSubCase,showSnackbar})=>{
return(
    <>
    <Grid>
   
      
          {selectedSubCase?.SubCaseId?
          <Grid className="selectedcasecontainer">
      <Typography className="childcaseid">{selectedSubCase.SubCaseId?selectedSubCase?.SubCaseId:''}</Typography>
      <Grid className="gridspace">
        <Typography className="subcaselabel">Conducted By</Typography>
        <Typography className="subcasedata">{selectedSubCase.ConductedBy?selectedSubCase?.ConductedBy:''}</Typography>
      </Grid>
      <Grid className="gridspace">
        <Typography className="subcaselabel">Case Type</Typography>
        <Typography className="subcasedata">{selectedSubCase.Type?selectedSubCase?.Type:''}</Typography>
      </Grid>
      <Grid className="gridspace">
        <Typography className="subcaselabel">Status</Typography>
        <Typography className="subcasedata">{selectedSubCase.Status?selectedSubCase?.Status:''}</Typography>
      </Grid>
      <Grid className="gridspace">
        <Typography className="subcaselabel">Current CaseWorker</Typography>
        <Typography className="subcasedata">{selectedSubCase.ConductedBy?selectedSubCase?.ConductedBy:''}</Typography>
      </Grid>
      <Grid className="gridspace">
        <Typography className="subcaselabel">Case Date </Typography>
        <Typography className="subcasedata">{selectedSubCase.DateConducted?selectedSubCase?.DateConducted:''}</Typography>
      </Grid>
      </Grid>:<Grid style={{ paddingTop: "10px", margin: "0px 10px" }}>
              <Grid className="rejectbox" >
                <InfoIcon className="rejectHeading" style={{ fontSize: '32px', marginRight: '10px' }}></InfoIcon>
                <Grid>
                  <Typography className="rejecttxt">No Data</Typography>
                </Grid>
              </Grid>
            </Grid>}
    </Grid>
    </>
)
}


export default withSnackbar(SelectedChildCase, "SelectedChildCase", "success");