import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const RestatementReport = (props)  =>{
    const [reasons,setreasons] = useState()
    const [effects,seteffects] = useState()
    const [headerTable, setheaderTable] = useState();
  const [Reportdata,setReportdata]=useState();
    const [pageloaded, setpageloaded] = useState(false)
    
    const CreateHeader = () => {
      const column = Object.keys(headerTable[0]);
      const newcolumn = column.map((data) => {
        if (data !== "id" && data !== "isNew") {
          return <TableCell key={data}>{data}</TableCell>;
        }
        return null;
      });
  
     
      return newcolumn;
    };
  
    // get table row data
    const tdData = () => {
      return headerTable.map((data,index) => {
        return (
          <TableBody key={index}>
            <TableRow
              key={data}
              sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
            >
              {Object.keys(headerTable[0]).map((x,index) => {
                if (x !== "id" && x !== "isNew") {
                  return (
                    <TableCell key={index} component="th" scope="row">
                      {data[x]}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableBody>
        );
      });
    };
    useEffect (()=>{ 
      setReportdata((Object.values(props.data.reportData["GRI 2.16 Communication of critical concerns"])[0]).answer);
      // setcontextual((Object.values(props.data.reportData[" Employee "])[2]).note);
      setheaderTable(
        Object.values(props.data.reportData["GRI 2.16 Communication of critical concerns"])[1].answer
      );
        setreasons((Object.values(props.data.reportData["GRI 2.4  Restatements of information"])[0]).answer)
        seteffects((Object.values(props.data.reportData["GRI 2.4  Restatements of information"])[1]).answer)
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} className="leftgrid">
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5>Restatements information</h5>
                        <h6 className="matter">Reasons for the restatements made in the report from previous reporting periods:</h6>
                        <div dangerouslySetInnerHTML={{__html:reasons}}></div>
                        <h6 className="matter">Effects on the restatements made in the report from previous reporting periods:</h6>
                        <div dangerouslySetInnerHTML={{__html:effects}}></div>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
          <h6><b>
          Describe how critical concerns are communicated to the highest governance body.
            </b>
          </h6>
          <div dangerouslySetInnerHTML={{ __html: Reportdata }}></div>
          <h6><b>The total number and the nature of critical concerns that were communicated to the highest governance body during the reporting period</b></h6>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 500, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable && CreateHeader()}
                  </TableRow>
                </TableHead>

                {headerTable && tdData()}
              </Table>
            </TableContainer>
          </div>
          </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default RestatementReport;