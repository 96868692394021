import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TCFDlogo from "../../../images/TCFDlogo.png";



const GDA1 = (props)  =>{
  
    const [Frequency,setFrequency] = useState("")
    const [Addressing,setAddressing]=useState();
    const [pageloaded, setpageloaded] = useState(false)

    useEffect (()=>{ 
       // console.log(Object.values(props.data.reportData["G.Disclosure A"]));
        setFrequency((Object.values(props.data.reportData["G.Disclosure A"])[4]).answer);
        setAddressing((Object.values(props.data.reportData["G.Disclosure A"])[11]).answer);
        
        setpageloaded(true);
    },[props])

    return(
    <React.Fragment>
        {pageloaded ? (
        <>       
        
            <Grid container spacing={1} className="border"  >
                <Grid item xs={12} md={2} className="border" >
                  <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  </div>
                  <h4 >Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li style={{color:"#61c4ee",fontSize:"20px"}}><b>Governance</b></li>
                    <li>Strategy</li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid>
                <Grid item md={10} container>
                  <Grid item xs={12} md={12} style={{backgroundColor:"#61c4ee"}} className="border">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Governance</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Disclose the organization’s governance around climate-related risks and opportunities.</p>
                  </Grid>
                <Grid item xs={12} md={6}  >
                        <h2>G.Disclosure A</h2>
                        <h6><b>Describe processes and frequency by which the board and/or board committees are informed about climate-related issues</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Frequency}}></div>
                </Grid>
                <Grid item xs={12} md={6} >
                  <h6><b>How is the board involved in setting  goals and targets for addressing climate-related issues.</b></h6>
                  <div dangerouslySetInnerHTML={{__html:Addressing}}></div>
                </Grid>
                </Grid>
                </Grid>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </React.Fragment>
    );
}
export default GDA1;