import axios from "axios";
const { REACT_APP_API_URL } = process.env;

let isRedirecting = false; // Flag to prevent multiple redirects

// Create an axios instance
const api = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to add tokens
api.interceptors.request.use(
  (config) => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    if (tokens) {
      config.headers['accessToken'] = tokens.accessToken;
      config.headers['Authorization'] = `Bearer ${tokens.idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && !isRedirecting) {
      isRedirecting = true; // Set the flag
      localStorage.removeItem('tokens');
      window.location.href = '/login'; // Adjust the login path as necessary
    }
    return Promise.reject(error);
  }
);

export const GetCaseNotification = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/notifications`,
    params: param,
    headers: {
      //  'Access-Control-Allow-Origin': '*',
      // "Access-Control-Allow-Methods":"POST, GET, OPTIONS, DELETE, PUT",
      // "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
    .catch((error) => console.log(error));
};
export const UpdateCaseNotification = async (id, notificationIds) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/readby`,
    data: {
      notificationIds: notificationIds,
    },
    params: {
      userRoleId: id ? id : "No Group",
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
    .catch((error) => console.log(error));
};

export const CreateCases = async (param) => {
  console.log(param.body,param.domain,param.case_type)
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST", 
    url: `${REACT_APP_API_URL}/case/createCase`,
    data:param.body,
    params: {
      domain:param.domain,
      case_name:param.case_type
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
    .catch((error) => console.log(error));
};

export const UpdateCases = async (params) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/updateCase`,
    data: {
      hash_tag: params.HashTags,
      case_attachment: params.CaseAttachment,
    },
    params: {
      // params: params
      caseId: params.Caseid,
      case_name: params.CaseName,
      creator: params.Creator,
      edited_by: params.EditedBy,
      case_category: params.CaseCategory,
      case_sub_category: params.CaseSubCategory,
      status: params.Status,
      case_priority: params.CasePriority,
      current_case_worker: params.CurrentCaseWorking,
      case_date: params.CaseDate,
      description: params.Description,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
    .catch((error) => console.log(error));
};

export const DeleteCase = async (case_id) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/closeCase`,
    params: {
      caseId: case_id,
      closeCaseReason: "test",
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
    .catch((error) => console.log(error));
};

export const getCases = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCases`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
    .catch((error) => console.log(error));
};

export const getFilterCases = async (filterOptions) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCases`,
    params: {
      case_category: filterOptions?.selectedCaseTypeId,
      case_sub_category: filterOptions?.caseType,
      CaseStatus: filterOptions?.CaseStatus,
      CasePriority: filterOptions?.CasePriority,
      from_Date: filterOptions?.from_Date,
      to_Date: filterOptions?.to_Date,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
    .catch((error) => console.log(error));
};
export const getCaseTypesTemp = async (templateType) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    //url: `${REACT_APP_API_URL_Neo4j}/getCaseTemplateType`,
    url:'https://ldapplicationserver.azurewebsites.net/case/getCaseTemplateType',
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:{
      templateType:templateType
    }
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};
// export const getCaseTypes = async () => {
//     const tokens = JSON.parse(localStorage.getItem("tokens"));
//     return await axios({
//         method: "GET",
//         url: `${REACT_APP_API_URL}/case/getCaseType`,
//         headers: {
//             "Content-Type": "application/json",
//             accessToken: tokens.accessToken,
//             Authorization: `Bearer ${tokens.idToken}`,
//         },
//     })
//         .then((response) => response.data)
//         .catch((error) => console.log(error));
// };

export const getSubCaseTypesTemp = async (TypetemplateID) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    //url: 'https://us-east1-regulatory-compliance-407213.cloudfunctions.net/getCaseTemplateType',
    url:"https://ldapplicationserver.azurewebsites.net/case/getCaseTemplates",
    params: {
      template: TypetemplateID,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getCaseAttachments = async (caseId) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getAttachmentByCase`,
    params: {
      caseId: caseId,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

// export const getSubCaseTypes = async (caseType) => {
//     const tokens = JSON.parse(localStorage.getItem("tokens"));
//     return await axios({
//         method: "GET",
//         url: `${REACT_APP_API_URL}/case/getSubCaseType`,
//         params: {
//             caseType: caseType
//         },
//         headers: {
//             "Content-Type": "application/json",
//             accessToken: tokens.accessToken,
//             Authorization: `Bearer ${tokens.idToken}`,
//         },
//     })
//         .then((response) => response.data)
//         .catch((error) => console.log(error));
// };

export const getCaseStatus = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getStatus`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getCasePriority = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/table/SignificanceOfRisk`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getAllCases = async (domain,templateType,filteroption) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    //url: `${REACT_APP_API_URL}/case/getCases`,
    url:`https://ld-function-apps.azurewebsites.net/api/getCases`,
    params: {
      domain: domain,
      templateType:templateType,
      from_Date: filteroption?.fromDate,
      to_Date: filteroption?.toDate,
      CaseStatus: filteroption?.caseStatus,
      CaseType: filteroption?.caseType?JSON.stringify(filteroption?.caseType):'',
      CaseCreator: filteroption?.creator?JSON.stringify(filteroption?.creator):'',
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getSubCases = async (caseid,filteroption) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
   // url: `${REACT_APP_API_URL}/case/getChildCases?caseId=${caseid}`,
   url:`https://ld-function-apps.azurewebsites.net/api/getChildCases`,
   params: {
    caseId: caseid,
    from_Date: filteroption?.fromDate,
    to_Date: filteroption?.toDate,
    CaseStatus: filteroption?.caseStatus,
    CheckType: filteroption?.caseType?JSON.stringify(filteroption?.caseType):'',
    CaseCreator: filteroption?.creator?JSON.stringify(filteroption?.creator):'',
  },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const openedCases = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/openedCases`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const closedCases = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/closedCases`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getCaseDataForGraph = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCaseDataForGraph`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};




export const getCountryList = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/location/countryList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getStateList = async (countrycode) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/location/stateList?countryId=${countrycode}`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getIdentityList = async (countrycode) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getIdentificationType`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const getCheckList = async (casecode) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCheckList?caseType=${casecode}`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getErrorList = async (casecode) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getErrorType?caseType=${casecode}`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getCaseById = async (caseid,tempType) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getParentCaseById`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:{
      caseId:caseid,
      templateType:tempType
    }
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getChildCaseById = async (caseid) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getChildCaseById?subCaseId=${caseid}`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};
export const getLogs = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCaseAudit`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getNotification = async (email) => {
  // const tokens = JSON.parse(localStorage.getItem("tokens"));
  // return await axios({
  //   method: "GET",
  //   url: `https://us-east1-regulatory-compliance-407213.cloudfunctions.net/getNotifications?user_email=${email}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     accessToken: tokens.accessToken,
  //     Authorization: `Bearer ${tokens.idToken}`,
  //   },
  // });
};


export const downloadFile = async (fileurl) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
   //url: `https://ldapplicationserver.azurewebsites.net/filecontent`,
  // url: `http://localhost:3005/filecontent`,
    url:`${REACT_APP_API_URL}/case/caseAttachmentContent`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:{
      url:fileurl
    }
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const UpdateCase = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/reassignCase`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: param.queryparam,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getComments = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCaseComments`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getCommentsReplies = async (comment_id) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCaseCommentReplies`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:{
      commentId:comment_id
    }
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const editComments = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/editCommentOrReply`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.data
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const deleteComments = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `https://ld-function-apps.azurewebsites.net/api/deleteCommentOrReply`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
   params:param
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const updateNotification = async (param) => {
  // const tokens = JSON.parse(localStorage.getItem("tokens"));
  // return await axios({
  //   method: "POST",
  //   url: 'https://us-east1-regulatory-compliance-407213.cloudfunctions.net/updateNotificationStatus',
  //   headers: {
  //     "Content-Type": "application/json",
  //     // accessToken: tokens.accessToken,
  //     // Authorization: `Bearer ${tokens.idToken}`,
  //   },
  //   data:{
  //     "notif_ids":param.id
  //   }
  // });
};

export const rejectCase = async (param,reqdata) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/rejectCase`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param,
    data:reqdata
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const submitForReview = async (param,reqdata) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/submitForReview`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param,
    data:reqdata
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const SubmitForRejectTask = async (param,reqdata) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/SubmitForRejectTask`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param,
    data:reqdata
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getTasks = async (domainName,selectedRole) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCaseTasks`,
    params:{
      domain:domainName,
      role:selectedRole
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

//domain list 
export const getDomainLists = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/domainDropdownList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:{
      worker_email:param.worker_email, 
      page_no:param.page_no
  
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getUserList = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getcaseWorker`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getUserRole = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCaseManagenetRoles`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const getResult = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getResult`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const getAllCaseCreator = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getAllCaseCreator`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const verifyCustomer = async (customerid) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCustomerByCustomerId`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:{
      customerId:customerid
    }
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const getAllCaseReviewer = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getcaseReviewer`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const updateParentCase = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/updateParentCase`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const closeParentCase = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/closeParentCase`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.data
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const closeSubCase = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/closeChildCase`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const updateSubCaseWithAttachmentsComments = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/updateSubCaseWithAttachmentsComments`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const submitForCaseReviewerReassign = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/submitForReassign`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const getOpenChildcaseCount = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getOpenChildcaseCount`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
  })
  .then((response) => response)
  .catch((error) => error);
};

export const closeChildCase = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/closeChildCase?`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};


export const reassignForCreator = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/case/reassignForCreator`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const createSubCase = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/case/createSubCase`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params:param.param,
    data:param.body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const getCaseDataFor3DGraph = async (param) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/case/getCaseDataFor3DGraph`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const caseGenAIResponse = async (body) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/case/caseChat`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    data:body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const deletecaseGenAISession = async (body) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "DELETE",
    url: `${REACT_APP_API_URL}/case/deleteSession`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    data:body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};

export const generateDraftResponse = async (body) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/case/generatingDraftResponse`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    data:body
  })
  .then((response) => response)
  .catch((error) => console.log(error));
};