// import React, { useEffect, useState } from "react";
// import { Grid } from "@mui/material";
// import withSnackbar from "../../components/Alert/withSnackbar";
// import { Graph } from "react-d3-graph";

// const { getCaseDataFor3DGraph } = require("../../Compliance/services/CaseFetch");

// const Graph3D = () => {
//   const [data, setData] = useState({ nodes: [{ id: "caseNode" }], links: [] });
//   const [expandedNodes, setExpandedNodes] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const res = await getCaseDataFor3DGraph();
//         // const initialData = res.data;
//         const initialData = {
//           nodes: [
//             { id: "Insurance", type: "caseType" },
//             { id: "KYC", type: "caseType" },
//             { id: "parent1", type: "Insurance", isParent: true },
//             { id: "child1", parent: "parent1" },
//             { id: "child2", parent: "parent1" },
//             { id: "parent2", type: "KYC", isParent: true },
//             { id: "child3", parent: "parent2" }
//           ],
//           links: [
//             { source: "parent1", target: "child1" },
//             { source: "parent1", target: "child2" },
//             { source: "parent2", target: "child3" }
//           ]
//         };
//         setData({
//           nodes: [{ id: "caseNode", type: "root" }],
//           links: []
//         });
//         setExpandedNodes([]);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleNodeClick = (nodeId) => {
//     setExpandedNodes((prevExpandedNodes) => {
//       if (prevExpandedNodes.includes(nodeId)) {
//         return prevExpandedNodes.filter((id) => id !== nodeId);
//       } else {
//         return [...prevExpandedNodes, nodeId];
//       }
//     });
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const res = await getCaseDataFor3DGraph();
//         const initialData = res.data;
//         // const initialData = {
//         //   nodes: [
//         //     { id: "Insurance", type: "caseType" },
//         //     { id: "KYC", type: "caseType" },
//         //     { id: "parent1", type: "Insurance", isParent: true },
//         //     { id: "child1", parent: "parent1" },
//         //     { id: "child2", parent: "parent1" },
//         //     { id: "parent2", type: "KYC", isParent: true },
//         //     { id: "child3", parent: "parent2" }
//         //   ],
//         //   links: [
//         //     { source: "parent1", target: "child1" },
//         //     { source: "parent1", target: "child2" },
//         //     { source: "parent2", target: "child3" }
//         //   ]
//         // };

//         // Build the graph data based on expanded nodes
//         let nodes = [{ id: "caseNode", type: "root" }];
//         let links = [];

//         expandedNodes.forEach((expandedNode) => {
//           if (expandedNode === "caseNode") {
//             // Show case types
//             initialData.nodes.forEach((node) => {
//               if (node.type === "caseType") {
//                 nodes.push(node);
//                 links.push({ source: "caseNode", target: node.id });
//               }
//             });
//           } else {
//             // Show parent or child nodes
//             initialData.nodes.forEach((node) => {
//               if (node.type === expandedNode || node.parent === expandedNode) {
//                 nodes.push(node);
//                 links.push({ source: expandedNode, target: node.id });
//               }
//             });
//           }
//         });

//         setData({ nodes, links });
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [expandedNodes]);

//   const myConfig = {
//     nodeHighlightBehavior: true,
//     node: {
//       color: "lightgreen",
//       size: 120,
//       highlightStrokeColor: "blue",
//       fontSize: 14,
//     },
//     link: {
//       highlightColor: "lightblue",
//       strokeWidth: 2,
//     },
//     staticGraph: false,
//     // d3: {
//     //   gravity: -250,
//     // },
//   };

//   return (
//     <Grid style={{width: "100%",
//       height: "100vh",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",}}>
//       {data.nodes.length > 0 && (
//         <Grid style={{ width: "100%",
//           height: "100%",
//           display: "flex",
//       justifyContent: "center",
//       alignItems: "center",}}>
//         <Graph
//           id="graph-id" // id is mandatory
//           data={data}
//           config={myConfig}
//           onClickNode={handleNodeClick}
//         />
//         </Grid>
//       )}
//     </Grid>
//   );
// };

// export default withSnackbar(Graph3D, "Graph3D", "success");

import React, { useEffect, useCallback, useState } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Grid, Typography } from "@mui/material";
import withSnackbar from "../../components/Alert/withSnackbar";

const { getCaseDataFor3DGraph } = require("../../Compliance/services/CaseFetch");

const Graph3D = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [expandedNodes, setExpandedNodes] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await getCaseDataFor3DGraph();
  //       const initialData = {
  //         nodes: [
  //           { id: "Insurance", type: "caseType" },
  //           { id: "KYC", type: "caseType" },
  //           { id: "parent1", type: "Insurance", isParent: true },
  //           { id: "child1", parent: "parent1" },
  //           { id: "child2", parent: "parent1" },
  //           { id: "parent2", type: "KYC", isParent: true },
  //           { id: "child3", parent: "parent2" }
  //         ],
  //         links: [
  //           { source: "parent1", target: "child1" },
  //           { source: "parent1", target: "child2" },
  //           { source: "parent2", target: "child3" }
  //         ]
  //       };
  //       setNodes([
  //         { id: "caseNode", data: { label: "caseNode" }, position: { x: 0, y: 0 } },
  //       ]);
  //       setEdges([]);
  //       setExpandedNodes([]);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleNodeClick = useCallback((event, node) => {
    setExpandedNodes((prevExpandedNodes) => {
      if (prevExpandedNodes.includes(node.id)) {
        return prevExpandedNodes.filter((id) => id !== node.id);
      } else {
        return [...prevExpandedNodes, node.id];
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCaseDataFor3DGraph();
        const initialData = res.data;

        let newNodes = [{ id: "caseNode", data: { label: "caseNode" }, position: { x: 0, y: 0 } }];
        let newEdges = [];

        expandedNodes.forEach((expandedNode) => {
          if (expandedNode === "caseNode") {
            initialData.nodes.forEach((node) => {
              if (node.type === "caseType") {
                newNodes.push({
                  id: node.id,
                  data: { label: node.id },
                  position: { x: Math.random() * 600 - 300, y: Math.random() * 400 - 200 }
                });
                newEdges.push({ id: `e${expandedNode}-${node.id}`, source: "caseNode", target: node.id });
              }
            });
          } else {
            initialData.nodes.forEach((node) => {
              if (node.type === expandedNode || node.parent === expandedNode) {
                newNodes.push({
                  id: node.id,
                  data: { label: node.id },
                  position: { x: Math.random() * 600 - 300, y: Math.random() * 400 - 200 }
                });
                newEdges.push({ id: `e${expandedNode}-${node.id}`, source: expandedNode, target: node.id });
              }
            });
          }
        });

        setNodes(newNodes);
        setEdges(newEdges);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [expandedNodes]);

  return (
    <Grid container style={{ height: "100vh" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Case Relationships Graph
      </Typography>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={handleNodeClick}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </Grid>
  );
};

export default withSnackbar(Graph3D, "Graph3D", "success");

