import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TCFD from "../../../images/TCFD.jpg";
import "../../styles/Report/Report.css";



function Logo() {


  return (
    <>
        <Box>
          <Grid>
            <Grid item xs={12} md={12}>
              <img
                src={TCFD}
                alt="tcfd"
                className="img"
              />
            </Grid>
          </Grid>
        </Box>
    </>
  );
}
export default Logo;


