import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function EleventhPage(props) {
  const [Questionset1, SetQuestionset1] = useState();
  const [Questionset2, SetQuestionset2] = useState();
  const [Questionset3, SetQuestionset3] = useState();
  const [Questionset4, SetQuestionset4] = useState();
  const [Questionset5, SetQuestionset5] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [QuestionNum2, setQuestionNumber2] = useState();
  const [QuestionNum3, setQuestionNumber3] = useState();
  const [QuestionNum4, setQuestionNumber4] = useState();
  const [QuestionNum5, setQuestionNumber5] = useState();
  //const [Question1,setQuestion1]=useState();
  const [Answerset1, setAnswerset1] = useState();
  const [Answerset2, setAnswerset2] = useState();
  const [Answerset3, setAnswerset3] = useState();
  const [Answerset4, setAnswerset4] = useState();
  const [Answerset5, setAnswerset5] = useState();
  const [QuestionNum1, setQuestionNumber1] = useState();

  useEffect(() => {
    const FetchData = () => {
      //getting section 1b question in single array
      const questions_array_a = [];
      const Answer_array_a = [];
      const QuestionNumber1 = [];

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Related Adviser"]).length;
        i++
      ) {
        questions_array_a.push(
          Object.keys(props.data.reportData["Related Adviser"])[i]
        );
        Answer_array_a.push(
          Object.values(props.data.reportData["Related Adviser"])[i].answer
        );
        QuestionNumber1.push(
          Object.values(props.data.reportData["Related Adviser"])[i].qno.substr(
            8,
            Object.values(props.data.reportData["Related Adviser"])[i].qno
              .length
          )
        );
      }
      // console.log(questions_array_b);
      SetQuestionset1(questions_array_a);
      setAnswerset1(Answer_array_a);
      
      setQuestionNumber1(QuestionNumber1);
    };
    const FetchData1 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber2 = [];

      for (
        let i = 0;
        i <
        Object.keys(
          props.data.reportData[
            "Investment Adviser Expecting to be Eligible for Commission Registration within 120 Days"
          ]
        ).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(
            props.data.reportData[
              "Investment Adviser Expecting to be Eligible for Commission Registration within 120 Days"
            ]
          )[i]
        );
        Answer_array_b.push(
          Object.values(
            props.data.reportData[
              "Investment Adviser Expecting to be Eligible for Commission Registration within 120 Days"
            ]
          )[i].answer
        );
        QuestionNumber2.push(
          Object.values(
            props.data.reportData[
              "Investment Adviser Expecting to be Eligible for Commission Registration within 120 Days"
            ]
          )[i].qno.substr(
            8,
            Object.values(
              props.data.reportData[
                "Investment Adviser Expecting to be Eligible for Commission Registration within 120 Days"
              ]
            )[i].qno.length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset2(questions_array_b);
      setAnswerset2(Answer_array_b);
     // console.log(Answer_array_b[0][0]);
      setQuestionNumber2(QuestionNumber2);
    };
    const FetchData2 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber3 = [];

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Multi-State Adviser"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Multi-State Adviser"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Multi-State Adviser"])[i].answer
        );
        QuestionNumber3.push(
          Object.values(props.data.reportData["Multi-State Adviser"])[
            i
          ].qno.substr(
            8,
            Object.values(props.data.reportData["Multi-State Adviser"])[i].qno
              .length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset3(questions_array_b);
      setAnswerset3(Answer_array_b);
      setQuestionNumber3(QuestionNumber3);
    };
    const FetchData3 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber4 = [];

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["SEC Exemptive Order"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["SEC Exemptive Order"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["SEC Exemptive Order"])[i].answer
        );
        QuestionNumber4.push(
          Object.values(props.data.reportData["SEC Exemptive Order"])[
            i
          ].qno.substr(
            8,
            Object.values(props.data.reportData["SEC Exemptive Order"])[i].qno
              .length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset4(questions_array_b);
      setAnswerset4(Answer_array_b);
     // console.log(Answer_array_b);
      setQuestionNumber4(QuestionNumber4);
    };
    const FetchData4 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber5 = [];

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Private Fund Assets"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Private Fund Assets"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Private Fund Assets"])[i].answer
        );
        QuestionNumber5.push(
          Object.values(props.data.reportData["Private Fund Assets"])[
            i
          ].qno.substr(
            8,
            Object.values(props.data.reportData["Private Fund Assets"])[i].qno
              .length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset5(questions_array_b);
      setAnswerset5(Answer_array_b);
      //console.log(Answer_array_b[0].Symbol.label);
      setQuestionNumber5(QuestionNumber5);
    };

    FetchData();
    FetchData1();
    FetchData2();
    FetchData3();
    FetchData4();
    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  bgcolor: "lightgray",
                  position: "sticky",
                  top: "0",
                  padding: "2px",
                  height: "100",
                }}
              >
                <ul
                  style={{
                    listStyleType: "square",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  <li>Website Addresses</li>
                  <li>Books and Records</li>
                  <li>
                    Registration with Foreign Financial Regulatory Authorities
                  </li>
                </ul>
              </Grid>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                
                  {Questionset1.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                       
                          <b>{QuestionNum1[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {Answerset1[index]}
                       
                        <br />
                      </React.Fragment>
                    );
                  })}
               
                  {Questionset2.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum2[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {Answerset2[index].map((ele,innerIndex)=>{
                            return(<h6 key={innerIndex}>*{ele}</h6>)
                          })}
                        
                        <br />
                      </React.Fragment>
                    );
                  })}
                
                  {Questionset3.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                       
                          <b>{QuestionNum3[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          <ol> {Answerset3[index].map((ele,index1)=>{
                          return (<li key={index1}>{ele}</li>)
                         })}</ol>
                        
                        <br />
                      </React.Fragment>
                    );
                  })}
               
                  {Questionset4.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum4[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {
                          index === 1 ? (<h6>{Answerset4[index].substr(0, 10)}</h6>):(
                          <h6>{Answerset4[index]}</h6>
                          )}
                        <br />
                      </React.Fragment>
                    );
                  })}
               
                  {Questionset5.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                       
                          <b>{QuestionNum5[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                         {Answerset5[index].Symbol.label} {Answerset5[index].Amount}
                        
                        <br />
                      </React.Fragment>
                    );
                  })}
          
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default EleventhPage;
