import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

function NewHiring(props) {
  const [isloading, setisloading] = useState(false);
  const [headerTable, setheaderTable] = useState();
  const [headerTable1, setheaderTable1] = useState();
  const [headerTable2, setheaderTable2] = useState();
  const [Benefits,setBenefits]=useState();


  // get table heading data
  const CreateHeader = (tableheader) => {
    const column = Object.keys(tableheader[0]);
    const newcolumn = column.map((data) => {
      if (data !== "id" && data !== "isNew") {
        return <TableCell key={data}>{data}</TableCell>;
      }
      return null;
    });

   
    return newcolumn;
  };

  // get table row data
  const tdData = (tabledata) => {
    return tabledata.map((data,index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(tabledata[0]).map((x,index) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell key={index} component="th" scope="row">
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
   
     setBenefits((Object.values(props.data.reportData["GRI 401.2 Benefits Provided to full-time employees that are not Provided to temporary or parttime employees"])[0]).answer);
    setheaderTable(
      Object.values(props.data.reportData["GRI 401.1 New employee hires and employee turnover"])[0].answer
    );
    setheaderTable1(
        Object.values(props.data.reportData["GRI 401.1 New employee hires and employee turnover"])[1].answer
      );
      setheaderTable2(
        Object.values(props.data.reportData["GRI 401.1 New employee hires and employee turnover"])[2].answer
      );
    setisloading(true);

    //CreateHeader();
  }, [isloading,props]);

  return (
    <div>
      {isloading && (
        <>
         <Box  className="EmpBox">
         <Grid container spacing={2}>
        <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                    <h5 className="underline">New employee hires and employee turnover</h5>
                <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
            <h6><b>Provide total number, rate of new employee hires and rate of 
                employee turnover during the reporting period, by age group.</b></h6>
              <Table
                sx={{ minWidth: 550, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable && CreateHeader(headerTable)}
                  </TableRow>
                </TableHead>

                {headerTable && tdData(headerTable)}
              </Table>
              
            </TableContainer>
            
          </div>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
            <h6><b>Provide the total number, rate of new employee hires, and rate of 
                employee turnover during the reporting period, by region</b></h6>
              <Table
                sx={{ minWidth: 550, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable2 && CreateHeader(headerTable2)}
                  </TableRow>
                </TableHead>

                {headerTable2 && tdData(headerTable2)}
              </Table>
              
            </TableContainer>
            
          </div>
          
          </Grid>
          <Grid item xs={12} md={5}  className="grid2">
          <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
            <h6><b>Provide the total number, rate of new employee hires, and rate 
                of employee turnover during the reporting period, by gender.</b></h6>
              <Table
                sx={{ minWidth: 550, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable1 && CreateHeader(headerTable1)}
                  </TableRow>
                </TableHead>

                {headerTable1 && tdData(headerTable1)}
              </Table>
              
            </TableContainer>
            
          </div>
          <br></br>
          <h5 className="underline">Benefits Provided to full-time employees that are not Provided to temporary or parttime employees</h5>
          <h6><b>Provide the benefits which are standard for full-time employees of the organization
             but are not provided to temporary or part-time employees."</b></h6>
            <div dangerouslySetInnerHTML={{__html:Benefits}}></div>
          
          </Grid>
          </Grid>
          </Box>
        </>
      )}
    </div>
  );
}

export default NewHiring;
