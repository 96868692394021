import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const WorkerHealth = (props)  =>{
    const [facilitates,setfacilitates] = useState()
    const [voluntary,setvoluntary] = useState()
    const[services,setservices]=useState()

   
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
        setfacilitates((Object.values(props.data.reportData["GRI 403.6 Promotion of worker health"])[0]).answer)
         setvoluntary((Object.values(props.data.reportData["GRI 403.6 Promotion of worker health"])[1]).answer)
         setservices((Object.values(props.data.reportData["GRI 403.6 Promotion of worker health"])[2]).answer)

        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5 className="underline"> Promotion of worker health</h5>
                        <h6><b>Explain How the organization facilitates workers access to non-occupational medical and healthcare services,
                             and the scope of access provided.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:facilitates}}></div>
                        <h6><b>Describe any voluntary health promotion services and programs offered to workers to address major non-work-related health risks,
                             including the specific health risks addressed</b></h6>
                        <div dangerouslySetInnerHTML={{__html:voluntary}}></div>
                        
                </Grid>
                <Grid item xs={12} md={5}  className="grid2">
                        <h6><b>Provide how the organization facilitates workers access to any voluntary health
                             promotion services and programs.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:services}}></div>
                        
                       
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default WorkerHealth;