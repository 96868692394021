import React, { useEffect, useState, useContext } from "react";
import {
    Grid,
    Typography,
    IconButton,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import withSnackbar from "../../components/Alert/withSnackbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterPopover from "Compliance/CaseManagment/CaseFilter";
import NoteContext from "context/PageContext";
import Visibility from '@mui/icons-material/Visibility';
import Radio from "@mui/material/Radio";
import InfoIcon from '@mui/icons-material/Info';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from "react-router-dom";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
  import {INSURANCE,ESG,USERNAME} from "Insurance/CaseManagement/Config.js"
const {
    getAllCases,
} = require("../services/CaseFetch");

const ParentCases = ({title,Info,setInfo ,selectedCaseID,setSelectedCaseID,fetchsubCasesData,setSelectedType,showSnackbar }) => {
    const navigate = useNavigate();
    const ContextData = useContext(NoteContext);
    const {templateType,caseType} = useTemplateType();
    const [anchorElFilter, setAnchorElFilter] = useState(null);
   // const [Info, setInfo] = useState([]);
    const [selectedSubCase, setselectedSubCase] = useState({ CaseId: '' });
    //const [selectedCaseID, setSelectedCaseID] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(4);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
   
 
    const handleApplyFilter = async (filterOptions) => {
        try {
            showSnackbar("Case table refeshing please wait..", "info");
            await getAllCases(ContextData.UserSelected, caseType,filterOptions).then((data) => {
                const parsedData = data?.data?.values.reverse()
                if (parsedData?.length > 0) {
                    setInfo(parsedData);
                    setSelectedCaseID(parsedData[0].CaseId)
                    ContextData.setSelectedCaseType(parsedData[0]?.label)
                }
                else {
                    setInfo([])
                    //setSubcases([])
                    setSelectedCaseID('')
                    ContextData.setSelectedCaseType('')
                    //fetchsubCasesData([])
                }
            });
            showSnackbar("Case table refeshed", "success");
        } catch (error) {
            ContextData.setUserLogInError({
                message: error.message,
                Errors: true,
            });
            showSnackbar("Feching cases error", "error");
           // setLoading(false);
        }
    };

    const handleFilterClick = (event) => {
        setAnchorElFilter(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorElFilter(false);

    };
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        const startIndex = (page - 1) * itemsPerPage;
        fetchsubCasesData(Info[startIndex]?.CaseId || '',Info[startIndex]?.CaseType || '',Info[startIndex]?.label || '');
    };

    return (
        <>
            <Grid>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px" }}>
                    <Grid>
                        <p className="heading">Parent Cases</p>
                        <p className="subhead" style={{marginLeft:'10px'}}>Case Details</p>
                    </Grid>
                    <Grid>
                        <Grid item xs={6} md={6} textAlign="end">
                            {/* {Info?.length > 0 && <> */}
                            <FilterPopover
                                anchorEl={anchorElFilter}
                                onApplyFilter={handleApplyFilter}
                                onClose={handleFilterClose}
                            // CaseCategoryData={CaseCategorys}
                            // caseStatusData={caseStatus}
                            />
                            <Tooltip title="Filter">
                                <IconButton
                                    className="filtericon"
                                    disabled={Info?.length <= 0}
                                    onClick={handleFilterClick}
                                >
                                    <FilterAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                {Info.length > 0 ?
                    <Grid style={{ margin: "0px 10px" }}>

                        <Grid className="casestable-container">
                            <Table className="parentcasestable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Select</TableCell>
                                        <TableCell>{title}</TableCell>
                                        <TableCell>Creator</TableCell>
                                        <TableCell>Case Type</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Case Date</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Info.slice(startIndex, endIndex).map(row => (
                                        <TableRow key={row.CaseId} className="casestable-row">
                                            <TableCell><Radio
                                                onClick={()=>fetchsubCasesData(row.CaseId,row.CaseType,row?.label)}
                                                checked={selectedCaseID === row.CaseId} style={{
                                                    color: selectedCaseID === row.CaseId ? '#25A392' : '#52aa9d',
                                                }}></Radio></TableCell>
                                            <TableCell>{row?.caseTitle ? row.caseTitle:row.CaseId}</TableCell>
                                            <TableCell>{row.Creator}</TableCell>
                                            <TableCell>{row.CaseType}</TableCell>
                                            <TableCell>{row.Status}</TableCell>
                                            <TableCell>{row.CaseDate}</TableCell>
                                            <TableCell>
                                                <Tooltip title="View a case">
                                                    <IconButton
                                                        className="Table_Button_visibility"
                                                        //onClick={() => navigate(`/viewCase?case_id=${btoa(row.CaseId)}`)}
                                                       // onClick={() => navigate(`/viewallchildcases?case_id=${btoa(row.CaseId)}`)}
                                                    onClick={() => navigate(`/createcase?case_id=${btoa(row.CaseId)}&case_type=${btoa(row.CaseType)}&parent_case=${btoa(true)}&show=${btoa(true)}&edit=${btoa(false)}&iscreatecase=${btoa(false)}&templateType=${btoa(row?.label)}`)}
                                                    >
                                                        <Visibility className="eyeBtn" />
                                                    </IconButton>
                                                </Tooltip></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </Grid>
                        <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center",marginBottom: "10px" }}>
                            <Grid>
                                <Pagination
                                    count={Math.ceil(Info.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        marginTop: 1,
                                        alignSelf: "center",
                                        justifySelf: "center",
                                    }}
                                />

                            </Grid>
                            <Grid>
                                <Button
                                   style={{marginTop:'10px'}}
                                    className="viewallbtn"
                                    onClick={() => navigate('/viewallcases')}
                                >
                                    View All
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> :

                    <Grid style={{ paddingTop: "10px", margin: "0px 10px 10px 10px" }}>
                        <Grid className="rejectbox" >
                            <InfoIcon className="rejectHeading" style={{ fontSize: '32px', marginRight: '10px' }}></InfoIcon>
                            <Grid>
                                <Typography className="rejecttxt">No Data</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }


            </Grid>
        </>
    )
}


export default withSnackbar(ParentCases, "ParentCases", "success");