import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { editDisclosure } from "../services/FormCreationFetch";
import { useRoleContext } from "context/RoleContex";


function EditDisclosure(props) {
    const [DisclosureData, setDisclosureData] = useState({ name: props.data.ElementName, ID: props.data.ElementID });
    const { updateEditDisclosureTitleTrigered } = useRoleContext();

    console.log(props.data);
    const handleChangeForDisclosure = (event) => {
        const { name, value } = event.target;
        setDisclosureData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const UpdateTitle = async () => {
        try {
            // const data = await editDisclosure(DisclosureData);
            editDisclosure(DisclosureData).then((res) => {
                props.setEditPopUP(false);
                updateEditDisclosureTitleTrigered(true)
            })

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <Dialog open={props.EditPopUP} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Disclosure</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} p={1}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="questionid-input"
                                name="name"
                                label="Disclosure Name"
                                value={DisclosureData.name}
                                onChange={handleChangeForDisclosure}
                                type="text"
                                focused
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="DisclosureID"
                                name="ID"
                                label="Disclosure ID"
                                value={DisclosureData.ID}
                                type="text"
                                focused
                                disabled
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.setEditPopUP(false); }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {UpdateTitle()}} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditDisclosure;
