import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";


function EighthPage(props) {
  const [Questions, SetQuestions] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [Answer, setAnswer] = useState();
  const [QuestionNumber, setQuestionNumber] = useState();
  const [Question1, setQuestion1] = useState();
  const [Question2, setQuestion2] = useState();
  const [Question3, setQuestion3] = useState();
  const [Question4, setQuestion4] = useState();

  //--------------Creating table header for 1st question
  const CreateHeader1 = () => {
    const column = Object.keys(Question1[0]);
    const newcolumn = column.map((x) => {
      if (x !== "id" && x !== "isNew") {
        return <TableCell key={x}>{x}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  //-------get table row data
  const tdData1 = () => {
    return Question1.map((data, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question1[0]).map((x) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell key={x} component="th" scope="row">
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };

  //----------Create table header for 2nd question----------------

  const CreateHeader2 = () => {
    const column = Object.keys(Question2[0]);
    const newcolumn = column.map((y) => {
      if (y !== "id" && y !== "isNew") {
        return <TableCell key={y}>{y}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  // //------get table row data

  const tdData2 = () => {
    return Question2.map((data1, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data1.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question2[0]).map((y) => {
              if (y !== "id" && y !== "isNew") {
                return (
                  <TableCell key={y} component="th" scope="row">
                    {data1[y]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };

  //----------Create table header for 3rd question----------------

  const CreateHeader3 = () => {
    const column = Object.keys(Question3[0]);
    const newcolumn = column.map((y) => {
      if (y !== "id" && y !== "isNew") {
        return <TableCell key={y}>{y}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  // //------get table row data

  const tdData3 = () => {
    return Question3.map((data3, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data3.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question3[0]).map((y) => {
              if (y !== "id" && y !== "isNew") {
                return (
                  <TableCell key={y} component="th" scope="row">
                    {data3[y]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };

  //----------Create table header for 4th question----------------

  const CreateHeader4 = () => {
    const column = Object.keys(Question4[0]);
    const newcolumn = column.map((y) => {
      if (y !== "id" && y !== "isNew") {
        return <TableCell key={y}>{y}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  // //------get table row data

  const tdData4 = () => {
    return Question4.map((data4, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data4.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question4[0]).map((y) => {
              if (y !== "id" && y !== "isNew") {
                return (
                  <TableCell key={y} component="th" scope="row">
                    {data4[y]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };

  useEffect(() => {
    const FetchData = () => {
      //fetching table data for first question
      setQuestion1(
        Object.values(props.data.reportData["Section 2a-Item A"])[0].answer
      );

      //fetching table data for second question
      setQuestion2(
        Object.values(props.data.reportData["Section 2a-Item A"])[1].answer
      );

      //fetching table data for third question
      setQuestion3(
        Object.values(props.data.reportData["Section 2a-Item A"])[2].answer
      );

      //fetching table data for fourth question
      setQuestion4(
        Object.values(props.data.reportData["Section 2a-Item A"])[3].answer
      );

      const questions_array = [];
      const QuestionNumber = [];
      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Section 2a-Item A"]).length;
        i++
      ) {
        questions_array.push(
          Object.keys(props.data.reportData["Section 2a-Item A"])[i]
        );
        QuestionNumber.push(
          Object.values(props.data.reportData["Section 2a-Item A"])[
            i
          ].qno.substr(
            5,
            Object.values(props.data.reportData["Section 2a-Item A"])[i].qno
              .length
          )
        );
      }
      //console.log(questions_array);
      SetQuestions(questions_array);
      setQuestionNumber(QuestionNumber);
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <React.Fragment>
      {Isloading && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
                <li>Section 1a</li>
                <li>Section 1b</li>
                <li>Section 1c</li>
                <li style={{ color: "Black", fontSize: "20px" }}>
                  <b>Section 2a</b>
                </li>
                <li>Section 2b</li>
                <li>Section 3</li>
                <li>Section 4</li>
                <li>Section 5</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={10}>
              {/* ----------1st question and answer */}
              <div>
                {Questions.map((x, index) => {
                  if (index === 0) {
                    return (
                      <React.Fragment key={index}>
                        {/* --------------------1st table -----------------------------*/}
                            <b>{QuestionNumber[index]}</b>
                            {"\u00A0"}
                            {"\u00A0"}
                            {x}
                          <br />
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <TableContainer>
                              <Table
                                sx={{ minWidth: 650, width: "50%" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 1,
                                      },
                                    }}
                                  >
                                    {CreateHeader1()}
                                  </TableRow>
                                </TableHead>

                                {tdData1()}
                              </Table>
                            </TableContainer>
                          </div>
                      </React.Fragment>
                    );
                  }

                  if (index === 2) {
                    return (
                      <React.Fragment key={index}>
                        {/* --------------------2nd table -----------------------------*/}
                            <b>{QuestionNumber[index]}</b>
                            {"\u00A0"}
                            {"\u00A0"}
                            {x}
                          
                          <br />
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <TableContainer>
                              <Table
                                sx={{ minWidth: 650, width: "50%" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 1,
                                      },
                                    }}
                                  >
                                    {CreateHeader2()}
                                  </TableRow>
                                </TableHead>

                                {tdData2()}
                              </Table>
                            </TableContainer>
                          </div>
                        
                      </React.Fragment>
                    );
                  } else if (index === 3) {
                    return (
                      <React.Fragment key={index}>
                        {/* --------------------3rd table -----------------------------*/}

                        
                            <b>{QuestionNumber[index]}</b>
                            {"\u00A0"}
                            {"\u00A0"}
                            {x}
                          <br />
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <TableContainer>
                              <Table
                                sx={{ minWidth: 650, width: "50%" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 1,
                                      },
                                    }}
                                  >
                                    {CreateHeader3()}
                                  </TableRow>
                                </TableHead>

                                {tdData3()}
                              </Table>
                            </TableContainer>
                          </div>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={index}>
                        {/* --------------------3rd table -----------------------------*/}

                       
                            <b>{QuestionNumber[index]}</b>
                            {"\u00A0"}
                            {"\u00A0"}
                            {x}
                          <br />
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <TableContainer>
                              <Table
                                sx={{ minWidth: 650, width: "50%" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 1,
                                      },
                                    }}
                                  >
                                    {CreateHeader4()}
                                  </TableRow>
                                </TableHead>

                                {tdData4()}
                              </Table>
                            </TableContainer>
                          </div>
                      </React.Fragment>
                    );
                  }
                })}
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default EighthPage;
