export const  styles={
table:{border:'none',
        height: 450,
        // width:"100%",
        display: 'flex',
        flexGrow:1,
        color: "rgba(0,0,0,.85)",
        fontFamily: 'Microsoft YaHei',
        // fontFamily: ["-apple-system","BlinkMacSystemFont",'"Segoe UI"',"Roboto",'"Helvetica Neue"',"Arial","sans-serif",
        //             '"Apple Color Emoji"','"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        marginTop:"0px",
        "& .actions": {
          color: "text.secondary"
        },

        "& .textPrimary": {
          color: "text.primary"
        },

        "& .MuiCheckbox-root svg": {
          width: 16,
          height: 16,
          backgroundColor: "transparent",
          border: "1px solid #d9d9d9 ",
          borderRadius: 1
        },

        "& .MuiCheckbox-root svg path": {
          display: "none"
        },

        "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
          backgroundColor: "#25A392",
          borderColor: "white"
        },

        "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
          position: "absolute",
          display: "table",
          border: "2px solid #fff",
          borderTop: 0,
          borderLeft: 0,
          transform: "rotate(45deg) translate(-50%,-50%)",
          opacity: 1,
          transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
          content: '""',
          top: "50%",
          left: "39%",
          width: 5.71428571,
          height: 9.14285714
        },

        "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
          width: 8,
          height: 8,
          backgroundColor: "#25A392",
          transform: "none",
          top: "39%",
          border: 0
        },

        "& .MuiDataGrid-columnsContainer": {
          backgroundColor: "#fafafa"
        },

        "& .MuiDataGrid-iconSeparator": {
          display: "none"
        },

        "& .MuiDataGrid-columnHeader": {
          borderRight: '1px solid lightgrey',
          borderTop: '1px solid lightgrey',
          //borderBottom: '1px solid lightgrey',
        // border: "1px solid white",
          backgroundColor: "#E9F6F4",
          borderRadius:"0px",
          color:'#25A392',
        },
        "& .MuiDataGrid-columnGroupHeader":{
          color:"black",
          backgroundColor:"white",
          height:"10px"
        },

        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
          borderRight: "1px solid lightgrey",
          borderBottom: "1px solid lightgrey"
        },

        "& .MuiDataGrid-cell": {
          color: "#384651"
        },

        "& .MuiPaginationItem-root": {
          borderRadius: 0
        },

        '& .MuiDataGrid-cell--editing':{
          boxShadow:'none',
        }
},
toolbarContainer:{
        display: "flex",
        justifyContent: "flex-start",
        borderRadius:"10px",
        backgroundColor:"white",
        paddingTop:"0px",
        paddingBottom:'5px',
        paddingLeft:'0px'
},

toolbarBox:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
        '& .MuiButtonGroup-grouped':{
        borderColor:'#BAD5E7',
        color:"#2076B0",
        borderRadius:'6px',
        backgroundColor:"#E9F1F7",
        transition:" transform .1s",
        alignContent:"center"
        },
        '& .MuiButtonGroup-grouped:hover':{
          borderColor:'gray',
          color:"white",
          backgroundColor:'#607d8b',
          transform:" scale(1.1)",
        }
},

addDelete:{
        paddingLeft:"15px",
        paddingRight:"5px",
},

buttonIcons:{
      paddingLeft:"15px",
      paddingRight:"5px",
      alignContent:'center',
}
}