import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import "../styles/OnBoard/CustOnBoard.css";
import Form_Text_input from "ESG/forms/Form_Text_input";
import Form_Address from "ESG/forms/Form_Address";
import Form_Autocomplete from "ESG/forms/Form_Autocomplete";
import Number from "ESG/forms/Form_Number";
import {
  GetCostomerOnBoardQuestions,
  getMultiSelectorValues,
  getDropdownValues,
  GetOnboardQuestionResponse,
  UpdateOnboardingQuestionResponse,
} from "ESG/services/Fetch";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Form_Table from "ESG/forms/Form_Table";
import MultiSelected from "ESG/forms/Form_MultiSelecter";
import Form_SubForm from "ESG/forms/Form_SubForm.js";
import Editable_List from "ESG/forms/Form_EditableList";
import { useNavigate } from "react-router-dom";
//import {getTelephoneNumber,GetCurrancy,getFormDropdownValues} from 'services/fetch';
import Form_Date_input from "ESG/forms/Form_Date_input";
import { useLocation } from "react-router";
import Container from "@mui/material/Container";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const OnBoardQuestions = ({ CIK }) => {
  // CIK = 1234;
  const domain = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  const [CostomerOnboardQuestions, setCostomerOnboardQuestions] = useState();
  const [InputField, setInputField] = useState();
  const [QuestionId, setQuestionId] = useState();
  const [FieldType, setFieldType] = useState();
  const [answer, setAnswer] = useState();

  let Answer;

  const getCostomerOnBoardQuestions = async () => {
    const res = await GetCostomerOnBoardQuestions();
    setCostomerOnboardQuestions(res);
    //console.log(res);
  };

  useEffect(() => {
    getCostomerOnBoardQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [expanded, setExpanded] = React.useState();

  const handleChange = (answer, id) => {
    setAnswer(answer);
    Answer = answer;
  };

  // console.log(CIK);
  const GetInputFieldForm = async (data, Answer) => {
    const key = data.key.id;
    const FieldType = data.key.properties.FieldType[0].value;
    console.log(FieldType);
    switch (FieldType) {
      case "Text Field":
        return (
          <Form_Text_input
            Answer={Answer}
            onChange={(answer) => handleChange(answer, key)}
          />
        );

      case "Drop Down":
        const DropdownValues = await getDropdownValues(key);
        return (
          <Form_Autocomplete
            FieldValues={Object.values(DropdownValues).toString()}
            Answer={Answer}
            onChange={(answer) => handleChange(answer, key)}
          />
        );

      case "Table":
        return (
          <Form_Table
            Answer={Answer ? JSON.parse(Answer) : []}
            TableJson={JSON.parse(data.key.properties.TableJson[0].value)}
            onChange={(answer) => handleChange(answer, key)}
            questionId={key}
            cik={CIK}
            domain={JSON.parse(localStorage.getItem("Name")).id.split("@")[1]}
          />
        );

      case "Editable List":
        return (
          <Editable_List
            Answer={Answer ? JSON.parse(Answer) : Answer}
            onChange={(answer) => handleChange(answer, key)}
          />
        );
      case "Form Field":
        //  console.log(Answer);
        return (
          <Form_SubForm
            Answer={
              typeof Answer === "string" && Answer.length !== 0
                ? JSON.parse(Answer)
                : {}
            }
            FormFieldJson={JSON.parse(
              data.key.properties.FormFieldJson[0].value
            )}
            onChange={(answer) => handleChange(answer, key)}
          />
        );

      case "List of Address":
        return (
          <Form_Address
            Answer={Answer ? JSON.parse(Answer) : Answer}
            onChange={(answer) => handleChange(answer, key)}
            ListOfAddress={true}
          />
        );
      case "Date":
        return (
          <Form_Date_input
            Answer={Answer}
            onChange={(answer) => handleChange(answer, key)}
          />
        );
      case "Number Field":
        return (
          <Number
            Answer={Answer}
            onChange={(answer) => handleChange(answer, key)}
          />
        );

      case "Multiselect":
        const response_dropdown = await getMultiSelectorValues(key);
        return (
          <MultiSelected
            Answer={Answer ? JSON.parse(Answer) : Answer}
            onChange={(answer) => handleChange(answer, key)}
            response_dropdown={
              response_dropdown?.values ? response_dropdown?.values : []
            }
          />
        );
    }
  };
  const navigate = useNavigate();

  const handleExpand = async (item) => {
    setInputField("");
    //  console.log(CIK, QuestionId, FieldType, answer);
    answer &&
      UpdateOnboardingQuestionResponse( QuestionId, FieldType, answer);
    const response = await GetOnboardQuestionResponse(item.key.id);
    //console.log(response.answer.Answer);
    GetInputFieldForm(item, response.answer.Answer).then((res) => {
      setInputField(res);
    });
    setExpanded(item.key.id);
    Answer = response.answer.Answer;
    setAnswer(response.answer.Answer);
    setQuestionId(item.key.id);
    setFieldType(item.key.properties.FieldType[0].value);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <h4 style={{ paddingLeft: "450px" }}>Additional Questions</h4>
        </div>
        {/* <Button
          onClick={() => navigate("/GoalSettingPage")}
          // className="form-button"
          variant="contained"
          color="secondary"
          type="button"
        >Next</Button> */}
      </div>
      <div className="form-content1">
        <div className="form-boxx1">
          {CostomerOnboardQuestions &&
            Object.entries(
              CostomerOnboardQuestions.basetree[0].customerOnBoardQuestions
                .value
            ).map((item) => (
              <Accordion
                key={item[1].key.id}
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === item[1].key.id}
                onChange={() => handleExpand(item[1])}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>
                    {item[1].key.properties.Question[0].value}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{InputField}</AccordionDetails>
              </Accordion>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default OnBoardQuestions;
