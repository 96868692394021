import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getCaseDataForGraph } from "Compliance/services/CaseFetch";

const CaseComplianceGraph = () => {

  const [isloaded, setisloaded] = useState(false);
  const [CaseName, setCaseName] = useState([]);
  const [CaseValues, setCaseValues] = useState([]);

  const fetchdataforGraph = async () => {
    try {
      const data = await getCaseDataForGraph();
console.log("%%%%%%",data.data)
      setCaseName(Object.keys(data.data.caseStatus[0]));
      setCaseValues(Object.values(data.data.caseStatus[0]));
      setisloaded(true);

    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    fetchdataforGraph();
  }, []);

  // Define your data for the pie chart

  const options = {
    chart: {
      type: "pie",
    },

    labels: CaseName,
  };

  const series = CaseValues;

  return (
    <div>
      {isloaded && (
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          height={250}
        />
      )}
    </div>
  );
};

export default CaseComplianceGraph;
