import React, { useEffect, useState, useContext } from "react";
import NoteContext from "context/PageContext";
import Popover from "@mui/material/Popover";
import {
  Grid,
  Box,
  Button,
  Typography,

} from "@mui/material";
import CardRow from "Compliance/CaseManagment/CaseDetails";
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { GetCaseNotification } from "Compliance/services/CaseFetch";





export default function Header({ title, mytasks_visible, backarrow_visible, link, showSnackbar }) {

  const navigate = useNavigate();
  const [hasCaseCreatorRole, setHasCaseCreatorRole] = useState();
  const [showCardRow, setShowCardRow] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ContextData = useContext(NoteContext);
  const [data, setdata] = useState(null);

  const[notificationData, setNotificationData] = useState([])
  const [ apiNotificationsRes, setApiNotificationsRes] = useState(false)

  useEffect(() => {
    setHasCaseCreatorRole(
      ContextData?.CaseRole?.name === "Case Creator" ? true : false
    );

  }, [ContextData?.CaseRole?.name, hasCaseCreatorRole]);






  const handleClick = (event) => {
    //alert("test")
    setAnchorEl(true);
    setShowCardRow(true);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleCardClick = (title, code) => {

    setdata({ CaseCategory: title, case_type: code });



  };

  const GetNotificationData = ({data}) => {
    return(
      <Grid>
        <Typography>{data.message}</Typography>
      </Grid>
    )

  }



  return (
    <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: 'center',paddingTop: '20px' }}>
      <Grid style={{ display: 'flex', alignItems: 'center',cursor:"pointer" }}>{backarrow_visible && <ArrowBackIosIcon onClick={() => { navigate(link) }} />}<Grid className="mainheading" >{title}</Grid></Grid>
      <Grid>
        {hasCaseCreatorRole && (
          <Button

            variant="contained"
            onClick={handleClick}
            className="createcase"
          >
            New Case
          </Button>
        )}
        {mytasks_visible !== 'false' && (
          <Button
            // id="create-case-button"
            // disabled={!hasCaseCreatorRole}
            // aria-describedby={id}
            variant="contained"
            onClick={() => navigate('/mytasks')}
            className="createcase"
          >
            My Tasks
          </Button>
        )}

      </Grid>
      <Popover

        className="POPUpB0x"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: '50%', left: '50%' }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        }}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}
      /*anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}*/
      >
        
        <Box sx={{ flexGrow: 1 }} className="CreateCaseBox" >
          {showCardRow && (
            <CardRow
              onCardClick={handleCardClick}
              onClose={handleClose}
            //onselectedtypeTemp={onselectedtypeTemp}
            />
          )}
        </Box>
      </Popover>
    </Grid>
  )
}