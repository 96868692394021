import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import "../../styles/Report/Report.css";
import { useCallback } from "react";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
 
function NominationReport(Props) {
 
  const [Impacts,setImpacts]=useState();
  const [pageLoading, setpageLoading] = useState(false);
  const [Nomination , setNomination]=useState();
  const fetchdata = useCallback(() => {
  //  console.log(Props.data.reportData["GRI 2.10 Nomination and selection of the highest governance body"]);
  const array=[];
      let htmlString= (((Object.values(Props.data.reportData["GRI 2.10 Nomination and selection of the highest governance body"])[0]).answer));
      setImpacts(((Object.values(Props.data.reportData["GRI 2.10 Nomination and selection of the highest governance body"])[1]).answer));
     let lenth=0;
     let startelement=0;
     for ( lenth;lenth<htmlString.length;)
     {
      lenth = htmlString.indexOf('</p>')+4;
      let data=htmlString.slice(startelement, lenth);
      htmlString=htmlString.slice(lenth);
      array.push(data);
     }
     
     // console.log(array);
      setNomination(array);
      setpageLoading(true);
  },[Props]);
  useEffect(() => {
    fetchdata();
  }, [fetchdata]);

  return (
    <div>
      {pageLoading ? (
        <>
          <Box  className="EmpBox">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <h4>Apple.Inc </h4>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
              <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={10}  className="grid1">
          <Item>
          <h4 className="underline">GRI 2.10 Nomination and selection  processes for the highest governance body and its committees</h4>
          <div dangerouslySetInnerHTML={{__html:Nomination}}></div>
          <h4>Criteria used for nominating and selecting the highest governance body members by independence and competencies relevant to the impacts of the organization</h4>
          <div dangerouslySetInnerHTML={{__html:Impacts}}></div>
          </Item>
        </Grid>
       
      </Grid>
    </Box>
          
        </>
      ) : (
         <div className="loaders">Loading...</div>
      )}
    </div>
  );
}

export default NominationReport;
