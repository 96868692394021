import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TCFDlogo from "../../../images/TCFDlogo.png";


const GDB1 = (props)  =>{
    
    const [Management,setManagement] = useState("")
    const [committees,setcommittees]=useState();
    const [pageloaded, setpageloaded] = useState(false)

    useEffect (()=>{ 
       // console.log(Object.values(props.data.reportData["G.Disclosure B"]));
        setManagement((Object.values(props.data.reportData["G.Disclosure B"])[4]).answer);
        setcommittees((Object.values(props.data.reportData["G.Disclosure B"])[5]).answer);
        
        setpageloaded(true);
    },[props])

    return(
    <React.Fragment>
        {pageloaded ? (
        <>
       
            <Grid container spacing={1}className="border">
                <Grid item xs={12} md={2} className="border" >
                <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  /></div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li style={{color:"#61c4ee",fontSize:"20px"}}><b>Governance</b></li>
                    <li>Strategy</li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#61c4ee"}} className="border">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Governance</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the processes by which management is informed about climate-related issues.</p>
                  </Grid>
                <Grid item xs={12} md={6}>
                        <h4><b>G.Disclosure B</b></h4>
                        <h6><b>Describe the processes by which management is informed about climate-related issues</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Management}}></div>
                </Grid>
                <Grid item xs={12} md={6} className="grid2">
                  <h6><b>Describe how management (through specific positions and/or management committees) monitors climate-related issues</b></h6>
                  <div dangerouslySetInnerHTML={{__html:committees}}></div>
                </Grid>
                </Grid>
            </Grid>
          
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </React.Fragment>
    );
}
export default GDB1;