import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import "../ESG/styles/Home/home.css";
import FeedIcon from "@mui/icons-material/Feed";
import { CardActions, Typography, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

// Configuration object for titles, routes, and icons
const titleConfig = {
  GRI: { route: "/Paginations", icon: <FeedIcon /> },
  PF: { route: "/PFReportPage", icon: <FeedIcon /> },
  TCFD: { route: "/TCFDReportPage", icon: <FeedIcon /> },
  ADV: { route: "/ADVReportPage", icon: <FeedIcon /> },
};

export default function DisclosureInfoCard({
  title,
  subtitle,
  progressValue,
  Status,
  StartDate,
  EndDate,
  Deadline,
  Enviroment,
  Social,
  Governance,
}) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { route, icon } = titleConfig[title] || { route: "", icon: null };

  return (
    <>
      <Box className="DisclosureInfoCard_Box">
        <Paper elevation={3}>
          <Card>
            <CardContent>
              <Typography  variant="body1">
                {title}
              </Typography>

              <Typography
                className="DisclosureInfoCard_subtitle"
                variant="body2"
              >
                <LinearProgress
                  className="DisclosureInfoCard_progress"
                  variant="determinate"
                  value={progressValue}
                />
                {subtitle}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                variant="outlined"
                className="Button_DisclosureInfoCard"
                onClick={() =>
                  navigate("/ViewReport", {
                    state: { disclosure: title, startdate: StartDate },
                  })
                }
              >
                View
              </Button>
              {route && (
                <IconButton
                  variant="outlined"
                  onClick={() => navigate(route)}
                  aria-label="open"
                  size="medium"
                  className="IconButton"
                >
                  {icon}
                </IconButton>
              )}

              <IconButton
                className={`expand-more-button ${expanded ? "expanded" : ""}`}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography variant="body2">
                  Deadline: {Deadline}
                </Typography>
                <Typography variant="body2">
                  status: {Status}
                </Typography>
                <Typography variant="body2">
                  Start date: {StartDate}
                </Typography>
                <Typography variant="body2">
                  End date: {EndDate}
                </Typography>
                <Typography variant="body2">
                  Environment:
                  {Enviroment === "0.00%" ? "undefined" : Enviroment}
                </Typography>
                <Typography variant="body2">
                  Social: {Social === "0.00%" ? "undefined" : Social}
                </Typography>
                <Typography variant="body2">
                  Governance: {Governance === "0.00%" ? "undefined" : Governance}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </Paper>
      </Box>
    </>
  );
}
