import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PaginationItem from '@mui/material/PaginationItem';
import DisclouserComplianceGraph from './DisclouserComplianceGraph';
// import Curosels from "./Curosels"


export default function DisclosuserCompliance(){
    return(

      <div style={{display:'flex',gap:"2rem", paddingTop:'1rem',justifyContent:"space-evenly"}}>
          <div style={{border:"1px solid #00000033", padding:"10px 20px",borderRadius:"4px"}}>
            <div style={{paddingBottom:'10px'}}>
            <CaseCompliance1 />
            </div>
            <CustomizedTables />
            <div style ={{paddingTop:'10px'}}>
            <BasicPagination />
            </div>
          </div>
          <div style={{border:"1px solid #00000033", padding:"10px 20px",borderRadius:"4px"}}>
            <CustomIcons />

          <DisclouserComplianceGraph   />
          
          <DisclouserComplianceGraph  />
          
          <DisclouserComplianceGraph  />
          
          <DisclouserComplianceGraph  />
          
          </div>
          <div>
          {/* <Curosels/> */}
            </div>
        
        </div>
    );
}
function CustomIcons() {
  return (
    <Stack spacing={2}>     
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
      />
    </Stack>
  );
}

// Pagination
 function BasicPagination() {
  return (
    <Stack spacing={2}>  
      <Pagination count={10} color="primary" showFirstButton showLastButton />     
  </Stack>
  );
}

//table style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "darkblue",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Eclair', 262, 16.0),
  createData('Cupcake', 305, 3.7),
  createData('Gingerbread', 356, 16.0),
];

//Table 
function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="Center">Category</StyledTableCell>
            <StyledTableCell align="Center">Type</StyledTableCell>
            <StyledTableCell align="Center">OpenDate</StyledTableCell>
           </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

//Top buttons
function CaseCompliance1() {
  return (
    <div>
    <Stack direction="row" spacing={2}>
      <Button variant="contained" >Open(6) </Button> 
      <Button variant="contained" >Close(5)</Button> 
      <Button variant="contained" >All(11)</Button>            
    </Stack>

    </div>
    
  );
}