import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Typography, IconButton } from "@mui/material";
import { CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from "@mui/material/styles";
import "../styles/Real_Time_Data/Real_Time_Data.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
// import ObjectViewer from "./Product";
function getObjectKeysAndValues(obj, prefix = '') {
  const keysAndValues = [];

  for (const key in obj) {
    if (key === 'details') {
      let value
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          value = {}
        } else if (obj[key] === "{}" && obj[key].length > 1) {
          value = JSON.parse(obj[key])
        } else {
          value = (JSON.parse(obj[key].length > 0) ? JSON.parse(obj[key]) : obj[key])
        }
        // const value = (JSON.parse(obj[key].length>0)?JSON.parse(obj[key]):obj[key]) ;
        const newPrefix = prefix ? `${key}` : key;

        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }

    else {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        // const newPrefix = prefix ? `${prefix}-->${key}` : key;
        const newPrefix = prefix ? `${prefix}->${key}` : key;
        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }
  }
  return keysAndValues;
}

function ObjectViewer(props) {
  const { data } = props;

  const keysAndValues = getObjectKeysAndValues(data);

  return (
    <ul>
      {keysAndValues.map(({ key, value }) => (
        <li key={key}>
          <strong>{key}: </strong>
          {value}
        </li>
      ))}
    </ul>
  );
}
const columns = [
  { id: 'name', label: 'LEI', minWidth: '50px', align: 'left', },
  { id: 'FullName', label: 'mic', minWidth: '50px', align: 'left', },
  { id: 'ShortName', label: 'Oprating Mic', minWidth: '50px', align: 'left', },
  { id: 'currancy', label: 'Status', minWidth: '50px', align: 'left', },
];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

 function Product({ Search,Refresh,showSnackbar }) {
  const ContextData = useContext(NoteContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [TotalPage, setTotalPage] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [tempData, setTempData] = useState();
  const [Executingvenue, setExecutingvenue] = useState([]);
  const [UpdateCompanyDetails, setUpdateCompanyDetails] = useState();
  const { GetExecutingvenue, GetTransactons, } = require("../../ESG/services/Fetch");
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const GetDetails = async () => {
    try {
      const data = await GetExecutingvenue(page, rowsPerPage);
      showSnackbar("Loading data, please wait...", "info");
      if (data?.venues) {
        if (Executingvenue?.length > 0) {
          setExecutingvenue([...Executingvenue, ...data.venues]);
        } else {
          setExecutingvenue(data.venues);
        }
        setTotalPage(data.rows);
      } else {
        // Handle the case when data.venues is falsy (e.g., data is null or undefined)
        console.error("Data is missing or invalid.");
      }
      showSnackbar("Data loaded successfully", "success");
    } catch (error) {
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true
      });
      // Handle any errors that occur during the API call.
      showSnackbar("Error loading data. Please try again.", "error")
      console.error("An error occurred:", error);
    }
  };
  
  useEffect(() => {
    GetDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, Refresh]);
  
  useEffect(() => {
    const GetTransactonSDetails = async () => {
      showSnackbar("Loading data, please wait...", "info");
      try {
        const data = await GetTransactons();
        // Check if data is valid before setting state
        if (data && data.venues) {
          // Handle the data here, for example:
          // setExecutingvenue(data.venues);
          // console.log(data);
        } else {
          // Handle the case when data is missing or invalid
          console.error("Data is missing or invalid.");
        }
        showSnackbar("Data loaded successfully", "success");
      } catch (error) {
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        // Handle any errors that occur during the API call.
        console.error("An error occurred:", error);
        showSnackbar("Error loading data. Please try again.", "error")
      }
    };
    GetTransactonSDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array, so it runs once on component mount
  
  
  // handle Company Details in Card
  const handleUpdate = (row, i) => {
    setUpdateCompanyDetails(row)
    setSelectedRowIndex(i)
  }

  //pagination for table handle change page 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

  };
  //pagination for table handle Change Rows Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Grid container spacing={0} >
      <Grid item xs={8}>
        <TableContainer className="TableContainer">
          <Table stickyHeader aria-label="sticky table" >
            <TableHead >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="Table_Head"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Executingvenue && Executingvenue.filter((val) => {
                if (Search === "" || Search === null) {
                  return val;
                } else if (
                  val.operatingmic
                    .toLowerCase()
                    .includes(Search.trim().toLowerCase())
                ) {
                  return val;
                }
              }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                <TableRow
                  style={{ backgroundColor: i === selectedRowIndex ? '#c4c4ff' : '' }}
                  onClick={() => handleUpdate(row, i)}
                  key={i}
                  className={i % 2 === 0 ? "TableRowOdd" : 'TableRowEven'}
                >
                  <TableCell component="th" scope="row" >
                    {row?.lei ?? 'undefined'}
                  </TableCell>
                  <TableCell align="left">{row?.mic ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.operatingmic ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.status ?? 'undefined'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={Math.floor(TotalPage)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <Grid item xs={4}>
        {UpdateCompanyDetails && UpdateCompanyDetails ? <>
          <Typography className="More_Details">{UpdateCompanyDetails?.mic}</Typography>
          <Card className="CompanyDetails_Card" >

            <CardContent className="CardContent">
              <ObjectViewer data={UpdateCompanyDetails} />

            </CardContent>
          </Card>
        </> : ''}
      </Grid>
    </Grid>
  );
}

export default withSnackbar(Product, "Home paage", "success");