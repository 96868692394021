// withSnackbar.js
import React, { useState } from 'react';
import ReusableSnackbar from './ReusableSnackbar'; // Adjust the path

const withSnackbar = (WrappedComponent, defaultSnackbarMessage, defaultSeverity) => {
  return function WithSnackbar(props) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(defaultSnackbarMessage);
    const [snackbarSeverity, setSnackbarSeverity] = useState(defaultSeverity);

    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };

    const handleShowSnackbar = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };

    return (
      <div>
        <WrappedComponent
          {...props}
          showSnackbar={handleShowSnackbar} // Pass the function to the wrapped component
        />
        <ReusableSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        />
      </div>
    );
  };
};

export default withSnackbar;
