import axios from "axios";
const { REACT_APP_API_URL } = process.env;

// Api for fetching BackEnd Graph (without Gender filter ) 
export const GetGraphdata = async (label, key, from_year, to_year) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  if (key === null || key === "") {
    return await axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/graphdata/${label}`,
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },
    })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  } else {
    return await axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/graphdata/${label}`,

      params: {
        country: key,
        from_year: from_year,
        to_year: to_year,
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },
    })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  }
};

// Api for fetching Country List data for dropdown
export const GetCountryList = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/location/countryList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => {
      //  console.log(response.data.values);
      return response.data.values;
    })
    .catch((error) => console.log(error));
};
// Api for fetching BackEnd Graph (with Gender filter )
export const GetSocaildata = async (
  label,
  Country,
  key,
  from_year,
  to_year
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/graphdata/${label}`,
    params: {
      gender: key,
      country: Country,
      from_year: from_year,
      to_year: to_year,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
// Api for applying Year Filter (For range silder)
export const Getdata = async (label, from_year, to_year) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/graphdata/${label}`,
    params: {
      from_year: from_year,
      to_year: to_year,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  });
};

export const GETGraphRender = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
   url: `${REACT_APP_API_URL}/graphdata/graphtemplate`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  });
};

export const SectorGraphRender = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/graphdata/sectorGraphList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  });
};
//

export const GetMaterialTopics = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getMaterialTopics`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data.values)
    .catch((error) => console.log(error));
};

export const PutGoalSetting = async (
  Material_Topic,
  CIK,
  Date,
  year2023,
  year2024,
  year2025,
  year2026,
  year2027
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  // console.log(
  //   Material_Topic,
  //   CIK,
  //   Date,
  //   year2023,
  //   year2024,
  //   year2025,
  //   year2026,
  //   year2027
  // );
  // console.log(year2023);
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/createGoalSetting`,
    params: {
      Material_Topic: Material_Topic,
      CIK: CIK,
      OnBoardDate: Date,
      year2023: year2023,
      year2024: year2024,
      year2025: year2025,
      year2026: year2026,
      year2027: year2027,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data.values)
    .catch((error) => console.log(error));
};

export const GetGoalSettingValues = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getGoalSetting`,

    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  });
};

export const updateGoalSetting = async (formData) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/updateGoalSetting`,
    params: {
      goalId: formData.id,
      Material_Topic: formData.topic,
      year2023: formData.year1,
      year2024: formData.year2,
      year2025: formData.year3,
      year2026: formData.year4,
      year2027: formData.year5,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data.values)
    .catch((error) => console.log(error));
};

export const DeleteGoalData = async (id) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "DELETE",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/deleteGoal`,
    params: {
      goalId: id,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data.values)
    .catch((error) => console.log(error));
};

export const GetMaterialTopicsfortable = async (MaterialTopic) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getMaterialTopics`,
    params: {
      topic: MaterialTopic,
    },

    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const CreateGoals = async (topic, formData) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
 
  // console.log(formData,topic);
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/addMaterialTopic`,
    data: formData,
    params: {
      topic: topic,
      date: "03/08/2023",
      domain: localStorage.getItem("UserSelectedDomain"),
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const GetTableGridForMaterialTopic = async (topic) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  //  console.log(topic,domainName);
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getTableGridForMaterialTopic`,
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
    },

    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => {
      // console.log(response.data);
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const AddTableData = async (topic, CreatedDate, data) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/addTableData`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
      year: CreatedDate,
    },
    data: {
      data: data,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const GetTableData = async (topic, year) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  //console.log(topic, year, domainName);
  // console.log(topic, year);
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getTableData`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
      year: year,
    },
  })
    .then((response) => {
      //console.log(response.data.data.properties.response[0].value)
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const fetchMaterialTopic = async (topic) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/fetchMaterialTopic`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetGantChartData = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/goalSettingData`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getFormGridForMaterialTopic = async (topic) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  //console.log(topic);
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getFormGridForMaterialTopic`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
    },
  })
    .then((response) => {
      //  console.log(topic, response.data);
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const addMaterialTopicFormData = async (topic, year, subTopic, data) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  // console.log(topic, year, subTopic, data);
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/addMaterialTopicFormData`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
      year: year,
      subTopic: `${subTopic}`,
    },
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
};

export const getMaterialTopicFormData = async (topic, year, subTopic) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  //  console.log(topic,domainName);
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getMaterialTopicFormData`,
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
      year: year,
      subTopic: subTopic,
    },

    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => {
      // console.log(response.data);
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const getTableData = async (topic, year) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
 
   
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getTableData`,
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
      year: year,
    },

    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) =>  response.data)
    .catch((error) => console.log(error));
};

export const ListOfYearsForWhichMaterialTopicIsCreated = async (subTopic) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/ListOfYearsForWhichMaterialTopicIsCreated`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      subTopic: subTopic,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const addProject = async (topic, year, subTopic, data) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  console.log(topic, year, subTopic, data);
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/addProject`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic: topic,
      year: year,
      subTopic: subTopic,
    },
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
};

export const getProjects = async (Topic) => {
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  const tokens = JSON.parse(localStorage.getItem("tokens")); 
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getProjects`,
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
      topic:Topic,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getGroups = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/azure/groups`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const PostaddCompany = async (companyInfo) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/addCompany`,
    data: companyInfo,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const getAllCompany = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getAllCompany`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const deleteCompany = async (id) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "DELETE",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/deleteCompany`,
    params: {
      companyName: id,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data.values)
    .catch((error) => console.log(error));
};

export const editCompany = async (companyInfo) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/editCompany`,
    data: companyInfo,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const getInterestedProductDropDownList = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getInterestedProductDropDownList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const PostsuccessOnboard = async (companyInfo) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/successOnboard`,
    data: companyInfo,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const getCompanyByDomain = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getCompanyByDomain`,
    params: {
      domain: domainName,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const ghgEmissionBySector = async (country, sector) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  //const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/graphdata/fetchSectorBasedDataForGraph`,
    params: {
      country: country,
      graphLabel: sector,
      // property: "Agriculture",
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};


