import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext } from 'react';
import NoteContext from 'context/PageContext';
import '../ESG/styles/ErrorPage/ErrorPage.css'

export default function ErrorMessages({handleLogin}) {
  const contextData = useContext(NoteContext);

  const handleClose = () => {
    contextData.setUserLogInError({
      Errors: false
    });
  };

  return (
      <Dialog
      className='ErrorMessages'
        fullWidth={true}
        open={contextData.UserLogInError.Errors}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"ERROR:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
         {contextData.UserLogInError?.message==='Request failed with status code 401' ?
           "Your access token expried. please login again..."
           :contextData.UserLogInError?.message
         }
            {/* Your access token "expried".
            please login again... */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
     { contextData.UserLogInError?.message==='Request failed with status code 401' && <Button autoFocus onClick={() =>{ handleLogin("popup");
        contextData.setUserLogInError({
          Errors: false
        });
      }}
        >
           Click to Login again
          </Button>}
          <Button onClick={handleClose} >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
}
