import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const MaterialTopic1 = (props)  =>{
    const [prioritized,setprioritized] = useState()
    const [experts,setexperts] = useState()
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
        setprioritized((Object.values(props.data.reportData["GRI 3.1 Process to determine material topics"])[1]).answer)
         setexperts((Object.values(props.data.reportData["GRI 3.1 Process to determine material topics"])[2]).answer)
        // setcontributed((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[2]).note)
        // setfeedback((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[4]).note)
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5 className="underline">Process to determine material topics</h5>
                        <h6><b>Explain how it has prioritized the impacts for reporting based on their significance.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:prioritized}}></div>
                        
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h6><b>Describe other processes by which the organization provides for or cooperates in the remediation of negative impacts that it identifies it has caused or contributed to.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:experts}}></div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default MaterialTopic1;