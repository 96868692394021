import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "../styles/Forms_Styles/Form_RadioButton.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

const Form_Radio_Group = ({FieldValues,onChange,getAnswerDisable}) => {
  console.log(getAnswerDisable)
  const getApprove=getAnswerDisable;
  const {Answer}=useContext(NoteContext);
  let arr =[]
  if(typeof FieldValues === 'string' && FieldValues.trim() !== ""){

   arr = FieldValues.split(",").map(value => value.trim());
  }else
  {
  arr=[];
  }

  const handleChange = (event) => {
    onChange(event.target.value);
  };
  let Info='';
  try {
    Info= (arr.includes(Answer.UpdatedAnswer) && Answer.UpdatedAnswer.length !== 0 && Answer.UpdatedAnswer !== null) ? Answer.UpdatedAnswer : ''
  } catch (error) {
    Info='';
  }
  return (
    <FormControl disabled={getApprove}>
   
    {arr?.map((item, index) => (
    <RadioGroup
      key={index}
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={Info}
      onChange={handleChange}
    >
      <FormControlLabel value={item} control={<Radio color="success"/>} label={item} />
    
    </RadioGroup>
    ))}
  </FormControl>
    
   
  );
};
export default Form_Radio_Group;
