import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";

function RDA3(props) {
  const [isloading, setisloading] = useState(false);
  const [carbon, setcarbon] = useState();
    const [weather, setweather] = useState();

  useEffect(() => {
    // console.log("hello",(Object.values(props.data.reportData["R.Disclosure A"])));
      setweather(Object.values(props.data.reportData["R.Disclosure A"])[7].answer);
    setcarbon(Object.values(props.data.reportData["R.Disclosure A"])[8].answer);
      // console.log(Object.values(props.data.reportData["S.Disclosure B"]));
    setisloading(true);

    //CreateHeader();
  }, [isloading,props.data.reportData]);

  return (
    <React.Fragment>
      {isloading && (
        <>
          
            <Grid container spacing={1} className="border2">
            <Grid item xs={12} md={2} className="border2" >
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                    <li>Strategy</li>
                    <li style={{color:"#373737",fontSize:"20px"}}><b>Risk Management</b></li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#373737"}} className="border2">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Risk Management</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe how processes for identifying, assessing, and managing climate-related risks 
                  are integrated into the organization’s overall risk management.</p>
                  </Grid>
              <Grid item xs={12} md={6} className="grid1">
              <h4 className="underline">R.Disclosure A</h4>
                <h5><b>Insurance companies should describe the processes for identifying and assessing climate-related risks on re-/insurance portfolios by geography,
                   business division, or product segments for physical risks from changing frequencies and intensities of weather-related perils.</b></h5>
                <div dangerouslySetInnerHTML={{ __html: weather }}></div>
                 </Grid>
                 <Grid item xs={12} md={6} className="grid2">
                <h5><b>Insurance companies should describe the processes for identifying and assessing climate-related risks on re-/insurance portfolios by geography, business division, or product segments for transition risks resulting from a reduction in insurable interest
                   due to a decline in value, changing energy costs, or implementation of carbon regulation.</b></h5>
                <div dangerouslySetInnerHTML={{ __html: carbon }}></div>
                 </Grid>
              </Grid> 
            </Grid>
        </>
      )}
   </React.Fragment>
  );
}

export default RDA3;



