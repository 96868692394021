import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "index.css";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import App from "App";
// import { BrowserRouter as Router } from "react-router-dom";
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const instance = new PublicClientApplication(msalConfig);
/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */

 const root = ReactDOM.createRoot(document.getElementById("root"));
 root.render(
       //  <React.StrictMode>
          <MsalProvider instance={instance}>
            <App />
          </MsalProvider>
       //  </React.StrictMode>
        );


