import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Card } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import AccordionActions from "@mui/material/AccordionActions";
import Checkbox from "@mui/material/Checkbox";
import { getUsStatesList } from "Insurance/Services/FillingService";
import { useNavigate } from "react-router-dom";
import {
  getForms,
  get_Newfilling_Insu,
  postNewFilingGenAI,
} from "Insurance/Services/FillingService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CircularProgress, Backdrop } from "@mui/material";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000, // Adjusted width for more space
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 2,
  py: 2,
};
//code for insur new filling

function CreateFilling() {
  const navigate = useNavigate();

  const [stateOptions, setStateOptions] = useState([
    "Florida",
    "Texas",
    "New York",
  ]);
  const insuranceOptions = [
    "Homeowners",
    "General Liability",
    "Worker's Compensation",
    "Private Passenger Auto No-Fault",
    "Private Passenger Auto Liability",
    "Commercial Automobile Liability",
    "Private Passenger Auto Physical Damage",
    "Commercial Auto Physical Damage",
  ];
  const categoryOptions = ["Product Forms", "Financial Reporting Form"];
  const [open, setOpen] = useState(false);
  const [stateValue, setStateValue] = useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fillingName, setFillingName] = useState("");
  const [categoryVal, setCategoryVal] = useState("");
  const [formLabel, setFormLabel] = useState("");
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState("");
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [deadlineDateError, setDeadlineDateError] = useState("");
  const [FillingNameError, setFillingNameError] = useState("");
  const [insuranceTypeError, setInsuranceTypeError] = useState("");
  const [stateValueError, setStateValueError] = useState("");
  const [categoryValError, setCategoryValError] = useState("");
  const [selectedFormError, setSelectedFormError] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [SelectGenAI, setSelectGenAI] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCategoryVal("");
    setFormLabel("");
    setInsuranceType("");
    setFillingName("");
    setStartDate(null);
    setEndDate(null);
    setDeadlineDate(null);
    setStateValue("");
    setStartDateError("");
    setEndDateError("");
    setDeadlineDateError("")
  };

  useEffect(() => {
    triggerFormsChange();
  }, [stateValue, insuranceType, categoryVal]);

  const triggerFormsChange = () => {
    if (stateValue !== "" && insuranceType !== "" && categoryVal !== "") {
      const data = {
        insuranceType: "Insurance",
        type: categoryVal,
        state: stateValue,
      };
      getFormsData(data);
    }
  };

  useEffect(() => {
    getStateOptions();
  }, []);

  const getStateOptions = async () => {
    const res = await getUsStatesList("USA");
    setStateOptions(res.data.values);
   
  };

  const getFormsData = async (data) => {
    const formData = {
      filingCategory: data.insuranceType,
      formType: data.type,
      state: data.state,
    };

    const res = await getForms(formData);
    setForms(res.data.values);
   
    setFormLabel(categoryVal);
    handleChange("panel2");
  };

  const SelectedListItem = () => {
    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
    };

    const selectedCard = (index, item) => {
      setSelectedIndex(index);
      setSelectedForm(item);
      setSelectedFormError("");
    };

    return (
      <Grid container xs={12} sx={{ minWidth: "100%" }}>
        <Grid
          item
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            maxHeight: "30vh",
            overflowY: "auto",
          }}
        >
          <Grid sx={{}} aria-label="secondary mailbox folder">
            {forms.map((item, index) => {
              return (
                <>
                  <Card
                    className="rounded-0 mb-2  border"
                    elevation={0}
                    sx={{
                      px: 1,
                      py: 1,
                      borderColor: "grey!important",
                      boxShadow: "none!important",
                      "&:hover": {
                        backgroundColor: "#E9F6F4",
                        color: "#25A392",
                      },
                      cursor: "pointer",
                      color: selectedIndex === index ? "#25A392" : "",
                      backgroundColor: selectedIndex === index ? "#E9F6F4" : "",
                    }}
                    onClick={() => {
                      selectedCard(index, item);
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={!!selectedFormError}
                    helperText={selectedFormError}
                  >
                    <Grid container justifyContent="space-between">
                      <span>
                        <Typography
                          sx={{
                            fontSize: "0.7em",
                            fontWeight: selectedIndex === index ? 600 : "",
                            mt: selectedIndex === index ? 0.5 : null,
                          }}
                        >
                          {item}
                        </Typography>
                      </span>
                      {selectedIndex === index ? <DoneIcon /> : ""}
                    </Grid>
                  </Card>
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleStartDateChange = (event) => {
    setStartDate(event);
    setStartDateError("");
  };

  const handleEndDateChange = (event) => {
    setEndDate(event);
    setEndDateError("");
  };

  const handleFillingNameChange = (event) => {
    setFillingName(event.target.value);
    setFillingNameError("");
  };

  const handleDeadlineChange = (event) => {
    setDeadlineDate(event);
    setDeadlineDateError("");
  };

  const FormTypeAccordion = () => {
    return (
      <>
        <Grid mt={2}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{}}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Form Selection
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={6} sx={{ mt: 2 }}>
                    <FormControl fullWidth required>
                      <InputLabel id="dropdown2-label">
                        Insurance Type​
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="dropdown2-label"
                        value={insuranceType}
                        label="Insurance Type​"
                        onChange={(event) => {
                          setInsuranceType(event.target.value);
                          setInsuranceTypeError("");
                          triggerFormsChange();
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={!!insuranceTypeError}
                        helperText={insuranceTypeError}
                      >
                        {insuranceOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sx={{ mt: 2 }}>
                    <FormControl fullWidth required>
                      <InputLabel id="dropdown1-label">Select State</InputLabel>
                      <Select
                        size="small"
                        labelId="dropdown1-label"
                        value={stateValue}
                        label="Select State"
                        onChange={(event) => {
                          setStateValue(event.target.value);
                          setStateValueError("");
                          triggerFormsChange();
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={!!stateValueError}
                        helperText={stateValueError}
                      >
                        {stateOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={5.9} sx={{ mt: 2 }}>
                    <FormControl fullWidth required>
                      <InputLabel id="dropdown3-label">Category</InputLabel>
                      <Select
                        size="small"
                        labelId="dropdown3-label"
                        value={categoryVal}
                        label="Category"
                        onChange={(event) => {
                          setCategoryValError("");
                          setCategoryVal(event.target.value);
                          triggerFormsChange();
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={!!categoryValError}
                        helperText={categoryValError}
                      >
                        {categoryOptions.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5.9} sx={{ mt: 2 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={SelectGenAI} />}
                        label="Click here to auto-fill the form using GenAI"
                        onChange={() => {
                          setSelectGenAI(!SelectGenAI);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </React.Fragment>
            </AccordionDetails>
          </Accordion>
          {formLabel !== "" ? (
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {formLabel}{" "}
                </Typography>
                <Typography sx={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                  {selectedFormError !== "" ? selectedFormError : ""}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SelectedListItem />
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Grid>
      </>
    );
  };

  const Spinner = ({ open }) => {
    return (
      <Backdrop open={open} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const dateFormatedTO = (dateString) => {
    const date = new Date(dateString);
    
    if (isNaN(date)) {
        console.error("Invalid date");
        return null;
    }
   
    const formattedDate = dayjs(date);
  const year = formattedDate.year();
  const month = formattedDate.month() + 1; // Months are zero-based
  const day = formattedDate.date();
    return [year, month , day ];
  };

  const validateDates = () => {
    let isValid = true;
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = parseInt(
      String(currentDate.getMonth() + 1).padStart(2, "0"),
      10
    );
    const day = parseInt(String(currentDate.getDate()).padStart(2, "0"), 10);

    const [Uyear, Umonth, Uday] = dateFormatedTO(startDate);
    const [Eyear, Emonth, Eday] = dateFormatedTO(endDate);
    const [Dyear, Dmonth, Dday] = dateFormatedTO(deadlineDate);
       console.log( dateFormatedTO(startDate))
    if (!fillingName) {
      setFillingNameError("Enter the filling name");
    }

    if (!startDate) {
      setStartDateError("Start date is required");
      isValid = false;
    } else if (
      month === Umonth
        ? !(year <= Uyear && month <= Umonth && day <= Uday)
        : !(year <= Uyear && month <= Umonth)
    ) {
     
      setStartDateError("Choose today's date or date from future");
      isValid = false;
    }

    if (!endDate) {
      setEndDateError("End date is required");
      isValid = false;
    } else if (
      startDate &&
      endDate &&
      !(Umonth === Emonth
        ? Uyear <= Eyear && Umonth <= Emonth && Uday <= Eday
        : Uyear <= Eyear && Umonth <= Emonth)
    ) {
     
      setEndDateError("End date should fall after Start date");
      isValid = false;
    }

    if (!deadlineDate) {
      setDeadlineDateError("Deadline date is required");
      isValid = false;
    } else if (
      endDate &&
      deadlineDate &&
      !(Emonth === Dmonth
        ? Dyear >= Eyear && Dmonth >= Emonth && Dday >= Eday
        : Dyear >= Eyear && Dmonth >= Emonth)
    ) {
      setDeadlineDateError("Deadline date should fall after End date");
      isValid = false;
    }
    if (!insuranceType) {
      setInsuranceTypeError("Select the Insurance type");
      isValid = false;
    }

    if (selectedForm === "") {
      setSelectedFormError("Select the From type");
      isValid = false;
    }
    if (!categoryVal) {
      setCategoryValError("Select the category value");
      isValid = false;
    }

    if (!stateValue) {
      setStateValueError("Select the state value");
      isValid = false;
    }

    return isValid;
  };

  const handleCreate = async () => {
    try {
    
      if (validateDates()) {
        //Gather all data and perform further actions
        const domain = localStorage.getItem("UserSelectedDomain");
        get_Newfilling_func();

        // // Perform further actions here, such as sending data to an API
      }
    } catch (error) {
      console.error(error);
    }
  };

  const get_Newfilling_func = async () => {
    setOpen(false);
    setLoading(true);
    (async () => {
      try {
        const DisplayDisclousre = (data, disclosure, startdate) => {
          if (data.status === 200) {
            setLoading(false);
            navigate("/ViewReport", {
              state: {
                disclosure: disclosure,
                startdate: startdate,
              },
            });
          }
        };

        if (2 === 2) {
          let new_from_date = new Date(startDate);
          let new_to_date = new Date(endDate);
          let new_deadline_date = new Date(deadlineDate);
          var dd_from = new_from_date.getDate();
          var dd_to = new_to_date.getDate();
          var dd_deadline = new_deadline_date.getDate();
          var mm_from = new_from_date.getMonth() + 1;
          var mm_to = new_to_date.getMonth() + 1;
          var mm_deadline = new_deadline_date.getMonth() + 1;
          var yyyy_from = new_from_date.getFullYear();
          var yyyy_to = new_to_date.getFullYear();
          var yyyy_deadline = new_deadline_date.getFullYear();
          let actual_from_date = dd_from + "/" + mm_from + "/" + yyyy_from;
          let actual_to_date = dd_to + "/" + mm_to + "/" + yyyy_to;
          let actual_deadline_date =
            dd_deadline + "/" + mm_deadline + "/" + yyyy_deadline;
          const domain = localStorage.getItem("UserSelectedDomain");
          let data;
          if (SelectGenAI) {
            data = await postNewFilingGenAI(
              fillingName,
              selectedForm,
              domain,
              actual_from_date,
              actual_to_date,
              actual_deadline_date
            );
          } else {
            data = await get_Newfilling_Insu(
              fillingName,
              selectedForm,
              domain,
              actual_from_date,
              actual_to_date,
              actual_deadline_date
            );
          }
          const DisclosureNameforTree = data.data.disclosureType;
          const startdatefortree = data.data.periodStart;
          
          DisplayDisclousre(data, DisclosureNameforTree, startdatefortree);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  };

  return (
    <Grid>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#25A392",
          "&:hover": {
            backgroundColor: "#25A392",
          },
        }}
        color="primary"
        onClick={handleOpen}
      >
        Create New Form
      </Button>
      <Spinner open={loading} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid></Grid>
          <Grid container mt={0}>
            <Grid item sx={{ mt: 0 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Insurance Form
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleClose}
                sx={{
                  mt: 0.5,
                  position: "absolute",
                  right: 30,
                  top: 16,
                  color: "white",
                  backgroundColor: "#212529",
                  "&:hover": {
                    backgroundColor: "#212529", // Same color on hover
                  },
                  width: "20px",
                  height: "20px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
              <TextField
              sx={{ width: '250px' }}
                required
                id="filling-name"
                label="Filling Name"
                type="text"
                InputLabelProps={{ shrink: true }}
                value={fillingName}
                onChange={handleFillingNameChange}
                error={!!FillingNameError}
                helperText={FillingNameError}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mt: 1}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newvalue) => handleStartDateChange(newvalue)}
                  inputFormat="MM/DD/YYYY"
                  onError={(newError) => setStartDateError(newError)}
                  slotProps={{
                    textField: {
                      error: !!startDateError,
                      helperText: startDateError,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newvalue) => handleEndDateChange(newvalue)}
                  inputFormat="MM/DD/YYYY"
                  onError={(newError) => setEndDateError(newError)}
                  slotProps={{
                    textField: {
                      error: !!endDateError,
                      helperText: endDateError,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Deadline Date"
                  value={deadlineDate}
                  onChange={(newvalue) => handleDeadlineChange(newvalue)}
                  inputFormat="MM/DD/YYYY"
                  onError={(newError) => setDeadlineDateError(newError)}
                  slotProps={{
                    textField: {
                      error: !!deadlineDateError,
                      helperText: deadlineDateError,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormTypeAccordion />
            </Grid>
          </Grid>

          <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 1 }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#25A392",
                  "&:hover": {
                    backgroundColor: "#25A392",
                  },
                }}
                onClick={handleCreate}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default CreateFilling;
