import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Chip from "@mui/material/Chip";
import Paper from '@mui/material/Paper';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import { Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
// importing data form database
import {
  GetCountryList,
  PostaddCompany,
  getAllCompany,
  deleteCompany,
  editCompany,
  getInterestedProductDropDownList,
} from "../../ESG/services/GraphFetch";
import AddUser from "./Adduser";
import { Height, Opacity } from "@mui/icons-material";
import ListItemIcon from '@mui/material/ListItemIcon';
import BusinessIcon from '@mui/icons-material/Business';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';

const override = css.bind`
  display: block;
  margin: 0 auto;
  border-color: red;
`;



function ManageCompany() {
  const [IsLoaded, setisLoaded] = useState(false);
  const [countryList, setcountryList] = useState([]);
  const [Questions, setQuestion] = useState("what product are of interest?");
  const [interestedInValue, setinterestedInValue] = useState();
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  const [tabOpened, setTabOpened] = useState(true)

  const navigate = useNavigate();

    const [Isloaded, setIsloade] = useState(false);
    const [CompanyData, setCompanyData] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [editedCompanyData, setEditedCompanyData] = useState({});
    useEffect(() => {
      fetchCompanyData();
    }, []);


    const fetchCompanyData = async () => {
      try {
        let data = await getAllCompany();
        // console.log(data.data.data);
        setCompanyData(data.data.data);
        console.log("other Company Data", data.data.data)
        setIsloade(true);
      } catch (error) { }
    };
    // Function to open the edit popup for a specific company
    const openEditPopup = (company) => {
      setSelectedCompany(company);
      setIsEditPopupOpen(true);
      setEditedCompanyData({
        // ...other properties
        interestedIn: JSON.parse(company?.properties?.interestedIn[0]?.value),
        isEnabled: company?.properties?.isEnabled[0]?.value,
      });
    };

    // Function to close the edit popup
    const closeEditPopup = () => {
      setSelectedCompany(null);
      setIsEditPopupOpen(false);
    };


    const handleSaveButtonClick = async () => {
      // Create an object to store all the edited data
      const editedData = {
        companyId: selectedCompany.id, // Include the company id for identification
        companyName:
          editedCompanyData.name || selectedCompany.properties.name[0].value,
        country: editedCompanyData.country || [
          selectedCompany.properties.country[0].value,
        ],
        startDate:
          editedCompanyData.startDate ||
          selectedCompany.properties.startDate[0].value,
        domain:
          editedCompanyData.domain ||
          selectedCompany.properties.domain[0].value,
        isEnabled:
          editedCompanyData.isEnabled !== undefined
            ? editedCompanyData.isEnabled
            : selectedCompany.properties.isEnabled[0].value,
        interestedIn:
          editedCompanyData.interestedIn ||
          selectedCompany.properties.interestedIn[0].value,
      };
      //console.log(editedData);
      // You can now send `editedData` to your server for updating the company information
      // Example: sendEditedDataToServer(editedData);
      const suss = await editCompany(editedData);
      if (suss.status === 200) {
        fetchCompanyData();
      }
      // Close the edit popup
      closeEditPopup();
    };

    const handleInputValueChange = (event, property) => {
      let newValue;

      // console.log(event.target.value, property);

      if (event.target.type === "checkbox") {
        newValue = event.target.checked;
      } else {
        newValue = event.target.value;
      }

      setEditedCompanyData((prevData) => ({
        ...prevData,
        [property]: newValue,
      }));
    };

    return (
      <React.Fragment>
            <ArrowBackIcon style={{marginTop:"0.23em", marginRight:"0.2em",cursor:"pointer"}} onClick={() => {navigate("/AdminPanel");}} />

        {Isloaded ? (
          <React.Fragment>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Company id</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>startDate</TableCell>
                    <TableCell>Domain Name</TableCell>
                    <TableCell>isEnabled</TableCell>
                    <TableCell>interestedIn</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {CompanyData?.map((res, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{res.id ? res.id : ""}</TableCell>
                        <TableCell>{res.properties.name ? res.properties.name[0].value : ''}</TableCell>
                        <TableCell>{res.properties.country ? res.properties.country[0].value : ''}</TableCell>
                        <TableCell>{res.properties.startDate ? res.properties.startDate[0].value : ''}</TableCell>
                        <TableCell>{res.properties.domain ? res.properties.domain[0].value : ''}</TableCell>

                        <TableCell>
                          <Checkbox
                            label="Is Enabled"
                            checked={res.properties.isEnabled && res.properties.isEnabled[0]?.value}
                          />
                        </TableCell>
                        <TableCell>
                          {res.properties.interestedIn ? JSON.parse(res.properties.interestedIn[0].value)?.map((d, index) => (
                            <span key={index}>
                              {d}
                              {res.properties.interestedIn[0].value.length > 1 &&
                                index < res.properties.interestedIn[0].value.length - 1 &&
                                ", "}
                            </span>
                          )) : ''}
                        </TableCell>

                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => openEditPopup(res)}
                          >
                            Update
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          <Box p={2}>
            <div className="loading-container" >
              <h6>Loading....</h6>
              <PropagateLoader
                css={override}
                size={15}
                color={"#3498db"}
                loading={true}
              />
            </div>
          </Box>
        )}

        {/* Edit Company Popup */}
         <Dialog
          open={isEditPopupOpen}
          onClose={closeEditPopup}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle className="title">Edit Company</DialogTitle>
          <DialogContent>
            {selectedCompany && (
              <div>
                {/* Add input fields here for editing company data */}
                <Stack direction="row" my={2} spacing={2} justifyContent="space-between">
                  <TextField
                    label="Company Name"
                    value={
                      editedCompanyData.name ||
                      selectedCompany.properties.name[0].value
                    }
                    sx={{ m: 2, minWidth: 245 }}
                    size="small"
                    onChange={(event) => handleInputValueChange(event, "name")}

                  />

                  <FormControl
                    sx={{ m: 2, minWidth: 245 }}>
                    <InputLabel id="dropdown-label">country</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      value={
                        editedCompanyData.country ||
                        selectedCompany.properties.country[0].value
                      }
                      size="small"
                      onChange={(event) =>
                        handleInputValueChange(event, "country")
                      }
                    >
                      {countryList?.map((res, index) => (
                        <MenuItem key={res.CountryCode} value={res.CountryCode}>
                          {res.CountryCode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction="row" my={2} spacing={2} justifyContent="space-between">
                  <TextField
                    type="date"
                    label="startDate"
                    value={
                      editedCompanyData.startDate ||
                      selectedCompany.properties.startDate[0].value
                    }
                    sx={{ m: 2, minWidth: 245 }}
                    size="small"
                    onChange={(event) =>
                      handleInputValueChange(event, "startDate")
                    }

                  />

                  <TextField
                    label="domain"
                    value={
                      editedCompanyData.domain ||
                      selectedCompany.properties.domain[0].value
                    }
                    sx={{ m: 2, minWidth: 245 }}
                    size="small"
                    onChange={(event) => handleInputValueChange(event, "domain")}

                  />
                </Stack>

                <Stack direction="row" spacing={2} mt={2} mb={1} justifyContent="space-between">
                  <FormControl sx={{ m: 2, minWidth: 550 }}>
                    <Typography className="prod-interestQues" mb={1} sx = {{fontSize:"0.8em"}} id="interestedIn-label">
                      What Product Are Of Interest?
                    </Typography>
                    <Grid>
                      {(editedCompanyData.interestedIn || [])?.map((value) => (
                        <Chip className="me-2"
                          key={value}
                          label={value}
                          onDelete={() => handleInputValueChange(value, "interestedIn")}
                        />
                      ))}
                    </Grid>
                    {interestedInValue?.map((res) => (
                      <Chip
                        key={res}
                        label={res}
                        onClick={() => handleInputValueChange(res, "interestedIn")}
                        color={
                          (editedCompanyData.interestedIn || []).includes(res)
                            ? 'primary'
                            : 'default'
                        }
                        variant="outlined"
                        clickable
                      />
                    ))}
                  </FormControl>;
                </Stack>

                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <Typography >Enabled</Typography>

                  <Checkbox
                    label="Is Enabled"
                    checked={
                      editedCompanyData.isEnabled !== undefined
                        ? editedCompanyData.isEnabled
                        : selectedCompany.properties.isEnabled[0].value
                    }
                    onChange={(event) =>
                      handleInputValueChange(event, "isEnabled")
                    }
                  />
                </Grid>
              </div>
            )}
          </DialogContent>


          <Stack direction="row" justifyContent="center" spacing={2}>
            <DialogActions>
              <Button className="btn-primary" variant="contained" onClick={handleSaveButtonClick}>
                Save
              </Button>
              <Button className="btn-cancel" onClick={closeEditPopup}>Cancel</Button>
            </DialogActions>
          </Stack>

        </Dialog>
      </React.Fragment>
    );
  }

export default ManageCompany