import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding:'0px 20px 0px 20px',
  },
  '& .MuiDialogActions-root': {
    padding:"10px 20px 15px 20px"

  },
  '& .MuiDialog-paper':{
  },
  '& .MuiDialogTitle-root':{
    padding:'20px 0px 20px 20px',
    fontWeight:600,
    fontSize:'18px',
    color:'#061826',
  },
  
  
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 30,
            top: 16,
            color: "white",
            backgroundColor: "black",
            width: "20px",
            height: "20px",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Modal({open,setOpen,title,actions,children,maxWidth,setSelectedNote,setNewNote,sectionname}) {
  
  const handleClose = () => {
    if(sectionname==="Goal")
    {
      setOpen(false);
    }else{
    setSelectedNote(null);
              setNewNote({
                content:'',
                blobPath:''
              });
    setOpen(false);
            }
  };

  return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={maxWidth}
      >

        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent >
          {children}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </BootstrapDialog>
  );
}