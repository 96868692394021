import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const OccupationalHealth = (props)  =>{
    const [minimization,setminimization] = useState()
    const [unfavorable,setunfavorable] = useState()
    const[participation,setparticipation]=useState()
    const[relevant,setrelevant]=useState()
   
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
        setminimization((Object.values(props.data.reportData["GRI 403.3 Occupational health services health and safety"])[0]).answer)
         setunfavorable((Object.values(props.data.reportData["GRI 403.3 Occupational health services health and safety"])[1]).answer)
         setparticipation((Object.values(props.data.reportData["GRI 403.4 Worker participation, consultation, and communication on occupational health and safety"])[0]).answer)
         setrelevant((Object.values(props.data.reportData["GRI 403.4 Worker participation, consultation, and communication on occupational health and safety"])[1]).answer)
        
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5 className="underline"> Occupational health services health and safety</h5>
                        <h6><b>How the organization maintains the confidentiality of workers health-related information for occupational health services functions that contribute to the identification
                             and elimination of hazards and minimization of risks?</b></h6>
                        <div dangerouslySetInnerHTML={{__html:minimization}}></div>
                        <h6><b>How the organization ensures that workers participation in any occupational health services is not used for any favorable or unfavorable treatment of workers?</b></h6>
                        <div dangerouslySetInnerHTML={{__html:unfavorable}}></div>
                        
                </Grid>
                <Grid item xs={12} md={5}  className="grid2">
                        <h5 className="underline">Worker participation, consultation, and communication on occupational health and safety</h5>
                        <h6><b>Describe the processes for worker participation and consultation in the development, implementation, and evaluation 
                            of the occupational health and safety management system.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:participation}}></div>
                        <h6><b>Describe providing access to and communicating relevant information on occupational health and safety to workers.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:relevant}}></div>
                       
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default OccupationalHealth;