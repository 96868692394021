import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";

function ThirdPage(props) {
  const [Questions, SetQuestions] = useState();
  const [Isloading, setIsloading] = useState(false);
  //const [Question1,setQuestion1]=useState();
  const [Answer, setAnswer] = useState();
  const [QuestionNum , setQuestionNumber] = useState();




  useEffect(() => {

  const FetchData = () => {
    const questions_array_b = [];
  const Answer_array_b = [];
  const QuestionNumber =[]
    //getting section 1b question in single array
   
    for (let i = 0; i < (Object.keys(props.data.reportData["Participation or Interest in Client Transactions"]).length); i++) {
      questions_array_b.push((Object.keys(props.data.reportData["Participation or Interest in Client Transactions"])[i]));
      Answer_array_b.push((Object.values(props.data.reportData["Participation or Interest in Client Transactions"])[i]).answer);
      QuestionNumber.push(((Object.values(props.data.reportData["Participation or Interest in Client Transactions"])[i]).qno).substr(4,((Object.values(props.data.reportData["Participation or Interest in Client Transactions"])[i]).qno).length));
    }
  // console.log(Answer_array_b);
    SetQuestions(questions_array_b);
    setAnswer(Answer_array_b);
   // console.log(Answer_array_b);
    setQuestionNumber(QuestionNumber);


  }

 
    FetchData();
    setIsloading(true);
  }, [props])


  return (

    <div>
      {Isloading &&
      
       <Grid container spacing={1} >
            <Grid container>
             <Box  sx={{
            
            backgroundColor: 'lightgray', width:"15%"
            
          }}
           >
          <Grid item xs={12} md={3} sx={{ bgcolor: "lightgray",
  position: "sticky",top: "0",padding: "2px",height:"100"
 }}>
          <ul style={{ listStyleType: "square", textAlign: "left", fontSize:"20px"}}>
                    <li>Participation in Client Transactions</li>
                    </ul>
          </Grid>
          
          </Box>
          
          <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}  >
         
          <Box style={{
          
          fontSize : '1rem',
        }}>
            <div>

              {Questions.map((x, index) => (


               
                    <div key={index}><b>{QuestionNum[index] }</b>{'\u00A0'}{'\u00A0'}{x}
                      <br />
                      {Answer[index]}
                    </div>
                 



              ))}


            </div>
            </Box>
            </Grid>
            
          </Grid>
          </Grid>

         
      }
    </div>
  )



}

export default ThirdPage