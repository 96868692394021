import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const HazardIdentification = (props)  =>{
    const [reprisals,setreprisals] = useState()
    const [workers,setworkers] = useState()
    const[investigate,setinvestigate]=useState()
    const[routine,setroutine]=useState()
    const[eliminate,seteliminate]=useState()
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
        setreprisals((Object.values(props.data.reportData["GRI 403.2 Hazard identification, risk assessment, and incident investigation"])[0]).answer)
         setworkers((Object.values(props.data.reportData["GRI 403.2 Hazard identification, risk assessment, and incident investigation"])[1]).answer)
         setinvestigate((Object.values(props.data.reportData["GRI 403.2 Hazard identification, risk assessment, and incident investigation"])[2]).answer)
         setroutine((Object.values(props.data.reportData["GRI 403.2 Hazard identification, risk assessment, and incident investigation"])[3]).answer)
         seteliminate((Object.values(props.data.reportData["GRI 403.2 Hazard identification, risk assessment, and incident investigation"])[4]).answer)
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5 className="underline">Hazard identification, risk assessment, and incident investigations</h5>
                        <h6><b>Describe the processes for workers to report work-related hazards and hazardous situations, 
                            and an explanation of How workers are protected against reprisals.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:reprisals}}></div>
                        <h6><b>Describe the policies and processes for workers to remove themselves from work situations that they believe could cause injury or ill health.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:workers}}></div>
                        
                </Grid>
                <Grid item xs={12} md={5}  className="grid2">
                        
                        <h6><b>Describe the processes used to investigate work-related incidents, including the processes to identify hazards and assess risks relating to the incidents</b></h6>
                        <div dangerouslySetInnerHTML={{__html:investigate}}></div>
                        <h6><b>Describe the processes used to identify work-related hazards and assess risks based on routine and non-routine</b></h6>
                        <div dangerouslySetInnerHTML={{__html:routine}}></div>
                        <h6><b>Describe the processes used to identify work-related hazards and assess risks based on routine and non-routine</b></h6>
                        <div dangerouslySetInnerHTML={{__html:eliminate}}></div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default HazardIdentification;

// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";

// const HazardIdentification = (props) => {
//   const [dataLoaded, setDataLoaded] = useState(false);
//   const [reportData, setReportData] = useState(null);

//   useEffect(() => {
//     if (props.data && props.data.reportData) {
//       const hazardIdentificationData =
//         props.data.reportData["GRI 403.2 Hazard identification, risk assessment, and incident investigation"];

//       setReportData(hazardIdentificationData);
//       setDataLoaded(true);
//     }
//   }, [props.data]);

//   const renderReportContent = () => {
//     const { questions, answers } = reportData;

//     return (
//       <>
//         <Box className="EmpBox">
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
//               <h4>Apple.Inc</h4>
//               <ul style={{ listStyleType: "square", textAlign: "left" }}>
//                 <li>Introduction</li>
//                 <li>About the report</li>
//                 <li>Policies</li>
//                 <li>Employees details</li>
//                 <li>Performance and career development</li>
//                 <li>Sectors</li>
//               </ul>
//             </Grid>
//             <Grid item xs={12} md={5} className="grid1">
//               <h5 className="underline">
//                 Hazard identification, risk assessment, and incident investigations
//               </h5>
//               {questions.map((question, index) => (
//                 <div key={index}>
//                   <h6>
//                     <b>{question}</b>
//                   </h6>
//                   <div dangerouslySetInnerHTML={{ __html: answers[index] || "" }} />
//                 </div>
//               ))}
//             </Grid>
//           </Grid>
//         </Box>
//       </>
//     );
//   };

//   return <div>{dataLoaded ? renderReportContent() : <div className="loaders">Loading...</div>}</div>;
// };

// export default HazardIdentification;





