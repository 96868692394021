import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function SixthPage(props) {
  const [Questionset1, SetQuestionset1] = useState();
  const [Questionset2, SetQuestionset2] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [QuestionNum2, setQuestionNumber2] = useState();
  //const [Question1,setQuestion1]=useState();
  const [Answerset1, setAnswerset1] = useState();
  const [Answerset2, setAnswerset2] = useState();
  const [QuestionNum1, setQuestionNumber1] = useState();

  useEffect(() => {
    const FetchData = () => {
      //getting section 1b question in single array
      const questions_array_a = [];
      const Answer_array_a = [];
      const QuestionNumber1 = [];

      for (
        let i = 0;
        i <
        Object.keys(props.data.reportData["Other Business Activities"]).length;
        i++
      ) {
        questions_array_a.push(
          Object.keys(props.data.reportData["Other Business Activities"])[i]
        );
        Answer_array_a.push(
          Object.values(props.data.reportData["Other Business Activities"])[i]
            .answer
        );
        QuestionNumber1.push(
          Object.values(props.data.reportData["Other Business Activities"])[
            i
          ].qno.substr(
            4,
            Object.values(props.data.reportData["Other Business Activities"])[i]
              .qno.length
          )
        );
      }
      // console.log(questions_array_b);
      SetQuestionset1(questions_array_a);
      setAnswerset1(Answer_array_a);
     // console.log(Answer_array_a);
      setQuestionNumber1(QuestionNumber1);
    };
    const FetchData1 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber2 = [];

      for (
        let i = 0;
        i <
        Object.keys(
          props.data.reportData[
            "Financial Industry Affiliations and Private Fund Reporting"
          ]
        ).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(
            props.data.reportData[
              "Financial Industry Affiliations and Private Fund Reporting"
            ]
          )[i]
        );
        Answer_array_b.push(
          Object.values(
            props.data.reportData[
              "Financial Industry Affiliations and Private Fund Reporting"
            ]
          )[i].answer
        );
        QuestionNumber2.push(
          Object.values(
            props.data.reportData[
              "Financial Industry Affiliations and Private Fund Reporting"
            ]
          )[i].qno.substr(
            4,
            Object.values(
              props.data.reportData[
                "Financial Industry Affiliations and Private Fund Reporting"
              ]
            )[i].qno.length
          )
        );
      }
      // console.log(questions_array_b);
      SetQuestionset2(questions_array_b);
      setAnswerset2(Answer_array_b);
     // console.log(Answer_array_b);
      setQuestionNumber2(QuestionNumber2);
    };

    FetchData();
    FetchData1();

    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <ul
                style={{
                  listStyleType: "square",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                <li>Other Business Activities</li>
                <li>Financial Industry Affiliations</li>
                <li>Private Fund Reporting</li>
              </ul>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
             
                  {Questionset1.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                      
                          <b>{QuestionNum1[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {index === 0 ?(<React.Fragment>
                            {Answerset1[index].map((itimes,index1)=>{
                              return <li key={index1}>{itimes}</li> ;
                            })}
                          </React.Fragment>):
                          ( Answerset1[index])}
                        <br />
                      </React.Fragment>
                    );
                  })}
               
                  {Questionset2.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum2[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {index === 0 ?(Answerset2[index].map((itimes,index)=>{
                            return <li key={index}>{itimes}</li>
                          })):(
                          Answerset2[index])}
                       
                        <br />
                      </React.Fragment>
                    );
                  })}
              
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default SixthPage;
