import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import {
  Grid,
  LinearProgress,
  Typography,
  Button,
  Box,
  Link,
  Breadcrumbs,
  IconButton,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/system";
import "../styles/ViewReport/ViewReport.css";
import Tree from "../../components/Tree.js";
import Form_Text_input from "ESG/forms/Form_Text_input.js";
import Form_Autocomplete from "ESG/forms/Form_Autocomplete.js";
import CustomInlineToolbar from "ESG/forms/Form_RTE.js";
import Form_Date_input from "ESG/forms/Form_Date_input.js";
import Form_PeriodicDate from "ESG/forms/Form_PeriodicDate.js";
import CheckBoxs from "ESG/forms/Form_CheckBox.js";
import Currency from "ESG/forms/Form_Currency.js";
import Number from "ESG/forms/Form_Number.js";
import MultiSelected from "ESG/forms/Form_MultiSelecter.js";
import Form_Table from "ESG/forms/Form_Table.js";
import Form_Radio_Group from "ESG/forms/Form_Radio_Group.js";
import Form_Address from "ESG/forms/Form_Address.js";
import Comments from "ESG/forms/Form_CommentBox.js";
import NoteContext from "context/PageContext.js";
import Form_ContractInfo from "ESG/forms/Form_ContractInfo.js";
import Editable_List from "ESG/forms/Form_EditableList.js";
import Form_NumberandDropdown from "ESG/forms/Form_NumberandDropdown.js";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import RejectReason from "../../Utilities/FormCreation/RejectReason";
import Paper from "@mui/material/Paper";
import { CircularProgress, Backdrop } from "@mui/material";
import {
  getBaseTree,
  getHelperText,
  UpdateHandler,
  treeResponse,
  getDropdownValues,
  getMultiSelectorValues,
  getUnits,
  GetDependentCount,
  getTelephoneNumber,
  GetCurrancy,
  putApprove,
} from "ESG/services/Fetch.js";
import Percentage from "../forms/Form_Percentage.js";
import Form_Clock from "ESG/forms/Form_Clock.js";
import Form_SubForm from "ESG/forms/Form_SubForm.js";
import Form_Url from "ESG/forms/Form_Url.js";
import Form_Email from "ESG/forms/Form_Email.js";
import PaperComponent from "components/PaperComponent.js";
import DescriptionIcon from "@mui/icons-material/Description";
import AIbox from "./AIbox.js";
import No_Selection from "../../images/No_Selection.svg";
import NoteImage from "../../images/NoteImage.svg";
import Form_AdditionalNote from "ESG/forms/Form_AdditionalNote";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function findPath1(obj, target) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === target) {
        return [key]; // found the target, return its name
      } else if (typeof obj[key] === "object") {
        var result = findPath1(obj[key], target); // recursive call
        if (result) {
          return [key].concat(result); // prepend current key to result
        }
      }
    }
  }
  return null; // target not found
}
const ViewReport = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setPathname, Answer, setAnswer } = useContext(NoteContext);
  const [helperText, sethelperText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const UserInfo = JSON.parse(localStorage.getItem("Name"));
  const [state, setState] = useState(); // To Set the Disclosure form Back end
  const [form, setform] = useState("");
  const [selected, setSelected] = useState();
  const [DisclosurePath, setDisclosurePath] = useState();
  const [Question, setQuestion] = useState();
  // const [DependentCount, setDependentCount] = useState();
  const [QuestionID, setQuestionID] = useState();
  const [DisplayMessage, setDispalyMessage] = useState({
    DisplayName: "",
    displaypresent: false,
  });
  const [FieldType_Data, setFieldType_Data] = useState("");
  const [ExpandedNodes, setExpandedNodes] = useState([]);
  const [loading, setLoading] = useState(true); // To Set and Unset Loading when data getting backend
  const [DropdownValues, setDropdownValues] = useState(null);
  const [Comment, setComment] = useState({
    InitialComment: "",
    UpdatedComment: "",
  });
  // Retrieve the account details from localStorage
  const accountDetails = JSON.parse(localStorage.getItem("AccountDetails"));

  // Check if accountDetails and filingGroups exist and are valid
  //console.log(localStorage.getItem("selectedUserRoleContext"),"============")

  // Set the state with the display name
  const [filingGroups, setfilingGroups] = useState(
    localStorage.getItem("selectedUserRoleContext")
  );
  const [ResponseUpdated, setResponseUpdated] = useState(false);
  const [AdditionalNote, setAdditionalNote] = useState({
    InitialNote: [],
    UpdatedNote: [],
  });
  const location = useLocation();
  
  let disclosure_name_from_user = location.state.disclosure;
  const startDate_of_disclosure = location.state.startdate;
  let questionId = selected;
  const cik = "0000320193";
  let disclosure, section;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [isTreeVisible, setIsTreeVisible] = useState(true);
  const [Sections, setSections] = useState({
    Section: "",
    Subsection: "",
  });
  const [TreeData, setTreeData] = useState();
  let path = [];
  let Form;
  let question;
  let display_message;
  const arrdata = state && Object.entries(state);
  const data = state && arrdata[0][1].value;
  const PathforRedirect = localStorage.getItem("pathSelected");
  const [RefershAnswer, setRefershAnswer] = useState(true);
  const [appovelQuest, setappovelQuest] = useState();
  const [DelteOpen, setDelteOpen] = useState(false);
  const [ReasonForReject, SetReasonForReject] = useState("");
  const [approveOrRejectComment, setapproveOrRejectComment] = useState();
  const [approvedOrRejectedBy, setapprovedOrRejectedBy] = useState();
  const [openLoader, setopenLoader] = useState(false);
  
  function findPath(obj, target) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === target) {
          return [obj[key].key.properties.name[0].value]; // found the target, return its name
        } else if (typeof obj[key] === "object") {
          var result = findPath(obj[key], target); // recursive call
          if (result) {
            return key !== "value"
              ? [obj[key].key.properties.name[0].value].concat(result)
              : [key].concat(result); // prepend current key to result
          }
        }
      }
    }
    return null; // target not found
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNodeToggle = (node) => {
    const path = findPath1(data, node);
    setExpandedNodes((prevExpandedNodes) => {
      if (prevExpandedNodes.includes(node)) {
        return prevExpandedNodes.filter((i) => i !== node);
      } else {
        return path.filter((value, index) => !(index % 2));
      }
    });
  };

  const memoizedKeys = useMemo(() => {
    let arr = [];
    const getkeys = (data) => {
      if (data && typeof data === "object") {
        Object.entries(data).forEach((item) => {
          arr.push({
            id: item[1].key.id,
            hasQuestion: item[1].key.properties.hasOwnProperty("Question"),
          });
          if (Object.keys(item[1].value).length !== 0) {
            getkeys(item[1].value);
          }
        });
      }
    };
    getkeys(data);
    // console.log("memoized", data);
    return arr;
  }, [data]);

  useEffect(() => {
    
    (async () => {
      await getBaseTree(disclosure_name_from_user).then((data) => {
        setState(data && data.basetree[0]);
        setLoading(false);
      });
    })();
    setPathname(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 600 && setIsMobile(true);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const latestValuesRef = useRef({
    ResponseUpdated: false,
    UpdatedAnswer: null,
    section: null,
    disclosure: null,
    UpdatedRTE: null,
    Question: null,
    questionId: null,
  });

  useEffect(() => {
    // Update the ref whenever the values change
    latestValuesRef.current = {
      ResponseUpdated,
      UpdatedAnswer: Answer.UpdatedAnswer,
      section: Sections.Subsection,
      disclosure: Sections.Section,
      Question,
      questionId: selected,
    };
  }, [ResponseUpdated, Answer.UpdatedAnswer, Sections, Question, selected]);

  useEffect(() => {
    // Cleanup function
    return () => {
      // Access the latest values from the ref
      if (latestValuesRef.current.ResponseUpdated) {
        // console.log("update res 2");
        UpdateHandler(
          latestValuesRef.current.UpdatedAnswer,
          latestValuesRef.current.Question,
          latestValuesRef.current.disclosure,
          latestValuesRef.current.section,
          latestValuesRef.current.questionId,
          startDate_of_disclosure
        );
        setResponseUpdated(false);
      }
    };
  }, []); // Empty dependency array to run only on unmount

  const handleSelect = (nodeIds) => {
    setSelected(nodeIds);
  };

  const Toggle = (type) => {
    if (type === "prev" && selected === memoizedKeys[0].id) {
      return;
    }
    if (
      type === "next" &&
      selected === memoizedKeys[memoizedKeys.length - 1].id
    ) {
      return;
    }
    if (debounceTimer) {
      // console.log("timer found");
      // If debounceTimer is active, return early to prevent repeated calls
      return;
    }
    const newDebounceTimer = setTimeout(() => {
      setDebounceTimer(null); // Reset debounceTimer after 1 second
    }, 1000);

    setDebounceTimer(newDebounceTimer);
    for (let i = 0; i < memoizedKeys.length; i++) {
      if (memoizedKeys[i].id === selected) {
        if (type === "prev" && i !== 0) {
          onNodeToggle(memoizedKeys[i - 1].id);
          setSelected(memoizedKeys[i - 1].id);
          selectHandler(
            memoizedKeys[i - 1].id,
            memoizedKeys[i - 1].hasQuestion
          );
          return;
        } else if (
          type === "next" &&
          selected !== memoizedKeys[memoizedKeys.length - 1].id
        ) {
          onNodeToggle(memoizedKeys[i + 1].id);
          setSelected(memoizedKeys[i + 1].id);
          selectHandler(
            memoizedKeys[i + 1].id,
            memoizedKeys[i + 1].hasQuestion
          );
          return;
        }
      }
    }

    clearTimeout(newDebounceTimer);
  };

  const handleChange = (updatedAnswer,genai) => {
    if(genai)
    {
      UpdateHandler(
        updatedAnswer,
        latestValuesRef.current.Question,
        latestValuesRef.current.disclosure,
        latestValuesRef.current.section,
        latestValuesRef.current.questionId,
        startDate_of_disclosure
      );
    }
    else{
    setAnswer((prevstate) => {
      if (
        prevstate.InitialAnswer.trim() !==
        (typeof updatedAnswer === "object"
          ? JSON.stringify(updatedAnswer)
          : updatedAnswer
        ).trim()
      ) {
        if (
          !(
            prevstate.InitialAnswer.trim() === "" && updatedAnswer?.length === 0
          )
        ) {
          setResponseUpdated(true);
          // console.log(
          //   prevstate.InitialAnswer.trim(),
          //   updatedAnswer
          // );
        }
      } else {
        setResponseUpdated(false);
        //console.log("res false");
      }
      return {
        ...prevstate,
        UpdatedAnswer: updatedAnswer,
      };
    });
  }
  };

  const SetTreeVisibility = () => {
    setIsTreeVisible(!isTreeVisible);
  };

  const selectHandler = async (key, hasQuestion) => {
  
    setapproveOrRejectComment(undefined);
    // console.log("selectHandler", key, hasQuestion);
    const Envariable = false;
    setQuestionID(key);

    function getParentAndGrandParent(path, json, value) {
      for (let key in json) {
        if (typeof json[key] === "object") {
          path.push(key.toString());
          getParentAndGrandParent(path, json[key], value);
          path.pop();
        } else {
          if (json[key] === value) {
            disclosure = path[0];
            section = path[2];
            setSections({
              Section: disclosure,
              Subsection: section,
            });
          }
        }
      }
      return path;
    }
    getParentAndGrandParent(
      path,
      state.Disclosure ? state.Disclosure.value : state,
      key
    );
    const arrdata = Object.entries(state);
    const obj = state && arrdata[0][1].value;
    const selectedpath = findPath(obj, key);
    setDisclosurePath(selectedpath.filter((value, index) => !(index % 2)));
    setform("");

    if (ResponseUpdated) {
      if (FieldType_Data === "Radio Button with Dependency") {
        await UpdateHandler(
          Answer.UpdatedAnswer,
          Question,
          disclosure,
          section,
          questionId,
          startDate_of_disclosure
        );
      } else {
        UpdateHandler(
          Answer.UpdatedAnswer,
          Question,
          disclosure,
          section,
          questionId,
          startDate_of_disclosure
        );
      }
      setResponseUpdated(false);
    }
    if (!hasQuestion) {
      Question && setQuestion(null);
      // Question && console.log("quest set null");
      return;
    }

    const data = await treeResponse(disclosure, key, startDate_of_disclosure);
    setTreeData(data);

    if (data && Object.keys(data)?.includes("message")) {
      setDispalyMessage({
        ...DisplayMessage,
        DisplayName: data.message,
        displaypresent: true,
      });
      display_message = data.message;
    } else {
      setDispalyMessage({ ...DisplayMessage, displaypresent: false });
      question =
        data?.data?.formProperties?.Question &&
        data.data.formProperties.Question[0];
      setappovelQuest(
        data?.data?.formProperties?.isApproved &&
          data.data.formProperties.isApproved[0]
      );
      setQuestion(question);
      if (key) {
        getHelperText(key).then((res) => {
          sethelperText(res?.helperText);
        });
      }
    }

    //Handle Answer
    let Ans = "";

    if (
      data?.data?.answer?.Answer &&
      Array.isArray(data?.data?.answer?.Answer) &&
      data.data.answer.Answer.length > 0
    ) {
      Ans = data.data.answer.Answer[0];
      setAnswer({ InitialAnswer: Ans, UpdatedAnswer: Ans });
    }

    let rte = "";

    if (
      data &&
      data.data &&
      data.data.answer &&
      Array.isArray(data.data.answer.AdditionalNote) &&
      data.data.answer.AdditionalNote.length > 0
    ) {
      rte = data.data.answer.AdditionalNote[0];
    }
    setAdditionalNote({
      InitialNote: rte || [],
      UpdatedNote: rte || [],
    });

    //handle Comment
    let comment = "";
    //checking the comments and storing into usestate
    if (
      data &&
      data.data &&
      data.data.answer &&
      data.data.answer.CommentNote &&
      data.data.answer.CommentNote[0]?.length !== 0
    ) {
      comment = data.data.answer.CommentNote[0];
      setComment((prevstate) => {
        try {
          return {
            ...prevstate,
            InitialComment: JSON.parse(comment.replace(/\n/g, "\\n")),
            UpdatedComment: JSON.parse(comment.replace(/\n/g, "\\n")),
          };
        } catch {}
      });
    } else {
      setComment((prevstate) => {
        return { ...prevstate, InitialComment: "", UpdatedComment: "" };
      });
    }
    // seting the Fieldtype and storing into usestate
    const FieldType =
      data &&
      data.data &&
      data.data.formProperties &&
      data.data.formProperties.FieldType
        ? data.data.formProperties.FieldType[0]
        : null;
    const getAnswerDisable =
      data &&
      data.data &&
      data.data.formProperties &&
      data.data.formProperties.isApproved
        ? data.data.formProperties.isApproved[0]
        : false;

    if (data.data.formProperties?.approveOrRejectComment) {
      if (data.data.formProperties?.approvedOrRejectedBy) {
        setapprovedOrRejectedBy(
          data.data.formProperties?.approvedOrRejectedBy[0]
        );
      }
      setapproveOrRejectComment(
        data.data.formProperties.approveOrRejectComment[0]
      );
    }

    setFieldType_Data(FieldType);

    if (FieldType === "Text Field") {
      Form = (
        <Form_Text_input
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Drop Down" || FieldType === "Drop down") {
      const response_dropdown = await getDropdownValues(key);
      const FieldValues = Object.values(response_dropdown).toString();
      setDropdownValues(FieldValues);
      Form = (
        <Form_Autocomplete
          onChange={handleChange}
          FieldValues={FieldValues}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Check Box") {
      const FieldValues = data.data.formProperties.FieldValues[0];
      Form = (
        <CheckBoxs
          FieldValues={FieldValues}
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Table") {
      const TableJson = JSON.parse(data.data.formProperties.TableJson);
      Form = (
        <Form_Table
          TableJson={TableJson}
          questionId={key}
          onChange={handleChange}
          cik={cik}
          startDate={startDate_of_disclosure}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Multiselect") {
      const response_dropdown = await getMultiSelectorValues(key);
      Form = (
        <MultiSelected
          onChange={handleChange}
          response_dropdown={
            response_dropdown?.values ? response_dropdown?.values : []
          }
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (
      FieldType === "Radio Button" ||
      FieldType === "Radio Button with Dependency"
    ) {
      const FieldValues = data.data.formProperties.FieldValues[0];
      Form = (
        <Form_Radio_Group
          FieldValues={
            FieldValues.length !== 0 && FieldValues !== null ? FieldValues : []
          }
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Currency Field") {
      const response_dropdown = await GetCurrancy();
      Form = (
        <Currency
          FieldValues={response_dropdown}
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Number Field" || FieldType === "Number") {
      Form = (
        <Number onChange={handleChange} getAnswerDisable={getAnswerDisable} />
      );
    } else if (FieldType === "Date") {
      Form = (
        <Form_Date_input
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Date Range") {
      Form = (
        <Form_PeriodicDate
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "List of Address" || FieldType === "Address") {
      Form = (
        <Form_Address
          onChange={handleChange}
          ListOfAddress={true}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Contact Information") {
      Form = (
        <Form_ContractInfo
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (
      FieldType === "Number Field with Units" ||
      FieldType === "Number Field with Drop Down"
    ) {
      const response_dropdown = await getUnits(key);
      Form = (
        <Form_NumberandDropdown
          FieldValues={response_dropdown}
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (
      FieldType === "Percentage Field" ||
      FieldType === "Number Field with Percentage"
    ) {
      Form = (
        <Percentage
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Editable List") {
      Form = (
        <Editable_List
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "TelephoneNumber") {
      const response_dropdown = await getTelephoneNumber();
      Form = (
        <Form_NumberandDropdown
          FieldValues={response_dropdown}
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "PeriodicTime") {
      Form = (
        <Form_Clock
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Form Field") {
      const FormFieldJson = JSON.parse(
        data.data.formProperties.FormFieldJson[0]
      );
      Form = (
        <Form_SubForm
          FormFieldJson={FormFieldJson}
          Answer={Ans ? JSON.parse(Ans) : {}}
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "Rich Text" || FieldType === "RTE") {
      Form = (
        <CustomInlineToolbar
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    } else if (FieldType === "URL Field") {
      Form = (
        <Form_Url onChange={handleChange} getAnswerDisable={getAnswerDisable} />
      );
    } else if (FieldType === "Email Field") {
      Form = (
        <Form_Email
          onChange={handleChange}
          getAnswerDisable={getAnswerDisable}
        />
      );
    }

    setform(Form);
  };

  const RenderSections = (xs, md) => {
    return (
      <Grid
        item
        container
        rowSpacing={2}
        direction="column"
        sx={{
          [theme.breakpoints.down("xs")]: {
            display: xs, // Set display property for extra small screens
          },
          [theme.breakpoints.up("md")]: {
            display: md, // Set display property for small screens and larger
          },
        }}
      >
        <Grid item>
          <PaperComponent>
            {!DisplayMessage.displaypresent === true ? (
              Question ? (
                <AIbox
                  QuestionID={QuestionID}
                  startDate={startDate_of_disclosure}
                  Enddata={location.state.EndDate}
                  Fieldtype={FieldType_Data && FieldType_Data}
                  handleChange={handleChange}
                  DropdownValues={DropdownValues}
                  selectHandler={selectHandler}
                 
                />
              ) : (
                <Grid p={2} minHeight={"29vh"}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#061826",
                      marginBottom: "10px",
                    }}
                  >
                    AI Suggestion
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Please select a question from the available options. By
                    doing so, our AI system can better understand your query and
                    offer insightful recommendations to assist you effectively.
                    Take a moment to choose a question that aligns with your
                    current inquiry, and unlock the power of AI-driven
                    suggestions to optimize your interaction with our platform.
                  </Typography>
                </Grid>
              )
            ) : (
              <Grid p={2} minHeight={"29vh"}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#061826",
                    marginBottom: "30px",
                  }}
                >
                  AI Suggestion
                </Typography>
                <Typography>You Have Not Selected Any Section Yet.</Typography>
              </Grid>
            )}
          </PaperComponent>
        </Grid>

        <Grid item>
          
          <PaperComponent>
            <div className="addInfo-box">
              {!DisplayMessage.displaypresent === true ? (
                Question ? (
                   <Form_AdditionalNote
                    AdditionalNote={AdditionalNote}
                    setAdditionalNote={setAdditionalNote}
                    startDate={startDate_of_disclosure}
                    questionId={questionId}
                  />
                 
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#061826",
                      }}
                    >
                      Additional Info
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ alignSelf: "center" }}
                        src={NoteImage}
                        alt="No Selection"
                      />
                    </Box>
                  </>
                )
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#061826",
                    }}
                  >
                    Additional Info
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ alignSelf: "center" }}
                      src={NoteImage}
                      alt="No Selection"
                    />
                  </Box>
                </>
              )}
            </div>
          </PaperComponent>
  
        </Grid>
      </Grid>
    );
  };

  const NoSelection = ({ Label }) => {
    return (
      <PaperComponent>
        <div className="NoSelection">
          <Grid
            p={2}
            container
            wrap="nowrap"
            justifyContent="space-between"
            className="Discloser_path_box"
          >
            <Grid item xs={8}>
              <Breadcrumbs
                maxItems={3}
                separator="›"
                aria-label="breadcrumb"
                className="Discloser_path"
              >
                {DisclosurePath &&
                  DisclosurePath.map((item) => {
                    return (
                      <Link
                        className="pathlabels"
                        title={item}
                        key={item}
                        aria-current="page"
                      >
                        {item}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            </Grid>
            <Grid
              container
              item
              justifyContent={"flex-end"}
              xs={4}
              wrap="nowrap"
            >
              {selected && (
                <>
                  {" "}
                  <IconButton
                    sx={{
                      border: "1px solid #BAD5E7",
                      backgroundColor: "#E9F1F7",
                      borderRadius: "20px",
                    }}
                    size="small"
                    onClick={() => {
                      Toggle("prev");
                    }}
                  >
                    <KeyboardArrowLeftSharpIcon sx={{ color: "#2076B0" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      border: "1px solid #BAD5E7",
                      backgroundColor: "#E9F1F7",
                      borderRadius: "20px",
                      marginLeft: "10px",
                    }}
                    size="small"
                    onClick={() => {
                      Toggle("next");
                    }}
                  >
                    <KeyboardArrowRightIcon sx={{ color: "#2076B0" }} />
                  </IconButton>
                </>
              )}
            </Grid>
          </Grid>
          <Grid>
            {" "}
            <img src={No_Selection} alt="No Selection" />
            <Typography
              sx={{ fontSize: "16px", color: "#2076B0", textAlign: "center" }}
            >
              {Label}
            </Typography>
          </Grid>
          <Grid></Grid>
        </div>
      </PaperComponent>
    );
  };

  function getCurrentDateFormatted() {
    const date = new Date();

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns month from 0-11
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  const handleAprove = async () => {
    setopenLoader(true);
    setRefershAnswer(false);
    const approve = true;
    const startdate = decodeURIComponent(startDate_of_disclosure);
    const currentDate = getCurrentDateFormatted();
    const approveOrRejectComment = "Approved";
    try {
      const result = await putApprove(
        startdate,
        disclosure_name_from_user,
        questionId,
        filingGroups,
        approve,
        currentDate,
        approveOrRejectComment
      );
      selectHandler(questionId, true);
      setRefershAnswer(true);
      setopenLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async () => {
    setDelteOpen(true);
  };
  const handleRejectForAnalyst = async () => {
    setopenLoader(true);
    setRefershAnswer(false);
    const Approve = false;
    const startdate = decodeURIComponent(startDate_of_disclosure);
    const currentDate = getCurrentDateFormatted();
    let filingGroups = localStorage.getItem("selectedUserRoleContext");

    let approveOrRejectComment = ReasonForReject;
    try {
      const result = await putApprove(
        startDate_of_disclosure,
        disclosure_name_from_user,
        questionId,
        filingGroups,
        Approve,
        currentDate,
        "FilingAnalyst reject this question"
      );
      selectHandler(questionId, true);
      setRefershAnswer(true);
      setopenLoader(false);
    } catch (error) {
      console.log(error);
    }
    setDelteOpen(false);
  };

  const handleDeleteReason = async () => {
    setopenLoader(true);
    setRefershAnswer(false);
    const Approve = false;
    const startdate = decodeURIComponent(startDate_of_disclosure);
    const currentDate = getCurrentDateFormatted();
    let filingGroups = localStorage.getItem("selectedUserRoleContext");

    let approveOrRejectComment = ReasonForReject;
    try {
      const result = await putApprove(
        startDate_of_disclosure,
        disclosure_name_from_user,
        questionId,
        filingGroups,
        Approve,
        currentDate,
        approveOrRejectComment
      );
      selectHandler(questionId, true);
      setRefershAnswer(true);
      setopenLoader(false);
    } catch (error) {
      console.log(error);
    }
    setDelteOpen(false);
  };
  const handleJustClose = () => {
    setDelteOpen(false);
  };

  const Spinner = ({ open }) => {
    return (
      <Backdrop open={open} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
        <Typography>Loading......</Typography>
      </Backdrop>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <LinearProgress />
      ) : (
        <Box sx={{ backgroundColor: "#F5F5F6" }}>
          <Grid container justifyContent="space-between" px={2} mt={1}>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="small"
                sx={{ display: isMobile && !isTreeVisible ? "block" : "none" }}
                onClick={() => {
                  SetTreeVisibility();
                }}
              >
                <KeyboardArrowLeftSharpIcon sx={{ color: "#2076B0" }} />
              </IconButton>
              <ArrowBackIosIcon
                style={{
                  marginRight: "0.2em",
                  cursor: "pointer",
                  display: isMobile && !isTreeVisible ? "none" : "block",
                }}
                onClick={() => {
                  navigate("/ESGHome");
                }}
              />
              <Typography
                sx={{ fontSize: "22px", color: "#061826", fontWeight: "600" }}
              >
                {disclosure_name_from_user}
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>

          <Grid container spacing={0.5}>
            {/* section 1 Tree */}
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              sx={{ display: isMobile && !isTreeVisible ? "none" : "block" }}
            >
              <Box className="treeSection">
                <Tree
                  state={state}
                  selected={selected}
                  handleSelect={handleSelect}
                  selectHandler={selectHandler}
                  SetTreeVisibility={SetTreeVisibility}
                  isMobile={isMobile}
                  ExpandedNodes={ExpandedNodes}
                  setExpandedNodes={setExpandedNodes}
                />
              </Box>
            </Grid>

            {/* section 2 Q/A & Comments */}
            <Grid
              item
              xs={12}
              sm={7}
              md={5}
              sx={{ display: isMobile && isTreeVisible ? "none" : "block" }}
            >
              {/* Q/A */}
              <div className="section2">
                {!DisplayMessage.displaypresent === true ? (
                  Question ? (
                    <Grid container rowSpacing={2} direction="column">
                      <Grid item>
                        <PaperComponent>
                          <div className="quesAns-box">
                            <Grid container direction="column" rowSpacing={2}>
                              {/* Breadcrumbs & PrevNext Buttons */}
                              <Grid
                                item
                                container
                                wrap="nowrap"
                                justifyContent="space-between"
                                className="Discloser_path_box"
                              >
                                <Grid item xs={8} wrap="nowrap">
                                  <Breadcrumbs
                                    maxItems={2}
                                    separator="›"
                                    aria-label="breadcrumb"
                                    className="Discloser_path"
                                  >
                                    {DisclosurePath &&
                                      DisclosurePath.map((item) => {
                                        return (
                                          <Link
                                            className="pathlabels"
                                            title={item}
                                            key={item}
                                            aria-current="page"
                                          >
                                            {item}
                                          </Link>
                                        );
                                      })}
                                  </Breadcrumbs>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  justifyContent={"flex-end"}
                                  xs={4}
                                  wrap="nowrap"
                                >
                                  <IconButton
                                    sx={{
                                      border: "1px solid #BAD5E7",
                                      backgroundColor: "#E9F1F7",
                                      borderRadius: "20px",
                                    }}
                                    size="small"
                                    onClick={() => {
                                      Toggle("prev");
                                    }}
                                  >
                                    <KeyboardArrowLeftSharpIcon
                                      sx={{ color: "#2076B0" }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      border: "1px solid #BAD5E7",
                                      backgroundColor: "#E9F1F7",
                                      borderRadius: "20px",
                                      marginLeft: "10px",
                                    }}
                                    size="small"
                                    onClick={() => {
                                      Toggle("next");
                                    }}
                                  >
                                    <KeyboardArrowRightIcon
                                      sx={{ color: "#2076B0" }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              {!DisplayMessage.displaypresent === true
                                ? Question && (
                                    <Grid container direction={"column"}>
                                      <Grid item p={2}>
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            color:
                                              appovelQuest === undefined
                                                ? "#061826"
                                                : appovelQuest === true
                                                ? "blue"
                                                : "red",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {Question}
                                          <IconButton
                                            aria-describedby={id}
                                            size="small"
                                            onClick={handleClick}
                                          >
                                            <InfoIcon />
                                          </IconButton>
                                          <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}
                                          >
                                            <Typography
                                              sx={{ p: 2, width: "400px" }}
                                            >
                                              {helperText}
                                            </Typography>
                                          </Popover>
                                        </Typography>
                                      </Grid>
                                      {RefershAnswer && (
                                        <Grid item mt={1}>
                                          {form}
                                        </Grid>
                                      )}

                                      <Grid item p={2} mt={1}>
                                        {form &&
                                          ((filingGroups &&
                                            filingGroups ===
                                              "Filing Reviewer") ||
                                          filingGroups === "FIling Auditor" ? (
                                            <Grid
                                              item
                                              container
                                              spacing={2}
                                              justifyContent={"flex-start"}
                                            >
                                              <Grid item>
                                                <button
                                                  onClick={() => {
                                                    handleAprove();
                                                  }}
                                                >
                                                  Approve
                                                </button>
                                              </Grid>
                                              <Grid item>
                                                <button
                                                  onClick={() => {
                                                    handleReject();
                                                  }}
                                                >
                                                  Reject
                                                </button>
                                              </Grid>
                                            </Grid>
                                          ) : filingGroups &&
                                            filingGroups === "FilingAnalyst" ? (
                                            <Grid
                                              item
                                              container
                                              justifyContent={"flex-start"}
                                              spacing={2}
                                            >
                                              <Grid item>
                                                <DoneIcon
                                                  onClick={() => {
                                                    handleAprove();
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item>
                                                <CloseIcon
                                                  onClick={() => {
                                                    handleRejectForAnalyst();
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                          ) : (
                                            filingGroups &&
                                            filingGroups ===
                                              "Filing-Administrator" && (
                                              <Grid
                                                item
                                                container
                                                justifyContent={"flex-start"}
                                                spacing={2}
                                              >
                                                <Grid item>
                                                  <button
                                                    onClick={() => {
                                                      handleAprove();
                                                    }}
                                                  >
                                                    Submit
                                                  </button>
                                                </Grid>
                                                <Grid item>
                                                  <button
                                                    onClick={() => {
                                                      handleReject();
                                                    }}
                                                  >
                                                    Reject
                                                  </button>
                                                </Grid>
                                              </Grid>
                                            )
                                          ))}
                                      </Grid>
                                      {approveOrRejectComment &&
                                        approvedOrRejectedBy && (
                                          <Grid item p={2} mt={1}>
                                            <Paper
                                              elevation={3}
                                              style={{
                                                padding: "16px",
                                                backgroundColor: "#f9f9f9",
                                              }}
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                              >
                                                Comment by{" "}
                                                {approvedOrRejectedBy}
                                              </Typography>
                                              <Typography
                                                variant="body1"
                                                style={{ marginTop: "8px" }}
                                              >
                                                {approveOrRejectComment}
                                              </Typography>
                                            </Paper>
                                          </Grid>
                                        )}
                                    </Grid>
                                  )
                                : DisplayMessage.DisplayName}
                            </Grid>
                          </div>
                        </PaperComponent>
                      </Grid>
                      <RejectReason
                        open={DelteOpen}
                        handleClose={handleDeleteReason}
                        SetReasonForReject={SetReasonForReject}
                        handleJustClose={handleJustClose}
                      />
                      <Spinner open={openLoader} />
                      {/* { RenderSections('block','none')} */}
                      {/* Comments */}
                      <Grid item>
                        <PaperComponent>
                          <div className="comment-box">
                            {!DisplayMessage.displaypresent === true
                              ? Question && (
                                  <Grid
                                    container
                                    direction={"column"}
                                    spacing={1}
                                  >
                                    <Grid item>
                                      <Typography
                                        sx={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                          color: "#061826",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Your comment
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Comments
                                        currentUserId={UserInfo.id}
                                        setComment={setComment}
                                        Coment={Comment}
                                        QuestionID={QuestionID}
                                        startDate={startDate_of_disclosure}
                                      />
                                    </Grid>
                                  </Grid>
                                )
                              : ""}
                          </div>
                        </PaperComponent>
                      </Grid>
                    </Grid>
                  ) : (
                    <NoSelection
                      Label={"You Have Not Selected Any Section Yet."}
                    />
                  )
                ) : (
                  <NoSelection Label={DisplayMessage.DisplayName} />
                )}
              </div>
            </Grid>

            {/* section 3 Help Text & Additional Info(RTE) */}
            <Grid
              item
              md={3}
              sx={{
                [theme.breakpoints.up("xs")]: {
                  display: "none", // Set display property for extra small screens
                },
                [theme.breakpoints.up("md")]: {
                  display: "block", // Set display property for small screens and larger
                },
              }}
            >
              <div className="section3">{RenderSections("none", "block")}</div>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ViewReport;
