import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, TextField, Typography, Autocomplete, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from '@mui/icons-material/Info';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import "../styles/Real_Time_Data/Real_Time_Data.css";
import MovingIcon from '@mui/icons-material/Moving';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Trading_Table from "./TransactionTab";
import Product from "./ProductsTab";
import Transactions from "./TradingTab";
import Popover from '@mui/material/Popover';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PopupCard from "./PopupCard";
import BusinessAreas from "./BusinessAreasTab";
import CollateralsData from "./CollateralsTab";
import { CollectionsBookmarkOutlined } from "@mui/icons-material";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import { useContext } from "react";
import NoteContext from "context/PageContext";
const columns = [
  { id: 'name', label: 'LEI', minWidth: '100px', align: 'left', },
  { id: 'code', label: 'Company Name', minWidth: '100px', align: 'left', },
];

 function StickyHeadTable({showSnackbar}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const ContextData = useContext(NoteContext);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [TotalPage, setTotalPage] = useState(0);
  const [tempData, setTempData] = useState();
  const [page, setPage] = React.useState(0);
  const [Refresh, setRefresh] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [Search, setSearch] = React.useState("");
  const [CompanyList, setCompanyList] = React.useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [CompanyDetails, setCompanyDetails] = React.useState();
  const [TradStatus, setTradStatus] = React.useState();
  const [value, setValue] = React.useState('1');
  const [UpdateCompanyDetails, setUpdateCompanyDetails] = React.useState();
  const { GetCopmaniesList, GetCopmaniesDetails } = require("../../ESG/services/Fetch");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //Get Copmanies List
  const getcountries = async () => {
    try {
      const data = await GetCopmaniesList(page, rowsPerPage);
      showSnackbar("Loading data, please wait...", "info");
      if (data?.entities) {
        if (CompanyList?.length > 0) {
          setCompanyList([...CompanyList, ...data.entities]);
          showSnackbar("Data loaded successfully", "success");
        } else {
          setCompanyList(data.entities);
          showSnackbar("Data loaded successfully", "success");
        }
        setTotalPage(data.rows);
      } else {
        showSnackbar("Error loading data. Please try again.", "error")
        console.error("Data is missing or invalid.");
      }
    } catch (error) {
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true
      });
      showSnackbar("Error loading data. Please try again.", "error")
      console.error("An error occurred:", error);
    }
  };
  
  useEffect(() => {
    getcountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, value, Refresh]);
  


  //handle refresh the data 
  const handleRefresh = () => {
    setRefresh(!Refresh)
  }

  // Get Copmanies Details
  useEffect(() => {
    const GetDetails = async () => {
      try {
        const data = await GetCopmaniesDetails(UpdateCompanyDetails?.id);
        showSnackbar("Loading data, please wait...", "info");
        setCompanyDetails(data);
        showSnackbar("Data loaded successfully", "success");
      } catch (error) {
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        showSnackbar("Error loading data. Please try again.", "error")
        console.error("An error occurred:", error);
      }
    };
    if (UpdateCompanyDetails) {
      GetDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UpdateCompanyDetails]);
  
  //console.log(UpdateCompanyDetails)
  // handle Company Details in Card
  const handleUpdate = (row, i) => {
    setUpdateCompanyDetails(row)
    setSelectedRowIndex(i)
  }

  //pagination for table handle change page 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //pagination for table handle Change Rows Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TabContext value={value}>
        <Box className='Top_box'  >
          <TextField
            id="standard-basic"
            placeholder="Search"
            variant="outlined"
            sx={{ marginLeft: '10px' }}
            // value={Search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              className: "searchInput",
            }}
          ></TextField>
          <IconButton title="Refresh table data" color="secondary" onClick={handleRefresh}><RefreshIcon /></IconButton>
          {value === '4' && <IconButton color="primary" onClick={handleClick}><FilterAltIcon /></IconButton>}

          {/* <TabList  aria-label="lab API tabs example"> */}
          <Tab className={`Tabs ${value === '1' ? "TabsChecked" : 'TabsUnChecked'}`} title="Company list" onChange={handleChange} icon={<InfoIcon />} wrapped value="1" />
          <Tab className={`Tabs ${value === '2' ? "TabsChecked" : 'TabsUnChecked'}`} title="Products" onChange={handleChange} icon={<ProductionQuantityLimitsIcon />} value="2" />
          <Tab className={`Tabs ${value === '3' ? "TabsChecked" : 'TabsUnChecked'}`} title="Transactions" onChange={handleChange} icon={<TransferWithinAStationIcon />} value="3" />
          <Tab className={`Tabs ${value === '4' ? "TabsChecked" : 'TabsUnChecked'}`} title="Trading" onChange={handleChange} icon={<MovingIcon />} value="4" />
          <Tab className={`Tabs ${value === '5' ? "TabsChecked" : 'TabsUnChecked'}`} title="Collaterals" onChange={handleChange} icon={<LibraryBooksIcon />} value="5" />
          <Tab className={`Tabs ${value === '6' ? "TabsChecked" : 'TabsUnChecked'}`} title="Business Areas" onChange={handleChange} icon={<BusinessCenterIcon />} value="6" />
          <Popover

            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          ><Box sx={{ width: 300, height: 300 }}>
              <Typography sx={{ padding: '10px 0px 0px 10px' }}>Filter By Status:</Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={StatusData}
                onChange={(e, Status) => setSearch(Status)}
                sx={{ width: 200, paddingLeft: '10px' }}
                renderInput={(params) => <TextField {...params} label="Filter" />}
              /></Box>
          </Popover>
          {/* </TabList> */}
        </Box>
        {value === "1" && <TabPanel className="Pan_CompanyDetails" value="1">{<Grid container spacing={0} >
          <Grid item xs={7}>
            <TableContainer className="TableContainer">
              <Table stickyHeader aria-label="sticky table" >
                <TableHead >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        className="Table_Head"
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {CompanyList && CompanyList?.filter((val) => {
                    if (Search === "" || Search === null) {
                      return val;
                    } else if (
                      val.id
                        .toLowerCase()
                        .includes(Search.trim().toLowerCase()) || val.name.toLowerCase()
                          .includes(Search.trim().toLowerCase())
                    ) {
                      return val;
                    }
                  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                    <TableRow
                      style={{ backgroundColor: i === selectedRowIndex ? '#c4c4ff' : '' }}
                      onClick={() => handleUpdate(row, i)}
                      key={i}
                      // className="TableRow"
                      className={i % 2 === 0 ? "TableRowOdd" : 'TableRowEven'}
                    >
                      <TableCell component="th" scope="row" >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={Math.floor(TotalPage)}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid><Grid item xs={5}>
            {/* <PopupCard /> */}
            {CompanyDetails && CompanyDetails?.address?.length > 0 ? <>
              <Typography className="Company_Name">{UpdateCompanyDetails?.name}</Typography>
              {CompanyDetails?.address?.map((CompanyDetails, i) => (<Card key={i} className="CompanyDetails_Card1" >
                <CardContent className="CardContent">
                  <Typography variant="h7" component="div">
                    {CompanyDetails?.firstline ?? 'undefined'}<br></br>
                    {CompanyDetails?.secondline ?? 'undefined'}<br></br>
                    {CompanyDetails?.city ?? 'undefined'}<br></br>
                    {CompanyDetails?.country ?? 'undefined'}<br></br>
                    {CompanyDetails?.region ?? 'undefined'}<br></br>
                    {CompanyDetails?.postalcode ?? 'undefined'}
                  </Typography>
                </CardContent>
              </Card>

              ))}
            </> : ''}
          </Grid></Grid>}

        </TabPanel>}
        {value === "2" && <TabPanel className="Pan_CompanyDetails" value="2"><Trading_Table Search={Search} Refresh={Refresh} /></TabPanel>}
        {value === "3" && <TabPanel className="Pan_CompanyDetails" value="3"><Product Search={Search} Refresh={Refresh} /></TabPanel>}
        {value === "4" && <TabPanel className="Pan_CompanyDetails" value="4"><Transactions Search={Search} TradStatus={TradStatus} Refresh={Refresh} /></TabPanel>}
        {value === "6" && <TabPanel className="Pan_CompanyDetails" value="6"><BusinessAreas Search={Search} TradStatus={TradStatus} Refresh={Refresh} /></TabPanel>}
        {value === "5" && <TabPanel className="Pan_CompanyDetails" value="5"><CollateralsData Search={Search} TradStatus={TradStatus} Refresh={Refresh} /></TabPanel>}

      </TabContext>
    </>
  );
}

const StatusData = [
  'Submit', 'Notsubmit', 'NOTPROCESSED', 'Processed'
];

export default withSnackbar(StickyHeadTable, "Home paage", "success");