import React from "react";
import { Grid } from "@mui/material";
import { TextField, Button, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import "../styles/Forms_Styles/Form_ContractInfo.css";
import "../styles/Forms_Styles/Form_RTE.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";
function Form_ContractInfo({  onChange,getAnswerDisable }) {
  const {Answer}=useContext(NoteContext);
  const [data, setdata] = useState({
    Name: "",
    Position: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
  });
  let Info=[];
  try {
    
     Info= typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : []
  } catch (error) {
    Info=[];
    
  }
  
  const [index, setIndex] = useState();
  const [bolin, setBolin] = useState(false);
  let { Name, Position, PhoneNumber, Email, Address } = data;
 
  const handleAddClick = () => {

    if (data.Name.trim() !== "" && data.Position.trim() !== "" && data.PhoneNumber.trim() !== "" && data.Email.trim() !== "" && data.Address.trim() !== "") {
      onChange([...Info, data]);

      setdata({
        Name: "",
        Position: "",
        PhoneNumber: "",
        Email: "",
        Address: "",
      });
    }
  };
  const handleUpdate = (i) => {
   
    if (Info[i]?.Name.trim() !== "" && Info[i]?.Position.trim() !== "" && Info[i]?.PhoneNumber.trim() !== "" && Info[i]?.Email.trim() !== "" && Info[i]?.Address.trim() !== "") {

      let { Name, Position, PhoneNumber, Email, Address } = Info[i];
      
  
      setdata({ Name, Position,PhoneNumber, Email, Address });
      setBolin(true);
      setIndex(i);
    }
  };

  const handleDelete = (i) => {
    let total = [...Info];
    total.splice(i, 1);
    onChange(total)
  };

  const Updateinfo = () => {

    if (Name !== "" && Position !== "" && PhoneNumber !== "" && Email !== "" && Address !== "") {

      let total = [...Info];
      total.splice(index, 1, { Name, Position, PhoneNumber, Email, Address });

      onChange(total)
      setdata({
        Name: "",
        Position: "",
        PhoneNumber: "",
        Email: "",
        Address: "",
      });
      setBolin(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
        fullWidth
          size="small"
          id="standard-basic"
          variant="outlined"
          value={data.Name}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, Name: e.target.value };
            })
          }
          placeholder="Name"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
        fullWidth
          size="small"
          id="standard-basic"
          variant="outlined"
          placeholder="Position"
          value={data.Position}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, Position: e.target.value };
            })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
        fullWidth
          size="small"
          id="standard-basic"
          variant="outlined"
          type="number"
          placeholder="Phone Number"
          value={data.PhoneNumber}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, PhoneNumber: e.target.value };
            })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
        fullWidth
          id="standard-basic"
          variant="outlined"
          size="small"
          placeholder="Email"
          value={data.Email}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, Email: e.target.value };
            })
          }
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="standard-basic"
          variant="outlined"
          size="small"
          placeholder="Address"
          value={data.Address}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, Address: e.target.value };
            })
          }
        />
      </Grid>

      <Grid item xs={12}>
        {getAnswerDisable &&
        <Button
          onClick={bolin ? Updateinfo : handleAddClick}
          variant="outlined"
          startIcon={bolin ? <DoneOutlinedIcon /> : <AddIcon />}
          sx={{ color: "#25A392", borderColor: "#CBE5E1", bgcolor: "#E9F6F4" }}
        >
          {bolin ? "Save" : "Add"}
        </Button>
}
      
      </Grid>
      <Grid item container>
        <TableContainer className="InputFields">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow className="Table_Row">
                <TableCell style={{ color: "#25A392" }}>Name</TableCell>
                <TableCell style={{ color: "#25A392" }}>PhoneNumber</TableCell>
                <TableCell style={{ color: "#25A392" }}>Position</TableCell>
                <TableCell style={{ color: "#25A392" }}>Email</TableCell>
                <TableCell style={{ color: "#25A392" }}>Address</TableCell>
                <TableCell style={{ color: "#25A392" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Info &&
                Info.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ backgroundColor: i % 2 === 1 ? "#EBEDED" : "white" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "14px" }} // Adjust the font size as needed
                    >
                      {row.Name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      {row.PhoneNumber}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      {row.Position}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>{row.Email}</TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      {row.Address}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        title="Detele"
                        className="Table_Button_Delete"
                        onClick={() => handleDelete(i)}
                      >
                        <DeleteForeverIcon
                          className="Table_Button"
                          edge="end"
                          aria-label="delete"
                          title="Detele"
                        />
                      </IconButton>

                      <IconButton
                        title="Edit"
                        className="Table_Button_Edit"
                        onClick={() => handleUpdate(i)}
                      >
                        <EditIcon
                          className="Table_Button"
                          aria-label="Edit"
                          title="Edit"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default Form_ContractInfo;
