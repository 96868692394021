import axios from "axios";


const { REACT_APP_API_URL } = process.env;


// import { useContext } from 'react';
// import NoteContext from 'context/PageContext';
/* const getToken = async (scopes) => {
  const { instance } = require("../index");
  const account = instance.getActiveAccount();

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }
  

  const response = await instance.acquireTokenSilent({
    account: account,
    scopes: ["user.Read"],
  });
  return response.idToken;
}; */
/* const getAccessToken = async (scopes) => {
  const { instance } = require("index");
  const account = instance.getActiveAccount();

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await instance.acquireTokenSilent({
    account: account,
    scopes: ["Group.Read.All"],
  });
  return response.accessToken;
}; */
export const getBaseTree = async (disclosure) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
 //console.log(disclosure,"-------------------------");
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/basetree`,
    params: {
      disclosure: disclosure,
      domain:localStorage.getItem("UserSelectedDomain"),
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getHelperText = async (key) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/helpertext`,
    params: {
      questionId: key,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

// For Update Answer
export const UpdateHandler = async (
  UpdatedAnswer,
  Question,
  disclosure,
  section,
  questionId,
  startDate
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
console.log( UpdatedAnswer,
  Question,
  disclosure,
  section,
  questionId,
  startDate)
 
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/esg/newresponse`,
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
      questionId: questionId,
      disclosure: disclosure,
      section: section, 
      startDate: startDate,
    },
    data: {
      question: Question,
      answer: UpdatedAnswer,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

// For Tree Response

export const treeResponse = async ( disclosure, questionId, startDate,) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
 
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/response`,
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
      disclosure: disclosure,
      questionId: questionId,
      startDate: startDate,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => { 
      
      return response.data})
    .catch((error) => console.log(error));
};

export const typeOfVertex = async (questionId) => {
  //console.log("questionId",questionId)
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/typeOfVertex`,
    params: {
      Id: questionId,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

//For Getting Dropdown Values

export const getDropdownValues = async (questionId) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/dropdownvalues`,
    params: {
      questionId: questionId,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getMultiSelectorValues = async (questionId) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/table/multiSelectorValues`,
    params: {
      questionId: questionId,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const getUnits = async (questionId) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/location/unitList`,
    params: {
      questionId: questionId,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

//For ADV Telephone number

export const getTelephoneNumber = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/location/FullCountryNameList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

//For Gri Dashboard

export const getGriDashboard = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  let type;
  if(localStorage.getItem("permittedPath") === "Insurance")
    {
      type ="Insurance";
    }
    else if(localStorage.getItem("permittedPath") === "ESG")
      {
        type ="ESG";
      }
    else 
      {
        type ="Compliance";
      }

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/analytics`,
    params:{
      domain:localStorage.getItem("UserSelectedDomain"),
      filingType:type,
    },
   
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
   .then((response) => {
    console.log(response);
    return response})
  .catch((error) =>  error);
};

export const getDisclosures_Newfilling = async () => {
  let type;
  if(localStorage.getItem("permittedPath") === "Insurance")
    {
      type ="Insurance";
    }
    else if(localStorage.getItem("permittedPath") === "ESG")
      {
        type ="ESG";
      }
    else 
      {
        type ="Compliance";
      }
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/filingdropdowns`,
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
      filingCategory:type,

    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

//for New Filing

export const get_Newfilling = async (
  DisclosureName,
  disclosurevalue,
  selectedDomain,
  fromtDate,
  toDate,
  deadlineDate
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/esg/newfiling`,
    params: {
      disclosureName:DisclosureName,
      domain:selectedDomain,
      filingType:"ESG",
      disclosure: disclosurevalue,
      periodStart: fromtDate,
      periodEnd: toDate,
      periodDeadline: deadlineDate,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const CreateFilingWithAI = async (
  DisclosureName,
  disclosurevalue,
  selectedDomain,
  fromtDate,
  toDate,
  deadlineDate
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/genAI/postNewFilingGenAI`,
    params: {
      company: localStorage.getItem("UserSelectedDomain").split(".")[0],
      disclosureName:DisclosureName,
      domain:selectedDomain,
      filingType:"ESG",
      disclosure: disclosurevalue,
      periodStart: fromtDate,
      periodEnd: toDate,
      periodDeadline: deadlineDate,
    },
    data:{"COMPANY":localStorage.getItem("UserSelectedDomain").split(".")[0]},
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};


export const getFormDropdownValues = async (path) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}${path}`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

//For Getting Table Dropdown Values

export const getTableDropdownValues = async (
  questionId,
  path,
  startDate,
  domain
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const LocationPath = path === "/filings/table/selectedLocation";

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}${path}`,
    params: {
      ...(LocationPath ? {} : { questionId: questionId }),
      startDate: startDate,
      domain: domain,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const AddNewDropDownValue = async (questionId, newDropdownValue) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/table/addDropdownvalue`,
    params: {
      questionId: questionId,
      newDropdownValue: newDropdownValue,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

//To get Login User Detailes
export const getUserProfile = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/azure/me`,
    params:{
   domain:localStorage.getItem("UserSelectedDomain"),
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      //     "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => {
    console.log(response);
    return response})
  .catch((error) => console.log(error));
};

//Country List Api
export const GetCountryList = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/location/countryList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data.values)
    .catch((error) => console.log(error));
};

//For States List based on country select
export const GetStateList = async (Country) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/location/stateList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      countryId: Country,
    },
  })
    .then((response) => response.data.values)
    .catch((error) => console.log(error));
};
export const GetNotification = async (id) => {
  console.log(id);
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/azure/notifications`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      // "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },

    params: {
      userRoleId: id ? id : "No Group",
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const UpdateNotification = async (notificationIds,role) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/azure/readby`,
    data: {
      notificationIds: notificationIds,
    },
    params: {
      userRoleId: role ? role : "No Group",
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const GetDependentCount = async (QuestionID) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/dependentQuestionCount`,
    params: {
      questionId: QuestionID,
    },
    headers: {
      //  'Access-Control-Allow-Origin': '*',
      // "Access-Control-Allow-Methods":"POST, GET, OPTIONS, DELETE, PUT",
      // "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetCopmaniesList = async (page, rowsPerPage) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/companies`,
    params: {
      pageno: page,
      records: rowsPerPage,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetCopmaniesDetails = async (CompanyID) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/companylocations`,
    params: {
      id: CompanyID,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const GetInstruments = async (page, rowsPerPage) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/fininstrum`,
    params: {
      pageno: page,
      records: rowsPerPage,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetExecutingvenue = async (page, rowsPerPage) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/executingvenues`,
    params: {
      pageno: page,
      records: rowsPerPage,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const Getbusinessareas = async (page, rowsPerPage) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/businessareas`,
    params: {
      pageno: page,
      records: rowsPerPage,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const Getcollaterals = async (page, rowsPerPage) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/collaterals`,
    params: {
      pageno: page,
      records: rowsPerPage,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const Getdaycountconvention = async (page, rowsPerPage) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/daycountconvention`,
    params: {
      pageno: page,
      records: rowsPerPage,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const GetTransactons = async (page, rowsPerPage, statetype) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/trades`,
    params: {
      pageno: page,
      records: rowsPerPage,
      statetype: statetype,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const GetcollateralsTrades = async (id) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/collattrades`,
    params: {
      id: id,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const GetAddrsfininstvenues = async (lei) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/postgre/addrsfininstvenues`,
    params: {
      lei: lei,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetCurrancy = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/table/currencySymbolList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const putReviewer = async (
  startDate,
  questionId,
  isAnswered,
  markedOn
) => {
  
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/esg/markasread`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      startDate: startDate,
      questionId: questionId,
      isAnswered: isAnswered,
      markedOn: markedOn,
    },
  })
    .then((response) => response.data.result)
    .catch((error) => console.log(error));
};

export const handleQuestionDataUpdate = async (
  date,
  DisplayName,
  Question,
  questionid
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/formBuilder/editQuestion`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
      date: date,
      DisplayName: DisplayName,
      Question: Question,
      questionId: questionid,
    },
  })
    .then((response) => response.data.result)
    .catch((error) => console.log(error));
};

export const putApprove = async (startDate_of_disclosure,disclosure_name_from_user,questionId,filingGroups,Approve,currentDate,approveOrRejectComment) => {
  console.log(startDate_of_disclosure);
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/esg/approvereject`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      questionId: questionId,
      domain :localStorage.getItem("UserSelectedDomain"),
      Approve:Approve,
      startDate: startDate_of_disclosure,
      markedOn:currentDate,
    },
    data:{
      role:filingGroups,
approveOrRejectComment:approveOrRejectComment
    }
  })
    .then((response) => response.data.result)
    .catch((error) => console.log(error));
};

export const AddCustOnBoard = async (data) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/createCustomerOnBoard`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    data: {
      HQAddress: data.HQAddress,
    },
    params: {
      LegalName: data.legalName,
      ParentOrganization: data.parentOrganization,
      CIK: data.cik,
      SIC: data.sic,
      TypeOfCompany: data.typeOfCompany,
      IncorporationDetail: data.incorporationDetail,
      Holding: data.holding,
      domain: localStorage.getItem("UserSelectedDomain"),
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const GetSicCode = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/table/getSicCode`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetCostomerOnBoardQuestions = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
 
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/costomerOnBoardQuestions`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetOnboardQuestionResponse = async ( QID) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getOnboardQuestionResponse`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      qid: QID,
      domain: localStorage.getItem("UserSelectedDomain"),
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const UpdateOnboardingQuestionResponse = async (
 
  qid,
  fieldType,
  answer
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
 
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/storeOnboardingQuestionResponse`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      qid: qid,
      fieldType: fieldType,
      domain: localStorage.getItem("UserSelectedDomain"),
    },
    data: {
      answer: answer,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetTypeOfCompany = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getTypeOfCompany`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetCustomerOnBoardDetails = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getCustomerOnBoard`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
     
      domain: localStorage.getItem("UserSelectedDomain"),
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const GetCustomerOnBoard = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getCustomerOnBoard`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain: localStorage.getItem("UserSelectedDomain"),
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const fetchEditIcon = async (disclosurename, formValues) => {
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/graphdata/editSection`,
    params: {
      disclosurename: disclosurename,
      sectionName: formValues.sectionname,
      sectionId: formValues.sectionid,
      sectiondescription: formValues.sectiondescription,
      sectionhelpertext: formValues.sectionhelpertext,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const FetchGetData = async (disclosurename) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/graphdata/getdata`,
    params: {
      disclosurename: disclosurename,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const answerTypesDropdown = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/answerTypes`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const bard_oai = async (Question) => {
  // const tokens = JSON.parse(localStorage.getItem("tokens"));
  
  return await axios({
    method: "POST",
    url: `https://app-bard-openai.azurewebsites.net/api/bard_oai`,
    data: Question,
    // headers: {
    //   "Content-Type": "application/json",
    //   accessToken: tokens.accessToken,
    //   Authorization: `Bearer ${tokens.idToken}`,
    // },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const AIprompts = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/AIprompts`,
   
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const updateAdditionalNote = async (
  startDate,
  questionId,
  backendComments,
  blobPath
) => {
 
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  let data = {
    additionalNote: backendComments, 
    blobPath:blobPath,
  };
  
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/esg/updateCommentNoteAndAdditionalNote`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
      startDate: startDate,
      questionId: questionId,
    },
    data: data,
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const updateCommentNote = async (
  startDate,
  questionId,
  backendComments,
) => {
 
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  let data = {
    commentNote: backendComments, 
  };
  
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/esg/updateCommentNoteAndAdditionalNote`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
      startDate: startDate,
      questionId: questionId,
    },
    data: data,
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const DeleteAdditionalNote = async (
  startDate,
  questionId,
  blobPath
) => {
 
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "DELETE",
    url: `${REACT_APP_API_URL}/filings/esg/deleteAdditionalNote`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
      startDate: startDate,
      questionId: questionId,
      blobPath:blobPath
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const domainDropdownList = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/domainDropdownList`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      //     "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => {
    return response.data})
  .catch((error) => console.log(error));
};

export const getQuestionDetails = async (questionId, endDate, startDate, CancelObject) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  
  

  try {
    const response = await axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/filings/table/getQuestionDetails`,
      params: {
        questionId: questionId,
        company: localStorage.getItem("UserSelectedDomain").split(".")[0],
        startDate: startDate,
        endDate: endDate,
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },
      cancelToken: CancelObject, // Pass the cancel token to the request
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching data:', error);
    }
    return null; // Or handle the error accordingly
  }
};
export const GetEsgNews = async () => {
  let type;
  if(localStorage.getItem("permittedPath") === "Insurance")
    {
      type ="Insurance";
    }
    else if(localStorage.getItem("permittedPath") === "ESG")
      {
        type ="ESG";
      }
    else 
      {
        type ="Compliance";
      }
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/getESGnews`,
    params :{
      newsType : type,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
 
export const GetEsgEvents = async () => {
  let type;
  if(localStorage.getItem("permittedPath") === "Insurance")
    {
      type ="Insurance";
    }
    else if(localStorage.getItem("permittedPath") === "ESG")
      {
        type ="ESG";
      }
    else 
      {
        type ="Compliance";
      }
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/getESGevents`,
    params :{
      eventType :type,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const AddUser = async (UserDetails) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/esg/addUser`,
    data: {
      email:UserDetails.UserEmail,
      displayName:UserDetails.UserName,
      UserRole:UserDetails.UserRole,
      UserStartDate:UserDetails.UserStartDate,
      companies:UserDetails.companies,
      UserCompany:UserDetails.UserCompany,
    },
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const GetAllUsers = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/getUsers`,
    params: {
      domain:localStorage.getItem("UserSelectedDomain"),
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const getUserRole = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/customerOnBoard/getUserRole`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      //     "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
  .then((response) => {
    return response.data})
  .catch((error) => console.log(error));
};

export const GENAI = async (questionId, endDate, startDate, CancelObject) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  try {
    const response = await axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/filings/table/getQuestionDetailsLLM`,
      params: {
        questionId: questionId,
        company: localStorage.getItem("UserSelectedDomain").split(".")[0],
        startDate: startDate,
        endDate: endDate,
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },
      cancelToken: CancelObject, // Pass the cancel token to the request
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching data:', error);
    }
    return null; // Or handle the error accordingly
  }
};

export const pathpermission = async (email) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  try {
    const response = await axios({
      method: "GET",
      url: `https://us-east1-complianceledger.cloudfunctions.net/pathpermissions`,
      params: {
        email:email,
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },
       // Pass the cancel token to the request
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching data:', error);
    }
    return null; // Or handle the error accordingly
  }
};
