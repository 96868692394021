import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import { Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";

// importing data form database
import {
  GetCountryList,
  PostaddCompany,
  getAllCompany,
  deleteCompany,
  editCompany,
  getInterestedProductDropDownList,
} from "../../ESG/services/GraphFetch";
import AddUser from "./Adduser";
import { useNavigate } from "react-router-dom";

const override = css.bind`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function AdminPanel() {
  const [Isloaded, setisLoaded] = useState(false);
  const [countryList, setcountryList] = useState([]);
  const [Questions, setQuestion] = useState("what product are of interest?");
  const [interestedInValue, setinterestedInValue] = useState();
  const domainName = JSON.parse(localStorage.getItem("Name")).id.split("@")[1];
  const [tabOpened, setTabOpened] = useState(true);

  const navigate = useNavigate();


 

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  function AdminPanelMain() {
    const [value, setValue] = useState(0);

    return (
      <Grid
        container
        direction="column"
        bgcolor="#F5F5F6"
        style={{ height: "100vh" }}
      >
        <Grid item>
          <Typography
            style={{
              backgroundColor: "#F5F5F6",
              margin: "0",
              paddingLeft: "1.1em",
              paddingTop: "0.7em",
              fontSize: "1.3em",
              fontWeight: 650,
            }}
          >
            <ArrowBackIosIcon
                  style={{
                    marginRight: "0.2em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
            Admin
          </Typography>
        </Grid>

        <Grid item container>
          <Grid item xs={3}>
            <List sx={{ backgroundColor: "#F5F5F6",padding:"10px" }}>
              <ListItem  sx={{
                        fontSize: "16px",
                        color: "#25A392",
                        fontWeight: 600,
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                        borderRadius:"5px"
                    }}>
                <ListItemButton
                  disableTouchRipple
                  onClick={() => {
                    navigate("/AddCompany");
                  }}
                  className="ListItem"
                >
                   <ListItemIcon sx={{color: "#25A392"}}>
                    <BusinessCenterIcon fontSize="large"/>
                </ListItemIcon>
                  <ListItemText
                    primary={
                        "Company Management"
                    }
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: 600,
                      },
                    }}
                   
                  />
                </ListItemButton>
              </ListItem>
              <br></br>
              {/* <ListItem>
                <ListItemButton
                  disableTouchRipple
                  onClick={() => {
                    navigate("/ManageCompany");
                  }}
                  className="ListItem"
                >
                  <ListItemText
                    primary={
                      <Grid>
                        <img
                          src={require("../../images/42px_manage.svg").default}
                          alt=""
                          style={{
                            height: "17%",
                            width: "17%",
                            paddingLeft: "0.3em",
                            marginRight: "0.3em",
                            marginBottom: "0.2em",
                          }}
                        />
                        Manage Company
                      </Grid>
                    }
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "16px",
                        color: "#25A392",
                        paddingLeft: "5px",
                        fontWeight: 600,
                        padding: "1em",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem> */}
               <ListItem  sx={{
                        fontSize: "16px",
                        color: "#25A392",
                        fontWeight: 600,
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                        borderRadius:"5px"
                    }}>
                <ListItemButton
                  disableTouchRipple
                  onClick={() => {
                    navigate("/AddUser");
                  }}
                  className="ListItem"
                >
                  <ListItemIcon sx={{color: "#25A392"}}>
                    <PersonAddIcon fontSize="large"/>
                </ListItemIcon>
                  <ListItemText
                    primary={
                       "Users Management" 
                    }
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: 600,
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={9}>
            <Card
              sx={{
                marginTop: "1.25em",
                minHeight: "fit-content",
                width: "97%",
                padding: "2em",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center ",
                  justifyContent: "center",
                }}
              >
                <img
                  src={require("../../images/Admin_graphic.svg").default}
                  className=""
                  alt="..."
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  useEffect(() => {
    //fetching Countrylist
    const FetchCountryList = async () => {
      try {
        const country = await GetCountryList();
        setcountryList(country);
        // setisLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };

    const FtechUserintrestIems = async () => {
      try {
        const data = await getInterestedProductDropDownList();
        
        setinterestedInValue(data.data);
        setisLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };
    FtechUserintrestIems();
    FetchCountryList();
  }, []);
  return (
    <React.Fragment>
      {Isloaded ? (
        <AdminPanelMain />
      ) : (
        <Box sx={{ backgroundColor: "#F5F5F6", height: "100%" }} p={2}>
          <Grid className="loading-container text-center mt-5">
            <Typography className="ms-4">Loading....</Typography>
            <PropagateLoader
              css={override}
              size={15}
              color={"#3498db"}
              loading={true}
            />
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default AdminPanel;
