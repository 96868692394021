import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function FifthPage(props) {
  const [Question, SetQuestion] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [Answer, setAnswer] = useState();
  const [QuestionNumber, setQuestionNumber] = useState();

  const ThData = (TableData) => {
    // get table column
    const column = Object.keys(TableData[0]);
    //console.log(column);
    return column.map((data) => {
      if (data === "id" || data === "isNew") {
      } else {
        return <th key={data}>{data}</th>;
      }
    });
  };

  // get table row data
  const tdData = (TableData) => {
    // get table column
    const column = Object.keys(TableData[0]);
    // console.log(column);
    return TableData.map((data) => {
      return (
        <tr>
          {column.map((v) => {
            if (v === "id" || v === "isNew") {
            } else {
              return <td>{data[v]}</td>;
            }
          })}
        </tr>
      );
    });
  };
  useEffect(() => {
  const FetchData = () => {
    //getting section 1b question in single array
    const questions_array = [];
    const Answer_array = [];
    const QuestioNumArray = [];

    for (
      let i = 0;
      i < Object.keys(props.data.reportData["Section 1b-Item B"]).length;
      i++
    ) {
      questions_array.push(
        Object.keys(props.data.reportData["Section 1b-Item B"])[i]
      );
      Answer_array.push(
        Object.values(props.data.reportData["Section 1b-Item B"])[i].answer
      );
      QuestioNumArray.push(
        Object.values(props.data.reportData["Section 1b-Item B"])[i].qno.substr(
          5,
          Object.values(props.data.reportData["Section 1b-Item B"])[i].qno
            .length
        )
      );
    }

    console.log(Answer_array);
    SetQuestion(questions_array);
    setAnswer(Answer_array);
    setQuestionNumber(QuestioNumArray);
  };

 
    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Box>
          <Grid container paddingLeft={1}>
            <div>
              {Question.map((x, index) => {
                return (
                  <>
                    <div key={`question-${index}`}>
                    
                      <b> {QuestionNumber[index]}</b>
                      {"\u00A0"}
                      {"\u00A0"}
                      {x}
                      <br />

                      {index === 2 ? (
                        <ol>
                          {Object.keys(Answer[index]).map((itimes, index1) => {
                            return (
                              <li key={`${index}-${index1}`}>
                                {itimes}:
                                {Object.values(Answer[index])[index1].Amount}
                              </li>
                            );
                          })}
                        </ol>
                      ) : index === 0 ||
                        index === 1 ||
                        index === 8 ||
                        index === 9 ? (
                        <h6>
                          {Answer[index].Symbol.label}
                          {Answer[index].Amount}
                        </h6>
                      ) : index === 6 ? (
                        <h6>{Answer[index].Amount}</h6>
                      ) : index === 5 || index === 7 ? (
                        <table style={{ border: "1px solid black" }}>
                          <thead>
                            <tr style={{ border: "1px solid black" }}>
                              {ThData(Answer[index])}
                            </tr>
                          </thead>
                          <tbody>{tdData(Answer[index])}</tbody>
                        </table>
                      ) : (
                        <h6>{Answer[index]}</h6>
                      )}
                
                    </div>
                    <br />
                  </>
                );
                //}
              })}
            </div>
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default FifthPage;
