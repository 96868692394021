import  React,{useState} from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Button,Typography,Card, CardHeader, CardContent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Comments from './Comments';
const Drawer=({comment})=> {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div style={{ position: 'relative', padding: '0px', width: 'auto', marginLeft: '10px', marginRight: '10px',background: "#F5F5F6" }}>
    {expanded && (
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={handleExpandClick}></div>
    )}
    <Card style={{ position: 'absolute', bottom: 0, width: '100%', zIndex: 1000, padding: '0px',bottom: '0px',
            position: 'fixed',
            background: '#fff',
            width: '69%' }}>
      <CardHeader
        style={{ padding: '0px !important' }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px !important', }}>
            <Typography style={{ marginLeft: '10px' }} className="checklist">Comments</Typography>
            <Button onClick={handleExpandClick}>
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
          </div>
        }
      />
      {expanded && (
        <CardContent style={{ display: 'flex', flexDirection: 'column-reverse' }}>
         {comment}
        </CardContent>
      )}
    </Card>
  </div>
  );
}

export default Drawer