import React, { useEffect, useState,useRef } from "react";
import {
  Grid,
  LinearProgress,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  useMediaQuery,
Drawer,
Accordion,
AccordionSummary,
AccordionDetails,
Box
} from "@mui/material";
import { format } from "date-fns";
import Visibility from '@mui/icons-material/Visibility';
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import "../styles/Case_Managment/Case_Home.css";
import "../styles/Real_Time_Data/Real_Time_Data.css";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterPopover from "Compliance/CaseManagment/CaseFilter";
import ChildFilterPopover from "Compliance/CaseManagment/ChildCaseFilter";
import { useContext } from "react";
import NoteContext from "context/PageContext";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import Header from '../CaseManagment/Header.js'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Loader from "./Loader";
import Pagination from '@mui/material/Pagination';
import ParentCases from "Compliance/Components/ParentCases";
import CaseStatistics from "Compliance/Components/CaseStatistics";
import SelectedChildCase from "Compliance/Components/SelectedChildCase";
import ChildCases from "Compliance/Components/ChildCases";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ChatButton from "Insurance/CaseManagement/ChatButton";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
import ChatAccordian from "Insurance/CaseManagement/ChatAccordian";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import HorizontalAccordion from "Insurance/CaseManagement/HorizontalAccordian";
const AccordionContainer = styled(Box)(({ theme, expanded, width }) => ({
  width: expanded ? width : '50px',
  overflow: 'hidden',
  transition: 'width 0.3s ease-in-out',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

const AccordionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  width: '50px',
  height: '100%',
}));

const AccordionContent = styled(Box)(({ theme, expanded }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  whiteSpace: 'nowrap',
  transform: expanded ? 'translateX(0)' : 'translateX(100%)',
  transition: 'transform 0.3s ease-in-out',
  width: '100%',
}));

const CustomAccordion = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef(null);
  const [width, setWidth] = useState('200px');

  useEffect(() => {
    if (contentRef.current) {
      setWidth(`${contentRef.current.scrollWidth + 50}px`);
    }
  }, [contentRef.current]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <AccordionContainer expanded={expanded} width={width}>
      <AccordionContent ref={contentRef} expanded={expanded}>
        {children}
      </AccordionContent>
      <AccordionHeader onClick={handleToggle}>
        <Typography>{title}</Typography>
        <ExpandMore style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
      </AccordionHeader>
    </AccordionContainer>
  );
};
const theme = createTheme();
const Case_Home = ({ showSnackbar }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {caseType} = useTemplateType();
  const {
    getAllCases,
    getSubCases,

  } = require("../services/CaseFetch");

  const currentDate = new Date();
  const formattedDate = format(currentDate, "dd-MM-yyyy");
  const [hasCaseCreatorRole, setHasCaseCreatorRole] = useState();


  useEffect(() => {
    setHasCaseCreatorRole(
      ContextData?.CaseRole?.displayName === "Case Creator" ? true : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextData?.CaseRole?.displayName, hasCaseCreatorRole]);


  const [loading, setLoading] = useState(false);
  const [caseStatus, setcaseStatus] = useState([]);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [childanchorElFilter, setchildAnchorElFilter] = useState(null);

  const [statdata, setStatData] = useState([]);
  const [Info, setInfo] = useState([]);
  const [subcases, setSubcases] = useState([]);
  const [selectedSubCase, setselectedSubCase] = useState({ CaseId: '' });

  const [totalCount, setTotalCount] = useState([]);
  const [selectedCaseID, setSelectedCaseID] = useState()
  const [selectedType, setSelectedType] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubPage, setCurrentSubPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [openedCases, setOpenedCases] = useState(null);
  const [closedCases, setClosedCases] = useState(null);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const startSubIndex = (currentSubPage - 1) * itemsPerPage;
  const endSubIndex = startSubIndex + itemsPerPage;

    const userData = JSON.parse(localStorage.getItem("AccountDetails"));

    const [redirectLink, setRedirectLink] = useState("")
    const [backArrowVisible, setBackArrowVisible] = useState(false)
    const email=(userData?.endUser?.mail)?.split(".")[0].toLowerCase()


  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    fetchsubCasesData(Info[startIndex]?.CaseId || '', Info[startIndex]?.CaseType || '',Info[startIndex]?.label || '');
  };
  const handleSubPageChange = (event, page) => {
    setCurrentSubPage(page);
  };


  const isSmallScreen = useMediaQuery('(max-width:600px)');
  var graphstats = {
    chart: {
      width: function () {
        return window.innerWidth * 0.9; // Adjust the factor according to your preference
      },
      height: function () {
        return window.innerHeight * 0.7; // Adjust the factor according to your preference
      },
    },
    labels: ["Open", "Close"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              fontSize: '22px',
              fontFamily: 'Cambria, Cochin, Georgia, Times, "Brush Script", serif !important;',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              }
            },
          },
        },
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.labels[opts.seriesIndex]
      }
    },
    responsive: [{
      breakpoint: 100,
      options: {
        chart: {
          width: function () {
            return window.innerWidth * 0.9;
          },
          height: function () {
            return window.innerHeight * 0.7;
          },
        },
        legend: {
          show: false,
          position: 'bottom'
        }
      }
    }],
    colors: ["#F09A1F", "#25A392"],
  };

  const fetchData = async () => {
    // Fetch data from your API based on the current page
    try {
      setLoading(true);
      setInfo([]);
      const data = await getAllCases(ContextData.UserSelected, caseType,'');
      var res = data.data.values.reverse();

      if (res?.length > 0) {

        setInfo(res);
        setSelectedCaseID(res[0].CaseId)
        setSelectedType(res[0]?.label)
        await fetchsubCasesData(res[0].CaseId, res[0].CaseType,res[0]?.label)
        setOpenedCases(data.data.openCases);
        setClosedCases(data.data.closedCases);
        setStatData([data.data.openCases, data.data.closedCases])
      }
      else {
        setInfo([])
        setSelectedCaseID('')
        setSelectedType('')
        setselectedSubCase({ CaseId: '' })
        ContextData.setSelectedCaseType('')
        setSubcases([])
        //fetchsubCasesData([])
      }

      if (currentPage == 1) {
        setTotalCount(Math.ceil(data.data.totalCases / itemsPerPage));
        //const totalPages = Math.ceil(totalCount / itemsPerPage);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      showSnackbar("Error fetching data", "error")
      // Handle error if needed
    }
  };




  const fetchsubCasesData = async (caseid, casetype,labeltype) => {
    setLoading(true)
    try {
      setSelectedCaseID(caseid)
      ContextData.setSelectedCaseLabel(labeltype)
      ContextData.setSelectedCaseType(casetype)
      const data = await getSubCases(caseid);
      var res = data.data.values;
      setSubcases(res);
      setselectedSubCase(data.data.values[0] ? data.data.values[0] : { CaseId: '' })
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
      showSnackbar("Error in fetching data", "error")
      // Handle error if needed
    }
  };

const [title,setTitle]=useState("")
  useEffect(() => {


    fetchData();
      setBackArrowVisible(true)
      setRedirectLink("/")
     if(ContextData.UserSelected==="AllStateInsurance.com"){
      setTitle("Case Title")
     }
     else{
      setTitle("Case ID")
     }
    //return;
  }, [ContextData.UserSelected]);





  const handleApplyFilter = async (filterOptions) => {
    try {
      showSnackbar("Case table refeshing please wait..", "info");
      await getAllCases(ContextData.UserSelected, caseType,filterOptions).then((data) => {
        const parsedData = data?.data?.values.reverse()
        if (parsedData?.length > 0) {
          setInfo(parsedData);
          setSelectedCaseID(parsedData[0].CaseId)
          setSelectedType(parsedData[0]?.label)
          fetchsubCasesData(parsedData[0].CaseId, parsedData[0].CaseType,parsedData[0]?.label)
        }
        else {
          setInfo([])
          setSubcases([])
          setSelectedCaseID('')
          setSelectedType('')
          setselectedSubCase({ CaseId: '' })
          ContextData.setSelectedCaseType('')
          //fetchsubCasesData([])
        }
      });
      showSnackbar("Case table refeshed", "success");
    } catch (error) {
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true,
      });
      showSnackbar("Feching cases error", "error");
      setLoading(false);
    }
  };

  const handleApplyChildFilter = async (filterOptions) => {
    try {
      showSnackbar("Case table refeshing please wait..", "info");
      await getSubCases(selectedCaseID, filterOptions).then((data) => {
        const parsedData = data?.data?.values.map((obj) => {
          const parsedObj = { ...obj };
          parsedObj.hash_tag = obj.hash_tag ? JSON.parse(obj.hash_tag) : [];
          return parsedObj;
        });
        setSubcases(parsedData);
      });
      showSnackbar("Case table refeshed", "success");
    } catch (error) {
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true,
      });
      showSnackbar("Feching cases error", "error");
      setLoading(false);
    }
  };


  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorElFilter(false);

  };

  const handlechildFilterClick = (event) => {
    setchildAnchorElFilter(event.currentTarget);
  };

  const handlechildFilterClose = () => {
    setchildAnchorElFilter(false);

  };


  const ChildCasesWrapper = () => {
    return (
      <ChildCases title={title} data={subcases} setselectedSubCase={setselectedSubCase} selectedCaseID={selectedCaseID} ltype={selectedType}/>
    );
  };
  const [open,setOpen]=useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        {loading  && <Loader />}
        <Grid className="Case_Home">
          <Header title="Case Management" backarrow_visible={backArrowVisible} link={redirectLink} />
         {/* <Grid style={{display:'flex',justifyContent:'flex-end'}}>
          <IconButton onClick={toggleDrawer}>
        {open ?<ArrowForwardIcon><Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                Co Assist
              </Typography></ArrowForwardIcon> :<ArrowBackIcon><Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                Co Assist
              </Typography> </ArrowBackIcon> }
      </IconButton>
</Grid> */}
      {/* ChatButton displayed below the header */}
      {/* {open && (
        <div style={{ padding: '10px', backgroundColor: '#f0f0f0' }}>
          <ChatAccordian />
        </div>
      )}*/}
      <> 
 <HorizontalAccordion/>
 </>  
          <>

            <Grid className="rowContiner">
              <Grid className="cardbox parentCaseCard">
                <ParentCases title={title} Info={Info} setInfo={setInfo} selectedCaseID={selectedCaseID} setSelectedCaseID={setSelectedCaseID} fetchsubCasesData={fetchsubCasesData} setSelectedType={setSelectedType}/>
              </Grid>
              <Grid className="cardbox statsCard">
                <CaseStatistics graphstats={graphstats} statdata={statdata} openedCases={openedCases} closedCases={closedCases} />
              </Grid>
            </Grid>
            <Grid className="rowContiner">
              <Grid className="cardbox parentCaseCard">

                <ChildCasesWrapper key={JSON.stringify(subcases)} />
              </Grid>
              <Grid className="cardbox statsCard" >
                <Grid>
                  <Typography className="heading">Selected Item Info</Typography>
                </Grid>
                <SelectedChildCase selectedSubCase={selectedSubCase} />
              </Grid>
            </Grid>
    
          </>
        {/* <ChatButton/> */}
        </Grid>
      </React.Fragment>
    </ThemeProvider>
  );
};

export default withSnackbar(Case_Home, "Case Home", "success");
// export default Case_Home;

