import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getCaseDataForGraph } from "Compliance/services/CaseFetch";

const BarGraph = () => {
  const [isloaded, setisloaded] = useState(false);
  const [CaseName, setCaseName] = useState([]);
  const [CaseValues, setCaseValues] = useState([]);
  const fetchdataforGraph = async () => {
    try {
      let nameArray = [];
      let valuesArray = [];
      const data = await getCaseDataForGraph();
      valuesArray = [
        ...Object.values(data.data.caseStatus[0]),
        ...Object.values(data.data.casePriority[0]),
      ];
      nameArray = [
        ...Object.keys(data.data.caseStatus[0]),
        ...Object.keys(data.data.casePriority[0]),
      ];
      setCaseName(nameArray);
      setCaseValues(valuesArray);

      setisloaded(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchdataforGraph();
  }, []);
  // Define your data for the bar chart
  const options = {
    chart: {
      type: "bar",
      width: 300,
    },
    xaxis: {
      categories: CaseName,
    },
  };

  const series = [
    {
      name: "Series 1",
      data: CaseValues,
    },
  ];

  return (
    <div>
      {isloaded && (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={250}
        />
      )}
    </div>
  );
};

export default BarGraph;
