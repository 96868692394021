import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import {
  GetMaterialTopicsfortable,
  CreateGoals,
  getTableData,
} from "../services/GraphFetch";
import { Button } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';



 
function MaterialTopicTable(props) {
  
  const [Env, setEnv] = useState();
  const [Soc, setSoc] = useState();
  const [Gov, setGov] = useState();
  const [DisplayItem, setDisplayItem] = useState("Environmental");
  const [isloaded, setisloaded] = useState(false);
  const [Total, setTotal] = useState({
    EnvTotal: 0,
    SocailTotal: 0,
    GovrnTotal: 0,
  });
 
  //saving answer for EVN
  const [EsgEnv, setEsgEnv] = useState({});
  const [EsgSoc, setEsgSoc] = useState({});
  const [EsgGov, setEsgGov] = useState({});
 
  const FtechTabledata = async () => {
    try {
      const materilityForENV = await GetMaterialTopicsfortable("Environmental");
      const materilityforSOC = await GetMaterialTopicsfortable("Social");
      const materilityForGOV = await GetMaterialTopicsfortable("Governance");
 
      //console.log(materilityForENV);
      setEnv(materilityForENV.values);
      setGov(materilityForGOV.values);
      setSoc(materilityforSOC.values);
 
      const getENVdataset = await getTableData("Environmental", 2024);
      const getSOCdataset = await getTableData("Social", 2024);
      const getGOVdataset = await getTableData("Governance", 2024);
      if (Object.keys(getENVdataset).length > 0) {
        let ENVTopic = Object.keys(getENVdataset?.data?.properties).filter(
          (item) => item !== "pk" && item !== "response"
        );
        let total = 0;
        ENVTopic?.map((items, index) => {
       
          if( getENVdataset?.data?.properties[items][0].value !== "")
          {
                 total +=parseInt(getENVdataset?.data?.properties[items][0].value);
          }
         
          setEsgEnv((prevData) => ({
            ...prevData,
            [items]: getENVdataset?.data?.properties[items][0].value,
          }));
        });
        setTotal((prev) => {
          return { ...prev, EnvTotal: total };
        });
      }
 
      if (Object.keys(getSOCdataset).length > 0) {
        let SOCTopic = Object.keys(getSOCdataset?.data?.properties).filter(
          (item) => item !== "pk" && item !== "response"
        );
        let total = 0;
 
        SOCTopic?.map((items, index) => {
          if(getSOCdataset?.data?.properties[items][0].value !== "")
          {

            total += parseInt(getSOCdataset?.data?.properties[items][0].value);
          }
         
          setEsgSoc((prevData) => ({
            ...prevData,
            [items]: getSOCdataset?.data?.properties[items][0].value,
          }));
        });
        setTotal((prev) => {
          return { ...prev, SocailTotal: total };
        });
      }
 
      if (Object.keys(getGOVdataset).length > 0) {
        let GOVTopic = Object.keys(getGOVdataset?.data?.properties).filter(
          (item) => item !== "pk" && item !== "response"
        );
        let total = 0;
        GOVTopic?.map((items, index) => {
          if( getGOVdataset?.data?.properties[items][0].value !== "")
          {

            total += parseInt(getGOVdataset?.data?.properties[items][0].value);
          }
          setEsgGov((prevData) => ({
            ...prevData,
            [items]: getGOVdataset?.data?.properties[items][0].value,
          }));
        });
        setTotal((prev) => {
          return { ...prev, GovrnTotal: total };
        });
      }
 
      setisloaded(true);
    } catch (error) {
      console.error(error);
    }
  };
 
  useEffect(() => {
    FtechTabledata();
  }, []);
 
  const handleChange = (event, newAlignment) => {
    setDisplayItem(newAlignment);
  };
 
 
 
  const handleSave = async (topic) => {
    try {
      if (topic === "Environmental") {
         await CreateGoals(topic, EsgEnv);
         setDisplayItem("Social");
        
      } else if (topic === "Social") {
      await CreateGoals(topic, EsgSoc);
       setDisplayItem("Governance");
        
      } else if (topic === "Governance") {
         await CreateGoals(topic, EsgGov);
        
        props.setpage("Goals");

        
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  const FilterButtons = ({ FilterType, handleChange }) => {
    return (
      <ToggleButtonGroup
        className="toggleButtons"
        sx={{
          ".MuiToggleButtonGroup-grouped": {
            padding: "8px 18px",
            fontSize: "14px",
          },
        }}
        value={FilterType}
        exclusive
        onChange={handleChange}
        size="small"
      >
        <ToggleButton value="Environmental">Environment</ToggleButton>
        <ToggleButton value="Social">Social</ToggleButton>
        <ToggleButton value="Governance">Governance</ToggleButton>
      </ToggleButtonGroup>
    );
  };
 
  const HandleBackButton = () => {
    if (DisplayItem === "Governance") {
      setDisplayItem("Social");
    } else if (DisplayItem === "Social") {
      setDisplayItem("Environmental");
    }
  };
 
  return (
    <>
      {isloaded && (
        <Box p={2}>
          <Grid container direction={"column"}>
            <Grid item>
              <Typography
                sx={{ color: "#061826", fontSize: "18px", fontWeight: 600 }}
              >
                Materiallity
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "#B2B7BC", fontSize: "14px" }}>
                Please Provide Your Inputs For Goals
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} mt={2}>
            <Grid item>
              <FilterButtons
                FilterType={DisplayItem}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item>
              <Grid container direction={"row"} spacing={2}>
                <Grid item sx={{ marginRight: 5 }}>
                  <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                    Environment
                  </Typography>
                  <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                    {Total.EnvTotal === 100
                      ? "Completed"
                      : Total.EnvTotal === 0
                      ? "Not-Started"
                      : "In-Progress"}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginRight: 5 }}>
                  <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                    Social
                  </Typography>
                  <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                    {Total.SocailTotal === 100
                      ? "Completed"
                      : Total.EnvTotal === 0
                      ? "Not-Started"
                      : "In-Progress"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                    Governance
                  </Typography>
                  <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                    {Total.GovrnTotal === 100
                      ? "Completed"
                      : Total.EnvTotal === 0
                      ? "Not-Started"
                      : "In-Progress"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4} mt={1}>
            {DisplayItem === "Environmental" &&
              Env?.map((items, index) => {
                return (
                  <Grid item md={4} key={index}>
                    <Typography sx={{ color: "#58646E", fontSize: "14px" }}>
                      {items}
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      name={items}
                      size="small"
                      InputProps={{
                        endAdornment: "%", // Add "%" symbol as end adornment
                      }}
                      value={EsgEnv[items] || ""}
                    onChange={(e)=>{
                      if (e.target.value > 100 || e.target.value < 0) return;
                      let total = 0;
                      const newEsgEnv = { ...EsgEnv, [e.target.name]: e.target.value };
                      Object.values(newEsgEnv).map((res) => {
                        if(res.length!==0){
                          total += parseInt(res);
                        }    
                      });
                     
                     if(total <= 100){
                      setTotal((prev)=>{
                        return{...prev,EnvTotal:total}
                      })
                      setEsgEnv((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      });
                     }
                    }}
                      disabled={
                        (!EsgEnv.hasOwnProperty(items) ||
                        EsgEnv[items] === 0 || EsgEnv[items].length===0) &&
                        Total.EnvTotal >= 100
                      }
                    />
                  </Grid>
                );
              })}
            {DisplayItem === "Social" &&
              Soc?.map((items, index) => {
                return (
                  <Grid item md={4} key={index}>
                    <Typography sx={{ color: "#58646E", fontSize: "14px" }}>
                      {items}
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      name={items}
                      size="small"
                      // onInput={handleInput}
                      InputProps={{
                        endAdornment: "%", // Add "%" symbol as end adornment
                      }}
                      value={EsgSoc[items] || ""}
                      onChange={(e)=>{
                        if (e.target.value > 100 || e.target.value < 0) return;
                        let total = 0;
                        const NewSoc = { ...EsgSoc, [e.target.name]: e.target.value };
                        Object.values(NewSoc).map((res) => {
                          if(res.length!==0){
                            total += parseInt(res);
                          }    
                        });
                       
                       if(total <= 100){
                        setTotal((prev)=>{
                          return{...prev,SocailTotal:total}
                        })
                        setEsgSoc((prev) => {
                          return { ...prev, [e.target.name]: e.target.value };
                        });
                       }
                      }}
                        disabled={
                          (!EsgSoc.hasOwnProperty(items) ||
                          EsgSoc[items] === 0 || EsgSoc[items].length===0) &&
                          Total.SocailTotal >= 100
                        }
                    />
                  </Grid>
                );
              })}
            {DisplayItem === "Governance" &&
              Gov?.map((items, index) => {
                return (
                  <Grid item md={4} key={index}>
                    <Typography sx={{ color: "#58646E", fontSize: "14px" }}>
                      {items}
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      name={items}
                      size="small"
                      // onInput={handleInput}
                      InputProps={{
                        endAdornment: "%", // Add "%" symbol as end adornment
                      }}
                      value={EsgGov[items] || ""}
                      onChange={(e)=>{
                        if (e.target.value > 100 || e.target.value < 0) return;
                        let total = 0;
                        const NewGov = { ...EsgGov, [e.target.name]: e.target.value };
                        Object.values(NewGov).map((res) => {
                          if(res.length!==0){
                            total += parseInt(res);
                          }    
                        });
                       
                       if(total <= 100){
                        setTotal((prev)=>{
                          return{...prev,GovrnTotal:total}
                        })
                        setEsgGov((prev) => {
                          return { ...prev, [e.target.name]: e.target.value };
                        });
                       }
                      }}
                        disabled={
                          (!EsgGov.hasOwnProperty(items) ||
                          EsgGov[items] === 0 || EsgGov[items].length===0) &&
                          Total.GovrnTotal >= 100
                        }
                    />
                  </Grid>
                );
              })}
          </Grid>
          <Box sx={{ backgroundColor: "#E9F6F4 " }} p={2} mt={2}>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography>Total value of {"Governces"} Goal</Typography>
              </Grid>
              <Grid item>
             
                <LinearProgress
                  variant="determinate"
                  value={
                    (DisplayItem === "Environmental"
                      ? Total.EnvTotal/100
                      : DisplayItem === "Social"
                      ? Total.SocailTotal/100
                      : Total.GovrnTotal / 100) * 100
                  }
                  color="primary"
                  style={{ width: 100 }}
                />
                
                </Grid>
                <Grid item>
                <Typography>
                  {DisplayItem === "Environmental"
                    ? Total.EnvTotal
                    : DisplayItem === "Social"
                    ? Total.SocailTotal
                    : Total.GovrnTotal}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container justifyContent={"space-between"} my={1}>
              <Grid item>
                <Button
                  sx={{
                    backgroundColor: "#DFE1E3",
                    border: "1px solid #CBE5E1",
                    textTransform: "capitalize",
                    borderRadius: "6px",
                    paddingX: "10px",
                    color: "#8C959B",
                  }}
                  onClick={HandleBackButton}
                  disabled={DisplayItem === "Environmental"}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    backgroundColor: "white",
                    marginLeft: "5px",
                    color: "#B2B7BC",
                    borderRdius: "6px",
                    textTransform: "capitalize",
                    border: "1px solid #DFE1E3",
                  }}
                  onClick={() => {
                    if (DisplayItem === "Environmental") {
                      setDisplayItem("Social");
                    } else if (DisplayItem === "Social") {
                      setDisplayItem("Governance");
                    }
                  }}
                  disabled={DisplayItem === "Governance"}
                >
                  Skip
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#25A392",
                    marginLeft: "5px",
                    color: "white",
                    borderRdius: "6px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    handleSave(DisplayItem);
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
}
 
export default MaterialTopicTable;