import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";


function SecondPage(props) {
  const [Questions, SetQuestions] = useState();
  const [Answer, setAnswer] = useState();

  const [Isloading, setIsloading] = useState(false);
  const [QuestionNumber, setQuestionNumber] = useState();

  useEffect(() => {
    // FetchQuestionNum();
    const Answer_array = [];
    const questions_array = [];
    const QuestioNumArray = [];

    for (
      let i = 0;
      i < Object.keys(props.data.reportData["Section 1a-Item 0"]).length;
      i++
    ) {
      questions_array.push(
        Object.keys(props.data.reportData["Section 1a-Item 0"])[i]
      );
      Answer_array.push(
        Object.values(props.data.reportData["Section 1a-Item 0"])[i].answer
      );
      QuestioNumArray.push(
        Object.values(props.data.reportData["Section 1a-Item 0"])[i].qno.substr(
          5,
          Object.values(props.data.reportData["Section 1a-Item 0"])[i].qno
            .length
        )
      );
    }

    SetQuestions(questions_array);
    setAnswer(Answer_array);
    setQuestionNumber(QuestioNumArray);
   // console.log(questions_array);
    console.log(Answer_array);
    setIsloading(true);
  }, [props]);

  return (
    //     <div>
    //         {Isloading &&
    //         <Box>
    //         <Grid container paddingLeft={1}>
    //         <div>

    //      {Questions.map((x,index)=>{
    //         return (
    //         <>
    //          <div key= {index}><b>{QuestionNumber[index]}</b>{'\u00A0'}{'\u00A0'}{x}
    //           <br/>
    //          {/* {Answer[index]} */}
    //           </div>
    //           <br/>
    //        </>
    //        )
    //      })}

    //         </div>
    //         </Grid>
    //         </Box>
    //         }

    //     </div>
    //   )

    <React.Fragment>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ bgcolor: "lightgray" }}>
            <ul style={{ listStyleType: "square", textAlign: "left" }}>
              <li style={{ color: "Black", fontSize: "20px" }}>
                <b>Section 1a</b>
              </li>
              <li>Section 1b</li>
              <li>Section 1c</li>
              <li>Section 2a</li>
              <li>Section 2b</li>
              <li>Section 3</li>
              <li>Section 4</li>
              <li>Section 5</li>
            </ul>
          </Grid>

          {/* <Grid item md={10} container> */}
          <Grid item xs={12} md={9}>
            <div>
              {Questions?.map((x, index) => {
                return (
                  <React.Fragment key={index}>
                    <div  style={{ width: "160vh" }}>
                      <b>{QuestionNumber[index]}</b>
                      {"\u00A0"}
                      {"\u00A0"}
                      {x}
                      <br />
                    </div>
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          </Grid>
        </Grid>

        {/* </Grid> */}

        {/* </Box> */}
      </Box>
    </React.Fragment>
  );
}

export default SecondPage;
