import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Button, Grid, Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {
  FetchGetData,
  fetchEditIcon,
  editSection,
} from "../services/FormCreationFetch";
import NoteContext from "context/PageContext";

const EditSection = (props) => {
  const contextData = useContext(NoteContext);
  let session_values = JSON.parse(sessionStorage.getItem("section"));
  const [formValues, setFormValues] = useState([{ session_values }]);
  // eslint-disable-next-line no-unused-vars

  const [sectionName, setSectionName] = useState(props.secName);
  const [sectionId, setSectionId] = useState(props.secId);
  const [sectiondescription, setSectionDescription] = useState(props.secDis);
  const [sectionhelpertext, setSectionHelpertext] = useState(props.secHelp);

  const copyItem = formValues;
  const [open, setOpen] = useState(props.open);
  let disclosurename = contextData.disclosureName;

  const handleClose = () => {
    setOpen(false);
    // setIconEDit(false);
    // console.log("Cancel the form")
  };

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const fetchEditData = async (disclosurename) => {
    await FetchGetData(disclosurename).then((data) => {
      // console.log("Data", data);
      setFormValues(copyItem);
    });
  };

  // const handleEditIcon =()=>{
  //   fetchEditData()
  //   setIconEDit(true)
  // }

  const handleEditIcon = () => {
    fetchEditData(disclosurename);
    // setIconEDit(true)
    setOpen(true);
    //console.log('Edit is hitting')
  };
  const handleSave = async () => {
    // console.log("page is hitting")
    await fetchEditIcon(disclosurename, formValues).then((data) => {
      //   console.log(data)
      //    //setExisting_disclosure(data._items[0]);
    });
  };

  const handleEditSection = async () => {
    let disclosureId;
    if (contextData.disclosureName) {
      disclosureId = contextData.disclosureName;
    } else {
      disclosureId = JSON.parse(localStorage.getItem("disclosurename"));
    }
    await editSection(
      disclosurename,
      sectionName,
      sectionId,
      sectiondescription,
      sectionhelpertext
    ).then(() => {
      props.handleClose();
      contextData.setReloadData(true);
    });
  };

  return (
    <div>
      <Dialog open={open} sx={{ color: "white" }}>
        <DialogTitle id="alert-dialog-title">Edit section hello</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="sectionname-input"
                name="sectionname"
                label="Section Name"
                type="text"
                value={sectionName}
                onChange={(e) => setSectionName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              {sectionId && 
              <TextField
                fullWidth
                id="sectionid-input"
                name="sectionid"
                label="section id"
                type="text"
                required
                value={sectionId}
                onChange={(e) => setSectionId(e.target.value)}
                disabled
                focused
              />
              }
            </Grid>
            {/* </Grid>
          <Grid container spacing={2}> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="sectiondescription-input"
                name="sectiondescription"
               label="section description"
                type="text"
                required
                value={sectiondescription || ''}
                onChange={(e) => setSectionDescription(e.target.value)}
              />
            </Grid>
            {/* </Grid>
          <Grid container spacing={2}> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="sectionhelpertext-input"
                name="sectionhelpertext"
                label="section helpertext"
                type="text"
                required
                value={sectionhelpertext || ''}
                onChange={(e) => setSectionHelpertext(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={editIcon?handleSave:handleAddSection}>{editIcon?"Save":"Create"}</Button>
        </DialogActions> */}
        <DialogActions style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25a392",
              marginLeft: "10px",
              flexDirection: "row",
            }}
            onClick={handleEditSection}
          >
            Save
          </Button>
          <Button
            variant="text"
            style={{ color: "red", flexDirection: "row" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditSection;
