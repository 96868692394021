import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

function FifthPage(props) {
  const [Question, SetQuestion] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [Question1, setQuestion1] = useState();
  const [QuestionNumber, setQuestionNumber] = useState();

  //--------------Creating table header
  const CreateHeader1 = () => {
    const column = Object.keys(Question1[0]);
    const newcolumn = column.map((x) => {
      if (x !== "id" && x !== "isNew") {
        return <TableCell key={x}>{x}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  const tdData1 = () => {
    return Question1.map((data, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question1[0]).map((x,index) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell component="th" scope="row" key={index}>
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };

  useEffect(() => {
    const FetchData = () => {
      const questions_array = [];
      const QuestioNumArray = [];
      for (
        let i = 0;
        i <
        Object.keys(props.data.reportData["Amendments to Schedules A and B"])
          .length;
        i++
      ) {
        questions_array.push(
          Object.keys(props.data.reportData["Amendments to Schedules A and B"])[
            i
          ]
        );
        QuestioNumArray.push(
          Object.values(
            props.data.reportData["Amendments to Schedules A and B"]
          )[i].qno.substr(
            4,
            Object.values(
              props.data.reportData["Amendments to Schedules A and B"]
            )[i].qno.length
          )
        );
      }
      //console.log(questions_array);
      SetQuestion(questions_array);
      setQuestionNumber(QuestioNumArray);
      setQuestion1(
        Object.values(
          props.data.reportData["Amendments to Schedules A and B"]
        )[0].answer
      );
     
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  bgcolor: "lightgray",
                  position: "sticky",
                  top: "0",
                  padding: "2px",
                  height: "100",
                }}
              >
                <ul
                  style={{
                    listStyleType: "square",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  <li>Direct Owners</li>
                  <li>Executive Officers</li>
                </ul>
              </Grid>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                <div>
                  {Question.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        <b>{QuestionNumber[index]}</b>
                        {"\u00A0"}
                        {"\u00A0"}
                        {x}
                        <div
                          style={{ justifyContent: "right", display: "flex" }}
                        >
                          <TableContainer>
                            <Table
                              sx={{ minWidth: 650, width: "70%" }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 1,
                                    },
                                  }}
                                >
                                  {CreateHeader1()}
                                </TableRow>
                              </TableHead>

                              {tdData1()}
                            </Table>
                          </TableContainer>
                        </div>
                        <br />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default FifthPage;

