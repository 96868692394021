import React, { useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import "../ESG/styles/Notification/Notification.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useContext } from "react";
import ImageUrl from "../images/no notification.jpg"
import { GetNotification, UpdateNotification } from "ESG/services/Fetch"; //esg compliance insurance
import {
  getNotification,
  UpdateCaseNotification,
  GetCaseNotification,
} from "Compliance/services/CaseFetch"; //case management
import NoteContext from "../context/PageContext";
import Loader from "Compliance/CaseManagment/Loader";
import withSnackbar from "./Alert/withSnackbar";

import { useRoleContext } from "context/RoleContex";

const Notification = ({
  handleClick,
  handleClose,
  openNotif,
  anchorE,
  id,
  showSnackbar
}) => {
  const ContextData = useContext(NoteContext);
  const [Notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Reload, setReload] = useState(true);
  const [ID, setID] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { selectedRole, puslishTriggered } = useRoleContext();
  const [selectedIndex, setSelectedIndex] = useState([]);

  const [selctedIds, setSelectedIds] = useState([])



  useEffect(() => {
    if (ContextData?.CaseRole?.id) {
      var data = {
        userRoleId: ContextData?.CaseRole?.id,
        domain: ContextData?.UserSelected
      }
      FetchApi(data)  //{id:"",domain:""}
    }
  }, [ContextData]);

  useEffect(() => {
    CheckUtilityNotification()

  }, [selectedRole])

  useEffect(() => {
    if (puslishTriggered) {
      CheckUtilityNotification()
    }

  }, [puslishTriggered])

  const CheckUtilityNotification = () => {
    if (window.location.pathname !== "/caseManagement") {
      if (selectedRole && localStorage.getItem("AccountDetails")) {
        const userData = JSON.parse(localStorage.getItem("AccountDetails"));
        if (userData?.filingGroups) {
          const selectedGroup = userData?.filingGroups.filter(data => { return data.displayName === selectedRole })[0];
          console.log(selectedGroup, "selectedGroup");
          if(selectedGroup && selectedGroup?.id)
          {
            UtilityNotification(selectedGroup.id)
          }
        }
      }
    }
  }

  const UtilityNotification = async (id) => {
    try {
      const res = await GetNotification(id)
      if (res?.notifications) {
        console.log("res56", res.notifications);
        setNotifications(res.notifications)
        console.log(Notifications);

      }
      else {
        setNotifications([])

      }

    }
    catch (e) {
      setNotifications([])
    }
  }

  useEffect(() => {
    console.log("not", Notifications);
  }, [Notifications])

  const FetchApi = async (data) => {
    try {
      const res = await GetCaseNotification(data)
      if (res?.data?.notifications?.message == "No new notifications") {
        setLoading(true)
        setNotifications([])
        setLoading(false)
      }
      //(!res?.data?.notifications?.message)
      else {
        setLoading(true)
        setNotifications(res?.data?.notifications)
        setLoading(false)
      }

    }
    catch (e) {
      showSnackbar(e?.response?.data?.message || "error in updating notifications", "error")
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      //if (window.location.pathname === "/caseManagement") {
      try {
        // if (ContextData?.CaseRole?.id) {
        // Check if CaseRole?.id is present
        // const notification = await getNotification('amit@gmail.com');
        //console.log("data.data",data.data.data)
        // setNotifications(notification.data.data);
        //}
        setLoading(false);
      } catch (error) {
        console.error(error);
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true,
        });
        setLoading(false);
      }
      // } 
      // else {
      //   try {
      //     const data = await GetNotification(ContextData?.CaseRole?.id);
      //     setNotifications(data || []);
      //     setLoading(false);
      //   } catch (error) {
      //     ContextData.setUserLogInError({
      //       message: error.message,
      //       Errors: true,
      //     });
      //     console.error(error);
      //     setLoading(false);
      //   }
      // }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //  Reload,
    //window.location.pathname,
    //Notifications === undefined,
    // openNotif,
    // ContextData?.CaseRole,
  ]);

  const update_Read_UnRead = () => {
    // setLoading(true); // Set loading to true before updating data
    const idsData = Notifications.filter((data, index) => selectedIndex.includes(index)).map(data => data.id);
    console.log("idsData", idsData);
    const Update_IsRead = async () => {
      if (
        window.location.pathname === "/caseManagement" ||
        window.location.pathname === "/editcase" ||
        window.location.pathname === "/viewCase" ||
        window.location.pathname === "/mytasks" ||
        window.location.pathname === "/viewallchildcases" ||
        window.location.pathname === "/createcase" ||
        window.location.pathname === "/viewallcases" ||
        window.location.pathname === "/allsubcases"
      ) {
        try {
          setLoading(true);
          const res = await UpdateCaseNotification(ContextData?.CaseRole?.id, idsData);
          if(res) {
            FetchApi({
              userRoleId: ContextData?.CaseRole?.id,
              domain: ContextData?.UserSelected
            })
            setSelectedIndex([])
          }
          setLoading(false);
        } catch (error) {
          ContextData.setUserLogInError({
            message: error.message,
            Errors: true,
          });
          console.error(error);
          setLoading(false);
        }
      } 
      else {
        try {
          if (selectedRole && localStorage.getItem("AccountDetails")) {
            const userData = JSON.parse(localStorage.getItem("AccountDetails"));
            if (userData?.filingGroups) {
              const selectedGroup = userData?.filingGroups.filter(data => { return data.displayName == selectedRole })[0];
              console.log(selectedGroup, "selectedGroup");
              const res = await UpdateNotification(idsData, selectedGroup.id);
              if(res){
                UtilityNotification(selectedGroup.id)
                setSelectedIndex([])
              }
            }
          }
        } catch (error) {
          ContextData.setUserLogInError({
            message: error.message,
            Errors: true,
          });
          console.error(error);
          setLoading(false);
        }
      }
    };
    Update_IsRead();
    //setReload(!Reload);
    setID([]);

  };

  const UpdatedIRsRead_handler = (event) => {
    const id = event.target.value;
    const isRead = event.target.checked;

    if (isRead === true) {
      setID((ID) => [...ID, id]);
    } else {
      setID(ID.filter((ID) => ID !== event.target.value));
    }
  };
  //Handle Selecet All
  const handleSelectAll = () => {
    if (!selectAll) {
      const allNotificationIds = Notifications.map(
        (item) => item.id
      );
      setID(allNotificationIds);
    } else {
      setID([]);
    }
    setSelectAll(!selectAll);
  };

  // Function to refresh the component without page reload
  const refreshComponent = () => {
    //setReload(!Reload);
  };

  const handleCheckboxChange = (event, index) => {

    console.log(index, "index");
    if (event.target.checked) {
      setSelectedIndex((prevIndexes) => [...prevIndexes, index]);
    }
    else {
      setSelectedIndex((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
  };

  return (
    <div>
      <IconButton
        aria-label="Notifications"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge
          sx={{ color: "success" }}
          badgeContent={Notifications.filter(notification => notification.isRead === false).length}
          //badgeContent={2} 
          color="secondary"
        >
          <NotificationsIcon style={{ color: "black" }} />
        </Badge>
      </IconButton>
      {anchorE && (
        <Popover
          id={id}
          open={openNotif}
          className="notifications-custom-popover"
          anchorEl={anchorE}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box className="notification-main-box">
            {loading ? (
              <React.Fragment>
                <Card className="Skeleton_CardMain">
                  <CardHeader
                    subheader={"loading please wait..."}
                    title={<Skeleton className="Skeleton_Subtitle" />}
                  />
                  <CardContent>
                    <React.Fragment>
                      <Skeleton className="Skeleton_Content" />
                      <Skeleton className="Skeleton_Content" />
                    </React.Fragment>
                  </CardContent>
                </Card>
              </React.Fragment>
              // <Loader/>
            ) :
              // Notifications?.notifications.message ===
              //   "No new notifications" ? (
              //   <Typography className="Title_Notifications" variant="h6">
              //     {Notifications?.status}
              //   </Typography>
              // ) :
              (
                <>
                  <Box className="Main_BoX_Notification">
                    <Grid container alignItems="center">
                      <Grid item xs={6} md={6}>
                        <Typography className="Title_Notifications" variant="h6">
                          Notifications
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} textAlign="end">
                        {selectAll === true && <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          }
                          label="Select All"
                        />}
                      </Grid>
                    </Grid>
                    {Notifications.filter(notification => notification.isRead === false).length <= 0 ?
                      <Card>
                        <CardMedia component="img" height="194" image={ImageUrl} alt="No Notification" />
                        <Typography variant="body2" color="text.secondary" style={{ textAlign: "center" }}>
                          No notifications yet. Click the button below to reload.
                        </Typography>
                        <Button
                          variant="outlined"
                          //  startIcon={<RefreshIcon />}
                          onClick={() => {
                            FetchApi({
                              userRoleId: ContextData?.CaseRole?.id,
                              domain: ContextData?.UserSelected
                            })
                          }}
                          style={{ marginTop: "10px" }}
                        >
                          Reload
                        </Button>
                      </Card>
                      : Notifications &&
                      Notifications
                        .map((item, index) => (
                          item.isRead === false && (
                            <Card
                              style={{ margin: '10px' }}
                              key={index}
                            //className="Main_Card"
                            // className={
                            //   item.status=='read'
                            //     ? "read-notifications"
                            //     : "not-read-notifications"
                            // }
                            >
                              <CardHeader
                                style={{ padding: '0px 0px 0px 10px' }}
                                // className={
                                //   item.status=='read'
                                //     ? "read-notifications"
                                //     : "not-read-notifications"
                                // }

                                action={
                                  item?.isRead === true ? (
                                    ""
                                  ) : (
                                    <FormControlLabel
                                      title="Mark as Read/UnRead"
                                      control={
                                        <Checkbox
                                          checked={selectedIndex.includes(index)}
                                          onChange={(event) => { handleCheckboxChange(event, index) }}
                                        />
                                      }
                                      className="MuiSvgIcon-root icon-size-notifications"
                                      value={item.id || null}
                                    />
                                  )
                                }

                                subheader={`Date:${item.date || null}`}
                              />
                              <CardContent>
                                <Typography className="Body_Containt">
                                  {item.message || null}
                                </Typography>
                              </CardContent>
                            </Card>

                          )
                        ))
                    }
                  </Box>
                  <Grid container className="Grid_Mark_Button">
                    <Grid item xs={6}>
                      <Button
                        className={`Mark_As_Read_Button ${ID.length > 0 ? "active" : ""
                          }`}
                        disabled={selectedIndex.length > 0 ? false : true}
                        variant="contained"
                        onClick={() => {
                          update_Read_UnRead();
                          //  refreshComponent(); // Call refreshComponent after updating the notifications
                        }}
                      >
                        Mark as Read
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
          </Box>
        </Popover>
      )}
    </div>
  );
}

export default withSnackbar(Notification, "Notification", "success");