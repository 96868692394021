import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const Remediate = (props)  =>{
    const [remediation,setremediation] = useState()
    const [grievance,setgrievance] = useState()
    const [contributed,setcontributed] = useState()
    const [feedback,setfeedback]=useState()
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
        setremediation((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[0]).answer)
        setgrievance((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[1]).answer)
        setcontributed((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[2]).answer)
        setfeedback((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[4]).answer)
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5 className="underline">Processes to remediate negative impacts</h5>
                        <h6><b>Describe its commitments to provide for or cooperate in the remediation of negative impacts that the organization identifies it has caused or contributed to.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:remediation}}></div>
                        <h6><b>Describe its approach to identifying and addressing grievances, including the grievance mechanisms that the organization has established or participates in.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:grievance}}></div>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h6><b>Describe other processes by which the organization provides for or cooperates in the remediation of negative impacts that it identifies it has caused or contributed to.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:contributed}}></div>
                        <h6><b>Describe how the organization tracks the effectiveness of the grievance mechanisms and other remediation processes, and report
                             examples of their effectiveness, including stakeholder feedback.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:feedback}}></div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default Remediate;