import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Typography, IconButton } from "@mui/material";
import { CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from "@mui/material/styles";
import "../styles/Real_Time_Data/Real_Time_Data.css";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import { useContext } from "react";
import NoteContext from "context/PageContext";
// import ObjectViewer from "./Product";
//Funtion for dynamicly display nested object key and value 
function getObjectKeysAndValues(obj, prefix = '') {
  const keysAndValues = [];
  for (const key in obj) {
    if (key === 'details') {
      let value
      if (obj.hasOwnProperty(key)) {
        obj[key] = ""
        if (obj[key] === "") {
          value = {}
        } else if (obj[key] === "{}" && obj[key].length > 1) {
          value = JSON.parse(obj[key])
        } else {
          value = (JSON.parse(obj[key].length < 0) ? JSON.parse(obj[key]) : obj[key])
        }
        // const value = (JSON.parse(obj[key].length>0)?JSON.parse(obj[key]):obj[key]) ;
        const newPrefix = prefix ? `${key}` : key;

        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }
    else {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        // const newPrefix = prefix ? `${prefix}-->${key}` : key;
        const newPrefix = prefix ? `${prefix}->${key}` : key;
        if (typeof value === 'object') {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }
  }
  return keysAndValues;
}
//Funtion for dynamicly display nested object key and value 
function ObjectViewer(props) {
  const { data, val } = props;
  const keysAndValues = getObjectKeysAndValues(data);
  return (
    <React.Fragment>

      {val && keysAndValues.slice(...val).map(({ key, value }) => (
        <li key={key}>
          <strong>{key}: </strong>
          {value}
        </li>
      ))}

    </React.Fragment>
  );
}
// funtion Dynamic card display
export function DynamicCard(props) {
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <React.Fragment>
      <Card className="CompanyDetails_Card">
        <CardContent className="CardContent">
          <Typography className="Table_Head_card" sx={{backgroundColor:props.ExpandMore_card_data.props.data?.portfolio !==null?'#000080':'#6200ea'}} >
            {props.Card_header}
          </Typography>
          {props.Main_card_data}
        </CardContent>
        <CardActions disableSpacing>
          <ExpandMore
            className="ExpandMore"
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" className="Collapse_More" unmountOnExit>
          <CardContent>
            {props.ExpandMore_card_data}
          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  )
}

const columns = [
  { id: 'Area', label: 'Id', minWidth: '50px', align: 'left', },
  { id: 'Catagary', label: 'Collateraltype', minWidth: '50px', align: 'left', },
  { id: 'Code', label: 'Quantity', minWidth: '50px', align: 'left', },
  { id: 'unit', label: 'Unit', minWidth: '50px', align: 'left', },
  { id: 'quality', label: 'Quality', minWidth: '50px', align: 'left', },
  { id: 'time', label: 'Time', minWidth: '50px', align: 'left', },
  { id: 'Status', label: 'Reporting Status', minWidth: '50px', align: 'left', },

];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

 function CollateralsData({ Search, Refresh,showSnackbar }) {
  const ContextData = useContext(NoteContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [TotalPage, setTotalPage] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [tempData, setTempData] = useState();
  const [CollateralsData, Setcollaterals] = useState([]);
  const [CollateralsTrades, setcollateralsTrades] = useState();
  const [Details, setDetails] = useState();
  const { Getcollaterals,GetcollateralsTrades } = require("../../ESG/services/Fetch");
  const [expanded, setExpanded] = useState(false);
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchCollaterals = async () => {
      try {
        showSnackbar("loadng data please wait..", "info");
        const data = await Getcollaterals(page, rowsPerPage);
        setTempData(data?.collaterals);
        if (CollateralsData?.length > 0) {
          Setcollaterals([...CollateralsData, ...tempData]);
        } else {
          Setcollaterals(data?.collaterals);
        }
        setTotalPage(data?.rows);
        showSnackbar("Data loaded successfully", "success");
      } catch (error) {
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        console.error("Error fetching collaterals:", error);
        showSnackbar("Error loading data. Please try again.", "error");
      }
    };

    fetchCollaterals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, Refresh]);

  
  useEffect(() => {
    const fetchCollateralsTradesDetails = async () => {
      try {
        showSnackbar("Loading data, please wait...", "info");
        const data = await GetcollateralsTrades(Details?.id);
        setcollateralsTrades(data?.trades);
        showSnackbar("Data loaded successfully", "success");
      } catch (error) {
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        console.error("Error fetching collaterals trades:", error);
        showSnackbar("Error loading data. Please try again.", "error");
      }
    };

    Details && fetchCollateralsTradesDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Details]);

  // handle Company Details in Card
  const handleUpdate = (row, i) => {
    setDetails(row)
    setSelectedRowIndex(i)
  }

  //pagination for table handle change page 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //pagination for table handle Change Rows Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Grid container spacing={0} >
      <Grid item xs={8}>
        <TableContainer className="TableContainer">
          <Table stickyHeader aria-label="sticky table" >
            <TableHead >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="Table_Head"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {CollateralsData && CollateralsData.filter((val) => {
                if (Search === "" || Search === null) {
                  return val;
                } else if (
                  val.mylei
                    .toLowerCase()
                    .includes(Search.trim().toLowerCase())
                ) {
                  return val;
                }
              }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                <TableRow
                  style={{ backgroundColor: i === selectedRowIndex ? '#c4c4ff' : '' }}
                  onClick={() => handleUpdate(row, i)}
                  key={i}
                  className={i % 2 === 0 ? "TableRowOdd" : 'TableRowEven'}
                >
                  <TableCell component="th" scope="row" >{row?.id ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.collateraltype ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.quantity ?? 'null'}</TableCell>
                  <TableCell align="left">{row?.unit ?? 'null'}</TableCell>
                  <TableCell align="left">{row?.quality ?? 'null'}</TableCell>
                  <TableCell align="left">{row?.txtime ?? 'undefined'}</TableCell>
                  <TableCell align="left">{row?.reportingstatus ?? 'undefined'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={Math.floor(TotalPage)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <Grid className="Card_Section" item xs={4}>
        {Details && Details ? <>
          <Typography className="More_Details">{Details.providerortaker}</Typography>
          <Card className="CompanyDetails_Card">
            <CardContent className="CardContent">
              <ObjectViewer data={Details} val={[0]} />
            </CardContent>
          </Card>
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 6, sm: 9, md: 12, lg: 12 }} elevation={15} >
          {CollateralsTrades?.length>0 && CollateralsTrades.map((details, i) => (

            <Grid key={i} item xs={6} sm={4.5} md={6} lg={6}  >
              <DynamicCard
                key={i}
                Card_header={`Trades :${i+1}`}
                ExpandMore_card_data={<ObjectViewer data={details} val={[3]} />}
                Main_card_data={<ObjectViewer data={details} val={[0, 3]} />}
              />
            </Grid>
          ))}</Grid>
        </> : ''}
      </Grid>
    </Grid>
  );
}

export default withSnackbar(CollateralsData, "CollateralsData paage", "success");