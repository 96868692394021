import React, { useState } from 'react';
import {
    Button,
    InputLabel,
    TextField
  } from "@mui/material";
  import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ReasonDialog=({ open, onClose, onConfirm })=> {
    const [reason, setReason] = useState('');
  
    const handleConfirmReason = () => {
      onConfirm(reason);
      onClose();
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Enter Reason</DialogTitle>
        <DialogContent>
            <InputLabel>Reason</InputLabel>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmReason} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default ReasonDialog;
  