import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";

function RDB1(props) {
  const [isloading, setisloading] = useState(false);
  const [portfolios, setportfolios] = useState();
    const [production, setproduction] = useState();

  useEffect(() => {
    // console.log("hello",(Object.values(props.data.reportData["R.Disclosure A"])));
      setproduction(Object.values(props.data.reportData["R.Disclosure B"])[4].answer);
    setportfolios(Object.values(props.data.reportData["R.Disclosure A"])[5].answer);
      // console.log(Object.values(props.data.reportData["S.Disclosure B"]));
    setisloading(true);

    //CreateHeader();
  }, [isloading,props.data.reportData]);

  return (
    <React.Fragment>
      {isloading && (
        <>
          
            <Grid container spacing={1} className="border2">
            <Grid item xs={12} md={2} className="border2" >
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                    <li>Strategy</li>
                    <li style={{color:"#373737",fontSize:"20px"}}><b>Risk Management</b></li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#373737"}} className="border2">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Risk Management</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the organization’s processes for identifying and 
                                        assessing climate-related risks.</p>
                  </Grid>
              <Grid item xs={12} md={6} className="grid1">
              <h4 className="underline">R.Disclosure B</h4>
                <h5><b>Asset owners should describe how they consider the positioning of their total
                     portfolio with respect to the transition to a low-carbon energy supply, production, and use.</b></h5>
                <div dangerouslySetInnerHTML={{ __html: production }}></div>
                 </Grid>
                 <Grid item xs={12} md={6} className="grid2">
                <h5><b>Explain how asset owners actively manage their portfolios positioning in relation to this transition.</b></h5>
                <div dangerouslySetInnerHTML={{ __html: portfolios }}></div>
                 </Grid>
              </Grid> 
            </Grid>
        </>
      )}
   </React.Fragment>
  );
}

export default RDB1;



