export const INSURANCE = "Insurance";
export const ESG = "ESG";
export const KYC = "KYC";
export const USERNAME="ankurjit"
export const CASECREATOR="Case Creator"
export const sectionList=[
    {label:"Basic Info",value:"BasicInfo"},
    {label:"Identification",value:"Identification"},
    {label:"Evidences",value:"Evidences"},
    { label: "Child Cases", value: "ChildCases" }
  ]

export const insurancesectionList=[
    {label:"Basic Info",value:"BasicInfo"},
    // {label:"Inquiry Info",value:"InquiryInfo"},
    {label:"Documents",value:"Evidences"},
    {label:"Response",value:"ResponseBox"},
    { label: "Child Cases", value: "ChildCases" }
  ]

export const sectionListChild = [
    { label: "Basic Info", value: "BasicInfo" },
    { label: "Identification", value: "Identification" },
    { label: "Evidences", value: "Evidences" },
    { label: "Child Cases", value: "ChildCases" }
  ]

export const insurancesectionListChild=[
    {label:"Basic Info",value:"BasicInfo"},
    // {label:"Inquiry Info",value:"InquiryInfo"},
    {label:"Documents",value:"Evidences"},
    {label:"Response",value:"ResponseBox"},
    { label: "Child Cases", value: "ChildCases" }
  ]
const userData = JSON.parse(localStorage.getItem("AccountDetails"));
export const loggedUser = (userData?.endUser?.mail)?.split(".")[0].toLowerCase();
 