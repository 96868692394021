import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
const { getGriDashboard } = require("../../ESG/services/Fetch");

const BarChart = (props) => {
  console.log(props.Socail, "hellodata");
  // Define your data for the bar chart
  const options = {
    chart: {
      type: "bar",
      width: 300, // Set the desired width here (e.g., 300px)
    },
    xaxis: {
      categories: props.data,
    },
  };

  const series = [
    {
      name: "SOC",
      data: props.Socail,
    },
    {
      name: "ENV",
      data: props.EvnData,
    },
    {
      name: "GOV",
      data: props.Gov,
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

function Graph() {
  const [Isloaded, setisLoaded] = useState(false);
  const [Name, setName] = useState();
  const [EvnData, setEvnData] = useState();
  const [Socail, setSocail] = useState();
  const [Gov, setGov] = useState();
  const FtechGraphdata = async () => {
    try {
      const data = await getGriDashboard();
      const Namedata = [];
      const ENV = [];
      const Socail = [];
      const Gov = [];
      // console.log(data.data.analytics.disclosures);
      data.data.analytics.disclosures.map((res, index) => {
        Namedata.push(res.filing);
        if (res.environmental === null) {
          ENV.push(10);
        } else {
          ENV.push(res.environmental);
        }
        if (res.governance === null) {
          Gov.push(10);
        } else {
          Gov.push(res.governance);
        }
        if (res.social === null) {
          Socail.push(10);
        } else {
          Socail.push(res.social);
        }
        //setName(res.filing);
        //console.log(res.environmental);
        // console.log(res.governance);
        // console.log(res.social);
        // console.log(res.governance);
      });
      setName(Namedata);
      setSocail(Socail);
      setGov(Gov);
      setEvnData(ENV);
      //console.log(Namedata, "heklo");

      setisLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FtechGraphdata();
  });
  return (
    <div>
      {Isloaded && (
        <BarChart data={Name} Socail={Socail} EvnData={EvnData} Gov={Gov} />
      )}
    </div>
  );
}

export default Graph;