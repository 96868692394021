import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Typography,
  InputLabel,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Button,
  TextField,
  MenuItem
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Select from 'react-select';
import withSnackbar from "../../components/Alert/withSnackbar";
import Evidences from "./Evidences";

const {
  getCheckList,
  getErrorList,
  getResult,
} = require("../../Compliance/services/CaseFetch");

const CheckList = ({ setdata, data, errors, setErrors, expdate, checkrows, setCheckRows, newRowData, setNewRowData, subworker, subworkeremail, setbase64FileList, errorData, checkData, setDefaultResult, resultList, defaultResult, base64FileList, display, showSnackbar }) => {

  const handleCheckChange = (value, keyval) => {
    setNewRowData((prevData) => {
      if (
        [
          "exception_type",
          "exception_description",
          "error_code",
          "expected_resolution_by",
        ].includes(keyval)
      ) {
        return {
          ...prevData,
          exception: {
            ...prevData.exception,
            [keyval]: value,
          },
        };
      } else if (keyval == "type") {
        return {
          ...prevData,
          [keyval]: value,
          current_case_worker: subworker,
          current_case_worker_email: subworkeremail,
          expected_closure_date: expdate,
        };
      } else {
        return {
          ...prevData,
          [keyval]: value,
        };
      }
    });
  };

  const addCheckRow = () => {
    if (newRowData.type !== "" && newRowData.date_conducted !== "") {
      // setCheckRows((prevRows) => [...prevRows, newRowData]);
      setCheckRows((prevRows) => [
        ...prevRows,
        { ...newRowData, type: newRowData.type.label },
      ]);
      const modifiedNewRowData = {
        ...newRowData,
        type: newRowData.type.value,
      };
      setdata((prevData) => {
        return { ...prevData, checksdata: [...checkrows, modifiedNewRowData] };
      });
    }
    clearCkecklist()
    
    //base64FileList=[]
   
  };
const clearCkecklist=()=>{
  setNewRowData({
    type: "",
    date_conducted: "",
    conducted_by: "",
    current_case_worker: "",
    current_case_worker_email: "",
    expected_closure_date: "",
    result: "",
    link_to_evidence: "",
    exception: {
      exception_type: "",
      error_code: "",
      expected_resolution_by: "",
      exception_description: "",
    },
    sub_attachments: [],
  });
  setbase64FileList([]);
}
  return (
    <>
      <Grid >
        <Grid className="alignspacebetween">
          <Typography className="checklist">CheckList</Typography>
          {display && <IconButton aria-label="refresh" onClick={clearCkecklist}>
            <RefreshIcon />
          </IconButton>
}
        </Grid>
        {display &&
          <>
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
              <Grid className="common" style={{ marginRight: "10px" }}>
                <InputLabel className="commonLabel">
                  Check Type <span className="redfont">*</span>
                </InputLabel>

                <Select
                  value={newRowData.type}
                  onChange={(e) => {
                    handleCheckChange(e, "type");
                    const correspondingErrorCode = errorData?.find(
                      (item) => item.status === e.label
                    )?.code;
                    handleCheckChange(correspondingErrorCode, "error_code");
                    setErrors({ type: false });
                  }}
                  options={checkData}
                  isSearchable={true}
                  //placeholder="Select a state..."
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: errors.type ? "red" : "",
                    }),
                  }}
                />
                {errors.type && (
                  <p className="errorcode">Please provide check type</p>
                )}
              </Grid>
              <Grid className="common" style={{ marginRight: "10px" }}>
                <InputLabel className="commonLabel">
                  Date Conducted: <span className="redfont">*</span>
                </InputLabel>
                <TextField
                  size="small"
                  id="date_conducted"
                  type="date"
                  value={newRowData.date_conducted}
                  onChange={(e) => {
                    handleCheckChange(e.target.value, "date_conducted");
                    setErrors({ date_conducted: false });
                  }}
                  error={errors.date_conducted}
                  helperText={
                    errors.date_conducted
                      ? "Please provide date conducted"
                      : ""
                  }
                />
              </Grid>
              <Grid className="common" style={{ marginRight: "10px" }}>
                <InputLabel className="commonLabel">
                  Check Conducted By <span className="redfont">*</span>
                </InputLabel>
                <TextField
                  size="small"
                  type="text"
                  id="conducted_by"
                  value={newRowData.conducted_by}
                  onChange={(e) => {
                    handleCheckChange(e.target.value, "conducted_by");
                    setErrors({ conducted_by: false });
                  }}
                  error={errors.conducted_by}
                  helperText={
                    errors.conducted_by ? "Please provide conducted by" : ""
                  }
                />
              </Grid>

              <Grid
                className="common"
                style={{ marginRight: "10px", marginTop: "10px" }}
              >
                <InputLabel className="commonLabel">
                  Result <span className="redfont">*</span>
                </InputLabel>
                <TextField
                  select
                  size="small"
                  id="result"
                  value={newRowData.result}
                  style={{ height: "30px" }}
                  onChange={(e) => {
                    setDefaultResult(e.target.value);
                    handleCheckChange(e.target.value, "result");
                    setErrors({ result: false });
                  }}
                  error={errors.result}
                  helperText={errors.result ? "Please select result" : ""}
                >
                  {resultList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid className="common" style={{ marginTop: "10px" }}>
                <InputLabel className="commonLabel">
                  Link To Evidence <span className="redfont">*</span>
                </InputLabel>
                <TextField
                  size="small"
                  type="text"
                  id="link_to_evidence"
                  value={newRowData.link_to_evidence}
                  onChange={(e) => {
                    handleCheckChange(e.target.value, "link_to_evidence");
                    setErrors({ link_to_evidence: false });
                  }}
                  error={errors.link_to_evidence}
                  helperText={
                    errors.link_to_evidence
                      ? "Please provide link to evidence"
                      : ""
                  }
                />
              </Grid>

              {defaultResult === "Fail" && (
                <Grid style={{ width: "100%", marginTop: "10px" }}>
                  <Grid className="rowcss">
                    <Grid
                      className="checkcommon"
                      style={{ marginRight: "15px" }}
                    >
                      <InputLabel className="commonLabel">
                        Error Type
                      </InputLabel>
                      <TextField
                        size="small"
                        id="exception_type"
                        type="text"
                        value={newRowData.exception.exception_type}
                        onChange={(e) =>
                          handleCheckChange(
                            e.target.value,
                            "exception_type"
                          )
                        }
                      />
                    </Grid>
                    <Grid className="checkcommon">
                      <InputLabel className="commonLabel">
                        Error Code
                      </InputLabel>
                      <TextField
                        select
                        size="small"
                        id="error_code"
                        disabled
                        value={newRowData.exception.error_code}
                      //onChange={(e) => handleCheckChange(e.target.value, 'error_code')}
                      >
                        {errorData &&
                          errorData.map((item) => (
                            <MenuItem key={item.code} value={item.code}>
                              {item.code}:{item.status}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid className="rowcss">
                    <Grid
                      className="checkcommon"
                      style={{ marginRight: "15px" }}
                    >
                      <InputLabel className="commonLabel">
                        Expected Resolution By
                      </InputLabel>
                      <TextField
                        size="small"
                        id="expected_resolution_by"
                        type="date"
                        value={newRowData.exception.expected_resolution_by}
                        onChange={(e) =>
                          handleCheckChange(
                            e.target.value,
                            "expected_resolution_by"
                          )
                        }
                        InputProps={{
                          inputProps: {
                            max: data.expected_closure_date,
                          },
                        }}
                      />
                    </Grid>
                    <Grid className="checkcommon">
                      <InputLabel className="commonLabel">
                        Error Details
                      </InputLabel>
                      <TextField
                        size="small"
                        id="exception_description"
                        type="textarea"
                        value={newRowData.exception.exception_description}
                        onChange={(e) =>
                          handleCheckChange(
                            e.target.value,
                            "exception_description"
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <Evidences base64FileList={base64FileList} setbase64FileList={setbase64FileList} setNewRowData={setNewRowData} showHeading={false} show={true}/>
                </Grid>
              )}
            </Grid>
            <Grid className="alignend">
              <Button
                onClick={addCheckRow}
                className="addcheck"
                style={{ height: "40px", marginTop: "10px" }}
              >
                Add
              </Button>
            </Grid>
          </>
        }
        {checkrows && checkrows.length > 0 && (
          <Grid>
           {display && <Typography className="identity" style={{ marginTop: '10px', marginBottom: '0px' }}>Added CheckList</Typography>}
            <Table
              className="casestable checktable"
              style={{ marginTop: "5px" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Check type</TableCell>
                  <TableCell>Date Conducted</TableCell>
                  <TableCell>Check Conducted By</TableCell>
                  <TableCell>Result</TableCell>
                  <TableCell>Link To Evidence</TableCell>
                  {display &&
                    <>
                      <TableCell>Error Code</TableCell>
                      <TableCell>Exception Type</TableCell>
                      <TableCell>Expected Resolution By</TableCell>
                      <TableCell>Error Description</TableCell>
                    </>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {checkrows.map((row, index) => (
                  <TableRow key={index} style={{ padding: "5px" }}>
                    <TableCell>{row?.type || row?.CheckType}</TableCell>
                    <TableCell>{row?.date_conducted || row?.DateConducted}</TableCell>
                    <TableCell>{row?.conducted_by || row?.ConductedBy}</TableCell>
                    <TableCell>{row?.result || row?.Result}</TableCell>
                    <TableCell>{row?.link_to_evidence || row?.LinkToEvidence}</TableCell>
                    {display &&
                      <>    <TableCell>
                        {row?.exception?.error_code || ""}
                      </TableCell>
                        <TableCell>
                          {row?.exception?.exception_type || ""}
                        </TableCell>
                        <TableCell>
                          {row?.exception?.expected_resolution_by || ""}
                        </TableCell>
                        <TableCell>
                          {row?.exception?.exception_description || ""}
                        </TableCell>
                      </>
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </>
  )
}


export default withSnackbar(CheckList, "CheckList", "success");