import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Index from "../Report/ADV/Index";
import "../styles/Report/Report.css";
import { AdvReportData } from "../services/ReportFetch";
import Adv from "ESG/Report/ADV/Adv";
import SecondPage from "ESG/Report/ADV/SecondPage";
import ThirdPage from "ESG/Report/ADV/ThirdPage";
import FourthPage from "ESG/Report/ADV/FourthPage";
import FifthPage from "ESG/Report/ADV/FifthPage";
import SixthPage from "ESG/Report/ADV/SixthPage";
import SeventhPage from "ESG/Report/ADV/SeventhPage";
import EighthPage from "ESG/Report/ADV/EighthPage";
import NinthPage from "ESG/Report/ADV/NinthPage";
import TenthPage from "ESG/Report/ADV/TenthPage";
import EleventhPage from "ESG/Report/ADV/EleventhPage";
import TwelthPage from "ESG/Report/ADV/TwelthPage";
import ThirteenPage from "ESG/Report/ADV/ThirteenPage";
import FourteenPage from "ESG/Report/ADV/FourteenPage";
import FifteenPage from "ESG/Report/ADV/FifteenPage";
import SixteenPage from "ESG/Report/ADV/SixteenPage";
import Seventeen from "ESG/Report/ADV/Seventeen";
import TwentyOnePage from "ESG/Report/ADV/TwentyOnePage";
import NineteenPage from "ESG/Report/ADV/NineteenPage";
import TwentyPage from "ESG/Report/ADV/TwentyPage";
import Eighteeth from "ESG/Report/ADV/Eighteeth";



const OpenPage = (props) => {
  const { children, page, index } = props;
  return (
    <div hidden={page !== index}>{page === index && <Box>{children}</Box>}</div>
  );
};
const ADVReportPage = () => {
  
  const [activePage, setActivePage] = useState(1);
  const [Isloading,setIsloading]=useState(false);
  const [ADVDataSet,setADVDataSet]=useState();
  
//  const  GetAdvData=()=>{
//   AdvReportData().then((x)=>{
//     setADVDataSet(x.data);
//   })

useEffect(()=>{
  AdvReportData().then((x)=>{
  setADVDataSet(x.data);
  //GetAdvData();
 setIsloading(true);
 })
 // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
    {Isloading ? (
      <div style={{marginTop:"65px"}}>
        <Box className="PageBox">
        <OpenPage page={activePage} defaultPage index={1}>
        <Index data={ADVDataSet}/>
        </OpenPage>
        {/* <OpenPage page={activePage} defaultPage index={2}>
        <Adv  data={ADVDataSet} />
        </OpenPage> */}
        <OpenPage page={activePage} defaultPage index={5}>
        <SecondPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={3}>
        <ThirdPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={7}>
        <FourthPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={8}>
        <FifthPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={2}>
        <SixthPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={6}>
        <SeventhPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={4}>
        <EighthPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={9}>
        <NinthPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={10}>
        <TenthPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={11}>
        <EleventhPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={12}>
        <TwelthPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={13}>
        <ThirteenPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={14}>
        <FourteenPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={15}>
        <FifteenPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={16}>
        <SixteenPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={17}>
        <Seventeen data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={19}>
        <NineteenPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={20}>
        <TwentyPage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={21}>
        <TwentyOnePage data={ADVDataSet}/>
        </OpenPage>
        <OpenPage page={activePage} defaultPage index={18}>
        <Eighteeth data={ADVDataSet}/>
        
        </OpenPage>
        
       
        </Box>
          <Pagination
            count={21}
            page={activePage}
            onChange={(event, newPage) => setActivePage(newPage)}
            size="medium"
            color="secondary"
            variant="outlined"
          />
       
      </div>
      ) : (
         <div className="loaders">
          <br/>
          <br/>
          <br/>
          <h1>Loading</h1>
                    
          </div>
        
    )}
</>
  );
};

export default ADVReportPage