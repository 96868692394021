import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

function FourteenthPage(props) {
  const [Questionset1, SetQuestionset1] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [Answer, setAnswer] = useState();
  const [Question1, setQuestion1] = useState();
  const [QuestionNumSet1, setQuestionNumSet1] = useState();

  //----------Create table header for 4th question----------------

  const CreateHeader1 = () => {
    const column = Object.keys(Question1[0]);
    const newcolumn = column.map((y) => {
      if (y !== "id" && y !== "isNew") {
        return <TableCell key={y}>{y}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  // //------get table row data

  const tdData1 = () => {
    return Question1.map((data1, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data1.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question1[0]).map((y) => {
              if (y !== "id" && y !== "isNew") {
                return (
                  <TableCell key={y} component="th" scope="row">
                    {data1[y]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
    const FetchData = () => {
      //fetching table data for first question
      setQuestion1(
        Object.values(props.data.reportData["Section 3-Item B"])[0].answer
      );

      const questions_array_a = [];
      const QuestionNumSet1 = [];
      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Section 3-Item B"]).length;
        i++
      ) {
        questions_array_a.push(
          Object.keys(props.data.reportData["Section 3-Item B"])[i]
        );
        QuestionNumSet1.push(
          Object.values(props.data.reportData["Section 3-Item B"])[
            i
          ].qno.substr(
            5,
            Object.values(props.data.reportData["Section 3-Item B"])[i].qno
              .length
          )
        );
      }
      //console.log(questions_array);
      SetQuestionset1(questions_array_a);
      setQuestionNumSet1(QuestionNumSet1);
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <React.Fragment>
      {Isloading && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
                <li>Section 1a</li>
                <li>Section 1b</li>
                <li>Section 1c</li>
                <li>Section 2a</li>
                <li>Section 2b</li>
                <li style={{ color: "Black", fontSize: "20px" }}>
                  <b>Section 3</b>
                </li>
                <li>Section 4</li>
                <li>Section 5</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={10}>
              {/* ------Item A question and answers -----------*/}
              <div>
                {Questionset1.map((x, index) => {
                  return (
                    <React.Fragment key={index}>
                      
                        <b>{QuestionNumSet1[index]}</b>
                        {"\u00A0"}
                        {"\u00A0"}
                        {x}
                        <br />
                        <div
                          style={{ justifyContent: "right", display: "flex" }}
                        >
                          <TableContainer>
                            <Table
                              sx={{ minWidth: 650, width: "50%" }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 1,
                                    },
                                  }}
                                >
                                  {CreateHeader1()}
                                </TableRow>
                              </TableHead>

                              {tdData1()}
                            </Table>
                          </TableContainer>
                        </div>
                      
                    </React.Fragment>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default FourteenthPage;
