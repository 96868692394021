import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const Labeling = (props)  =>{

    const [disseminating,setdisseminating] = useState()   
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
     
         setdisseminating((Object.values(props.data.reportData["GRI 417.1 Requirements for product and service information and labeling"])[2]).answer)
              
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={10}  className="grid1">
                        <h5 className="underline">Requirements for product and service information and labeling</h5>
                        
                        <h6><b>Provide  information,about substances that might produce an environmental or social impact and is required by the organizations procedures for product and service.
                           Provide the methodology for disseminating this information</b></h6>
                        <div dangerouslySetInnerHTML={{__html:disseminating}}></div>       
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default Labeling;