import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    Typography,
    Button,
    Box
  } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withSnackbar from "../../components/Alert/withSnackbar";
import {remark} from 'remark'; 
import html from 'remark-html';
import Loader from "Compliance/CaseManagment/Loader";
import { generateDraftResponse } from "Compliance/services/CaseFetch";
import "Compliance/styles/Case_Managment/Case_Audit.css"
const processMarkdown = async (markdown) => {
  const result = await remark().use(html).process(markdown);
  return (result.toString());
};

const AI=({showSnackbar,data,evidences,setdata})=>{
const [response,setResponse]=useState("")
const [loading,setLoading]=useState(false)

const generateResponse=async()=>{
 //console.log(evidences)
 // event.stopPropagation()
const body={
  input:{
  caseTitle:data?.caseTitle,
  subcategory:data?.subcategory||data?.case_type,
  dateOpened:data?.dateOpened,
  dateClosed:data?.dateClosed,
  assignedTo:data?.current_case_worker_email,
  department:data?.department,
  description:data?.description,
  },
  //input:data?.description,
  doc64:evidences[evidences?.length-1]?.attachment_base64?.split(",")[1]
}
//console.log(body)
setLoading(true)
const res=await generateDraftResponse(body)
// //console.log("res.data.values.output",res.data.values.output)
var parsedResponse=await processMarkdown(res.data.values.output)
//console.log("parsedResponse",parsedResponse)
setResponse(parsedResponse)
setLoading(false)
}

const addResponse=()=>{
  setdata((prevstate) => {
    return {
      ...prevstate,
      response: response,
    };
  });
  }
return(
    <>
    <Grid>
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography className="mainhead">AI Suggestion</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box height={200} overflow="auto">
          <Grid style={{display:'flex',justifyContent:'center'}}>
          {response ? <Button onClick={addResponse} style={{alignProperty:'right'}} className="createcasebtn">Add Response</Button> :''}
          </Grid>
         
         {loading ? <Loader/> :
          <Grid>
         <div dangerouslySetInnerHTML={{ __html: response }} />  
         </Grid>
         }
       
          <Grid style={{display:'flex',justifyContent:'center'}}>
            <Button onClick={generateResponse}  className="createcasebtn">Generate Response</Button>
          </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
    </>
)
}


export default withSnackbar(AI, "AI", "success");