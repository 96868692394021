import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

function FourthPage(props) {
  const [Questionset1, SetQuestionset1] = useState();
  const [Questionset2, SetQuestionset2] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [Question1, setQuestion1] = useState();
  const [Answer, setAnswer] = useState();
  const [QuestionNumSet1, setQuestionNumSet1] = useState();
  const [QuestionNumSet2, setQuestionNumSet2] = useState();

  //--------------Creating table header
  const CreateHeader1 = () => {
    const column = Object.keys(Question1[0]);
    const newcolumn = column.map((x) => {
      if (x !== "id" && x !== "isNew") {
        return <TableCell key={x}>{x}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  //-------get table row data
  const tdData1 = () => {
    return Question1.map((data,index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question1[0]).map((x) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell key={x} component="th" scope="row">
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };

  useEffect(() => {
    const FetchData = () => {
      //fetching table data for first question
      setQuestion1(
        Object.values(props.data.reportData["Section 1a-Item B"])[0].answer
      );

      // console.log(
      //   Object.values(props.data.reportData["Section 1a-Item B"])[0].answer[0]
      // );

      //getting section 1a question in single array
      const questions_array_a = [];
      const QuestionNumSet1 = [];
      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Section 1a-Item B"]).length;
        i++
      ) {
        questions_array_a.push(
          Object.keys(props.data.reportData["Section 1a-Item B"])[i]
        );
        QuestionNumSet1.push(
          Object.values(props.data.reportData["Section 1a-Item B"])[
            i
          ].qno.substr(
            5,
            Object.values(props.data.reportData["Section 1a-Item B"])[i].qno
              .length
          )
        );
      }
      //console.log(questions_array);
      SetQuestionset1(questions_array_a);
      setQuestionNumSet1(QuestionNumSet1);

      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumSet2 = [];
      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Section 1b-Item A"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Section 1b-Item A"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Section 1b-Item A"])[i].answer
        );
        QuestionNumSet2.push(
          Object.values(props.data.reportData["Section 1b-Item A"])[
            i
          ].qno.substr(
            5,
            Object.values(props.data.reportData["Section 1b-Item A"])[i].qno
              .length
          )
        );
      }
      //console.log(questions_array);
      SetQuestionset2(questions_array_b);
      setAnswer(Answer_array_b);
      setQuestionNumSet2(QuestionNumSet2);
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <React.Fragment>
      {Isloading && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
                <li style={{ color: "Black", fontSize: "20px" }}>
                  <b>Section 1a</b>
                </li>
                <li style={{ color: "Black", fontSize: "20px" }}>
                  <b>Section 1b</b>
                </li>
                <li>Section 1c</li>
                <li>Section 2a</li>
                <li>Section 2b</li>
                <li>Section 3</li>
                <li>Section 4</li>
                <li>Section 5</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={10}>
              {/* -------------------- section 1a question & table -----------------------------*/}

              <div>
                {Questionset1.map((x, index) => {
                  return (
                    <React.Fragment key={index}>
                     
                        <b>{QuestionNumSet1[index]}</b>
                        {"\u00A0"}
                        {"\u00A0"}
                        {x}{" "}
                     
                    </React.Fragment>
                  );
                })}
                <br/>
              </div>
              <div>
                <div style={{ justifyContent: "right", display: "flex" }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650, width: "50%" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 1 },
                          }}
                        >
                          {CreateHeader1()}
                        </TableRow>
                      </TableHead>

                      {tdData1()}
                    </Table>
                  </TableContainer>
                </div>
                <br />
              </div>

              {/* ------section 1b question and answers -----------*/}
              <div>
                {Questionset2.map((x, index) => {
                  return (
                    <React.Fragment key={index}>
                      
                        <b>{QuestionNumSet2[index]}</b>
                        {"\u00A0"}
                        {"\u00A0"}
                        {x}
                        <br/>
                        {Answer[index]}
                     <br/>
                    </React.Fragment>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default FourthPage;
