import React, { useEffect, useState,useContext,useRef } from "react";
import {
    Box,
    Grid,
    InputLabel,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    MenuItem
  } from "@mui/material";
import JoditEditor from 'jodit-react';
import withSnackbar from "../../components/Alert/withSnackbar"; 
// const {

//   } = require("../../Compliance/services/CaseFetch");

const ResponseBox=({insurancedata,setinsurancedata,edit,iscreatecase,showSnackbar})=>{
  const editor = useRef(null);
  const [disablefirstlastName,setDisablefirstlastName]=useState(false);
  const [disable,setDisable]=useState(false);
  const [enable,setEnable]=useState("");
  const [response,setResponse]=useState("");
  //const enable=edit==true?false:true;
  useEffect(()=>{
    if(iscreatecase){
      setDisable(false);
      setEnable(false)
    }
    else if(edit==true){
      setEnable(false)
      setDisable(true);
    }
    else if(edit!=true){
      setDisable(true);
      setEnable(true)
    }
  },[])

  
return(
    <>
  
  <Box className='InputFields' style={{marginTop:"10px"}}>
    <JoditEditor 
        ref={editor}
        value={insurancedata?.response}
        tabIndex={1} 
        onBlur={(newValue)=>
          setinsurancedata((prevstate) => {
            return { ...prevstate, response: newValue };
          })
        }
        // onChange={(newValue)=>
        //   setinsurancedata((prevstate) => {
        //     return { ...prevstate, response: newValue };
        //   })
        // }
        config={{
          readonly: enable, 
          // autofocus : true , 
          cursorAfterAutofocus: 'end',
        }}
     
        />
        </Box>
              
    </>
)
}


export default withSnackbar(ResponseBox, "ResponseBox", "success");