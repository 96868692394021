import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import NoteContext from "context/PageContext";
import withSnackbar from "../Alert/withSnackbar"; // Adjust the path
const options = ['Light', 'Dark', 'Customise'];

 function ThemeController({showSnackbar}) {
  const ContextData = React.useContext(NoteContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleMenuItemClick = (event, index) => {
    showSnackbar("Theme changed", "info");
    setSelectedIndex(index);
    ContextData.setThemeType(index === 0 ? true : false)
    console.log(index)
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup title='Themes select' variant="outlined" ref={anchorRef} aria-label="split button">
        <Button sx={{ textTransform: 'capitalize' }}
        variant="outlined"
        size="small"
          title='Select Theme'
          onClick={handleToggle}
          startIcon={<ArrowDropDownIcon />}>
          {options[selectedIndex]}
          {/* <ArrowDropDownIcon /> */}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 0,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default withSnackbar(ThemeController, "ThemeController", "success");