import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    Typography,
    InputLabel,
    TextField,
    Chip
  } from "@mui/material";
import withSnackbar from "../../components/Alert/withSnackbar"; 


const HashTag=({setdata,data,display,showSnackbar})=>{
    const [tag, setTag] = useState("");

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };
  // Handle Add HashTags
  const handleAddTag = () => {
    if (tag.trim() !== "") {
      setdata((prevData) => ({
        ...prevData,
        hash_tag: [...prevData.hash_tag, tag.trim()],
      }));
      setTag("");
    }
  };
  //function Delete HashTags
  const handleDeleteTag = (tagToDelete) => {
    setdata((prevData) => ({
      ...prevData,
      hash_tag: prevData.hash_tag.filter((tag) => tag !== tagToDelete),
    }));
  };
return(
    <>
    <Grid>
                  <Typography className="identity">HashTags</Typography>
             {display &&     <Grid className="rowclass">
                    <Grid className="common">
                      <InputLabel className="commonLabel">HashTag</InputLabel>
                      <TextField
                        size="small"
                        value={tag}
                        required
                        onChange={handleTagChange}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleAddTag();
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
}
                  {data?.hash_tag && typeof data?.hash_tag === "string" && data?.hash_tag !=""
                    ? JSON.parse(data?.hash_tag)
                    : data?.hash_tag?.map((tag, index) => (
                        <Chip
                          key={index}
                          label={`#${tag}`}
                          onDelete={display ? () => handleDeleteTag(tag) : undefined}
                          //disabled={!display} 
                          className="hastag"
                        />
                      ))}
                </Grid>
    </>
)
}


export default withSnackbar(HashTag, "HashTag", "success");