import React, { useEffect, useState,useContext } from "react";
import {
    InputLabel,
    TextField,
    Button,
    MenuItem
  } from "@mui/material";
  import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import withSnackbar from "../../components/Alert/withSnackbar"; 


const Dialogbox=({ open, onClose, onConfirm,heading,subhead,data,setuseremail,setusername,useremail,username }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>
  
                  <InputLabel className="subtypes">{subhead}</InputLabel>
                  <TextField
                    select
                    size="small"
                   value={useremail+","+username}
                    //className="selectclass" 
                    style={{width:'100%'}}
                    onChange={(e)=>{
                        setuseremail(e?.target?.value?.split(",")[0])
                        setusername(e?.target?.value?.split(",")[1])
                    }}
                  
                  >
                    {data && data.map(item => (
                  <MenuItem value={item.email+","+item.name} className="subtypeval" >{item.name}</MenuItem>
                    ))}
                  </TextField>
                
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }


export default withSnackbar(Dialogbox, "Dialogbox", "success");