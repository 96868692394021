import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    Typography
  } from "@mui/material";
import withSnackbar from "../../components/Alert/withSnackbar"; 
import "../../Compliance/styles/Case_Managment/ParentCaseInfo.css"

const InsuranceParentCaseInfo=({data,showSnackbar})=>{
return(
    <>
    <Grid className="boxborder">
        <Grid>
          <Typography className="parentHeading">Parent Case ID : {data?.parentid}</Typography>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"space-between",marginTop:'15px'}}>
          <Grid>
            <Typography className="subhead">Case Title</Typography>
            <Typography className="subcontent">{data?.caseTitle}</Typography>
          </Grid>
          <Grid>
            <Typography className="subhead">Date Opened</Typography>
            <Typography className="subcontent">{data?.dateOpened}</Typography>
          </Grid>
          <Grid>
            <Typography className="subhead">Regulatory Authority</Typography>
            <Typography className="subcontent">{data?.regulatoryAuthority}</Typography>
          </Grid>
        </Grid>
        <Grid style={{marginTop:'15px'}}>
        <Typography className="subhead">Description</Typography>
        <Typography className="subcontent">{data?.description}</Typography>
        </Grid>
    </Grid>
    </>
)
}


export default withSnackbar(InsuranceParentCaseInfo, "InsuranceParentCaseInfo", "success");