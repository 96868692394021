import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

function Obligations(props) {
  const [isloading, setisloading] = useState(false);
  const [headerTable, setheaderTable] = useState();
  const [headerTable1, setheaderTable1] = useState();
  const[coverage,setcoverage]=useState();
  const[pension,setpension]=useState();


  // get table heading data
  const CreateHeader = (tableheader) => {
    const column = Object.keys(tableheader[0]);
    const newcolumn = column.map((data) => {
      if (data !== "id" && data !== "isNew") {
        return <TableCell key={data}>{data}</TableCell>;
      }
      return null;
    });

   
    return newcolumn;
  };

  // get table row data
  const tdData = (tabledata) => {
    return tabledata.map((data,index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(tabledata[0]).map((x,index) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell key={index} component="th" scope="row">
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
   
    setcoverage((Object.values(props.data.reportData["GRI 201.3 Defined benefit plan obligations and other retirement plans"])[4]).answer);
    setheaderTable(
      Object.values(props.data.reportData["GRI 201.3 Defined benefit plan obligations and other retirement plans"])[0].answer
    );
    setheaderTable1(
        Object.values(props.data.reportData["GRI 201.3 Defined benefit plan obligations and other retirement plans"])[1].answer
      );
      setpension((Object.values(props.data.reportData["GRI 201.3 Defined benefit plan obligations and other retirement plans"])[3]).answer);
    setisloading(true);

    //CreateHeader();
  }, [isloading,props]);

  return (
    <div>
      {isloading && (
        <>
         <Box  className="EmpBox">
         <Grid container spacing={2}>
        <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                  <h5 className="underline">Defined benefit plan obligations and other retirement plans</h5>
                <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
            <h6><b>What are the plans liabilities met by the organizations general resources,
                 and enter the estimated value of those liabilities?.</b></h6>
              <Table
                sx={{ minWidth: 550, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable && CreateHeader(headerTable)}
                  </TableRow>
                </TableHead>

                {headerTable && tdData(headerTable)}
              </Table>
              
            </TableContainer>
            
          </div>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <TableContainer>
            <h6><b>Which are the schemes liabilities are estimated to be covered by the assets that 
                have been set aside to meet them?</b></h6>
              <Table
                sx={{ minWidth: 550, width: "50%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    {headerTable1 && CreateHeader(headerTable1)}
                  </TableRow>
                </TableHead>

                {headerTable1 && tdData(headerTable1)}
              </Table>
              
            </TableContainer>
            
          </div>
          
          
          </Grid>
          <Grid item xs={12} md={5}  className="grid2">
          
          <h6><b>If a fund set up to pay the plans pension liabilities is not fully covered, explain the strategy, if any, adopted by the employer to work towards full coverage, and
             the timescale, if any, by which the employer hopes to achieve full coverage.</b></h6>
            <div dangerouslySetInnerHTML={{__html:coverage}}></div> 
            <h6><b>When the pension liability plan's estimate was made?.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:pension}}></div>
          
          
          </Grid>
          </Grid>
          </Box>
        </>
      )}
    </div>
  );
}

export default Obligations;


