import "../../styles/Report/Report.css";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";

function MTDA2(props) {
  const [isloading, setisloading] = useState(false);
  const [capabilities, setcapabilities] = useState();
  const [investment, setinvestment] = useState();


  useEffect(() => {
    // console.log(Object.keys(props.data.reportData["M.Disclosure A"]))
     setcapabilities(Object.values(props.data.reportData["M.Disclosure A"])[8].answer);
     setinvestment(Object.values(props.data.reportData["M.Disclosure A"])[9].answer);

    setisloading(true);
  }, [isloading, props]);
  return (
    <React.Fragment>
      {isloading && (
        <>
          <Grid container spacing={1} className="border3">
            <Grid item xs={12} md={2} className="border3" >
              <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  </div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                    <li>Strategy</li>
                    <li>Risk Management</li>
                    <li style={{color:"#724272",fontSize:"20px"}}><b>Metrics and Targets</b></li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#724272"}} className="border3">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Metrics and Targets</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Disclose the metrics used by the organization to assess climate-related risks and 
                                    opport unities in line with its strategy and risk management process.</p>
                  </Grid>
              <Grid item xs={12} md={6} className="grid1">
              <h4 className="underline">M.Disclosure A</h4>
                <h5><b>Describe the extent to which insurance underwriting activities are aligned with a well below 2°C scenario,
                     using whichever approach or metrics best suit their organizational context or capabilities.</b>
                </h5>
                <div dangerouslySetInnerHTML={{ __html: capabilities }}></div>
              </Grid>
              <Grid item xs={12} md={6} className="grid2">
                <h5>
                  <b>
                  Describe metrics used to assess climate-related risks and opportunities in each fund or investment strategy.
                   Where relevant, asset owners should also describe how these metrics have changed over time.
                  </b>
                </h5>
                <div dangerouslySetInnerHTML={{ __html: investment }}></div>
              </Grid>
              </Grid>
            </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default MTDA2;