import React, { useEffect, useState, useContext } from "react";
import NoteContext from "context/PageContext";
import {
  Grid,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import withSnackbar from "components/Alert/withSnackbar";
import Loader from "../Loader";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "../../../Compliance/styles/Case_Managment/CreateCase.css"
import Basicinfo from "Compliance/Components/BasicInfo";
import HashTag from "Compliance/Components/HashTag";
import CheckList from "Compliance/Components/CheckList";
import Evidences from "Compliance/Components/Evidences";
import IdentificationList from "Compliance/Components/IdentificationList";
import Comments from "Compliance/Components/Comments";
import AI from "Compliance/Components/AI";
import CaseAudit from "../CaseAudit";
import Drawer from "Compliance/Components/Drawer";
import { Refresh } from "@mui/icons-material";
import ParentCaseInfo from "Compliance/Components/ParentCaseInfo";
import SectionButtons from "Compliance/Components/SectionButtons";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
import ChildCases from "Compliance/Components/ChildCases";
import SelectedChildCase from "Compliance/Components/SelectedChildCase";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {sectionList,KYC} from "Insurance/CaseManagement/Config.js"
import Actions from "./Actions";

const KYCTemplate = ({ showSnackbar, caseid,edit,iscreatecase }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {templateType} = useTemplateType();
  const {
    CreateCases,
    getCountryList,
    getStateList,
    getUserList,
    getCheckList,
    getErrorList,
    getResult,
    downloadFile,
    getIdentityList,
    getCaseById,
    getChildCaseById,
    updateSubCaseWithAttachmentsComments
  } = require("../../services/CaseFetch");

  const params = new URLSearchParams(window.location.search);
  const casetype = atob(params.get("case_type"));
  const casecode = atob(params.get("case_code"));
  //const caseid = params.get("case_id") ? atob(params.get("case_id")) : '';
  const is_parent_case = params.get("parent_case") ? JSON.parse(atob(params.get("parent_case"))) : '';
  const show = params.get("show") ? JSON.parse(atob(params.get("show"))) : '';
  const tasks = params.get("tasks") ? JSON.parse(atob(params.get("tasks"))) : '';
  const display = caseid ? false : true
  const [sectionType, setSectionType] = useState("BasicInfo");
  const [hasCaseCreatorRole, setHasCaseCreatorRole] = useState();
  const [showCardRow, setShowCardRow] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "create-case-button" : undefined;
  const [fileDataArray, setFileDataArray] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [rows, setRows] = useState([{ Type: "", IdentificationNo: "" }]);
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");
  const userDetails = JSON.parse(localStorage.getItem("AccountDetails"));
  const auth = userDetails?.endUser;
  const [selectedSubCase, setselectedSubCase] = useState({ CaseId: "" });
  const [data, setdata] = useState({
    Caseid: "",
    customer_id: "",
    EditedBy: "",
    CaseCategory: "",
    Status: "",
    case_priority: "",
    hash_tag: [],
    CaseDate: formattedDate,
    description: "",
    CaseAttachment: "",
    accountApprovalDate: "",
    accountOpeningDate: "",
    first_name: "",
    last_name: "",
    country: "",
    country_code: "",
    state: "",
    state_code: "",
    case_type: "",
    expected_closure_date: "",
    creator_email: auth?.mail,
    creator_id: auth?.mail,
    creator: auth?.displayName,
    checksdata: [],
    comment: "",
    current_case_worker: "",
    current_case_worker_email: "",
    childcases: [],
  });

  const [expdate, setexpdate] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [checkErrMsg, setCheckErrMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [countryData, setCountryData] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [defaultState, setDefaultState] = useState(null);
  const [caseworkers, setcaseworkers] = useState([]);
  const [subworker, setsubworker] = useState("");
  const [subworkeremail, setsubworkeremail] = useState("");
  const [checkData, setCheckData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [resultList, setResultList] = useState([]);
  const [checkrows, setCheckRows] = useState([]);
  const [base64FileList, setbase64FileList] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [newcommentsData, setnewCommentsData] = useState([]);
  const [identityData, setIdentityData] = useState(null);
  const [defaultidentityTypes, setDefaultIdentityTypes] = useState([]);
  const [newRowData, setNewRowData] = useState({
    type: "",
    date_conducted: "",
    conducted_by: "",
    current_case_worker: "",
    current_case_worker_email: "",
    expected_closure_date: "",
    result: "",
    link_to_evidence: "",
    exception: {
      exception_type: "",
      error_code: "",
      expected_resolution_by: "",
      exception_description: "",
    },
    sub_attachments: [],
  });
  const [defaultcheckType, setDefaultCheckType] = useState();
  const [defaultResult, setDefaultResult] = useState("SUCCESS");
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());
  const [Subcases, setSubcases] = useState();
  const [newreplyData, setreplyData] = useState([]);
  const [AIfiles,setAIfile]=useState([]);
  useEffect(() => {
    setHasCaseCreatorRole(
      ContextData?.CaseRole?.displayName === "Case Creator" ? true : false
    );

    setdata({ ...data, case_type: casecode });
  }, [ContextData?.CaseRole?.displayName, hasCaseCreatorRole]);

  const getCountryStateList=async(country)=>{
    const cdata = await getCountryList();
    var cres = cdata.data.values;
    const countryObject = cres.find((e) => e.CountryName === country);
    const [sdata,userlist] =
      await Promise.all([
        getStateList(countryObject?.CountryCode),
        getUserList()
      ]);

   
    var countryDataList = cres.map((country) => ({
      value: country.CountryCode,
      label: country.CountryName,
    }));
    //setCountryData(res);
    var ssdata = sdata.data.values;
    var sortedStatedata = ssdata.map((state) => ({
      value: state,
      label: state,
    }));
    //setStateData(sortedStatedata);
    setcaseworkers(userlist?.data)
    return { countryDataList, sortedStatedata };
  }
  useEffect(() => {
    if(caseid){    
      fetchsubCasesData(caseid,"KYC")
    }
    else{
      fetchData();
    }
    setSectionType("BasicInfo")
  }, [caseid]);
  useEffect(() => {
    setShowChildCases(is_parent_case?JSON.parse(is_parent_case):'');
  }, [is_parent_case]);

  const fetchData = async () => {
    try {
      setLoading(true)
      const cdata = await getCountryList();
      const [userslist, sdata, chdata, erdata, result,idata] =
        await Promise.all([
          getUserList(),
          getStateList(cdata.data.values[0].CountryCode),
          getCheckList(casetype),
          getErrorList(casetype),
          getResult(),
          getIdentityList()
        ]);

      var cres = cdata.data.values;
      var res = cres.map((country) => ({
        value: country.CountryCode,
        label: country.CountryName,
      }));
      setCountryData(res);
      setDefaultCountry(res[0]);
      setdata((prevState) => ({
        ...prevState,
        country: res[0].label,
        country_code: res[0].value,
      }));

      var ssdata = sdata.data.values;
      var sortedStatedata = ssdata.map((state) => ({
        value: state,
        label: state,
      }));
      setStateData(sortedStatedata);
      setDefaultState(sortedStatedata[0]);
      setdata((prevState) => ({
        ...prevState,
        state_code: sortedStatedata[0].value,
        state: sortedStatedata[0].label,
      }));
      setcaseworkers(userslist.data);
      setResultList(result?.data?.values);
      var checkdata = chdata?.data?.values.map((chktype) => ({
        value: chktype,
        label: chktype,
      }));
      setCheckData(checkdata);
      setErrorData(erdata?.data);
      var sidtypes = idata?.data?.values;
      var idtypes = sidtypes.map((idtype) => ({
        value: idtype,
        label: idtype,
      }));
    
      setIdentityData(idtypes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar("unable to fetch data", "error");
    }
  };




  const handleClose = () => {
    setdata({
      customer_id: "",
      // Creator: UserInfo.name,
      EditedBy: "",
      CaseCategory: "",
      CaseSubCategory: "",
      Status: "",
      case_priority: "",
      hash_tag: [],
      CurrentCaseWorking: "",
      CaseDate: formattedDate,
      description: "",
      CaseAttachment: "",
      expected_closure_date: "",
    });
    setFileDataArray([]);
    setAnchorEl(null);
    setIsEditing(false);
    setIsViewMode(false);
    setShowCardRow(true);
    navigate("/caseManagement");
  };

  const handleAddClick = () => {
    const mandatoryFields = [
      "first_name",
      "last_name",
      "country",
      //'country_code',
      "state",
      //'state_code',
      "accountApprovalDate",
      "accountOpeningDate",
      "current_case_worker",
      "current_case_worker_email",
      "expected_closure_date",
      "case_priority",
    ];
    var checkFileds = [
      "type",
      "date_conducted",
      "conducted_by",
      "expected_closure_date",
      "result",
      "link_to_evidence",
    ];
    const newErrors = {};
    let hasError = false;

    mandatoryFields.forEach((field) => {
      if (!data[field]) {
        newErrors[field] = true;
        hasError = true;
      }
    });
    const atLeastOneFailedCheck = data?.checksdata?.some(
      (check) => check.result === "Fail"
    );
    if (data.checksdata.length == 0) {
      checkFileds.forEach((field) => {
        newErrors[field] = true;
        hasError = true;
      });
    } else if (!atLeastOneFailedCheck) {
      setCheckErrMsg("Add atleast one failed check");
    }

    // debugger;
    // if ( data.first_name==''|| data.last_name==''||data.country==''||data.country_code==''||data.state==''||data.state_code==''|| data.accountApprovalDate==''||data.accountOpeningDate==''||data.current_case_worker==''||data.current_case_worker_email==''||data.expected_closure_date==''||data.case_priority==''||data.checksdata.length<=0) {
    //  showSnackbar("Fill all Mandatory fields","error")
    // }
    if (hasError) {
      setErrors(newErrors);
      setErrMsg(
        "Required information missing.Please provide the missing information highlighted below."
      );
      // Show error message or handle error state here
      //showSnackbar("Fill all Mandatory fields","error");
    }

    else {
      setErrors({});
      const newCase = {
        case: {
          first_name: data.first_name,
          last_name: data.last_name,
          country: data.country,
          country_code: data.country_code,
          state: data.state,
          state_code: data.state_code,
          application_date: formattedDate,
          account_approval_date: data.accountApprovalDate,
          account_opening_date: data.accountOpeningDate,
          description: data.description,
          case_type: data.case_type,
          case_priority: data.case_priority,
          hash_tag: data.hash_tag,
          customer_id: data.customer_id,
          expected_closure_date: data.expected_closure_date,
          creator_email: data.creator_email,
          creator_id: data.creator_id,
          creator: data.creator,
          current_case_worker: data.current_case_worker,
          current_case_worker_email: data.current_case_worker_email,
        },
        checks: data.checksdata,
        identifications: rows,
        comment: newcommentsData,
        case_attachments: files,
        templateType:KYC
      };
      const createcase_body = {
        body: newCase,
        domain: ContextData.UserSelected,
        case_type: casecode,
        
        
      };
      createCase(createcase_body);

      setAnchorEl(null);
    }
  
 
  };
  //Create Case function
  const createCase = async (info) => {
    try {
      showSnackbar("Creating new case please wait... ", "info");
      setLoading(true);
      const res = await CreateCases(info);
      if (res.status == 200) {
        clearForm();
        // setCheckRows([]);
        setFileDataArray([]);
        setLoading(false);

        navigate("/caseManagement");
        showSnackbar("New case Created Successfully", "success");
      }
    } catch (error) {
      console.error(error);
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true,
      });
      setLoading(false);
      showSnackbar("Unable to create Case", "error");
    }
  };

  const clearForm = () => {
  
    setdata({
      customer_id: "",
      // Creator: UserInfo.name,
      EditedBy: "",
      CaseCategory: "",
      CaseSubCategory: "",
      Status: "",
      case_priority: "",
      hash_tag: [],
      CurrentCaseWorking: "",
      CaseDate: formattedDate,
      description: "",
      CaseAttachment: "",
      expected_closure_date: "",
      comment: "",
      identifications: [],
      checks: [],
      first_name: "",
      last_name: "",
      accountApprovalDate: "",
      accountOpeningDate: "",
      current_case_worker: "",
      current_case_worker_email: "",
    });

  }

  const fetchsubCasesData = async (caseid,cType) => {
    // Fetch data from your API based on the current page
  
   // var isparent=false;
    var case_detail,details;
    //ContextData.setselectedCaseType(cassetype)
    try {
     
      if(is_parent_case){
        setLoading(true)
        const [case_detail] = await Promise.all([
        getCaseById(caseid,cType)
        
      ]);
      
      const details = case_detail.data.parent_case[0]
      const { countryDataList, sortedStatedata }=await getCountryStateList(details.Country)
      setDefaultCountry(countryDataList?.find((e)=>e.label==details.Country))
      setDefaultState(sortedStatedata?.find((e)=>e.label== details.State))
      setdata({
        first_name: details.FirstName,
        last_name: details.LastName,
        customer_id: details.CustomerId,
        hash_tag: (details.HashTags!="") && (typeof(details.HashTags)==='string') ? JSON.parse(details.HashTags):details.HashTags,
        country: countryDataList?.find((e)=>e.label==details.Country),
        state:  sortedStatedata?.find((e)=>e.label== details.State),
        current_case_worker: details.CurrentCaseWorker,
        current_case_worker_email: details.CurrentCaseWorkerEmail,
        creator: details.Creator,
        creator_email: details.CreatorEmail,
        expected_closure_date: details.ExpectedClosureDate,
        description: details.Description,
        identificationList:(details?.Identifications != '') && (typeof(details?.Identifications)==='string') ?JSON.parse(details?.Identifications):details?.Identifications,
        parentid: caseid,
        case_priority:details?.Priority,
        accountApprovalDate:details?.accountapprovaldate,
        accountOpeningDate:details?.accountopeningdate,
        Status:details?.Status,
      })
    
    setSubcases(case_detail.data.AllSubcase);
    setselectedSubCase(case_detail.data.AllSubcase[0]);
    setsubworker(details.CurrentCaseWorker)
      setsubworkeremail( details.CurrentCaseWorkerEmail)
      setCheckRows(case_detail?.data?.AllCheckList)
      setRows((details?.Identifications != '') && (typeof(details?.Identifications)==='string') ?JSON.parse(details?.Identifications):details?.Identifications)
      setLoading(false);
    }else{
      setLoading(true)
      const [case_detail] = await Promise.all([
        getChildCaseById(caseid)
      ]);
      
      const details =case_detail.data.parentCase[0]
      const subCase=case_detail.data.subCase[0]
      const { countryDataList, sortedStatedata }=await getCountryStateList(details.Country)
      setDefaultCountry(countryDataList?.find((e)=>e.label==details.Country))
      setDefaultState(sortedStatedata?.find((e)=>e.label== details.State))
      setdata({
        ...data,
        parent_case_id:details?.ParentCaseId,
        first_name: details?.FirstName,
        last_name: details?.LastName,
        customer_id: details?.CustomerId,
        hash_tag: JSON.parse(details?.HashTags),
        country: details?.Country,
        state: details?.State,
        identificationList: JSON.parse(details?.Identifications),
        current_case_worker: subCase?.CurrentCaseWorker || '',
        current_case_worker_email: subCase?.CurrentCaseWorkerEmail || '',
        current_case_supervisor: 'Deepak@jktech.com,Deepak', //res.CurrentCaseSupervisor||
        current_case_supervisor_email: 'Deepak@jktech.com', //res.CurrentCaseSupervisorEmail||
        expected_closure_date: subCase?.ExpectedResolutionBy,
        case_priority: details?.Priority,
        accountApprovalDate: details?.AccountApprovalDate,
        accountOpeningDate:details?.AccountOpeningDate,
        hash_tag: details.HashTags && typeof details?.HashTags === "string"
          ? (isValidJson(details?.HashTags) ? JSON.parse(details?.HashTags) : JSON.parse(details?.HashTags.replace(/'/g, '"')))
          : details?.HashTags,
        description: subCase?.ExceptionDescription,
        type:subCase?.Type,
        //checksdata:res.parent_case.checks
        Status:subCase?.Status
      });
      setsubworker(details.CurrentCaseWorker)
      setsubworkeremail( details.CurrentCaseWorkerEmail)
      setCheckRows(case_detail?.data?.AllCheckList)
      setRows((details?.Identifications != '') && (typeof(details?.Identifications)==='string') ?JSON.parse(details?.Identifications):details?.Identifications)
      setselectedSubCase(case_detail.data.subCase[0]);
      setLoading(false);
    } 
      //setIsShow(details?.Status == 'close' ? false : true)
      // setRows(JSON.parse(details.Identifications))
      // setCheckRows(case_detail.data.AllCheckList)
     
      //setfilteredRows(case_detail.data.AllSubcase);
      
      //setCheckList(case_detail.data.AllCheckList);
     


      
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
      // Handle error if needed
    }
  };

  const isValidJson = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }
  const updateSubCase=async()=>{
    
   
  
    
    var param={
      param:{
        SubCaseId:caseid, 
        domain:ContextData.UserSelected
      },
      body:{
        
        ExceptionDescription: data.description,
        ExpectedResolutionBy:data.expected_closure_date,
        sub_attachments:files,
        comment:[...newcommentsData,...newreplyData],
        response:''
      }
    }
    try{
      setLoading(true)
   var res=await updateSubCaseWithAttachmentsComments(param)
   if (res.status == 200) {
   setFiles([])
   setnewCommentsData([])
   setreplyData([])
    fetchData();
    setLoading(false)
  }
}catch(e){
showSnackbar(e.response.data.message||"error in updating case","error")
}
  }
  const [showChildCases, setShowChildCases] = useState(is_parent_case);
  const filteredSectionList = sectionList.filter(section => {
    if (section.value === "ChildCases") {
      return showChildCases;
    }
    return true;
  });
  
  return (
    <Grid style={{ background: "#F5F5F6" }}>
      <Grid className="mainheading" style={{ padding: "15px 0px 0px 15px", display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
        <Grid>
          <ArrowBackIosIcon onClick={() => { navigate("/caseManagement") }} />
        {caseid ?  "View Case" :"Create Case" }
        </Grid>
        <Grid>
        {JSON?.parse(iscreatecase) &&  <Button className="undobtn" onClick={clearForm}><Refresh />Undo</Button> }
        </Grid>
      </Grid>
      <Grid>
        {loading ? (
          <Loader />
        ) : (
          <Grid style={{ backgroundColor: "#F5F5F6",background: "#F5F5F6"}}>
            <Grid style={{ display: 'flex', backgroundColor: "#F5F5F6",justifyContent:'space-between',background: "#F5F5F6" }}>
              <Grid style={{ width: '74%', display: "flex", flexDirection: "column",background: "#F5F5F6" }}>
                <Box sx={{}}>
                  {errMsg || checkErrMsg ? (
                    <Grid style={{ paddingTop: "10px", margin: "0px 10px" }}>
                      <Grid className="rejectbox">
                        <InfoIcon className="rejectHeading" style={{ fontSize: "32px", marginRight: "10px" }} />
                        <Grid>
                          <Typography className="rejecttxt">
                            {errMsg ? errMsg : ""}
                          </Typography>
                          <Typography className="rejecttxt">
                            {checkErrMsg ? checkErrMsg : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid px={2} py={1} spacing={2} className="boxcard" style={{ height: "70vh" }}>
                    <Grid>
                    
                     <SectionButtons
                     list={filteredSectionList}
                     sectionType={sectionType}
                     setSectionType={setSectionType}
                     />
                    </Grid>
                    <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Grid>
                        <Typography className="mainhead" style={{marginTop:'10px',marginBottom:'10px'}}>{casetype}</Typography>
                      </Grid>
                      <Grid style={{ height: 'auto', maxHeight: '50vh', overflow: 'auto' }}>
                        <Grid style={{ height: 'auto', maxHeight: '45vh' }}>
                          {sectionType === "BasicInfo" &&
                            <>

                                <Grid item>
                                
                                <Basicinfo setdata={setdata} data={data} setexpdate={setexpdate} errors={errors} setErrors={setErrors} countryData={countryData} defaultCountry={defaultCountry} setDefaultCountry={setDefaultCountry} stateData={stateData} setStateData={setStateData} setDefaultState={setDefaultState} defaultState={defaultState} caseworkers={caseworkers} subworker={subworker} setsubworker={setsubworker} subworkeremail={subworkeremail} setsubworkeremail={setsubworkeremail} iscreatecase={iscreatecase} edit={edit}/>
                               

                              <Grid item>
                              <HashTag setdata={setdata} data={data} display={display} />
                              </Grid>
                              <Grid item marginTop="10px">
                              <CheckList setdata={setdata} data={data} errors={errors} setErrors={setErrors} expdate={expdate} checkrows={checkrows} setCheckRows={setCheckRows} newRowData={newRowData} setNewRowData={setNewRowData} subworker={subworker} subworkeremail={subworkeremail} setbase64FileList={setbase64FileList} errorData={errorData} checkData={checkData} setDefaultResult={setDefaultResult} resultList={resultList} defaultResult={defaultResult} base64FileList={base64FileList} display={display} />
                              
                              </Grid>
                              
                            
                             </Grid>
                            </>
                          }
                          {sectionType === "Identification" &&
                            <>
                              <Grid item>
                               <ParentCaseInfo data={data} />
                              <IdentificationList identityData={identityData} defaultidentityTypes={defaultidentityTypes} setDefaultIdentityTypes={setDefaultIdentityTypes} setRows={setRows} rows={rows} display={iscreatecase?JSON.parse(iscreatecase):iscreatecase} />
                              </Grid>
                              {/* <Grid container className="btnContainer">
                                <Button className="createcasebtn"
                                //onClick={handleAddClick}
                                >
                                  Save
                                </Button>

                              </Grid> */}
                              
                            </>
                          }
                           {/* {sectionType === "InquiryInfo" &&
                            <>
                              <Grid item>
                                <ParentCaseInfo data={data} />
                              <Inquiry/>
                              </Grid>
                               <Grid container className="btnContainer">
                                <Button className="createcasebtn"
                                //onClick={handleAddClick}
                                >
                                  Save
                                </Button>

                              </Grid>
                              
                            </>
                          } */}
                          {sectionType === "Evidences" &&
                            <>
                              <Grid item>
                             
                               <ParentCaseInfo data={data} />
                          
                                <Evidences setAIfile={setAIfile} caseid={caseid} base64FileList={files} setbase64FileList={setFiles} setNewRowData={false} showHeading={true} show={true}/>
                              </Grid>
                              {/* <Grid container className="btnContainer">
                                <Button className="createcasebtn"
                                //onClick={handleAddClick}
                                >
                                  Save
                                </Button>

                              </Grid> */}
                        
                            </>
                          }
                           {sectionType === "ChildCases" &&
                  <>
                  
                    <Grid item>
                    <ParentCaseInfo data={data} />
                      <ChildCases data={Subcases} setselectedSubCase={setselectedSubCase} selectedCaseID={caseid} />
                    </Grid>
                   
                  </>
                }
                    {    iscreatecase && JSON.parse(iscreatecase) ?  
                           <Grid container className="btnContainer" style={{marginTop:'10px'}}>
                                <Button onClick={handleAddClick} className="createcasebtn">
                                  Create Case
                                </Button>
                                <Button width={200} className="cancelbtn" onClick={handleClose}>
                                  Cancel
                                </Button>

                              </Grid>
                           :   <Actions caseid={caseid} setLoading={setLoading} parentDetails={data} files={files} setFiles={setFiles} ErrMessage={errMsg} setErrMessage={setErrMsg} newcommentsData={newcommentsData} setnewCommentsData={setnewCommentsData} newreplyData={newreplyData} setreplyData={setreplyData} caseType={"KYC"} fetchsubCasesData={fetchsubCasesData} edit={edit} updateSubCase={updateSubCase} isparent={is_parent_case} tasks={tasks}/> 
                        
              }</Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                </Box>
                <Grid style={{ position: "absolute", bottom: 0, width: "70%",backgroundColor: "#F5F5F6" }}>
                  <Drawer tyle={{ position: "absolute", bottom: 0, }} comment={<Comments setnewCommentsData={setnewCommentsData} newcommentsData={newcommentsData} is_new={edit?JSON?.parse(edit):false} show={show} caseid={caseid} newreplyData={newreplyData} setreplyData={setreplyData}/>} />
                </Grid>
              </Grid>
              <Grid style={{ width: '25%', marginTop: '10px', marginRight: '10px' }}>
                <Grid>
                <CaseAudit caseid={caseid ? caseid : ''} refreshTimestamp={refreshTimestamp} />
                </Grid>
              <Grid style={{marginTop:'10px'}}>
              <AI data={data} evidences={AIfiles} setdata={setdata}/>
              </Grid> 
              <Grid style={{ marginTop: "10px" }}>
                {sectionType == 'ChildCases' &&
                  <>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography className="mainhead">Selected Item Info</Typography>
                      </AccordionSummary>
                      <AccordionDetails className="headingspace">
                        <SelectedChildCase selectedSubCase={selectedSubCase} />
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
              </Grid>  
              </Grid>

            </Grid>


          </Grid>
        )}
      </Grid>
    </Grid>

  );
};

export default withSnackbar(KYCTemplate, "Create Case", "success");
