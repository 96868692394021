import { Box, Card, Grid, Paper, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React, { useEffect, useState } from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  getFormGridForMaterialTopic,
  addMaterialTopicFormData,
  getMaterialTopicFormData,
  ListOfYearsForWhichMaterialTopicIsCreated,
} from "../../ESG/services/GraphFetch";
import Button from "@mui/material/Button";
import Modal from "components/Modal";
import { error } from "jquery";
import No_Selection from "../../images/No_Selection.svg";

function MaterialTable(props) {
  const [alignment, setAlignment] = useState("Environment");
  const [DisplayItem, setDisplayItem] = useState("Environmental");
  const [EnvTopicName, setEnvTopicName] = useState();
  const [SocTopicName, setSocTopicName] = useState();
  const [GovTopicName, setGovTopicName] = useState();
  const [OpenselectModal, setOpenselectModal] = useState(false);
  const [TopiName, setTopiName] = useState();
  const [seletedYear, setseletedYear] = useState();
  const [AnswerForGoal, setAnswerForGoal] = useState();

  const fetchDataforDisplay = async () => {
    const EnvTopic = await getFormGridForMaterialTopic("Environmental");
    const SocTopic = await getFormGridForMaterialTopic("Social");
    const GovTopic = await getFormGridForMaterialTopic("Governance");
   
    setEnvTopicName(EnvTopic);
    setSocTopicName(SocTopic);
    setGovTopicName(GovTopic);
  };

  useEffect(() => {
    fetchDataforDisplay();
  }, []);

  const handleChange = (event, newAlignment) => {
    setAlignment(event.target.value);
    setDisplayItem(event.target.value);
  };

  const handleforAddField = (topic) => {
    setDisplayItem(topic);
  };

  const handleChangeForSave = () => {
    addMaterialTopicFormData(
      DisplayItem,
      seletedYear,
      AnswerForGoal.subTopic,
      AnswerForGoal
    );
    setOpenselectModal(false);
  };
  const HandleChangeForGoals = (values, name) => {
   
    setAnswerForGoal((prevData) => {
      return {
        ...prevData,
        year: seletedYear,
        subTopic: TopiName,
        [name]: values,
      };
    });
  };
  useEffect(() => {
    try {
      GetAnswerValue();
    } catch (error) {
      console.log(error);
    }
  }, [seletedYear]);

  const GetAnswerValue = async () => {
    const answervalues = await getMaterialTopicFormData(
      DisplayItem,
      seletedYear,
      TopiName
    );
    // console.log(answervalues[0]?.endDate);
    setAnswerForGoal(answervalues[0]);
  };
  const HandleChangeTonext = (topic) => {
    try {
      if (topic === "Environmental") {
        setDisplayItem("Social");
      } else if (topic === "Social") {
        setDisplayItem("Governance");
      } else if (topic === "Governance") {
       
        props.setpage('Supporting Projects')
      }
    } catch (error) {
      console.error(error);
    }
  };
  const HandleBackButton = () => {
    if (DisplayItem === "Governance") {
      setDisplayItem("Social");
    } else if (DisplayItem === "Social") {
      setDisplayItem("Environmental");
    }
  };

  const FilterButtons=({ FilterType, handleChange })=> {
    return (
      <ToggleButtonGroup
        className="toggleButtons"
        sx={{
          ".MuiToggleButtonGroup-grouped": {
            padding: "8px 18px",
            fontSize: "14px",
          },
        }}
        value={FilterType}
        exclusive
        onChange={handleChange}
        size="small"
      >
        <ToggleButton value="Environmental">Environment</ToggleButton>
        <ToggleButton value="Social">Social</ToggleButton>
        <ToggleButton value="Governance">Governance</ToggleButton>
      </ToggleButtonGroup>
    );
  }
  
  return (
    <Box p={2}>
      <Grid container direction={"column"}>
        <Grid item>
          <Typography sx={{ color: "#061826", fontSize: "20px" }}>
            Goals
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: "#B2B7BC", fontSize: "14px" }}>
            Please Provide Your Inputs For Goals
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-between"} mt={2}>
        <Grid item>
        <FilterButtons
                FilterType={DisplayItem}
                handleChange={handleChange}
              />
        </Grid>
        <Grid item>
          <Grid container direction={"row"} spacing={2}>
            <Grid item sx={{ marginRight: 5 }}>
              <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                Environment
              </Typography>
              <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                in-Progress
              </Typography>
            </Grid>
            <Grid item sx={{ marginRight: 5 }}>
              <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                Social
              </Typography>
              <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                Not started
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                Governance
              </Typography>
              <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                Not started
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        {DisplayItem === "Environmental" ? (
          <>
            {EnvTopicName  && EnvTopicName?.topics?.map((items, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Paper>
                    <Grid container spacing={1} mt={1}>
                      <Grid item container>
                        <Typography sx={{ color: "#25A392", fontSize: "18px" }}>
                          {items}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent={"space-between"}>
                        {EnvTopicName && EnvTopicName?.year?.map((years, index) => {
                          return (
                            <Grid item key={index}>
                              <Grid item>
                                <Typography
                                  sx={{ color: "#B2B7BC", fontSize: "12px" }}
                                >
                                  {years}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    border: "1px dotted",
                                    borderRadius: "20px",
                                    textTransform: "capitalize",
                                  }}
                                  size="small"
                                  onClick={() => {
                                    setOpenselectModal(true);
                                    setseletedYear(years);
                                    setTopiName(items);
                                  }}
                                >
                                  Select
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })
           }
           {EnvTopicName && EnvTopicName?.topics?.length === 0 &&  
           <Grid container sx={{display: 'flex',  justifyContent:'center',flexDirection:"column"}}>
             <img src={No_Selection} alt="No Selection" />
             <Typography textAlign={"center"}>Please complete the materiality assessment before proceeding to set your goals</Typography>
            </Grid>}
          </>
        ) : DisplayItem === "Social" ? (
          <>
            {SocTopicName && SocTopicName?.topics?.map((items, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Paper>
                    <Grid container spacing={1} mt={1}>
                      <Grid item container>
                        <Typography sx={{ color: "#25A392", fontSize: "18px" }}>
                          {items}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent={"space-between"}>
                        {SocTopicName?.year?.map((years, index) => {
                          return (
                            <Grid item key={index}>
                              <Grid item>
                                <Typography
                                  sx={{ color: "#B2B7BC", fontSize: "12px" }}
                                >
                                  {years}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    border: "1px dotted",
                                    borderRadius: "20px",
                                    textTransform: "capitalize",
                                  }}
                                  size="small"
                                  onClick={() => {
                                    setOpenselectModal(true);
                                    setseletedYear(years);
                                    setTopiName(items);
                                  }}
                                >
                                  Select
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
             {SocTopicName && SocTopicName?.topics?.length === 0 &&  
           <Grid container sx={{display: 'flex',  justifyContent:'center',flexDirection:"column"}}>
             <img src={No_Selection} alt="No Selection" />
             <Typography textAlign={"center"}>Please complete the materiality assessment before proceeding to set your goals</Typography>
            </Grid>}
          </>
        ) : (
          <>
            { GovTopicName && GovTopicName?.topics?.map((items, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Paper>
                    <Grid container spacing={1} mt={1}>
                      <Grid item container>
                        <Typography sx={{ color: "#25A392", fontSize: "18px" }}>
                          {items}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent={"space-between"}>
                        {GovTopicName && GovTopicName?.year?.map((years, index) => {
                          return (
                            <Grid item key={index}>
                              <Grid item>
                                <Typography
                                  sx={{ color: "#B2B7BC", fontSize: "12px" }}
                                >
                                  {years}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    border: "1px dotted",
                                    borderRadius: "20px",
                                    textTransform: "capitalize",
                                  }}
                                  size="small"
                                  onClick={() => {
                                    setOpenselectModal(true);
                                    setseletedYear(years);
                                    setTopiName(items);
                                  }}
                                >
                                  Select
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
             {GovTopicName && GovTopicName?.topics?.length === 0 &&  
           <Grid container sx={{display: 'flex',  justifyContent:'center',flexDirection:"column"}}>
             <img src={No_Selection} alt="No Selection" />
             <Typography textAlign={"center"}>Please complete the materiality assessment before proceeding to set your goals</Typography>
            </Grid>}
          </>
        )}
      </Grid>
      <Box>
        <Grid container justifyContent={"space-between"} my={1}>
          <Grid item>
            <Button
              sx={{
                backgroundColor: " #DFE1E3",
                border: "1px solid #CBE5E1",
                textTransform: "capitalize",
                borderRadius: "6px",
                paddingX: "10px",
                color: "#8C959B",
              }}
              onClick={HandleBackButton}
              disabled={DisplayItem === "Environmental"}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: "white",
                marginLeft: "5px",
                color: "#B2B7BC",
                borderRdius: "6px",
                textTransform: "capitalize",
                border: "1px solid #DFE1E3",
              }}
            >
              Skip
            </Button>
            <Button
              sx={{
                backgroundColor: "#25A392",
                marginLeft: "5px",
                color: "white",
                borderRdius: "6px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                HandleChangeTonext(DisplayItem);
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={OpenselectModal}
        setOpen={setOpenselectModal}
        title={`${TopiName},${seletedYear}`}
        actions={
          <Grid container direction={"column"} alignContent={"center"}>
            <Grid item my={1}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  paddingX: "20px",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: " #25a392",
                  },
                }}
                onClick={() => {
                  handleChangeForSave();
                }}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  paddingX: "20px",
                  textTransform: "capitalize",
                  color: "#F37472",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  setOpenselectModal(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        }
        sectionname={"Goal"}
      >
        <Grid container mt={1} spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Year"
                value={seletedYear}
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="startDate"
                type="date"
                value={AnswerForGoal && AnswerForGoal?.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  HandleChangeForGoals(e.target.value, "startDate");
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="EndDate"
                type="date"
                value={AnswerForGoal && AnswerForGoal?.endDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  HandleChangeForGoals(e.target.value, "endDate");
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="value"
                InputLabelProps={{
                  shrink: true,
                }}
                value={AnswerForGoal && AnswerForGoal?.value}
                onChange={(e) => {
                  HandleChangeForGoals(e.target.value, "value");
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
}

export default MaterialTable;
