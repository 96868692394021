import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import "../styles/Forms_Styles/Form_MultiSelect.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelect({ onChange,response_dropdown,getAnswerDisable}) {
  const {Answer}=useContext(NoteContext);
  const countries=response_dropdown
  let Info =[];
  try {
    Info = typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : [];
  } catch (error) {
    Info =[];
  }

  return (<>
    <FormControl fullWidth>
      <InputLabel  id="demo-multiple-checkbox-label">Select</InputLabel>
      <Select
        // variant="standard"
        disabled={getAnswerDisable}
        labelId="multi-select-label"
        multiple fullWidth
        value={Info}
        input={<OutlinedInput label=" Select " />}
        onChange={(event) => {onChange(event.target.value)}}
        renderValue={(selected) => (
          <div >
            {selected.map((value) => (
              <div key={value}>
                {countries.find((option) => option.name === value)?.name}
              </div>
            ))}
          </div>
        )}
        MenuProps={{
          classes: {
            paper: 'menu-paper',
          },
        }}
      >
        {countries?.map((option) => (
          <MenuItem  key={option?.name} value={option?.name}>
            <Checkbox checked={Info.indexOf(option?.name) > -1} />
              <ListItemText primary={option?.name} />
          </MenuItem>
        ))}
      </Select>
      
    </FormControl>
  </>
  );
}