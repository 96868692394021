import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Paper, List, ListItem, TextareaAutosize, ListItemText,TextField,InputAdornment,Toolbar,AppBar } from '@mui/material';
import { caseGenAIResponse } from "Compliance/services/CaseFetch.js";
import CircularProgress from '@mui/material/CircularProgress';
import { remark } from 'remark';
import html from 'remark-html';
import NoteContext from "context/PageContext";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import Help from '@mui/icons-material/Help';
import { styled } from '@mui/system';
import debounce from 'lodash/debounce';
import './ChatButton.css';

const processMarkdown = async (markdown) => {
  const result = await remark().use(html).process(markdown);
  return result.toString();
};

const sections = [
  { id: 'CoAssist', title: 'Co Assist', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...' },
];

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '250px',
  overflow: 'hidden',
  flexDirection: 'row-reverse',
  position: 'relative',
}));

const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  width: expanded ? '100%' : '40px',
  height: expanded ?'fit-content':'40px',
  transition: 'width 0.2s ease-out',
  backgroundColor: expanded ? '#fff' : '#009688',
  color: expanded ? '#fff' : '#fff',
  // margin: '3px',
  '&:hover': {
    backgroundColor: expanded ? '#fff' : '#009688',
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'center',
  },
  '& .MuiTypography-root': {
    writingMode: expanded ? 'horizontal-tb' : 'vertical-rl',
    transform: expanded ? 'none' : 'rotate(180deg)',
    textAlign: 'center',
    transition: 'transform 0.2s ease-out, writing-mode 0.2s ease-out',
  },
}));
const CustomTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '90px', 
  paddingTop: '8px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
    paddingRight: 0,
    '& textarea': {
      paddingTop: '8px',
    },
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#bdbdbd',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#9e9e9e',
    },
    '& .MuiInputAdornment-root': {
      alignSelf: 'flex-end',
    },
    '& .MuiIconButton-root': {
      marginBottom: '28px',
    },
    
  },
 
}));
const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: '#e1f5fe',
  padding: theme.spacing(2),
  maxHeight: 'calc(250px - 60px)', 
  overflowY: 'auto', 
}));
const HorizontalAccordion = React.memo(() => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("AccountDetails"));
  const auth = userDetails?.endUser;
  const ContextData = useContext(NoteContext);

  const fetchApiResponse = async (message) => {
    console.log("ContextData?.UserSelected",ContextData?.UserSelected)
    const obj = {
      input: message,
      session_id: auth?.id,
      domain: ContextData?.UserSelected||"jktech.com"
    };
    const res = await caseGenAIResponse(obj);
    return res?.data?.values;
  };

  const handleSend = async () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput('');
      setLoading(true);
      try {
        const apiResponse = await fetchApiResponse(input);
        const result = await processMarkdown(apiResponse?.output);
        const responseMessage = { text: result, sender: 'api' };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
      } finally {
        setLoading(false);
      }
    }
  };

  const debounceFetch = useRef(
    debounce(async (message) => {
      const apiResponse = await fetchApiResponse(message);
      const result = await processMarkdown(apiResponse?.output);
      const responseMessage = { text: result, sender: 'api' };
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
    }, 300) // Adjust the debounce delay as needed
  ).current;
  

  

  const handleChange = (sectionId) => (event, isExpanded) => {
    setExpandedSection(isExpanded ? sectionId : null);
  };
  const handleClose = () => setExpandedSection(null);
  return (
    <StyledBox style={{height:'auto'}}>
      {sections.map((section) => (
        <StyledAccordion
          key={section.id}
          expanded={expandedSection === section.id}
          onChange={handleChange(section.id)}
        >
          <AccordionSummary>
          {expandedSection === section.id ? (
              <AppBar position="static">
                <Toolbar variant="dense" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',background:'#009688' }}>
                  {/* <IconButton edge="start" color="inherit" aria-label="co-assist">
                    <Help /> 
                  </IconButton> */}
                  <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                    Co Assist
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            ) : (
              <IconButton edge="start" color="inherit" aria-label="co-assist" style={{paddingLeft:'18px',paddingTop:'6px',display:'contents'}}>
                <Help /> {/* Replace with your desired icon */}
              </IconButton>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                flexGrow: 1,
                maxHeight: '200px',
                overflowY: 'auto',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                wordBreak: 'break-word',
              }}
            >
              <List>
                {messages.map((msg, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <Paper
                      sx={{
                        p: 1,
                        bgcolor: msg.sender === 'user' ? '#cfe9ff' : '#e1f5fe',
                        maxWidth: '70%',
                        alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                      }}
                    >
                      {msg.sender === 'api' ? (
                        <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                      ) : (
                        <ListItemText primary={msg.text} />
                      )}
                    </Paper>
                  </ListItem>
                ))}
                {loading && (
                  <ListItem
                    sx={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <Paper
                      sx={{
                        p: 1,
                        bgcolor: '#e1f5fe',
                        maxWidth: '70%',
                        alignSelf: 'flex-start',
                      }}
                    >
                      <div className="typing-indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Paper>
                  </ListItem>
                )}
           
              </List>
            </Box>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                borderTop: '1px solid #ddd',
              }}
            >
              <TextareaAutosize
                minRows={2}
                style={{ width: '90%' }}
                placeholder="Type a message"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
              />
              <IconButton
                color="primary"
                onClick={handleSend}
                disabled={loading}
              >
                <SendIcon />
              </IconButton>
            </Box> */}
            <CustomTextField
      variant="outlined"
      placeholder="Ask me anything..."
      fullWidth
      //multiline
      style={{height:'60px'}}
      // rows={2}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
            onClick={handleSend}
            disabled={loading}
            >
              <SendIcon style={{color:'#009688'}}/>
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={input} 
      onChange={(e) => setInput(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleSend();
        }
      }}
    />

          </AccordionDetails>
        </StyledAccordion>
      ))}
    </StyledBox>
  );
});

export default HorizontalAccordion;
