import React, { useEffect, useState, useContext } from "react";
import { Grid, LinearProgress } from "@mui/material";
import "../styles/Home/home.css";
import DisclosureInfoCard from "../../components/DisclosureInfoCard";
import DisclosureInfoList from "../../components/DisclosureInfoList";
import NoteContext from "../../context/PageContext";

const Home = () => {
  const contextData = useContext(NoteContext);
  const [FillingData, setFillingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getGriDashboard } = require("../services/Fetch");
  //this is filling data
  useEffect(() => {
    contextData.setPathname(false);
    const getDashboardData = async () => {
      try {
        const data = await getGriDashboard();
        contextData.setFillingData(data);
        //console.log(data, 'enterd')
        setFillingData(data.data?.analytics?.disclosures);
         setLoading(false);
    } catch (error) {
      console.error('fiilingdata error:',error);
      contextData.setUserLogInError({
        message: error.message,
        Errors: true
      });
      setLoading(false);
    }
    };
    getDashboardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {loading && <LinearProgress />}
      {!contextData.TypeDisplay &&
        (contextData.Type === false ? (
          <>
            <Grid
              container
              spacing={{ xs: 1, md: 1 }}
              columns={{ xs: 6, sm: 9, md: 12, lg: 12 }}
              elevation={15}
            >
              {FillingData &&
                // eslint-disable-next-line array-callback-return
                FillingData?.filter((val) => {
                  if (contextData.Search === "") {
                    return val;
                  } else if (
                    val.filing
                      .toLowerCase()
                      .includes(contextData.Search.trim().toLowerCase())
                  ) {
                    return val;
                  }
                }).map((item, i) => (
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={3}
                    sm={3}
                    md={3}
                    lg={2}
                    key={i}
                  >
                    <DisclosureInfoCard
                      title={item.filing}
                      subtitle={"Completed:" + Math.round(item.progress) + "%"}
                      progressValue={Math.round(item.progress)}
                      StartDate={item.start}
                      EndDate={item.end}
                      Deadline={item.deadline}
                      Enviroment={
                        (Math.round(item.environmental * 100) / 100).toFixed(
                          2
                        ) + "%"
                      }
                      Social={
                        (Math.round(item.social * 100) / 100).toFixed(2) + "%"
                      }
                      Governance={
                        (Math.round(item.governance * 100) / 100).toFixed(2) +
                        "%"
                      }
                      Status={item.status}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        ) : (
          <DisclosureInfoList data={FillingData}  SearchData={contextData.Search} />
        ))}
    </div>
  );
};

export default Home;

