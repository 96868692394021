import React, {useRef} from 'react';
import JoditEditor from 'jodit-react';
import { Box } from '@mui/material';
import '../styles/Forms_Styles/Form_RTE.css'
import { useContext } from "react";
import NoteContext from "context/PageContext";
// import { useEffect } from 'react';

const CustomInlineToolbar = ({ onChange,getAnswerDisable}) => {
    const {Answer}=useContext(NoteContext);
    const editor = useRef(null);
    const config = {
        readonly: getAnswerDisable, // If true, editor is disabled
        // You can add other Jodit configuration options here
      };
    return (<Box className='InputFields'>
    <JoditEditor 
        ref={editor}
        value={typeof Answer?.UpdatedAnswer === "string" ? Answer?.UpdatedAnswer :  ""}
        tabIndex={1} 
        //onChange={(newValue)=>onChange(newValue)}
        onBlur={(newValue)=>onChange(newValue)}
        config={config}
        />
        </Box>
    )
}

export default CustomInlineToolbar