import * as React from 'react';
import {  TextField } from "@mui/material";
import "../styles/Forms_Styles/Form_Number.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

export default function Number({onChange,getAnswerDisable}) {
  const {Answer}=useContext(NoteContext);
 
  return (
        <TextField
             id="outlined-basic"
             type='number'
             inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
             placeholder='Enter value here'
             variant="outlined"
             fullWidth
             value={typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer}
             onChange={(e)=>onChange(e.target.value)}
             size='small'
             disabled={getAnswerDisable}
          />
  );
}
