import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

function SixthPage(props) {
  const [Question, SetQuestion] = useState();

  const [Isloading, setIsloading] = useState(false);
  const [Answer, setAnswer] = useState();
  const [Question17, setQuestion17] = useState();
  const [QuestionNumber, setQuestionNumber] = useState();

  // //--------------Creating table header
  const CreateHeader1 = () => {
    const column = Object.keys(Question17[0]);
    const newcolumn = column.map((x) => {
      if (x !== "id" && x !== "isNew") {
        return <TableCell key={x}>{x}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  //-------get table row data
  const tdData1 = () => {
    return Question17.map((data, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question17[0]).map((x) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell key={x} component="th" scope="row">
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
    const FetchData = () => {
      //fetching table data for first question
      // setQuestion1((Object.values(props.data.reportData["Section 1a-Item B"])[0]).answer);

      //console.log(((Object.values(props.data.reportData["Section 1a-Item B"])[0]).answer)[0]);

      //getting section 1a question in single array
      //    const questions_array_a=[];

      //    for(let i=0;i<(Object.keys(props.data.reportData["Section 1a-Item B"]).length);i++)
      //    {
      //     questions_array_a.push((Object.keys(props.data.reportData["Section 1a-Item B"])[i]));
      //     QuestioNumArray.push(((Object.values(props.data.reportData["Section 1a-Item B"])[0]).qno).substr(5,((Object.values(props.data.reportData["Section 1a-Item B"])[0]).qno).length));
      //    }
      //    //console.log(questions_array);
      //    SetQuestions(questions_array_a);

      //getting section 1b question in single array
      const questions_array = [];
      const Answer_array = [];
      const QuestioNumArray = [];
      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Section 1b-Item C"]).length;
        i++
      ) {
        questions_array.push(
          Object.keys(props.data.reportData["Section 1b-Item C"])[i]
        );
        Answer_array.push(
          Object.values(props.data.reportData["Section 1b-Item C"])[i].answer
        );
        QuestioNumArray.push(
          Object.values(props.data.reportData["Section 1b-Item C"])[
            i
          ].qno.substr(
            5,
            Object.values(props.data.reportData["Section 1b-Item C"])[i].qno
              .length
          )
        );
      }
      //console.log(questions_array);
      SetQuestion(questions_array);
      setQuestionNumber(QuestioNumArray);
      setAnswer(Answer_array);
      setQuestion17(
        Object.values(props.data.reportData["Section 1b-Item C"])[0].answer
      );
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <React.Fragment>
      {Isloading && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
                <li>Section 1a</li>
                <li style={{ color: "Black", fontSize: "20px" }}>
                  <b>Section 1b</b>
                </li>
                <li>Section 1c</li>
                <li>Section 2a</li>
                <li>Section 2b</li>
                <li>Section 3</li>
                <li>Section 4</li>
                <li>Section 5</li>
              </ul>
            </Grid>

            <Grid item xs={12} md={10}>
              {/* -------------------- section 1a question & table -----------------------------*/}

              {/* ------section 1b question and answers -----------*/}
              <div>
                {Question.map((x, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div>
                        
                          <b>{QuestionNumber[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          {/* <br/>
           {Answer[index]} */}
                       

                        <div
                          style={{ justifyContent: "right", display: "flex" }}
                        >
                          <TableContainer>
                            <Table
                              sx={{ minWidth: 650, width: "50%" }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 1,
                                    },
                                  }}
                                >
                                  {CreateHeader1()}
                                </TableRow>
                              </TableHead>

                              {tdData1()}
                            </Table>
                          </TableContainer>
                        </div>
                        <br />
                      </div>
                      <br />
                    </React.Fragment>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default SixthPage;
