import React, { useEffect, useState,useContext } from "react";
import {
    Box,
  } from "@mui/material";
  import CircularProgress from '@mui/material/CircularProgress';
import withSnackbar from "../../components/Alert/withSnackbar"; 
import '../styles/Case_Managment/Loader.css'

const Loader=({withSnackbar})=>{
return(
    <>

<Box
         display="flex"
         justifyContent="center"
         alignItems="center"
         height="100vh"
         width="100%"
         position="fixed"
         top={0}
         left={0}
         bgcolor="rgba(255, 255, 255, 0.7)" // You can adjust the background color and opacity
       >
         <CircularProgress />
       </Box>
        {/* <div className="loaders">Loading...</div> */}
    </>
)
}


export default withSnackbar(Loader, "View All Cases", "success");