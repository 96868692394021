import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function SixteenPage(props) {
  const [Questions, SetQuestions] = useState();
  const [Isloading, setIsloading] = useState(false);
  //const [Question1,setQuestion1]=useState();
  const [Answer, setAnswer] = useState();
  const [QuestionNum, setQuestionNumber] = useState();
 

  useEffect(() => {
    const FetchData = () => {
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber = [];
      //getting section 1b question in single array

      for (
        let i = 0;
        i <
        Object.keys(props.data.reportData["Identifying Information"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Identifying Information"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Identifying Information"])[i]
            .answer
        );
        QuestionNumber.push(
          Object.values(props.data.reportData["Identifying Information"])[
            i
          ].qno.substr(8,Object.values(props.data.reportData["Identifying Information"])[i].qno.length
          )
        );
      }
    //  console.log(Answer_array_b);
      SetQuestions(questions_array_b);
      setAnswer(Answer_array_b);
     // console.log(Answer_array_b);
      setQuestionNumber(QuestionNumber);
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  bgcolor: "lightgray",
                  position: "sticky",
                  top: "0",
                  padding: "2px",
                  height: "100",
                }}
              >
                <ul
                  style={{
                    listStyleType: "square",
                    textAlign: "left",
                    fontSize: "22px",
                  }}
                >
                  <li>Identifying Information</li>
                </ul>
              </Grid>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                
                  {Questions.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {index === 2 ? (<h6>{Answer[index].map((itimes)=>{
                            return itimes
                          })}</h6>):(
                            index === 3 ?(<h6 dangerouslySetInnerHTML={{__html:Answer[index]}}></h6>):(
                              index === 6 ?(<>

                                {Answer[index].map((ele, index1) => (
      
                                  <React.Fragment key={index1}>
      
                                    {ele.newStreet}&nbsp;
      
                                    {ele.newStreet2}&nbsp;
      
                                    {ele.newCity}&nbsp;
      
                                    {ele.newState}&nbsp;
      
                                    {ele.newCountry.CountryName}&nbsp;
      
                                    {ele.newZip}&nbsp;
      
                                  </React.Fragment>
      
                                ))}
      
                              </>):( index === 8 ? (<ol>{Answer[index].map((itimes)=>{
                                return itimes;
                              })}</ol>):(
                                index === 10 ?(<h6>{Answer[index].value} : {Answer[index].Amount}</h6>):(
                                  index === 12 ? (
                                    <>

                                {Answer[index].map((ele, index1) => (
      
                                  <React.Fragment key={index1}>
      
                                    {ele.newStreet}&nbsp;
      
                                    {ele.newStreet2}&nbsp;
      
                                    {ele.newCity}&nbsp;
      
                                    {ele.newState}&nbsp;
      
                                    {ele.newCountry.CountryName}&nbsp;
      
                                    {ele.newZip}&nbsp;
      
                                  </React.Fragment>
      
                                ))}
      
                              </>
                                  ):(
                                    <h6>{Answer[index]}</h6>
                                  )

                                )
                              ))
                            )
                          )}
                       
                        <br />
                      </React.Fragment>
                    );
                  })}
            
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default SixteenPage;
