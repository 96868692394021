import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import "../styles/OnBoard/CustOnBoard.css";
import Form_Text_input from "ESG/forms/Form_Text_input";
import Form_Address from "ESG/forms/Form_Address";
import Number from "ESG/forms/Form_Number";
//import { getTelephoneNumber } from "services/Fetch";
import Grid from "@mui/material/Grid";
import Form_Autocomplete from "ESG/forms/Form_Autocomplete";
import { GetSicCode, GetTypeOfCompany } from "ESG/services/Fetch";

const CustOnBoardPage = ({ formData, setFormData }) => {
  const [SicCodes, setSicCodes] = useState();
  const [getTypeOfCompany, setgetTypeOfCompany] = useState();

  const getsiccode = async () => {
    const res = await GetSicCode();

    setSicCodes(Object.values(res).toString());
  };

  const gettypeOfCompany = async () => {
    const res = await GetTypeOfCompany();

    setgetTypeOfCompany(Object.values(res).toString());
  };

  useEffect(() => {
    getsiccode();
    gettypeOfCompany();
  }, []);

  return (
    <div className="form-content">
      <div className="form-boxx">
        <h5 style={{ textAlign: "center" }}>
          <b>Customer on-boarding</b>
        </h5>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">Legal Name</Typography>
          </Grid>
          <Form_Text_input
            Answer={formData.legalName}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, legalName: e };
              })
            }
          />
          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">Parent Organization</Typography>
          </Grid>

          <Form_Text_input
            Answer={formData.parentOrganization}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, parentOrganization: e };
              })
            }
          />
          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">CIK</Typography>
          </Grid>

          <Number
            Answer={formData.cik}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, cik: e };
              })
            }
          />

          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">
              Standard Industrial Classification(SIC)
            </Typography>
          </Grid>

          <Form_Autocomplete
            FieldValues={SicCodes ?? ""}
            Answer={formData.sic}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, sic: e };
              })
            }
          />

          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">Type of Organization</Typography>
          </Grid>

          <Form_Autocomplete
            Answer={formData.typeOfCompany}
            FieldValues={getTypeOfCompany ?? ""}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, typeOfCompany: e };
              })
            }
          />

          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">Headquarters</Typography>
          </Grid>

          <Form_Address
            Answer={formData.HQAddress}
            ListOfAddress={false}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, HQAddress: e };
              })
            }
          />
          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">Incorporation Detail</Typography>
          </Grid>

          <Form_Text_input
            Answer={formData.incorporationDetail}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, incorporationDetail: e };
              })
            }
          />

          <Grid item xs={12} md={6}>
            <Typography className="Font-Size ">Holding</Typography>
          </Grid>

          <Form_Text_input
            Answer={formData.holding}
            onChange={(e) =>
              setFormData((prevstate) => {
                return { ...prevstate, holding: e };
              })
            }
          />
        </Grid>

        <div className="form-button"></div>
      </div>
    </div>
  );
};

export default CustOnBoardPage;
