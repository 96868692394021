import React, { useEffect, useState, useContext } from "react";
import NoteContext from "context/PageContext";
import {
  Grid,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import withSnackbar from "components/Alert/withSnackbar";
import Loader from "../Loader";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "../../../Compliance/styles/Case_Managment/CreateCase.css"
import Evidences from "Compliance/Components/Evidences";
import Comments from "Compliance/Components/Comments";
import AI from "Compliance/Components/AI";
import CaseAudit from "../CaseAudit";
import Drawer from "Compliance/Components/Drawer";
import { Refresh } from "@mui/icons-material";
import SectionButtons from "Compliance/Components/SectionButtons";
import Responsebox from "Insurance/CaseManagement/Responsebox";
import InsuranceBasicInfo from "Insurance/CaseManagement/InsuranceBasicInfo";
import InsuranceParentCaseInfo from "Insurance/CaseManagement/InsuranceParentCaseInfo";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
import {INSURANCE,ESG,sectionList,insurancesectionList} from "Insurance/CaseManagement/Config.js"
import ChildCases from "Compliance/Components/ChildCases";
import SelectedChildCase from "Compliance/Components/SelectedChildCase";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Actions from "./Actions";
import ParentCaseInfo from "Compliance/Components/ParentCaseInfo";
import CaseDetails from "../CaseDetails";

const InsuranceTemplate = ({ showSnackbar,userRole,iscreatecase,caseid,edit,childcase }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {templateType} = useTemplateType();
  const {
    CreateCases,
    getCountryList,
    getUserList,
    getCaseById,
    getChildCaseById,
    updateSubCaseWithAttachmentsComments
  } = require("../../services/CaseFetch");

  const params = new URLSearchParams(window.location.search);
  const casetype = atob(params.get("case_type"));
  const casecode = atob(params.get("case_code"));
  const tasks = params.get("tasks") ? JSON.parse(atob(params.get("tasks"))) : '';
  const is_parent_case = params.get("parent_case") ? JSON.parse(atob(params.get("parent_case"))) : '';
  const show = params.get("show") ? JSON.parse(atob(params.get("show"))) : '';
  const display = caseid ? false : true
  const [sectionType, setSectionType] = useState("BasicInfo");
  const [hasCaseCreatorRole, setHasCaseCreatorRole] = useState();
  const [showCardRow, setShowCardRow] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "create-case-button" : undefined;
  const [fileDataArray, setFileDataArray] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [rows, setRows] = useState([{ Type: "", IdentificationNo: "" }]);
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");
  const userDetails = JSON.parse(localStorage.getItem("AccountDetails"));
  const auth = userDetails?.endUser;
  const [insurancedata, setinsurancedata] = useState({
    subcategory:"",
    caseTitle: "",
    dateOpened: "",
    dateClosed: "",
    regulatoryAuthority: "",
    Status: "",
    assignedTo: "",
    CaseDate: formattedDate,
    description: "",
    CaseAttachment: "",
    case_type: "",
    creator_email: auth?.mail,
    creator_id: auth?.mail,
    creator: auth?.displayName,
    checksdata: [],
    comment: "",
    current_case_worker: "",
    current_case_worker_email: "",
    childcases: [],
    department:"",
    response:""
  });

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [checkErrMsg, setCheckErrMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [caseworkers, setcaseworkers] = useState([]);
  const [subworker, setsubworker] = useState("");
  const [subworkeremail, setsubworkeremail] = useState("");
  const [newcommentsData, setnewCommentsData] = useState([]);
  const [Subcases, setSubcases] = useState();
  const [selectedSubCase, setselectedSubCase] = useState({ CaseId: "" });
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());
  const [newreplyData, setreplyData] = useState([]);
  const [ctype,setctype]=useState()
  const [AIfiles,setAIfile]=useState([])
  useEffect(() => {
    setHasCaseCreatorRole(
      ContextData?.CaseRole?.displayName === "Case Creator" ? true : false
    );

    // setdata({ ...data, case_type: casetype });
  }, [ContextData?.CaseRole?.displayName, hasCaseCreatorRole]);
  const fetchCasesData = async (caseid,cType) => {
    // Fetch data from your API based on the current page
    
    //ContextData.setselectedCaseType(cassetype)
    try {
      if(JSON.parse(is_parent_case)){
        setLoading(true)
      const [userlist,case_detail] = await Promise.all([
        getUserList(),
        getCaseById(caseid,cType)
      ]);

      setcaseworkers(userlist?.data)
      
      const details = case_detail.data.parent_case[0]
    
        setinsurancedata({
          parentid: caseid,
          caseTitle:details?.caseTitle,
          dateOpened:details?.dateOpened,
          regulatoryAuthority:details?.regulatoryAuthority,
          description:details?.Description,
          response:details?.response,
          subcategory:details?.subcategory,
          department:details?.department,
          current_case_worker:details?.CurrentCaseWorker,
          current_case_worker_email:details?.CurrentCaseWorkerEmail,
          dateClosed:details?.dateClosed,
          case_type:details?.CaseType,
          Status:details?.Status
        })
        setctype(details?.CaseType)
       setSubcases(case_detail.data.AllSubcase);
       //setfilteredRows(case_detail.data.AllSubcase);
       setselectedSubCase(case_detail.data.AllSubcase[0]);
     // setIsShow(details?.Status == 'close' ? false : true)
     setLoading(false);
      }
      else{
        setLoading(true);
        const [casedata, caseworkerlist,casereviewerlist,country] = await Promise.all([
          getChildCaseById(caseid),
          getUserList(),
         // getAllCaseReviewer(),
         // getCountryList(),
          // getUserList('CaseSupervisor') , commentsRes, cdata, logdata,userslist,supervisorlist
        ]);
   
     
  
        setcaseworkers(caseworkerlist?.data)
       // setCaseReviewersList(casereviewerlist?.data)
        //setCaseReviewerEmail(casereviewerlist?.data[0]?.email)
        //setCaseReviewer(casereviewerlist?.data[0]?.name)
        
        var res = casedata.data;
        setinsurancedata({
          parent_case_id:res?.parentCase[0]?.ParentCaseId,
          current_case_worker: res?.subCase[0]?.CurrentCaseWorker || '',
        current_case_worker_email: res?.subCase[0]?.CurrentCaseWorkerEmail || '',
        current_case_supervisor: 'Deepak@jktech.com,Deepak', //res.CurrentCaseSupervisor||
        current_case_supervisor_email: 'Deepak@jktech.com', //res.CurrentCaseSupervisorEmail||
        description: res?.subCase[0]?.ExceptionDescription,
        type:res?.subCase[0]?.subcategory,
        caseTitle:res?.subCase[0]?.caseTitle,
        dateOpened:res?.parentCase[0]?.dateOpened,
        regulatoryAuthority:res?.parentCase[0]?.regulatoryAuthority,
        department:res?.subCase[0]?.department,
        dateClosed:res?.parentCase[0]?.dateClosed,
        response:res?.subCase[0]?.response,
        subcategory:res?.subCase[0]?.subcategory,
        case_type:res?.parentCase[0]?.CaseType,
        Status:res?.subCase[0]?.Status
         })
         setctype(res?.parentCase[0]?.CaseType)
         setLoading(false);
      }
      

      
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if needed
    }
  };
  const [showChildCases, setShowChildCases] = useState(is_parent_case?JSON.parse(is_parent_case):'');
  var filteredSectionList= insurancesectionList.filter(section => {
    if (section.value === "ChildCases") {
      return showChildCases;
    }
    return true;
  });
  useEffect(() => {
   
    if(caseid){
      fetchCasesData(caseid,"Insurance")
    }
    else{
      fetchData();
    }
    setSectionType("BasicInfo")
  
  }, [caseid]);
  useEffect(() => {
    setShowChildCases(is_parent_case?JSON.parse(is_parent_case):'');
  }, [is_parent_case]);
  // useEffect(() => {
  //   if (insurancesectionList.length > 0) {
  //     const filteredList = insurancesectionList.filter(section => {
  //       if (section.value === "ChildCases") {
  //         return showChildCases;
  //       }
  //       return true;
  //     });
  //     setfilteredSectionList(filteredList);
  //   }
  // }, [insurancesectionList, showChildCases]);

  const fetchData = async () => {
    try {
      const [userslist] =
        await Promise.all([
          getUserList()
        ]);

   
      setcaseworkers(userslist.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar("unable to fetch data", "error");
    }
  };




  const handleClose = () => {
    setinsurancedata({
        subcategory:"",
        caseTitle: "",
        dateOpened: "",
        dateClosed: "",
        regulatoryAuthority: "",
        Status: "",
        assignedTo: "",
        CaseDate: formattedDate,
        description: "",
        CaseAttachment: "",
        case_type: "",
        creator_email: auth?.mail,
        creator_id: auth?.mail,
        creator: auth?.displayName,
        checksdata: [],
        comment: "",
        current_case_worker: "",
        current_case_worker_email: "",
        childcases: [],
        department:"",
        response:""
    });
    setFileDataArray([]);
    setAnchorEl(null);
    setIsEditing(false);
    setIsViewMode(false);
    setShowCardRow(true);
    navigate("/caseManagement");
  };

  const handleAddClick = () => {
 
    const newCase = {
      case: {
        description: insurancedata.description,
        caseTitle: insurancedata.caseTitle,
        dateOpened: insurancedata.dateOpened,
        dateClosed: insurancedata.dateClosed,
        regulatoryAuthority: insurancedata.regulatoryAuthority,
        assignedTo: insurancedata.assignedTo,
        creator_email: insurancedata.creator_email,
        creator_id: insurancedata.creator_id,
        creator: insurancedata.creator,
        current_case_worker: insurancedata.current_case_worker,
        current_case_worker_email: insurancedata.current_case_worker_email,
        application_date:formattedDate,
        customer_id:"",
        case_type:casecode,
        account_opening_date:insurancedata.dateOpened,
        case_name:casetype,
        department:insurancedata.department,
        response:insurancedata.response,
        subcategory: insurancedata.subcategory,
      },
      checks: [{
        type: casecode,
        date_conducted: insurancedata.dateOpened,
        conducted_by:insurancedata.current_case_worker,
        current_case_worker: insurancedata.current_case_worker,
        current_case_worker_email: insurancedata.current_case_worker_email,
        expected_closure_date: insurancedata.dateClosed,
        result: "Fail",
        link_to_evidence: "",
        exception: {
          exception_type: insurancedata.subcategory,
          error_code: "",
          expected_resolution_by: insurancedata.dateClosed,
          exception_description: insurancedata.description,
        },
        sub_attachments: [],
      }],
      comment: newcommentsData,
      case_attachments: files,
      templateType:"Insurance"
    };
    const createcase_body = {
      body: newCase,
      domain: ContextData.UserSelected,
      case_type: casecode,
      //case_type: "KYC",
      
    };
    createCase(createcase_body);

    setAnchorEl(null);
  
  };
  //Create Case function
  const createCase = async (info) => {
    try {
      showSnackbar("Creating new case please wait... ", "info");
      setLoading(true);
      const res = await CreateCases(info);
      if (res.status == 200) {
        clearForm();
        // setCheckRows([]);
        setFileDataArray([]);
        setLoading(false);

        navigate("/caseManagement");
        showSnackbar("New case Created Successfully", "success");
      }
    } catch (error) {
      console.error(error);
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true,
      });
      setLoading(false);
      showSnackbar("Unable to create Case", "error");
    }
  };

  const clearForm = () => {
      setinsurancedata({
        subcategory:"",
        caseTitle: "",
        dateOpened: "",
        dateClosed: "",
        regulatoryAuthority: "",
        Status: "",
        assignedTo: "",
        CaseDate: "",
        description: "",
        CaseAttachment: "",
        case_type: "",
        creator_email: "",
        creator_id: "",
        creator: "",
        checksdata: [],
        department:"",
        comment: "",
        current_case_worker: "",
        current_case_worker_email: "",
        childcases: [],
        department:"",
        response:""
      });
  }



 

  const updateSubCase=async()=>{
    var desc,date,res='';
   
    var param={
      param:{
        SubCaseId:caseid, 
        domain:ContextData.UserSelected
      },
      body:{
        
        ExceptionDescription: insurancedata.description,
        ExpectedResolutionBy:insurancedata.dateClosed,
        sub_attachments:files,
        comment:[...newcommentsData,...newreplyData],
        response:insurancedata.response
      }
    }
    try{
      setLoading(true)
   var res=await updateSubCaseWithAttachmentsComments(param)
   if (res.status == 200) {
   setFiles([])
   setnewCommentsData([])
   setreplyData([])
    fetchData();
    setLoading(false)
  }
}catch(e){
showSnackbar(e.response.data.message||"error in updating case","error")
}
  } 
  return (
    <Grid style={{ background: "#F5F5F6" }}>
      <Grid className="mainheading" style={{ padding: "15px 0px 0px 15px", display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
        <Grid>
          <ArrowBackIosIcon onClick={() => { navigate("/caseManagement") }} />
          {caseid ?  "View Case" :"Create Case" }

        </Grid>
        <Grid>
        {JSON.parse(iscreatecase) &&  <Button className="undobtn" onClick={clearForm}><Refresh />Undo</Button> }
        </Grid>
      </Grid>
      <Grid>
        {loading ? (
          <Loader />
        ) : (
          <Grid style={{ backgroundColor: "#F5F5F6",background: "#F5F5F6"}}>
            <Grid style={{ display: 'flex', backgroundColor: "#F5F5F6",justifyContent:'space-between',background: "#F5F5F6" }}>
              <Grid style={{ width: '74%', display: "flex", flexDirection: "column",background: "#F5F5F6" }}>
                <Box sx={{}}>
                  {errMsg || checkErrMsg ? (
                    <Grid style={{ paddingTop: "10px", margin: "0px 10px" }}>
                      <Grid className="rejectbox">
                        <InfoIcon className="rejectHeading" style={{ fontSize: "32px", marginRight: "10px" }} />
                        <Grid>
                          <Typography className="rejecttxt">
                            {errMsg ? errMsg : ""}
                          </Typography>
                          <Typography className="rejecttxt">
                            {checkErrMsg ? checkErrMsg : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid px={2} py={1} spacing={2} className="boxcard" style={{ height: "70vh" }}>
                    <Grid>
                    
                     <SectionButtons
                     list={filteredSectionList}
                     sectionType={sectionType}
                     setSectionType={setSectionType}
                     />
                    </Grid>
                    <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Grid>
                        <Typography className="mainhead" style={{marginTop:'10px',marginBottom:'10px'}}>{casetype}</Typography>
                      </Grid>
                      <Grid style={{ height: 'auto', maxHeight: '50vh', overflow: 'auto' }}>
                        <Grid style={{ height: 'auto', maxHeight: '45vh' }}>
                          {sectionType === "BasicInfo" &&
                            <>

                                <Grid item>
                                <InsuranceBasicInfo casetype={JSON.parse(iscreatecase)?casetype:ctype} insurancedata={insurancedata} setinsurancedata={setinsurancedata} caseworkers={caseworkers} subworker={subworker} setsubworker={setsubworker} subworkeremail={subworkeremail} setsubworkeremail={setsubworkeremail} iscreatecase={iscreatecase} edit={edit}/>
                             </Grid>
                            </>
                          }
                       
                           {/* {sectionType === "InquiryInfo" &&
                            <>
                              <Grid item>
                                <ParentCaseInfo data={data} />
                              <Inquiry/>
                              </Grid>
                               <Grid container className="btnContainer">
                                <Button className="createcasebtn"
                                //onClick={handleAddClick}
                                >
                                  Save
                                </Button>

                              </Grid>
                              
                            </>
                          } */}
                          {sectionType === "Evidences" &&
                            <>
                              <Grid item>
                              <InsuranceParentCaseInfo data={insurancedata}/>
                                <Evidences setAIfile={setAIfile} caseid={caseid} base64FileList={files} setbase64FileList={setFiles} setNewRowData={false} showHeading={true} show={true} edit={edit}/>
                              </Grid>
                              {/* <Grid container className="btnContainer">
                                <Button className="createcasebtn"
                                //onClick={handleAddClick}
                                >
                                  Save
                                </Button>

                              </Grid> */}
                                {/* <Grid container className="btnContainer" style={{marginTop:'10px'}}>
                                <Button onClick={handleAddClick} className="createcasebtn">
                                  Create Case
                                </Button>
                                <Button width={200} className="cancelbtn" onClick={handleClose}>
                                  Cancel
                                </Button>

                              </Grid> */}
                            </>
                          }
                          {sectionType === "Documents" &&
                            <>
                              <Grid item>
                              <InsuranceParentCaseInfo data={insurancedata}/>
                                <Evidences setAIfile={setAIfile} caseid={caseid} base64FileList={files} setbase64FileList={setFiles} setNewRowData={false} showHeading={true} show={true} edit={edit}/>
                              </Grid>
                              {/* <Grid container className="btnContainer">
                                <Button className="createcasebtn"
                                //onClick={handleAddClick}
                                >
                                  Save
                                </Button>

                              </Grid> */}
                             
                            </>
                          }
                         {sectionType === 'ResponseBox' &&
                         <>
                         <Grid item>
                         <InsuranceParentCaseInfo data={insurancedata}/>
                              <Responsebox insurancedata={insurancedata} setinsurancedata={setinsurancedata} iscreatecase={iscreatecase}/>
                           </Grid>
                           </>   
                         }
                         {sectionType === "ChildCases" &&
                  <>
                  
                    <Grid item>
                    <InsuranceParentCaseInfo data={insurancedata}/>
                      <ChildCases data={Subcases} setselectedSubCase={setselectedSubCase} selectedCaseID={caseid} />
                    </Grid>
                   
                  </>
                }
            {    iscreatecase && JSON.parse(iscreatecase) ?    
               <Grid container className="btnContainer" style={{marginTop:'10px'}}>
               <Button onClick={handleAddClick} className="createcasebtn">
                 Create Case
               </Button>
               <Button width={200} className="cancelbtn" onClick={handleClose}>
                 Cancel
               </Button>

             </Grid>:
             <Actions caseid={caseid} setLoading={setLoading} parentDetails={insurancedata} files={files} setFiles={setFiles} ErrMessage={errMsg} setErrMessage={setErrMsg} newcommentsData={newcommentsData} setnewCommentsData={setnewCommentsData} newreplyData={newreplyData} setreplyData={setreplyData} caseType={"Insurance"} fetchsubCasesData={fetchCasesData} edit={edit} updateSubCase={updateSubCase} isparent={is_parent_case} tasks={tasks}/>
            }          
             </Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                </Box>
                <Grid style={{ position: "absolute", bottom: 0, width: "70%",backgroundColor: "#F5F5F6" }}>
                  <Drawer tyle={{ position: "absolute", bottom: 0, }} comment={<Comments setnewCommentsData={setnewCommentsData} newcommentsData={newcommentsData} is_new={edit?JSON?.parse(edit):false} show={show} newreplyData={newreplyData} setreplyData={setreplyData} caseid={caseid}/>} />
                </Grid>
              </Grid>
              <Grid style={{ width: '25%', marginTop: '10px', marginRight: '10px',}}>
                <Grid>
                <CaseAudit  caseid={caseid ? caseid : ''} refreshTimestamp={refreshTimestamp} />
                </Grid>
              <Grid style={{marginTop:'10px'}}>
              <AI data={insurancedata} evidences={AIfiles} setdata={setinsurancedata}/>
              </Grid>   
              <Grid style={{ marginTop: "10px" }}>
                {sectionType == 'ChildCases' &&
                  <>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography className="mainhead">Selected Item Info</Typography>
                      </AccordionSummary>
                      <AccordionDetails className="headingspace">
                        <SelectedChildCase selectedSubCase={selectedSubCase} />
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
              </Grid>
              </Grid>

            </Grid>


          </Grid>
        )}
      </Grid>
    </Grid>

  );
};

export default withSnackbar(InsuranceTemplate, "InsuranceTemplate", "success");
