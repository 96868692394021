import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const Labeling1 = (props)  =>{
    const [labeling,setlabeling] = useState()
    const [procedures,setprocedures] = useState()


   
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
      setlabeling((Object.values(props.data.reportData["GRI 417.1 Requirements for product and service information and labeling"])[1]).answer)
         setprocedures((Object.values(props.data.reportData["GRI 417.1 Requirements for product and service information and labeling"])[3]).answer)
         
         
        
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h5 className="underline">Requirements for product and service information and labeling</h5>
                        <h6><b>Providing information on the sourcing of components is required by the organizations
                           procedures for product and service information and labeling.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:labeling}}></div>
                        
                        
                </Grid>
                <Grid item xs={12} md={5}  className="grid2">
                        <h6><b>"Provide information on the safe use of the product or service is required by the 
                        organizations procedures for product and service information and labeling.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:procedures}}></div>
                       
                        
                       
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default Labeling1;