import React, { memo, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
} from "@mui/material";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import { Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  AddUser,GetAllUsers,
  domainDropdownList,
  getUserRole,
} from "../../ESG/services/Fetch";

// importing data form database
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditIcon from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import bgImage from "../../images/bg.svg";
import InputLabel from "@mui/material/InputLabel";

import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Sticky } from "semantic-ui-react";
import CloseIcon from "@mui/icons-material/Close";

const override = css.bind`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const tableHeader = {
  backgroundColor: "#E9F6F4",
  color: "#25A392",
  padding: "0.5em",
  fontWeight: 550,
};
const tableCell = {
  color: '#384651',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  verticalAlign: "middle",
  paddingTop: "0.4em",
  paddingBottom: "0.4em",
};



const TableForAddPermission=({setUserDetails,UserDetails})=>{
  const [data, setData] = useState(UserDetails.UserCompany.map((name, index) => ({
    id: index + 1,
    company: name,
    read: false,
    write: false,
    admin: false
  })));

useEffect(()=>{
setData(UserDetails.UserCompany.map((name, index) => ({
  id: index + 1,
  company: name,
  read: false,
  write: false,
  admin: false
})))
},[UserDetails.UserCompany])

  const handleCheckboxChange = (id, col) => (event) => {
    console.log("first")
    setData((prev)=>{
       const mock=prev.map(row => row.id === id ? { ...row, [col]: event.target.checked } : row)
       setUserDetails((prev) => {
          return { ...prev, ["companies"]: mock  };
        });
      return mock});
        
  };
 
 
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={tableHeader}>Company Name</TableCell>
            <TableCell style={tableHeader}>Read</TableCell>
            <TableCell style={tableHeader}>Write</TableCell>
            <TableCell style={tableHeader}>Admin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography>{row.company}</Typography>
                 
  
                
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={row.read}
                  onChange={handleCheckboxChange(row.id, 'read')}
                  size="small"
                  sx={{'&.Mui-checked':{
                    color:'#25A392'
                  }}}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={row.write}
                  onChange={handleCheckboxChange(row.id, 'write')}
                  size="small"
                  sx={{'&.Mui-checked':{
                    color:'#25A392'
                  }}}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={row.admin}
                  onChange={handleCheckboxChange(row.id, 'admin')}
                  size="small"
                  sx={{'&.Mui-checked':{
                    color:'#25A392'
                  }}}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}

const AddUserDialog=({CompanyList,userRole,edit,FetchAllUsers,setisLoading})=>{
  const [UserDetails, setUserDetails] = useState({
    UserName: null,
    UserEmail: null,
    UserStartDate: null,
    UserCompany: [],
    UserRole: [],
    companies: [],
  });
  const [open, setOpen] = useState(false);
  const handleonchangeUser = (event) => {
    const { name, value } = event.target;
    setUserDetails((prev) => {
      return { ...prev, [name]: value };
    });
};

const handleSubmit = async () => {
  try {
    const data = await AddUser(UserDetails);
    if (data.status === 200) {
      console.log('User added successfully');
      
      // Update state with new user details
      setUserDetails({
        UserName: null,
        UserEmail: null,
        UserStartDate: null,
        UserCompany: [],
        UserRole: [],
        companies: []
      });

      // Fetch updated user list
      FetchAllUsers();
      console.log('Updated user list:');
    } else {
      console.error('Failed to add user:', data.error); // Handle error response
    }

    setOpen(false); // Close modal or form
  } catch (error) {
    console.error('Error:', error); // Handle fetch or other errors
  }
};

  const HandleEdit = () => {
    setUserDetails({
      id: null,
      UserName: null,
      UserEmail: null,
      UserStartDate: null,
      UserCompany: null,
      UserRole: [],
      Read: false,
      write: false,
      Admin: false,
    });
    // setEdit(false);
    // setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return(
    <> <Button
    variant="contained"
    style={{ backgroundColor: "#25A392" }}
    onClick={() => {
      setOpen(true);
    }}
  >
    Add User
  </Button>
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle
      sx={{ fontWeight: 550, fontSize: "20px", color: "#061826" }}
    >
      Add User
    </DialogTitle>
    <IconButton
                  size="small"
                  aria-label="close"
                  onClick= {handleClose}
                  sx={{
                    position: "absolute",
                    right: 30,
                    top: 16,
                    color: "white",
                    backgroundColor: "black",
                    width: "20px",
                    height: "20px",
                  }}
                >
              <CloseIcon />
            </IconButton>
    <DialogContent>
      <Grid container spacing={1} justifyContent={'space-around'} >
        <Grid item>
          <TextField
            label="User Name"
            name="UserName"
            value={UserDetails.UserName || ""}
            onChange={(event) => {
              handleonchangeUser(event);
            }}
            margin="normal"
            
            size={"medium"}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label="User Email"
            name="UserEmail"
            value={UserDetails.UserEmail || ""}
            onChange={(event) => {
              handleonchangeUser(event);
            }}
            margin="normal"
           
            size={"medium"}
          />
        </Grid>
      </Grid>

      <Grid container mt={1} spacing={1} >
        <Grid item xs={6}>
        <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
            User Company
            </InputLabel>
            <Select
              // variant="standard"
              labelId="User"
              multiple
             
              value={UserDetails.UserCompany}
              name="UserCompany"

              input={<OutlinedInput label="User Company" />}
              onChange={(event) => {
                 handleonchangeUser(event);
              }}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <div key={value}>
                      {
                        CompanyList.find(
                          (option) => option === value
                        )
                      }
                    </div>
                  ))}
                </div>
              )}
              MenuProps={{
                classes: {
                  paper: "menu-paper",
                },
              }}
            >
              {CompanyList?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size={"medium"}
            type="date"
            label="Start Date"
            name="UserStartDate"
            value={UserDetails.UserStartDate || ""}
            onChange={(event) => {
              handleonchangeUser(event);
            }}
            focused
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
            User Role
            </InputLabel>
            <Select
              // variant="standard"
              labelId="UserRole"
              multiple
              value={UserDetails.UserRole}
              name="UserRole"

              input={<OutlinedInput label="User Role" />}
              onChange={(event) => {
                 handleonchangeUser(event);
              }}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <div key={value}>
                      {
                        userRole.find(
                          (option) => option === value
                        )
                      }
                    </div>
                  ))}
                </div>
              )}
              MenuProps={{
                classes: {
                  paper: "menu-paper",
                },
              }}
            >
              {userRole?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Grid container spacing={2} direction="column" p={2} mt={1}>
        <Typography sx={{ color: "#061826", fontSize: "16px",fontWeight:'600',marginBottom:'10px' }}>
          Select Permissions
        </Typography>
        {/* <Typography sx={{ color: "#B2B7BC", fontSize: "14px" }}>
          Select The Role Of This User.
        </Typography> */}
        <TableForAddPermission UserDetails={UserDetails} setUserDetails={setUserDetails}/>
       
      </Grid>
    </DialogContent>

    <Grid style={{ textAlign: "center", padding: "16px" }}>
      <Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={edit ? HandleEdit : handleSubmit}
        className=""
        margin={1}
        sx={{ backgroundColor: "#25A392" }}
        disabled={
          UserDetails.UserName &&
          UserDetails.UserEmail &&
          UserDetails.UserStartDate &&
          UserDetails.UserCompany &&
          UserDetails.UserRole.length > 0
            ? false
            : true
        }
      >
        {edit ? "Edit" : "Submit"}
      </Button>
      </Grid>
        <Grid>
      <Button
        variant="text"
        margin="1"
        className="btn-cancel"
        onClick={() => {
          setOpen(false);
        }}
        sx={{ color: "#F37472" }}
      >
        Cancel
      </Button>
      </Grid>
    </Grid>
  </Dialog>
  </>
  )
}

function Adduser() {
  const navigate = useNavigate();
  const [selectedDeleteUser, setSelectedDeleteUser] = useState("");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [CompanyList, setCompanyList] = useState([]);
  const [userRole, setuserRole] = useState();

  const [isLoading, setisLoading] = useState(false);

  const [edit, setEdit] = useState(false);
  const [users, setUsers] = useState();

  const FetchAPI = async () => {
    try {
        const [CompanyListData, UserRoles] = await Promise.all([
            domainDropdownList(),
            getUserRole(),  
        ]);

        setCompanyList(CompanyListData);
        setuserRole(UserRoles.data);
        
    } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
    }
};
const FetchAllUsers =async()=>{
  setisLoading(true);
  try{
  const users= await GetAllUsers();
  console.log(users.data.data)
  setUsers(users.data.data.filter((res)=>res.company===localStorage.getItem("UserSelectedDomain")))
  }catch (error) {
    console.error('Error fetching data:', error);
}
setisLoading(false);
}

  useEffect(() => {
    FetchAllUsers();
    FetchAPI();
  }, []);

  const RenderCompanyInfoTable = () => {
    console.log(users)
    return (
      <TableContainer className="mt-3 rounded-0 " >
        <Table className="table table-striped table-hover">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={tableHeader}>
                User Name
              </TableCell>
              <TableCell style={tableHeader}>User Email</TableCell>
              <TableCell style={tableHeader}>Company Name</TableCell>
              {/* <TableCell style={tableHeader}>Start Date</TableCell> */}
              <TableCell style={tableHeader}>User Role</TableCell>
              <TableCell style={tableHeader}>User Permissions</TableCell>
              <TableCell style={tableHeader}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users?.map((res,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" style={tableCell}>
                      {res.name}
                    </TableCell>
                    <TableCell align="left" style={tableCell}>
                      {res.email}
                    </TableCell>
                    <TableCell align="left" style={tableCell}>
                      {res.company}
                    </TableCell>
                    {/* <TableCell align="left" style={tableCell}>
                      {res.UserStartDate}
                    </TableCell> */}
                    <TableCell align="left" style={tableCell}>
                      {res.role}
                    </TableCell>
                    <TableCell align="left" style={tableCell}>
      { (<>
        <Checkbox
          checked={res?.read}
          size="small"
          sx={{'&.Mui-checked':{
            color:'#25A392'
          }}}
        />
        {"Read"}</>
      )}
       {"  "}
      
      { (<>
        <Checkbox
          checked={res?.write}
          size="small"
          sx={{'&.Mui-checked':{
            color:'#25A392'
          }}}
        />
        {"Write"}</>
      )}
       {"  "}
      
      { (<>
        <Checkbox
          checked={res?.admin}
          size="small"
          sx={{'&.Mui-checked':{
            color:'#25A392'
          }}}
        />
        {"Admin"}</>
      )}
      
    </TableCell>
                    <TableCell align="left" style={tableCell}>
                      <LongMenu comapnyData={res} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };


  const handleDelete = async () => {
    try {
      setUsers((prev) => {
        return prev.filter((x) => x.id !== selectedDeleteUser.id);
      });
    } catch (error) {}

    closeDeletePopup();
  };

  const LongMenu = ({ comapnyData }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const openEditPopup = (comapnyData) => {
      // setUserDetails(comapnyData);
      // setEdit(true);
      // setOpen(true);
    };

    return (
      <Grid>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            key="Edit Company"
            // onClick={() => openEditPopup(comapnyData)}
          >
            <EditIcon className="me-2" />
            Edit
          </MenuItem>
          <MenuItem
            key="Delete"
            className=" mb-2"
            // onClick={() => {
            //   setSelectedDeleteUser(comapnyData);
            //   setIsDeletePopupOpen(true);
            // }}
          >
            <DeleteIcon className="me-2" />
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    );
  };

  const closeDeletePopup = () => {
    setIsDeletePopupOpen(false);
  };

  const DeleteCompanyPopUp = () => {
    return (
      <Grid>
        <Dialog
          open={isDeletePopupOpen}
          onClose={closeDeletePopup}
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle className="" sx={{ fontSize: "1.1em", fontWeight: 550 }}>Edit Company</DialogTitle> */}
          <DialogContent>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundImage: `url(${bgImage})`, // Replace with the URL of your image
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                minHeight: "30vh", // Set the height as needed
              }}
            >
              <Grid
                item
                className="card border border-0 rounded-circle"
                style={{ height: "17vh", width: "17vh" }}
              >
                <img
                  className="m-auto"
                  src={require("../../images/66px_Delete.svg").default}
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid className="text-center">
                <Typography
                  className="mt-2 mb-0"
                  sx={{ fontSize: "1.2em", fontWeight: 550 }}
                >
                  Delete Company
                </Typography>
                <Typography
                  className=""
                  sx={{ fontSize: "0.7em", color: "#B2B7BC" }}
                >
                  Are You Sure You Want To Delete This Company.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <Stack direction="row" justifyContent="center" spacing={2}>
            <DialogActions className="mb-2">
              <Button
                className=""
                variant="contained"
                sx={{ backgroundColor: "#25A392" }}
                onClick={() => handleDelete()}
              >
                Delete
              </Button>
              <Button
                className="btn-cancel"
                onClick={closeDeletePopup}
                sx={{ color: "#F37472" }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Stack>
        </Dialog>
      </Grid>
    );
  };




 

  return (
    <React.Fragment>
      <Grid sx={{ backgroundColor: "#F5F5F6", height: "100%" }}>
        {!isLoading ? (
          <React.Fragment>
            <Grid
              container
              direction="row"
              py={2}
              px={1}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                direction="row"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <ArrowBackIosIcon
                  style={{
                    marginTop: "0.23em",
                    marginRight: "0.2em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/AdminPanel");
                  }}
                />
                <Typography
                  style={{
                    fontWeight: 650,
                    fontSize: "1.1em",
                    marginTop: "0.2em",
                  }}
                >
                 User Management
                </Typography>
              </Grid>
              <Grid item>
               <AddUserDialog CompanyList={CompanyList} userRole={userRole} edit={edit} FetchAllUsers={FetchAllUsers} setisLoading={setisLoading}/>
              </Grid>
            </Grid>
            <Paper sx={{ padding: "0.7em", marginX: "0.5em" }}>
              <Grid container direction="row" justifyContent={"space-between"}>
                {/* <Grid item>
                  <TextField
                    // value={searchFilings}
                    // onChange={handleSearch}
                    label="Search"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid> */}
                <Grid item>
                  <IconButton
                    sx={{
                      border: "1px solid #BAD5E7",
                      backgroundColor: "#E9F1F7",
                      borderRadius: "6px",
                      marginLeft: "10px",
                    }}
                    aria-label="filter"
                  >
                    <Badge color="error" variant="">
                      <FilterAltIcon sx={{ color: "#2076B0" }} />
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>
              <RenderCompanyInfoTable />
             
              <DeleteCompanyPopUp />
            </Paper>
          </React.Fragment>
        ) : (
          <Box p={2}>
            <Grid className="loading-container mt-5 text-center">
              <Typography className="ms-4">Loading....</Typography>
              <PropagateLoader
                css={override}
                size={15}
                color={"#3498db"}
                loading={true}
              />
            </Grid>
          </Box>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Adduser;
