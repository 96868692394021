import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import "../styles/Home/ESGHome.css";
import { Grid, Typography, Box, Pagination, Badge } from "@mui/material";
import WindowIcon from "@mui/icons-material/Window";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PaperComponent from "components/PaperComponent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "components/Modal";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getDisclosures_Newfilling } from "ESG/services/Fetch";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { parse, format } from "date-fns";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E9F6F4",
    color: "#25A392",
    fontWeight: "600",
    fontSize: 13,
    paddingLeft: "7px",
    paddingRight: "7px",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    paddingLeft: "7px",
    paddingRight: "7px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#EBEDED",
    borderRadius: "6px",
  },
  // hide last border
}));

function ToggleButtons({ view, setView }) {
  const handleChange = (event, nextView) => {
    //console.log("toggled");

    if (nextView !== null && view !== nextView) {
      setView(nextView);
    }
  };

  return (
    <ToggleButtonGroup
      className="toggleButtons"
      value={view}
      exclusive
      onChange={handleChange}
      size="small"
    >
      <ToggleButton value="cardView" aria-label="cardView">
        <WindowIcon />
      </ToggleButton>

      <ToggleButton value="listView" aria-label="listView">
        <FormatListBulletedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

function FilterDisclosureButtons({ FilterType, handleChange }) {
  return (
    <ToggleButtonGroup
      className="toggleButtons"
      sx={{
        ".MuiToggleButtonGroup-grouped": {
          padding: "8px 17px",
          fontSize: "14px",
        },
      }}
      value={FilterType}
      exclusive
      onChange={handleChange}
      size="small"
    >
      <ToggleButton value="All">All</ToggleButton>
      <ToggleButton value="In-Progress">In-Progress</ToggleButton>
      <ToggleButton value="Completed">Completed</ToggleButton>
    </ToggleButtonGroup>
  );
}

const ESGDisclosureCard = ({ res, navigate }) => {
  const permittedPath = localStorage.getItem("permittedPath");
  const formatDate = (dateString) => {
    const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
    return format(parsedDate, "MM/dd/yyyy");
  };
  return (
    <Card
      variant="outlined"
      sx={{
        padding: "10px",
        marginY: "10px",
        border: "1px solid #D5D3F9",
        borderRadius: "9px",
      }}
    >
      <Grid container>
        <Grid item container justifyContent={"space-between"}>
          <Grid item>
            <Typography
              onClick={(e) => {
                e.preventDefault();
                navigate("/ViewReport", {
                  state: {
                    disclosure: res.filing,
                    startdate: res.start,
                    EndDate: res.end,
                  },
                });
              }}
              className="Filling_name"
              style={{ color: "#25A392" }}
            >
              {res.disclosureName}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", color: "black", fontWeight: "500" }}
            >
              {res.filing}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label={res.status}
              variant="outlined"
              className={`${
                res.status.toUpperCase() === "IN PROGRESS"
                  ? "InProgressChip"
                  : "CompletedChip"
              }`}
            />
          </Grid>
        </Grid>
        <Grid item container justifyContent={"space-between"}>
          <Grid
            item
            container
            justifyContent={"space-between"}
            columnGap={2}
            my={1}
          >
            <Grid item>
              <Typography variant="h6" className="Label_Disclosure">
                Start Date
              </Typography>
              <Typography variant="body1">{formatDate(res.start)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className="Label_Disclosure">
                End Date
              </Typography>
              <Typography variant="body1">{formatDate(res.end)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className="Label_Disclosure">
                Deadline
              </Typography>
              <Typography variant="body1">
                {formatDate(res.deadline)}
              </Typography>
            </Grid>
            <Grid item>
              {permittedPath !== "Insurance" && (
                <>
                  <Typography variant="h6" className="Label_Disclosure">
                    Environmental
                  </Typography>

                  <Typography variant="body1">
                    {Math.round(res.environmental)}%
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item>
              {permittedPath !== "Insurance" && (
                <>
                  <Typography variant="h6" className="Label_Disclosure">
                    Social
                  </Typography>

                  <Typography variant="body1">
                    {Math.round(res.social)}%
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item>
              {permittedPath !== "Insurance" && (
                <>
                  <Typography variant="h6" className="Label_Disclosure">
                    Governance
                  </Typography>

                  <Typography variant="body1">
                    {Math.round(res.governance)}%
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item>
              {permittedPath !== "Insurance" && (
                <>
                  <Typography variant="h6" className="Label_Disclosure">
                    General
                  </Typography>
                  <Typography variant="body1">
                    {Math.round(res.general)}%
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item alignSelf={"center"}>
              <Button
                onClick={() =>
                  navigate("/ViewReport", {
                    state: {
                      disclosure: res.filing,
                      startdate: res.start,
                      EndDate: res.end,
                    },
                  })
                }
                className="View_Button"
              >
                View
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const ESGDisclosureRows = ({ res, navigate }) => {
  const formatDate = (dateString) => {
    const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
    return format(parsedDate, "MM/dd/yyyy");
  };
  return (
    <StyledTableRow key={res.filing}>
      <StyledTableCell>{res.filing}</StyledTableCell>
      <StyledTableCell>{res.status}</StyledTableCell>
      <StyledTableCell>{formatDate(res.start)}</StyledTableCell>
      <StyledTableCell>{formatDate(res.end)}</StyledTableCell>
      <StyledTableCell>{formatDate(res.deadline)}</StyledTableCell>
      <StyledTableCell>
        {
          <span>
            <div class="dotEnv" />
            {Math.round(res.environmental)}%
          </span>
        }
        {
          <span>
            <div class="dotSoc" />
            {Math.round(res.social)}%
          </span>
        }
        {
          <span>
            <div class="dotGov" />
            {Math.round(res.governance)}%
          </span>
        }
        {
          <span>
            <div class="dotGen" />
            {Math.round(res.general)}%
          </span>
        }
      </StyledTableCell>
      <StyledTableCell>{Math.round(res.progress) + "%"}</StyledTableCell>
      <StyledTableCell>
        <IconButton
          onClick={() =>
            navigate("/ViewReport", {
              state: {
                disclosure: res.filing,
                startdate: res.start,
                EndDate: res.end,
              },
            })
          }
          size="small"
        >
          <VisibilityIcon fontSize="small" sx={{ color: "#2076B0" }} />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const ESGDisclosureTable = ({ ESGDisclosures, navigate }) => {
  return (
    <Grid item container xs={12} mt={1}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Document</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>End Date</StyledTableCell>
              <StyledTableCell>Deadline Date</StyledTableCell>
              <StyledTableCell>ESG Status</StyledTableCell>
              <StyledTableCell>Overall</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {ESGDisclosures.map((res, index) => (
              <ESGDisclosureRows key={index} res={res} navigate={navigate} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const itemsPerPageCardView = 3; // Number of cards per page in card view
const itemsPerPageTableView = 8; // Number of rows per page in table view

const ESGDisclosures = ({ ESGDisclosures }) => {
  const [view, setView] = React.useState("cardView");
  const [FilterType, setFilterType] = React.useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilings, setSearchFilings] = useState("");
  const [OpenFilterModal, setOpenFilterModal] = useState(false);
  const [FilingTypes, setFilingTypes] = useState();
  const [FilteredDisclosures, setFilteredDisclosures] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const GetExistingDisclosureTypes = async () => {
      await getDisclosures_Newfilling().then((data) => {
        setFilingTypes(data?.values);
      });
    };
    GetExistingDisclosureTypes();
  }, []);

  const handleChange = (event, filterType) => {
    if (filterType !== null && FilterType !== filterType) {
      setFilterType(filterType);
      setCurrentPage(1);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilings(searchValue);
    // When searching, reset the current page to the first page
    currentPage !== 1 && setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  let itemsPerPage;
  if (view === "cardView") {
    itemsPerPage = itemsPerPageCardView;
  } else {
    itemsPerPage = itemsPerPageTableView;
  }

  const Disclosures = filtersApplied ? FilteredDisclosures : ESGDisclosures;

  const filteredDisclosures = Disclosures?.filter((res) => {
    if (FilterType === "All") {
      return true;
    } else if (FilterType === "In-Progress") {
      return res.status === "In progress";
    } else if (FilterType === "Completed") {
      return res.status === "Completed";
    }

    return res.status === FilterType;
  }).filter((res) => {
    // Filter based on search query
    return res.filing.toLowerCase().includes(searchFilings.toLowerCase());
  });

  const totalItems = filteredDisclosures?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredDisclosures?.slice(startIndex, endIndex);
  const permittedPath = localStorage.getItem("permittedPath");
  // console.log(ESGDisclosures);

  function HandleFilter() {
    const [selectedFilingTypes, setSelectedFilingTypes] = useState([]);
    const [Date, setDate] = useState({
      FromDate: undefined,
      TillDate: undefined,
    });

    const handleApplyFilter = () => {
      const filteredDisclosures = ESGDisclosures?.filter((disclosure) => {
        // Filter by date range
        const startDate = Date.FromDate;
        const endDate = Date.TillDate;

        if (
          (startDate && dayjs(disclosure.start).isBefore(startDate)) ||
          (endDate && dayjs(disclosure.end).isAfter(endDate))
        ) {
          return false;
        }

        // Filter by selected filing types
        if (
          selectedFilingTypes.length > 0 &&
          !selectedFilingTypes.includes(disclosure.filing)
        ) {
          return false;
        }

        return true;
      });

      // Update the filtered ESG disclosures
      setFilteredDisclosures(filteredDisclosures);
      setFiltersApplied(true);
      setFilterType("All");
      setCurrentPage(1);
      setOpenFilterModal(false);
    };

    const handleChipClick = (filing) => {
      setSelectedFilingTypes((prevSelectedFilingTypes) => {
        if (prevSelectedFilingTypes.includes(filing)) {
          // Remove the filing type if it's already selected
          return prevSelectedFilingTypes.filter((type) => type !== filing);
        } else {
          // Add the filing type if it's not selected
          return [...prevSelectedFilingTypes, filing];
        }
      });
    };

    return (
      <Modal
        open={OpenFilterModal}
        setOpen={setOpenFilterModal}
        title={"Disclosure Filter"}
        actions={
          <Grid container direction={"column"} alignContent={"center"}>
            <Grid item my={1}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  paddingX: "20px",
                  backgroundColor: "#25A392",
                  "&:hover": {
                    backgroundColor: " #25a392",
                  },
                }}
                onClick={handleApplyFilter}
              >
                Apply
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  paddingX: "20px",
                  textTransform: "capitalize",
                  color: "#F37472",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => setOpenFilterModal(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={2} mt={1} direction={"column"}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="From Date"
                    value={Date.FromDate}
                    onChange={(e) =>
                      setDate((prevstate) => ({
                        ...prevstate,
                        FromDate: e,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Till Date"
                    value={Date.TillDate}
                    onChange={(e) =>
                      setDate((prevstate) => ({
                        ...prevstate,
                        TillDate: e,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <Typography
              sx={{ fontSize: "16px", color: "#061826", fontWeight: 600 }}
            >
              Select Disclosure Type
            </Typography>
          </Grid>
          <Grid item container spacing={1} direction={"row"}>
            {FilingTypes?.map((filing) => (
              <Grid item key={filing}>
                <Chip
                  label={filing}
                  sx={{
                    backgroundColor:
                      selectedFilingTypes.includes(filing) && " #E9F6F4",
                    color: selectedFilingTypes.includes(filing) && "#25A392",
                    borderColor:
                      selectedFilingTypes.includes(filing) && "#BBE2DD",
                  }}
                  variant="outlined"
                  onClick={() => handleChipClick(filing)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Modal>
    );
  }

  const handleClearFilters = () => {
    // Clear the filters and set filtersApplied to false
    setFilterType("All");
    setSearchFilings("");
    setFiltersApplied(false);
    setFilteredDisclosures([]);
  };

  const renderESGDisclosures = () => {
    if (view === "cardView") {
      return currentItems.map((res, index) => (
        <ESGDisclosureCard key={index} res={res} navigate={navigate} />
      ));
    } else {
      return (
        <ESGDisclosureTable ESGDisclosures={currentItems} navigate={navigate} />
      );
    }
  };
  return (
    <React.Fragment>
      {ESGDisclosures && (
        <PaperComponent>
          <Box
            minHeight={660}
            sx={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography className="Title_Name">
                    {permittedPath + " Disclosures"}
                  </Typography>
                </Grid>

                <Grid item display={"flex"} flexDirection={"row"}>
                  {/* <Button  size="small" onClick={()=>{navigate("/GraphComponent")}} >
                  <AutoGraphIcon size="small"/>
                  </Button> */}
                  <ToggleButtons view={view} setView={setView} />
                  <IconButton
                    onClick={() => setOpenFilterModal(true)}
                    sx={{
                      border: "1px solid #BAD5E7",
                      backgroundColor: "#E9F1F7",
                      borderRadius: "6px",
                      marginLeft: "10px",
                    }}
                    aria-label="filter"
                  >
                    <Badge
                      //  badgeContent={filtersApplied ? 1 : null}
                      color="error"
                      variant={filtersApplied ? "dot" : null}
                    >
                      <FilterAltIcon sx={{ color: "#2076B0" }} />
                    </Badge>
                  </IconButton>
                  {filtersApplied && (
                    <Tooltip title="Remove Filter">
                      <IconButton
                        onClick={handleClearFilters}
                        sx={{
                          border: "1px solid #BAD5E7",
                          backgroundColor: "#E9F1F7",
                          borderRadius: "6px",
                          marginLeft: "10px",
                        }}
                        aria-label="clear filters"
                      >
                        <ClearIcon sx={{ color: "#F37472" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {OpenFilterModal && <HandleFilter />}
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <FilterDisclosureButtons
                  FilterType={FilterType}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid
                container
                my={1}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <Grid item>
                  <TextField
                    label="Search"
                    variant="outlined"
                    value={searchFilings}
                    size="small"
                    sx={{ width: "270px" }}
                    onChange={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {view === "listView" && (
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: "14px",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {
                      <span>
                        <div class="dotEnvH" />
                        Enviroment
                      </span>
                    }
                    {
                      <span>
                        <div class="dotSocH" />
                        Social
                      </span>
                    }
                    {
                      <span>
                        <div class="dotGovH" />
                        Governance
                      </span>
                    }
                    {
                      <span>
                        <div class="dotGenH" />
                        General
                      </span>
                    }
                  </Grid>
                )}
              </Grid>
              {currentItems.length > 0 ? (
                renderESGDisclosures()
              ) : (
                <Typography m={4} variant="body2">
                  No disclosures found for the selected filter.
                </Typography>
              )}
            </Box>
            <Grid container justifyContent={"center"} alignItems={"flex-end"}>
              <Grid item>
                <Pagination
                  variant="outlined"
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    marginTop: 1,

                    alignSelf: "center",

                    justifySelf: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </PaperComponent>
      )}
    </React.Fragment>
  );
};

export default ESGDisclosures;
