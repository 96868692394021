import { TextField } from "@mui/material";
import { useState } from "react";
import React from "react";
import "../styles/Forms_Styles/Form_Text_input.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

const Form_Email= ({onChange,getAnswerDisable}) => {
  const {Answer}=useContext(NoteContext);
  const [error, setError] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = event => {
    setError(false);
    if(event.target.value.length===0){
      onChange(event.target.value)
    }
    if (isValidEmail(event.target.value)) {
      onChange(event.target.value)
    }else{
      setError(true)
    }
  };


  return (
          <TextField
             placeholder="Enter email"
             variant="outlined"
             fullWidth
             type="email"
             value={typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : ""}
             error={error}
             helperText={error && 'Enter a Valid Email'}
             onChange={handleChange}
             size='small'
             disabled={getAnswerDisable}
          />
  );
};

export default Form_Email;


