import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { useEffect, useState } from "react";
import { TextField, Autocomplete, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "../styles/Forms_Styles/Form_Address.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useContext } from "react";
import NoteContext from "context/PageContext";
export default function Form_Address({ onChange, ListOfAddress,getAnswerDisable }) {
  const {Answer}=useContext(NoteContext);
  const [data, setdata] = useState({
    newStreet: '',
    newStreet2: '',
    newCity: '',
    newState: '',
    newCountry: '',
    newZip: '',
  });

  const [errorMessages, setErrorMessages] = useState({
    newStreet: '',
    newStreet2: '',
    newCity: '',
    newCountry: '',
    newState: '',
    newZip: '',
  });

  // const [isCheck, setIsChecked] = useState(false);

  const validateInput = () => {
    const newErrorMessages = {};

    // Check if any field is empty
    Object.keys(data).forEach((key) => {
      if ( typeof data[key] != 'object') {
        if (!data[key].trim()) {
          newErrorMessages[key] = `Input field cannot be empty`;
        }
        else {
          newErrorMessages[key] = ''; // Clear error message if not empty
        }
      }
      else{
        if (!data[key].CountryName.trim() ) {
          newErrorMessages[key] = 'Select a country';
        }
        else {
          newErrorMessages[key] = ''; // Clear error message if not empty
        }
      }
    });

    setErrorMessages(newErrorMessages);

    // Check if any error message is present
    return Object.values(newErrorMessages).every((msg) => !msg);
  };
  let Info=[];
  try {
     Info = typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : [];
    //console.log( typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : [])
  } catch (error) {
     Info =[];
  }

  
  const [index, setIndex] = useState();
  const [bolin, setBolin] = useState(false);
  let { newStreet, newStreet2, newCity, newState, newCountry, newZip } = data;
  const [Country, setNewCountry] = React.useState();
  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const { GetCountryList, GetStateList } = require("../services/Fetch");
  const [currentIndex, setCurrentIndex] = useState(0);

  const previousAddress = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const nextAddress = () => {
    if (currentIndex < Info.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
 

  useEffect(() => {
    const getcountries = async () => {
      await GetCountryList().then((data) => {
        setcountries(data);
      });
    };
    getcountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetCountryList])
  useEffect(() => {
    const getcountries = async () => {
      // console.log(Country,"country name");
      await GetStateList(Country?.CountryCode).then((data) => {
        // console.log(data,"statename")
        setstates(data);
      });
    };
    getcountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Country])


  const handleAddClick = () => {

    // console.log(data)
    if (!validateInput()) {
      return;
    }

    if (data.newCity !== "" && data.newCountry !== "" && data.newState !== "" && data.newStreet !== "" &&
      data.newStreet2 !== "" && data.newZip !== "") {
      onChange( [...Info, data]);
      setdata({ newStreet: '', newStreet2: '', newCity: '', newState: '', newCountry: '', newZip: '' });
    }
  }

  const handleUpdate = (i) => {
    
    // console.log(Info[i])
    let { newStreet, newStreet2, newCity, newState, newCountry, newZip } = Info[i]
    if (newCity !== "" && newCountry !== "" && newState !== "" && newStreet !== "" &&
      newStreet2 !== "" && newZip !== "") {
      setdata({ newStreet, newStreet2, newCity, newState, newCountry, newZip })
      setBolin(true);
      setIndex(i)
    }
  }

  const handleDelete = (i) => {
    // const cardtoRemove = Info.filter(
    //   (item) => item.Email !== Email
    // );
    // setInfo(cardtoRemove);
    let total = [...Info]
    total.splice(i, 1)
    onChange(total)
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const Updateinfo = () => {
    if (!validateInput()) {
      return;
    }
    let total = [...Info]
    total.splice(index, 1, { newStreet, newStreet2, newCity, newState, newCountry, newZip })
    onChange(total)
    setdata({ newStreet: '', newStreet2: '', newCity: '', newState: '', newCountry: '', newZip: '' })
    setBolin(false)
  }

  //console.log(currentIndex, Info[currentIndex])
  const formattedAddress = Info.length !== 0 && Object.keys(Info[currentIndex])
    .map((key) => {
      const value = Info[currentIndex][key];
      if (key === 'newCountry' && typeof value === 'object' && value.CountryName) {
        // Extract the "Country Name" property if it exists
        return value.CountryName;
      } else if (value) {
        // Include the property if it has a non-empty value
        return value;
      }
      return null; // Omit empty properties
    })
    .filter((value) => value !== null) // Remove omitted properties
    .join(', ');

  // Use the formattedAddress in your component

  return (
    <Grid container spacing={3}>
      <Grid item container >
        <Grid item xs={1}>
          <IconButton onClick={previousAddress} sx={{ color: "#2076B0" }} disabled={currentIndex === 0}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>

        <Grid
          item
          container
          direction={"column"}
          xs={10}
          sx={{
            backgroundColor: "#E9F1F7",
            border: "1px solid #BAD5E7",
            border: " 1px solid #BAD5E7",
            borderRadius: "6px",
            padding: "10px",
          }}
        >
          <Grid item>
            <Typography sx={{ color: "#2076B0", fontSize: "12px" }}>
              Address{" "}
              {Info.length !== 0 && `${currentIndex + 1}/${Info.length}`}
            </Typography>
          </Grid>
          <Grid item container justifyContent={'space-between'}>
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#384651",
                  textTransform: "capitalize",
                }}
                variant="body2"
              >
                {Info.length !== 0
                  ? formattedAddress
                  : "No Address Has Been Added Yet."}
              </Typography>
            </Grid><Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {Info.length !== 0 && (<>
                <IconButton size='small' onClick={() => handleDelete(currentIndex)}>
                  <DeleteOutlineOutlinedIcon fontSize='small' edge="end" />
                </IconButton>
                <IconButton size='small' onClick={() => handleUpdate(currentIndex)}>
                  <EditIcon edge="end" fontSize='small' />
                </IconButton></>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}>
          <IconButton sx={{ color: "#2076B0" }}
            onClick={nextAddress}
            disabled={currentIndex === Info.length - 1}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id="standard-basic"
          variant="outlined"
          size="small"
          // rows={3}
          placeholder="Enter Address 1"
          value={data.newStreet}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, newStreet: e.target.value };
            })
          }
          error={Boolean(errorMessages.newStreet)}
          helperText={errorMessages.newStreet}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="standard-basic"
          variant="outlined"
          size="small"
          // rows={3}
          placeholder="Enter Address 2"
          value={data.newStreet2}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, newStreet2: e.target.value };
            })
          }
          error={Boolean(errorMessages.newStreet2)}
          helperText={errorMessages.newStreet2}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="standard-basic"
          variant="outlined"
          size="small"
          placeholder="Enter City"
          value={data.newCity}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, newCity: e.target.value };
            })
          }
          error={Boolean(errorMessages.newCity)}
          helperText={errorMessages.newCity}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          variant="outlined"
          size="small"
          id="standard-basic"
          options={countries}
          getOptionLabel={(option) => option.CountryName ?? ""}
          value={data?.newCountry || ""}
          isOptionEqualToValue={(option) => option.CountryName ?? ""}
          disabled={getAnswerDisable}
          onChange={(e, newCountry) => {
            setdata((prevstate) => {
              return { ...prevstate, newCountry: newCountry };
            });
            // console.log(newCountry);
            newCountry === null || undefined
              ? setNewCountry({ CountryCode: "USA" })
              : setNewCountry(newCountry);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Country" variant="outlined" size="small" fullWidth />
          )}
          error={Boolean(errorMessages.CountryName)}
          helperText={errorMessages.CountryName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          id="standard-basic"
          options={states}
          variant="outlined"
          size="small"
          value={data.newState}
          disabled={getAnswerDisable}
          onChange={(e, newState) =>
            setdata((prevstate) => {
              return { ...prevstate, newState: newState };
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText="Please select Country First"
              placeholder="Select State"
            />
          )}
          error={Boolean(errorMessages.newState)}
          helperText={errorMessages.newState}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id="standard-basic"
          variant="outlined"
          size="small"
          // rows={3}
          placeholder="Enter Zip code"
          value={data.newZip}
          disabled={getAnswerDisable}
          onChange={(e) =>
            setdata((prevstate) => {
              return { ...prevstate, newZip: e.target.value };
            })
          }
          error={Boolean(errorMessages.newZip)}
          helperText={errorMessages.newZip}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {!getAnswerDisable && 
        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#E9F6F4",
            border: "1px solid #CBE5E1",
            textTransform: "capitalize",
            color: "#25A392",
            borderRadius: "6px",
            padding: "5px 10px",
            "&:hover": {
              backgroundColor: "#E9F6F4",
              border: "1px solid #CBE5E1",
            },
          }}
          size="small"
          onClick={bolin ? Updateinfo : handleAddClick}
          disabled={!ListOfAddress && Info.length === 1}
          startIcon={
            bolin ? (
              <CheckCircleIcon sx={{ color: "#25A392" }} />
            ) : (
              <AddCircleRoundedIcon sx={{ color: "#25A392" }} />
            )
          }
        >
          { bolin ? "Save" : "Add"}
        </Button>
}
      </Grid>
    </Grid>
  );
}


