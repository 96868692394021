import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import OutlinedInput from '@mui/material/OutlinedInput';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import { Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
// importing data form database
import {
  GetCountryList,
  PostaddCompany,
  getAllCompany,
  deleteCompany,
  editCompany,
  getInterestedProductDropDownList,
} from "../../ESG/services/GraphFetch";
import AddUser from "./Adduser";
import ListItemIcon from "@mui/material/ListItemIcon";
import BusinessIcon from "@mui/icons-material/Business";
//import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Badge } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import WindowIcon from "@mui/icons-material/Window";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import bgImage from "../../images/bg.svg";
import NoteContext from "context/PageContext";
import { ManageAccountsIcon } from '@mui/icons-material/ManageAccounts';

const override = css.bind`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const tableHeader = {
  backgroundColor: "#E9F6F4",
  color: "#25A392",
  padding: "0.5em",
  fontWeight: 550,
};

const tableCell = {
  color: "#384651",
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  verticalAlign: "middle",
  paddingTop: "0.4em",
  paddingBottom: "0.4em",
};


function AddCompany() {

  const context = useContext(NoteContext)
  const [isLoaded, setisLoaded] = useState(false);
  const [countryList, setcountryList] = useState([]);
  const [Questions, setQuestion] = useState("What product are of interest?");
  const [interestedInValue, setinterestedInValue] = useState();

  // console.log(JSON.parse(localStorage.getItem("Name")).id.split("@")[1]);
  const [open, setOpen] = useState(false);
  const [CompanyDetails, setCompanyDetails] = useState([]);
  const [CompanyTempDetails, setCompanyTempDetails] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editedCompanyData, setEditedCompanyData] = useState({});

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const [selectedDeleteCompany, setSelectedDeleteCompany] = useState("");

  const [companyInfo, setCompanyInfo] = useState({
    companyName: null,
    country: [],
    startDate: null,
    domain: null,
    isEnabled: true,
    interestedIn: null,
  });
  const navigate = useNavigate();

  const [searchFilings, setSearchFilings] = useState("");
  const [view, setView] = React.useState("cardView");

  const [clickedChips, setClickedChips] = useState([]);
  const [selectedCompCountry, setSelectedCompCountry] = useState(["ind"]);
  const [ButtonDisable,setButtonDisable]=useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const FetchCompanyDetails = async () => {
    try {
      const CompanyData = await getAllCompany();
      console.log(CompanyData.data.data);

      setCompanyDetails(CompanyData.data.data);
      setCompanyTempDetails(CompanyData.data.data);

      setisLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchCompanyDetails();
  }, []);

  const handleOpen = () => {

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCountryChange = (event) => {
    setCompanyInfo({ ...companyInfo, country: event.target.value });
  };

  const handleinterestedInChange = (res) => {
    setClickedChips((prevClickedChips) => {
      if (prevClickedChips.includes(res)) {
        return prevClickedChips.filter((chip) => chip !== res);
      } else {
        return [...prevClickedChips, res];
      }
    });
    //   console.log(clickedChips);

    setCompanyInfo({ ...companyInfo, interestedIn: res });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo({ ...companyInfo, [name]: value });
  };
  console.log(companyInfo.companyName ? true : false );
  const handleSubmit = async () => {
    companyInfo.interestedIn = clickedChips;
    
      console.log("suscuss")
      const suss = await PostaddCompany(companyInfo);
    if (suss.status === 200) {
      context.setCompanyAdded(true);
      FetchCompanyDetails();
      
    }
    setCompanyInfo({
      companyName: null,
      country: [],
      startDate: null,
      domain: null,
      isEnabled: true,
      interestedIn: null,
    })
      handleClose();
   
    
    // Close the dialog
   
  };

  const handleDelete = async (id) => {
    // Call the deleteCompany function with the id to delete the company
    //deleteCompany(id);

    try {
      // let CompanyData3 = await deleteCompany(id);
      deleteCompany(id).then((CompanyData) => {});
      //  console.log(CompanyData.data.data);

      // setCompanyDetails(CompanyData.data.data);
    } catch (error) {
      console.log(error);
    }
    const updatedCompanyDetails = CompanyDetails.filter(
      (company) => company.id !== id
    );
    console.log(updatedCompanyDetails, "---------------");
    setCompanyDetails(updatedCompanyDetails);
    setCompanyTempDetails(updatedCompanyDetails);
    closeDeletePopup();
  };

  function FilterDisclosureButtons() {
    return (
      <ToggleButtonGroup
        className="toggleButtons"
        sx={{
          ".MuiToggleButtonGroup-grouped": {
            padding: "8px 18px",
            fontSize: "14px",
            marginTop: "0",
          },
        }}
        exclusive
        size="small"
        value="Company"
      >
        <ToggleButton value="Company">Company</ToggleButton>
        {/* <ToggleButton value="Users">Users</ToggleButton> */}
      </ToggleButtonGroup>
    );
  }

  // Function to render a table based on companyInfo
  const renderCompanyInfoTable = () => {
    return (
      <TableContainer className="mt-3 rounded-0 " style={{}}>
        <Table className="table table-striped table-hover">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={tableHeader}>
                Company ID
              </TableCell>
              <TableCell style={tableHeader}>Company Name</TableCell>
              <TableCell style={tableHeader}>Country</TableCell>
              <TableCell style={tableHeader}>Start Date</TableCell>
              <TableCell style={tableHeader}>Domain Name</TableCell>
              <TableCell style={tableHeader}>Interested In</TableCell>
              <TableCell style={tableHeader}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CompanyDetails &&
              CompanyDetails?.map((res, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" style={tableCell}>
                      {res.id ? res.id : ""}
                    </TableCell>
                    <TableCell style={tableCell}>
                      {res.properties["name"]
                        ? res.properties["name"][0].value
                        : ""}
                    </TableCell>
                    <TableCell style={tableCell}>
                      {res.properties["country"]
                        ? res.properties["country"][0].value
                        : ""}
                    </TableCell>
                    <TableCell style={tableCell}>
                      {res.properties["startDate"]
                        ? res.properties["startDate"][0].value
                        : ""}
                    </TableCell>
                    <TableCell style={tableCell}>
                      {res.properties["domain"]
                        ? res.properties["domain"][0].value
                        : ""}
                    </TableCell>

                    <TableCell style={tableCell}>
                      {res.properties.interestedIn
                        ? JSON.parse(res.properties.interestedIn[0].value)?.map(
                            (d, index) => (
                              <span key={index}>
                                {d}
                                {res.properties.interestedIn[0].value.length >
                                  1 &&
                                  index <
                                    res.properties.interestedIn[0].value
                                      .length -
                                      1 &&
                                  ", "}
                              </span>
                            )
                          )
                        : ""}
                    </TableCell>
                    <TableCell style={tableCell}>
                      <LongMenu id={res.id} comapnyData={res} />

                      {/* <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleDelete(res.id)}
                                        >
                                            Delete
                                        </Button> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderCompanyInfoGrid = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2} py={2}>
          {CompanyDetails?.map((res, index) => {
            return (
              <Grid item xs={6}>
                <Card
                  sx={{ minHeight: "22vh", border: "1.3px solid #D5D3F9" }}
                  elevation={1}
                >
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          sx={{ fontSize: "0.94em", fontWeight: 550 }}
                          mt={1}
                          color="#25A392"
                          gutterBottom
                        >
                          {res.id
                            ? res.id.length > 10
                              ? res.id.slice(0, 9) + "..."
                              : res.id
                            : ""}{" "}
                          :{" "}
                          {res.properties["name"]
                            ? res.properties["name"][0].value
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <LongMenu id={res.id} comapnyData={res} />
                      </Grid>
                    </Grid>

                    <Grid container direction="row">
                      <Grid
                        item
                        justifyContent="space-between"
                        xs={5}
                        direction="column"
                      >
                        <Grid item my={1}>
                          <Grid>
                            <Typography
                              sx={{ fontSize: "0.65em", color: "#B2B7BC" }}
                            >
                              Domain Name
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.8em",
                                color: "#384651",
                                fontWeight: 550,
                              }}
                            >
                              {res.properties["domain"]
                                ? res.properties["domain"][0].value
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item mt={1}>
                          <Grid>
                            <Typography
                              sx={{ fontSize: "0.65em", color: "#B2B7BC" }}
                            >
                              Start Date
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.8em",
                                color: "#384651",
                                fontWeight: 550,
                              }}
                            >
                              {res.properties["startDate"]
                                ? res.properties["startDate"][0].value
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        justifyContent="space-between"
                        xs={7}
                        direction="column"
                      >
                        <Grid item my={1}>
                          <Grid>
                            <Typography
                              sx={{ fontSize: "0.65em", color: "#B2B7BC" }}
                            >
                              Country
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.8em",
                                color: "#384651",
                                fontWeight: 550,
                              }}
                            >
                              {res.properties["country"]
                                ? res.properties["country"][0].value
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item mt={1}>
                          <Grid>
                            <Typography
                              sx={{ fontSize: "0.65em", color: "#B2B7BC" }}
                            >
                              Interested In
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.8em",
                                color: "#384651",
                                fontWeight: 550,
                              }}
                            >
                              {res.properties.interestedIn
                                ? JSON.parse(
                                    res.properties.interestedIn[0].value
                                  )?.map((d, index) => (
                                    <span key={index}>
                                      {d}
                                      {res.properties.interestedIn[0].value
                                        .length > 1 &&
                                        index <
                                          res.properties.interestedIn[0].value
                                            .length -
                                            1 &&
                                        ", "}
                                    </span>
                                  ))
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  };

  const renderCompanyDetails = () => {
    if (view === "cardView") {
      return <>{renderCompanyInfoGrid()}</>;
    } else {
      return <>{renderCompanyInfoTable()}</>;
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilings(searchValue);
  };

  useEffect(() => {
    console.log(searchFilings);
    if (searchFilings) {
      const searchData = CompanyDetails.filter((data) => {
        if (
          data?.properties?.name[0]?.value
            .toLowerCase()
            .includes(searchFilings.toLowerCase())
        ) {
          return data;
        }
        if (data?.id.toLowerCase().includes(searchFilings.toLowerCase())) {
          return data;
        }
      });
      setCompanyDetails(searchData);
    }
    if (!searchFilings) {
      setCompanyDetails(CompanyTempDetails);
    }
  }, [searchFilings]);

  const openEditPopup = (company) => {
    setSelectedCompCountry([]);
    if (company?.properties?.country) {
      setSelectedCompCountry((prevA) => [
        ...prevA,
        company?.properties?.country[0]?.value,
      ]);
    }
    setSelectedCompany(company);
    setIsEditPopupOpen(true);
    // console.log(company, isEditPopupOpen);
    setEditedCompanyData({
      // ...other properties
      companyId: company.id,
      companyName: company?.properties?.name
        ? company?.properties?.name[0]?.value
        : "",
      country: company?.properties?.country
        ? company?.properties?.country[0]?.value
        : "",
      domain: company?.properties?.domain
        ? company?.properties?.domain[0]?.value
        : "",
      startDate: company?.properties?.startDate
        ? company?.properties?.startDate[0]?.value
        : "",
      interestedIn: JSON.parse(
        company?.properties?.interestedIn
          ? company?.properties?.interestedIn[0]?.value
          : "[]"
      ).map((data) => {
        if (data == "Environmental Social Governance") {
          return "ESG";
        } else {
          return data;
        }
      }),
      isEnabled: company?.properties?.isEnabled
        ? company?.properties?.isEnabled[0]?.value
        : "",
    });
  };

  const closeEditPopup = () => {
    setSelectedCompCountry([]);
    setSelectedCompany(null);
    setIsEditPopupOpen(false);
  };

  const handleInputValueChange = (event, property) => {
   
    let newValue;

    if (property === "interestedIn") {
      if (!editedCompanyData?.interestedIn.includes(event)) {
        setEditedCompanyData((prevData) => ({
          ...prevData,
          interestedIn: [...prevData.interestedIn, event],
        }));
      }
    } else if (property === "deleteInterestedIn") {
      if (editedCompanyData.interestedIn.includes(event)) {
        console.log(event);
        const updatedInterestedIn = editedCompanyData.interestedIn.filter(
          (item) => item !== event
        );
        setEditedCompanyData((prevData) => ({
          ...prevData,
          interestedIn: updatedInterestedIn,
        }));
      }
    } else if (property === "country") {
      setSelectedCompCountry(event.target.value);
      console.log("COMp", selectedCompCountry);
      setEditedCompanyData((prevData) => ({
        ...prevData,
        [property]: selectedCompCountry,
      }));
    } else if (property === "startDate") {
      newValue = event?.target?.value;
      const date = new Date(newValue);
      const formattedDate = date.toISOString().split("T")[0];
      setEditedCompanyData((prevData) => ({
        ...prevData,
        [property]: formattedDate,
      }));
      console.log(editedCompanyData);
    } else if (property === "domain") {
      newValue = event?.target?.value;
      setEditedCompanyData((prevData) => ({
        ...prevData,
        [property]: newValue,
      }));
    } else if (property === "name") {
      newValue = event?.target?.value;
      setEditedCompanyData((prevData) => ({
        ...prevData,
        ["companyName"]: newValue,
      }));
      setEditedCompanyData((prevData) => ({
        ...prevData,
        [property]: newValue,
      }));
    } else if (property === "isEnabled") {
        setEditedCompanyData((prevData) => ({
            ...prevData,
            [property]: event.target.checked,
          }));
    }else if (
      property !== "name" &&
      property !== "domain" &&
      property !== "startDate" &&
      property !== "interestedIn" &&
      event?.target?.type !== "checkbox" &&
      property !== "deleteInterestedIn" &&
      property !== "country" &&
      property !== "isEnabled"
    ) {
      newValue = event?.target?.value;
      setEditedCompanyData((prevData) => ({
        ...prevData,
        [property]: newValue,
      }));
    }

    
  };
  const handleSaveButtonClick = async () => {
    // Create an object to store all the edited data
    const editedData = {
      companyId: selectedCompany.id, // Include the company id for identification
      companyName:
        editedCompanyData.name || selectedCompany.properties.name[0].value,
      country: selectedCompCountry || [
        selectedCompany.properties.country[0].value,
      ],
      startDate:
        editedCompanyData.startDate ||
        selectedCompany.properties.startDate[0].value,
      domain:
        editedCompanyData.domain || selectedCompany.properties.domain[0].value,
      isEnabled:
        editedCompanyData.isEnabled !== undefined
          ? editedCompanyData.isEnabled
          : selectedCompany.properties.isEnabled[0].value,
      interestedIn:
        editedCompanyData.interestedIn ||
        selectedCompany.properties.interestedIn[0].value,
    };
    console.log(editedData);
    // You can now send `editedData` to your server for updating the company information
    // Example: sendEditedDataToServer(editedData);

    const suss = await editCompany(editedData);
    if (suss.status === 200) {
      FetchCompanyDetails();
    }
    // Close the edit popup
    closeEditPopup();
  };
  function LongMenu({ id, comapnyData }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOptionClick = (selectedOption) => {
      console.log(selectedOption);
      if (selectedOption == "Delete") {
        handleDelete(id);
      }
      handleClose();
    };

    return (
      <Grid>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            key="Edit Company"
            onClick={() => openEditPopup(comapnyData)}
          >
            <EditIcon className="me-2" />
            <span style={{ marginLeft: '8px' }}>Edit Company</span>
          </MenuItem>
          <MenuItem
            key="Delete"
            className=" mb-2"
            onClick={() => openDeletePopup(comapnyData)}
          >
            <DeleteIcon className="me-2" />
            <span style={{ marginLeft: '8px' }}>Delete</span>
          </MenuItem>
        </Menu>
      </Grid>
    );
  }

  useEffect(() => {
    console.log("eDited Com", editedCompanyData);
  }, [editedCompanyData]);

  function editCompanyPopUp() {
    return (
      <Grid>
        <Dialog
          open={isEditPopupOpen}
          onClose={closeEditPopup}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle className="" sx={{ fontSize: "1.1em", fontWeight: 550 }}>
            Edit Company
          </DialogTitle>
          <IconButton
                  size="small"
                  aria-label="close"
                  onClick= {closeEditPopup}
                  sx={{
                    position: "absolute",
                    right: 30,
                    top: 16,
                    color: "white",
                    backgroundColor: "black",
                    width: "20px",
                    height: "20px",
                  }}
                >
              <CloseIcon />
            </IconButton>
          <DialogContent>
            {selectedCompany && (
              <Grid>
                {/* Add input fields here for editing company data */}
                <Stack
                  direction="row"
                  my={2}
                  spacing={2}
                  justifyContent="space-between"
                >
                  <TextField
                    label="Company Name"
                    value={editedCompanyData.companyName}
                    sx={{ m: 2, minWidth: 245 }}
                    size="small"
                    onChange={(event) => handleInputValueChange(event, "name")}
                  />

                  <FormControl sx={{ m: 2, minWidth: 245 }}>
                    <InputLabel id="dropdown-label">country</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      multiple
                      value={selectedCompCountry}
                      size="small"
                      input={<OutlinedInput label="country" />}
                      onChange={(event) =>
                        handleInputValueChange(event, "country")
                      }
                      renderValue={(selected) => (
                        <Grid>
                          {selected?.map((value) => (
                            <Chip className="me-2" key={value} label={value} />
                          ))}
                        </Grid>
                      )}
                    >
                      {countryList?.map((res, index) => (
                        <MenuItem
                          key={res?.CountryCode}
                          value={res?.CountryCode}
                        >
                          <Checkbox
                            checked={
                              selectedCompCountry.includes(res?.CountryCode)
                                ? true
                                : false
                            }
                          />
                          <ListItemText primary={res?.CountryName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                <Stack
                  direction="row"
                  my={2}
                  spacing={2}
                  justifyContent="space-between"
                >
                  <TextField
                    type="date"
                    label="startDate"
                    focused
                    value={editedCompanyData?.startDate}
                    sx={{ m: 2, minWidth: 245 }}
                    size="small"
                    onChange={(event) =>
                      handleInputValueChange(event, "startDate")
                    }
                  />

                  <TextField
                    label="domain"
                    value={editedCompanyData?.domain}
                    sx={{ m: 2, minWidth: 245 }}
                    size="small"
                    onChange={(event) =>
                      handleInputValueChange(event, "domain")
                    }
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  mt={2}
                  mb={1}
                  justifyContent="space-between"
                >
                  <FormControl sx={{ m: 2, minWidth: 550 }}>
                    <Typography
                      className="prod-interestQues"
                      mb={1}
                      sx={{ fontSize: "0.8em", fontWeight: 550 }}
                      id="interestedIn-label"
                    >
                      What Product Are Of Interest?
                    </Typography>
                    <Grid>
                      {(editedCompanyData?.interestedIn || [])?.map((value) => (
                        <Chip
                          className="me-2"
                          key={value}
                          label={value}
                          onDelete={(event) =>
                            handleInputValueChange(value, "deleteInterestedIn")
                          }
                        />
                      ))}
                    </Grid>
                    <Grid>
                      {interestedInValue?.map((res) => (
                        <Chip
                          className="me-2 mt-2"
                          key={res}
                          label={res}
                          onClick={() =>
                            handleInputValueChange(res, "interestedIn")
                          }
                          variant={
                            (editedCompanyData?.interestedIn || []).includes(
                              res
                            )
                              ? "filled"
                              : "outlined"
                          }
                          color={
                            (editedCompanyData?.interestedIn || []).includes(
                              res
                            )
                              ? "primary"
                              : "default"
                          }
                          clickable
                        />
                      ))}
                    </Grid>
                  </FormControl>
                  ;
                </Stack>

                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <Typography>Enabled</Typography>

                  <Checkbox
                    label="Is Enabled"
                    checked={
                      editedCompanyData?.isEnabled !== undefined
                        ? editedCompanyData?.isEnabled
                        : selectedCompany.properties.isEnabled[0].value
                    }
                    onChange={(event) =>
                      handleInputValueChange(event, "isEnabled")
                    }
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>

          <Stack direction="row" justifyContent="center" spacing={2}>
            <DialogActions className="mb-2">
            <Grid style={{ textAlign: "center" }}>
              <Grid>
              <Button
                className=""
                sx={{ backgroundColor: "#25A392",width:"120px" }}
                variant="contained"
                onClick={handleSaveButtonClick}
              >
                Save
              </Button>
              </Grid>
              <Grid>
              <Button
                className="btn-cancel"
                sx={{ color: "#F37472" }}
                onClick={closeEditPopup}
              >
                Cancel
              </Button>
              </Grid>
              </Grid>
            </DialogActions>
          </Stack>
        </Dialog>
      </Grid>
    );
  }
  const openDeletePopup = (company) => {
    setSelectedDeleteCompany(company.id);
    setIsDeletePopupOpen(true);
  };

  const closeDeletePopup = () => {
    setIsDeletePopupOpen(false);
  };

  function deleteCompanyPopUp() {
    return (
      <Grid>
        <Dialog
          open={isDeletePopupOpen}
          onClose={closeDeletePopup}
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle className="" sx={{ fontSize: "1.1em", fontWeight: 550 }}>Edit Company</DialogTitle> */}
          <DialogContent>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundImage: `url(${bgImage})`, // Replace with the URL of your image
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                minHeight: "30vh", // Set the height as needed
                
              }}
            >
              <IconButton
                  size="small"
                  aria-label="close"
                  onClick= {closeDeletePopup}
                  sx={{
                    position: "absolute",
                    right: 30,
                    top: 30,
                    color: "white",
                    backgroundColor: "black",
                    width: "20px",
                    height: "20px",
                  }}
                >
              <CloseIcon />
            </IconButton>
              <Grid
                item
                className="card border border-0 rounded-circle"
                sx={{ height: "17vh", width: "17vh" }}
              >
                <img
                  className="m-auto"
                  src={require("../../images/66px_Delete.svg").default}
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid className="text-center">
                <Typography
                  className="mt-2 mb-0"
                  sx={{ fontSize: "1.2em", fontWeight: 550 }}
                >
                  Delete Company
                </Typography>
                <Typography
                  className=""
                  sx={{ fontSize: "0.7em", color: "#B2B7BC" }}
                >
                  Are You Sure You Want To Delete This Company.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <Stack direction="row" justifyContent="center" spacing={2}>
            <DialogActions className="mb-2">
            <Grid style={{ textAlign: "center" }}>
              <Grid>
              <Button
                className=""
                variant="contained"
                sx={{ backgroundColor: "#25A392",width:"120px" }}
                onClick={() => handleDelete(selectedDeleteCompany)}
              >
                Delete
              </Button>
              </Grid>
              <Grid>
              <Button
                className="btn-cancel"
                onClick={closeDeletePopup}
                sx={{ color: "#F37472" }}
              >
                Cancel
              </Button>
              </Grid>
              </Grid>
            </DialogActions>
          </Stack>
        </Dialog>
      </Grid>
    );
  }

  function toggleButtons(view, setView) {
    const handleChange = (event, nextView) => {
      console.log("toggled");

      if (nextView !== null && view !== nextView) {
        setView(nextView);
      }
    };

    return (
      <ToggleButtonGroup
        className="toggleButtons"
        exclusive
        size="small"
        value={view}
        onChange={handleChange}
      >
        <ToggleButton value="cardView" aria-label="cardView">
          <WindowIcon />
        </ToggleButton>

        <ToggleButton value="listView" aria-label="listView">
          <FormatListBulletedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  const handleView = (event) => {
    console.log();
  };
  useEffect(() => {
    //fetching Countrylist
    const FetchCountryList = async () => {
      try {
        const country = await GetCountryList();
        setcountryList(country);
        setisLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };

    const FtechUserintrestIems = async () => {
      try {
        const data = await getInterestedProductDropDownList();
        console.log(data.data);
        setinterestedInValue(data.data);
        setisLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    FtechUserintrestIems();
    FetchCountryList();
  }, []);

  return (
    <React.Fragment>
      <Grid sx={{ backgroundColor: "#F5F5F6", height: "100%" }}>
        {isLoaded ? (
          <React.Fragment>
            <Grid
              container
              direction="row"
              py={2}
              px={1}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                direction="row"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <ArrowBackIosIcon
                  style={{
                    marginTop: "0.23em",
                    marginRight: "0.2em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/AdminPanel");
                  }}
                />
                <Typography
                  style={{
                    fontWeight: 650,
                    fontSize: "1.1em",
                    marginTop: "0.2em",
                  }}
                >
                  Company Management
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#25A392" }}
                  onClick={handleOpen}
                >
                  Add Company
                </Button>
              </Grid>
            </Grid>
            <Paper sx={{ padding: "0.7em", marginX: "0.5em" }}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <FilterDisclosureButtons />
                </Grid>
                <Grid
                  item
                  direction="row"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item>{toggleButtons(view, setView)}</Grid>
                  <Grid item>
                    <IconButton
                      sx={{
                        border: "1px solid #BAD5E7",
                        backgroundColor: "#E9F1F7",
                        borderRadius: "6px",
                        marginLeft: "10px",
                      }}
                      aria-label="filter"
                    >
                      <Badge color="error" variant="">
                        <FilterAltIcon sx={{ color: "#2076B0" }} />
                      </Badge>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {editCompanyPopUp()}
              {deleteCompanyPopUp()}
              {/* <Grid className="mt-3">
                <TextField
                  value={searchFilings}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                  className=""
                  sx={{ fontWeight: 550, fontSize: "1em" }}
                >
                  Add Company
                </DialogTitle>
                <IconButton
                  size="small"
                  aria-label="close"
                  onClick= {handleClose}
                  sx={{
                    position: "absolute",
                    right: 30,
                    top: 16,
                    color: "white",
                    backgroundColor: "black",
                    width: "20px",
                    height: "20px",
                  }}
                >
              <CloseIcon />
            </IconButton>
                <DialogContent>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-around"
                  >
                    <Grid xs={6}>
                      <TextField
                        // fullWidth
                        label="Company Name"
                        name="companyName"
                        value={companyInfo.companyName}
                        onChange={handleChange}
                        margin="normal"
                        sx={{ m: 2, minWidth: 225 }}
                        size="small"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <FormControl sx={{ m: 2, width: 225 }} size="small" >
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                          labelId="country-label"
                          id="country"
                          name="country"
                          multiple
                          value={companyInfo.country}
                          input={<OutlinedInput label="Country" />}
                          onChange={handleCountryChange}
                          // renderValue={(selected) => (
                          //   <Grid>
                          //     {selected?.map((value) => (
                          //       <Chip key={value} label={value} />
                          //     ))}
                          //   </Grid>
                          // )}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {countryList?.map((res) => (
                            <MenuItem
                              key={res.CountryCode}
                              value={res.CountryCode}
                            >
                              <Checkbox
                                checked={
                                  companyInfo.country.indexOf(res.CountryCode) >
                                  -1
                                }
                              />
                              <ListItemText primary={res.CountryName} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <TextField
                        // fullWidth
                        focused
                        type="date"
                        label="Start Date"
                        name="startDate"
                        value={companyInfo.startDate}
                        onChange={handleChange}
                        margin="normal"
                        sx={{ m: 2, minWidth: 225 }}
                        size="small"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        // fullWidth
                        label="Domain"
                        name="domain"
                        value={companyInfo.domain}
                        onChange={handleChange}
                        margin="normal"
                        sx={{ m: 2, minWidth: 225 }}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <InputLabel
                        sx={{ fontWeight: 550, color: "#061826" }}
                        className="mb-0"
                        id="country-label"
                      >
                        {Questions}
                      </InputLabel>
                      <Typography
                        className="text-muted"
                        mt="1"
                        sx={{ color: "#B2B7BC", fontSize: "0.7em" }}
                      >
                        Please Select Your Interest(s)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} my={0}>
                      {interestedInValue?.map((res) => (
                        <Chip
                          key={res}
                          label={res}
                          onClick={() => handleinterestedInChange(res)}
                          clickable
                          className="me-2"
                          variant={
                            clickedChips.includes(res) ? "filled" : "outlined"
                          }
                          color={
                            clickedChips.includes(res) ? "primary" : "default"
                          }
                        />
                      ))}
                    </Grid>
                  </Grid>
                </DialogContent>
                <Grid style={{ textAlign: "center", padding: "16px" }}>
                  <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className=""
                    margin={1}
                    sx={{ backgroundColor: "#25A392" }}
                    disabled={companyInfo.companyName && companyInfo.startDate && companyInfo.domain && companyInfo.isEnabled &&companyInfo.interestedIn && companyInfo.country.length > 0 ? false : true}
                  >
                    Submit
                  </Button>
                  </Grid>
                  <Grid>
                  <Button
                    variant="text"
                    margin="1"
                    className="btn-cancel"
                    onClick={handleClose}
                    sx={{ color: "#F37472" }}
                  >
                    Cancel
                  </Button>
                  </Grid>
                </Grid>
              </Dialog>
              {renderCompanyDetails()}
              {/* Render the table below the button */}
              {/* {renderCompanyInfoGrid()} */}
              {/* {renderCompanyInfoTable()} */}
            </Paper>
          </React.Fragment>
        ) : (
          <Box p={2}>
            <Grid className="loading-container mt-5 text-center">
              <Typography className="ms-4">Loading....</Typography>
              <PropagateLoader
                css={override}
                size={15}
                color={"#3498db"}
                loading={true}
              />
            </Grid>
          </Box>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default AddCompany;
