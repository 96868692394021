import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import OutlinedInput from "@mui/material/OutlinedInput";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import FeedIcon from "@mui/icons-material/Feed";
import {
  CardActions,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
  Grid,
  IconButton,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import "../styles/FormCreation/newDisclosure.css";
import NoteContext from "context/PageContext";
import {
  listNewDisclosures,
  publishDisclosure,
  companyDropdown,
  getRolePermissions,
  domainDropdownList,
  GetNotification,
  CloneDisclosure
} from "../services/FormCreationFetch";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PaperComponent from "components/PaperComponent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WindowIcon from "@mui/icons-material/Window";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled, alpha } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import AddDisclosureModal from "./AddDisclosureModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditDisclosure from "./EditDisclosure";
import Modal from '@mui/material/Modal';
import Clone from "./Clone";
import { useRoleContext } from "context/RoleContex";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 150,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(128,128,128) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      marginTop: 0,
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ToggleButtons() {
  const [view, setView] = React.useState("listView");

  // const handleChange = (event, nextView) => {
  //   if (nextView !== null && view !== nextView) {
  //     setView(nextView);
  //   }
  // };
  return (
    <ToggleButtonGroup className="toggleButtons" exclusive size="small">
      <ToggleButton value="cardView" aria-label="cardView">
        <WindowIcon />
      </ToggleButton>
      <ToggleButton value="listView" aria-label="listView">
        <FormatListBulletedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

function GridViewpage() {
  const [open, setOpen] = React.useState(false);
  const [disclosureNames, setDisclosureNames] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [value, setValue] = React.useState([]);
  const [companyValue, setCompanyValue] = React.useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const contextData = useContext(NoteContext);
  // uncomment
  const [items, setItems] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const opennew = Boolean(anchorEl);

  // const [selectedItem, setSelectedItem] = useState("");


  const [SeletedForm, setSeletedForm] = useState("");

  const [publishCheck, setPublishCheck] = useState(false);
  const PathforRedirect = localStorage.getItem("pathSelected");
  const [EditPopUP, setEditPopUP] = useState(false);
  const [DataforEditDisclosure, setDataforEditDisclosure] = useState({
    ElementID: "",
    ElementName: "",
    index: "",
  });

  const userData = JSON.parse(localStorage.getItem("AccountDetails"));

  const [adminAccess, setAdminAccess] = useState(false);

  const [utilityPermission, setUtilityPermission] = useState({})

  const [disclosureId, setDisclosureId] = useState("")

  const [domains, setDomains] = useState([]);

  const { selectedRole, updatePublishTriggered, editDisclosureTitleTrigered, updateEditDisclosureTitleTrigered, addDisclosureTriggered, updateAddDisclosureTriggered } = useRoleContext();


  const [creatorAccess, setCreatorAccess] = useState(false);
  const [isClone, setisClone] = useState(false);

  useEffect(() => {
    if (userData?.permissions) {
      console.log("tes67", userData.permissions);
      const permissions = userData?.permissions.map(data => {
        return data.displayName
      });
      console.log("tes6", permissions);
      for (let i of permissions) {
        if (i == "Filing-Administrator") {
          console.log("tes4");
          setAdminAccess(true);
        }
      }
    }
    getDomainsApi()
  }, []);

  useEffect(() => {
    if (selectedRole) {
      GetRolePermissionAPi(selectedRole)
      if (userData?.filingGroups) {
        const selectedGroup = userData?.filingGroups.filter(data => { return data.displayName == selectedRole })[0];
        console.log(selectedGroup, "selectedGroup");
        getNotificationApi(selectedGroup.id)
      }
    }
  }, [selectedRole])

  const getNotificationApi = async (id) => {
    const res = await GetNotification(id)
  }

  const getDomainsApi = async () => {
    const res = await domainDropdownList()
    console.log(res, "tes5678");
    setDomains(res);
  }

  const GetRolePermissionAPi = async () => {
    try {
      const res = await getRolePermissions(selectedRole)
      setUtilityPermission(res?.utility[0]);
    } catch (error) {
      console.log(error);
    }

  }
  const companydropdown = async () => {
    await companyDropdown().then((data) => {
      //console.log(data.data);
      setCompanyList(data.data);
      console.log(companyList, "tes56");
    });
  };

  useEffect(() => {
    if (editDisclosureTitleTrigered) {

      companydropdown();
      getAllDisclosures();
    }

  }, [editDisclosureTitleTrigered]);

  useEffect(() => {
    
    if (addDisclosureTriggered) {
      companydropdown();
      getAllDisclosures();
    }
  }, [addDisclosureTriggered]);

  useEffect(() => {
    const companydropdown = async () => {
      await companyDropdown().then((data) => {
        //console.log(data.data);
        setCompanyList(data.data);
        console.log(companyList, "tes56");
      });
    };
    companydropdown();
    getAllDisclosures();
  }, []);

  const getAllDisclosures = async () => {
    await listNewDisclosures("jktech.com").then((data) => {
      // console.log(data.values);
      if (data) {
        setDisclosureNames(data.values);
        console.log("Data", disclosureNames);
      } else {
        alert("Network Error");
      }
    });
  };

  const handleClickPublish = async (disclosureName, index) => {
    console.log("tes370", disclosureId);
    const disclosure = disclosureNames.filter(data => {
      if (data.id == disclosureId) {
        return data;
      }
    })
    console.log(disclosure[0]?.companies, "tes370");
    let data = disclosure[0]?.companies
    if (!disclosure[0]?.companies) {
      data = ["jktech.com"]
    }
    await publishDisclosure(disclosureId, data).then((data) => {
      //console.log(SeletedForm, items);
      // console.log("published", data);
      getAllDisclosures();
      updatePublishTriggered(true)

    });
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    console.log("d99", disclosureNames);
  }, [disclosureNames])

  const AlertDialog = ({ data }) => {
    const [openTes, setOpenTes] = React.useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [completeItems, setCompleteItem] = useState([]);


    useEffect(() => {
      if (data?.companies) {
        setCompleteItem(data.companies);
      }
    }, []);

    const handleClickOpen = () => {
      setOpenTes(true);
      console.log("tes45", data);

    };

    const handleAddItem = () => {
      if (selectedItem && !completeItems.includes(selectedItem)) {
        setCompleteItem([...completeItems, selectedItem]);
        setSelectedItem("");
      }
      else {
        setSelectedItem("");
      }
    };
    const handleClose = (e) => {
      setSelectedItem("");
      setCompleteItem([])
      console.log(e);
      if (!e) {
        setCompleteItem([]);
      }
      setOpenTes(false);
    };

    const AddCompanyName = (item) => {
      //console.log(item);

      setSelectedItem(item);
    };

    const handleRemoveItem = (item) => {
      const updatedItems = completeItems.filter((i) => i !== item);
      setCompleteItem(updatedItems);
    };

    const TriggerContinue = () => {
      setItems(completeItems);
      handleClose(true);
      setDisclosureNames((prevData) => {
        const newData = [...prevData];
        newData.filter(res => {
          if (res.id == data.id) {
            res.companies = completeItems
          }
        })
        return newData;
      });


    }

    return (
      <React.Fragment>
        <Button
          size="small"
          sx={{
            border: "2px dashed lightgrey",
            borderRadius: "20px",
            padding: "5px 8px",
            color: "#2076B0",
          }}
          onClick={handleClickOpen}
        >
          Add company
        </Button>
        <Dialog
          open={openTes}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid container justifyContent="space-between" px={2} py={1}>
            <Grid item>
              <Typography
                sx={{
                  color: "#061826",
                  fontSize: "26px",
                  fontWeight: 600,
                }}
                id="customized-dialog-title"
              >
                Company
              </Typography>
            </Grid>
            <Grid item>
              <span>

                <IconButton
                  size="small"
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    color: "white",
                    backgroundColor: "black",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Grid>

          </Grid>


          <DialogContent className="m-0 p-0">
            <Typography variant="h6" sx={{ fontSize: "0.9em", pl: 2 }} className="mb-1" >{data.name}</Typography>
            <Grid container px={2}>
              <Grid container>
                <Grid item sx={{ marginRight: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Select Item</InputLabel>
                    <Select
                      value={selectedItem}
                      input={<OutlinedInput label="Select Item" />}
                      onChange={(e) => { AddCompanyName(e.target.value) }}
                      sx={{ width: 250 }}
                      size="small"
                    >
                      {domains.map((res) => {
                        return <MenuItem value={res}>{res}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleAddItem}
                    startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
                    size="medium"
                    sx={{ backgroundColor: "#E9F6F4" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>

              <Grid container direction={"column"} spacing={2} my={1}>
                <Grid item>
                  <Typography sx={{ color: "#061826", fontWeight: 600 }}>
                    Company To Publish
                  </Typography>
                </Grid>
                <Grid item>
                  {completeItems.length > 0 ? (
                    completeItems?.map((item) => (
                      <Chip
                        label={item}
                        key={item}
                        sx={{ marginRight: "10px", backgroundColor: "#E9F6F4" }}
                        onDelete={() => handleRemoveItem(item)}
                      />
                    ))
                  ) : (
                    <Typography sx={{ color: "#384651", size: "16px" }}>
                      No Company Selected
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container direction={"column"} alignItems={"center"}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    TriggerContinue()
                  }}
                  sx={{ backgroundColor: "#25A392" }}
                >
                  Continue
                </Button>
              </Grid>
              <Grid item>
                <Button
                  value={false}
                  color="error"
                  onClick={() => {
                    handleClose(false);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  const handleForClone = async (Discl) => {

    // const result=await CloneDisclosure(Discl);

  }
  const handleClick = (event, element, index) => {

    setDataforEditDisclosure((prevData) => ({
      ...prevData,
      ["ElementID"]: element.id,
      ["ElementName"]: element?.name ? element.name : "",
      ["index"]: index,
    }));

    console.log(event, element, index, "tes6789");
    setDisclosureId(element.id)
    setSeletedForm(element);
    setAnchorEl(event.currentTarget);
    setPublishCheck(element.isPublished);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setPublishCheck(false);
  };

  const handleCloseForEdit = () => {
    setIsDialogOpen(false);
  };

  const dialogContent = async () => {
    return (
      <Dialog open={isDialogOpen} onClose={handleCloseForEdit}>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography sx={{ color: "#061826", fontSize: 26 }}>
                Edit Disclosure
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleCloseForEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row">
            <Grid item>
              <TextField id="Disclosure Name" label="Disclosure Name" />
            </Grid>
            <Grid item>
              <TextField
                focused
                id="Select Date"
                label="Select Date"
                type="date"
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item>
              <TextField id="Version Number" label="Version Number" />
            </Grid>
            <Grid item>
              <TextField id="Select An option" label="Select An option" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <TextField id="Select An option" label="Select An option" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Button onClick={handleCloseForEdit} color="primary">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCloseForEdit} color="primary">
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const handleClone = () => {



    return (
      <Modal
        open={isClone}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>)
  }
  const HandleOpenforClone = () => {
    setisClone(false);
  }
  return (
    <Box padding={"20px"}>
      <Grid container justifyContent={"space-between"} mb={2}>
        <Grid item>
          <Typography
            sx={{ color: "#061826", fontSize: "26px", fontWeight: "600" }}
          >
            <ArrowBackIosIcon
              style={{
                marginRight: "0.2em",
                cursor: "pointer",
                // display: isMobile && !isTreeVisible ? "none" : "block",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            Utility
          </Typography>

        </Grid>
        <Grid item>
          {
            (utilityPermission?.createData) && (
              <Button
                variant="contained"
                sx={{ backgroundColor: "#25A392" }}
                onClick={async () => {
                  setOpenDialog(true);
                  updateAddDisclosureTriggered(false)
                }}
              >
                create New template
              </Button>
            )
          }
          <AddDisclosureModal
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
        </Grid>
      </Grid>
      <PaperComponent>
        <Grid container justifyContent={"space-between"} mb={2}>
          <Grid item>
            <Typography
              sx={{
                color: "#061826",
                fontSize: "20px",
                fontWeight: "600",
                marginTop: "10px",
                paddingLeft: "10px",
              }}
            >
              Created Template
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} px={2}>
          {disclosureNames.length !== 0
            ? disclosureNames.map((element, index) => (
              <Grid item xs={12} md={6}>
                <Card
                  variant="outlined"
                  key={index}
                  sx={{
                    padding: "10px",
                    marginY: "10px",
                    border: "1px solid #D5D3F9",
                    borderRadius: "9px",
                  }}
                >
                  <Grid container justifyContent={"space-between"}>
                    <Grid
                      item
                      onClick={() => {
                        localStorage.setItem(
                          "disclosurename",
                          JSON.stringify(element.id)
                        );

                        localStorage.setItem("DisclosureDetails", JSON.stringify({ "ElementID": element.id, "ElementName": element?.name ? element.name : "", "index": index }));
                        navigate("/RegistrationResult", {
                          state: { disclosure: element.id },
                        });
                        contextData.setDisclosureName(element.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Typography
                        customkey={index} // Adding a unique key to each element
                        sx={{ color: "#25A392", fontSize: "18px" }}
                        variant="body2"
                      >
                        {element.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {/* <Button
                        size="small"
                        sx={{
                          border: "2px dashed lightgrey",
                          borderRadius: "20px",
                          padding: "5px 8px",
                          color: "#2076B0",
                        }}
                        onClick={handleClickOpen}
                      >
                        Add company
                      </Button> */}
                      <AlertDialog data={element} />
                      {
                        (utilityPermission?.menuButton) && (
                          <IconButton
                            key={"menu" + index}
                            id={"demo-customized-button" + index}
                            aria-controls={
                              opennew ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={opennew ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={(event) => {
                              handleClick(event, element, index);

                            }}
                          >
                            <MoreVertIcon sx={{ color: "#2076B0" }} />
                          </IconButton>
                        )
                      }

                      <StyledMenu
                        id={`demo-customized-menu-${index}`}
                        MenuListProps={{
                          "aria-labelledby": `demo-customized-button-${index}`,
                        }}
                        anchorEl={anchorEl}
                        open={opennew}
                        onClose={handleClose}
                      >
                        {(publishCheck) ? (
                          <>
                            {
                              adminAccess && (

                                <MenuItem
                                  // onClick={() => handleClickPublish(element.id, index)}
                                  disableRipple
                                >
                                  <DriveFolderUploadIcon />
                                  Unpublish
                                </MenuItem>
                              )
                            }
                          </>
                        ) : (
                          <Grid>
                            {
                              (utilityPermission?.editButton) && (
                                <MenuItem
                                  onClick={() => {
                                    setEditPopUP(!EditPopUP);
                                    updateEditDisclosureTitleTrigered(false)
                                    setAnchorEl(null);
                                  }}
                                  key={index}
                                  disableRipple
                                >
                                  <EditIcon />
                                  Edit Title
                                </MenuItem>
                              )
                            }
                            {
                              (utilityPermission?.clone) && (
                                <MenuItem onClick={() => {
                                  setisClone(!isClone)
                                  setAnchorEl(null);
                                  handleClose();
                                }} disableRipple>
                                  <FolderCopyIcon />
                                  Clone
                                </MenuItem>
                              )

                            }
                            {
                              (utilityPermission?.publish) && (
                                <MenuItem
                                  onClick={() =>
                                    handleClickPublish(element, index)
                                  }

                                  disableRipple
                                >
                                  <DriveFolderUploadIcon />
                                  Publish
                                </MenuItem>

                              )
                            }
                            {isClone && <Paper elevation={3} ><Clone isclone={isClone} setclose={() => { setisClone(!isClone) }} /> </Paper>}
                            {
                              (utilityPermission?.delete) && (
                                <MenuItem onClick={handleClose} disableRipple>
                                  <DeleteIcon />
                                  Delete
                                </MenuItem>
                              )
                            }
                          </Grid>
                        )}
                      </StyledMenu>
                    </Grid>
                  </Grid>
                  {/* code for edite title */}


                  {/* code for opening dailog box for *AddCompany *Edit Disclousere */}

                  {/* 1st code */}
                  {/* {open && <AddcompanyForPublish key={index} data={element} />} */}
                  <Dialog open={isDialogOpen} onClose={handleCloseForEdit}>
                    {dialogContent}
                  </Dialog>

                  <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                    Company to Publish
                  </Typography>
                  <br />
                  <Grid>
                    {
                      element?.companies ? (
                        element.companies?.map((res) => {
                          return <Chip label={res} key={res} className="me-2" />;
                        })
                      ) :
                        (
                          <Grid
                            container

                          >
                            {element?.isPublished && (
                              <Grid item container justifyContent="space-between">
                                <Grid
                                  item
                                  mr={3}
                                  sx={{
                                    backgroundColor: "#25A392",
                                    color: "white",
                                    px: 1.4,
                                    py: 0.5,
                                    borderRadius: "2rem",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "0.95rem" }}>
                                    Published
                                  </Typography>
                                </Grid>
                                {/* <Grid>
                                <Button variant="contained" onClick={()=>{console.log(element.id)}}>Unpublish</Button>
                              </Grid> */}
                              </Grid>
                            )}
                          </Grid>
                        )
                    }
                  </Grid>
                </Card>
                {EditPopUP && (
                  <Paper elevation={3} >
                    <EditDisclosure
                      data={DataforEditDisclosure}
                      setEditPopUP={setEditPopUP}
                      EditPopUP={EditPopUP}
                    />
                  </Paper>
                )}
              </Grid>
            ))
            : null}
        </Grid>
      </PaperComponent>
    </Box>
  );
}

export default GridViewpage;

