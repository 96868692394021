import React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "../styles/Forms_Styles/Form_Autocomplete.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

const Form_Autocomplete = ({onChange,FieldValues,getAnswerDisable}) => {
  const {Answer}=useContext(NoteContext);
  const dropValues=typeof FieldValues==='string' ?
                  FieldValues.length!==0 && FieldValues.split(',')
                  :FieldValues


  return (
          <Autocomplete
            disablePortal 
            id="combo-box-demo"
            options={dropValues || []}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(o) => (typeof o === "string" ? o : "")}
            renderInput={(params) => (
              <TextField {...params} placeholder='Enter value here'/>
            )}
            value={typeof Answer.UpdatedAnswer ==="string" && dropValues.includes(Answer.UpdatedAnswer) && Answer.UpdatedAnswer}
            onChange={(_, newValue) =>onChange(newValue??'')}
            size="small"
            disabled={getAnswerDisable}
          />
  );
};

export default Form_Autocomplete;
