import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import send from "./../../images/24px_Send.png";
import FileIcon from "images/FileIcon.png";
import { format } from "date-fns";
import withSnackbar from "../../components/Alert/withSnackbar";
import "../../Compliance/styles/Case_Managment/Comments.css";
import NoteContext from "context/PageContext";
import Loader from "Compliance/CaseManagment/Loader";
const {
  getComments,
  getCommentsReplies,
  getCaseAttachments,
  downloadFile,
  editComments,
  deleteComments,
} = require("../services/CaseFetch");
//commentsData,setCommentsData,
const Comments = ({ is_new, setnewCommentsData, newcommentsData, setreplyData, newreplyData, show, caseid, showSnackbar }) => {
  const ContextData = useContext(NoteContext);
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [attachmentFile, setAttachmentFile] = useState(null);
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");
  const [editedCommentText, setEditedCommentText] = useState('');
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [editedCommentId, setEditedCommentId] = useState('');
  const [editedItemType, setEditedItemType] = useState('');
  const [newReplyText, setNewReplyText] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [commentsData, setCommentsData] = useState([]);
  //const [newcommentsData, setnewCommentsData] = useState([]);
  // const [newreplyData, setreplyData] = useState([]);
  const user = localStorage.getItem("AccountDetails")
  const parsedUser = user ? JSON.parse(user) : ''
  const [userdetails, setUserDetails] = useState(parsedUser?.endUser);
  const fetchsubCasesData = async (caseid) => {
    setLoading(true)
    try {
      const [comments] = await Promise.all([
        getComments({ caseId: caseid, domain: ContextData?.UserSelected }),

      ]);



      if (comments && comments.data && comments.data.comments) {
        const commentsData = comments.data.comments;
        setCommentsData(commentsData);

        // Fetch replies for each comment and update commentsData with replies
        for (let comment of commentsData) {
          try {
            const reply = await getCommentsReplies(comment.commentId);
            const commentattachments = await getCaseAttachments(comment.commentId)
            const ctattachment = typeof commentattachments?.data?.attachments === "string" ? JSON.parse(commentattachments?.data?.attachments) : commentattachments?.data?.attachments;
            const processedcommnetattchments = await processAttachments(ctattachment);
            const updatedComment = {
              ...comment,
              attachment: processedcommnetattchments,
              replies: reply.data.comments
            };
            setCommentsData(prevState => prevState.map(c => c.commentId === comment.commentId ? updatedComment : c));
          } catch (error) {
            console.error(`Error fetching replies for comment ${comment.commentId}:`, error);
          }
        }
      } else {
        console.error("Comments data is empty or invalid");
      }


      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if needed
    }
  };
  const processAttachments = async (attachments) => {
    const processedAttachments = [];

    for (let i = 0; i < attachments.length; i++) {
      try {
        const fileData = await downloadFile(attachments[i].url);
        // const base64 =  base64ToFile(fileData.data.data, attachments[i].FileName, attachments[i].url.split('/')[4].split('.')[1]);
        processedAttachments.push({ attachment_base64: attachments[i].prefix + "," + fileData.data.base64, file_name: attachments[i].filename, attachment_extension: 'application/' + attachments[i].filename.split('.')[1] });

      } catch (error) {
        // console.error('Error processing attachment:', error);
      }
    }

    return processedAttachments;
  };
  useEffect(() => {

    if (ContextData?.UserSelected) {

      fetchsubCasesData(caseid);

    }
  }, [ContextData?.UserSelected,caseid]);
  const renderInitials = (name) => {
    const initials = name
      ?.split(" ")
      ?.map((part) => part.charAt(0))
      ?.join("")
      ?.toUpperCase();
    return initials;
  };

  const postComment = (event) => {
    event.preventDefault();

    const reader = new FileReader();
    reader.onload = function (e) {
      const newComment = {
        "id": caseid,
        "comment_attachments": [{
          file_name: attachmentFile.name,
          attachment_extension: attachmentFile.type,
          attachment_base64: e.target.result,
        }],
        "is_reply": false,
        "message": commentText,
        "worker": userdetails?.displayName,
        "worker_email": userdetails?.mail,
        "worker_id": userdetails?.mail
      }
      if (is_new) {
        setCommentsData([...commentsData, newComment])
        setnewCommentsData([...newcommentsData, newComment]);
      }
      else {
        setnewCommentsData([...newcommentsData, newComment]);
      }
      setCommentText("");
      setAttachmentFile(null);
    };

    if (attachmentFile) {
      reader.readAsDataURL(attachmentFile);
    } else {

      const newComment = {
        "id": caseid,
        "comment_attachments": [],
        "is_reply": false,
        "message": commentText,
        "worker": userdetails?.displayName,
        "worker_email": userdetails?.mail,
        "worker_id": userdetails?.mail
      }
      if (is_new) {
        setCommentsData([...commentsData, newComment])
        setnewCommentsData([...newcommentsData, newComment]);
      }
      else {
        setnewCommentsData([...newcommentsData, newComment]);
      }
      setCommentText("");
    }
  };
  const handleAttachmentClick = () => {
    document.getElementById("attachment").click();
  };
  const onEditComment = async (commentId, itemType, initialComment) => {
    setIsEditingComment(true);
    setEditedCommentId(commentId);
    setEditedItemType(itemType);
    setEditedCommentText(initialComment);
  };

  const saveEditedComment = async () => {
    const param = {
      param: {
        id: editedCommentId,
      },
      data: {
        data: editedCommentText
      }
    }
    try {
      const res = await editComments(param)

      setIsEditingComment(false);
      setEditedCommentId('');
      setEditedCommentText('');
      const updatedComments = commentsData.map((comment) => {
        if (editedItemType === 'comment' && comment.commentId === editedCommentId) {
          return {
            ...comment,
            comment: editedCommentText,
          };
        }
        const updatedReplies = comment.replies.map((reply) => {
          if (editedItemType === 'reply' && reply.replyId === editedCommentId) {
            return {
              ...reply,
              message: editedCommentText,
            };
          }
          return reply;
        });

        return {
          ...comment,
          replies: updatedReplies,
        };
      });

      setCommentsData(updatedComments);
    }
    catch (e) {
      showSnackbar(e.response.data.message || "Error in updating comment", "error")
    }
  };



  const onDeleteComment = async (comment_id, type) => {
    const param = {
      id: comment_id
    }
    const res = await deleteComments(param)
    if (type === 'comment') {
      const updatedComments = commentsData.filter(comment => comment.commentId !== comment_id);
      setCommentsData(updatedComments);
    }
    else if (type === 'reply') {
      const updatedComments = commentsData.map(comment => {
        const updatedReplies = comment.replies.filter(reply => reply.replyId !== comment_id);
        return {
          ...comment,
          replies: updatedReplies
        };
      });

      setCommentsData(updatedComments);
    }

  }
  const addReplyToComment = (commentId, replyText) => {
    // Update commentsdata state with the new reply
    const updatedComments = commentsData.map(comment => {
      if (comment.commentId === commentId) {
        var newreply = {
          message: replyText,
          userName: userdetails?.displayName,
          worker_email: userdetails?.mail,
          worker_id: userdetails?.mail,
          is_reply: true,
          domain: ContextData?.UserSelected,
          id: comment.commentId,
          comment_attachments: [],
          dateAndTime: formattedDate
        }
        setreplyData([...newreplyData, newreply])
        return {
          ...comment,
          replies: [...comment?.replies, newreply]
        };
      }

      return comment;
    });
    setCommentsData(updatedComments);
    // Clear the reply text and reset the replyToCommentId
    setNewReplyText("");
    setReplyToCommentId(null);
  };

  const showReplyBox = (commentId) => {
    setReplyToCommentId(commentId);
  };
  const handleAddReply = (e) => {
    e.preventDefault();
    if (newReplyText.trim() === "") return;
    if (replyToCommentId === null) return;
    addReplyToComment(replyToCommentId, newReplyText);
  };
  const downloadFileOnClick = (file) => {

    const link = document.createElement('a');
    link.href = file.attachment_base64;
    link.download = file.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>

      <Grid>
{loading && <Loader/>}
{!loading &&
        <Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Grid style={{ width: "30%" }}></Grid>
            <Grid id="comments-container" style={{ width: "70%", marginRight: "15px" }}>
              {commentsData.length <= 0 && <Typography>No comments</Typography>}
              {!is_new && commentsData?.map((comment, index) => (
                <Grid key={index}>

                  <Grid
                    style={{ display: "flex", flexDirection: 'column' }}
                  >

                    <Grid style={{ display: "flex", alignItems: 'center' }}>
                      <Grid style={{ marginTop: "5px" }}>
                        <Grid
                          className="commentprofile"
                        >
                          {renderInitials(comment?.userName)}
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%"
                        }}
                      >
                        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid>
                            <span title="CommentOn" className="commentDate">
                              {comment.dateAndTime}
                            </span>
                          </Grid>
                          {show && <Grid style={{ display: "flex" }}>
                            <span style={{ marginRight: "10px" }}>
                              <ReplyIcon
                                onClick={() => showReplyBox(comment.commentId)}
                              />
                            </span>
                            {comment?.userEmail == userdetails?.mail &&
                              <>
                                <span style={{ marginRight: "10px" }}>
                                  <EditIcon
                                    onClick={() => onEditComment(comment.commentId, "comment", comment.comment)}
                                  />
                                </span>
                                <span>
                                  <DeleteIcon
                                    onClick={() => onDeleteComment(comment.commentId, 'comment')}
                                  />
                                </span>
                              </>
                            }
                          </Grid>
                          }
                        </Grid>
                        <Grid
                          className="commentBox"
                          style={{ padding: '10px' }}
                        >
                          {isEditingComment && editedCommentId === comment.commentId ? (


                            <Grid className="textbox-container">

                              <TextField
                                size="small"
                                type="text"
                                value={editedCommentText}
                                onChange={(e) => setEditedCommentText(e.target.value)}
                                className="message-input commenttxt"
                              />

                              <Button onClick={saveEditedComment} className="send-button">
                                <img src={send} style={{ width: '24px', height: '24px' }} alt="Send" />
                              </Button>
                            </Grid>


                          ) : (
                            <Grid>
                              <span className="commenttxt">{comment.comment}</span>
                              {comment?.attachment?.length > 0 ? comment.attachment?.map((file, index) => (
                                <Grid key={index}>
                                  <img src={FileIcon} alt="File Icon" className="file-icon" />
                                  <span className="file-name" onClick={() => {
                                    downloadFileOnClick(file);
                                  }} style={{ cursor: 'pointer', color: "#2076B0" }}>{file.file_name}</span>
                                </Grid>
                              )) : ''}
                            </Grid>
                          )}

                        </Grid>
                      </Grid>

                    </Grid>
                    {replyToCommentId === comment?.commentId && (
                      <Grid>

                        <Grid
                          className="col-12 descriptionCss"
                          style={{
                            display: "flex", marginLeft: "40px", alignItems: "center"
                          }}
                        >

                          <Grid
                            style={{ marginTop: "5px" }}
                          >
                            <Grid
                              className="commentprofile"

                            >
                              {renderInitials(userdetails?.displayName)}
                            </Grid>
                          </Grid>
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "90%"

                            }}
                          >
                            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                              <Grid>
                                <span title="CommentOn" className="commentDate">
                                  {formattedDate}
                                </span>
                              </Grid>
                            </Grid>
                            <Grid
                              className="commentBox"
                              style={{ padding: '5px', }}
                            >
                              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={newReplyText}
                                  onChange={(e) => setNewReplyText(e.target.value)}
                                  placeholder="Type your reply here..."
                                  style={{ background: '#fff', width: '100%' }}
                                />
                                <Button id="post-button" onClick={handleAddReply}>
                                  <img src={send} style={{ width: '24px', height: '24px', marginRight: '20px' }} alt="Send" />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {/* Render replies with an indent */}
                  {comment?.replies?.map((reply, index) => (
                    <Grid className="row" key={index} style={{ marginRight: "10px" }}>
                      <Grid
                        className="col-12 descriptionCss"
                        style={{
                          display: "flex", marginLeft: "40px", alignItems: "center"
                        }}
                      >

                        <Grid
                          style={{ marginTop: "5px" }}
                        >
                          <Grid
                            className="commentprofile"

                          >
                            {renderInitials(reply.userName)}
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%"

                          }}
                        >
                          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid>
                              <span title="CommentOn" className="commentDate">
                                {reply?.dateAndTime}
                              </span>
                            </Grid>
                            
                            {show && reply.replyId && reply.userEmail == userdetails?.mail &&
                              <Grid>
                                <span style={{ marginRight: "10px" }}>
                                  <EditIcon
                                    onClick={() => onEditComment(reply.replyId, "reply", reply.message)}
                                  />
                                </span>
                                <span>
                                  <DeleteIcon
                                    onClick={() => onDeleteComment(reply.replyId, 'reply')}
                                  />
                                </span>
                              </Grid>
                            }
                          </Grid>
                          <Grid
                            className="commentBox"
                          >
                            {isEditingComment && editedCommentId === reply.replyId ? (
                              <Grid className="textbox-container" style={{ padding: '10px' }}>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={editedCommentText}
                                  onChange={(e) => setEditedCommentText(e.target.value)}
                                  className="message-input commenttxt"
                                />
                                <Button onClick={saveEditedComment} className="send-button">
                                  <img src={send} style={{ width: '24px', height: '24px' }} alt="Send" />
                                </Button>
                              </Grid>
                            ) : (
                              <Grid>
                                <span className="commenttxt">{reply.message}</span>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ))}
              {newcommentsData?.map((comment, index) => (
                <Grid key={index}>

                  <Grid
                    style={{ display: "flex", flexDirection: 'column' }}
                  >

                    <Grid style={{ display: "flex", alignItems: 'center' }}>

                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%"
                        }}
                      >

                        <Grid>
                          <span title="CommentOn" className="commentDate">
                            {formattedDate}
                          </span>
                        </Grid>
                        <Grid style={{ display: 'flex' }}>
                          <Grid
                            className="commentprofile"

                          >
                            {renderInitials(comment.worker)}
                          </Grid>
                          <Grid
                            className="commentBox"
                          >
                            <Grid
                              className="descriptionClass"
                              id="comment-text"
                              style={{ marginLeft: "5px" }}
                            >
                              <span
                                id="user-comment"
                                title="Comment"
                                className="comment_txt"
                              >
                                {comment.message}
                                {comment?.comment_attachments?.length > 0 ? comment.comment_attachments?.map((file, index) => (
                                  <Grid key={index}>
                                    <img src={FileIcon} alt="File Icon" className="file-icon" />
                                    <span className="file-name" onClick={() => {
                                      downloadFileOnClick(file);
                                    }} style={{ cursor: 'pointer', color: "#2076B0" }}>{file.file_name}</span>
                                  </Grid>
                                )) : ''}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {show && <Grid style={{ marginTop: "10px" }}>
            {/* <Typography className="checklist">Comments</Typography> */}
            <Grid class="comment-box1">
              <Grid style={{ width: "100%", display: "flex" }}>
                <TextField
                  // multiline
                  // rows={1}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  style={{ width: "100%" }}
                  id="comment"
                  name="comment"
                  placeholder="Write a comment..."
                />
                <AttachFileIcon
                  class="attachment-icon"
                  onClick={handleAttachmentClick}
                />
                <TextField
                  type="file"
                  id="attachment"
                  name="attachment"
                  style={{ display: "none" }}
                  //onChange={handleFileChange}
                  onChange={(e) => {
                    setAttachmentFile(e.target.files[0]);
                  }}
                />
              </Grid>
              <Button id="post-button" onClick={postComment}>
                <img src={send} style={{ width: '24px', height: '24px' }} />
              </Button>
            </Grid>

          </Grid>
          }
        </Grid>
}
      </Grid>

    </>
  )
}


export default withSnackbar(Comments, "Comments", "success");