import React, { useEffect, useState, useContext } from "react";
import NoteContext from "context/PageContext";
import {
  Grid,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import withSnackbar from "../../../components/Alert/withSnackbar";
import Loader from "../Loader";
import ReasonDialog from "Compliance/Components/ReasonDialog.js";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialogbox from "Compliance/Components/Dialogbox";


const Actions = ({ showSnackbar,setLoading, caseid,parentDetails,files,setFiles,ErrMessage,setErrMessage,newcommentsData,setnewCommentsData,newreplyData,setreplyData,caseType,fetchsubCasesData,updateSubCase,edit,isparent,tasks }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const params = new URLSearchParams(window.location.search);
  const ischild=params.get("ischild")? JSON.parse(atob(params.get("ischild"))):false;
  const userrole=ContextData?.CaseRole?.name
  const {
    getOpenChildcaseCount,
    updateParentCase,
    closeParentCase,
    updateSubCaseWithAttachmentsComments,
    closeSubCase,
    SubmitForRejectTask,
    submitForReview,
    rejectCase,
    getUserList,
    getAllCaseReviewer,
    reassignForCreator,
    UpdateCase,
    closeChildCase
  } = require("../../services/CaseFetch");
  const [openReason, setOpenReason] = useState(false);
  const [childCase,setChildCase] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  
  const [isShow, setIsShow] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [functionName,setfunctionName] = useState();
  const [isReassign, setReassign] = useState(false);
  const [caseworkers, setcaseworkers] = useState([])
  const user=localStorage.getItem("AccountDetails")
  const parsedUser=user? JSON.parse(user):''
  const [caseReviewersList, setCaseReviewersList] = useState(parsedUser?.endUser);
  const [caseworker,setCaseWorker]=useState("")
  const [caseWorkerEmail,setCaseWorkerEmail]=useState("")
  const [casereviewer,setCaseReviewer]=useState("")
  const [caserevieweremail,setCaseReviewerEmail]=useState("")
  const [isSubmitReviewOpen, setSubmitOpenReview] = useState(false);
  const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {ErrMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleOpenConfirmation = async () => {
    setConfirmationOpen(false);
    setOpenReason(true);

  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };
  const closeSubCasehandle=async()=>{
    setChildCase(true)
    }
    const handleChildConfirmWithReason = async(reason) => {
      var param={
        param:{
          subCaseId:caseid,
          domain:ContextData?.UserSelected
        },
        body:{
          closeCaseReason:reason
        }
      }
      try{
        setLoading(true)
        var res=await closeChildCase(param);
        if(res.status==200){
          showSnackbar(res.data.message||"Case closed successfully","success");
          setLoading(false)
          navigate("/mytasks")
        }
      }
      catch(e)
      {
        showSnackbar(e?.resposne?.data?.message||"Error in closing a case","error")
        setLoading(false)
      }
    };
  const handleConfirmWithReason = async (reason) => {
    const param = {
      param: {
        caseId: caseid,
        domain: ContextData?.UserSelected
      },
      data: {
        closeCaseReason: reason
      }
    }
    try {
      setConfirmationOpen(false);
      setLoading(true)
      var res = await closeParentCase(param);
      if (res?.status == 200) {
        showSnackbar(res?.data?.message || "Case closed successfully", "success");
        setLoading(false)
        setTimeout(() => {
          navigate("/caseManagement")
        }, 300);
      }
    }
    catch (e) {

      showSnackbar(e?.response?.data?.message || "Error in closing a case", "error")
      setLoading(false)
    }
    setOpenReason(false);
  };
  const updateCase = async () => {
    setLoading(true);
    var param = {
      param: {
        caseId: caseid,
        domain: ContextData.UserSelected
      },
      body: {
        attachments: files,
        comment: [...newcommentsData, ...newreplyData],
        response:parentDetails?.response||""
      }
    }
    try {
      var res = await updateParentCase(param)
      fetchsubCasesData(caseid,caseType)
      setnewCommentsData([])
      setFiles([])
      setreplyData([])
      setLoading(false)
    }
    catch (e) {
      console.log(e)
      showSnackbar(e.response.data.message || "Error in update case", "error")
    }
  }
  const checkForChildcsesOpen = async () => {
    var param = {
      param: {
        caseId: caseid
      }
    }
    try {
      var res = await getOpenChildcaseCount(param)
      if(res?.response?.status==403){
        setErrMessage(res?.response?.data?.message)
        setConfirmationOpen(true);
      }
    }
    catch (e) {
      if (e.response.status == 403) {
        setErrMessage(e.response.data.message)
        setConfirmationOpen(true);
      }
    }
  }
  const handleOpenReassignConfirmation = (fname) => {
    setfunctionName(fname)
    setReassign(true);
  };
  const handleRejectCase = async (id) => {
    var param = {
      SubCaseId: id,
      domain:ContextData.UserSelected
    }
    var reqdata={
              CurrentCaseWorker: parentDetails.current_case_worker,
              CurrentCaseWorkerEmail: parentDetails.current_case_worker_email,
              ExceptionDescription:parentDetails.description,
              ExpectedResolutionBy: parentDetails.expected_closure_date,
              Reject:true
    }
    try {
      setLoading(true)
      var res = await rejectCase(param,reqdata)
      if (res.status == 200) {
        showSnackbar(res.data.message, "success");
        setLoading(false)
        navigate('/mytasks')
      }

    }
    catch (e) {
    console.log(e)
    setLoading(false)
      showSnackbar(e.response.data.message, "error");
    }
    //handleCloseConfirmation();
  };

  const handleCreatorReassign=async()=>{
    setLoading(true)
    var param={
      param:{
        SubCaseId:caseid
      },
      body:{
        CurrentCaseWorkerEmail:caseWorkerEmail
      }
    }
    try{
    var res=await reassignForCreator(param)
    if(res.status==200){
      showSnackbar(res.data.message, "success");
      setLoading(false)
      navigate("/mytasks");
    }
    }
    catch(e){
showSnackbar(e.response.data.message,"error")
setLoading(false)
    }
  }
  // const handleOpenConfirmation = () => {
  //   setSubmitOpenReview(true);
  // };
  const handleCloseReassignConfirmation=()=>{
    setReassign(false);
    setCaseWorkerEmail(caseworkers[0]?.email)
    setCaseWorker(caseworkers[0]?.name)
  }
  const handlereassignCloseConfirmation = () => {
    setSubmitOpenReview(false);
    setCaseReviewerEmail(caseReviewersList[0]?.email)
    setCaseReviewer(caseReviewersList[0]?.name)
  };

  const handleConfirmAction = async (id) => {
    var param = {
      SubCaseId: id,
      domain:ContextData.UserSelected
    }
    var reqdata={
      CaseReviewerEmailId:caserevieweremail,
      CurrentCaseWorkerEmail:parentDetails.current_case_worker_email
    }
    try {
      setLoading(true)
      var res = await submitForReview(param,reqdata)
      if (res.status == 200) {
        showSnackbar(res.data.message, "success");
        setLoading(false)
        navigate('/mytasks')
      }

    }
    catch (e) {
    console.log(e)
      showSnackbar(e.response.data.message, "error");
      setLoading(false)
    }
    //handleCloseConfirmation();
  };
  const handleRejectSupervisor = async (id) => {
    var param = {
      SubCaseId: id,
      domain:ContextData.UserSelected
    }
    var reqbody={
      CaseReviewerEmailId:caserevieweremail,
      CurrentCaseWorkerEmail:parentDetails.current_case_worker_email
    }
    try {
      setLoading(true)
      var res = await SubmitForRejectTask(param,reqbody)
      if (res.status == 200) {
        showSnackbar(res.data.message, "success");
        setLoading(false)
        navigate('/mytasks')
      }

    }
    catch (e) {
      setLoading(false)
      showSnackbar(e.response.data.message, "error");
    }
  }
  const onClickReassign = async () => {
    setLoading(true)
    try {
      const reqbody={
        queryparam:{
        SubCaseId:caseid,
        domain:ContextData.UserSelected
        },
        body:{
              CurrentCaseWorker: caseworker,
              CurrentCaseWorkerEmail: caseWorkerEmail
        }
      }
      const res = await UpdateCase(reqbody)
      if (res.status == 200) {
        //fetchData();
        navigate("/mytasks")
        setLoading(false)
      }

    }
    catch (e) {
      setLoading(false)
    }
  }
  const fetchData = async () => {
    try {
      //setLoading(true);
      // setUserInfo(auth.currentUser);
      const [caseworkerlist,casereviewerlist] = await Promise.all([

        getUserList(),
        getAllCaseReviewer(),
   
        
      ]);
      
 

      setcaseworkers(caseworkerlist?.data)
      setCaseReviewersList(casereviewerlist?.data)
      setCaseReviewerEmail(casereviewerlist?.data[0]?.email)
      setCaseReviewer(casereviewerlist?.data[0]?.name)
      setLoading(false);

   
   
      
     
      
    } catch (error) {
      console.log(error)
      showSnackbar(error?.response?.data.message || "Error while fetching case", "error");
      setLoading(false);
      // Handle errors here...
    }
  };
  const handleReviewerReassignOpenConfirmation = () => {
    setSubmitOpenReview(true);
  };

  useEffect(() => {

    fetchData()

  }, [caseid]);

  useEffect(() => {
    // Update isShow when parentDetails becomes available
    if (parentDetails?.Status) {
      setIsShow(parentDetails.Status === "open");
    }
  }, [parentDetails]);
  const handleReviewOpenConfirmation = () => {
    setSubmitOpenReview(true);
  };
  return (
    <Grid>
   {isShow && <Grid style={{ display: "flex", justifyContent: "center", margin: '15px 0px 10px 0px' }}>
                  {ContextData?.CaseRole?.name == "Case Reviewer" && isparent && <Button className="createcase" onClick={checkForChildcsesOpen}>Close</Button>}
               { (isparent || ischild) &&  (<Button className="createcase" onClick={updateCase}>Save</Button>) }
                </Grid>
                }
                {isConfirmationOpen && (
                  <ConfirmationDialog
                    open={isConfirmationOpen}
                    onClose={handleCloseConfirmation}
                    onConfirm={handleOpenConfirmation}
                  />
                )}

                {openReason && <ReasonDialog
                  open={openReason}
                  onClose={() => setOpenReason(false)}
                  onConfirm={handleConfirmWithReason}
                />
                }
                      {userrole == "Case Creator" && edit && tasks &&  (
          <Grid style={{display:'flex',justifyContent:'center'}}>
          
             <Button className="createcase" 
           onClick={() => handleOpenReassignConfirmation("creatorReassign")}
            >ReAssign</Button>
           
            
          </Grid>
        )}
        {userrole == "Case Worker" && edit && tasks &&  (
          <Grid style={{display:'flex',justifyContent:'center'}}>
          <Button className="createcase" 
           onClick={() => updateSubCase()}
            >Save</Button>
             <Button className="createcase" 
           onClick={() => handleOpenReassignConfirmation("workerReassign")}
            >ReAssign</Button>
            <Button className="createcase" 
            onClick={handleReviewOpenConfirmation} //handleReviewCase(atob(caseid), previousWorker)
            >Review</Button>
            <Button className="createcase" 
            onClick={() => handleRejectCase(caseid)}
            >Reject</Button>
            
          </Grid>
        )}
        {userrole == "Case Reviewer" &&  edit && tasks && (
          <Grid style={{display:'flex',justifyContent:'center'}}>
            <Button className="createcase" onClick={() => updateSubCase()}>Save</Button>
            <Button className="createcase" onClick={handleReviewerReassignOpenConfirmation}>ReAssign</Button>
            <Button className="createcase" onClick={() => handleRejectSupervisor(caseid)}>Reject</Button>
            <Button className="createcase" onClick={() => closeSubCasehandle()}>Close</Button>
          </Grid>
        )}
         {isSubmitReviewOpen && (
        <Dialogbox
        open={isSubmitReviewOpen}
        onClose={handlereassignCloseConfirmation}
        onConfirm={()=>{handleConfirmAction(caseid)}}
        heading="Select case reviewer"
        subhead="Case Reviewer"
        data={caseReviewersList}
        setuseremail={setCaseReviewerEmail}
        setusername={setCaseReviewer}
        useremail={caserevieweremail}
        username={casereviewer}
        />
      )}

    {isReassign && (
        <Dialogbox
        open={isReassign}
        onClose={handleCloseReassignConfirmation}
        onConfirm={()=>{functionName==="workerReassign"?onClickReassign():handleCreatorReassign()}}
        heading="Select case worker"
        subhead="Case Worker"
        data={caseworkers}
        setuseremail={setCaseWorkerEmail}
        setusername={setCaseWorker}
        useremail={caseWorkerEmail}
        username={caseworker}
        />
      )}
      {openReason && <ReasonDialog
    open={openReason}
    onClose={() => setOpenReason(false)}
    onConfirm={handleConfirmWithReason}
    />
   }
   {childCase && <ReasonDialog
    open={childCase}
    onClose={() => setChildCase(false)}
    onConfirm={handleChildConfirmWithReason}
    />
   }
    </Grid>

  );
};

export default withSnackbar(Actions, "Create Case", "success");
