import React, { useEffect, useState,useContext } from "react";
import NoteContext from "context/PageContext";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Button, Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Visibility from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import '../styles/Case_Managment/viewallcase.css'
import Header from '../CaseManagment/Header.js'
import Loader from "./Loader";
import Pagination from '@mui/material/Pagination';
const {
   getTasks
  } = require("../services/CaseFetch");

  
export default function MyTasks() {
  const navigate = useNavigate();
    const [Info, setInfo] = useState();
    const [loading, setLoading] = useState();
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [filteredRows, setfilteredRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const[totalPages,setTotalPages]= useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(5);
    const startIndex = (currentPage - 1) * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
    const ContextData = useContext(NoteContext);
    const handleStatusFilter = (status) => {
      setSelectedStatus(status);
    };
    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };
    useEffect(() => {
      
      filterdata();

    }, [selectedStatus]);

    const filterdata=()=>{
     setfilteredRows([])
      
      if (selectedStatus === "All") {
        setfilteredRows(Info)
      }
      else{
        setfilteredRows(Info?.filter((row) => row.Status===selectedStatus))
      }
    }

    const fetchData = async () => {
        try {
        setLoading(true)
        if(ContextData.UserSelected){
        const data=await getTasks(ContextData.UserSelected,ContextData?.CaseRole?.name);
        var res=data.data.values.reverse();
        //  var res=[{
        //     "CaseId": "b37a7783-9fc7-11ee-b2a4-075b3d7048ba",
        //     "CaseDate": "23-11-2023",
        //     "CurrentCaseWorker": "Shylaja",
        //     "CurrentCaseWorkerEmail": "shylaja.r@jktech.com",
        //     "Status": "open",
        //     "CaseType": "KYC",
        //     "Creator": "shylaja"
        // }]
          //setInfo(res);
          //setfilteredRows(res)
        
          setInfo(res); 
          setfilteredRows(res);
          setLoading(false);
          
        }
        } catch (error) {
          
          setLoading(false);
          //showSnackbar("Unable to fetch case", "error");
        }
      };

     
    useEffect(() => {
      
        fetchData()

      }, [currentPage,ContextData.UserSelected]);
    return (
        
        <Grid className="home Case_Home">
         
        <Grid>
          {/* <Typography className="heading">All Cases</Typography> */}
          <Header title="My Tasks" mytasks_visible='false' backarrow_visible={true} link="/caseManagement"/>
        </Grid>
        {loading ? <Loader/> :
        <Grid>
        
        <Grid>
        <Grid className="boxcard">
         
          {/* <Grid>
          <ButtonGroup
            disabled={Info?.length < 0}
            className="Button_Group"
            variant="contained"
            //color="primary"
            aria-label="outlined secondary button group"
          >
            <Button
              variant="contained"
              onClick={() => handleStatusFilter("All")}
              className={selectedStatus === "All" ? "selected" : "notselected"}
            >
              All
            </Button>
            <Button
              variant="contained"
              onClick={() => handleStatusFilter("open")}
              className={selectedStatus === "open" ? "selected" : "notselected"}
            >
              Open
            </Button>
            <Button
              variant="contained"
              onClick={() => handleStatusFilter("closed")}
              className={selectedStatus === "closed" ? "selected" : "notselected"}
            >
              Close
            </Button>

          </ButtonGroup>
          </Grid> */}
          { loading===false && filteredRows?.length>0?  
          <Grid style={{display:'flex',justifyContent:"space-between",flexDirection:"column"}}>
            <Grid style={{margin:"20px",minHeight:'60vh'}}>
           <Table  className="casestable" >
            <TableHead>
             
                <TableCell>Case ID</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Sub Case Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Current Case Worker</TableCell>
                <TableCell>Case Date</TableCell>
                <TableCell>Action</TableCell>
             
            </TableHead>
            <TableBody>
       
           { filteredRows.slice(startIndex,endIndex).map(row => (
     
                <TableRow>
                <TableCell className="caselink" 
                //onClick={() => navigate(`/viewallchildcases?case_id=${btoa(row.CaseId)}`)}
                >{row.CaseId}</TableCell>
                <TableCell>{row.Creator}</TableCell>
                <TableCell>{row.CheckType}</TableCell>
                <TableCell>{row.Status}</TableCell>
                <TableCell>{row.CurrentCaseWorker}</TableCell>
                <TableCell>{row.CaseDate}</TableCell>
                <TableCell>
        <Tooltip title="View a case">
          <IconButton
            className="Table_Button_visibility"
          //onClick={() => navigate(`/editcase?case_id=${btoa(row.CaseId)}&edit=${btoa(true)}&redirect=${btoa("tasks")}`)}
          onClick={() => navigate(`/createcase?case_id=${btoa(row.CaseId)}&edit=${btoa(true)}&redirect=${btoa("tasks")}&iscreatecase=${btoa(false)}&templateType=${btoa(row?.label)}&childcase=${btoa(true)}&show=${btoa(true)}&parent_case=${btoa(false)}&case_type=${btoa(row?.CheckType)}&tasks=${btoa(true)}&ischild=${btoa(false)}`)}
         >
            <Visibility className="eyeBtn Table_Button" />
          </IconButton>
        </Tooltip></TableCell>
            </TableRow>
            ))
           }
            </TableBody>
            
            </Table>
            </Grid>
            <Grid style={{display:'flex',justifyContent:'center',marginBottom:"15px"}}>
            <Grid container justifyContent="center">
                  <Pagination
                    count={Math.ceil(Info.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                    sx={{
                      marginTop: 1,
                      alignSelf: "center",
                      justifySelf: "center",
                    }}
                  />
                  
                </Grid>
          </Grid>
          </Grid>
            :
            <Grid>
              <Typography textAlign="center">No Tasks</Typography>
            </Grid>  
    }
          </Grid>
          
        </Grid>
</Grid>
}
        </Grid>
  
    )
} 