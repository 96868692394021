import axios from "axios";
const { REACT_APP_API_URL } = process.env;

export const ReportData=async()=>{
  
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    return await axios({
      method :"GET",
      url: `${REACT_APP_API_URL}/filings/esg/responsetoreport`,
      params: {
        cik : "0000320193",
        startDate : "01/01/2019",
        disclosure : "GRI"
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },

    })

  };

  export const GetIndexData= async()=> {
    const tokens = JSON.parse(localStorage.getItem("tokens"))
    return await axios({
      method :"GET",
      url: `${REACT_APP_API_URL}/graphdata/ESGtopics`,
      
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },

    })

  };

  export const GetIndexValues= async()=> {
    const tokens = JSON.parse(localStorage.getItem("tokens"))
    return await axios({
      method :"GET",
      url: `${REACT_APP_API_URL}/graphdata/IndexValue`,
      
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },

    })

  };

  export const AdvReportData=async()=>{
  
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    return await axios({
      method :"GET",
      url: `${REACT_APP_API_URL}/filings/esg/responsetoreport`,
      params: {
        cik : "0000320193",
        startDate : "01/01/2023",
        disclosure : "ADV"
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },

    })

  };

  export const PFReportData=async()=>{
  
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    return await axios({
      method :"GET",
      url: `${REACT_APP_API_URL}/filings/esg/responsetoreport`,
      params: {
        cik : "0000320193",
        startDate : "10/04/2023",
        disclosure : "PF"
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },

    })

  };

  export const TcfdReportData=async()=>{
  
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    return await axios({
      method :"GET",
      url: `${REACT_APP_API_URL}/filings/esg/responsetoreport`,
      params: {
        cik : "0000320193",
        startDate : "01/01/1970",
        disclosure : "TCFD"
      },
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },

    })

  };