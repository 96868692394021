import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

function ThirdPage(props) {
  const [Questions, SetQuestions] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [SingQuestion1, setSingQuestion1] = useState();
  const [SingAnswer1, setSingAnswer1] = useState();
  const [SingQuestion2, setSingQuestion2] = useState();
  const [SingAnswer2, setSingAnswer2] = useState();
  const [Question1, setQuestion1] = useState();
  const [Question2, setQuestion2] = useState();
  const [QuestionNumber, setQuestionNumber] = useState();

  //--------------Creating table header for 1st question
  const CreateHeader1 = () => {
    const column = Object.keys(Question1[0]);
    const newcolumn = column.map((x) => {
      if (x !== "id" && x !== "isNew") {
        return <TableCell key={x}>{x}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  //-------get table row data
  const tdData1 = () => {
    return Question1.map((data, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question1[0]).map((x) => {
              if (x !== "id" && x !== "isNew") {
                return (
                  <TableCell key={x} component="th" scope="row">
                    {data[x]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };

  //----------Create table header for 2nd question----------------

  const CreateHeader2 = () => {
    const column = Object.keys(Question2[0]);
    const newcolumn = column.map((y) => {
      if (y !== "id" && y !== "isNew") {
        return <TableCell key={y}>{y}</TableCell>;
      }
      return null;
    });

    return newcolumn;
  };

  // //------get table row data

  const tdData2 = () => {
    return Question2.map((data1, index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data1.id}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(Question2[0]).map((y) => {
              if (y !== "id" && y !== "isNew") {
                return (
                  <TableCell key={y} component="th" scope="row">
                    {data1[y]}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
    const FetchData = () => {
      const SignQuestionData1 = [];
      const SignAnswerData1 = [];

      for (
        let i = 0;
        i <
        Object.keys(
          Object.values(props.data.reportData["Section 1a-Item A"])[2].answer
        ).length;
        i++
      ) {
        SignQuestionData1.push(
          Object.keys(
            Object.values(props.data.reportData["Section 1a-Item A"])[2].answer
          )[i]
        );
        SignAnswerData1.push(
          Object.values(
            Object.values(props.data.reportData["Section 1a-Item A"])[2].answer
          )[i]
        );
      }
      //console.log(SignAnswerData1);
      setSingQuestion1(SignQuestionData1);
      setSingAnswer1(SignAnswerData1);


      const SignQuestionData2 = [];
      const SignAnswerData2 = [];

      for (
        let i = 0;
        i <
        Object.keys(
          Object.values(props.data.reportData["Section 1a-Item A"])[3].answer
        ).length;
        i++
      ) {
        SignQuestionData2.push(
          Object.keys(
            Object.values(props.data.reportData["Section 1a-Item A"])[3].answer
          )[i]
        );
        SignAnswerData2.push(
          Object.values(
            Object.values(props.data.reportData["Section 1a-Item A"])[3].answer
          )[i]
        );
      }
      //console.log(SignAnswerData2);
      setSingQuestion2(SignQuestionData2);
      setSingAnswer2(SignAnswerData2);

      //fetching table data for first question
      setQuestion1(
        Object.values(props.data.reportData["Section 1a-Item A"])[0].answer
      );

      //fetching table data for second question
      setQuestion2(
        Object.values(props.data.reportData["Section 1a-Item A"])[1].answer
      );

      //console.log(((Object.values(props.data.reportData["Section 1a-Item A"])[2]).answer)[1]);

      //Putting all question in single array
      const questions_array = [];
      const QuestionNumber = [];
      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Section 1a-Item A"]).length;
        i++
      ) {
        questions_array.push(
          Object.keys(props.data.reportData["Section 1a-Item A"])[i]
        );
        QuestionNumber.push(
          Object.values(props.data.reportData["Section 1a-Item A"])[
            i
          ].qno.substr(
            5,
            Object.values(props.data.reportData["Section 1a-Item A"])[i].qno
              .length
          )
        );
      }
      console.log(questions_array);
      SetQuestions(questions_array);
      setQuestionNumber(QuestionNumber);
    };

    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <React.Fragment>
      {Isloading && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
                <li style={{ color: "Black", fontSize: "20px" }}>
                  <b>Section 1a</b>
                </li>
                <li>Section 1b</li>
                <li>Section 1c</li>
                <li>Section 2a</li>
                <li>Section 2b</li>
                <li>Section 3</li>
                <li>Section 4</li>
                <li>Section 5</li>
              </ul>
            </Grid>

            <Grid item xs={12} md={10}>
              <div>
                {Questions.map((x, index) => {
                  if (index === 0) {
                    return (
                      <React.Fragment key={index}>
                        {/* --------------------1st question & table -----------------------------*/}
                    
                          
                            <b>{QuestionNumber[index]}</b>
                            {"\u00A0"}
                            {"\u00A0"}
                            {x}{" "}
                          
                          <br />
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <TableContainer>
                              <Table
                                className="Table_PF"
                                // sx={{ minWidth: 650, width: "50%" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 1,
                                        fontFamily: 'Microsoft YaHei',
                                        fontSize: "15px",
                                      },
                                    }}
                                  >
                                    {CreateHeader1()}
                                  </TableRow>
                                </TableHead>

                                {tdData1()}
                              </Table>
                            </TableContainer>
                          </div>

                      </React.Fragment>
                    );
                  } else if (index === 1) {
                    return (
                      <React.Fragment key={index}>
                        {/* --------------------2nd question & table -----------------------------*/}
                          <div  style={{ width: "170vh" }}>
                            
                            <b>{QuestionNumber[index]}</b>
                            {"\u00A0"}
                            {"\u00A0"}
                            {x}
                          </div>
                          <br />
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <TableContainer>
                              <Table
                                sx={{ minWidth: 650, width: "50%" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 1,
                                      },
                                    }}
                                  >
                                    {CreateHeader2()}
                                  </TableRow>
                                </TableHead>

                                {tdData2()}
                              </Table>
                            </TableContainer>
                          </div>
                      </React.Fragment>
                    );
                  } else if (index === 2){
                  
                      /* -------------------3rd question & form -----------------------*/
              
                    return (
                      <React.Fragment key={index}>   
                            <b>{QuestionNumber[index]}</b>
                            {"\u00A0"}
                            {"\u00A0"}
                            {x}{" "}
                          <br />

                          <Grid container rowspacing={3} columnspacing={3}>
                            {SingQuestion1.map((x, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Grid item xs={12} md={3}>
                                    <Typography>{x}</Typography>
                                  </Grid>
                                  <Grid item xs={12} md={9}>
                                    <Typography
                                     
                                      className="underline"
                                    >
                                      {SingAnswer1[index]}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              );
                            })}
                          </Grid>
                        </React.Fragment>

                    );
                  }
                  else {
                  
                    /* -------------------3rd question & form -----------------------*/
            
                  return (
                    <React.Fragment key={index}>   
                          <b>{QuestionNumber[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}{" "}
                        <br />

                        <Grid container rowspacing={3} columnspacing={3}>
                          {SingQuestion2.map((x, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Grid item xs={12} md={3}>
                                  <Typography>{x}</Typography>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                  <Typography
                                   
                                    className="underline"
                                  >
                                    {SingAnswer2[index]}
                                  </Typography>
                                </Grid>
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </React.Fragment>

                  );
                }
                })}
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default ThirdPage;
