import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Button,
  TextField,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import "../styles/Case_Managment/Case_Row.css";
import cross from './../../images/cross.png';
import { useContext } from "react";
import NoteContext from "context/PageContext";
import { getCaseTypesTemp } from "Compliance/services/CaseFetch";
import { useNavigate } from "react-router-dom";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import Loader from "./Loader";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
import { loggedUser,USERNAME } from "Insurance/CaseManagement/Config"; 


const CardRow = ({ onCardClick, onClose,onselectedtypeTemp,showSnackbar }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {templateType} = useTemplateType();
  const [caseTempData, setCaseTempData] = useState([]);
  const [selectCaseTypes, setSelectedCaseTypes] = useState(null);
  const [showDetailsScreen, setShowDetailsScreen] = useState(false);
  const [dataToMap,setdataToMap]=useState([]);
  const [loading,setLoading]=useState([]);
  const [list,setList]=useState(loggedUser==USERNAME?["Insurance"]:["ESG","Insurance"])
  const [selectedValue,setselectedValue]=useState(templateType)
  useEffect(() => {
   
    const fetchCaseCategory = async () => {
      try {
        showSnackbar("please wait. Geting  all case category types", "info");
        setLoading(true)
        const caseData = await getCaseTypesTemp(selectedValue);
        showSnackbar("Get all case category", "success");
       
        setCaseTempData(caseData.data);
        setdataToMap(caseData.data);
        setLoading(false)
       // rendercards()
      } catch (error) {
      
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        showSnackbar("Unable to get cases category", "error");
      }
    };
    fetchCaseCategory()
  }, [selectedValue]);
  useEffect(() => {
    rendercards()
  }, [dataToMap]);


 
  const handleSearchChange=(searchtext)=>{
   
    setdataToMap(caseTempData.filter((item) =>
     item.title.toLowerCase().includes(searchtext.toLowerCase())
   ));
 
  }
  const rendercards=()=>{
    return(
      
      <div style={{ display: 'flex', flexWrap: 'wrap'}}>
      { dataToMap && dataToMap?.map((card, index) => (
        <Card
         //style={{ width: '33.33%', padding: '10px' }}
          key={index}
         className="card"
          onClick={() => navigate(`/createcase?case_type=${btoa(card.title)}&case_code=${btoa(card.code)}&iscreatecase=${btoa(true)}&edit=${btoa(false)}&templateType=${btoa(card.code)}&show=${btoa(true)}`)}
        >
          <CardContent className="casetypecard">
            <Typography className="card-title">
              {card.title}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className="card-description"
            >
              {card.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
      </div>
    )
  }
const handleChange=(e)=>{
  console.log("eee",e)
  setselectedValue(e.target.value)
}
  return (
    <>
    {loading==true? (
      <Loader/>
    ):(
    <div className="card-row">
      <Grid container alignItems="center">
        <Grid className="headingrow">
          <Grid>
          <Typography className="CaseDetails_Title" variant="h6">
           New Cases
          </Typography>
          </Grid>
          <Grid>
          <Button id="post-button" onClick={onClose}><img src={cross}/></Button>
          </Grid>
        </Grid>
        
      <Grid style={{display:'flex',justifyContent:'space-between', width: '100%',marginLeft:'10px'}}>
        <Grid className="common">
        <InputLabel className="commonLabel">Select Category</InputLabel>
      <Select
        value={selectedValue}
        onChange={handleChange}
        size="small"
      >
        {list && list
        .map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))
         }
      </Select>
   
    </Grid>
        <Grid className="searchbox">
        <TextField
        size="small"
        placeholder="Search"
      //variant="outlined"
      //label="Search"
      onChange={(e)=>handleSearchChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
        </Grid>
        </Grid>
        <Grid item xs={6} md={6} textAlign="end">
         {showDetailsScreen && <IconButton  title="Back" onClick={() => {
              setShowDetailsScreen(false);
              setSelectedCaseTypes(null)
            }}>
           
          </IconButton>}
        </Grid>
      </Grid>
      
      {rendercards()}
    </div>
    )}
    </>
  );
};

// export default CardRow;
export default withSnackbar(CardRow, "Case Row Home", "success");