import React, { useEffect, useState, useRef } from "react";
import "../styles/Case_Managment/View_Case.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";
import { Grid, Table, TableRow, TableBody, TableCell, TableHead, Typography, Button, Step, Stepper, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Header from '../CaseManagment/Header.js'
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import { StepLabel, StepConnector } from "@mui/material";
import Chip from "@mui/material/Chip";
import TagIcon from "@mui/icons-material/Tag";
import withSnackbar from "../../components/Alert/withSnackbar";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import deleteIcon from 'images/DeleteIcon.png'
import FileIcon from 'images/FileIcon.png'
import UploadIcon from 'images/UploadIcon.png'
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import send from "./../../images/24px_Send.png";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CaseAudit from "./CaseAudit";
import Dialogbox from "Compliance/Components/Dialogbox";
import ReasonDialog from "Compliance/Components/ReasonDialog.js";
import SectionButtons from "Compliance/Components/SectionButtons";
import AI from "Compliance/Components/AI";
import Comments from "Compliance/Components/Comments";
import Evidences from "Compliance/Components/Evidences"
import ParentCaseInfo from "Compliance/Components/ParentCaseInfo";
import Drawer from "Compliance/Components/Drawer";
import HashTag from "Compliance/Components/HashTag";
import Basicinfo from "Compliance/Components/BasicInfo";
import IdentificationList from "Compliance/Components/IdentificationList";
import CheckList from "Compliance/Components/CheckList";
import InsuranceBasicInfo from "Insurance/CaseManagement/InsuranceBasicInfo";
import InsuranceParentCaseInfo from "Insurance/CaseManagement/InsuranceParentCaseInfo"
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
import {INSURANCE,sectionList,insurancesectionList,KYC} from "Insurance/CaseManagement/Config.js"
import Responsebox from "Insurance/CaseManagement/Responsebox";
const CompletedStepIcon = styled(CircleIcon)(({ theme }) => ({
  color: "#2076B0",
  fontSize: '14px',
  marginLeft: '8px',
  //marginTop:'3px'
}));

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    minHeight: "120px",
    borderLeft: "1px solid #98C0DB",
    marginLeft: '1px'
  },
  "& .MuiStepConnector-icon": {
    //  marginLeft: "-1px" // Adjust this value as needed to control the spacing
  }
}));

const {
  getChildCaseById,
  getUserList,
  UpdateCase,
  getCaseAttachments,
  downloadFile,
  submitForReview,
  rejectCase,
  SubmitForRejectTask,
  getAllCaseReviewer,
  updateSubCaseWithAttachmentsComments,
  closeChildCase,
  getCountryList,
  getStateList,
  reassignForCreator
} = require("../services/CaseFetch");




const EditCase = ({ showSnackbar, auth }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {templateType} = useTemplateType();
  const params = new URLSearchParams(window.location.search);
  const caseid = params.get('case_id')?atob(params.get('case_id')):'';
  const editparam=atob(params?.get('edit'));
  const redirect=atob(params?.get('redirect'))
  const edit=editparam=='true'?true:false;
  const [userInfo, setUserInfo] = useState({ displayName: '', email: '' })
  const [Info, setInfo] = useState({ parent_case: '', subcase: '' });
  const [loading, setLoading] = useState();
  const UserInfo = JSON.parse(sessionStorage.getItem("Name"));
  const currentDate = new Date();
  const formattedDate = format(currentDate, "dd-MM-yyyy");

  const [caseworkers, setcaseworkers] = useState([])
  const [previousWorker, setPreviousWorker] = useState('');
  const [expectedClosureDate,setexpectedClosureDate]=useState("")
  const [description,setdescription]=useState("")
  const [casereviewer,setCaseReviewer]=useState("")
  const [caserevieweremail,setCaseReviewerEmail]=useState("")
  const [caseWorkerEmail,setCaseWorkerEmail]=useState("")
  const [caseworker,setCaseWorker]=useState("")
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [openReason,setOpenReason] = useState(false);
  const [countryData,setCountryData]= useState([]);
  const [stateList,setStateList]= useState([]);
  const [Type,setType]=useState()
  const [data, setdata] = useState({
    parent_case_id:"",
    type:"",
    Caseid: "",
    customer_id: "",
    //Creator: UserInfo.name,
    EditedBy: "",
    CaseCategory: "",
    Status: "",
    case_priority: "",
    hash_tag: [],
    //CurrentCaseWorking: "",
    CaseDate: formattedDate,
    description: "",
    CaseAttachment: "",
    accountApprovalDate: "",
    accountOpeningDate: "",
    first_name: "",
    last_name: "",
    country: "",
    state: "",
    case_type: "",
    expected_closure_date: "",
    identificationList:"",
    //creator_email: auth.currentUser.email,
    // creator_id: auth.currentUser.email,
    // creator:auth.currentUser.displayName,
    checksdata: [],
    comment: '',
    current_case_worker: "",
    current_case_worker_email: "",
    current_case_supervisor: "",
    current_case_supervisor_email: "",
    // files: '',
  });
  const [insurancedata, setinsurancedata] = useState({
    subcategory:"",
    caseTitle: "",
    dateOpened: "",
    dateClosed: "",
    regulatoryAuthority: "",
    Status: "",
    assignedTo: "",
    CaseDate: formattedDate,
    description: "",
    CaseAttachment: "",
    case_type: "",
    creator_email: auth?.mail,
    creator_id: auth?.mail,
    creator: auth?.displayName,
    checksdata: [],
    comment: "",
    current_case_worker: "",
    current_case_worker_email: "",
    childcases: [],
    department:"",
    response:"",
  
  });
  const [isSubmitReviewOpen, setSubmitOpenReview] = useState(false);
  const [isReassign, setReassign] = useState(false);
  const [userrole, setuserrole] = useState(null);
  const [newcommentsData, setnewCommentsData] = useState([]);
  const [newreplyData, setreplyData] = useState([]);
  const user=localStorage.getItem("AccountDetails")
  const parsedUser=user? JSON.parse(user):''
  const [caseReviewersList, setCaseReviewersList] = useState(parsedUser?.endUser);
  const [sectionType, setSectionType] = useState("BasicInfo");
  const [defaultCountry,setDefaultCountry] = useState();
  const [defaultState,setDefaultState] = useState();
  const [functionName,setfunctionName] = useState();

  useEffect(() => {

    getUserRole();


  }, [ContextData?.CaseRole]);
  const getUserRole = async () => {
    //const role=await getRoles();
    const role = ContextData.CaseRole
    setuserrole(role)
  }





  

  const handleOpenConfirmation = () => {
    setSubmitOpenReview(true);
  };
  const handleCloseReassignConfirmation=()=>{
    setReassign(false);
    setCaseWorkerEmail(caseworkers[0]?.email)
    setCaseWorker(caseworkers[0]?.name)
  }
  const handleCloseConfirmation = () => {
    setSubmitOpenReview(false);
    setCaseReviewerEmail(caseReviewersList[0]?.email)
    setCaseReviewer(caseReviewersList[0]?.name)
  };

  const handleConfirmAction = async (id) => {
    var param = {
      SubCaseId: id,
      domain:ContextData.UserSelected
    }
    var reqdata={
      CaseReviewerEmailId:caserevieweremail,
      CurrentCaseWorkerEmail:data.current_case_worker_email
    }
    try {
      var res = await submitForReview(param,reqdata)
      if (res.status == 200) {
        showSnackbar(res.data.message, "success");
        navigate('/mytasks')
      }

    }
    catch (e) {
    console.log(e)
      showSnackbar(e.response.data.message, "error");
    }
    //handleCloseConfirmation();
  };

  const handleOpenReassignConfirmation = (fname) => {
    setfunctionName(fname)
    setReassign(true);
  };
  const handleRejectCase = async (id) => {
    var param = {
      SubCaseId: id,
      domain:ContextData.UserSelected
    }
    var reqdata={
              CurrentCaseWorker: data.current_case_worker,
              CurrentCaseWorkerEmail: data.current_case_worker_email,
              ExceptionDescription:data.description,
              ExpectedResolutionBy: data.expected_closure_date,
              Reject:true
    }
    try {
      var res = await rejectCase(param,reqdata)
      if (res.status == 200) {
        showSnackbar(res.data.message, "success");
        navigate('/mytasks')
      }

    }
    catch (e) {
    console.log(e)
      showSnackbar(e.response.data.message, "error");
    }
    //handleCloseConfirmation();
  };

  const handleCreatorReassign=async()=>{
    var param={
      param:{
        SubCaseId:caseid
      },
      body:{
        CurrentCaseWorkerEmail:caseWorkerEmail
      }
    }
    try{
    var res=await reassignForCreator(param)
    if(res.status==200){
      showSnackbar(res.data.message, "success");
      navigate("/mytasks");
    }
    }
    catch(e){
showSnackbar(e.response.data.message,"error")
    }
  }
 
  const fetchData = async () => {
    try {
      setLoading(true);
      // setUserInfo(auth.currentUser);
      const [casedata, caseworkerlist,casereviewerlist,country] = await Promise.all([
        getChildCaseById(caseid),
        getUserList(),
        getAllCaseReviewer(),
        getCountryList(),
        // getUserList('CaseSupervisor') , commentsRes, cdata, logdata,userslist,supervisorlist
      ]);
 
    var cres = country.data.values;
    var scres = cres.map((country) => ({
      value: country.CountryCode,
      label: country.CountryName,
    }));

       setCountryData(scres);

      setcaseworkers(caseworkerlist?.data)
      setCaseReviewersList(casereviewerlist?.data)
      setCaseReviewerEmail(casereviewerlist?.data[0]?.email)
      setCaseReviewer(casereviewerlist?.data[0]?.name)
      
      var res = casedata.data;
      var countrycode;
for (var i = 0; i < cres.length; i++) {
  if (cres[i].CountryName === res?.parentCase[0]?.Country) {
    setDefaultCountry({label:cres[i].CountryName,value:cres[i].CountryCode});
    countrycode=cres[i].CountryCode;
    break;
  }
}
      var sdata = await getStateList(countrycode);
      var stateData=sdata?.data?.values
      var sortedStatedata = stateData?.map((state) => ({
        value: state,
        label: state,
      }));
      for (var i = 0; i < stateData.length; i++) {
        if (stateData[i] === res?.parentCase[0]?.State) {
          setDefaultState({label:stateData[i],value:stateData[i]});
          break;
        }
      }
      setStateList(sortedStatedata)
      setType(res?.subCase[0]?.label.includes(INSURANCE)?INSURANCE:KYC)
      //setInfo({ parent_case: res?.parentCase[0], subcase: res?.subCase[0] });
      if(res?.subCase[0]?.label.includes(INSURANCE)){
         setinsurancedata({
          parent_case_id:res?.parentCase[0]?.ParentCaseId,
          current_case_worker: res?.subCase[0]?.CurrentCaseWorker || '',
        current_case_worker_email: res?.subCase[0]?.CurrentCaseWorkerEmail || '',
        current_case_supervisor: 'Deepak@jktech.com,Deepak', //res.CurrentCaseSupervisor||
        current_case_supervisor_email: 'Deepak@jktech.com', //res.CurrentCaseSupervisorEmail||
        description: res?.subCase[0]?.ExceptionDescription,
        type:res?.subCase[0]?.Type,
        caseTitle:res?.subCase[0]?.caseTitle,
        dateOpened:res?.parentCase[0]?.dateOpened,
        regulatoryAuthority:res?.parentCase[0]?.regulatoryAuthority,
        department:res?.subCase[0]?.department,
        dateClosed:res?.parentCase[0]?.dateClosed,
        response:res?.subCase[0]?.response,
        subcategory:res?.subCase[0]?.subcategory,
        case_type:res?.parentCase[0]?.CaseType
         })
      }else{
      setdata({
        ...data,
        parent_case_id:res?.parentCase[0]?.ParentCaseId,
        first_name: res?.parentCase[0]?.FirstName,
        last_name: res?.parentCase[0]?.LastName,
        customer_id: res?.parentCase[0]?.CustomerId,
        hash_tag: JSON.parse(res?.parentCase[0]?.HashTags),
        country: res?.parentCase[0]?.Country,
        state: res?.parentCase[0]?.State,
        identificationList: JSON.parse(res?.parentCase[0]?.Identifications),
        current_case_worker: res?.subCase[0]?.CurrentCaseWorker || '',
        current_case_worker_email: res?.subCase[0]?.CurrentCaseWorkerEmail || '',
        current_case_supervisor: 'Deepak@jktech.com,Deepak', //res.CurrentCaseSupervisor||
        current_case_supervisor_email: 'Deepak@jktech.com', //res.CurrentCaseSupervisorEmail||
        expected_closure_date: res?.subCase[0]?.ExpectedResolutionBy,
        case_priority: res?.parentCase[0]?.Priority,
        accountApprovalDate: res?.parentCase[0]?.AccountApprovalDate,
        accountOpeningDate:res?.parentCase[0]?.AccountOpeningDate,
        hash_tag: res?.parentCase[0].HashTags && typeof res?.parentCase[0]?.HashTags === "string"
          ? (isValidJson(res?.parentCase[0]?.HashTags) ? JSON.parse(res?.parentCase[0]?.HashTags) : JSON.parse(res?.parentCase[0]?.HashTags.replace(/'/g, '"')))
          : res?.parentCase[0]?.HashTags,
        description: res?.subCase[0]?.ExceptionDescription,
        type:res?.subCase[0]?.Type,
        //checksdata:res.parent_case.checks
      });
    }
     // setCaseWorkerEmail(res?.subCase[0]?.CurrentCaseWorkerEmail)
     // setCaseWorker(res?.subCase[0]?.CurrentCaseWorker)
      setPreviousWorker(res?.subCase[0]?.CurrentCaseWorkerEmail)
      setdescription(res?.subCase[0]?.ExceptionDescription)
      setexpectedClosureDate(res?.subCase[0]?.ExpectedResolutionBy)
      
      setLoading(false);
      setTimeout(() => {
        if (res) {
          //showSnackbar("Case details fetched successfully", "success");
        }
      }, 20000);
    } catch (error) {
      console.log(error)
      showSnackbar(error?.response?.data.message || "Error while fetching case", "error");
      setLoading(true);
      // Handle errors here...
    }
  };


  useEffect(() => {

    fetchData()

  }, []);

 
 
  const isValidJson = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }


  const onClickReassign = async () => {
    setLoading(true)
    try {
      const reqbody={
        queryparam:{
        SubCaseId:caseid,
        domain:ContextData.UserSelected
        },
        body:{
              CurrentCaseWorker: caseworker,
              CurrentCaseWorkerEmail: caseWorkerEmail
        }
      }
      const res = await UpdateCase(reqbody)
      if (res.status == 200) {
        //fetchData();
        navigate("/mytasks")
        setLoading(false)
      }

    }
    catch (e) {
      setLoading(false)
    }
  }

  const updateSubCase=async()=>{
    var desc,date,res='';
    if(Type===INSURANCE){
     desc=insurancedata.description;
     date=insurancedata.dateClosed;
     res=insurancedata.response
    }
    else{
      desc=data.description;
      date=data.expected_closure_date
    }
    var param={
      param:{
        SubCaseId:caseid, 
        domain:ContextData.UserSelected
      },
      body:{
        
        ExceptionDescription: desc,
        ExpectedResolutionBy:date,
        sub_attachments:files,
        comment:[...newcommentsData,...newreplyData],
        response:res
      }
    }
    try{
      setLoading(true)
   var res=await updateSubCaseWithAttachmentsComments(param)
   if (res.status == 200) {
   setFiles([])
   setnewCommentsData([])
   setreplyData([])
    fetchData();
    setLoading(false)
  }
}catch(e){
showSnackbar(e.response.data.message||"error in updating case","error")
}
  }
  
  const handleRejectSupervisor = async (id) => {
    var param = {
      SubCaseId: id,
      domain:ContextData.UserSelected
    }
    var reqbody={
      CaseReviewerEmailId:caserevieweremail,
      CurrentCaseWorkerEmail:data.current_case_worker_email
    }
    try {
      var res = await SubmitForRejectTask(param,reqbody)
      if (res.status == 200) {
        showSnackbar(res.data.message, "success");
        navigate('/mytasks')
      }

    }
    catch (e) {
      showSnackbar(e.response.data.message, "error");
    }
  }

 const closeSubCase=async()=>{
 setOpenReason(true)
 }
 const handleConfirmWithReason = async(reason) => {
  var param={
    param:{
      subCaseId:caseid,
      domain:ContextData?.UserSelected
    },
    body:{
      closeCaseReason:reason
    }
  }
  try{
    var res=await closeChildCase(param);
    if(res.status==200){
      showSnackbar(res.data.message||"Case closed successfully","success");
      navigate("/mytasks")
    }
  }
  catch(e)
  {
    showSnackbar(e?.resposne?.data?.message||"Error in closing a case","error")
  }
};



  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column',backgroundColor:'#F5F5F6' }} className="App">

    <Grid style={{ flex: '0 0 auto', position: 'sticky', top: 0, zIndex: 999,marginLeft:'15px' }}>
    <Header  title={edit==true?"Edit Case":"View Case"} backarrow_visible={true} link={redirect=="dashboard"?"/caseManagement":"/mytasks"}/>
    </Grid>
  
    <Grid style={{marginBottom:'10px',flex: 1, overflow: 'hidden'}}>
      <Grid style={{height: 'calc(100vh - 180px)', overflowY: 'auto',}}>
        {loading ? (
          <Loader />
        ) : (
          <Grid display="flex">
            <Grid px={2}  className="boxcard" style={{width: '70%'}}>
            <SectionButtons
                   list={Type === INSURANCE ? insurancesectionList: sectionList}
                   sectionType={sectionType}
                   setSectionType={setSectionType}
                   />
                   <Grid>
                      <Typography className="mainhead" style={{marginTop:'10px',marginBottom:'10px'}}></Typography>
                     
                    </Grid>
                    <Grid className="editidbox">
                          <Grid>
                            <Typography className="editchildcaseid">Child Case ID:{caseid}</Typography>
                            </Grid>
                            <Grid>
                        <Typography className="subcaselabel">Parent Case ID</Typography>
                        {Type === INSURANCE ?
                        <Typography className="editparentid" onClick={() => navigate(`/viewallchildcases?case_id=${btoa(insurancedata?.parent_case_id)}`)}>{insurancedata?.parent_case_id}</Typography>
                        :<Typography className="editparentid" onClick={() => navigate(`/viewallchildcases?case_id=${btoa(data?.parent_case_id)}`)}>{data?.parent_case_id}</Typography>
                        }
                        </Grid>
                          
                        </Grid>
                        <Grid marginBottom="10px">
                        {Type === INSURANCE ? <Typography className="checklist">{insurancedata?.type}</Typography>:  
                        <Typography className="checklist">{data?.type}</Typography>
                        }
                        </Grid>
                        {sectionType === "BasicInfo" &&
                          <>
                         {Type === INSURANCE ?
                          <Grid item>
                          <InsuranceBasicInfo setinsurancedata={setinsurancedata}  insurancedata={insurancedata} edit={edit}   casetype={insurancedata.case_type} caseworkers={caseworkers} subworker={data.current_case_worker} subworkeremail={data.current_case_worker_email} iscreatecase={false}/>
                        </Grid>
                          :<>
                              <Grid item>
                                <Basicinfo setdata={setdata}  data={data} edit={edit} countryData={countryData} defaultCountry={defaultCountry} stateData={stateList} defaultState={defaultState} caseworkers={caseworkers} subworker={data.current_case_worker} subworkeremail={data.current_case_worker_email} iscreatecase={false}/>
                              </Grid>

                            <Grid item>
                              <HashTag setdata={setdata} data={data} display={false} />
                            </Grid>
                            </>
}
                          </>
                        }
                        {sectionType === "Identification" &&
                          <>
                            <Grid item>
                              <ParentCaseInfo data={data} />
                              <IdentificationList rows={data?.identificationList}  display={false} />
                            </Grid>    
                          </>
                        }
                        {sectionType === "Evidences" &&
                          <>
                            <Grid item>
                            {Type === INSURANCE ?
                          <Grid item>
                          <InsuranceParentCaseInfo  data={insurancedata} edit={edit}  />
                        </Grid>:
                              <ParentCaseInfo data={data} />
                            }
                              <Evidences caseid={caseid} base64FileList={files} setbase64FileList={setFiles} setNewRowData={false} showHeading={true}  show={edit}/>
                            </Grid>
                            {/* <Grid container className="btnContainer">
                              <Button className="createcasebtn"
                              //onClick={handleAddClick}
                              >
                                Save
                              </Button>

                            </Grid> */}
                            
                          </>
                        }
                        {sectionType=="ResponseBox" &&
                        <>
                        <Grid item>
                          <InsuranceParentCaseInfo  data={insurancedata} edit={edit}  />
                        </Grid>
                        <Grid>
                          <Responsebox insurancedata={insurancedata} setinsurancedata={setinsurancedata} edit={edit} iscreatecase={false}/>
                        </Grid>
                        </>
                        
                        }
               { !loading &&
      <Grid style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
      {userrole?.name == "Case Creator" && edit &&  (
          <>
          
             <Button className="createcase" 
           onClick={() => handleOpenReassignConfirmation("creatorReassign")}
            >ReAssign</Button>
           
            
          </>
        )}
        {userrole?.name == "Case Worker" && edit &&  (
          <>
          <Button className="createcase" 
           onClick={() => updateSubCase()}
            >Save</Button>
             <Button className="createcase" 
           onClick={() => handleOpenReassignConfirmation("workerReassign")}
            >ReAssign</Button>
            <Button className="createcase" 
            onClick={handleOpenConfirmation} //handleReviewCase(atob(caseid), previousWorker)
            >Review</Button>
            <Button className="createcase" 
            onClick={() => handleRejectCase(caseid)}
            >Reject</Button>
            
          </>
        )}
        {userrole?.name == "Case Reviewer" &&  edit && (
          <>
            <Button className="createcase" onClick={() => updateSubCase()}>Save</Button>
            <Button className="createcase" onClick={handleOpenConfirmation}>ReAssign</Button>
            <Button className="createcase" onClick={() => handleRejectSupervisor(caseid)}>Reject</Button>
            <Button className="createcase" onClick={() => closeSubCase()}>Close</Button>
          </>
        )}
         {isSubmitReviewOpen && (
        <Dialogbox
        open={isSubmitReviewOpen}
        onClose={handleCloseConfirmation}
        onConfirm={()=>{handleConfirmAction(caseid)}}
        heading="Select case reviewer"
        subhead="Case Reviewer"
        data={caseReviewersList}
        setuseremail={setCaseReviewerEmail}
        setusername={setCaseReviewer}
        useremail={caserevieweremail}
        username={casereviewer}
        />
      )}

    {isReassign && (
        <Dialogbox
        open={isReassign}
        onClose={handleCloseReassignConfirmation}
        onConfirm={()=>{functionName==="workerReassign"?onClickReassign():handleCreatorReassign()}}
        heading="Select case worker"
        subhead="Case Worker"
        data={caseworkers}
        setuseremail={setCaseWorkerEmail}
        setusername={setCaseWorker}
        useremail={caseWorkerEmail}
        username={caseworker}
        />
      )}
      {openReason && <ReasonDialog
    open={openReason}
    onClose={() => setOpenReason(false)}
    onConfirm={handleConfirmWithReason}
    />
   }
      </Grid>
}
    
            </Grid>
            <Grid  style={{ width: '30%', marginTop: '10px', marginRight: '10px',}}>
                  <Grid >
                    <CaseAudit caseid={caseid ? caseid : ''} />
                  </Grid>
                  <Grid style={{ marginTop: "10px" }}>
                    <AI />
                  </Grid>
                  
                </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  
    <Grid style={{ flex: '0 0 auto', position: 'fixed', bottom: 0, width: '67%', zIndex: 999}}>
    <Drawer  comment={<Comments  setnewCommentsData={setnewCommentsData} newcommentsData={newcommentsData} is_new={false} show={edit} caseid={caseid} newreplyData={newreplyData} setreplyData={setreplyData}/>} />
    </Grid>
  </Grid>
  )
}

export default withSnackbar(EditCase, "Edit Case", "success");

