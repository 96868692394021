import React, { useEffect, useState, useContext } from "react";
import {
  getQuestionDetails,
  GENAI,
  updateAdditionalNote,
} from "../services/Fetch";
import {
  Grid,
  IconButton,
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CachedIcon from "@mui/icons-material/Cached";
import { randomId } from "@mui/x-data-grid-generator";
import axios from "axios";
import NoteContext from "context/PageContext";
import "../styles/AI/AI.css";


function AIbox(props) {
  const constextdata = useContext(NoteContext);
  const [value, setValue] = useState(null);
  const [AIAnswer, setAIAnswer] = useState();
  const [BardAnswer, setBardAnswer] = useState();
  const [ChartGPTAnswer, setChartGPTAnswer] = useState();
  const [Enable, setEnable] = useState(true);
  const [ConfidenceScore, setConfidenceScore] = useState();
  const [showAISuggestion, setShowAISuggestion] = useState(true); // Default display of AI Suggestion
  const [showSource, setShowSource] = useState(false); // Default hide Source
  const [selectedSources, setSelectedSources] = useState([]);
  const source = axios.CancelToken.source();

  const [sourceData, setSorceDate] = useState();
  const [FromAI, setFromAI] = useState();

  const ErrorFunction = () => {
    setAIAnswer(
      "We apologize, but we couldn't generate a response for your query at the moment. It's possible that the requested data is not available or not provided. Please try again later or refine your question to provide more specific information. Thank you for your understanding."
    );
  };

  const handleAiAnswer = async (data) => {
    try {
      setConfidenceScore(data.ConfidenceScore);
      if(data.ConfidenceScore > 0)
      {
        setBardAnswer(data.res);
        setAIAnswer(JSON.stringify(data.res, null, 2));
        setEnable(false);
      }
      else
      {
        ErrorFunction();
      }
      
    } catch (error) {
      console.error(error);
      setBardAnswer(
        "Sorry i can't find the answer for ur question We apologize, but we couldn't generate a response for your query at the moment. It's possible that the requested data is not available or not provided. Please try again later or refine your question to provide more specific information. Thank you for your understanding"
      );
      setAIAnswer(
        "Sorry, i can't find the answer for ur question We apologize, but we couldn't generate a response for your query at the moment. It's possible that the requested data is not available or not provided. Please try again later or refine your question to provide more specific information. Thank you for your understanding"
      );
    }
  };

  const FtechAIData = async () => {
    setShowAISuggestion(true); // Ensure AI Suggestion is shown by default
    setShowSource(false);
    setFromAI("");
    setSorceDate("");
    setConfidenceScore(0);
    setBardAnswer("");
    setChartGPTAnswer("");
    setValue(null);
    setAIAnswer(null);

    try {
      const data = await GENAI(
        props.QuestionID,
        props.Enddata,
        props.startDate,
        source.token
      );

      if (data.res) {
        setSorceDate(data?.note);
        setFromAI(data?.from);
        if (props.Fieldtype === "Currency Field") {
          function removeSpecialCharacters(obj) {
            if (obj && obj.res && typeof obj?.res.Amount === "string") {
              obj.res.Amount = obj.res.Amount.replace(/,/g, "");
            }
            return obj;
          }
          handleAiAnswer(removeSpecialCharacters(data));
        } else {
          handleAiAnswer(data);
        }
      } else {
        ErrorFunction();
      }
    } catch (error) {
      ErrorFunction();
      console.error(error);
    }
  };

  useEffect(() => {
    setEnable(true);
    FtechAIData();
    return () => {
      source.cancel("Request canceled by cleanup");
    };
  }, [props.QuestionID]);

  const HandleAnswerChange = async (event) => {
    try {
      setValue(event.target.value);
      if (event.target.value === "BardResponse") {
        if (props.Fieldtype === "Text Field") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Drop Down") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Date Range") {
          props.handleChange(JSON.stringify(BardAnswer), true);
        } else if (props.Fieldtype === "Rich Text") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Number Field") {
          props.handleChange(`${BardAnswer}`, true);
        } else if (props.Fieldtype === "Date") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Table") {
          try {
            const data = BardAnswer.rows;
            data.forEach((obj) => {
              obj.id = randomId();
              obj.isNew = false;
            });
            if (typeof constextdata.Answer.UpdatedAnswer !== "string") {
              constextdata.Answer.UpdatedAnswer.length !== 0 &&
                constextdata?.Answer?.UpdatedAnswer?.forEach((item) =>
                  data.push(item)
                );
            }

            props.handleChange(data, true);
          } catch (error) {
            console.error(error);
          }
        } else if (props.Fieldtype === "Currency Field") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Percentage Field") {
          props.handleChange(`${BardAnswer}`, true);
        } else if (props.Fieldtype === "Form Field") {
          //props.handleChange(BardAnswer,true);
        } else if (
          props.Fieldtype === "Radio Button" ||
          props.Fieldtype === "Radio Button with Dependency"
        ) {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Check Box") {
          let arrayitem = [BardAnswer];
          props.handleChange(arrayitem, true);
        } else if (props.Fieldtype === "List of Address") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Multiselect") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "TelephoneNumber") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Contact Information") {
          try {
            let data = BardAnswer;
            if (typeof constextdata.Answer.UpdatedAnswer !== "string") {
              constextdata.Answer.UpdatedAnswer.map((item) => data.push(item));
            }
            props.handleChange(data, true);
          } catch (error) {
            console.error(error);
          }
        } else if (props.Fieldtype === "URL Field") {
          // props.handleChange(BardAnswer);
        } else if (props.Fieldtype === "Email Field") {
          // props.handleChange(BardAnswer);
        } else if (props.Fieldtype === "Number Field with Units") {
          props.handleChange(BardAnswer, true);
        } else if (props.Fieldtype === "Editable List") {
          try {
            const data = BardAnswer;
            data.forEach((obj) => {
              obj.id = randomId();
              obj.completed = false;
            });

            if (typeof constextdata.Answer.UpdatedAnswer !== "string") {
              constextdata.Answer.UpdatedAnswer.length !== 0 &&
                constextdata.Answer.UpdatedAnswer.map((item) =>
                  data.push(item)
                );
            }

            props.handleChange(data, true);
          } catch (error) {
            console.error(error);
          }
        }
        if (sourceData) {
          await updateAdditionalNote(
            props.startDate,
            props.QuestionID,
            sourceData
          );
        }
        props.selectHandler(props.QuestionID, true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleAISuggestion = () => {
    setShowAISuggestion(true);
    setShowSource(false);
  };

  const toggleSource = () => {
    setShowSource(true);
    setShowAISuggestion(false);
  };

  const handleSourceChange = (event, sourceItem) => {
    if (event.target.checked) {
      setSelectedSources([...selectedSources, sourceItem]);
    } else {
      setSelectedSources(selectedSources.filter((item) => item !== sourceItem));
    }
  };

  return (
    <Grid container direction={"column"} padding={2}>
      <Grid
        item
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item onClick={toggleAISuggestion}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: showAISuggestion ? "#2076B0" : "#061826",
              marginBottom: "5px",
              cursor: "pointer",
            }}
          >
            AI Suggestion
          </Typography>
        </Grid>
        <Grid item onClick={toggleSource}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: showSource ? "#2076B0" : "#061826",
              marginBottom: "5px",
              cursor: "pointer",
            }}
          >
            Source
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={FtechAIData}>
            <CachedIcon />
          </IconButton>
        </Grid>
      </Grid>

      {showAISuggestion && (
        <Grid>
          <Grid item>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={value}
                name="radio-buttons-group"
                onClick={HandleAnswerChange}
              >
                <FormControlLabel
                  value="BardResponse"
                  control={<Radio />}
                  label="AI Response"
                  disabled={Enable}
                  sx={{ color: "#25A392" }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Paper sx={{ color: "#BAD5E7" }}>
            <Grid container p={1} justifyContent={"space-between"}>
              <Grid item>
                <Typography sx={{ color: "#2076B0" }}>
                  Confidence Score
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#2076B0" }}>
                  {ConfidenceScore ? ConfidenceScore : 0}/10
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid item mt={2}>
            {AIAnswer ? (
              props.Fieldtype === "Rich Text" ? (
                <div
                  key={AIAnswer}
                  dangerouslySetInnerHTML={{ __html: AIAnswer }}
                />
              ) : (
                <Typography key={AIAnswer} sx={{ color: "blue" }}>
                  {AIAnswer}
                </Typography>
              )
            ) : (
              <Box display="flex" alignItems="center">
                <CircularProgress size={20} />
                <Typography sx={{ marginLeft: 1 }}>
                  Fetching AI response ...
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      {showSource && sourceData && (
        <Box
          border={1}
          borderRadius={2}
          p={1}
          sx={{
            border: "1px solid green",
            width: "100%",
            maxWidth: 300, // Set max width
            overflow: "hidden", // Hide overflow content
            wordWrap: "break-word", // Ensure text wraps
            whiteSpace: "pre-wrap", // Preserve whitespace and wrap long lines
            fontFamily: "Times New Roman", // Set font family
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                border={1}
                borderRadius={2}
                p={1}
                sx={{
                  border: "1px solid green",
                  fontFamily: "Times New Roman", // Set font family
                }}
              >
                <Typography sx={{ fontFamily: "Times New Roman" }}>
                  Source Type: {FromAI}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box
                border={1}
                borderRadius={2}
                p={1}
                sx={{
                  border: "1px solid green",
                  maxHeight: 200, // Limit the height of the container
                  overflowY: "auto", // Add vertical scroll for overflow content
                  fontFamily: "Times New Roman", // Set font family
                }}
              >
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: sourceData }}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontFamily: "Times New Roman", // Set font family
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
}

export default AIbox;
