import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Button, Grid, Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {
  addSection,
  FetchGetData,
  fetchEditIcon,
} from "../services/FormCreationFetch";
import NoteContext from "context/PageContext";
import EditDisclosure from "./EditDisclosure";

const NewSection = (props) => {
  const contextData = useContext(NoteContext);
  let session_values = JSON.parse(sessionStorage.getItem("section"));
  const [formValues, setFormValues] = useState([{ session_values }]);
  // eslint-disable-next-line no-unused-vars
  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState();
  const [sectionId, setSectionId] = useState();
  const [sectiondescription, setSectionDescription] = useState('');
  const [sectionhelpertext, setSectionHelpertext] = useState('');
  const [editIcon, setIconEDit] = React.useState(false);
  const copyItem = formValues;
  const [open, setOpen] = React.useState(false);
  const [Error,setError]=useState();
  let disclosurename = contextData.disclosureName;
  const [openEdit,setopenEdit]=useState(false);
  
  const handleEdit=()=>{
    setopenEdit(!openEdit)
  }
  //  console.log(disclosurename);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setIconEDit(false);
    // console.log("Cancel the form")
  };

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const fetchEditData = async (disclosurename) => {
    await FetchGetData(disclosurename).then((data) => {
      //console.log("Data", data)
      setFormValues(copyItem);
    });
  };

  // const handleEditIcon =()=>{
  //   fetchEditData()
  //   setIconEDit(true)
  // }


  const handleSave = async () => {
    //console.log("page is hitting")
    await fetchEditIcon(disclosurename, formValues).then((data) => {
      //   console.log(data)
      //    //setExisting_disclosure(data._items[0]);
    });
  };

  const handleAddSection = () => {
    // localStorage.removeItem('myDisclosure');

    // const dataFromLocalStorage = JSON.parse(localStorage.getItem("myDisclosure"));
    const dataFromLocalStorage = "";
    let disclosureName;
    if (contextData.disclosureName) {
      disclosureName = contextData.disclosureName;
    } else {
      disclosureName = JSON.parse(localStorage.getItem("disclosurename"));
    }

    // Step 2: Parse the JSON data from local storage (if it exists) or initialize a new object
    let demoData = dataFromLocalStorage
      ? dataFromLocalStorage
      : { title: disclosureName, sections: [] };

    // Step 3: Create a new section element and push it to the sections array
    const newSection = {
      title: sectionName,
      sectionId: sectionId,
      questions: [],
      subsections: [],
    };
    demoData.sections.push(newSection);

    // Step 4: Save the updated data back to the local storage
    // localStorage.setItem("myDisclosure", JSON.stringify(demoData));
    // const disclosurename = ((dataFromLocalStorage[0].title));
    const disclosurename = demoData.title;
    // console.log(disclosurename);
    // setSections(dataFromLocalStorage);
    setSections(demoData);
    //console.log(disclosurename, sectionName, sectionId, sectiondescription, sectionhelpertext);
    addSection(
      disclosurename,
      sectionName,
      sectionId,
      sectiondescription,
      sectionhelpertext
    ).then((res) => {
      if(res?.message){
        setError(res.message)
      }else{
        props.handleClose();
       contextData.setReloadData(true);
       
      }
      
    });
  };

  return (
    <div>
      {props.condtion[0].editButton &&
      <Tooltip title="Add Section">
        <IconButton
          size="small"
          id="add"
          aria-label="Add new Section"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleClickOpen}
          color="inherit"
        >
          <NoteAddIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      </Tooltip>
     }
     {props.condtion[0].editButton &&
      <IconButton onClick={handleEdit}>
        <EditIcon sx={{ fontSize: "18px" }} />
      </IconButton>
     }
     {props.condtion[0].delete &&
      <IconButton onClick={() => console.log("delete disclosure")}>
        <DeleteIcon sx={{ fontSize: "18px" }} />
      </IconButton>
     }
      {/* <IconButton><DisplaySettingsIcon sx={{ fontSize: '18px' }} /></IconButton> */}
      <Dialog open={open} sx={{ color: "white" }}>
        <DialogTitle id="alert-dialog-title">
          {editIcon ? "Edit the section" : "Create new section"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="sectionname-input"
                name="sectionname"
                label="Section Name"
                type="text"
                value={formValues.sectionName}
                onChange={(e) => setSectionName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="sectionid-input"
                name="sectionid"
                label="section id"
                type="text"
                required
                error={Error}
                helperText={Error}
                value={formValues.sectionId}
                onChange={(e) => {  
                  Error && setError(null);
                  setSectionId(e.target.value)}}
              />
            </Grid>
            {/* </Grid>
          <Grid container spacing={2}> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="sectiondescription-input"
                name="sectiondescription"
                label="section description"
                type="text"
                required
                value={formValues.sectiondescription}
                onChange={(e) => setSectionDescription(e.target.value)}
              />
            </Grid>
            {/* </Grid>
          <Grid container spacing={2}> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="sectionhelpertext-input"
                name="sectionhelpertext"
                label="section helpertext"
                type="text"
                required
                value={formValues.sectionhelpertext}
                onChange={(e) => setSectionHelpertext(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25a392",
              marginLeft: "10px",
              flexDirection: "row",
            }}
            onClick={handleAddSection}
          >
            Create
          </Button>
          <Button
            variant="text"
            style={{ color: "red", flexDirection: "row" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openEdit && (
                      <EditDisclosure
                        data={JSON.parse(localStorage.getItem("DisclosureDetails"))} 
                        setEditPopUP={handleEdit}
                        EditPopUP={openEdit}
                      />
                    )}
    </div>
  );
};

export default NewSection;
