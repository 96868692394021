import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Chart from "react-google-charts";
import { useCallback } from "react";
// import "../../styles/Report/Report.css";

export const data = [
  ["Country", "Number of emp's"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700],
  ["INDIA", 1000],
];
const commonStyles = {
  bgcolor: "background.paper",
  borderColor: "text.primary",
  m: 1,
  border: 1,
  width: "80rem",
  height: "32rem",
};

function CDReport(props) {
  const [ISloading, setISloading] = useState(false);
  const [Company, setCompany] = useState();
  const [Bussniessname, setBussniessname] = useState();
  const [operatingLocation, setoperatingLocation] = useState();
  const [Address, setAddress] = useState();

  const FetchCompanyDetails = useCallback(() => {
    setCompany(
      Object.values(props.data.reportData["GRI 2.1 Oganization Details"])[0]
        .answer
    );
    const Headq = Object.values(
      Object.values(props.data.reportData["GRI 2.1 Oganization Details"])[2]
        .answer[0]
    );
    console.log(
      Object.values(
        Object.values(props.data.reportData["GRI 2.1 Oganization Details"])[2]
          .answer
      )
    );
    // console.log(Headq.length);

    const samplearray = [];
    for (let i = 0; i < Headq.length; i++) {
      if (i === 4) {
        //console.log(Object.values(Headq[i]));
        samplearray.push(Object.values(Headq[i])[0]);
      } else {
        samplearray.push(Headq[i]);
        //console.log(samplearray);
      }
    }

    setAddress(samplearray);

    setBussniessname(
      Object.values(props.data.reportData["GRI 2.1 Oganization Details"])[1]
        .answer
    );
    // console.log(
    //   Object.values(props.data.reportData["GRI 2.1 Oganization Details"])[1]
    //     .answer
    // );

    setoperatingLocation(
      Object.values(props.data.reportData["GRI 2.1 Oganization Details"])[4]
        .answer
    );
    // console.log(
    //   Object.values(props.data.reportData["GRI 2.1 Oganization Details"])[4]
    //     .answer
    // );

    setISloading(true);
  }, [props]);
  useEffect(() => {
    FetchCompanyDetails();
  }, [FetchCompanyDetails]);

  return (
    <>
      {ISloading ? (
        <div className="cdBox">
          <Box sx={{ ...commonStyles, justifyContent: "left" }}>
            <Grid container spacing={2}>
              <Grid xs={12} md={4}>
                <h1 align="center">{Company}</h1>
                <ul>
                  <li key={Company}> Companys legal name is {Company}</li>
                  <li key={Bussniessname}>
                    Company business name is {Bussniessname}
                  </li>
                  <li key={1}>
                    Headquarters located at
                    <br />
                    {Address.map((Address, index) => {
                      return (
                        <React.Fragment key={index}>{Address}</React.Fragment>
                      );
                    })}
                  </li>
                  <li>
                    Operating locations
                    {operatingLocation.map((operatingLocation, index1) => {
                      return (
                        <ul key={index1}>
                          <li key={index1}> {operatingLocation}</li>
                        </ul>
                      );
                    })}
                  </li>
                </ul>
              </Grid>
              <Grid xs={12} md={8}>
                <Chart
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = data[selection[0].row + 1];
                        console.log("Selected : " + region);
                      },
                    },
                  ]}
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={data}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      ) : (
        <div className="loaders">Loading...</div>
      )}
    </>
  );
}

export default CDReport;
