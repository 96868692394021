import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DataGrid,
  GridToolbarContainer,
  GridCellModes,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  // GridToolbarExportContainer,
  // GridCsvExportMenuItem
} from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import { randomId } from "@mui/x-data-grid-generator";
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import AutocompleteForTable from "ESG/forms/AutocompleteForTable";
import {
  CurrencyForTable,
  EditTextarea,
  CheckBox,
} from "ESG/forms/ComponentsForTable";
import { styles } from "ESG/styles/Forms_Styles/Form_table";
import { getTableDropdownValues } from "ESG/services/Fetch";
import dayjs from "dayjs";
import "../styles/Forms_Styles/Form_RTE.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

const getJson = (apiRef) => {
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  return JSON.stringify(data, null, 2);
};
const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: "text/json",
        });

        exportBlob(blob, "DataGrid_demo.json");

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};

// const csvOptions = { delimiter: ';' };

const Form_Table = ({
  TableJson,
  questionId,
  onChange,
  startDate,
  domain,
  getAnswerDisable
}) => {
  const [open, setOpen] = useState(false);
  const {Answer}=useContext(NoteContext);
  // console.log(Answer)
  // const [pageSize, setPageSize] = useState(100);
  let rows=[];
  try {
    
     rows=typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : []
  } catch (error) {
    rows=[];
  }

  
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [RowTypeGrid, setRowTypeGrid] = useState(false);
  

  const handleStateChange = (cellData) => {
    const { id, field, value } = cellData;
    console.log(id, field, value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [cellModesModel, setCellModesModel] = React.useState({});
  console.log(TableJson,Answer);
 
  const ColumnData = TableJson.ColumnLabel.Columns;
  const RowLabels = TableJson.RowLabel ? TableJson.RowLabel.Rows : null;
  const Headers = TableJson.Headers ? TableJson.Headers.Columns : null;
  let columnGroupingModel = [];

  if (Headers) {
    Headers.forEach((header) => {
      const obj = {};
      obj.groupId = header.MainHeader;
      obj.children = header.SubHeader;
      columnGroupingModel.push(obj);
    });
  }

  const handleCellClick = React.useCallback((params) => {
    params.field !== "__check__" &&
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

 
  const handleDeleteClick = (rowsSelected) => () => {
    const filteredRows = rows.filter(row => !rowsSelected.includes(row.id));
    onChange(filteredRows);
  };
  

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    // console.log('process update');
    onChange(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

 

  function EditToolbar(props) {
    const { onChange, columns } = props;
    const AddNewRecord = () => {
      const id = randomId();
      let obj = {
        id,
        isNew: true,
      };
      columns.map((item) => (obj[item.field] = ""));
      onChange([...rows, obj]);
    };

    return (
      <GridToolbarContainer sx={{ ...styles.toolbarContainer }}>
        <Box sx={{ ...styles.toolbarBox }}>
          <ButtonGroup
            fullWidth
            size="small"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button
              sx={{ ...styles.addDelete }}
              onClick={AddNewRecord}
              startIcon={<AddCircleOutlinedIcon />}
            />
            <Button
              sx={{ ...styles.addDelete }}
              onClick={handleDeleteClick(selectedRows)}
              startIcon={<DeleteIcon />}
            />
            <GridToolbarColumnsButton
              fullWidth
              sx={{ ...styles.buttonIcons }}
            />
            <GridToolbarFilterButton fullWidth sx={{ ...styles.buttonIcons }} />
            <GridToolbarDensitySelector
              fullWidth
              sx={{ ...styles.buttonIcons }}
            />
            <GridToolbarExport fullWidth sx={{ ...styles.buttonIcons }} />
            {/* <CustomExportButton /> */}
          </ButtonGroup>
        </Box>
      </GridToolbarContainer>
    );
  }

  EditToolbar.propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setLoading(true);
    let colarr = [];
    if (!ColumnData) {
      return;
    }
    // eslint-disable-next-line
    Object.entries(ColumnData).map((item) => {
      let obj = {
        flex: 1,
        minWidth: 150,
        editable: true,
        field: item[1].Field ?? item[1].Name.trim(),
        headerName: item[1].Name.trim(),
      };
      switch (item[1].Type) {
        case "Drop Down":
        case "Drop Down List":
          obj.type = "singleSelect";
          if (typeof item[1].values === "string") {
            getTableDropdownValues(questionId, item[1].values).then((res) => {
              obj.valueOptions = res;
            });
          } else {
            obj.valueOptions = item[1].values;
          }
          obj.renderEditCell = (params) => (
            <AutocompleteForTable
              // sx={{ margin: "0px" }}
              {...params}
              questionId={questionId}
              values={item[1]?.values}
              startDate={startDate}
              domain={domain}
            />
          );
          break;
        case "Text Field":
          obj.type = "string";
          break;
        case "Currency Field":
          obj.type = "string";
          obj.renderEditCell = (params) => (
            <CurrencyForTable
              {...params}
              values={item[1].values}
              questionId={questionId}
            />
          );
          break;
        case "Number Field":
          obj.type = "number";
          break;
        case 'Date':
        case 'Date Field':
          obj.type = 'date';
          obj.valueFormatter = (params) => params.value && dayjs(params.value).format('DD/MM/YYYY');
          break;
        case "Percentage Field":
          obj.type = "number";
          obj.valueFormatter = (params) => {
            if (params.value == null) {
              return "";
            }
            const valueFormatted = params.value.toLocaleString();
            return `${valueFormatted} %`;
          };
          break;
        case "Rich Text":
          obj.type = "string";
          obj.renderEditCell = (params) => <EditTextarea {...params} />;
          obj.renderCell = () => <Typography variant="h6">...</Typography>;
          break;
        case "Check Box":
          obj.type = "Check Box";
          obj.renderEditCell = (params) => (
            <Box ml={1}>
              <CheckBox {...params} />
            </Box>
          );
          obj.renderCell = (params) => <CheckBox {...params} />;
          break;
        case "Boolean":
          obj.type='boolean'
          break;
        default:
          obj.type = "string";
      }
      colarr.push(obj);
    });

    if (RowLabels) {
      setRowTypeGrid(true);
      if (rows.length === 0) {
        let rowarr = [];
        //looping through rowlabels and creating no of rows equal to it
        for (var i = 0; i < RowLabels[0].RowValues.length; i++) {
          const id = randomId();
          let obj = {
            //each new obj is a new row
            id,
            isNew: true,
          };
          colarr.forEach((column) => (obj[column.field] = "")); //copying column.field i-e name of column as property for a row obj
          rowarr.push(obj); //pushing each row to temp array
        }

        Object.entries(RowLabels).map((item) => {
          //looping through no of columns which has pe-defined rows
          colarr.forEach((column) => {
            if (column.field === item[1].columnName) {
              //finding column with pre-defined rows and making it uneditable
              column.type !== "Check Box" && delete column.editable;
            }
          });
          rowarr.forEach((row, index) => {
            row[item[1].columnName] = item[1].RowValues[index];
          });
        });
        onChange(rowarr);
      } else {
        // eslint-disable-next-line
        Object.entries(RowLabels).map((item) => {
          colarr.forEach((column) => {
            if (column.field === item[1].columnName) {
              column.type !== "Check Box" && delete column.editable;
            }
          });
          rows.forEach((row, index) => {
            if (typeof row[item[1].columnName] === "string") {
              //for textfield ans will be stored as string
              row[item[1].columnName] = item[1].RowValues[index];
            } else {
              row[item[1].columnName].label = item[1].RowValues[index]; //if field type is checkbox it will b an obj
            }
          });
        });
        onChange(rows);
      }
    }

    setColumns(colarr);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="InputFields">
      {!loading && (
        <DataGrid
          sx={{ ...styles.table }}
          rows={rows}
          columns={columns}
          // onCellValueChange={handleStateChange}
          //onCellValueChange={handleStateChange}
          // onEditCellChange={handleStateChange}
          // onCellKeyDown={handleStateChange}
          // onCellEditCommit={handleStateChange}
          columnHeaderHeight={40}
          columnGroupingModel={columnGroupingModel}
          disableRowSelectionOnClick
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          processRowUpdate={processRowUpdate}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            setSelectedRows(ids);
          }}
          rowSelectionModel={selectedRows}
          localeText={{
            toolbarColumns: "",
            toolbarFilters: "",
            toolbarDensity: "",
            toolbarExport: "",
          }}
          components={{
            Toolbar:  EditToolbar,
            OpenFilterButtonIcon: FilterAltRoundedIcon,
          }}
          componentsProps={{
            toolbar: { onChange, setColumns, columns, handleClickOpen },
          }}
          experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
        />
      )}
    </Box>
  );
};
export default Form_Table;
