import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Autocomplete, Drawer } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from "@mui/icons-material/Search";
import { CardActions, Grid, TextField, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Skeleton from "@mui/material/Skeleton";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { loginRequest } from "../authConfig";
import NoteContext from "../context/PageContext.js";
import ldlogo from "../images/ldlogo.png";
import "../ESG/styles/Navbar/Navbar.css";
import DrawerMenu from "./DrawerMenu.js";
import ErrorMessages from "./ErrorMessage.js";
import MyProfile from "../components/MyProfile.js";
import Notification from "./Notification.js";
import RoleSwitch from "./Switchnig_Roles.js";
import ThemeController from "../components/Theme/ThemeController.js";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { getUserProfile, domainDropdownList } from "../ESG/services/Fetch";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RolesDropDown from "./RolesDropDown";
import {deletecaseGenAISession} from 'Compliance/services/CaseFetch'
export const NavigationBar = () => {
  // const { instance } = useMsal();
  const { instance } = require("../index");

  /**
   *
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */

  return (
    <>
      <AuthenticatedTemplate>
        <PrimarySearchAppBar instance={instance} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <PrimarySearchAppBar instance={instance} />
      </UnauthenticatedTemplate>
    </>
  );
};

const PrimarySearchAppBar = (props) => {
  const contextData = useContext(NoteContext);

  const drawerWidth = 60;
  const isAuthenticated = useIsAuthenticated();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [AccountInfo, setAccountInfo] = React.useState();
  const [anchorE, setAnchorE] = React.useState(null);
  const openNotif = Boolean(anchorE);
  const id = openNotif ? "simple-popover" : undefined;
  const [openDialog, setOpenDialog] = React.useState(false);
  const valueRef = useRef(""); //creating a refernce for TextField Component
  const [expanded, setExpanded] = useState(false);
  const [AdminRole, setAdminrole] = useState();
  const location = useLocation();
  const [UserDomain, setUserDomain] = useState(null);
  const [LoggedOut, setLoggedOut] = useState(false);

  // const userData = JSON.parse(localStorage.getItem("AccountDetails"));

  const [userData, setUserData] = useState("")

  useEffect(() => {
    const FetchUserDropDownDomain = async () => {
      try {
        const userdomainList = await domainDropdownList();
        localStorage.setItem(
          "UserSelectedDomain",
          JSON.parse(localStorage.getItem("Name")).id.split("@")[1]
        );
        contextData.setUserSelected(
          JSON.parse(localStorage.getItem("Name")).id.split("@")[1]
        );
        setUserDomain(userdomainList);
      } catch (error) {
        console.error(error);
      }
    };
    if (contextData.CompanyAdded) {
      FetchUserDropDownDomain();
      contextData.setCompanyAdded(false);
    }
  }, [contextData.CompanyAdded]);
  // const Account_Info = JSON.parse(localStorage.getItem("AccountInfo"));
  useEffect(() => {
    // Define the function to fetch the user profile data
    const getDashboardData = async () => {
      // Check if the user is authenticated
      contextData.setUserLogInError({
        message: "",
        Errors: false,
      });
      if (isAuthenticated) {
        try {
          //set the user domain
          const userdomainList = await domainDropdownList();
          localStorage.setItem(
            "UserSelectedDomain",
            JSON.parse(localStorage.getItem("Name")).id.split("@")[1]
          );
          contextData.setUserSelected(
            JSON.parse(localStorage.getItem("Name")).id.split("@")[1]
          );
          setUserDomain(userdomainList);

          // Fetch user profile data

          const response = await getUserProfile();
          // console.log(response);
          // console.log(response.data);
          if (response?.data) {
            // Successful data retrieval
            setAccountInfo(response?.data);
            localStorage.setItem(
              "AccountDetails",
              JSON.stringify(response?.data)
            );
            setUserData(response?.data);
          } else {
            // Handle the case where data is not available
            console.log("/me Error: No data received");
            // contextData.setUserLogInError({
            //   message: "Login failed. Please check your credentials.",
            //   Errors: true,
            // });
          }
        } catch (error) {
          console.error("Error fetching user profile data:", error);
          // contextData.setUserLogInError({
          //   message: error.message,
          //   Errors: true
          // });
        }
      }
    };
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  // for restrict the user for login page
  useEffect(() => {
    const domainName = JSON.parse(localStorage.getItem("Name")).id.split(
      "@"
    )[1];
    // const FetchRestricticon = async () => {
    //   const data = await getCompanyByDomain();
    //   console.log(
    //     data.data[1].properties.interestedIn[0].value,
    //     "----------------------------"
    //   );
    // };

    // FetchRestricticon();
    setAdminrole(domainName);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (event) => {
    setAnchorE(event.currentTarget);
  };

  const LogoutButton = () => {
    return (
      <Button
        edge="end"
        title="Logout"
        aria-label="Logout"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={() => handleLogout("redirect")}
        variant="text"
      >
        Logout
      </Button>
    );
  };

  const LoginButton = () => {
    return (
      <Tooltip title="Login">
        <IconButton
          size="large"
          edge="end"
          aria-label="Login"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={() => handleLogin("popup")}
        >
          <LoginIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const ProfileManager = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          marginLeft: "5px",
        }}
      >
        {AccountInfo?.endUser && (
          <>
            <Avatar
              style={{
                fontSize: "small",
                width: 30,
                height: 30,
                alignSelf: "center",
                padding: "10px",
                border: "1px solid #A3A1F3",
              }}
              {...stringAvatar(
                AccountInfo?.endUser?.displayName ?? "Not Assigned"
              )}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  color: "#FF9B71",
                  fontSize: "14px",
                  padding: 0,
                  margin: 0,
                }}
              >
                {AccountInfo?.endUser?.displayName ?? "Not Assigned"}
              </Typography>
              <Typography
                sx={{
                  color: "#061826",
                  fontSize: "10px",
                  padding: 0,
                  margin: 0,
                }}
              >
                {window.location.pathname === "/caseManagement"
                  ? contextData?.CaseRole?.name ?? AccountInfo?.endUser?.mail
                  : AccountInfo?.endUser?.mail}
              </Typography>
            </Box>
          </>
        )}
        <Tooltip title="My profile">
          <IconButton
            sx={{
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
            edge="start"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <ArrowDropDownRoundedIcon
              fontSize="medium"
              sx={{ color: "black" }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const handleClose = () => {
    setAnchorE(null);
  };

  //new filling handler
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      // anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <TextField
          id="standard-basic"
          placeholder="Search"
          variant="outlined"
          inputRef={valueRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            className: "searchInput",
          }}
        ></TextField>
      </MenuItem>

      <MenuItem onClick={handleClickOpen}>NewFilling</MenuItem>
      <MenuItem onClick={handleClick}>Notifications</MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>Profile</MenuItem>
      <MenuItem onClick={() => handleLogout("redirect")}>Logout</MenuItem>
    </Menu>
  );

  const { instance } = useMsal();
 const deleteSession=async()=>{
  const userDetails = JSON.parse(localStorage.getItem("AccountDetails"));
  const auth = userDetails?.endUser;
  var obj={
    session_id:auth?.id
  }
  await deletecaseGenAISession(obj)
 }
  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      deleteSession()
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    }
    //  else if (logoutType === "redirect") {
    //   instance.logoutRedirect({
    //     postLogoutRedirectUri: "/",
    //   });
    // }
  };
  //Automatic Log out Function

  const LogOutTokenExpr = () => {
    setTimeout(() => {
      console.log("logged out")
      handleLogout("redirect");
    }, localStorage.getItem('expirationTime'));
  };

  //login handele funtion
  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then(async (loggedIn) => {
          localStorage.removeItem("Name");
          contextData.setName({
            ...contextData.Name,
            id: loggedIn.account.username,
            name: loggedIn.account.name,
          });

          await instance
            .acquireTokenSilent({
              account: loggedIn.account,
              scopes: loggedIn.scopes,
            })
            .then((tokens) => {
              localStorage.setItem("tokens", JSON.stringify(tokens));
              const expirationTime = new Date(tokens.expiresOn);

              const currentTime = new Date();

              const timeDifference = expirationTime.getTime() - (currentTime.getTime() - 100000);
              // console.log(expirationTime, currentTime);
              // console.log( timeDifference);
              localStorage.setItem('expirationTime', timeDifference);
              LogOutTokenExpr();
              console.log(tokens.expiresOn);
            });
          localStorage.setItem("userScopes", JSON.stringify(loggedIn.scopes));
          instance.setActiveAccount(loggedIn.account);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (loginType === "redirect") {
      instance
        .loginRedirect(loginRequest)
        .then((loggedIn) => {
          localStorage.clear();
          instance.setActiveAccount(loggedIn.account);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  if (JSON.parse(localStorage.getItem("Name")) === null) {
    localStorage.setItem("Name", JSON.stringify(contextData.Name));
  }

  //after login return
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    const nameParts = name.split(" ");
    const firstInitial = nameParts[0][0];
    const secondInitial = nameParts.length > 1 ? nameParts[1][0] : ""; // Check if second part exists

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${firstInitial}${secondInitial}`,
    };
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const renderMenu = (
    <div className="profile_Menu">
      <Popover
        // anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        anchorPosition={{ top: 100, left: 400 }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <Box className="renderMenu-button" component="form" autoComplete="off">
          {AccountInfo?.endUser ? (
            <>
              <Paper elevation={3}>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item className="LoggedInToolbar_Avatar">
                        <Avatar className="Company_Logo" src={ldlogo} />
                      </Grid>
                      <Grid item className="LogOut_Button">
                        <LogoutButton />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item className="LoggedInToolbar_Avatar">
                        <Avatar
                          className="LoggedInToolbar_Avatar_Box"
                          {...stringAvatar(
                            AccountInfo?.endUser?.displayName ?? "Not Assigned"
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography className="LoggedInToolbar_AccountInfo">
                          {AccountInfo?.endUser?.displayName ?? "Not Assigned"}
                          <br></br>
                          {AccountInfo?.endUser?.mail ?? "Not Assigned"}
                          <br></br>
                          {AccountInfo?.endUser?.companyName ?? "Not Assigned"}
                          <br></br>
                          {AccountInfo?.endUser?.jobTitle ?? "Not Assigned"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions disableSpacing>
                    {/* <MenuItem>
                      <MyProfile />
                    </MenuItem> */}
                    {window.location.pathname === "/caseManagement" ? (
                      <RoleSwitch />
                    ) : (
                      userData !== "" && userData?.filingGroups && (
                        <RolesDropDown data={userData} />

                      )
                    )}

                    <ThemeController />
                    {/* <IconButton
                      className={`expand-more-button-navbar ${
                        expanded ? "expanded-navbar" : ""
                      }`}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton> */}
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography variant="body2">
                        Mobile Phone:
                        {AccountInfo?.endUser?.mobilePhone ?? "Not Assigned"}
                      </Typography>
                      <Typography variant="body2">
                        Office Location:
                        {AccountInfo?.endUser?.officeLocation ?? "Not Assigned"}
                      </Typography>
                      <Typography variant="body2">
                        Preferred Language:
                        {AccountInfo?.endUser?.preferredLanguage ??
                          "Not Assigned"}
                      </Typography>
                    </CardContent>
                  </Collapse>
                </Card>
              </Paper>
            </>
          ) : (
            <React.Fragment>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item className="LoggedInToolbar_Avatar">
                  <Avatar className="Company_Logo" src={ldlogo} />
                </Grid>
                <Grid item className="LogOut_Button">
                  <LogoutButton />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item className="LoggedInToolbar_Avatar">
                  <Skeleton
                    variant="circular"
                    width={80}
                    height={80}
                    className="Skeleton"
                  />
                </Grid>
                <Grid item>
                  <Typography className="LoggedInToolbar_AccountInfo">
                    <Skeleton className="Skeleton-Typography-AccountInfo" />
                    <br></br>
                    <Skeleton className="Skeleton-Typography-AccountInfo" />
                  </Typography>
                  <MenuItem>
                    <Skeleton className="Skeleton-MenuItem-AccountInfo" />
                  </MenuItem>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Box>
      </Popover>
    </div>
  );
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  return (
    // Main Navbar Return
    isAuthenticated === true ? (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            padding: 0,
          }}
          className="Navigation_Main"
        >
          <Toolbar variant="dense" disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                marginLeft: "5px",
                display: { sm: "none" },
                color: "black",
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={ldlogo} className="logo" alt="ldlogo" style={{ marginRight: '25px' }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {(window.location.pathname === "/" ||
                window.location.pathname === "/Home" ||
                window.location.pathname === "/caseManagement" || ((userData?.endUser?.mail)?.split(".")[0].toLowerCase() == "ankurjit")) && (
                  <>
                    {
                      userData !== "" ? (
                        // (userData.endUser.mail).split(".")[0].toLowerCase() == "ankurjit" ? null :
                        (
                          <>
                            {UserDomain && (
                              <FormControl fullWidth >
                                <Select
                                  value={contextData.UserSelected}
                                  onChange={(e) => {
                                    localStorage.setItem(
                                      "UserSelectedDomain",
                                      e.target.value
                                    );

                                    contextData.setUserSelected(e.target.value);
                                  }}
                                  size="small"

                                >
                                  {UserDomain?.map((item, index) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </>

                        )

                      ) : null
                    }
                  </>
                )}
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{
              display: { xs: "none", md: "flex" }, alignItems: "center",
              justifyContent: "space-between", // Add space between the components
            }}
            >
              <Notification
                handleClick={handleClick}
                handleClose={handleClose}
                anchorE={anchorE}
                openNotif={openNotif}
                id={id}
              />
              <div style={{ marginLeft: "10px" }}>
                <ProfileManager />
              </div>
            </Box>
            <Box className="mobileMenuId-box-class">
              <Tooltip title="More">
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                >
                  <MoreIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
        <DrawerMenu
          drawerWidth={drawerWidth}
          open={mobileOpen}
          setMobileOpen={setMobileOpen}
        />
        {renderMobileMenu}
        {renderMenu}
        <ErrorMessages handleLogin={handleLogin} />
      </React.Fragment>
    ) : (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" className="Navigation_Main">
          <Toolbar variant="dense">
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src={ldlogo} className="logo" alt="" />
            </Typography>

            <LoginButton />
          </Toolbar>
        </AppBar>
      </Box>
    )
  );
};
