import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import ENV from "../../../images/Env.jpg";
import Govr from "../../../images/Governance.jpg";
import Socail from "../../../images/Social.jpg";
import Apple from "../../../images/General.jpg";

import { GetIndexValues } from "../../services/ReportFetch";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function IndexReport(props) {
  const [Isloading, setIsloading] = useState(false);

  const [title, setTitel] = useState([]);
  const [EnvTopic, setEnvtopics] = useState([]);
  const [SocailTopic, setSocailtopics] = useState([]);
  const [GovTopic, setGovtopics] = useState([]);

  useEffect(() => {
    GetIndexValues().then((Response) => {
      setIsloading(true);
      let titlearray = [];
      for (var i = 0; i < 3; i++) {
        titlearray.push(Object.keys(Response.data.data)[i].split(" ")[0]);
      }
      setTitel(titlearray);

      setEnvtopics(Object.values(Response.data.data)[0]);
      setGovtopics(Object.values(Response.data.data)[2]);
      setSocailtopics(Object.values(Response.data.data)[1]);
    });
  }, [Isloading]);

  return (
    <>
      {Isloading && (
        <Box sx={{ bgcolor: "#cfe8fc", height: "85vh", width: "100vw" }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <Item>
                <h2>Company location</h2> <h6>ESG REPORT 2020-21 </h6>
              </Item>
            </Grid>
            <Grid item xs={6} md={2.5}>
              <img
                src={Apple}
                alt="Apple"
                style={{ width: "100%", height: "50%" }}
                classlocation="image"
              />
              <Item sx={{ height: "50%" }}>
                <h4>Apple.Inc </h4>
                <ul  style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                </ul>
              </Item>
            </Grid>
            <Grid item xs={6} md={2.5}>
              <img
                src={ENV}
                alt={ENV}
                style={{ width: "100%", height: "50%" }}
              />
              <Item sx={{ height: "50%" }}>
                <h4>{title[0]}</h4>
                {EnvTopic.map((EnvTopic, index) => {
                  return (
                    <ul key={index}
                      style={{
                        lineHeight: "80%",
                        listStyleType: "square",
                        textAlign: "left",
                      }}
                    >
                      <li key={index}>{EnvTopic}</li>
                    </ul>
                  );
                })}
              </Item>
            </Grid>
            <Grid item xs={6} md={2.5}>
              <img
                src={Socail}
                alt={Socail}
                style={{ width: "100%", height: "50%" }}
              />
              <Item sx={{ height: "50%" }}>
                <h4>{title[1]}</h4>
                {SocailTopic.map((SocailTopic, index1) => {
                  return (
                    <ul key={index1}
                      style={{
                        lineHeight: "80%",
                        listStyleType: "square",
                        textAlign: "left",
                      }}
                    >
                      <li key={index1}>{SocailTopic}</li>
                    </ul>
                  );
                })}
              </Item>
            </Grid>
            <Grid item xs={6} md={2.5}>
              <img
                src={Govr}
                style={{ width: "100%", height: "50%" }}
                alt={Govr}
              />
              <Item sx={{ height: "50%" }}>
                <h4>{title[2]}</h4>
                {GovTopic.map((GovTopic, index2) => {
                  return (
                    <ul key={index2}
                      style={{
                        lineHeight: "80%",
                        listStyleType: "square",
                        textAlign: "left",
                      }}
                    >
                      <li key={index2}>{GovTopic}</li>
                    </ul>
                  );
                })}
              </Item>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
export default IndexReport;


