import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import NoteContext from "context/PageContext";
import Header from "../CaseManagment/Header.js";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import withSnackbar from "../../components/Alert/withSnackbar";
import { format } from "date-fns";
import CaseAudit from "./CaseAudit.js";
import Loader from "./Loader.js";
import SectionButtons from "Compliance/Components/SectionButtons.js";
import CheckList from "Compliance/Components/CheckList.js";
import HashTag from "Compliance/Components/HashTag.js";
import Evidences from "Compliance/Components/Evidences.js";
import ChildCases from "Compliance/Components/ChildCases.js";
import IdentificationList from "Compliance/Components/IdentificationList.js";
import ParentCaseInfo from "Compliance/Components/ParentCaseInfo.js";
import SelectedChildCase from "Compliance/Components/SelectedChildCase.js";
import AI from "Compliance/Components/AI.js";
import Comments from "Compliance/Components/Comments.js";
import Drawer from "Compliance/Components/Drawer";
import "../../Compliance/styles/Case_Managment/CreateCase.css"
import ReasonDialog from "Compliance/Components/ReasonDialog.js";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsuranceParentCaseInfo from 'Insurance/CaseManagement/InsuranceParentCaseInfo.js'
import useTemplateType from "Insurance/CaseManagement/useTemplateType";
import {INSURANCE,sectionListChild,insurancesectionListChild,KYC} from "Insurance/CaseManagement/Config.js"
import Responsebox from "Insurance/CaseManagement/Responsebox.js";
const {
  getCaseById,
  updateParentCase,
  closeParentCase,
  getOpenChildcaseCount
} = require("../services/CaseFetch");



const ViewAllChildCases = ({ showSnackbar }) => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = format(currentDate, "dd-MM-yyyy");
  const {templateType,caseType} = useTemplateType();
  const [Subcases, setSubcases] = useState();
  const [loading, setLoading] = useState();
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filteredRows, setfilteredRows] = useState([]);
  const ContextData = useContext(NoteContext);
  const [selectedSubCase, setselectedSubCase] = useState({ CaseId: "" });
  const [parentDetails, setParentDetails] = useState();
  const [attachments, setAttachments] = useState([]);
  const [checkList, setCheckList] = useState();
  const [caseid, setCaseid] = useState();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [commentsdata, setCommentsdata] = useState([]);
  const [newcommentsData, setnewCommentsData] = useState([]);
  const [newreplyData, setreplyData] = useState([]);
  const [ErrMessage, setErrMessage] = useState('')
  const user = localStorage.getItem("AccountDetails")
  const parsedUser = user ? JSON.parse(user) : ''
  const [sectionType, setSectionType] = useState("BasicInfo");
  const [openReason, setOpenReason] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [Type,setType]=useState();

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    // filterdata();

    //setfilteredRows(data)
  };



  const checkForChildcsesOpen = async () => {
    var param = {
      param: {
        caseId: caseid
      }
    }
    try {
      var res = await getOpenChildcaseCount(param)
    }
    catch (e) {
      console.log(e)
      if (e.response.status == 403) {
        setErrMessage(e.response.data.message)
        setConfirmationOpen(true);
      }
    }
  }
  const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {ErrMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleOpenConfirmation = async () => {
    setConfirmationOpen(false);
    setOpenReason(true);

  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmWithReason = async (reason) => {
    const param = {
      param: {
        caseId: caseid,
        domain: ContextData?.UserSelected
      },
      data: {
        closeCaseReason: reason
      }
    }
    try {
      setConfirmationOpen(false);
      setLoading(true)
      var res = await closeParentCase(param);
      if (res?.status == 200) {
        showSnackbar(res?.data?.message || "Case closed successfully", "success");
        setLoading(false)
        setTimeout(() => {
          navigate("/caseManagement")
        }, 300);
      }
    }
    catch (e) {

      showSnackbar(e?.response?.data?.message || "Error in closing a case", "error")
      setLoading(false)
    }
    setOpenReason(false);
  };

  useEffect(() => {
    filterdata();
  }, [selectedStatus]);
  const filterdata = () => {
    setfilteredRows([]);

    if (selectedStatus === "All") {
      setfilteredRows(Subcases);
    } else {
      setfilteredRows(Subcases?.filter((row) => row.Status === selectedStatus));
    }
  };
 
  const fetchsubCasesData = async (caseid,cType) => {
    // Fetch data from your API based on the current page
    setLoading(true)
    //ContextData.setselectedCaseType(cassetype)
    try {
      const [case_detail] = await Promise.all([
        getCaseById(caseid,cType)
      ]);


      
      const details = case_detail.data.parent_case[0]
      setType(details?.label?.includes(INSURANCE)?INSURANCE:KYC)
      if(details?.label?.includes(INSURANCE)){
        setParentDetails({
          parentid: caseid,
          caseTitle:details?.caseTitle,
          dateOpened:details?.dateOpened,
          regulatoryAuthority:details?.regulatoryAuthority,
          description:details?.Description,
          response:details?.response
        })
        setSubcases(case_detail.data.AllSubcase);
        setfilteredRows(case_detail.data.AllSubcase);
        setselectedSubCase(case_detail.data.AllSubcase[0]);
      }

      else{
        console.log("details",details)
      setParentDetails({
        first_name: details.FirstName,
        last_name: details.LastName,
        customer_id: details.CustomerId,
        hash_tag: (details.HashTags!="") && (typeof(details.HashTags)==='string') ? JSON.parse(details.HashTags):details.HashTags,
        country: details.Country,
        state: details.State,
        current_case_worker: details.CurrentCaseWorker,
        current_case_worker_email: details.CurrentCaseWorkerEmail,
        creator: details.Creator,
        creator_email: details.CreatorEmail,
        expected_closure_date: details.ExpectedClosureDate,
        description: details.Description,
        identificationList:(details?.Identifications != '') && (typeof(details?.Identifications)==='string') ?JSON.parse(details?.Identifications):details.HashTags,
        parentid: caseid
      })
    }
      setIsShow(details?.Status == 'close' ? false : true)
      // setRows(JSON.parse(details.Identifications))
      // setCheckRows(case_detail.data.AllCheckList)
      setSubcases(case_detail.data.AllSubcase);
      setfilteredRows(case_detail.data.AllSubcase);
      setselectedSubCase(case_detail.data.AllSubcase[0]);
      setCheckList(case_detail.data.AllCheckList);
     


      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if needed
    }
  };

  useEffect(() => {

    if (ContextData?.UserSelected) {
      const params = new URLSearchParams(window.location.search);
      const caseid = params.get("case_id");
      fetchsubCasesData(atob(caseid),caseType);
      setCaseid(atob(caseid));

    }
  }, [ContextData?.UserSelected]);


  const updateCase = async () => {
    setLoading(true);
    var param = {
      param: {
        caseId: caseid,
        domain: ContextData.UserSelected
      },
      body: {
        attachments: files,
        comment: [...newcommentsData, ...newreplyData],
        response:parentDetails?.response||""
      }
    }
    try {
      var res = await updateParentCase(param)
      fetchsubCasesData(caseid,caseType)
      setnewCommentsData([])
      setFiles([])
      setreplyData([])
      setLoading(false)
    }
    catch (e) {
      console.log(e)
      showSnackbar(e.response.data.message || "Error in update case", "error")
    }
  }
  return (
    
<Grid container style={{ display: 'flex', flexDirection: 'column',backgroundColor:'#F5F5F6',}} className="App">

<Grid style={{ flex: '0 0 auto', position: 'sticky', top: 0, zIndex: 999,marginLeft:'15px' }}>
  <Header title="Parent Case" backarrow_visible={true} link="/caseManagement" />
</Grid>

<Grid style={{marginBottom:'10px',}}>
  <Grid style={{ height: 'calc(100vh - 180px)', overflowY: 'auto',}}>
    {loading ? (
      <Loader />
    ) : (
      <Grid display="flex" >
        <Grid px={2}  className="boxcard" style={{width: '70%'}}>
                <SectionButtons
                  list={Type===INSURANCE? insurancesectionListChild : sectionListChild}
                  sectionType={sectionType}
                  setSectionType={setSectionType}
                />
                <Grid style={{ marginTop: '10px' }}>
               {Type ===INSURANCE? <InsuranceParentCaseInfo data={parentDetails}/> :  <ParentCaseInfo data={parentDetails} /> }
                </Grid>
                {sectionType === "BasicInfo" && Type!=INSURANCE && 
                  <>

                    <Grid item style={{marginTop:'10px'}}>
                      <HashTag data={parentDetails} display={false} />
                    </Grid>
                    <Grid item>
                      <CheckList checkrows={checkList} display={false} />
                    </Grid>
                  </>
                }
                {Type === "Identification" && Type!=INSURANCE &&
                  <>
                    <Grid item>

                      <IdentificationList rows={parentDetails.identificationList} display={false} />
                    </Grid>
                  </>
                }
                {sectionType === "Evidences" &&
                  <>
                    <Grid item>

                      <Evidences caseid={caseid} base64FileList={files} setbase64FileList={setFiles} setNewRowData={false} showHeading={true}  show={isShow} />
                    </Grid>
                  </>
                }
                {sectionType === "ResponseBox" &&
                <Responsebox insurancedata={parentDetails} setinsurancedata={setParentDetails} edit={true}/>
                }
                {sectionType === "ChildCases" &&
                  <>
                  
                    <Grid item>
                      <ChildCases data={Subcases} setselectedSubCase={setselectedSubCase} selectedCaseID={caseid} />
                    </Grid>
                   
                  </>
                }

                {isShow && <Grid style={{ display: "flex", justifyContent: "center", margin: '15px 0px 10px 0px' }}>
                  {ContextData?.CaseRole?.name == "Case Reviewer" && <Button className="createcase" onClick={checkForChildcsesOpen}>Close</Button>}
                  <Button className="createcase" onClick={updateCase}>Save</Button>
                </Grid>
                }
                {isConfirmationOpen && (
                  <ConfirmationDialog
                    open={isConfirmationOpen}
                    onClose={handleCloseConfirmation}
                    onConfirm={handleOpenConfirmation}
                  />
                )}

                {openReason && <ReasonDialog
                  open={openReason}
                  onClose={() => setOpenReason(false)}
                  onConfirm={handleConfirmWithReason}
                />
                }

        </Grid>
        <Grid  style={{ width: '30%', marginTop: '10px', marginRight: '10px',}}>
              <Grid >
                <CaseAudit caseid={caseid ? caseid : ''} />
              </Grid>
              <Grid style={{ marginTop: "10px" }}>
                <AI />
              </Grid>
              <Grid style={{ marginTop: "10px" }}>
                {sectionType == 'ChildCases' &&
                  <>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography className="mainhead">Selected Item Info</Typography>
                      </AccordionSummary>
                      <AccordionDetails className="headingspace">
                        <SelectedChildCase selectedSubCase={selectedSubCase} />
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
              </Grid>
            </Grid>
      </Grid>
    )}
  </Grid>
</Grid>

<Grid style={{ flex: '0 0 auto', position: 'fixed', bottom: 0, width: '67%', zIndex: 999}}>
  <Drawer comment={<Comments setnewCommentsData={setnewCommentsData} newcommentsData={newcommentsData} is_new={false} setreplyData={setreplyData} newreplyData={newreplyData} show={isShow} caseid={caseid}/>} />
</Grid>
</Grid>
  );
}

export default withSnackbar(ViewAllChildCases, "Case Home", "success");