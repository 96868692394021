import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathpermission } from "../services/Fetch";
import ESG from "../../images/Env.jpg";
import Compliance from "../../images/compliance.jpg";
import Insurance from "../../images/Insurance.jpg";
import FormBuilder from "../../images/form-builder.jpg";
import Case_management from "../../images/Case_manegement.jpg";
import SettingsIcon from "@mui/icons-material/Settings";

const imagedict = {
  ESG: ESG,
  Compliance: Compliance,
  Insurance: Insurance,
  FormBuilder: FormBuilder,
  Case_management: Case_management,
};

const ItemName = ({ name }) => {
  return (
    <Typography
      variant="h6"
      align="center"
      sx={{
        fontWeight: "bold",
        margin: "8px 0", // Added margin for spacing
      }}
    >
      {name}
    </Typography>
  );
};

const ItemDescription = ({ description }) => {
  return (
    <Typography
      variant="body2"
      align="center"
      sx={{
        color: "gray",
        marginBottom: "8px", // Added margin for spacing
      }}
    >
      {description}
    </Typography>
  );
};

const ItemBox = ({ imageName }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 200,
        backgroundImage: `url(${imagedict[imageName]})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

function GlobalDashBoard() {
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getSubscriptionList = async (email) => {
    try {
      if (email.toLowerCase() === "ankurjit.singh@jktech.com") {
        localStorage.setItem("permittedPath", "Insurance");
        localStorage.setItem("pathSelected", "/ESGHome");
        navigate("/ESGHome");
      } else {
        const data = await pathpermission(email);
        setSubscription(data.filter((item) => item.name !== "Administration"));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const accountDetails = JSON.parse(localStorage.getItem("AccountDetails"));
      const email = accountDetails?.endUser?.mail;

      if (email) {
        clearInterval(intervalId);
        getSubscriptionList(email);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const handleClick = (name, path) => {
    try {
      if (name) {
        if (
          name === "Regulatory Filling tab" ||
          name === "ESG" ||
          name === "Compliance"
        ) {
          navigate("/ESGHome");
        } else {
          navigate(path);
        }
        if (name === "Regulatory Filling tab") {
          localStorage.setItem("permittedPath", "Insurance");
          localStorage.setItem("pathSelected", "/ESGHome");
        } else {
          localStorage.setItem("permittedPath", name);
          localStorage.setItem("pathSelected", path);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box minHeight="100vh" px={2}>
      <Box display="flex" justifyContent="flex-end" py={2}>
        <IconButton
          onClick={() => {
            handleClick("Administration", "/AdminPanel");
          }}
          color="primary"
        >
          <SettingsIcon />
        </IconButton>
      </Box>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          wrap="wrap"
        >
          {subscription &&
            subscription.map((item, index) => (
              <Grid container item xs={12} sm={6} md={4} lg={2} key={index}>
                <Paper
                  elevation={2}
                 
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: item.path ? "pointer" : "not-allowed",
                    filter: item.path ? "none" : "blur(2px) grayscale(100%)",
                    width: "100%",
                    height: 450, // Fixed height for all Paper components
                    borderRadius: "16px",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: item.path ? "scale(1.05)" : "none",
                    },
                    padding: 2,
                    boxSizing: "border-box",
                    position: "relative",
                  }}
                >
                  <ItemBox imageName={item.imageName} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexGrow: 1,
                      width: "100%",
                      padding: 2,
                      boxSizing: "border-box",
                    }}
                  >
                    <ItemName name={item.name} />
                    <ItemDescription description={item.description} />
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#25A392", width: "100%" }}
                      onClick={() => handleClick(item.name, item.path)}
                    >
                      Launch
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
}

export default GlobalDashBoard;
