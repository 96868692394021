import React, { useState,useEffect,useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  MenuItem,
  InputLabel
} from '@mui/material';
import Select from "react-select";
import NoteContext from "context/PageContext";
import { getUserList,getCheckList } from 'Compliance/services/CaseFetch';
import Loader from 'Compliance/CaseManagment/Loader';


const CreateChildCasePopup = ({ open, handleClose, handleSave,handleChange,checkdata,load }) => {
  const ContextData = useContext(NoteContext);
  const today = new Date().toISOString().split('T')[0];
    const [caseworkers, setcaseworkers] = useState([]);
    const [loading, setLoading] = useState([]);
    const [checkData,setCheckData]=useState([]);
 
    useEffect(() => {
    
        fetchData();
    
    }, [load]);
  
    const fetchData = async () => {
      try {
        setLoading(true);
        const [userslist,chdata] =
          await Promise.all([
            getUserList(),
            getCheckList(ContextData.selectedCaseType),
          ]);
  
        setcaseworkers(userslist.data);
       
        var checkdata = chdata?.data?.values.map((chktype) => ({
          value: chktype,
          label: chktype,
        }));
        setCheckData(checkdata);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("unable to fetch data", "error");
      }
    };
  return (
    <>
    {load ?
    
    <Loader/>
    :
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Child Case</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
                  <InputLabel className="commonLabel">Sub Category</InputLabel>
              

              <Select
             label="Subcategory"
              name="subcategory"
                  value={checkData?.find(option => option.value === checkdata.subcategory)}
                  onChange={(e) => {
             
                    handleChange(e,"subcategory")
                    
                  }}
                  options={checkData}
                  isSearchable={true}
                  //placeholder="Select a state..."
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                     // borderColor: errors.type ? "red" : "",
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                      position: 'absolute', 
                    }),
                  }}
                 
                />
               
                </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Case Title"
              name="caseTitle"
              value={checkdata.caseTitle}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              size='small'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Date Opened"
              name="date_conducted"
              type='date'
              value={checkdata.date_conducted}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              size='small'
              inputProps={{
                min: today, 
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Date Closed"
              name="expected_closure_date"
              type="date"
              value={checkdata.expected_closure_date}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              size='small'
              inputProps={{
                min: checkdata.date_conducted, 
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Department"
              name="department"
              value={checkdata.department}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              size='small'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Current Case Worker"
              name="current_case_worker_email"
              select
              value={checkdata.current_case_worker_email+","+checkdata.current_case_worker}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              size='small'
              >

                 {caseworkers &&
                      caseworkers?.map((item) => (
                        <MenuItem
                          key={item.email}
                          value={item.email+ "," +item.name}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
              </TextField>
            
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Description"
              name="exception_description"
              type="textarea"
              multiline="4"
              value={checkdata.exception_description}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
}
    </>
  );
};

export default CreateChildCasePopup;
