import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {IconButton} from '@mui/material';
import "../styles/Forms_Styles/Form_Url.css"
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Button} from '@mui/material';
import { Box } from '@mui/material';
import { useContext } from "react";
import NoteContext from "context/PageContext";

const Form_Url = ({onChange,getAnswerDisable}) => {
  const {Answer}=useContext(NoteContext);
  const [Error,setError]=useState(false);
  const [Url,setUrl]=useState('');
  const UrlLists=typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : []
  const [HelperText,setHelperText]=useState('');

  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

  const validateUrl = (value) => {
    // Regular expression pattern to validate URLs
    if (value.length===0 || !value.match(urlPattern)) {
      // If the value doesn't match the pattern, show an error
      setError(true)
      return 'Invalid URL';
    }
    setError(false);
    return 'Valid URL'; // No error
  };

  const handleAddUrl=()=>{
    const isvalid= validateUrl(Url);
    if(isvalid==='Valid URL'){
      if(!UrlLists.includes(Url)){
      onChange([...UrlLists,Url])
      setUrl('');
      setHelperText('')
      }else{
        setHelperText('Url already exits')
      }
    }else{
      setHelperText('Enter a Valid Url')
    }
  }

  const handleDeleteUrl =(item)=>{
   onChange(UrlLists.filter((url) => url !== item))
  }


  return (
    <Box>
      <Box sx={{ alignItems: "center",alignContent:'center',justifyItems:'center' }}>
      <TextField
            size="small"
            label="Enter Url"
            type="url"
            variant="outlined"
            value={Url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            error={Error} // Set this to true if there's an error
            helperText={HelperText} // Helper text to display the error message
            disabled={getAnswerDisable}
          />
          {getAnswerDisable &&
      <Button
        variant="outlined"
        onClick={handleAddUrl}
        size="medium"
        sx={{backgroundColor: '#E9F6F4',
        border: '1px solid #CBE5E1',
        textTransform:"capitalize",
        color:'#25A392',
        borderRadius: '6px',
        padding:"5px 10px",
        '&:hover':{
          backgroundColor: '#E9F6F4',
          border: '1px solid #CBE5E1',
        },
      marginLeft:'10px'
      }}
        startIcon={<AddIcon />}
        color='success'
      >
       Add
      </Button>
}
    </Box>
    <Box>
        <List>
          {UrlLists.length !== 0 ? (
            UrlLists.map((url, index) => {
              return (
                <ListItem
                   sx={{borderRadius:'5px',marginBottom:'5px',border:"1px solid lightgrey"}}
                  key={index}
                  secondaryAction={
                      <IconButton edge="end" aria-label="delete">
                        <DeleteOutlinedIcon
                          onClick={() => handleDeleteUrl(url)}
                        />
                      </IconButton>
                  }
                >
                  <ListItemText primary={url} />
                </ListItem>
              );
            })
          ) : (
            <ListItem  sx={{borderRadius:'5px',marginBottom:'5px',border:"1px solid lightgrey"}}>
              <ListItemText primary={"No Url's found"} />
            </ListItem>
          )}
        </List>
        </Box>
    </Box>
  );

};


export default Form_Url;