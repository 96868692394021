import React,{useEffect, useState} from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAllCases,openedCases,closedCases } from "Compliance/services/CaseFetch";
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

import "../styles/Compliance/ComplianceTable.css"


export default function ComplienceTable() {

  const[currentPage, setCurrentPage]= useState(1);
  const itemsperPage =5
  const startIndex =(currentPage-1)*itemsperPage;
  const endIndex= startIndex+itemsperPage;
  
  const [Info,setInfo]=React.useState(null);
  console.log("INFO",Info)
   const currentData=Info &&Info.slice(startIndex,endIndex);
 

  


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const[closureVlaue, setClosureValue]=useState("All")
  const history = useNavigate();

   useEffect(() => {
    const fetchData = async () => {
     
      try {
        if (closureVlaue==="Close"){
          const data = await closedCases (); // Await the data directly
          console.log("Table data",)
          setInfo(data.data.data)
        }
        else if(closureVlaue==="Open"){
          const data = await openedCases (); // Await the data directly
          console.log("Table data",)
          setInfo(data.data.data)
        }
        else if(closureVlaue==="All"){
          const data = await getAllCases (); // Await the data directly
          console.log("Table data",)
          setInfo(data.data.data)
        }
        
      } catch (error) {
        console.error(error);
      }
    };
    
   
  
    fetchData();
  }, [page,closureVlaue]);

  return (
  

<div>
  <div>
  <div style={{textAlign:"center"}}>
   <b><h1>Case Complience</h1></b> 
  </div>
  <div>
  <Box
      sx={{
        textAlign:'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup variant="outlined" aria-label="outlined button group" >
        <Button onClick={()=>setClosureValue("Open")}>Open</Button>
        <Button  onClick={()=>setClosureValue("Close")}>Close</Button>
        <Button  onClick={()=>setClosureValue("All")}>All</Button>
      </ButtonGroup>
      {console.log("closureValue", closureVlaue)}
   
    </Box>
  </div>
  </div>
  
<div>
      <table>
      <thead>
        <tr>
        <th>Category</th>
        <th>Type</th>
        <th>Open Date</th>
        </tr>
      </thead>
      <tbody>
        
        {currentData&& currentData.map((item,index)=>(
          <tr key={index}>
          <td>{item.CaseCategory}</td>
          <td>{item.CaseSubCategory}</td>
          <td>{item.CaseDate}</td>
          </tr>
        )
        )}
      </tbody>
      </table>
      <div style={{float:"right",paddingTop:"10px"}}>
        <button onClick={()=>setCurrentPage(currentPage-1)}
        disabled={currentPage===1}> Previous</button>
         <button onClick={()=>setCurrentPage(currentPage+1)}
        disabled={Info &&endIndex>=Info.length}> next</button>
      </div>
    </div>

</div>
    
  );
}