import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
    makeStyles
  } from "@mui/styles";
import { useContext } from "react";
import NoteContext from "context/PageContext";
import { getLogs } from "Compliance/services/CaseFetch";
import { useNavigate } from "react-router-dom";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import Loader from "./Loader";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import { StepConnector } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "Compliance/styles/Case_Managment/Case_Audit.css"

const CompletedStepIcon = styled(CircleIcon)(({ theme }) => ({
  color: "#2076B0",
  fontSize:'14px',
  marginLeft:'8px',
  //marginTop:'3px'
}));
 
const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    minHeight: "10px",
    borderLeft: "3px solid #98C0DB !important",
    // marginLeft:'1px'
  },
  "& .MuiStepConnector-icon": {
  //  marginLeft: "-1px" // Adjust this value as needed to control the spacing
  }
}));
const labelProps = {
  StepIconComponent:CompletedStepIcon
     
};
const CaseAudit = ({ caseid,refreshTimestamp,showSnackbar }) => {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);

  useEffect(() => {
    if(ContextData.UserSelected){
    fetchLogs();
    }
  }, [refreshTimestamp]);
  const fetchLogs=async()=>{
    try {
      setLoading(true);
      // setUserInfo(auth.currentUser);
      const [logsdata] = await Promise.all([
    
        getLogs({caseId:caseid,domain:ContextData.UserSelected}),
      
      ]);
      setLogsData(logsdata?.data)
      setLoading(false);
      
    } catch (error) {
      showSnackbar(error?.response?.data.message || "Error while fetching comments", "error");
      setLoading(true);
    }
  }

  return (
    <Grid>
    <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    
                  >

            <Typography className="mainhead" >Case Audit</Typography>
            </AccordionSummary>
            <AccordionDetails  style={{    }}>
      {!logsData.length && <Typography textAlign="center">No Case Audit</Typography>}
      <Box height={200} overflow="auto"  className="headingspace">
      <Stepper orientation="vertical"
      connector={<CustomStepConnector />}
      >
        {logsData.map((step, index) => (
          
          <Step key={index} active expanded>
            <StepLabel className="stepperheading" {...labelProps}>{step.heading}</StepLabel>
            <Typography className="steppertime">{step.TimeStamp}</Typography>
            <StepContent>
              <Typography className="steppermessage">{step.Message}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      </Box>
      </AccordionDetails>


   </Accordion>
 </Grid>
  );
};

export default withSnackbar(CaseAudit, "Case Row Home", "success");