import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../styles/Home/ESGHome.css";
import { Link, useNavigate } from "react-router-dom";
import { Box, Card, Grid, Typography, Button } from "@mui/material";

import { GetCountryList } from "../../ESG/services/GraphFetch";
import FormControl from "@mui/material/FormControl";
import PaperComponent from "components/PaperComponent";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chart from "react-apexcharts";
import CloseIcon from "@mui/icons-material/Close";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import "../styles/graph/GraphStyle.css";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  GetGraphdata,
  GetSocaildata,
  GETGraphRender,
  ghgEmissionBySector,
  SectorGraphRender
} from "../../ESG/services/GraphFetch";
import ReactApexChart from "react-apexcharts";

const SectorGraph = (props) => {
  const [Isloaded, setIsloading] = useState(false);
  const [GraphDataset, setGraphDataset] = useState();
  const [Year, setyear] = useState();
  const [CountryName,setCountryName]=useState("AFG");

  const ghgEmissionBySectordata = async () => {
    try {
      const data = await await ghgEmissionBySector(props.country, props.sector);
      //console.log(data.data._items);
      const valuesArray = [];
      const keysArray = [];
      const yearsArray = [];
      if( data.data._items.length>0){
      data.data._items.forEach((item) => {
        const values = [];
        const keys = [];
        let year = null;

        for (const key in item) {
          if (
            Array.isArray(item[key]) &&
            !["Year", "Code", "Entity", "pk"].includes(key)
          ) {
            // Extract values from arrays
            values.push(item[key][0]);
            keys.push(key);
          }
          if (key === "Year") {
            year = item[key][0];
          }
        }

        valuesArray.push(values);
        keysArray.push(keys);
        yearsArray.push(year);
      });

      // console.log("Values Array:", valuesArray);
      // console.log("Keys Arraydisplayed:", keysArray[0]);
      // console.log("Years Array:", yearsArray);
      const result = [];

      for (let i = 0; i < keysArray[0].length; i++) {
        const name = keysArray[0][i];
        const data = valuesArray.map((subArray) => parseFloat(subArray[i]));
        result.push({ name, data });
      }

      //console.log(result);
      setGraphDataset(result);
      setyear(yearsArray);
      setIsloading(true);
    }
    else{
      setGraphDataset([]);
      setIsloading(true);
    }
    } catch (error) {
      console.log(error);
    }
  };
  const chartData = {
    options: {
      chart: {
        id: "basic-line",
      },
      xaxis: {
        categories: Year,
      },
      noData: {
        text: "No Data",
        align: "centre",
        verticalAlign: "middle",
        offsetX: 10,
        offsetY: 10,
        style: {
          color: "red",
          fontSize: "20px",
          fontFamily: "Microsoft YaHei",
        },
      },
    },
    series: GraphDataset,
  };

  useEffect(() => {
    ghgEmissionBySectordata();
  }, [props.country,props.sector]);
  return (
    <>
      {Isloaded && (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
          />
      )}
    </>
  );
};

function WorldGraph(props) {
  const [value1, setValue1] = React.useState([1960, 2021]);
  const [Year, setYear] = useState();
  const [Datavalue, setDatavalue] = useState([]);
  const [Isloading, setIsloading] = useState(false);
  const [Name, setName] = useState("worldData");
  const [Countrylist, Setcountry] = useState([]);
  const [inputValue, setinputValue] = useState(props.Country);
  const [Gender, setGender] = useState();
  let YearRange = [];
  // let countryarray=[];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Istrue, setIstrue] = useState(false);
  const [FromYear, setFromYear] = useState();
  const [EndYear, setEndYear] = useState();
  const [inputValue1, setinputValue1] = useState();

  const GenderList = ["male", "female"];

  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };
  //code for setting year filter for graph
  const setYearforfilter = async () => {
    await GetSocaildata(props.Name).then((Data) => {
      setFromYear(Object.keys(Data.data[0])[0]);
      setEndYear(
        Object.keys(Data.data[0])[Object.keys(Data.data[0]).length - 1]
      );
    });
  };
  // console.log(props.Country);
  //code for fetching backend data for graph
  useEffect(() => {
    if (props.Country !== undefined) {
      setinputValue(props.Country);
    }
    if (props.GenderDep) {
      //code for fetching backend data for graph with Gender params
      GetSocaildata(props.Name, inputValue, Gender, value1[0], value1[1]).then(
        (Response) => {
          const y_axis_values = Object.values(Response.data[0]).map((Value) => {
            return Value;
          });
          setYear(Object.keys(Response.data[0]));
          setYearforfilter();
          Object.keys(Response.data[0]).forEach((Element) => {
            let a = { value: Number(Element), label: Element };
            YearRange.push(a);
          });
          if(y_axis_values.length)
          {
          setDatavalue(y_axis_values);
          }
        else
        {
        setDatavalue([]);
        }
          setName(Response.name);
          //console.log(Response.data);
          setIsloading(true);
          setIstrue(true);
        }
      );
    } else {
      //code for fetching backend data for graph without Gender params
      try {
        GetGraphdata(props.Name, inputValue, value1[0], value1[1]).then((x) => {
          setYear(Object.keys(x.data[0]));
          //console.log("Object.keys(x.data[0])",Object.keys(x.data[0]))
          Object.keys(x.data[0]).forEach((Element) => {
            YearRange.push({ value: Number(Element), label: Element });
          });
          setYearforfilter();
          if(Object.values(x.data[0]).length)
          {
          setDatavalue(Object.values(x.data[0]));
          setName(x.name);
          setIsloading(true);
          setIstrue(true);
          }
        else
        {
        setDatavalue([])
        setName(x.name);
        setIsloading(true);
        setIstrue(true);
        }
         
        });
      } catch (error) {
        console.error(error);
      }
      
    }

    FtechCountryList();
  }, [inputValue, Gender, value1, props.Name, props.Country]);

  const FtechCountryList = async () => {
    GetCountryList().then((response) => {
      //console.log(Object.values(response[0]));
      Setcountry(response);
      // console.log(response);
    });
  };
  // code for displaying pop up graph
  let state = {
    options: {
      colors: [props.Gcolour],
      chart: {
        id: "basic-bar",
      },
      noData: {
        text: "There is no data available for this country",
        align: "centre",
        verticalAlign: "middle",
        offsetX: 10,
        offsetY: 10,
        style: {
          color: "red",
          fontSize: "20px",
          fontFamily: "Microsoft YaHei",
        },
      },
      dataLabels: {
        enabled: false,
        floating: false,
      },
      yaxis: {
        show: true,
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      xaxis: {
        categories: Year,
        tickAmount: 4,
      },
    },
    stroke: { show: true, curve: "stepline", lineCap: "butt", width: 0.5 },
    series: [
      {
        name: Name,
        data: Datavalue,
      },
    ],
  };

  //code for normal graph page
  let data1 = {
    options: {
      colors: [props.Gcolour],
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
       
      },
      noData: {
        text: "No Data",
        align: "centre",
        verticalAlign: "middle",
        offsetX: 10,
        offsetY: 10,
        style: {
          color: "red",
          fontSize: "20px",
          fontFamily: "Microsoft YaHei",
        },
      },
      dataLabels: {
        enabled: false,
        floating: false,
      },
      legend: {
        show: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },

      yaxis: {
        show: true,
        labels: {
          formatter: function (value) {
            if (
              props.Name === "Unemployment" ||
              props.Name === "Employers" ||
              props.Name === "LaborTaxAndContributions"
            ) {
              return value.toFixed(2) + props.SiUint;
            } else {
              return value / props.denominator + props.SiUint;
            }
          },
        },
      },
      stroke: { show: true, curve: "smooth", lineCap: "butt", width: 0.5 },
      xaxis: {
        show: true,
        axisTicks: {
          show: false,
        },
        categories: Year,
        tickAmount: 4,
      },
    },
    series: [
      {
        name: Name,
        data: Datavalue,
      },
    ],
  };
  return (

      <Grid container direction={'column'}>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton
            variant="outlined"
            onClick={handleOpen}
            aria-label="delete"
            size="small"
            className="IconButton"
          >
            <ZoomOutMapIcon className="ExpandIcon" />
          </IconButton>
        </Grid>
       <Grid item>
        <Typography align="center" className="title">
          {Name}
        </Typography>
        </Grid>
        <Grid item>
          <Chart
          options={data1.options}
          series={data1.series}
          type="area"
          height={300}
        /></Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="ModelBox">
            <Typography
              component={"span"}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              <IconButton
                onClick={handleClose}
                aria-label="delete"
                size="small"
                className="IconButton"
                variant="outlined"
              >
                <CloseIcon />
              </IconButton>
            </Typography>
            <div>
              <div>
                {Isloading ? (
                  <>
                    <h4 align="center" className="title">
                      {Name}
                    </h4>
                    <Grid container>
                      <Autocomplete
                        id="standard-basic"
                        options={Countrylist}
                        getOptionLabel={(option) => option.CountryName ?? ""}
                        isOptionEqualToValue={(option) =>
                          option.CountryName ?? ""
                        }
                        value={
                          Countrylist?.find(
                            (country) => country.CountryCode === props.Country
                          ) || null
                        }
                        onChange={(e, newCountry) => {
                          props.setCountryName(newCountry?.CountryCode);
                          setinputValue(() => {
                            return newCountry?.CountryCode;
                          });
                        }}
                        className="CountryBox"
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Country" />
                        )}
                      />

                      <Divider orientation="vertical" flexItem></Divider>
                      {props.GenderDep && (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={GenderList}
                          value={inputValue1}
                          defaultValue={null}
                          onInputChange={(event, newInputValue1) => {
                            setGender(newInputValue1);
                          }}
                          className="GenderBox"
                          renderInput={(params) => (
                            <TextField {...params} label="Gender" />
                          )}
                        />
                      )}
                    </Grid>
                    {Istrue && (
                      <Chart
                        options={state.options}
                        series={state.series}
                        type="bar"
                        width={"300%"}
                        height={"150%"}
                      />
                    )}

                    <Box sx={{ width: 250, m: 2 }}>
                      <Slider
                        value={value1}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                        step={1}
                        marks={YearRange}
                        min={Number(FromYear)}
                        max={Number(EndYear)}
                      />
                    </Box>
                  </>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </Grid>

  );
}

const ESGGraphDashBoard = () => {
  const navigate = useNavigate();
  const [Isloaded, setIsLoaded] = useState(false);
  const [dataset, setdataset] = useState([]);
  const [countryList, setcountryList] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState("");
  const [Topiclist, setTopiclist] = useState();
  const [CountryName, setCountryName] = useState("");
  const [GraphDisplaydata, setGraphDisplaydata] = useState({
    name: "MethaneEmissions",
    denominator: 1,
    siUnit: "%",
    gColour: "#660033",
    genderDependent: false,
  });

  const [selectedEmission, setSelectedEmission] = useState(
    "methane-emissions-by-sector"
  );
  
  const [SectorList, setSectorList] = useState("");
  const [selectedSectorName, setselectedSectorName] = useState("methane-emissions-by-sector");
  const GetCountryListData = async () => {
    const data = await GetCountryList();
    setcountryList(data);
    setCountryName(data[0].CountryCode)
  };

  const GetSecotrListData = async () => {
    try {
      const data = await SectorGraphRender();
      setSectorList(data?.data?.values);
    } catch (error) {
      console.log(error);
    }
   
  };

  const GetTopicsForDropDown = async () => {
    const topicArray = [];
    const data2 = await GETGraphRender();
    // console.log(data2.data);
    setdataset(data2.data);
    data2.data.Environment.map((res, index) => {
      // console.log(res.name);
      topicArray.push(res.name);
    });
    data2.data.Governance.map((res, index) => {
      // console.log(res.name);
      topicArray.push(res.name);
    });
    data2.data.Social.map((res, index) => {
      // console.log(res.name);
      topicArray.push(res.name);
    });
    //console.log(topicArray);
    setTopiclist(topicArray);
    setIsLoaded(true);
  };
  const handleEmissionChange = (event) => {
    setSelectedEmission(event.target.value);
  };

  useEffect(() => {
    GetCountryListData();
    GetTopicsForDropDown();
    GetSecotrListData();
  }, [selectedEmission]);

  const handleChangeforTopic = (event) => {
    const selectedName = event.target.value;
    const selectedSector = SectorList.find(e => e.name === selectedName);
    setselectedSectorName(selectedSector?selectedSector.id:'')
    setSelectedDataset(selectedName);
    function findItemByName(name) {
      for (const category in dataset) {
        const categoryData = dataset[category];
        const foundItem = categoryData?.find((item) => item.name === name);
        if (foundItem) {
          return foundItem;
        }
      }
      return null; // Return null if not found
    }

    // Example usage:
    //const itemName = "MethaneEmissions";
    const foundItem = findItemByName(event.target.value);

    if (foundItem) {
      //console.log("Found Item:", foundItem);
      setGraphDisplaydata(foundItem);
    } else {
      console.log("Item not found.");
    }
  };

  const HandleSetcountry = (event) => {
    // console.log(event);
    setCountryName(event);
  };
  return (
    <React.Fragment>
      <PaperComponent>
        <Grid container p={2}>
          <Grid item container mb={1} sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography className="Title_Name">Quick Review</Typography>
            </Grid>
            <Grid item>
              <Button
                size="small"
                sx={{
                  border: "1px solid #BAD5E7",
                  backgroundColor: "#E9F1F7",
                  paddingX: "15px",
                  borderRadius: "15px",
                  marginLeft: "10px",
                  color: "#2076B0",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/ESG-DashBoard")}
              >
                View All
              </Button>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Autocomplete
                id="country-select-demo"
                fullWidth
                size="small"
                sx={{ width: 200 }}
                options={countryList}
                autoHighlight
                getOptionLabel={(option) => option.CountryName}
                isOptionEqualToValue={(option, value) =>
                  option.CountryCode === value.CountryCode
                }
                onChange={(_, newValue) => {
                  HandleSetcountry(newValue?.CountryCode ?? "");
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Country" />
                )}
              />
            </Grid>
            <Grid item>
              <FormControl>
                <Select
                  value={selectedDataset}
                  onChange={handleChangeforTopic}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value="" disabled>
                    Select Dataset
                  </MenuItem>
                  {Topiclist?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ fontSize: "20px" }}>
                <Link
                  to="/RealTimeData"
                  // style={{ textDecoration: "none", marginLeft: "10px" }}
                  style={{ color: "#2076B0", fontSize: "14px" }}
                >
                  Or Select from List
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1} my={1}>
            <Grid item xs={12} md={8}>
              <Card variant="outlined">
                <SectorGraph
                  sector={selectedSectorName}
                  country={CountryName}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  padding: "5px",
                  border: "1px solid #D5D3F9",
                  borderRadius: "9px",
                }}
              >
                <WorldGraph
                  // key={index}
                  Name={GraphDisplaydata?.name}
                  denominator={GraphDisplaydata?.denominator}
                  SiUint={GraphDisplaydata?.siUnit}
                  Gcolour={GraphDisplaydata?.gColour}
                  GenderDep={GraphDisplaydata?.genderDependent}
                  Country={CountryName}
                  setCountryName={setCountryName}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </PaperComponent>
    </React.Fragment>
  );
};

export default ESGGraphDashBoard;
