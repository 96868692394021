import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    Typography,
    InputLabel,
    TextField,
    Chip
  } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import InfoIcon from '@mui/icons-material/Info';
import withSnackbar from "../../components/Alert/withSnackbar"; 


const CaseStatistics=({graphstats,statdata,openedCases,closedCases,showSnackbar})=>{
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
return(
    <>
 <Grid>
          <Grid>
          <Typography className="heading">Cases Stats</Typography>
          </Grid>
        { statdata[0]>0?<Grid className="statbox">
      <Grid>
       <ReactApexChart
                options={{
                  ...graphstats,
                  chart: {
                    ...graphstats.chart,
                    width: windowWidth * 0.9,
                    height: 250,
                  },
                }}
                series={statdata}
                type="donut"
                height={250}

              />
      </Grid>
      <Grid style={{marginRight:'15px'}}>
       {/* <Grid>
          <h6 className="caseslabel">Total cases</h6>
          <h6 className="totalcases">{totalCases}</h6>
        </Grid>*/}
        <Grid>
          <Typography className="caseslabel">Open cases</Typography>
          <Typography className="opencases">{openedCases}</Typography>
        </Grid>
        <Grid>
          <Typography className="caseslabel">Close cases</Typography>
          <Typography className="closecases">{closedCases}</Typography>
        </Grid>
      </Grid>
      </Grid>:
      <Grid style={{ paddingTop: "10px", margin: "0px 10px" }}>
              <Grid className="rejectbox" >
                <InfoIcon className="rejectHeading" style={{ fontSize: '32px', marginRight: '10px' }}></InfoIcon>
                <Grid>
                  <Typography className="rejecttxt">No Data</Typography>
                </Grid>
              </Grid>
            </Grid>}
        </Grid>
    </>
)
}


export default withSnackbar(CaseStatistics, "AI", "success");