import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import IndexReport from "ESG/Report/GRI/IndexReport";
import CDReport from "ESG/Report/GRI/CDReport";
import EmpReport from "ESG/Report/GRI/EmpReport";
import "../styles/Report/Report.css";
import NominationReport from "ESG/Report/GRI/NominationReport";
import { ReportData  } from "../services/ReportFetch";
import GovReport from "ESG/Report/GRI/GovReport";
import RestatementReport from "ESG/Report/GRI/RestatementReport";
import GovernceBody from "ESG/Report/GRI/GovernceBody";
import Delegation from "ESG/Report/GRI/Delegation";
import ConflictofInterest from "ESG/Report/GRI/ConflictofInterest";
import ExternalAssurance from "ESG/Report/GRI/ExternalAssurance";
import Remuneration from "ESG/Report/GRI/Remuneration";
import Remuneration2 from "ESG/Report/GRI/Remuneration2";
import PolicyCommitments from "ESG/Report/GRI/PolicyCommitments";
import Remediate from "ESG/Report/GRI/Remediate";
import Regulations from "ESG/Report/GRI/Regulations";
import MaterialTopic from "ESG/Report/GRI/MaterialTopic";
import MaterialTopic1 from "ESG/Report/GRI/MaterialTopic1";
import ManagementMT from "ESG/Report/GRI/ManagementMT";
import ManagementMT1 from "ESG/Report/GRI/ManagementMT1";
import Financial from "ESG/Report/GRI/Financial";
import Obligations from "ESG/Report/GRI/Obligations";
import Infrastructure from "ESG/Report/GRI/Infrastructure";
import Weight from "ESG/Report/GRI/Weight";
import Dischargerelated from "ESG/Report/GRI/Dischargerelated";
import NewHiring from "ESG/Report/GRI/NewHiring";
import Parentalleave from "ESG/Report/GRI/Parentalleave";
import HazardIdentification from "ESG/Report/GRI/HazardIdentification";
import OccupationalHealth from "ESG/Report/GRI/OccupationalHealth";
import WorkerHealth from "ESG/Report/GRI/WorkerHealth";
import AverageHours from "ESG/Report/GRI/AverageHours";
import Labeling from "ESG/Report/GRI/Labeling";
import Labeling1 from "ESG/Report/GRI/Labeling1";
import Labeling2 from "ESG/Report/GRI/Labeling2";


const OpenPage = (props) => {
  const { children, page, index } = props;
  return (
    <div hidden={page !== index}>{page === index && <Box>{children}</Box>}</div>
  );
};
const Paginations = () => {
  const [ReportDataset,setReportDataset]=useState([]);
  const [activePage, setActivePage] = useState(1);
  const [Isloading,setIsloading]=useState(false);
 
  useEffect(()=>{
    ReportData().then((x)=>{
      setReportDataset(x.data);
      setIsloading(true);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  

  
  return (
    <>
    {Isloading ? (
      <div style={{marginTop:"65px"}}>
         <Box className="PageBox">
        <OpenPage page={activePage} defaultPage index={1}>
          <IndexReport  data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={2}>
          <CDReport  data={ReportDataset} />
        </OpenPage>
        <OpenPage page={activePage} index={3}>
          <EmpReport data={ReportDataset} />
        </OpenPage>
        <OpenPage page={activePage} index={4}>
          <NominationReport data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={5}>
          <GovReport data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={6}>
          <RestatementReport data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={7}>
          <GovernceBody data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={8}>
          <Delegation data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={9}>
          <ConflictofInterest data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={10}>
          <ExternalAssurance data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={11}>
          <Remuneration data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={12}>
          <Remuneration2 data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={13}>
          <PolicyCommitments data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={14}>
          <Remediate data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={15}>
          <Regulations data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={16}>
          <MaterialTopic data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={17}>
          <MaterialTopic1 data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={18}>
          <ManagementMT data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={19}>
          <ManagementMT1 data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={20}>
          <Financial data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={21}>
          <Obligations data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={22}>
          <Infrastructure data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={23}>
          <Weight data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={24}>
          <Dischargerelated data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={25}>
          <NewHiring data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={26}>
          <Parentalleave data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={27}>
          <HazardIdentification data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={28}>
          <OccupationalHealth data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={29}>
          <WorkerHealth data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={30}>
          <AverageHours data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={31}>
          <Labeling data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={32}>
          <Labeling1 data={ReportDataset}/>
        </OpenPage>
        <OpenPage page={activePage} index={33}>
          <Labeling2 data={ReportDataset}/>
        </OpenPage>
        </Box>
          <Pagination
            count={33}
            page={activePage}
            onChange={(event, newPage) => setActivePage(newPage)}
            size="medium"
            color="secondary"
            variant="outlined"
          />
       
      </div>
      ) : (
         <div className="loaders">Loading...</div>
        
    )}
</>
  );
};
export default Paginations;

