import React, { useEffect ,useState} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function TwentyOnePage(props) {
    const [Questions, SetQuestions] = useState();
    const [Answer, setAnswer] = useState();
    const [Isloading, setIsloading] = useState(false);
    const [QuestionNum, setQuestionNumber] = useState();

    useEffect(()=>{
        const FetchData = () => {
            const questions_array_b = [];
            const Answer_array_b = [];
            const QuestionNumber = [];
            //getting section 1b question in single array
      
            for (
              let i = 0;
              i < Object.keys(props.data.reportData[ "Civil Judicial Action Disclosure Reporting Page"]).length;
              i++
            ) {
              questions_array_b.push(
                Object.keys(props.data.reportData[ "Civil Judicial Action Disclosure Reporting Page"])[i]
              );
              Answer_array_b.push(
                Object.values(props.data.reportData[ "Civil Judicial Action Disclosure Reporting Page"])[i].answer
              );
              QuestionNumber.push(
                Object.values(props.data.reportData[ "Civil Judicial Action Disclosure Reporting Page"])[
                  i
                ].qno.substr(
                  22,
                  Object.values(props.data.reportData[ "Civil Judicial Action Disclosure Reporting Page"])[i].qno
                    .length
                )
              );
            }
            // console.log(questions_array_b);
            SetQuestions(questions_array_b);
            setAnswer(Answer_array_b);
        //   console.log ( Object.values(Answer_array_b[14]));
        //   console.log (Object.keys(Answer_array_b[14]));
        //  console.log(Answer_array_b[14])
            setQuestionNumber(QuestionNumber);
          };
        
          FetchData();



          setIsloading(true);
    },[props])
  return (
    <div>{Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <ul
                style={{
                  listStyleType: "square",
                  textAlign: "left",
                  fontSize: "23px",
                }}
              >
                <li>Civil Judicial Action Disclosure Reporting Page</li>
                <li>Form of Organization</li>
              </ul>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                <div>
                  {Questions.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {index === 9  ?(<React.Fragment>
                           <ol> {Answer[index].map((itimes,index1)=>{
                              return <li key={index1}>{itimes}</li> ;
                            })}</ol>
                          </React.Fragment>):
                          (index === 14 ?(<ol>Answer[index]</ol>)
                           : ( <h6 dangerouslySetInnerHTML={{__html: Answer[index]}}></h6>))}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </div>
                
              </Box>
            </Grid>
          </Grid>
        </Grid>
    )}</div>
  )
}

export default TwentyOnePage;