import { useState, useEffect } from "react";
import '../styles/Commentbox/comment.css'
import {IconButton, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Avatar from '@mui/material/Avatar';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { updateCommentNote } from "ESG/services/Fetch";


const Comment = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  parentId = null,
  currentUserId,
}) => {
  const isEditing =
    activeComment &&
    activeComment.id === comment.id  &&
    activeComment.type === "editing";
  const isReplying =
    activeComment &&
    activeComment.id === comment.id  &&
    activeComment.type === "replying";
  const canDelete =
    currentUserId === comment.userId && replies.length === 0 ;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.userId ;
  const replyId = parentId ? parentId : comment.id;
  const createdAt = new Date(comment.createdAt).toLocaleDateString() +' |'+new Date(comment.createdAt).toLocaleTimeString();

  function stringAvatar(name) {
    return {
      children:
      `${typeof name?.split(' ')[0]!=='undefined'?name?.split(' ')[0].charAt(0).toUpperCase ():'X'}${typeof name.split(' ')[1]!=='undefined'?name.split(' ')[1].charAt(0).toUpperCase ():'X'}`,
    };
  } 

  return (
    
    <div key={comment.id} className="comment">
      <div className="comment-image-container">
      <Avatar className="comment_Avatar" sx={{ color:'#2076B0',backgroundColor:'#E9F1F7',fontSize:'small',border:'1px solid #BAD5E7'}} {...stringAvatar(comment?.username)} />
      </div>
      <div className="comment-right-part">
        <div className="comment-content">
          <div className="comment-author">{comment.username}</div>
          <div className="comment-Date_Time">{createdAt}</div>
        </div>
        {!isEditing && <div className="comment-text">{comment.body}</div>}
        {isEditing && (
          <CommentForm
            submitLabel={<AutorenewIcon display='update' title='Update' color="primary" />}
            hasCancelButton
            initialText={comment.body}
            handleSubmit={(text) => updateComment(text, comment.id)}
            handleCancel={() => {
              setActiveComment(null);
            }}
          />
        )}
        <div className="comment-actions">
          {canReply && (
            <IconButton
              className="comment-action"
              onClick={() =>
                setActiveComment({ id: comment.id, type: "replying" })
              }
              
            >
             <ReplyIcon className="comment-action_replay"/>
            </IconButton>
          )}
          {canEdit && (
            <IconButton
              className="comment-action"
              onClick={() =>
                setActiveComment({ id: comment.id, type: "editing" })
              }
            >
              <EditIcon className="comment-action_Edit"/>
            </IconButton>
          )}
          {canDelete && (
            <IconButton
            
              className="comment-action"
              onClick={() => deleteComment(comment.id)}
            >
             <DeleteForeverIcon title='Delete' className="comment-action_Delete"/>
            </IconButton>
          )}
        </div>
        {isReplying && (
          <CommentForm
            submitLabel={<SendIcon display='reply' title='Reply' color="secondary"/>}
            handleSubmit={(text) => addComment(text, replyId)}
            handleCancel={() => {
              setActiveComment(null);
            }}
          />
        )}
        {replies.length > 0 && (
          <div className="replies">
            {replies.map((reply) => (
              <Comment
                comment={reply}
                key={reply.id}
                setActiveComment={setActiveComment}
                activeComment={activeComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                addComment={addComment}
                parentId={comment.id}
                replies={[]}
                currentUserId={currentUserId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};


const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
  QuestionID,
  startDate,
  CIK
}) => {
  const [text, setText] = useState(initialText);
  const isTextareaDisabled = text.length === 0;
  const onSubmit = async(event) => {
    event.preventDefault();
    handleSubmit(text);
    setText("");
  };
 
  return (
      <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
      <TextField
        variant="outlined"
        placeholder="Type something here…"
        multiline
        size="small"
        fullWidth
        maxRows={4}
        className="comment-form-textarea"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <IconButton disabled={isTextareaDisabled} onClick={onSubmit}>
        {submitLabel}
      </IconButton>
      {!isTextareaDisabled && (submitLabel.props.display==='reply'|| submitLabel.props.display==='update') ?
        <IconButton
          type="button"
          className="comment-form-button comment-form-cancel-button"
          onClick={handleCancel}
        >
          <ClearIcon title='Clear'/>
        </IconButton>:''
}

</Box>
  );
};
 

const updateCommentApi = async (text) => {
  return { text };
};

 const deleteCommentApi = async () => {
  return {};
};



const Comments = ({currentUserId,setComment,Coment,QuestionID,startDate}) => {

const UserInfo = JSON.parse(localStorage.getItem("Name"));
// console.log(UserInfo);
  const ans = Coment?.InitialComment;
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = backendComments.filter(
    (backendComment) => backendComment.parentId === null||backendComment.parentId===undefined
  );
 
  const createCommentApi = async (text, parentId = null) => {

    return {
      id: Math.random().toString(36).substr(2, 9),
      body: text,
      parentId,
      userId:UserInfo.id,
      username: UserInfo.name,
      createdAt: new Date(),
    };
  };

  const handleSaveComment=async(comments)=>{
    try {
      const CIK = "0000320193";
       await updateCommentNote( startDate, QuestionID, JSON.stringify(comments));
       
    } catch (error) {
      console.log(error);
    }
     }
  
  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  const addComment = (text, parentId) => {
    createCommentApi(text, parentId).then((comment) => {
      console.log('added comment')
      handleSaveComment([comment, ...backendComments]);
      setBackendComments([comment, ...backendComments]);
      setActiveComment(null);
    });
  };

  const updateComment = (text, commentId) => {
      const updatedBackendComments = backendComments.map((backendComment) => {
        if (backendComment.id === commentId) {
          return { ...backendComment, body: text };
        }
        return backendComment;
      });
      console.log('updated comment')
      handleSaveComment(updatedBackendComments);
      setBackendComments(updatedBackendComments);
      setActiveComment(null);
  };

  const deleteComment = (commentId) => {
    if (window.confirm("Are you sure you want to remove comment?")) {
        const updatedBackendComments = backendComments.filter(
          (backendComment) => backendComment.id !== commentId
        );
        handleSaveComment(updatedBackendComments);
        setBackendComments(updatedBackendComments);

    }
  };
 
  useEffect(() => {
    if(typeof ans ==='object'&& ans.length!==0){
      setBackendComments(ans);
    }else{
      setBackendComments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ans]);
  

  useEffect(() => {
    setComment((prevstate) => {
      return { ...prevstate, UpdatedComment: backendComments }
    })
  }, [backendComments])

  return (
    <Box className="comments"> 
     
      <div className="comments-container">
        {rootComments.map((rootComment) => (
          <Comment
            key={rootComment.id}
            comment={rootComment}
            replies={getReplies(rootComment.id)}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            addComment={addComment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            currentUserId={currentUserId}
          />
        ))}
      </div>
      <CommentForm submitLabel={<SendIcon display='send' title='Send' sx={{color:'#2076B0'}}/>} handleSubmit={addComment}  handleCancel={() => {
              setActiveComment(null);
            }} />
    </Box>
  );
};

export default Comments;
