import { Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useRoleContext } from "context/RoleContex";

export default function RolesDropDown({ data }) {
    const [role, setRole] = React.useState('');
    const [hasAccess, setHasAcces] = useState(false);
    const {updateSelectedRole} = useRoleContext();

    useEffect(() => {
        console.log(data, "009");
        if (data?.filingGroups) {
            setRole(data.filingGroups[0]?.displayName);
            console.log("009",role);
            setHasAcces(true)
        }
        if(localStorage.getItem("selectedUserRoleContext")){
            setRole(localStorage.getItem("selectedUserRoleContext"))
        }
        localStorage.setItem("tes67",role);
    }, []);

    const handleChange = (event) => {
        setRole(event.target.value);
    };


    useEffect(() => {
        localStorage.setItem("selectedUserRoleContext",role);
        updateSelectedRole(role)
    }, [role]);

    return (
        <React.Fragment>
            {
                hasAccess && (
                    <FormControl sx={{ m: 1, minWidth: 120, fontSize: "0.5em" }} size="small">
                        <InputLabel id="demo-select-small-label">Role</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={role}
                            label="role"
                            onChange={handleChange}
                        >
                            {
                                data.filingGroups.map((item) => {
                                    return (
                                        <MenuItem value={item.displayName}>{item.displayName}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                )
            }
        </React.Fragment>
    )
}