import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    Typography
  } from "@mui/material";
import withSnackbar from "../../components/Alert/withSnackbar"; 
import "../../Compliance/styles/Case_Managment/ParentCaseInfo.css"

const ParentCaseInfo=({data,showSnackbar})=>{

return(
    <>
    <Grid className="boxborder">
        <Grid>
          <Typography className="parentHeading">Parent Case ID : {data?.parentid}</Typography>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"space-between",marginTop:'15px'}}>
          <Grid>
            <Typography className="subhead">Customer ID</Typography>
            <Typography className="subcontent">{data?.customer_id}</Typography>
          </Grid>
          <Grid>
            <Typography className="subhead">Name</Typography>
            <Typography className="subcontent">{data?.first_name +" "+ data?.last_name}</Typography>
          </Grid>
          <Grid>
            <Typography className="subhead">Expected Closure Date</Typography>
            <Typography className="subcontent">{data?.expected_closure_date}</Typography>
          </Grid>
        </Grid>
        <Grid style={{marginTop:'15px'}}>
        <Typography className="subhead">Description</Typography>
        <Typography className="subcontent">{data?.description}</Typography>
        </Grid>
    </Grid>
    </>
)
}


export default withSnackbar(ParentCaseInfo, "ParentCaseInfo", "success");