import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Button, Grid, Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { SectionNewQuestion } from "./NewQuestions";
import {
  addSubSection,
  FetchGetData,
  fetchEditIcon,
} from "../services/FormCreationFetch";
import NoteContext from "context/PageContext";
import EditSection from "./EditSection";

const EditSubSection = (props) => {
  const contextData = useContext(NoteContext);
  let session_values = JSON.parse(sessionStorage.getItem("section"));
  const [formValues, setFormValues] = useState([{ session_values }]);
  // eslint-disable-next-line no-unused-vars
  const [subSections, setSubSections] = useState([]);
  const [subSectionName, setSubSectionName] = useState(props.secName);
  const [subSectionId, setSubSectionId] = useState(props.secId);
  const [subSectionDescription, setSubsectionDescription] = useState(
    props.secDis
  );
  const [subSectionHelpertext, setSubSectionHelpertext] = useState(
    props.secHelp
  );
  const [editIcon, setIconEDit] = React.useState(false);
  const [open, setOpen] = React.useState(props.open);
  // const copyItem = formValues
  const [showMyComponent, setShowMyComponent] = useState(false);

  let disclosurename = contextData.disclosureName;

  const fetchEditData = async () => {
    await FetchGetData(disclosurename).then((data) => {
      // console.log("Data", data._items)
      // setFormValues(copyItem)
    });
  };

  const handleEditIcon = () => {
    //   fetchEditData()
    //   setIconEDit(true)
    // console.log(showMyComponent);
    setShowMyComponent(true);
    //console.log(showMyComponent);
    //console.log(props);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIconEDit(false);
    //console.log("Cancel button is hitting")
  };

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleAddSubSection = () => {
    //console.log(props.id);
    // const dataFromLocalStorage = JSON.parse(localStorage.getItem("myDisclosure"));
    let dataFromLocalStorage;
    // const disclosureId = ((dataFromLocalStorage[0].title));
    // const disclosureId = 'DisclosureTestNew9';
    let disclosureId;
    if (contextData.disclosureName) {
      disclosureId = contextData.disclosureName;
    } else {
      disclosureId = JSON.parse(localStorage.getItem("disclosurename"));
    }

    const sectionId = props.id;
    let demoData = dataFromLocalStorage
      ? dataFromLocalStorage
      : { title: disclosureId, sections: [] };
    const newSubSection = {
      title: subSectionName,
      sectionId: subSectionId,
      questions: [],
    };
    // console.log(demoData.sections);
    // eslint-disable-next-line array-callback-return
    demoData.sections.map((data) => {
      if (data.sectionId === props.id) {
        // console.log(data.subsections);
        data.subsections.push(newSubSection);
      }
    });
    // console.log(demoData);
    // localStorage.setItem("myDisclosure", JSON.stringify(demoData));
    setSubSections(demoData);
    addSubSection(
      disclosureId,
      sectionId,
      subSectionName,
      subSectionDescription,
      subSectionHelpertext
    ).then(() => {
      props.handleClose();
      contextData.setReloadData(true);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (event) => {
    event.preventDefault();
    //console.log("################", (formValues));
    sessionStorage.setItem("section", JSON.stringify(formValues));
    // console.log(sessionStorage.getItem("section"))
    // if (!Form.error)
    //   window.location.replace("./RegistrationResult");
    // Send data to the backend via POST
    /*DEEPAK - Uncomment after server side code is implemented */
    /* fetch("http://localhost:5050/register", {  // Enter your IP address here
        method: 'POST', 
        mode: 'cors', 
        href: '/RegistrationResult',
        body: JSON.stringify(formValues) // body data type must match "Content-Type" header
        }) */
  };
  const handleSave = async () => {
    // console.log("page is hitting")
    await fetchEditIcon(disclosurename, formValues).then((data) => {
      // console.log(data)
      //setExisting_disclosure(data._items[0]);
    });
  };
  //  console.log(props.id);

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Edit Subsection</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="subsectionname-input"
                name="subsectionname"
                label="SubSection Name"
                type="text"
                value={subSectionName}
                onChange={(e) => setSubSectionName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              {subSectionId && 
              <TextField
                fullWidth
                id="subsectionid-input"
                name="subsectionid"
                label="subsection id"
                type="text"
                required
                value={subSectionId}
                onChange={(e) => setSubSectionId(e.target.value)}
                disabled
                focused
              />
            }
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="subsectiondescription-input"
                name="subsectiondescription"
                label="subsection description"
                type="text"
                required
                value={subSectionDescription || ''}
                onChange={(e) => setSubsectionDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="subsectionhelpertext-input"
                name="subsectionhelpertext"
                label="subsection helpertext"
                type="text"
                required
                value={subSectionHelpertext || ''}
                onChange={(e) => setSubSectionHelpertext(e.target.value)}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25a392",
              marginLeft: "10px",
              flexDirection: "row",
            }}
            onClick={handleAddSubSection}
          >
            Create
          </Button>
          <Button
            variant="text"
            style={{ color: "red", flexDirection: "row" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {showMyComponent && <EditSection open={true} />}
    </div>
  );
};

export default EditSubSection;
