import {  TableHead, TableRow ,TableCell, TableBody} from "@mui/material";
import "../../styles/Report/Report.css";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";



function GDATable(props) {
  const [isloading, setisloading] = useState(false);
  const [Question, setQuestion] = useState();


  useEffect(() => {
    const ddd = [];
    const questionData = [];
    const answerData = [];
  
    for (let i = 0; i < Object.keys(props.data.reportData["G.Disclosure A"]).length; i++) {
      if (
        Object.values(props.data.reportData["G.Disclosure A"])[i].answer === "Yes" ||
        Object.values(props.data.reportData["G.Disclosure A"])[i].answer === "No"
      ) {
        questionData.push(Object.keys(props.data.reportData["G.Disclosure A"])[i]);
        answerData.push(Object.values(props.data.reportData["G.Disclosure A"])[i].answer);
        ddd.push({
          Questions: Object.keys(props.data.reportData["G.Disclosure A"])[i],
          Answer: Object.values(props.data.reportData["G.Disclosure A"])[i].answer
        });
      }
    }
  
    setQuestion(ddd);
    setisloading(true);
  }, [props]);
  

  

  return (

    <React.Fragment>
      {isloading ? ( 
      <>
            <Grid container className="border" >
              <Grid item xs={12} md={2} className="border">
              <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  /></div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left", }}>
                  <li style={{color:"#61c4ee",fontSize:"20px"}}><b>Governance</b></li>
                    <li>Strategy</li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid>
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"#61c4ee"}} className="border">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Governance</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Does the board and/or board committees consider climate-related issues when reviewing and setting</p>
                  </Grid>
           <Grid item xs={12} md={12}  > 
          {/* <h4><b>Does the board and/or board committees consider climate-related issues when reviewing and setting</b></h4> */}
                <div style={{ justifyContent: "right", display: "flex" }}>
      <TableContainer>
      <Table className="Table_TCFD1"  aria-label="simple table">
        <TableHead>
          <TableRow  sx={{"&:last-child td, &:last-child th": { border: 1,fontFamily: 'Microsoft YaHei',fontSize:"15px" } }}>
          <TableCell>Questions</TableCell>
          <TableCell>Yes/No</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{"&:last-child td, &:last-child th": { border: 1,fontSize:"15px" } }}> 
        {Question.map((val, key) => {
          return (
            <TableRow key={key} >
              <TableCell>{val.Questions}</TableCell>
              <TableCell>{val.Answer}</TableCell>
            </TableRow>
          )
        })}
        </TableBody>
      </Table>
      </TableContainer>
      </div>
              </Grid>
              </Grid>
            </Grid>
    

      </>
        ) : (
           <div className="loaders">Loading...</div>
          )}
     
    </React.Fragment>
  );
}

export default GDATable;



