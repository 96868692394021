import { createContext, useContext, useState, useEffect } from 'react';

const RoleContext = createContext();

export const RoleContextProvider = ({ children }) => {
    const [selectedRole, setSelectedRole] = useState("");
    const [puslishTriggered, setPublishTriggered] = useState(false)
    const [editDisclosureTitleTrigered, setEditDisclosureTitleTrigered] = useState(false)
    const [addDisclosureTriggered, setAddDisclosureTriggered] = useState(false);

    const [editApiTriggered, setEditApiTriggered] = useState(false)
    const [cloneApiTriggered, setCloneApiTriggered] = useState(false)
    const [publishApiTriggered,setPublishApiTrigerred] = useState(false)
    const [unpublishApiTriggered,setUnpublishApiTrigerred] = useState(false)



    useEffect(() => {
        if (localStorage.getItem("selectedUserRoleContext")) {
            setSelectedRole(localStorage.getItem("selectedUserRoleContext"))
        }
    }, []);

    const updateSelectedRole = (newValue) => {
        setSelectedRole(newValue);
    }

    const updatePublishTriggered = (newValue) => {
        setPublishTriggered(newValue);
    }

    const updateEditDisclosureTitleTrigered = (newValue) => {
        setEditDisclosureTitleTrigered(newValue);
    }

    const updateAddDisclosureTriggered = (newValue) => {
        setAddDisclosureTriggered(newValue);
    }

    const updateEditApiTriggered = (newValue) => {
        setEditApiTriggered(newValue);
    }

    const updateCloneApiTriggered = (newValue) => {
        setCloneApiTriggered(newValue);
    }
    
    const updatePublishApiTriggered = (newValue) => {
        setPublishApiTrigerred(newValue);
    }

    const updateUnpublishApiTriggered = (newValue) => {
        setUnpublishApiTrigerred(newValue);
    }

    return (
        <RoleContext.Provider value=
            {{
                selectedRole,
                updateSelectedRole,
                puslishTriggered, 
                updatePublishTriggered, 
                editDisclosureTitleTrigered, 
                updateEditDisclosureTitleTrigered, 
                addDisclosureTriggered,
                updateAddDisclosureTriggered,
                editApiTriggered,
                updateEditApiTriggered,
                cloneApiTriggered,
                updateCloneApiTriggered,
                publishApiTriggered,
                updatePublishApiTriggered,
                unpublishApiTriggered,
                updateUnpublishApiTriggered
            }}>
            {children}
        </RoleContext.Provider>
    )
}

export const useRoleContext = () => {
    return useContext(RoleContext)
}
