import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from '@mui/material';
import { unpublishDisclosure, publishDisclosure } from "../services/FormCreationFetch";
import { useRoleContext } from 'context/RoleContex';
const PublishModal = ({ open, handleClose, item, handlePublish, companies }) => {
  const { updatePublishApiTriggered, updateUnpublishApiTriggered } = useRoleContext();
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  if (!item) return null;

  const handleConfirmClick = async () => {
    if (item.isPublished) {
      const res = await unpublishDisclosure(item.id);
      if (res) {
        updateUnpublishApiTriggered(true)

      }
    } else {
      //console.log(selectedCompanies);
      const res = await publishDisclosure(item.id, selectedCompanies);
      if (res) {
        updatePublishApiTriggered(true);
      }
    }
    handlePublish(item.id);
    handleClose();
  };

  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          width: 400,
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {item.isPublished ? 'Unpublish' : 'Publish'} Item
        </Typography>
        <Typography>
          Are you sure you want to {item.isPublished ? 'unpublish' : 'publish'} this item?
        </Typography>

        {!item.isPublished && (
          <FormControl fullWidth style={{ marginTop: '20px' }}>
            <InputLabel id="company-select-label">Select Companies</InputLabel>
            <Select
              labelId="company-select-label"
              multiple
              value={selectedCompanies}
              onChange={handleChange}
              renderValue={(selected) => selected.map(id => companies.find(company => company.id === id)?.name).join(', ')}
            >
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  <Checkbox checked={selectedCompanies.includes(company.id)} />
                  <ListItemText primary={company.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmClick}
            disabled={!selectedCompanies.length && !item.isPublished}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PublishModal;
