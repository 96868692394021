import React, { useEffect, useState,useContext } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Button, Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Visibility from '@mui/icons-material/Visibility';
import { useAsyncError, useNavigate } from "react-router-dom";
import '../styles/Case_Managment/viewallcase.css'
import Header from '../CaseManagment/Header.js'
import NoteContext from "context/PageContext";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";

const {
    getAllCases,
   
  } = require("../services/CaseFetch");
  
  
export default function ViewAllCases() {
  const navigate = useNavigate();
  const ContextData = useContext(NoteContext);
  const {templateType,caseType} = useTemplateType();
    const [Info, setInfo] = useState();
    const [loading, setLoading] = useState();
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [filteredRows, setfilteredRows] = useState([]);


    const handleStatusFilter = (status) => {
      setSelectedStatus(status);      
      //setfilteredRows(data)
    };
    useEffect(() => {
      
      filterdata();

    }, [selectedStatus]);

 

    const filterdata=()=>{
     setfilteredRows([])
      
      if (selectedStatus === "All") {
        setfilteredRows(Info)
      }
      else{
        setfilteredRows(Info?.filter((row) => row.Status===selectedStatus))
      }
    }
  
    const fetchData = async () => {
        try {
         
          const data=await getAllCases(ContextData.UserSelected,caseType,'');
          var res=data.data.values;
          setInfo(res);
          setfilteredRows(res)

         
          setLoading(false);
          setTimeout(() => {
            if (res) {
              //showSnackbar("Case details fetched successfully", "success");
            }
          }, 20000);
        } catch (error) {
          
          setLoading(false);
          //showSnackbar("Unable to fetch case", "error");
        }
      };
    useEffect(() => {
      
        fetchData()

      }, []);
    return (
        
        <Grid className="home Case_Home">
         
        <Grid>
          {/* <Typography className="heading">All Cases</Typography> */}
          <Header title="All Cases" backarrow_visible={true} link="/caseManagement"/>
        </Grid>
        <Grid className="boxcard">
          <Typography className="subtitle">Case Details</Typography>
          <Grid>
          <ButtonGroup
            disabled={Info?.length < 0}
            className="Button_Group"
            variant="contained"
            //color="primary"
            aria-label="outlined secondary button group"
          >
            <Button
              variant="contained"
              onClick={() => handleStatusFilter("All")}
              className={selectedStatus === "All" ? "selected" : "notselected"}
            >
              All
            </Button>
            <Button
              variant="contained"
              onClick={() => handleStatusFilter("open")}
              className={selectedStatus === "open" ? "selected" : "notselected"}
            >
              Open
            </Button>
            <Button
              variant="contained"
              onClick={() => handleStatusFilter("close")}
              className={selectedStatus === "close" ? "selected" : "notselected"}
            >
              Close
            </Button>

          </ButtonGroup>
          </Grid>
           <Table  className="casestable">
            <TableHead>
             
                <TableCell>Case ID</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Case Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Current Case Worker</TableCell>
                <TableCell>Case Date</TableCell>
                <TableCell>Action</TableCell>
             
            </TableHead>
            <TableBody>
            {loading==false  && (
            filteredRows.map(row => (
     
                <TableRow>
                <TableCell className="caselink" onClick={() => navigate(`/viewallchildcases?case_id=${btoa(row.CaseId)}`)}>{row.CaseId}</TableCell>
                <TableCell>{row.Creator}</TableCell>
                <TableCell>{row.CaseType}</TableCell>
                <TableCell>{row.Status}</TableCell>
                <TableCell>{row.CurrentCaseWorker}</TableCell>
                <TableCell>{row.CaseDate}</TableCell>
                <TableCell>
        <Tooltip title="View a case">
          <IconButton
            className="Table_Button_visibility"
            onClick={() => navigate(`/viewallchildcases?case_id=${btoa(row.CaseId)}`)}
          >
            <Visibility className="Table_Button" />
          </IconButton>
        </Tooltip></TableCell>
            </TableRow>
            ))
            )} 
            </TableBody>
            </Table>
          </Grid>
        </Grid>
  
    )
} 