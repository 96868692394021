import React, { useEffect, useState } from "react";
import "../styles/Home/ESGHome.css";
import { Box, Grid, Typography, Button } from "@mui/material";
import PaperComponent from "components/PaperComponent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GetEsgEvents, GetEsgNews, pathpermission } from "ESG/services/Fetch";
import Pagination from '@mui/material/Pagination';

function ToggleNewsEvents({ FilterType, handleChange }) {
  return (
    <ToggleButtonGroup
      className="toggleButtons"
      sx={{
        ".MuiToggleButtonGroup-grouped": {
          padding: "8px 18px",
          fontSize: "14px",
        },
      }}
      value={FilterType}
      exclusive
      onChange={handleChange}
      size="small"
    >
      <ToggleButton value="News">News</ToggleButton>
      <ToggleButton value="Events">Events</ToggleButton>
    </ToggleButtonGroup>
  );
}

const ESGEvents = () => {
  const [FilterType, setFilterType] = React.useState("News");
  const [EsgNews, setEsgNews] = React.useState([]);
  const [EsgEvents, setEsgEvents] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchError, setFetchError] = useState(null);
  const permittedPath = localStorage.getItem("permittedPath");

  useEffect(() => {
    const fetchEsgNewsAndEvents = async () => {
      try {
        const esgNewsResponse = await GetEsgNews();
        const esgEventsResponse = await GetEsgEvents();
        setEsgNews(esgNewsResponse?.News?._items || []);
        setEsgEvents(esgEventsResponse?.Events?._items || []);
      } catch (error) {  
        setFetchError(error.message || "Failed to load ESG news and events");
      }
    };
    fetchEsgNewsAndEvents();
  }, []);

  const handleChange = (event, filterType) => {
    if (filterType !== null && FilterType !== filterType) {
      setFilterType(filterType);
      setCurrentPage(1);
    }
  };

 
  const itemsPerPage = 5;
  const totalItems = FilterType === "News" ? EsgNews.length : EsgEvents.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      {fetchError ? (
        <PaperComponent>
          <Box minHeight={735} sx={{ padding: "15px", overflow: "scroll" }}>
            <Typography mb={1} className="Title_Name">
            {permittedPath + ' Updates'}
            </Typography>
            <Grid container mb={2}>
              <ToggleNewsEvents
                FilterType={FilterType}
                handleChange={handleChange}
              />
            </Grid>
          </Box>
        </PaperComponent>
      ) : (
        <>
          {EsgEvents.length > 0 && (
            <React.Fragment>
              <PaperComponent>
                <Box
                  minHeight={660}
                  sx={{ padding: "15px",display: 'flex', flexDirection: 'column', justifyContent: 'space-between'  }}
                >
                  <Box>
                  <Typography mb={1} className="Title_Name">
                  {permittedPath + ' Updates'}
                  </Typography>
                  <Grid container mb={2}>
                    <ToggleNewsEvents
                      FilterType={FilterType}
                      handleChange={handleChange}
                    />
                  </Grid>

                  {(FilterType !== "News"
                    ? EsgEvents.slice(startIndex, endIndex)
                    : EsgNews.slice(startIndex, endIndex)
                  ).map((item, index) => (
                    <React.Fragment>
                      <Grid container flexDirection={'row'} spacing={0.5}
                        mb={'10px'}
                        key={item._id}>
                          <Grid item xs={2} sx={{marginTop:'2px'}}>
                          <Typography p={0} m={0} sx={{textAlign:'center',color:'#25A392',fontSize:'12px'}}>{(item?.properties?.date[0].value.split('-')[1]).toUpperCase()}</Typography>
                          <Typography p={0} m={0} sx={{textAlign:'center',color:'#25A392',fontSize:'20px',fontWeight:600}}>{item?.properties?.date[0].value.split('-')[2]}</Typography>
                          <Typography p={0} m={0} sx={{textAlign:'center',color:'#25A392',fontSize:'12px'}}>{item?.properties?.date[0].value.split('-')[0]}</Typography>
                          </Grid>
                        <Grid item xs={10}>
                        <Typography className="NewsDate">
                              {item?.properties?.location &&
                                item?.properties?.location[0].value}
                            </Typography>
                          <a
                            href={
                              item.properties.url &&
                              item.properties.url[0].value
                            }
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography className="Newheading">
                              {item.properties.title[0].value}
                            </Typography>
                          </a>
                          <Typography className="NewsData">
                            {item.properties.description[0].value}
                          </Typography>
                        </Grid>
                       
                        </Grid>
                     
                      {index !== endIndex - 1 && (
                        <div className="lightBreakLine" />
                      )}
                    </React.Fragment>
                  ))}
                 </Box>
                  <Grid container justifyContent="center">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      color="primary"
                      sx={{
                        marginTop: 1,
                        alignSelf: "center",
                        justifySelf: "center",
                      }}
                    />
                  </Grid>
                </Box>
              </PaperComponent>
            </React.Fragment>
          )}
        </>
      )}
    </>
  );
};

export default ESGEvents;
