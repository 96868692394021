import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

export default function SnackBarComponent({ open, setOpenSnackbar, snackbarData }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <Grid container alignItems="center" >
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Grid>
  );

  return (
    <Grid >
      {/* <Snackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.text}
        action={action}
        sx={{ maxWidth: "96%", display: 'flex', flexDirection: "row" }}
      /> */}
      <Snackbar open={open}  onClose={handleClose} sx={{maxWidth:"96%"}}>
        <Alert
          onClose={handleClose}
          severity={snackbarData.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarData.text}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
