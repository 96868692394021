import React from 'react'
import { Button } from '@mui/material';
import { NavLink } from "react-router-dom";
import error from "../../images/Error.jpg";
import '../styles/ErrorPage/ErrorPage.css';

const ErrorPage=()=> {
  return (
    <div  className='Error Main'>
      <img src={error} alt="error" />
      <NavLink to="/">
        <Button className="btn"> Go Back</Button>
      </NavLink>
    </div>
  );
};

export default ErrorPage;




  
