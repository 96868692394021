import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "../styles/Forms_Styles/Form_Date_input.css"
import { useContext } from "react";
import NoteContext from "context/PageContext";

export default function Form_Date_input({onChange,getAnswerDisable }) {
  const {Answer}=useContext(NoteContext)
  let Info="";
  try {
   Info=typeof Answer.UpdatedAnswer === "string" ? Answer.UpdatedAnswer : "";
  } catch (error) {
    Info='';
  }

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker sx={{fontSize:'small'}}
            label="Enter Date"
            openTo="year"
            views={['year', 'month', 'day']}
            value={dayjs(Info)}
            onChange={(newValue) => {
              onChange(newValue);
            }}
            disabled={getAnswerDisable}
          />
      </LocalizationProvider>
  );
}
