import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Container,
  createFilterOptions,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteContext from "context/PageContext";
import {
  enforcingAgency_Dropdown,
  categoryDropdown,
} from "../services/FormCreationFetch";
import { domainDropdownList } from "../../ESG/services/Fetch";

const Clone = ({ isclone, setclose }) => {
  const contextData = useContext(NoteContext);
  const [disclosurename, setDisclosureName] = useState("");
  const [effectivedate, seteffectivedate] = useState("");
  const [version, setversion] = useState("");
  const [enforcingagency, setenforcingagency] = useState("");
  const [enforcingagencyValue, setenforcingagencyValue] = useState(null);
  const [categoryList, setCategoryList] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [domainValues, setdomainValues] = useState();
  const defaultDomain = localStorage.getItem("UserSelectedDomain");
  const [selectedDomain, setselectedDomain] = useState(defaultDomain);


  const [insuranceTriggered, setInsuranceTriggered] = useState(false)
  const [formTypeValue, setFormTypeValue] = useState("");
 

 

  useEffect(() => {
   
    const fetchdomainname = async () => {
      const data = await domainDropdownList();
      setdomainValues(data);
    };

    const category_dropdown = async () => {
      await categoryDropdown().then((data) => {
        setCategoryList(data.data);
      });
    };
    fetchdomainname();
    category_dropdown();
    
  }, []);

  // const filter = createFilterOptions(categoryList);

  const handleAddDisclosure = () => {
    //sending data to backend
  };

  const handleClickClose = () => {
    setclose(false);
  };

  return (
    <div>
      <Dialog
        open={isclone}
        onClose={() => handleClickClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Clone Disclosure</DialogTitle>
        <DialogContent>
          <div>
            <Grid container spacing={2} sx={{ marginTop: "5px" }}>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  required
                  id="disclosurename-input"
                  name="disclosurename"
                  label="Disclosure Name"
                  type="text"
                  value={disclosurename}
                  onChange={(e) => setDisclosureName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  id="effectivedate-input"
                  name="effectivedate"
                  type="date"
                  required
                  value={effectivedate}
                  onChange={(e) => seteffectivedate(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  id="version-input"
                  name="version"
                  label="Version Number"
                  type="number"
                  required
                  value={version}
                  onChange={(e) => setversion(e.target.value)}
                />
              </Grid>
              
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25a392",
              marginLeft: "10px",
              flexDirection: "row",
            }}
            onClick={handleAddDisclosure}
            disabled={
              !(
                version &&
                effectivedate &&
                disclosurename 
              )
            }
          >
            Create
          </Button>
          <Button
            variant="text"
            style={{ color: "red", flexDirection: "row" }}
            onClick={handleClickClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Clone;