import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const Dischargerelated = (props)  =>{
    const [effluent,seteffluent] = useState()
    const [operating,setoperating] = useState()
    const[internally,setinternally]=useState()
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
        seteffluent((Object.values(props.data.reportData["GRI 303.2 Management of water dischargerelated impacts"])[0]).answer)
         setoperating((Object.values(props.data.reportData["GRI 303.2 Management of water dischargerelated impacts"])[1]).answer)
         setinternally((Object.values(props.data.reportData["GRI 303.2 Management of water dischargerelated impacts"])[2]).answer)
        // setfeedback((Object.values(props.data.reportData["GRI 2.25 Processes to remediate negative impacts"])[4]).note)
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={10}  className="grid1">
                        <h5 className="underline">Management of water dischargerelated impacts</h5>
                        <h6><b>Mention the minimum standards set for the quality of effluent discharge, and how these minimum standards were determined.</b></h6>
                        <div dangerouslySetInnerHTML={{__html:effluent}}></div>
                        
                {/* </Grid>
                <Grid item xs={12} md={5}  className="grid1"> */}
                        <h6><b>How standards for facilities operating in locations with no local discharge requirements were determined?</b></h6>
                        <div dangerouslySetInnerHTML={{__html:operating}}></div>
                        <h6><b>Provide any internally developed water quality standards or guidelines</b></h6>
                        <div dangerouslySetInnerHTML={{__html:internally}}></div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default Dischargerelated;