import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustOnBoardPage from "./CustOnBoardPage"; // Import the CustOnBoardPage component
import OnBoardQuestions from "./OnBoardQuestions"; // Import the OnBoardQuestions component
import { useNavigate } from "react-router-dom";
// import MaterialTopicTable from "./MaterialTopicTable";
// import MaterialTable from "./MaterialTable";
import { AddCustOnBoard, GetCustomerOnBoard } from "ESG/services/Fetch";
// // import GanttChart from "./GanttChart";
// // import Products from "./Products";
// import Compliance from "./Compliance";
import { getCompanyByDomain } from "../../ESG/services/GraphFetch";
const steps = [
  "Customer Onboarding",
  "Additional Questions",
  // "Materiallity",
  // "Materiallity plans",
  // "Compliance",
];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isloading, setisloading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    legalName: "",
    parentOrganization: "",
    cik: "",
    sic: "",
    typeOfCompany: "",
    HQAddress: "",
    incorporationDetail: "",
    holding: "",
  });
  const navigate = useNavigate();

  const isStepOptional = (step) => {
    return step === 1; // Second step (Additional Questions) is optional
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  React.useEffect(() => {
    GetCustomerOnBoard().then((data) => {
      //console.log(data);
      const res = data.values[0];
      if (data.values.length > 0) {
        const response = {
          legalName: res.LegalName,
          parentOrganization: res.ParentOrganization,
          cik: res.CIK,
          sic: res.SIC,
          typeOfCompany: res.TypeOfCompany,
          HQAddress: JSON.parse(res.HQAddress),
          incorporationDetail: res.IncorporationDetail,
          holding: res.Holding,
        };
        setFormData(response);
      }
      setisloading(true);
      // console.log(response);
      // console.log(res.HQAddress);
    });
    const ftechCompanyAccess = async () => {
      try {
        const dataww = await getCompanyByDomain();
        console.log(dataww);
      } catch (error) {}
    };

    ftechCompanyAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isloading]);

  const handleNext = () => {
    if (activeStep === 0) {
      for (const key in formData) {
        if (!formData[key]) {
          alert(`Property "${key}" is empty!`);
          return;
        }
      }
      AddCustOnBoard(formData);
      // console.log('submitted')
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      // case 0:
      //   return <Products />;
      case 0:
        return (
          <CustOnBoardPage formData={formData} setFormData={setFormData} />
        );
      case 1:
        return <OnBoardQuestions CIK={formData.cik} />;
      // case 2:
      //   return <MaterialTopicTable />;
      // case 3:
      //   return <MaterialTable CIK={formData.cik} />;
      // case 4:
      //   return (
      //     <Compliance />
      //   );

      default:
        return "Unknown step";
    }
  };

  return (
    <React.Fragment>
      {isloading && (
        <Box sx={{ width: "100%", marginTop: "65px" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button variant="contained" onClick={handleReset}>
                    Reset
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={handleSkip}
                      sx={{ mr: 1 }}
                    >
                      Skip
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}
