import React from "react";
import { Paper } from "@mui/material";


const PaperComponent=({children})=>{

    return(
    <Paper 
        sx={{
            backgroundColor:'white',
            borderRadius: '10px',
            boxShadow: '0px 5px 7px #0000000A',
            opacity:1,
            }}>
        {children}
    </Paper>
    );
}

export default PaperComponent;