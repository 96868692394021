import axios from "axios";
import { data } from "jquery";
const { REACT_APP_API_URL } = process.env;

export const addDisclosure = async (
  disclosurename,
  effectivedate,
  version,
  enforcingagency,
  domain,
  categoryValue, //countryList
  formType,
  insuranceTriggered
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const params = {
    disclosurename: disclosurename,
    effectivedate: effectivedate,
    version: version,
    enforcingagency: enforcingagency,
    domain: domain,
    categoryValue: categoryValue,
  };

  if (insuranceTriggered && formType) {
    params.formType = formType;
  }

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/addDisclosure`,
    params: params,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const addSection = async (
  disclosurename,
  sectionName,
  sectionId,
  sectiondescription,
  sectionhelpertext
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/addSection`,
    params: {
      disclosurename: disclosurename,
      sectionName: sectionName,
      sectionId: sectionId,
      sectiondescription: sectiondescription,
      sectionhelpertext: sectionhelpertext,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const addSubSection = async (
  disclosureId,
  sectionId,
  subsectionTitle,
  subsectionDescription,
  subsectionHelpertext
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/addSubSection`,
    params: {
      disclosureId: disclosureId,
      sectionId: sectionId,
      subsectionTitle: subsectionTitle,
      subsectionDescription: subsectionDescription,
      subsectionHelpertext: subsectionHelpertext,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const addSectionQuestion = async (
  disclosureId,
  sectionId,
  question,
  questionid,
  questiondescription,
  questionhelpertext,
  questionId,
  prompt,
  AnswerType,
  TableData,
  subQuestion
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/addSectionQuestion`,
    params: {
      disclosureId: disclosureId,
      sectionId: sectionId,
      question: question,
      questionid: questionid,
      questiondescription: questiondescription,
      questionhelpertext: questionhelpertext,
      questionId: questionId,
      prompt: prompt,
      AnswerType: AnswerType,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    data: {
      FieldTypeJson: TableData ? TableData : subQuestion,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const addSubSectionQuestion = async (
  disclosureId,
  subsectionid,
  question,
  questionid,
  questiondescription,
  questionhelpertext,
  questionId,
  prompt,
  AnswerType,
  TableData
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  //   console.log("UI subsection question function")
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/addSubSectionQuestion`,
    params: {
      disclosureId: disclosureId,
      subsectionid: subsectionid,
      question: question,
      questionid: questionid,
      questiondescription: questiondescription,
      questionhelpertext: questionhelpertext,
      questionId: questionId,
      prompt: prompt,
      AnswerType: AnswerType,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
    data: {
      FieldTypeJson: TableData,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const answerTypesDropdown = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/answerTypes`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const fetchDisclosure = async (disclosurename) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/tree`,
    params: {
      disclosurename: disclosurename,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const fetchquestion = async (disclosurename) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/getData`,
    params: {
      disclosurename: disclosurename,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const listNewDisclosures = async (domain) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/fetchNewDisclosure`,
    params: {
      domain: domain,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getDropDownListApi = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/getDropDownList`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const dropdownEdgeCreation = async (questionId, dropDownVertex) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/addEdgeToDropDown`,
    params: {
      questionId: questionId,
      dropDownVertex: dropDownVertex,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const addCheckboxAndRadioButton = async (questionId, data) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/setCheckboxAndRadioButtonValue`,
    params: {
      questionId: questionId,
    },
    data: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => console.log(response.data))
    .catch((error) => console.log(error));
};

export const addTableData = async (questionId, data) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/getTableGrid`,
    params: {
      questionId: questionId,
    },
    data: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => console.log(response.data))
    .catch((error) => console.log(error));
};

export const FetchGetData = async (disclosurename) => {
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/getdata`,
    params: {
      disclosurename: disclosurename,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const fetchEditIcon = async (disclosurename, formValues) => {
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/formBuilder/editSection`,
    params: {
      disclosurename: disclosurename,
      sectionName: formValues.sectionname,
      sectionId: formValues.sectionid,
      sectiondescription: formValues.sectiondescription,
      sectionhelpertext: formValues.sectionhelpertext,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getTableCuurencyUrl = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/getCurrencyUrl`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const publishDisclosure = async (disclosure, companyId) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/publishDisclosure`,
    params: {
      disclosure: disclosure,
    },
    data: {
      companyId: companyId,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const CloneDisclosure = async (disclosure) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/cloneDisclosure`,
    data: disclosure,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const enforcingAgency_Dropdown = async (newValue) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/financialRegulatoryAuthoritiesDropDown`,
    params: {
      category: newValue,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const categoryDropdown = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/categoryDropDownValues`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getRolePermissions = async (role) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/getRolePermissions`,
    params: {
      role: role,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
export const companyDropdown = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/formBuilder/companyDropDownValues`,
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const editSection = async (
  disclosureName,
  sectionName,
  sectionId,
  sectiondiscription,
  sectionhelpertext
) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/formBuilder/editSection`,
    params: {
      disclosureName: disclosureName,
      sectionName: sectionName,
      sectionId: sectionId,
      sectiondescription: sectiondiscription,
      sectionhelpertext: sectionhelpertext,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const editQuestion = async (data, FieldTypeJson) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/formBuilder/editQuestion`,
    params: {
      disclosureVertex: data.DisclosureName,
      Question: data.questionid,
      updatedQuestion: data.question,
      questiondescription: data.questiondescription,
      questionhelpertext: data.questionhelpertext,
      AnswerType: data.AnswerType,
      FieldTypeJson: FieldTypeJson,
      questionId: data.questionId,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const DeleteQuestion = async (id) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "DELETE",
    url: `${REACT_APP_API_URL}/filings/formBuilder/deleteQuestion`,
    params: {
      questionId: id,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const DeleteSection = async (id) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "DELETE",
    url: `${REACT_APP_API_URL}/filings/formBuilder/deleteSection`,
    params: {
      VertexId: id,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const bard_oai = async (Question) => {
  // const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `https://app-bard-openai.azurewebsites.net/api/bard_oai`,
    data: Question,
    // headers: {
    //   "Content-Type": "application/json",
    //   accessToken: tokens.accessToken,
    //   Authorization: `Bearer ${tokens.idToken}`,
    // },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const editDisclosure = async (DisclosureData) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  console.log(DisclosureData)
  return await axios({
    method: "PUT",
    url: `${REACT_APP_API_URL}/filings/formBuilder/editDisclosure`,
    params: {
      disclosureId: DisclosureData.id,
      disclosurename: DisclosureData.name,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const unpublishDisclosure = async (DisclosurID) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));

  return await axios({
    method: "POST",
    url: `${REACT_APP_API_URL}/filings/formBuilder/unpublishDisclosure`,
    data: {
      disclosureId: DisclosurID,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const domainDropdownList = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/filings/esg/domainDropdownList`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      //     "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const GetNotification = async (id) => {
  console.log(id);
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `${REACT_APP_API_URL}/azure/notifications`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      // "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },

    params: {
      userRoleId: id ? id : "No Group",
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

// PDf AI form creation

export const CreateFromBuilderUsingUI =  async(
  disclosurename,
  effectivedate,
  version,
  enforcingagency,
  domain,
  categoryValue,
  industry,
  state,
  formType,
  data,
  insuranceTriggered
) => {
  const params = {
    disclosurename: disclosurename,
    effectivedate: effectivedate,
    version: version,
    enforcingagency: enforcingagency,
    domain: domain,
    categoryValue: categoryValue,
    industry: industry,
    state: state,
    formType: formType,
  }
  if (insuranceTriggered && formType) {
    params.formType = formType;
  }
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  try {
    const response = await axios({
      method: "POST",
      url: `https://us-central1-complianceledger.cloudfunctions.net/process_pdf`,
      params: params,
      data: {
        base64_pdf: data,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokens.idToken}`,
      }
    })
    return response.data;
  }
  catch (error) {
    return error?.response?.data
   }
};

//testing 


export const testing = async () => {

  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "GET",
    url: `https://us-central1-complianceledger.cloudfunctions.net/pdf_test`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      // "Access-Control-Allow-Headers" :"append,delete,entries,foreach,get,has,keys,set,values,Authorization",
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const DeleteDisclosure = async (disclosure) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return await axios({
    method: "DELETE",
    url: `${REACT_APP_API_URL}/filings/formBuilder/deleteDisclosure`,
    params: {
      disclosure:disclosure,
    },
    headers: {
      "Content-Type": "application/json",
      accessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
