import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TCFDlogo from "../../../images/TCFDlogo.png";


const SDB2 = (props)  =>{
    
    const [Insurance,setInsurance] = useState("")
    const [Influence,setInfluence]=useState();
    const [pageloaded, setpageloaded] = useState(false)

    useEffect (()=>{ 
      //console.log(Object.values(props.data.reportData["S.Disclosure B"]));

      setInsurance((Object.values(props.data.reportData["S.Disclosure B"])[12]).answer);
      setInfluence((Object.values(props.data.reportData["S.Disclosure B"])[14]).answer);
        // setLong((Object.values(props.data.reportData["S.Disclosure A"])[2]).note);
        
        setpageloaded(true);
    },[props])

    return(
    <React.Fragment>
        {pageloaded ? (
        <>
            <Grid container spacing={1} className="border1">
            <Grid item xs={12} md={2} className="border1" >
                <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  /></div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                  <li style={{color:"steelblue",fontSize:"20px"}}><b>Strategy</b></li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"steelblue"}} className="border1">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Strategy</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the Insurance companies should provide supporting quantitative information on how the potential impacts influence client or broker selection and risks and opportunities.</p>
                  </Grid>
                <Grid item xs={12} md={6} className="grid1">
                        <h4 className="underline">S.Disclosure B</h4>
                        <h5 className="underline">Insurance companies should describe the potential impacts of climate-related risks and opportunities.</h5>
                        <div dangerouslySetInnerHTML={{__html:Insurance}}></div>
                </Grid>
                <Grid item xs={12} md={6} className="grid2" >
                  <h5 className="underline">Insurance companies should provide supporting quantitative information on how the potential impacts influence client or broker selection.</h5>
                  <div dangerouslySetInnerHTML={{__html:Influence}}></div>
                </Grid>
                </Grid>
            </Grid>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </React.Fragment>
    );
}
export default SDB2;