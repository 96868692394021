import {
  Box,
  Card,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React, { useEffect, useState } from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  getFormGridForMaterialTopic,
  addMaterialTopicFormData,
  getMaterialTopicFormData,
  ListOfYearsForWhichMaterialTopicIsCreated,
} from "../../ESG/services/GraphFetch";
import { getTableData, addProject, getProjects } from "../services/GraphFetch";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Button from "@mui/material/Button";
import Modal from "components/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import No_Selection from "../../images/No_Selection.svg";

function Compliance() {
  const [alignment, setAlignment] = useState("Environment");
  const [DisplayItem, setDisplayItem] = useState("Environmental");
  const [EnvTopicName, setEnvTopicName] = useState();
  const [SocTopicName, setSocTopicName] = useState();
  const [GovTopicName, setGovTopicName] = useState();
  const [EnvTopic,setEnvTopic]=useState({})
  const [SocTopic,setSocTopic]=useState({})
  const [GovTopic,setGovTopic]=useState({})
  const [OpenselectModal, setOpenselectModal] = useState(false);
  const [TopiName, setTopiName] = useState();
  const [seletedYear, setseletedYear] = useState();

  const fetchDataforDisplay = async () => {
    try {
      
    
    let env = [];
    let Gov = [];
    let Soc = [];
    const EnvTopic = await getTableData("Environmental", 2024);
    const SocTopic = await getTableData("Social", 2024);
    const GovTopic = await getTableData("Governance", 2024);
   
    //console.log(Object.keys(SocTopic?.data?.properties));
    Object.keys(SocTopic?.data?.properties).map((items, index) => {
      if (items !== "response" && items !== "pk") {
        Soc.push(items);
      }
    });

    Object.keys(EnvTopic?.data?.properties).map((items, index) => {
      if (items !== "response" && items !== "pk") {
        env.push(items);
      }
    });
    Object.keys(GovTopic?.data?.properties).map((items, index) => {
      if (items !== "response" && items !== "pk") {
        Gov.push(items);
      }
    });

    // const addedProjects = await getMaterialTopicFormData(
    //   "Environmental",
    //   2023,
    //   "Opportunities in Renewable Energy"
    // );
    // console.log(addedProjects);
    setEnvTopicName(env);
    setSocTopicName(Soc);
    setGovTopicName(Gov);
  } catch (error) {
      console.error(error);
  }
  };
  const FilterButtons = ({ FilterType, handleChange }) => {
    return (
      <ToggleButtonGroup
        className="toggleButtons"
        sx={{
          ".MuiToggleButtonGroup-grouped": {
            padding: "8px 18px",
            fontSize: "14px",
          },
        }}
        value={FilterType}
        exclusive
        onChange={handleChange}
        size="small"
      >
        <ToggleButton value="Environmental">Environment</ToggleButton>
        <ToggleButton value="Social">Social</ToggleButton>
        <ToggleButton value="Governance">Governance</ToggleButton>
      </ToggleButtonGroup>
    );
  };
  const FtechProject=async()=>{
    try {
      const res=await getProjects(DisplayItem);
      
       let Envprojects={};
       let Socprojects={};
       let Govprojects={};
       res?.data?.map((project)=>{
         if(project.Topic==='Environmental'){
           Envprojects[project["subTopic"]]={
           ProjectName:project["project Name"],
           startDate:project["start date"],
           endDate:project["end date"],
           value:project['Value']
          }
         }
         else if(project.Topic==='Social'){
           Socprojects[project["subTopic"]]={
           ProjectName:project["project Name"],
           startDate:project["start date"],
           endDate:project["end date"],
           value:project['Value']
          }
         }
         else if(project.Topic==='Governance'){
           Govprojects[project["subTopic"]]={
           ProjectName:project["project Name"],
           startDate:project["start date"],
           endDate:project["end date"],
           value:project['Value']
          }
         }
       })
       
       setEnvTopic(Envprojects)
       setSocTopic(Socprojects)
       setGovTopic(Govprojects)
    } catch (error) {
      console.error(error);
    }
   
    
    
  
  }

  useEffect(() => {
    fetchDataforDisplay();
    FtechProject();
    
  }, [DisplayItem]);

  const handleChange = (event, newAlignment) => {
   // console.log(event.target.value);
    setAlignment(event.target.value);
    setDisplayItem(event.target.value);
  };

  const handleforAddField = (topic) => {
    setDisplayItem(topic);
  };

  const additems = async (value, index) => {
    EnvTopicName.splice(index, 0, value);
    setEnvTopicName([...EnvTopicName]);
    const topic=EnvTopic[value];
    setEnvTopic((prevstate)=>{
       return{...prevstate,[value]:topic}
    })
  };

  const handleDeleteitem = (index) => {
    EnvTopicName.splice(index, 1);
    setEnvTopicName([...EnvTopicName]);
  };
  const HandleSaveData = (topic) => {
    if(DisplayItem==='Environmental'){
      addProject(DisplayItem, 2024, topic, EnvTopic[topic])
    }
    else if(DisplayItem==='Social'){
      addProject(DisplayItem, 2024, topic, SocTopic[topic])
    }else if(DisplayItem==='Governance'){
      addProject(DisplayItem, 2024, topic, GovTopic[topic])
    }
   
   
  };


  const handleOnchangeEnv = (e,topic) => {
    const {name,value}=e.target;
   const envTopic=EnvTopic[topic] ??{};
  
   envTopic[name]=value;
    setEnvTopic((prevstate)=>{
      return {...prevstate,[topic]:envTopic}
    })
  };

  const handleOnchangeSoc = (e,topic) => {
    const {name,value}=e.target;
   const envTopic=SocTopic[topic] ??{};
  
   envTopic[name]=value;
    setSocTopic((prevstate)=>{
      return {...prevstate,[topic]:envTopic}
    })
   
  };
  const handleOnchangeGov = (e,topic) => {
    const {name,value}=e.target;
   const envTopic=GovTopic[topic] ??{};
   
   envTopic[name]=value;
    setGovTopic((prevstate)=>{
      return {...prevstate,[topic]:envTopic}
    })
   
  };
  const HandleChangeTonext = (topic) => {
    try {
      if (topic === "Environmental") {
        setDisplayItem("Social");
      } else if (topic === "Social") {
        setDisplayItem("Governance");
      } else if (topic === "Governance") {
        setDisplayItem("Environmental");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const HandleBackButton = () => {
    if (DisplayItem === "Governance") {
      setDisplayItem("Social");
    } else if (DisplayItem === "Social") {
      setDisplayItem("Environmental");
    }
  };
console.log(EnvTopicName)
  return (
    <Box p={2}>
      <Grid container direction={"column"}>
        <Grid item>
          <Typography
            sx={{ color: "#061826", fontSize: "20px", fontWeight: 600 }}
          >
            Compliance
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: "#B2B7BC", fontSize: "14px" }}>
            Please Provide Your Inputs For Goals
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-between"} mt={2}>
        <Grid item>
        <FilterButtons
                FilterType={DisplayItem}
                handleChange={handleChange}
              />
        </Grid>
        <Grid item>
          <Grid container direction={"row"} spacing={2}>
            <Grid item sx={{ marginRight: 5 }}>
              <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                Environment
              </Typography>
              <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                in-Progress
              </Typography>
            </Grid>
            <Grid item sx={{ marginRight: 5 }}>
              <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                Social
              </Typography>
              <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                Not started
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "#B2B7BC", fontSize: "12px" }}>
                Governance
              </Typography>
              <Typography sx={{ color: "#384651", fontSize: "16px" }}>
                Not started
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1} p={1}>
        {DisplayItem === "Environmental" ? (
          <React.Fragment>
            {EnvTopicName ? EnvTopicName?.map((items, index) => {
              return (
                <Card
                  variant="outlined"
                  key={index}
                  sx={{
                    padding: "10px",
                    marginY: "10px",
                    border: "1px solid #D5D3F9",
                    borderRadius: "9px",
                    width: "100%",
                  }}
                >
                  <Grid container spacing={3} md={12}>
                    <Grid container item>
                      <Grid item>
                        <Chip
                          label={"Id-1"}
                          variant="outlined"
                          sx={{
                            backgroundColor: "#FEF5E9",
                            color: "#F09A1F",
                            paddingX: "10px",
                            border: "1px solid #FAE0BA",
                            borderRadius: "50px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#25A392",
                            fontSize: "16px",
                            fontWeight: 600,
                            marginLeft: "10px",
                          }}
                        >
                          {items}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent={"space-between"}>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          label="Project Name"
                          variant="outlined"
                          name="ProjectName"
                          value={EnvTopic[items]?.ProjectName}
                          onChange={(e) => {
                            handleOnchangeEnv(e,items);
                          }}
                          size="small"
                          focused={EnvTopic[items]?.ProjectName ? true : false}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          label="start Date"
                          variant="outlined"
                          type="date"
                          size="small"
                          name="startDate"
                          value={EnvTopic[items]?.startDate}
                          onChange={(e) => {
                            handleOnchangeEnv(e,items);
                          }}
                          focused
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                           label="End date"
                          variant="outlined"
                          type="date"
                          size="small"
                          name="endDate"
                          value={EnvTopic[items]?.endDate}
                          onChange={(e) => {
                            handleOnchangeEnv(e,items);
                          }}
                          focused
                        />
                      </Grid>
                      <Grid item>
                        {/* <IconButton
                          onClick={() => {
                            additems(items, index);
                          }}
                        >
                          <AddCircleOutlinedIcon />
                        </IconButton> */}
                        {/* <IconButton
                          onClick={() => {
                            handleDeleteitem(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton> */}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          label="Value"
                          variant="outlined"
                          size="small"
                          type="number"
                          name="value"
                          value={EnvTopic[items]?.value}
                          onChange={(e) => {
                            handleOnchangeEnv(e,items);
                          }}
                          focused={EnvTopic[items]?.value ? true : false}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            HandleSaveData(items);
                          }}
                        >
                          <BeenhereIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              );
            }) : <Grid container sx={{display: 'flex',  justifyContent:'center',flexDirection:"column"}}>
            <img src={No_Selection} alt="No Selection" />
            <Typography textAlign={"center"}> Please complete the materiality assessment before proceeding to set your goals</Typography>
           </Grid> }
            
          </React.Fragment>
        ) : (
          <React.Fragment>
            {DisplayItem === "Social" ? (
              <React.Fragment>
                {SocTopicName ? SocTopicName?.map((items, index) => {
                  return (
                    <Card
                    variant="outlined"
                    key={index}
                    sx={{
                      padding: "10px",
                      marginY: "10px",
                      border: "1px solid #D5D3F9",
                      borderRadius: "9px",
                      width:"100%"
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid container item>
                        <Grid item>
                          <Chip
                            label={"Id-1"}
                            variant="outlined"
                            sx={{
                              backgroundColor: "#FEF5E9",
                              color: "#F09A1F",
                              paddingX: "10px",
                              border: "1px solid #FAE0BA",
                              borderRadius: "50px",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#25A392",
                              fontSize: "16px",
                              fontWeight: 600,
                              marginLeft: "10px",
                            }}
                          >
                            {items}
                          </Typography>
                        </Grid>
  
                        <Grid item>
                          {/* <Typography
                            sx={{
                              color: "#061826",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            Target 10
                          </Typography> */}
                        </Grid>
                      </Grid>
                      <Grid container item justifyContent={"space-between"}>
                        <Grid item>
                          <TextField
                            id="outlined-basic"
                            label="Project Name"
                            variant="outlined"
                            name="ProjectName"
                            value={SocTopic[items]?.ProjectName}
                            onChange={(e) => {
                              handleOnchangeSoc(e,items);
                            }}
                            size="small"
                            focused={SocTopic[items]?.ProjectName  ? true : false}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="outlined-basic"
                            label="start Date"
                            variant="outlined"
                            type="date"
                            size="small"
                            name="startDate"
                            value={SocTopic[items]?.startDate}
                            onChange={(e) => {
                              handleOnchangeSoc(e,items);
                            }}
                            focused
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="outlined-basic"
                            label="End date"
                            variant="outlined"
                            type="date"
                            size="small"
                            name="endDate"
                            value={SocTopic[items]?.endDate}
                            onChange={(e) => {
                              handleOnchangeSoc(e,items);
                            }}
                            focused
                          />
                        </Grid>
                        <Grid item>
                          {/* <IconButton
                            onClick={() => {
                              additems(items, index);
                            }}
                          >
                            <AddCircleOutlinedIcon />
                          </IconButton> */}
                          {/* <IconButton
                            onClick={() => {
                              handleDeleteitem(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton> */}
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item>
                          <TextField
                            id="outlined-basic"
                            label="Value"
                            variant="outlined"
                            size="small"
                            type="number"
                            name="value"
                            value={SocTopic[items]?.value}
                            onChange={(e) => {
                              handleOnchangeSoc(e,items);
                            }}
                            focused={SocTopic[items]?.value  ? true : false}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              HandleSaveData(items);
                            }}
                          >
                            <BeenhereIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                  );
                }) : 
                <Grid container sx={{display: 'flex',  justifyContent:'center',flexDirection:"column"}}>
                <img src={No_Selection} alt="No Selection" />
                <Typography textAlign={"center"}> Please complete the materiality assessment before proceeding to set your goals</Typography>
               </Grid>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {GovTopicName ? GovTopicName?.map((items, index) => {
                  return (
                    <Card
                  variant="outlined"
                  key={index}
                  sx={{
                    padding: "10px",
                    marginY: "10px",
                    border: "1px solid #D5D3F9",
                    borderRadius: "9px",
                    width:"100%"
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid container item>
                      <Grid item>
                        <Chip
                          label={"Id-1"}
                          variant="outlined"
                          sx={{
                            backgroundColor: "#FEF5E9",
                            color: "#F09A1F",
                            paddingX: "10px",
                            border: "1px solid #FAE0BA",
                            borderRadius: "50px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#25A392",
                            fontSize: "16px",
                            fontWeight: 600,
                            marginLeft: "10px",
                          }}
                        >
                          {items}
                        </Typography>
                      </Grid>

                      <Grid item>
                        {/* <Typography
                          sx={{
                            color: "#061826",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Target 10
                        </Typography> */}
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent={"space-between"}>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          label="Project Name"
                          variant="outlined"
                          name="ProjectName"
                          value={GovTopic[items]?.ProjectName}
                          onChange={(e) => {
                            handleOnchangeGov(e,items);
                          }}
                          size="small"
                          focused={GovTopic[items]?.ProjectName ? true: false}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          label="start Date"
                          variant="outlined"
                          type="date"
                          size="small"
                          name="startDate"
                          value={GovTopic[items]?.startDate}
                          onChange={(e) => {
                            handleOnchangeGov(e,items);
                          }}
                          focused
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                           label="End date"
                          variant="outlined"
                          type="date"
                          size="small"
                          name="endDate"
                          value={GovTopic[items]?.endDate}
                          onChange={(e) => {
                            handleOnchangeGov(e,items);
                          }}
                          focused
                        />
                      </Grid>
                      <Grid item>
                        {/* <IconButton
                          onClick={() => {
                            additems(items, index);
                          }}
                        >
                          <AddCircleOutlinedIcon />
                        </IconButton> */}
                        {/* <IconButton
                          onClick={() => {
                            handleDeleteitem(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton> */}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          label="Value"
                          variant="outlined"
                          size="small"
                          type="number"
                          name="value"
                          value={GovTopic[items]?.value}
                          onChange={(e) => {
                            handleOnchangeGov(e,items);
                          }}
                          focused={GovTopic[items]?.value ?  true : false}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            HandleSaveData(items);
                          }}
                        >
                          <BeenhereIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
                  );
                }) : 
                <Grid container sx={{display: 'flex',  justifyContent:'center',flexDirection:"column"}}>
                <img src={No_Selection} alt="No Selection" />
                <Typography textAlign={"center"}> Please complete the materiality assessment before proceeding to set your goals</Typography>
               </Grid>}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Grid>
      <Box>
        <Grid container justifyContent={"space-between"} my={1}>
          <Grid item>
            <Button
              sx={{
                backgroundColor: " #DFE1E3",
                border: "1px solid #CBE5E1",
                textTransform: "capitalize",
                borderRadius: "6px",
                paddingX: "10px",
                color: "#8C959B",
              }}
              onClick={HandleBackButton}
              disabled={DisplayItem === "Environmental"}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: "white",
                marginLeft: "5px",
                color: "#B2B7BC",
                borderRdius: "6px",
                textTransform: "capitalize",
                border: "1px solid #DFE1E3",
              }}
            >
              Skip
            </Button>
            <Button
              sx={{
                backgroundColor: "#25A392",
                marginLeft: "5px",
                color: "white",
                borderRdius: "6px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                HandleChangeTonext(DisplayItem);
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={OpenselectModal}
        setOpen={setOpenselectModal}
        title={`${TopiName},${seletedYear}`}
        actions={
          <Grid container direction={"column"} alignContent={"center"}>
            <Grid item my={1}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  paddingX: "20px",
                  backgroundColor: "#25A392",
                  "&:hover": {
                    backgroundColor: " #25a392",
                  },
                }}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  paddingX: "20px",
                  textTransform: "capitalize",
                  color: "#F37472",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  setOpenselectModal(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container mt={1} spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Year"
                defaultValue={seletedYear}
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="startDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="EndDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="value"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
}

export default Compliance;
