import React, { useCallback, useEffect, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
//import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CaseComplianceGraph from "./CaseComplianceGraph";
import Carousel from "react-material-ui-carousel";
//import { Carousel } from 'antd';
//import Curosels from "styles/Compliance/Curosels.css";
import ComplienceTable from "./ComplianceTable";
import DisclouserComplianceGraph from "./DisclouserComplianceGraph";
import BarGraph from "./BarGraph";
import DisclouserComplianceTable from "./DisclouserComplianceTable";
import Graph from "./Graph";

// Table style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "darkblue",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));



const data={
  series: [ 55, 13, 43],
  labels: [ 'Location', 'Rate of Open1', 'Rate of Close']

}
 

export default function Case_Compliance() {
  const [activeChild, setActiveChild] = useState(0);

  

  // Basically items = [1, 2, 3, 4]
  const items = useMemo(
    () => [
      <CaseComplianceGraph props={data} />,
      <CaseComplianceGraph props={data}/>,
      <CaseComplianceGraph props={data}/>,
      <CaseComplianceGraph props={data}/>,
      <BarGraph props={data} />,
      <Graph props={data} />
    ],
    []
  );


  // The Keypress Event Handler
  const changeChild = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        // If supposed previous child is < 0 set it to last child
        setActiveChild((a) => (a - 1 < 0 ? items.length - 1 : a - 1));
      } else if (e.key === "ArrowRight") {
        // If supposed next child is > length -1 set it to first child
        setActiveChild((a) => (a + 1 > items.length - 1 ? 0 : a + 1));
      }
    },
    [items]
  );

  // Set and cleanup the event listener
  useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  });

  return (
   
    <div>
      <div style={{ display: "flex" }}>
        
        <div
          style={{
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "50%",
          }}
        >
      
          <div style={{border:"1px solid #00000033", padding:"10px 20px", borderRadius:"4px",minHeight:"450px"}}>
          
          <div style={{ width: "100%" }}>
            <ComplienceTable/>
          </div>

          </div>
          
          {/* End of the complience table */}


          <div style={{border:"1px solid #00000033", padding:"10px 20px", borderRadius:"4px",minHeight:"450px"}}>
       
          <div style={{ width: "100%" }}>
            <DisclouserComplianceTable />
          </div>
          </div>
        </div>
        <div
          style={{
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "50%",
          }}
        >
            <div style={{border:"1px solid #00000033", padding:"10px 20px", borderRadius:"4px",minHeight:"495px"}}> 
            <div><h1 style={{textAlign:"center"}}><b>Case Compliance Graph</b></h1></div>
          <div style={{ width: "100%",paddingTop:"4rem"  }}>
            
            <Carousel
              index={activeChild} // <-- This controls the activeChild
              autoPlay={false} // <-- You probaly want to disable this for our purposes
              navButtonsAlwaysVisible
            >
              {items.map((i) => {
                return (
                  <p align="center" key={i}>
                    {i}
                  </p>
                );
              })}
            </Carousel>
            </div>
          </div>
          <div style={{border:"1px solid #00000033", padding:"10px 20px", borderRadius:"4px",minHeight:"486px"}}>
          <div><h1 style={{textAlign:"center"}}><b>Disclosure Compliance Graph</b></h1></div> 
          <div style={{ width: "100%",paddingTop:"4rem" }}>
            <Carousel
              index={activeChild} // <-- This controls the activeChild
              autoPlay={false} // <-- You probaly want to disable this for our purposes
              navButtonsAlwaysVisible
            >
              {items.map((i) => {
                return (
                  <p align="center" key={i}>
                    {i}
                  </p>
                );
              })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}