import React from "react";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import "../styles/Forms_Styles/Form_Percentage.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";
export default function Percentage({ onChange,getAnswerDisable }) {
  const { Answer } = useContext(NoteContext);
  let data="";
  try {
    
    data =
      typeof Answer.UpdatedAnswer === "string" &&
      Answer.UpdatedAnswer.length !== 0
        ? JSON.parse(Answer.UpdatedAnswer)
        : typeof Answer.UpdatedAnswer === "object" &&
          Answer.UpdatedAnswer.length !== 0
        ? Answer.UpdatedAnswer
        : "";
  } catch (error) {
    data="";
  }

  return (
    <FormControl variant="outlined" disabled={getAnswerDisable}>
      <Input
        id="standard-adornment-weight"
        type="number"
        value={data ?? ""}
        onChange={(e) => {
          onChange(`${Math.min(100, Math.max(0, e.target.value))}`);
        }}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        inputProps={{
          "aria-label": "weight",
        }}
      />
    </FormControl>
  );
}
