import axios from "axios";
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_API_URL_Neo4j } = process.env;


export const getForms = async (filingData) => {
    
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    return await axios({
        method: "GET",
        url: `${REACT_APP_API_URL}/filings/esg/filingdropdowns?filingCategory=${filingData.filingCategory}&formType=${filingData.formType}&state = ${filingData.state}`,
        headers: {
            "Content-Type": "application/json",
            accessToken: tokens.accessToken,
            Authorization: `Bearer ${tokens.idToken}`,
        },
    });
};

export const getUsStatesList = async (countrycode) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    return await axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/filings/location/stateList?countryId=${countrycode}`,
      headers: {
        "Content-Type": "application/json",
        accessToken: tokens.accessToken,
        Authorization: `Bearer ${tokens.idToken}`,
      },
    });
  };


export const postNewFilingGenAI = async (
    DisclosureName,
    disclosurevalue,
    selectedDomain,
    fromtDate,
    toDate,
    deadlineDate
) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    console.log( DisclosureName,
        disclosurevalue,
        selectedDomain,
        fromtDate,
        toDate,
        deadlineDate)

    return await axios({
        method: "POST",
        url: `${REACT_APP_API_URL}/filings/esg/postNewFilingGenAI`,
        params: {
            disclosureName: DisclosureName,
            domain: selectedDomain,
            filingType: "Insurance",
            disclosure: disclosurevalue,
            periodStart: fromtDate,
            periodEnd: toDate,
            periodDeadline: deadlineDate,
        },
        data:{"COMPANY":localStorage.getItem("UserSelectedDomain").split(".")[0]},
        headers: {
            "Content-Type": "application/json",
            accessToken: tokens.accessToken,
            Authorization: `Bearer ${tokens.idToken}`,
        },
    })
        .then((response) => response)
        .catch((error) => console.log(error));
};
export const get_Newfilling_Insu = async (
    DisclosureName,
    disclosurevalue,
    selectedDomain,
    fromtDate,
    toDate,
    deadlineDate
) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    console.log( DisclosureName,
        disclosurevalue,
        selectedDomain,
        fromtDate,
        toDate,
        deadlineDate)

    return await axios({
        method: "POST",
        url: `${REACT_APP_API_URL}/filings/esg/newfiling`,
        params: {
            disclosureName: DisclosureName,
            domain: selectedDomain,
            filingType: "Insurance",
            disclosure: disclosurevalue,
            periodStart: fromtDate,
            periodEnd: toDate,
            periodDeadline: deadlineDate,
        },
        headers: {
            "Content-Type": "application/json",
            accessToken: tokens.accessToken,
            Authorization: `Bearer ${tokens.idToken}`,
        },
    })
        .then((response) => response)
        .catch((error) => console.log(error));
};