import React, { useEffect, useState } from "react";
import CheckBoxDropdownList from '../../components/CheckBoxDropdownList.js';
import {getBaseTree} from 'ESG/services/Fetch';
import { LinearProgress } from '@mui/material';

const ViewPermissions = () => {

    const [state, setState] = useState('');
    const [loading, setLoading] = useState(true);// To Set and Unset Loading when data getting backend 
    
    const fetchtree = async () => {
        getBaseTree('GRI').then((data) => {
          setState(data.basetree[0]);
          setLoading(false);
        });
    };
    
    useEffect(() => {
        fetchtree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div>
            {loading?<LinearProgress />:<CheckBoxDropdownList data={state}/>}
        </div>
    );
}

export default ViewPermissions;