// import React, { useEffect, useState } from "react";
// import Grid from "@mui/material/Grid";
// import Chart from "react-apexcharts";
// import Box from "@mui/material/Box";
// import "../../styles/Report/Report.css";


// function EmpReport(props) {
//   const [Info, setInfo] = useState("");
//   const [isloading, setisloading] = useState(false);
//   const [itemsfirst, setitemsfirst] = useState();
//   const [itemssecond, setitemssecond] = useState();
//   const [page, setpage] = useState(false);
//   const FirstItem = [];
//   const secondItem = [];

//   useEffect(() => {
   
//       setInfo(
//         Object.values(props.data.reportData["GRI 2.7 Employees"])[0].note.replace(
//           /<\/?[^>]+(>|$)/g,
//           ""
//         )
//       );
//       //console.log(Object.values(x.data.reportData[" Employees "]));
//       setpage(true);
   
//     Spilt();
//   }, [page,props]);

//   const Spilt = () => {
//     const charLimit = 50;
//     // console.log(Info);
//     let result = Info.split(/(\s+)/).reduce((output, item) => {
//       let last = output.pop() || ""; //get the last item
//       return last.length + item.length > charLimit //would adding the current item to it exceed 36 chars?
//         ? [...output, last, item] //Yes: start a new item
//         : [...output, last + item]; //No:  add to previous item
//     }, []);
//     //  console.log("Result:");
//     //  console.log(result);
//     //  console.log(result.length);
//     if (result.length > 20) {
//       for (let i = 0; i < result.length; i++) {
//         if (i < 13) {
//           FirstItem.push(result[i]);
//         } else {
//           secondItem.push(result[i]);
//         }
//       }
//     }
//     setitemsfirst(FirstItem);
//     setitemssecond(secondItem);
//     setisloading(true);
//   };

//   const Barcharts = {
//     series: [
//       {
//         name: "Permanent employees",
//         data: [44, 55, 41, 37, 22, 43, 21],
//       },
//       {
//         name: "Temporary employees",
//         data: [53, 32, 33, 52, 13, 43, 32],
//       },
//       {
//         name: "Full-time employees",
//         data: [12, 17, 11, 9, 15, 11, 20],
//       },
//       {
//         name: "Part-time employees",
//         data: [9, 7, 5, 8, 6, 9, 4],
//       },
//       {
//         name: "Non-guaranteed hours employees",
//         data: [25, 12, 19, 32, 25, 24, 10],
//       },
//     ],
//     options: {
//       chart: {
//         type: "bar",
//         height: 450,
//         width: 650,
//         stacked: true,
//       },
//       plotOptions: {
//         bar: {
//           horizontal: true,
//           dataLabels: {
//             total: {
//               enabled: true,
//               offsetX: 0,
//               style: {
//                 fontSize: "13px",
//                 fontWeight: 900,
//               },
//             },
//           },
//         },
//       },
//       stroke: {
//         width: 1,
//         colors: ["#fff"],
//       },
//       title: {
//         text: "Data on number of employees",
//         style: {
//           fontSize: "16px",
//           fontWeight: "bold",
//           fontFamily: "Lucida Sans",
//           color: "#263238",
//         },
//       },
//       xaxis: {
//         categories: ["INDIA", "USA", "UK", "UA", "CHINA"],
//         labels: {
//           formatter: function (val) {
//             return val + "K";
//           },
//         },
//         title: {
//           text: "No of employees",
//         },
//       },
//       yaxis: {
//         title: {
//           text: "Countries",
//         },
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return val + "K";
//           },
//         },
//       },
//       fill: {
//         opacity: 1,
//       },
//       legend: {
//         position: "bottom",
//         horizontalAlign: "left",
//         offsetX: 40,
//         // show:false,
//       },
//     },
//   };

//   const state = {
//     series: [25, 15, 44, 55, 41],
//     options: {
//       chart: {
//         width: "100%",
//         type: "pie",
//       },
//       labels: ["INDIA", "USA", "UK", "UA", "CHINA"],
//       theme: {
//         monochrome: {
//           enabled: false,
//         },
//       },
//       plotOptions: {
//         pie: {
//           dataLabels: {
//             offset: -5,
//           },
//         },
//       },
//       title: {
//         text: "",
//       },
//       dataLabels: {
//         formatter(val, opts) {
//           const name = opts.w.globals.labels[opts.seriesIndex];
//           return [name, val.toFixed(1) + "%"];
//         },
//       },
//       legend: {
//         show: true,
//       },
//     },
//   };
//   return (
//     <>
//       {page ? (
//         <Box  className="EmpBox">
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
//               <h4>Apple.Inc </h4>
//               <ul style={{ listStyleType: "square", textAlign: "left" }}>
//               <li key ={1}>Introduction </li>
//                   <li key ={2}>About the Report</li>
//                   <li key ={3} >Policies</li>
//                   <li key ={4}>Employees details</li>
//                   <li key ={5}>performance and career development</li>
//                   <li key ={6}>Sectors</li>
//               </ul>
//             </Grid>
//             <Grid item xs={12} md={5}  className="grid1">
//               <h3 className="hName">
//                 <u>Employees details</u>
//               </h3>
//               {isloading ? (
//                 <>
//                   <h6  className="content">{itemsfirst}</h6>
//                   <Chart
//                     options={state.options}
//                     series={state.series}
//                     type="pie"
//                     width="300"
//                   />
//                   <h6 className="content">{itemssecond}</h6>
//                 </>
//               ) : (
//                 <h1>page is loading</h1>
//               )}
//             </Grid>
//             <Grid item xs={12} md={5} className="grid2">
//               <Chart
//                 options={Barcharts.options}
//                 series={Barcharts.series}
//                 type="bar"
//                 width="500"
//                 height={500}
//               />
//             </Grid>
//           </Grid>
//         </Box>
//       ) : (
//          <div className="loaders">Loading...</div>
//       )}
//     </>
//   );
// }

// export default EmpReport;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Chart from "react-apexcharts";
import Box from "@mui/material/Box";
import "../../styles/Report/Report.css";

function EmpReport(props) {
  const [Info, setInfo] = useState("");
  const [isloading, setisloading] = useState(false);
  const [itemsfirst, setitemsfirst] = useState([]);
  const [itemssecond, setitemssecond] = useState([]);
  const [page, setpage] = useState(false);

  useEffect(() => {
    const Spilt = () => {
      const charLimit = 50;
      let result = Info.split(/(\s+)/).reduce((output, item) => {
        let last = output.pop() || "";
        return last.length + item.length > charLimit
          ? [...output, last, item]
          : [...output, last + item];
      }, []);
      if (result.length > 20) {
        setitemsfirst(result.slice(0, 13));
        setitemssecond(result.slice(13));
      } else {
        setitemsfirst(result);
        setitemssecond([]);
      }
      setisloading(true);
    };

    setInfo(
      Object.values(props.data.reportData["GRI 2.7 Employees"])[0].note.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
    );
    setpage(true);
    Spilt();
  }, [Info, props]);

  const Barcharts = {
    series: [
      {
        name: "Permanent employees",
        data: [44, 55, 41, 37, 22, 43, 21],
      },
      {
        name: "Temporary employees",
        data: [53, 32, 33, 52, 13, 43, 32],
      },
      {
        name: "Full-time employees",
        data: [12, 17, 11, 9, 15, 11, 20],
      },
      {
        name: "Part-time employees",
        data: [9, 7, 5, 8, 6, 9, 4],
      },
      {
        name: "Non-guaranteed hours employees",
        data: [25, 12, 19, 32, 25, 24, 10],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 450,
        width: 650,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "Data on number of employees",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          fontFamily: 'Microsoft YaHei',
          color: "#263238",
        },
      },
      xaxis: {
        categories: ["INDIA", "USA", "UK", "UA", "CHINA"],
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
        title: {
          text: "No of employees",
        },
      },
      yaxis: {
        title: {
          text: "Countries",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };

  const state = {
    series: [25, 15, 44, 55, 41],
    options: {
      chart: {
        width: "100%",
        type: "pie",
      },
      labels: ["INDIA", "USA", "UK", "UA", "CHINA"],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },
      title: {
        text: "",
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(1) + "%"];
        },
      },
      legend: {
        show: true,
      },
    },
  };

  return (
    <>
      {page ? (
        <Box className="EmpBox">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
              <h4>Apple.Inc</h4>
              <ul style={{ listStyleType: "square", textAlign: "left" }}>
                <li key={1}>Introduction</li>
                <li key={2}>About the Report</li>
                <li key={3}>Policies</li>
                <li key={4}>Employees details</li>
                <li key={5}>performance and career development</li>
                <li key={6}>Sectors</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={5} className="grid1">
              <h3 className="hName">
                <u>Employees details</u>
              </h3>
              {isloading ? (
                <>
                  <h6 className="content">{itemsfirst}</h6>
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="pie"
                    width="300"
                  />
                  <h6 className="content">{itemssecond}</h6>
                </>
              ) : (
                <h1>page is loading</h1>
              )}
            </Grid>
            <Grid item xs={12} md={5} className="grid2">
              <Chart
                options={Barcharts.options}
                series={Barcharts.series}
                type="bar"
                width="500"
                height={500}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div className="loaders">Loading...</div>
      )}
    </>
  );
}

export default EmpReport;


