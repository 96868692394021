import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    InputLabel,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    MenuItem
  } from "@mui/material";
import Select from "react-select";
import withSnackbar from "../../components/Alert/withSnackbar"; 
import Loader from "Compliance/CaseManagment/Loader";
const {
  getCheckList 
  } = require("../../Compliance/services/CaseFetch");

const InsuranceBasicInfo=({casetype,insurancedata,setinsurancedata,caseworkers,subworker,setsubworker,subworkeremail,setsubworkeremail,edit,iscreatecase,showSnackbar})=>{
  const [disablefirstlastName,setDisablefirstlastName]=useState(false);
  const [disable,setDisable]=useState(false);
  const [enable,setEnable]=useState();
  const [loading,setLoading]=useState(false);
  const[checkData,setCheckData]=useState([]);
  //const enable=edit==true?false:true;
  useEffect(()=>{

    setLoading(true)
    if(iscreatecase && JSON.parse(iscreatecase)){
      setDisable(false);
      setEnable(false)
    }
   else if(edit=="true"){
      setEnable(false)
      setDisable(true);
    }
     else if(edit=="false"){
      setDisable(true);
      setEnable(true)
    }
     if(checkData !=null){
      setLoading(false)
     }
     if(casetype){
    fetchData()
     }
  },[])

  const fetchData = async () => {
    try {
      const [chdata] =
        await Promise.all([
          getCheckList(casetype),
        ]);
      var checkdata = chdata?.data?.values.map((chktype) => ({
        value: chktype,
        label: chktype,
      }));
      setCheckData(checkdata);
      //setLoading(false);
    } catch (error) {
      ///setLoading(false);
      showSnackbar("unable to fetch data", "error");
    }
  };
  const selectedOption = checkData.find(option => option.value === insurancedata.subcategory);
 
return(
    <>
    {loading ? <Loader/> :
    <>
    <Grid className="rowcss">
               
                <Grid className="common">
                  <InputLabel className="commonLabel">Sub Category</InputLabel>
              

                  {(!selectedOption && !JSON.parse(iscreatecase)) ?(
         <TextField
         required
         size="small"
         id="outlined-read-only-input"
         value={insurancedata.case_type}
         disabled={disable}
       />
      ):  
      <Select

                  value={selectedOption}
                  onChange={(e) => {
                    setinsurancedata((prevstate) => {
                      return {
                        ...prevstate,
                        subcategory: e.value,
                      };
                    });
                  
                  }}
                  options={checkData}
                  isSearchable={true}
                  //placeholder="Select a state..."
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                     // borderColor: errors.type ? "red" : "",
                    }),
                  }}
                  isDisabled={disable}
                />
               } 
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                    Case Title <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    id="outlined-read-only-input"
                    value={insurancedata.caseTitle}
                    onChange={(e) => {
                      // if (errors["first_name"]) {
                      //   setErrors((prevErrors) => ({
                      //     ...prevErrors,
                      //     ["first_name"]: false,
                      //   }));
                      // }
                      setinsurancedata((prevstate) => {
                        return {
                          ...prevstate,
                          caseTitle: e.target.value,
                        };
                      });
                    }}
                    // error={errors?.first_name}
                    // helperText={
                    // errors?.first_name ? "Please provide your first name" : ""
                    // }
                    // disabled={disablefirstlastName||disable}
                    disabled={disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                   Date Opened <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    type="date"
                    required
                    size="small"
                    value={insurancedata.dateOpened}
                    onChange={(e) => {
                      // if (errors["dateOpened"]) {
                      //   setErrors((prevErrors) => ({
                      //     ...prevErrors,
                      //     ["dateOpened"]: false,
                      //   }));
                      // }
                      setinsurancedata((prevstate) => {
                        return {
                          ...prevstate,
                          dateOpened: e.target.value,
                        };
                      });
                    }}
                    // error={errors?.accountOpeningDate}
                    // helperText={
                    //   errors?.accountOpeningDate
                    //     ? "Please provide account opening date"
                    //     : ""
                    // }
                     disabled={disable}
                  />
                </Grid>
              </Grid>
              <Grid className="rowcss">
               
                <Grid className="common">
                  <InputLabel className="commonLabel">
                   Date Closed <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    type="date"
                    required
                    size="small"
                    value={insurancedata.dateClosed}
                    InputProps={{
                      inputProps: { min: insurancedata.dateOpened }, 
                    }}
                    disabled={!insurancedata.dateOpened || disable}
                    onChange={(e) => {
                      // if (errors["accountOpeningDate"]) {
                      //   setErrors((prevErrors) => ({
                      //     ...prevErrors,
                      //     ["accountOpeningDate"]: false,
                      //   }));
                      // }
                      setinsurancedata((prevstate) => {
                        return {
                          ...prevstate,
                          dateClosed: e.target.value,
                        };
                      });
                    }}
                    // error={errors?.accountOpeningDate}
                    // helperText={
                    //   errors?.accountOpeningDate
                    //     ? "Please provide account opening date"
                    //     : ""
                    // } 
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                  Regulatory Authority <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    id="outlined-read-only-input"
                    value={insurancedata.regulatoryAuthority}
                    onChange={(e) => {
                      // if (errors["first_name"]) {
                      //   setErrors((prevErrors) => ({
                      //     ...prevErrors,
                      //     ["first_name"]: false,
                      //   }));
                      // }
                      setinsurancedata((prevstate) => {
                        return {
                          ...prevstate,
                          regulatoryAuthority: e.target.value,
                        };
                      });
                    }}
                    // error={errors?.first_name}
                    // helperText={
                    //   errors?.first_name ? "Please provide your first name" : ""
                    // }
                     disabled={disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                    Assigned To <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    value={insurancedata.current_case_worker_email+ "," +insurancedata.current_case_worker}
                    size="small"
                    select
                    onChange={(e) => {
                    //   if(errors){
                    //   if (errors["current_case_worker"]) {
                    //     setErrors((prevErrors) => ({
                    //       ...prevErrors,
                    //       ["current_case_worker"]: false,
                    //       ["current_case_worker_email"]: false,
                    //     }));
                    //   }
                    // }
                      setsubworker(e.target.value.split(",")[1]);
                      setsubworkeremail(e.target.value.split(",")[0]);
                      setinsurancedata((prevstate) => {
                        return {
                          ...prevstate,
                          current_case_worker: e.target.value.split(",")[1],
                          current_case_worker_email:
                            e.target.value.split(",")[0],
                        };
                      });
                    }}
                    // error={errors?.current_case_worker}
                    // helperText={
                    //   errors?.current_case_worker
                    //     ? "Please select current case worker"
                    //     : ""
                    // }
                    disabled={disable}
                  >
                    {caseworkers &&
                      caseworkers?.map((item) => (
                        <MenuItem
                          key={item.email}
                          //value={item.email + "," + item.name}
                          value={item.email+ "," +item.name}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid className="rowcss">
              <Grid className="common">
                  <InputLabel className="commonLabel">
                  Department <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    id="outlined-read-only-input"
                    value={insurancedata.department}
                    onChange={(e) => {
                      // if (errors["first_name"]) {
                      //   setErrors((prevErrors) => ({
                      //     ...prevErrors,
                      //     ["first_name"]: false,
                      //   }));
                      // }
                      setinsurancedata((prevstate) => {
                        return {
                          ...prevstate,
                          department: e.target.value,
                        };
                      });
                    }}
                    // error={errors?.first_name}
                    // helperText={
                    //   errors?.first_name ? "Please provide your first name" : ""
                    // }
                  disabled={disable}
                  />
                </Grid>
              </Grid>
             
              <Grid>
                <Grid className="colcss">
                  <InputLabel className="commonLabel">Description</InputLabel>
                  <TextField
                    multiline
                    rows={4}
                    id="standard-basic"
                    required
                    value={insurancedata.description}
                    onChange={(e) =>
                      setinsurancedata((prevstate) => {
                        return { ...prevstate, description: e.target.value };
                      })
                    }
                    disabled={enable}
                  />
                </Grid>
              </Grid>
              </>
}   
    </>
)
}


export default withSnackbar(InsuranceBasicInfo, "InsuranceBasicInfo", "success");