import React from 'react';
import { Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import "../styles/Forms_Styles/Form_Currency.css"
import { Autocomplete } from '@mui/material';
import { useContext } from "react";
import NoteContext from "context/PageContext";


export default function Currency({  FieldValues, onChange ,getAnswerDisable}) {
  const {Answer}=useContext(NoteContext);
  let data={};
  try {
    
     data= typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0  ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : {}
  } catch (error) {
    data={};
  }
  

  return (
    <Grid container spacing={2}>
    <Grid item xs={4} md={4}>
      {FieldValues === undefined ? null :
           <Autocomplete
           fullWidth
           size='small'
           options={FieldValues}
          //  value={data.Symbol.lable}
           getOptionLabel={(option) => option.label??''}
            value={data?.Symbol||''}
           isOptionEqualToValue={(option) => option.label??''}
           onChange={(e, Currency) => {
            onChange({Amount:data.Amount,Symbol:Currency})
          }}
            disabled={getAnswerDisable}
           renderInput={(params) => <TextField {...params}  helperText='Currency' variant="outlined" />}
         />
        }
           </Grid>
    <Grid item xs={8} md={8}>

      <TextField 
        fullWidth
        size='small'
        id="outlined-adornment-amount"
        helperText='Enter Amount'
        variant="outlined"
        type="number"
        disabled={getAnswerDisable}
        value={data.Amount}
        onChange={(e) => {

          onChange({Amount: e.target.value,Symbol:data.Symbol})
        }}
      />
  </Grid></Grid>
  );
}
