import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import NoteContext from "context/PageContext";
import { categoryDropdown, domainDropdownList } from "../services/FormCreationFetch";

const CloneDisclosureModal = ({ open, handleClose, item, handleClone }) => {
  const contextData = useContext(NoteContext);
  const [disclosurename, setDisclosureName] = useState("");
  const [effectivedate, seteffectivedate] = useState("");
  const [version, setversion] = useState("");
  const [enforcingagency, setenforcingagency] = useState("");
  const [enforcingagencyValue, setenforcingagencyValue] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [domainValues, setdomainValues] = useState([]);
  const defaultDomain = localStorage.getItem("UserSelectedDomain");
  const [selectedDomain, setselectedDomain] = useState(defaultDomain);

  useEffect(() => {
    const fetchdomainname = async () => {
      const data = await domainDropdownList();
      setdomainValues(data);
    };

    const category_dropdown = async () => {
      const data = await categoryDropdown();
      setCategoryList(data.data);
    };

    fetchdomainname();
    category_dropdown();
  }, []);

  const handleAddDisclosure = () => {
    handleClone(item, disclosurename, effectivedate, version, enforcingagency);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Clone Disclosure :{item && item.name } </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "5px" }}>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              required
              id="disclosurename-input"
              name="disclosurename"
              label="Disclosure Name"
              type="text"
              value={disclosurename}
              onChange={(e) => setDisclosureName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              id="effectivedate-input"
              name="effectivedate"
              type="date"
              required
              value={effectivedate}
              onChange={(e) => seteffectivedate(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              id="version-input"
              name="version"
              label="Version Number"
              type="number"
              required
              value={version}
              onChange={(e) => setversion(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#25a392",
            marginLeft: "10px",
            flexDirection: "row",
          }}
          onClick={handleAddDisclosure}
          disabled={!version || !effectivedate || !disclosurename}
        >
          Create
        </Button>
        <Button
          variant="text"
          style={{ color: "red", flexDirection: "row" }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloneDisclosureModal;
