import React from 'react'
import SEClogo from "../../../images/SEClogo.jpg";
import ADVLogo from "../../../images/ADV_Logo.png"
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function Index() {
  return (
   
    <>
    <Box>
          <Grid>
            <Grid item xs={12} md={6} sx={{ position: 'absolute', left: '50%', top: '45%',transform: 'translate(-50%, -50%)'}}>
              <img
                src={ADVLogo}
                alt="tcfd"
                
                // className="img"
              />
               
           <Grid item xs={12} md={6} sx={{marginTop:'25px'}}>
           <h5 style={{color:"#006699",textAlign:"center"}} >UNIFORM APPLICATION FOR INVESTMENT ADVISER REGISTRATION AND REPORT FORM BY EXEMPT REPORTING ADVISERS</h5>
        </Grid>
            </Grid>
          </Grid>
        </Box>
        </>
  )
}

export default Index