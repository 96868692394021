import React, { useEffect, useState } from "react";
import "../../styles/Report/Report.css";
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';




const ConflictofInterest = (props)  =>{
    const [Processes,setProcesses] = useState()
    const [Stakeholders,setStakeholders] = useState()
    const [Shareholders,setShareholders] = useState()
    const [pageloaded, setpageloaded] = useState(false)
    useEffect (()=>{ 
        setProcesses((Object.values(props.data.reportData["GRI 2.15 Conflicts of interest"])[0]).answer)
        setStakeholders((Object.values(props.data.reportData["GRI 2.15 Conflicts of interest"])[1]).answer)
        setShareholders((Object.values(props.data.reportData["GRI 2.15 Conflicts of interest"])[2]).answer)
        setpageloaded(true);
    },[pageloaded,props])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h2>Conflicts of interest</h2>
                        <h6><b>Processes for the highest governance body to ensure that conflicts of interest are prevented and mitigated:</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Processes}}></div>
                        <h6><b>Conflicts of interest relating to cross-Stakeholders with suppliers and other stakeholders:</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Stakeholders}}></div>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h6><b>Conflicts of interest relating to the existence of controlling shareholders:</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Shareholders}}></div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default ConflictofInterest;