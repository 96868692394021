import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function EighthPage(props) {
  const [Questions, SetQuestions] = useState();
  const [Isloading, setIsloading] = useState(false);
  //const [Question1,setQuestion1]=useState();
  const [Answer, setAnswer] = useState();
  const [QuestionNum, setQuestionNumber] = useState();

  useEffect(() => {
    const FetchData = () => {
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber = [];
      //getting section 1b question in single array

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Custody"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Custody"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Custody"])[i].answer
        );
        QuestionNumber.push(
          Object.values(props.data.reportData["Custody"])[i].qno.substr(
            4,
            Object.values(props.data.reportData["Custody"])[i].qno.length
          )
        );
      }
      // console.log((Answer_array_b[11]));
      SetQuestions(questions_array_b);
      setAnswer(Answer_array_b);
      // console.log(Answer_array_b[11].substring(0,10));
      //console.log(Object.values(Answer_array_b[2])[0].Amount);
      setQuestionNumber(QuestionNumber);
    };
    FetchData();
    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  bgcolor: "lightgray",
                  position: "sticky",
                  top: "0",
                  padding: "2px",
                  height: "100",
                }}
              >
                <ul
                  style={{
                    listStyleType: "square",
                    textAlign: "left",
                    fontSize: "25px",
                  }}
                >
                  <li>Custody</li>
                </ul>
              </Grid>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                {Questions.map((x, index) => {
                  return (
                    <React.Fragment key={index}>
                      <b>{QuestionNum[index]}</b>
                      {"\u00A0"}
                      {"\u00A0"}
                      {x}
                      <br />
                      {index === 2 ? (
                        <ol>
                          <li>
                            Total Number of Clients :{" "}
                            {Object.values(Answer[index])[1]}
                          </li>
                          <li>
                            U.S. Dollar Amount:{" "}
                            {Object.values(Answer[index])[0].Symbol.label}
                            {Object.values(Answer[index])[0].Amount}
                          </li>
                        </ol>
                      ) : index === 6 ? (
                        <ol>
                          <li>
                            Total Number of Clients :{" "}
                            {Object.values(Answer[index])[1]}
                          </li>
                          <li>
                            U.S. Dollar Amount:{" "}
                            {Object.values(Answer[index])[0].Symbol.label}
                            {Object.values(Answer[index])[0].Amount}
                          </li>
                        </ol>
                      ) : index === 8 ? (
                        <ol>
                          {Answer[index].map((itimes, index1) => {
                            return <li key={index1}>{itimes}</li>;
                          })}
                        </ol>
                      ) : index === 11 ? (
                        <React.Fragment>
                          {Answer[index].substr(0, 10)}
                        </React.Fragment>
                      ) : (
                        Answer[index]
                      )}
                      <br />
                    </React.Fragment>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default EighthPage;