import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    InputLabel,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    MenuItem
  } from "@mui/material";
import Select from "react-select";
import withSnackbar from "../../components/Alert/withSnackbar"; 
import NoteContext from "context/PageContext";
const {
    getCountryList,
    getStateList,
    getUserList,
    verifyCustomer
  } = require("../../Compliance/services/CaseFetch");


const BasicInfo=({setdata,data,setexpdate,errors,setErrors,countryData,defaultCountry,setDefaultCountry,stateData,setStateData,setDefaultState,defaultState,caseworkers,subworker,setsubworker,subworkeremail,setsubworkeremail,edit,iscreatecase,showSnackbar})=>{
  const [disablefirstlastName,setDisablefirstlastName]=useState(false);
  const [disable,setDisable]=useState(false);
  const [enable,setEnable]=useState("");
  const ContextData = useContext(NoteContext);
  //const enable=edit==true?false:true;
  useEffect(()=>{
    if(iscreatecase && JSON.parse(iscreatecase)){
      setDisable(false);
      setEnable(false)
    }
  
    else if( edit && JSON.parse(edit)==false){
      setEnable(true)
      setDisable(true);
    }
  else if(JSON.parse(edit)==true){
      setDisable(true);
      setEnable(false)
    }
  },[])
    const handleCustomerIDChange =async()=>{
        try{
          var res=await verifyCustomer(data.customer_id)
          if(res.status==200){
            setdata((prevstate) => {
              return {
                ...prevstate,
                first_name: res.data.values.FirstName,
                last_name:res.data.values.LastName
              };
            })
            setDisablefirstlastName(true)
          }
        }
        
        
       catch(e){
    
        if(e.response.status==404){
          setdata((prevstate) => {
            return {
              ...prevstate,
              first_name: '',
              last_name:'',
              customer_id:''
            };
          })
          setDisablefirstlastName(false)
          showSnackbar(e.response.data.message,"error")
        }
      }
        
      }
      const handlecountryDropdownChange = async (cdata) => {
        if (cdata) {
          const { value, label } = cdata;
          setDefaultCountry(cdata);
          setdata((prevstate) => {
            return {
              ...prevstate,
              country: label,
              country_code: value,
            };
          });
          var data = await getStateList(value);
          var sdata = data.data.values;
    
          var sortedState = sdata.map((state) => ({
            value: state,
            label: state,
          }));
          setStateData(sortedState);
          setDefaultState(sortedState[0]);
        }
      };
    
      var handlestateDropdownChange = async (value) => {
        setDefaultState(value);
    
        setdata((prevstate) => {
          return {
            ...prevstate,
            state: value.label,
            state_code: value.code,
          };
        });
      };
  
return(
    <>
    <Grid className="rowcss">
                {/* <Grid className="common">
                  <InputLabel className="commonLabel">Case Category</InputLabel>
                  <TextField
                    value={data.case_type}
                    disabled
                    id="outlined-size-small"
                    defaultValue="Small"
                    size="small"
                  />
                </Grid> */}
                <Grid className="common">
                  <InputLabel className="commonLabel">Customer ID</InputLabel>
                  <TextField
                    value={data.customer_id}
                    onChange={(e) =>
                      setdata((prevstate) => {
                        return {
                          ...prevstate,
                          customer_id: e.target.value,
                        };
                      })
                    }
                    onBlur={handleCustomerIDChange} 
                    id="outlined-size-small"
                    defaultValue="Small"
                    size="small"
                    type="number"
                    disabled={disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                    First Name <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    id="outlined-read-only-input"
                    value={data.first_name}
                    onChange={(e) => {
                      if (errors["first_name"]) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["first_name"]: false,
                        }));
                      }
                      setdata((prevstate) => {
                        return {
                          ...prevstate,
                          first_name: e.target.value,
                        };
                      });
                    }}
                    error={errors?.first_name}
                    helperText={
                      errors?.first_name ? "Please provide your first name" : ""
                    }
                    disabled={disablefirstlastName||disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                    Last Name<span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    id="outlined-read-only-input"
                    value={data.last_name}
                    onChange={(e) => {
                      if (errors["last_name"]) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["last_name"]: false,
                        }));
                      }
                      setdata((prevstate) => {
                        return {
                          ...prevstate,
                          last_name: e.target.value,
                        };
                      });
                    }}
                    error={errors?.last_name}
                    helperText={
                      errors?.last_name ? "Please provide your last name" : ""
                    }
                    disabled={disablefirstlastName||disable}
                  />
                </Grid>
              </Grid>
              <Grid className="rowcss">
               
                <Grid className="common">
                  <InputLabel className="commonLabel">
                    Account Openening Date <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    type="date"
                    required
                    size="small"
                    value={data.accountOpeningDate}
                    onChange={(e) => {
                      if (errors["accountOpeningDate"]) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["accountOpeningDate"]: false,
                        }));
                      }
                      setdata((prevstate) => {
                        return {
                          ...prevstate,
                          accountOpeningDate: e.target.value,
                        };
                      });
                    }}
                    error={errors?.accountOpeningDate}
                    helperText={
                      errors?.accountOpeningDate
                        ? "Please provide account opening date"
                        : ""
                    }
                    disabled={disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel" disabled={true}>
                    Country <span className="redfont">*</span>
                  </InputLabel>
                 
                  <Select
                    value={defaultCountry}
                    onChange={handlecountryDropdownChange}
                    options={countryData}
                    isSearchable={true}
                    placeholder="Select a country..."
                    error={errors?.country}
                    helperText={errors?.country ? "Please slect country" : ""}
                    isDisabled={disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                    State <span className="redfont">*</span>
                  </InputLabel>

                  <Select
                    value={defaultState}
                    onChange={handlestateDropdownChange}
                    options={stateData}
                    isSearchable={true}
                    placeholder="Select a state..."
                    error={errors?.state}
                    helperText={errors?.state ? "Please select state" : ""}
                    isDisabled={disable}
                  />
                </Grid>
                
              </Grid>
              <Grid className="rowcss">
              <Grid className="common">
                  <InputLabel className="commonLabel">
                    Account Approval Date <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    type="date"
                    required
                    value={data.accountApprovalDate}
                    onChange={(e) => {
                      setdata((prevData) => ({
                        ...prevData,
                        accountApprovalDate: e.target.value,
                      }));
                      if (errors["accountApprovalDate"]) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["accountApprovalDate"]: false,
                        }));
                      }
                    }}
                    size="small"
                    id="outlined-read-only-input"
                    error={errors?.accountApprovalDate}
                    helperText={
                      errors?.accountApprovalDate
                        ? "Please provide accounrt approval date"
                        : ""
                    }
                    disabled={!data.accountOpeningDate||disable} // Disable if account opening date is not selected
                    InputProps={{
                      inputProps: { min: data.accountOpeningDate }, // Set minimum date
                    }}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                    {" "}
                    Expected Closure Date <span className="redfont">*</span>
                  </InputLabel>

                  <TextField
                    type="date"
                    size="small"
                    required
                    value={data.expected_closure_date}
                    onChange={(e) => {
                      if(errors){
                      if (errors["expected_closure_date"]) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["expected_closure_date"]: false,
                        }));
                      }
                    }
                    if(setexpdate){
                      setexpdate(
                        e.target.value.split("-")[2] +
                          "-" +
                          e.target.value.split("-")[1] +
                          "-" +
                          e.target.value.split("-")[0]
                      );
                    }
                      setdata((prevstate) => {
                        return {
                          ...prevstate,
                          //expected_closure_date: e.target.value.split("-")[2] + "-" + e.target.value.split("-")[1] + "-" + e.target.value.split("-")[0],
                          expected_closure_date: e.target.value,
                        };
                      });
                    }}
                    error={errors?.expected_closure_date}
                    helperText={
                      errors?.expected_closure_date
                        ? "Please provide expected closure date"
                        : ""
                    }
                    disabled={enable}
                  />
                </Grid>
                <Grid className="common">
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="commonLabel"
                  >
                    Case Priority
                  </FormLabel>
                  <RadioGroup
                  className="wrapcontent"
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) =>
                      setdata((prevstate) => {
                        return { ...prevstate, case_priority: e.target.value };
                      })
                    }
                    value={data.case_priority}
                   // disabled={disable}
                    aria-disabled={disable}
                  >
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label="High"
                      disabled={disable}
                    />
                    <FormControlLabel
                      value="medium"
                      control={<Radio />}
                      label="Medium"
                      disabled={disable}
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label="Low"
                      disabled={disable}
                    />
                  </RadioGroup>
                </Grid>
                
              </Grid>
             
              <Grid>
                <Grid className="colcss">
                  <InputLabel className="commonLabel">Description</InputLabel>
                  <TextField
                    multiline
                    rows={4}
                    id="standard-basic"
                    required
                    value={data.description}
                    onChange={(e) =>
                      setdata((prevstate) => {
                        return { ...prevstate, description: e.target.value };
                      })
                    }
                    disabled={enable}
                  />
                </Grid>
              </Grid>
              <Grid className="rowcss" style={{ marginTop: "10px" }}>
              <Grid className="common">
                  <InputLabel className="commonLabel">
                    Current Case worker <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    value={subworkeremail+ "," +subworker}
                    size="small"
                    select
                    onChange={(e) => {
                      if(errors){
                      if (errors["current_case_worker"]) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["current_case_worker"]: false,
                          ["current_case_worker_email"]: false,
                        }));
                      }
                    }
                      setsubworker(e.target.value.split(",")[1]);
                      setsubworkeremail(e.target.value.split(",")[0]);
                      setdata((prevstate) => {
                        return {
                          ...prevstate,
                          current_case_worker: e.target.value.split(",")[1],
                          current_case_worker_email:
                            e.target.value.split(",")[0],
                        };
                      });
                    }}
                    error={errors?.current_case_worker}
                    helperText={
                      errors?.current_case_worker
                        ? "Please select current case worker"
                        : ""
                    }
                    disabled={disable}
                  >
                    {caseworkers &&
                      caseworkers?.map((item) => (
                        <MenuItem
                          key={item.email}
                          //value={item.email + "," + item.name}
                          value={item.email+ "," +item.name}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {/* <Grid className="common">
                  <InputLabel className="commonLabel">
                    {" "}
                    Current Case Worker Email <span className="redfont">*</span>
                  </InputLabel>

                  <TextField
                    size="small"
                    type="text"
                    disabled
                    value={data.current_case_worker_email}
                    
                    error={errors.current_case_worker_email}
                    helperText={
                      errors.current_case_worker_email
                        ? "Please provide current case worker email"
                        : ""
                    }
                  />
                </Grid> */}
                
              </Grid>
    </>
)
}


export default withSnackbar(BasicInfo, "BasicInfo", "success");