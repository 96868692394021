import React from 'react';
import {Grid,TextField} from '@mui/material';
import "../styles/Forms_Styles/Form_NumberandDropdown.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

const Form_NumberandDropdown = ({FieldValues,onChange,getAnswerDisable}) => {
  const limitChar = 10;
  const {Answer}=useContext(NoteContext);
  const data=typeof Answer.UpdatedAnswer === "string" && Answer.UpdatedAnswer.length !== 0 ? JSON.parse(Answer.UpdatedAnswer) : typeof Answer.UpdatedAnswer === 'object' && Answer.UpdatedAnswer.length !== 0 ? Answer.UpdatedAnswer : {}


  return (
    <Grid container spacing={3}>
      <Grid item xs={4} md={4}>
       <TextField
            id="standard-select-currency-native"
           select
           size='small'
           fullWidth
           helperText="Select Value"
            //  label="select"
            value={data.value || ''}
            onChange={(e) => {
              onChange({Amount:data.Amount ||'',value: e.target.value})
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            disabled={getAnswerDisable}
          >
            {FieldValues?.map((option, index) => (
              <option key={index} value={option.unit}>
                {option.name}
              </option>
            ))}
          </TextField>  
          </Grid>
          <Grid item xs={8} md={8}>
          <TextField
            fullWidth
            size='small'
            id="outlined-adornment-amount"
            helperText="Enter Value"
            variant="outlined"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength: 10 }}
            value={data.Amount || ''}
            onChange={(e) => {
              if (e.target.value.toString().length <= limitChar) {
              onChange({Amount:e.target.value,value: data.value ||''})
            }
            }}
            disabled={getAnswerDisable}
            // label="Amount"
           // inputProps={{ maxLength: 10 }}
          />
</Grid>
           
    </Grid>
  );
}

export default Form_NumberandDropdown;
