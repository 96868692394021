import React, { useEffect, useState } from "react";
import Modal from "components/Modal.js";
import { Typography, Button, Grid, IconButton, Divider } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { updateAdditionalNote,DeleteAdditionalNote } from "ESG/services/Fetch";
import "../styles/Forms_Styles/Form_Autocomplete.css";
import  {useRef} from 'react';
import JoditEditor from 'jodit-react';

function Form_AdditionalNote({ setAdditionalNote, AdditionalNote,startDate, questionId }) {
  const editor = useRef(null);
  const [openRTE, setOpenRTE] = useState(false);
  const [newNote, setNewNote] = useState({
    content:'',
    blobPath:''
  });
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    
    if (!openRTE && newNote?.content?.length!==0 && selectedNote) {
      updateAdditionalNote(startDate, questionId,newNote.content,newNote.blobPath).then((res)=>{
        console.log('updated note');
        })
      setAdditionalNote((prevState) => {
        const updatedNotes = prevState.UpdatedNote.map((note) =>
          note === selectedNote ? newNote : note
        );
        return {
          ...prevState,
          UpdatedNote: updatedNotes,
        };
      });

      setNewNote({
        content:'',
        blobPath:''
      });
      setSelectedNote(null);
    } else if (!openRTE && newNote && newNote?.content?.length!==0) {
      console.log(startDate,questionId,newNote)
      updateAdditionalNote(startDate, questionId,newNote.content).then((res)=>{
      console.log('added note');
      })
      setAdditionalNote((prevState) => {
        return {
          ...prevState,
          UpdatedNote: [...prevState.UpdatedNote, newNote],
        };
      });

      setNewNote({
        content:'',
        blobPath:''
      });
    }
  }, [openRTE]);

  const excludeTags = (html, excludedTags) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const elementsToRemove = doc.querySelectorAll(excludedTags.join(', '));

    elementsToRemove.forEach((element) => {
      element.parentNode.removeChild(element);
    });

    return doc.body.innerText; // Use innerText to get only the text content
  };
  const handleGridItemClick = (note) => {
    setSelectedNote(note);
    setNewNote(note); // Pre-fill the RTE with the selected note content
    setOpenRTE(true);
  };

  const handleDeleteNote = () => {
    if (selectedNote) {
      console.log('Delete note:', selectedNote);
      
      DeleteAdditionalNote(startDate,questionId,newNote.blobPath);
      setAdditionalNote((prevState) => {
        const updatedNotes = prevState.UpdatedNote.filter((note) => note !== selectedNote);
        return {
          ...prevState,
          UpdatedNote: updatedNotes,
        };
      });
      setSelectedNote(null);
      setNewNote({
        content:'',
        blobPath:''
      });
      setOpenRTE(false);

    }
  };

  const excludedTags = ['img', 'table', 'a'];

  return (
    <>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#061826",
              marginBottom: "5px",
            }}
          >
            Additional Info
          </Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={() => setOpenRTE(true)}
            sx={{ textTransform: "capitalize" }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container direction='column'>
        {AdditionalNote.UpdatedNote.length > 0 &&
          AdditionalNote.UpdatedNote.map((note, index) => (
            <Grid  item key={index} onClick={() => handleGridItemClick(note)}>
                  <div className="limited-content" dangerouslySetInnerHTML={{__html:excludeTags(note?.content, excludedTags)}}></div>
            </Grid>
          ))}
      </Grid>
      <Modal
        open={openRTE}
        setOpen={setOpenRTE}
        title={selectedNote ? "Edit Additional Info" : "Add Additional Info"}
        actions={<>
         {selectedNote && ( <Button
            sx={{
              textTransform: "capitalize",
              color: "white",
              backgroundColor: "#25A392",
              "&:hover": {
                backgroundColor: " #25a392",
              },
              marginRight:"5px"
            }}
            onClick={handleDeleteNote}
          >
            Delete
          </Button>)}
         <Button sx={{
              textTransform: "capitalize",
              color: "white",
              backgroundColor: "#25A392",
              "&:hover": {
                backgroundColor: " #25a392",
              },
            }} onClick={()=>{
              setOpenRTE(false);

            }}>Save</Button>

          <Button
            sx={{
              textTransform: "capitalize",
              color: "white",
              backgroundColor: "#25A392",
              "&:hover": {
                backgroundColor: " #25a392",
              },
            }}
            onClick={() => {
              setOpenRTE(false);
              setSelectedNote(null);
              setNewNote({
                content:'',
                blobPath:''
              });
            }}
          >
            close
          </Button></>
        }
        setNewNote={setNewNote}
        setSelectedNote={setSelectedNote}
      >
        <JoditEditor 
        ref={editor}
        value={newNote.content || ""}
        tabIndex={1} 
        onChange={(e) => {
          if(e==='<p><br></p>'){return}
          setNewNote((prevstate)=>{return {...prevstate,content:e}})
        }}
       
        />
      </Modal>
    </>
  );
}

export default Form_AdditionalNote;
