import { TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import "../styles/Forms_Styles/Form_Text_input.css"
import NoteContext from "context/PageContext";
const Form_Text_input = ({onChange,getAnswerDisable}) => {
const {Answer}=useContext(NoteContext);
const disableanswer=getAnswerDisable;
//console.log(disableanswer,"---===---==---")

  return (
          <TextField
             placeholder="Enter text here"
             variant="outlined"
             fullWidth
             value={typeof Answer?.UpdatedAnswer === "string" ? Answer.UpdatedAnswer:"" }
             onChange={(e)=>onChange(e.target.value)}
             size='small'
             disabled={disableanswer}
          />
  );
};

export default Form_Text_input;
