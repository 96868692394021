import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { editDisclosure } from "../services/FormCreationFetch";
import { useRoleContext } from 'context/RoleContex';

const EditTitleModal = ({ open, handleClose, item, handleSave }) => {
  const [newTitle, setNewTitle] = useState('');
  const {updateEditApiTriggered } = useRoleContext();

  useEffect(() => {
    if (item) {
      setNewTitle(item.name);
    }
  }, [item]);

  const handleTitleChange = (event) => {
    setNewTitle(event.target.value);
  };

  const handleSaveClick = async () => {
    const updatedItem = { id: item.id, name: newTitle, isPublished: item.isPublished };
    const result = await editDisclosure(updatedItem);
    console.log(result);
    updateEditApiTriggered(true)
    handleSave(item.id, newTitle);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute', width: 400, backgroundColor: 'white', padding: '20px', boxShadow: 24 }}>
        <Typography variant="h6" gutterBottom>Edit Title</Typography>
        <TextField
          label="Title"
          value={newTitle}
          onChange={handleTitleChange}
          fullWidth
          margin="normal"
        />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSaveClick}>Save</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditTitleModal;
