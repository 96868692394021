import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TCFDlogo from "../../../images/TCFDlogo.png";

function SDBTable(props) {
  const [isloading, setisloading] = useState(false);
  const [headerTable, setheaderTable] = useState();
    const [prioritized, setprioritized] = useState();

  // get table heading data
  const CreateHeader = () => {
    const column = Object.keys(headerTable[0]);
    const newcolumn = column.map((data) => {
         if (data !== "id" && data !== "isNew") {
      return <TableCell key={data}>{data}</TableCell>;
         }
         return null;
    });

    // console.log(newcolumn)
    return newcolumn;
  };

  // get table row data
  const tdData = () => {
    return headerTable.map((data,index) => {
      return (
        <TableBody key={index}>
          <TableRow
            key={data}
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
          >
            {Object.keys(headerTable[0]).map((x,index) => {
                if (x !== "id" && x !== "isNew") {
              return (
                <TableCell key={index} component="th" scope="row">
                  {data[x]}
                </TableCell>
              );
                }
                return null;
            })}
          </TableRow>
        </TableBody>
      );
    });
  };
  useEffect(() => {
    // console.log("hello",(Object.values(props.data.reportData["Disclosure B"])));
      setprioritized(Object.values(props.data.reportData["S.Disclosure B"])[8].answer);
    // setcontextual((Object.values(props.data.reportData[" Employee "])[2]).note);
    setheaderTable(
      Object.values(props.data.reportData["S.Disclosure B"])[9].answer);
      // console.log(Object.values(props.data.reportData["S.Disclosure B"]));
    setisloading(true);

    //CreateHeader();
  }, [isloading,props]);

  return (
    <React.Fragment>
      {isloading && (
        <>
          
            <Grid container spacing={1} className="border1">
            <Grid item xs={12} md={2} className="border1" >
              <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  />
                  </div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                  <li style={{color:"steelblue",fontSize:"20px"}}><b>Strategy</b></li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"steelblue"}} className="border1">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Strategy</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the impact of climate-related 
                          risks and opportunities on the organization’s businesses, strategy, and financial planning..</p>
                  </Grid>
              <Grid item xs={12} md={6} className="grid1">
                <h4><b>Risks and opportunities are prioritized</b></h4>
                <div dangerouslySetInnerHTML={{ __html: prioritized }}></div>
                 </Grid>
           <Grid item xs={12} md={6}  className="grid2"> 
          <h5><b>Describe the impact of climate-related issues on their financial performance and financial position</b></h5>
                <div style={{ justifyContent: "right", display: "flex" }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 550, width: "40%" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 1 },
                          }}
                        >
                          {headerTable && CreateHeader()}
                        </TableRow>
                      </TableHead>

                      {headerTable && tdData()}
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
              </Grid>
            </Grid>
        </>
      )}
   </React.Fragment>
  );
}

export default SDBTable;