import NoteContext from "context/PageContext";
import { useState } from "react";

const NoteState = (props) => {
  
  const [UserSelected,setUserSelected]=useState(null);
  const [Answer, setAnswer] = useState({
    InitialAnswer: "",
    UpdatedAnswer: "",
  }); 
  const [Type, setType] = useState(false);
  const [ThemeType, setThemeType] = useState(true);
  const [TypeDisplay, setTypeDiaplay] = useState(false);
  const [FillingData, setFillingData] = useState(false);
  const [pathname, setPathname] = useState(false);
  const [TotalValue, setTotalValue] = useState(false);
  const [Search, setSearch] = useState("");
  const [UserLogInError, setUserLogInError] = useState({
    message: "",
    Errors: false,
  });
  const [Graphs, setGraphs] = useState();
  const [CaseRole, setCaseRole] = useState(); // The initial value is undefined
  const [Name, setName] = useState({
    id: "",
    name: "",
  });
  const [showSecondaryNavbar, setShowSecondaryNavbar] = useState(false);
  const [disclosureName, setDisclosureName] = useState("");
  const [ReloadData, setReloadData] = useState(false);
  const [selectedCaseType,setSelectedCaseType]=useState();
  const [CompanyAdded,setCompanyAdded]=useState(false);
  const [selectedCaseLabel,setSelectedCaseLabel]=useState();
  return (
    <NoteContext.Provider
      value={{
        UserSelected,
        setUserSelected,
        ReloadData,
        setReloadData,
        setUserLogInError,
        UserLogInError,
        showSecondaryNavbar,
        setShowSecondaryNavbar,
        setCaseRole,
        CaseRole,
        ThemeType,
        setThemeType,
        TypeDisplay,
        setTypeDiaplay,
        Type,
        setType,
        pathname,
        setPathname,
        Name,
        setName,
        TotalValue,
        setTotalValue,
        Graphs,
        setGraphs,
        FillingData,
        setFillingData,
        setSearch,
        Search,
        disclosureName,
        setDisclosureName,
        Answer, setAnswer,
        selectedCaseType,
        setSelectedCaseType,
        CompanyAdded,
        setCompanyAdded,
        selectedCaseLabel,
        setSelectedCaseLabel
      }}
    >
      {props.children}
    </NoteContext.Provider>
  );
};

export default NoteState;
