import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TCFDlogo from "../../../images/TCFDlogo.png";

const SDA1 = (props)  =>{
    
    const [Short,setShort] = useState("")
    const [Medium,setMedium]=useState();
    const[Long,setLong]=useState();
    const [pageloaded, setpageloaded] = useState(false)

    useEffect (()=>{ 
       //console.log(Object.values(props.data.reportData["S.Disclosure A"]));

        setShort((Object.values(props.data.reportData["S.Disclosure A"])[0]).answer);
        setMedium((Object.values(props.data.reportData["S.Disclosure A"])[1]).answer);
        setLong((Object.values(props.data.reportData["S.Disclosure A"])[2]).answer);
        
        setpageloaded(true);
    },[props])

    return(
    <React.Fragment>
        {pageloaded ? (
        <>
            <Grid container spacing={1} className="border1">
            <Grid item xs={12} md={2} className="border1" >
            <div>
                  <img
                    src={TCFDlogo}
                    alt="tcfd"
                    height="25vh"
                    width="60%"
                    className="img1"
                  /></div>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li>Governance </li>
                    <li style={{color:"steelblue",fontSize:"20px"}}><b>Strategy</b></li>
                    <li>Risk Management</li>
                    <li>Metrics and Targets</li>
                  </ul>
                </Grid >
                <Grid item md={10} container>
                <Grid item xs={12} md={12} style={{backgroundColor:"steelblue"}} className="border1">
                  <h6 style={{fontSize:"30px",color:"white",paddingLeft:"10px"}}>Strategy</h6>
                      <p style={{color:"white",paddingLeft:"10px"}}>Describe the climate-related risks and opportunities the organization has
                    identified over the short, medium, and long term.</p>
                  </Grid>
                <Grid item xs={12} md={6} className="grid1">
                        <h4 className="underline">S.Disclosure A</h4>
                        <h5 className="underline"><b>The relevant short-term time horizons.</b></h5>
                        <div dangerouslySetInnerHTML={{__html:Short}}></div>
                        <h5 className="underline"><b>The relevant medium-term time horizons.</b></h5>
                  <div dangerouslySetInnerHTML={{__html:Medium}}></div>
                </Grid>
                <Grid item xs={12} md={6} className="grid2" >
                  <h5 className="underline"><b>The relevant long-term time horizons.</b></h5>
                  <div dangerouslySetInnerHTML={{__html:Long}}></div>
                  
                </Grid>
                </Grid>
            </Grid>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </React.Fragment>
    );
}
export default SDA1;