import React, { useEffect, useState,useContext } from "react";
import {
    Grid,
    InputLabel,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    MenuItem
  } from "@mui/material";
import Select from "react-select";
import withSnackbar from "../../components/Alert/withSnackbar"; 
import NoteContext from "context/PageContext";
import useTemplateType from "Insurance/CaseManagement/useTemplateType";

const {
getCaseTypesTemp,
getSubCaseTypesTemp
  } = require("../../Compliance/services/CaseFetch");

const Inquiry=({showSnackbar})=>{
  const ContextData = useContext(NoteContext);
  const {templateType} = useTemplateType();
  const [disablefirstlastName,setDisablefirstlastName]=useState(false);
  const [disable,setDisable]=useState(false);
  const [enable,setEnable]=useState("");
  const [templateData,setTemplateData]=useState();
  const [selectedtemplate,setSelectedTemplate]=useState();
  const [subtemplateData,setSubTemplateData]=useState();
  const [selectedsubtemplate,setSelectedSubTemplate]=useState();
  const [inquirySource,setinquirySource]=useState();
  const [description,setDescription]=useState();
  //const enable=edit==true?false:true;
 


  useEffect(()=>{
    // if(iscreatecase){
    //   setDisable(false);
    //   setEnable(false)
    // }
    // else if(edit==true){
    //   setEnable(false)
    //   setDisable(true);
    // }
    // else if(edit!=true){
    //   setDisable(true);
    //   setEnable(true)
    // }
    fetchData()
  },[])
  const fetchData = async () => {
    try {
      const tempType = await getCaseTypesTemp(templateType);
      var tres = tempType.data;
      var res = tres.map((temp) => ({
        value: temp.code,
        label: temp.title,
      }));
      setTemplateData(res);
      setSelectedTemplate(res[0]);
      fetchsubTemplate(res[0].code)
    }
    catch(error){

    }
    }
    const fetchsubTemplate = async (value) => {
        if (value) {
          var data = await getSubCaseTypesTemp(value);
          var stdata = data.data;
    
          var sortedsubtemp = stdata.map((stemp) => ({
            value: stemp.title,
            label: stemp.title,
          }));
          setSubTemplateData(sortedsubtemp);
          setSelectedSubTemplate(sortedsubtemp[0]);
        }
      };
      const handletemplateDropdownChange = async (temp) => {
        if (temp) {
          const { value, label } = temp;
          setSelectedTemplate(temp);
          fetchsubTemplate(value);
        }
      };
    
      var handlesubtempDropdownChange = async (value) => {
        setSelectedSubTemplate(value);
    
      };
  
return(
    <>
  
              <Grid className="rowcss" style={{marginTop:"10px"}}>
               
                
                <Grid className="common">
                  <InputLabel className="commonLabel" disabled={true}>
                  Inquiry Category <span className="redfont">*</span>
                  </InputLabel>
                 
                  <Select
                    value={selectedtemplate}
                    onChange={handletemplateDropdownChange}
                    options={templateData}
                    isSearchable={true}
                    placeholder="Select a Template..."
                    // error={errors?.country}
                    //helperText={errors?.country ? "Please slect template" : ""}
                    isDisabled={disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                  Sub Category <span className="redfont">*</span>
                  </InputLabel>

                  <Select
                    value={selectedsubtemplate}
                    onChange={handlesubtempDropdownChange}
                    options={subtemplateData}
                    isSearchable={true}
                    placeholder="Select a Sub Category..."
                   // error={errors?.state}
                    //helperText={errors?.state ? "Please select Sub Category" : ""}
                    isDisabled={disable}
                  />
                </Grid>
                <Grid className="common">
                  <InputLabel className="commonLabel">
                  Inquiry Source <span className="redfont">*</span>
                  </InputLabel>
                  <TextField
                    type="date"
                    required
                    value={inquirySource}
                    onChange={(e) => {
                      setinquirySource(e.target.value) 
                    }}
                    size="small"
                    id="outlined-read-only-input"
                   // disabled={!data.accountOpeningDate||disable} 
                    
                  />
                </Grid>
              </Grid>
             
             
              <Grid>
                <Grid className="colcss">
                  <InputLabel className="commonLabel">Description of Inquiry</InputLabel>
                  <TextField
                    multiline
                    rows={4}
                    id="standard-basic"
                    required
                    value={description}
                    onChange={(e) =>
                        setDescription(e.target.value)
                    }
                    disabled={enable}
                  />
                </Grid>
              </Grid>
              
    </>
)
}


export default withSnackbar(Inquiry, "Inquiry", "success");