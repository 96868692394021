import React, { useContext, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ViewReport from "ESG/pages/ViewReport";
import ErrorPage from "ESG/pages/ErrorPage";
import LandingPage from "ESG/pages/LandingPage";
import GraphPage from "ESG/pages/GraphPage";
import Home from "ESG/pages/Home";
import Permissions from "ESG/pages/Permissions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "App.css";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import NoteState from "context/StateContext";
import { NavigationBar } from "./components/Navigationbar";
import Paginations from "ESG/pages/Paginations";
import ADVReportPage from "ESG/pages/ADVReportPage";
import PFReportPage from "ESG/pages/PFReportPage";
import TCFDReportPage from "ESG/pages/TCFDReportPage";
import NoteContext from "context/PageContext";
import GoalSettingPage from "ESG/pages/GoalSettingPage";
import CaseHome from "Compliance/CaseManagment/CaseHome";
import CustOnBoardPage1 from "ESG/pages/CustOnBoardPage1";
import RegistrationResult from "./Utilities/FormCreation/RegistrationResult";
// import NewCreatedDisclosure from "Utilities/FormCreation/NewCreatedDisclosure";

import NewCreatedTemplate from "Utilities/FormCreation/NewCreatedTemplate";

import DisclosuserCompliance from "Compliance/ComplianceDashBoard/DisclouserCompliance";
import Case_Compliance from "Compliance/ComplianceDashBoard/Case_Compliance";
// import CustOnBoardPage from "pages/CustOnBoardPage";
import StickyHeadTable from "Compliance/RealtimeData/RealTimeData";
import AddUser from "./Administration/pages/Adduser";
import AdminPanel from "./Administration/pages/AdminPanel";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import ESGHome from "./ESG/pages/ESGHome";
import AddDisclosureModal from "Utilities/FormCreation/AddDisclosureModal";
import OnBoardQuestions from "ESG/pages/OnBoardQuestions";
import CustOnBoardPage from "ESG/pages/CustOnBoardPage";
import AddCompany from "./Administration/pages/AddCompany";
import ManageCompany from "./Administration/pages/ManageCompany";
import ViewAllCases from "Compliance/CaseManagment/ViewAllCases";
import MyTasks from "Compliance/CaseManagment/MyTasks";
import CreateCase from "Compliance/CaseManagment/CreateCase";
import ViewAllChildCases from "Compliance/CaseManagment/ViewAllChildCases";
import EditCase from "Compliance/CaseManagment/EditCase";
import TradeReport from "Compliance/TradeReporting/TradeReporting_Home";
import ViewSubCases from "Compliance/CaseManagment/ViewSubCases";
import GobalDashBoard from "ESG/pages/GobalDashBoard";
import { RoleContextProvider } from "context/RoleContex";
import GridViewpage from "Utilities/FormCreation/GridViewpage";
import Graph3D from "Compliance/Components/Graph";
//import GraphComponent from "ESG/pages/GraphComponent";
/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  //theme for Drak and Light
  const ContextData = useContext(NoteContext);
  useEffect(() => {
    const detectColorScheme = (e) => {
      ContextData.setThemeType(e.matches ? false : true);
    };
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    darkModeMediaQuery.addEventListener("change", detectColorScheme);
    ContextData.setThemeType(darkModeMediaQuery.matches ? false : true);
    return () => {
      darkModeMediaQuery.removeEventListener("change", detectColorScheme);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  document.body.style.backgroundColor = ContextData.ThemeType
    ? "#ffffff"
    : "#4a4646";
  const styleElement = document.createElement("style");

  // Define the CSS rule
  const cssRule = `
  ::-webkit-scrollbar-track {
    background-color: ${ContextData.ThemeType ? "#ffffff" : "#4a4646"}; 
  }`;
  // Set the CSS rule as the style element's content
  styleElement.innerHTML = cssRule;

  // Append the style element to the document's head
  document.head.appendChild(styleElement);
  return (
    <div className="App">
      <AuthenticatedTemplate>
        {/* <div>
          <ThemeProvider
            theme={ContextData.ThemeType ? setTheme(true) : setTheme(false)}
          > */}
        <Router>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavigationBar />
            <Box component="main" sx={{ flexGrow: 1, minHeight: "100vh" }}>
              <Toolbar variant="dense" />
              <Routes>
                <Route path="/ESGHome" element={<ESGHome />} />
                <Route path="/" element={<GobalDashBoard />} />
                <Route path="/MainPage" element={<Home />} />
                <Route path="/Home" element={<ESGHome />} />
                <Route path="/ViewReport" element={<ViewReport />} />
                <Route path="/ESG-DashBoard" element={<GraphPage />} />
                <Route path="/Permissions" element={<Permissions />} />
                <Route path="/Paginations" element={<Paginations />} />
                <Route path="/ADVReportPage" element={<ADVReportPage />} />
                <Route path="/PFReportPage" element={<PFReportPage />} />
                <Route path="/TCFDReportPage" element={<TCFDReportPage />} />
                <Route path="/GoalSettingPage" element={<GoalSettingPage />} />
                <Route path="/caseManagement" element={<CaseHome />} />
                <Route path="/mytasks" element={<MyTasks />} />
                <Route path="/createcase" element={<CreateCase />} />
                <Route path="/viewallchildcases" element={<ViewAllChildCases />} />
                <Route path="/editcase" element={<EditCase />} />
                <Route path="/allsubcases" element={<ViewSubCases />} />
                <Route path="/GridViewpage" element={<GridViewpage />} />
                <Route path="/Graph3D" element={<Graph3D/>}/>
                <Route
                  path="/CustOnBoardPage1"
                  element={<CustOnBoardPage1 />}
                />
                <Route path="/RealTimeData" element={<TradeReport />} />
                <Route
                  path="/RegistrationResult"
                  element={<RegistrationResult />}
                />
                <Route
                  path="/NewCreatedTemplate"
                  element={<NewCreatedTemplate />}
                />
                {/* <Route path="/CustOnBoardPage" element={<CustOnBoardPage />} />
                                <Route path="/OnBoardQuestions" element={<OnBoardQuestions />} /> */}
                <Route path="/CustOnBoardPage" element={<CustOnBoardPage />} />
                <Route
                  path="/OnBoardQuestions"
                  element={<OnBoardQuestions />}
                />
                <Route path="/AddUser" element={<AddUser />} />
                <Route path="/AdminPanel" element={<AdminPanel />} />
                <Route path="/ViewAllCases" element={<ViewAllCases />} />
                <Route
                  path="/AddDisclosureModal"
                  element={<AddDisclosureModal />}
                />
                <Route path="/RealTimeData" element={<TradeReport />} />
                {/* <Route path="/TradeReport" element={<TradeReport/>} /> */}
                <Route
                  path="/NewCreatedTemplate"
                  element={<NewCreatedTemplate />}
                />

                <Route
                  path="/DisclouserCompliance"
                  element={<DisclosuserCompliance />}
                />
                <Route path="/Compliance" element={<Case_Compliance />} />
                <Route path="/AddCompany" element={<AddCompany />} />
                <Route path="/ManageCompany" element={<ManageCompany />} />
                <Route path="*" element={<ErrorPage />} />
                <Route path="/caseManagement" element={<CaseHome />} />
                {/* <Route path="/GraphComponent" element={<GraphComponent />} /> */}

              </Routes>
            </Box>
          </Box>
        </Router>
        {/* </ThemeProvider>
        </div> */}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate alignItems="center">
        <Router>
          <NavigationBar />
          <LandingPage />
          {/* <CaseCompliance/> */}
        </Router>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <NoteState>
        <RoleContextProvider>
          {/* <NavigationBar/> */}
          {/* <PageLayout> */}
          <MainContent />
          {/* </PageLayout> */}
        </RoleContextProvider>
      </NoteState>
    </StyledEngineProvider>
  );
}
