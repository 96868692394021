import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import "../ESG/styles/NewFiling/NewFiling.css";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import {
  getDisclosures_Newfilling,
  get_Newfilling,
  CreateFilingWithAI,
  domainDropdownList,
} from "../ESG/services/Fetch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Backdrop } from "@mui/material";
export default function NewFilling({
  handleClickOpen,
  openDialog,
  setOpenDialog,
}) {
  const [UseAIAssist, setUseAIAssist] = useState(false);
  const [Newfilings, setNewFilings] = useState([]);
  const [disclosurevalue, setDisclosureValue] = useState();
  const [fromtDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  const [deadlineDate, setDeadlineDate] = React.useState();
  const [DisclosureName, setDisclosureName] = useState();
  const [selectedDomain, setselectedDomain] = useState(
    localStorage.getItem("UserSelectedDomain")
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const get_Newfilling_func = async () => {
    let new_disclosurevalue = Object.values(disclosurevalue).toString();
    let new_from_date = new Date(fromtDate);
    let new_to_date = new Date(toDate);
    let new_deadline_date = new Date(deadlineDate);
    var dd_from = new_from_date.getDate();
    var dd_to = new_to_date.getDate();
    var dd_deadline = new_deadline_date.getDate();
    var mm_from = new_from_date.getMonth() + 1;
    var mm_to = new_to_date.getMonth() + 1;
    var mm_deadline = new_deadline_date.getMonth() + 1;
    var yyyy_from = new_from_date.getFullYear();
    var yyyy_to = new_to_date.getFullYear();
    var yyyy_deadline = new_deadline_date.getFullYear();
    if (dd_from < 10) {
      dd_from = "0" + dd_from;
    }
    if (mm_from < 10) {
      mm_from = "0" + mm_from;
    }
    if (dd_to < 10) {
      dd_to = "0" + dd_to;
    }
    if (mm_to < 10) {
      mm_to = "0" + mm_to;
    }
    if (dd_deadline < 10) {
      dd_deadline = "0" + dd_deadline;
    }
    if (mm_deadline < 10) {
      mm_deadline = "0" + mm_deadline;
    }
    let actual_from_date = dd_from + "/" + mm_from + "/" + yyyy_from;
    let actual_to_date = dd_to + "/" + mm_to + "/" + yyyy_to;
    let actual_deadline_date =
      dd_deadline + "/" + mm_deadline + "/" + yyyy_deadline;
    setLoading(true);
    (async () => {
      try {
        const DisplayDisclousre=(data,disclosure,startdate)=>{
          if (data.status === 200) {
            setLoading(false);
            navigate("/ViewReport", {
              state: {
                disclosure: disclosure,
                startdate: startdate,
              },
            });
          }
         }
        if(UseAIAssist)
        {
        const data=await  CreateFilingWithAI(
            DisclosureName,
            disclosurevalue,
            selectedDomain,
            actual_from_date,
            actual_to_date,
            actual_deadline_date
          )
          const DisclosureNameforTree=data.data.disclosureType;
          const startdatefortree=data.data.periodStart;
          // console.log(data.data.disclosureType);
          // console.log(data.data.periodStart);
          //console.log(data);
          DisplayDisclousre(data,DisclosureNameforTree,startdatefortree);
        }
        else
        {
          const data=await get_Newfilling(
            DisclosureName,
            disclosurevalue,
            selectedDomain,
            actual_from_date,
            actual_to_date,
            actual_deadline_date
          );
          const DisclosureNameforTree=data.data.disclosureType;
          const startdatefortree=data.data.periodStart;
          // console.log(data.data.disclosureType);
          // console.log(data.data.periodStart);
          DisplayDisclousre(data,DisclosureNameforTree,startdatefortree);
        
        }
       

       
        
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  };
 

  const Spinner = ({ open }) => {
    return (
      <Backdrop open={open} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  useEffect(() => {
    setselectedDomain(localStorage.getItem("UserSelectedDomain"));
    const getDisclosures = async () => {
      await getDisclosures_Newfilling().then((data) => {
        setNewFilings(data?.values);
      });
    };
    if (openDialog === true) {
      getDisclosures();
    }
  }, [openDialog, getDisclosures_Newfilling]);

  const handleSubmit = () => {
    var g1 = new Date(fromtDate);
    var g2 = new Date(toDate);
    var g3 = new Date(deadlineDate);
    if (g1.getTime() === null || g2.getTime() === null || g3.getTime() === null)
      alert("Dates cannot be embty");
    else if (
      g1.getTime() > g2.getTime() ||
      g2.getTime() > g3.getTime() ||
      g1.getTime() > g3.getTime()
    )
      alert("Deadline date is always grater than from and to dates");
    else {
      get_Newfilling_func();
      setOpenDialog(false);
    }
  };

  const handleClickClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Button className="NewFilingButton" onClick={() => handleClickOpen()}>
        New Filings
      </Button>
      <Spinner open={loading} />
      <Dialog
        open={openDialog}
        onClose={() => handleClickClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"New Filings"}</DialogTitle>
        <IconButton
          size="small"
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 30,
            top: 16,
            color: "white",
            backgroundColor: "black",
            width: "20px",
            height: "20px",
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText id="alert-dialog-title">
            Provide The Following Details To Create New Filing.
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <Grid container my={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    onChange={(e) => {
                      setDisclosureName(e.target.value);
                    }}
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    value={selectedDomain}
                    id="outlined-basic"
                    label="Domain"
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={Newfilings}
                    onChange={(event, value) => {
                      // console.log(value)
                      setDisclosureValue(value);
                    }}
                    value={disclosurevalue}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Disclosures"
                        error={disclosurevalue === null}
                        helperText={fromtDate === null ? "Fill the value" : ""}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container my={2} justifyContent={"space-between"}>
                {/* <Form_PeriodicDate /> */}
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      value={fromtDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                        console.log(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={fromtDate === null}
                          helperText={
                            fromtDate === null ? "Fill from date" : ""
                          }
                          
                        />
                      )}
                      inputFormat="MM/dd/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={toDate === null || toDate < fromtDate}
                          helperText={
                            toDate === null
                              ? "Fill To date"
                              : "" + toDate < fromtDate
                              ? "To date not before from date"
                              : ""
                          }
                          
                        />
                      )}
                      inputFormat="MM/dd/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container my={2} spacing={2}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Deadline"
                      value={deadlineDate}
                      onChange={(newValue) => {
                        setDeadlineDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            deadlineDate === null ||
                            deadlineDate < fromtDate ||
                            deadlineDate < fromtDate
                          }
                          helperText={
                            deadlineDate === null
                              ? "Fill Deadline Date"
                              : "" + deadlineDate < fromtDate ||
                                deadlineDate < toDate
                              ? "Deadline not before from or to dates"
                              : ""
                          }
                          
                        />

                      )}
                      inputFormat="MM/dd/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Use Ai Assistant"
                    control={
                      <Checkbox
                        checked={UseAIAssist}
                        onChange={() => {
                          setUseAIAssist(!UseAIAssist);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          direction={"column"}
          mb={1}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{ backgroundColor: "#25A392" }}
              disabled={
                !(
                  disclosurevalue &&
                  deadlineDate &&
                  toDate &&
                  fromtDate &&
                  selectedDomain &&
                  DisclosureName
                )
              }
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              onClick={() => setOpenDialog(false)}
              style={{ color: "#F37472" }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
