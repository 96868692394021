import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {TextField} from "@mui/material";
export default function Form_CheckboxWithTextField({label,onChange,Answer,getAnswerDisable}) {

    const [checkedItem,setcheckedItem]=useState({
      ischecked:Answer?Answer?.ischecked:false,
      value:Answer?Answer?.value:''
    });

    const handleCheck=(event)=>{
       setcheckedItem((prevState)=>{
        return {...prevState,ischecked:event.target.checked}
       })
    }

    const handleChange=(value)=>{
      setcheckedItem((prevState)=>{
       return {...prevState,value:value}
      })
   }

   useEffect(()=>{
    onChange(checkedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
   },[checkedItem])
 
  return (
    <Grid container direction={"column"} rowgap={1}>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedItem.ischecked}
              color="success"
              onChange={handleCheck}
            />
          }
          value={label}
          label={label}
          labelPlacement="end"
          disabled={getAnswerDisable}
        />
      </Grid>

      {checkedItem.ischecked && (
        <Grid item>
          <TextField
            size='small'
            variant="outlined"
            value={checkedItem.value}
            onChange={handleChange}
            disabled={getAnswerDisable}
          />
        </Grid>
      )}
    </Grid>
  );
}
