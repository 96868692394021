import React, { useState } from 'react';
import '../styles/Real_Time_Data/PopupCard.css';
import { Button, IconButton } from '@mui/material';
import { Info } from '@mui/icons-material';

function PopupCard({Message}) {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="popup-card-container">
      <IconButton className="icon-button" onClick={togglePopup}>
        <Info />
      </IconButton>
      {isPopupOpen && (
        <div className="popup-card">
          <h4>Trade Message</h4>
          <p>{Message}</p>
          <Button className="close-button" onClick={togglePopup}>
            Close
          </Button>
        </div>
      )}
    </div>
  );
}

export default PopupCard;
