import React, { useEffect, useState ,useRef} from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useGridApiContext } from "@mui/x-data-grid";
import {  getFormDropdownValues } from 'ESG/services/Fetch';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import JoditEditor from 'jodit-react';

export function CurrencyForTable(props) {
  const { id,value,field, questionId,values} = props;
  const [CurrencySymbols,setCurrencySymbols]=useState();
  const [currency, setcurrency] = useState({
    Amount:value?value.toString().substring(1):'',
    Symbol:value?value[0]:'$',
   });
   
   const apiRef = useGridApiContext();

   useEffect(()=>{
    const GetCurrencySymbols=async()=>{
      getFormDropdownValues(values).then((res)=>{
        setCurrencySymbols(res);
       })
    }
    GetCurrencySymbols();
   },[]);

   useEffect(()=>{
   const FormattedCurrency= (currency.Symbol+currency.Amount).toString();
    apiRef.current.setEditCellValue({
      id,
      field,
      value: FormattedCurrency
    });
   },[currency,value,apiRef,id,field])


  

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <FormControl fullWidth >
          <OutlinedInput
            id="outlined-adornment-amount"
            type='number'
            startAdornment={<InputAdornment position="start">
               {CurrencySymbols === undefined ? null :
           <TextField 
            id="standard-select-currency"
            select
            value={currency.Symbol}
            onChange={(e)=>{
              setcurrency((prevstate)=>{
                return {...prevstate,Symbol:e.target.value}
              })
            }}
            SelectProps={{
              native: true,
            }}
            variant="standard"
          >
            {CurrencySymbols && CurrencySymbols.map((option, index) => (
            <option key={index} value={option.label}>
              {option.label}
            </option>
            ))}
          </TextField>}</InputAdornment>}

          value={currency.Amount}
          onChange={(e)=>{
            setcurrency((prevstate)=>{
              return {...prevstate,Amount:e.target.value}
            })
          }}
          />
        </FormControl>
    </Box>
  );
}

export function EditTextarea(props) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState();
  const [inputRef, setInputRef] = React.useState(null);
  const apiRef = useGridApiContext();
  const editor = useRef(null);

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const handleChange = (newValue)=>{
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue
    });
  }

  return (
    <div style={{ position:'static', alignSelf: 'flex-end'}}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'relative',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper sx={{width:'600px'}} open anchorEl={anchorEl} placement="bottom" >
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
          <JoditEditor 
        ref={editor}
        value={valueState}
        tabIndex={1} 
        onChange={(newValue)=>handleChange(newValue)}
        />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

export function CheckBox(props) {
  const { id,value,field} = props;
  const [CheckedItem,setCheckedItem]=useState(typeof value==='string'?{label:value,ischecked:false}:value);
 
   const apiRef = useGridApiContext();

   useEffect(()=>{
    apiRef.current.setEditCellValue({
      id,
      field,
      value: CheckedItem
    });
   },[CheckedItem])

  return (
    <Box sx={{ alignItems:'center' }}>
      <FormControlLabel sx={{'.MuiFormControlLabel-label':{fontSize:'small'}}} label={CheckedItem.label} control={<Checkbox checked={CheckedItem.ischecked} 
        onChange={()=>setCheckedItem((prevstate)=>{
          const ischeck= prevstate.ischecked;
        return {...prevstate,ischecked:!ischeck};
      })}/>}/>
      </Box>
  );
}

