import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function TwelthPage(props) {
  const [Questionset1, SetQuestionset1] = useState();
  const [Questionset2, SetQuestionset2] = useState();
  const [Questionset3, SetQuestionset3] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [QuestionNum2, setQuestionNumber2] = useState();
  const [QuestionNum3, setQuestionNumber3] = useState();
  //const [Question1,setQuestion1]=useState();
  const [Answerset1, setAnswerset1] = useState();
  const [Answerset2, setAnswerset2] = useState();
  const [Answerset3, setAnswerset3] = useState();
  const [QuestionNum1, setQuestionNumber1] = useState();

  useEffect(() => {
    const FetchData = () => {
      //getting section 1b question in single array
      const questions_array_a = [];
      const Answer_array_a = [];
      const QuestionNumber1 = [];

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Successions"]).length;
        i++
      ) {
        questions_array_a.push(
          Object.keys(props.data.reportData["Successions"])[i]
        );
        Answer_array_a.push(
          Object.values(props.data.reportData["Successions"])[i].answer
        );
        QuestionNumber1.push(
          Object.values(props.data.reportData["Successions"])[i].qno.substr(
            8,
            Object.values(props.data.reportData["Successions"])[i].qno.length
          )
        );
      }
      // console.log(questions_array_b);
      SetQuestionset1(questions_array_a);
      setAnswerset1(Answer_array_a);
      setQuestionNumber1(QuestionNumber1);
    };
    const FetchData1 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber2 = [];

      for (
        let i = 0;
        i <
        Object.keys(
          props.data.reportData[
            "Advisers to Registered Investment Companies and Business Development Companies"
          ]
        ).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(
            props.data.reportData[
              "Advisers to Registered Investment Companies and Business Development Companies"
            ]
          )[i]
        );
        Answer_array_b.push(
          Object.values(
            props.data.reportData[
              "Advisers to Registered Investment Companies and Business Development Companies"
            ]
          )[i].answer
        );
        QuestionNumber2.push(
          Object.values(
            props.data.reportData[
              "Advisers to Registered Investment Companies and Business Development Companies"
            ]
          )[i].qno.substr(
            8,
            Object.values(
              props.data.reportData[
                "Advisers to Registered Investment Companies and Business Development Companies"
              ]
            )[i].qno.length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset2(questions_array_b);
      setAnswerset2(Answer_array_b);
      //console.log(Answer_array_b);
      setQuestionNumber2(QuestionNumber2);
    };
    const FetchData2 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber3 = [];

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Wrap Fee Programs"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Wrap Fee Programs"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Wrap Fee Programs"])[i].answer
        );
        QuestionNumber3.push(
          Object.values(props.data.reportData["Wrap Fee Programs"])[
            i
          ].qno.substr(
            8,
            Object.values(props.data.reportData["Wrap Fee Programs"])[i].qno
              .length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset3(questions_array_b);
      setAnswerset3(Answer_array_b);
     // console.log(Answer_array_b);
      setQuestionNumber3(QuestionNumber3);
    };

    FetchData();
    FetchData1();
    FetchData2();

    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  bgcolor: "lightgray",
                  position: "sticky",
                  top: "0",
                  padding: "2px",
                  height: "100",
                }}
              >
                <ul
                  style={{
                    listStyleType: "square",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  <li>Website Addresses</li>
                  <li>Books and Records</li>
                  <li>
                    Registration with Foreign Financial Regulatory Authorities
                  </li>
                </ul>
              </Grid>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                
                  {Questionset1.map((x, index) => {
                    return (
                      <React.Fragment key={index} >
                        
                          <b>{QuestionNum1[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {Answerset1[index]}
                
                        <br />
                      </React.Fragment>
                    );
                  })}
              
                  {Questionset2.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                       
                          <b>{QuestionNum2[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {index === 0 ? (<React.Fragment>{Answerset2[index][0]}</React.Fragment>)
                          :
                          (index === 2 ?(<React.Fragment>Amount :{Answerset2[index].Symbol.label}{Answerset2[index].Amount}</React.Fragment>):
                          (<React.Fragment>{Answerset2[index]}</React.Fragment>))}
                        
                        <br />
                      </React.Fragment>
                    );
                  })}
                
                  {Questionset3.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        
                          <b>{QuestionNum3[index]}</b>
                          {"\u00A0"}
                          {"\u00A0"}
                          {x}
                          <br />
                          {index === 0 ?(<React.Fragment>{Answerset3[index][index]} </React.Fragment>):(<React.Fragment>{Answerset3[index]} </React.Fragment>)}
                  
                        <br />
                      </React.Fragment>
                    );
                  })}
               
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default TwelthPage;
