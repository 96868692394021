import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const ExternalAssurance = (props)  =>{
    const [Policy,setPolicy] = useState()
    const [Reference,setReference] = useState()
    const [Standards,setStandards] = useState()
    const [Relationship,setRelationship] = useState()
    const [pageloaded, setpageloaded] = useState(false)
    
    useEffect (()=>{ 
        setPolicy((Object.values(props.data.reportData["GRI 2.5 External assurance"])[0]).note)
        setReference((Object.values(props.data.reportData["GRI 2.5 External assurance"])[2]).note)
        setStandards((Object.values(props.data.reportData["GRI 2.5 External assurance"])[3]).note)
        setRelationship((Object.values(props.data.reportData["GRI 2.5 External assurance"])[4]).note)
        setpageloaded(true);
    },[pageloaded,props.data.reportData])
    return(
    <div>
        {pageloaded ? (
        <>
        <Box  className="EmpBox">
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ bgcolor: "lightgray" }}>
                  <h4>Apple.Inc </h4>
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                  <li key ={1}>Introduction </li>
                  <li key ={2}>About the Report</li>
                  <li key ={3} >Policies</li>
                  <li key ={4}>Employees details</li>
                  <li key ={5}>performance and career development</li>
                  <li key ={6}>Sectors</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h2>External assurance</h2>
                        <h6><b>Policy and practice for seeking external assurance:</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Policy}}></div>
                        <h6><b>Reference to the external assurance report(s):</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Reference}}></div>
                        <h6><b>Relationship between the organization and the assurance provider:</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Relationship}}></div>
                       
                </Grid>
                <Grid item xs={12} md={5}  className="grid1">
                        <h6><b>The assurance standards used, the level of assurance obtained, and any limitations of the assurance process:</b></h6>
                        <div dangerouslySetInnerHTML={{__html:Standards}}></div>
                </Grid>
            </Grid>
        </Box>
        </>
      ) : (
         <div className="loaders">Loading...</div>
        )}
    </div>
    );
}
export default ExternalAssurance;