import React from "react";
import { Grid, Typography } from "@mui/material";
import Total_Disclosures from "../../images/Total_Disclosures.svg";
import Completed_disclosures from "../../images/Completed_disclosures.svg";
import InProgress_disclosures from "../../images/InProgress_disclosures.svg";
import Goals_Achieved from "../../images/Goals_Achieved.svg";
import PaperComponent from "components/PaperComponent";
import Skeleton from "@mui/material/Skeleton";

const CardComponent = ({
  img,
  label,
  value,
  iconClassName,
  valueClassName,
}) => {
  
  
  return (
    <Grid item xs={12} sm={6} md={3}>
      <PaperComponent>
        <Grid container spacing={1} className="DashboardCard">
          <Grid item xs={2}>
            <div className={`CardIcon ${iconClassName}`}>
              <img src={img} />
            </div>
          </Grid>
          <Grid item xs={10} >
            <Typography className="CardTitle">{label}</Typography>
            <Typography className={`CardTitle2 ${valueClassName}`} >
              {value}
            </Typography>
          </Grid>
        </Grid>
      </PaperComponent>
    </Grid>
  );
};

const ESGDashboard = ({ Total, Completed, Pending }) => {

  const isDataAvailable = Total !== undefined;
  const numberOfTimes = 4;
  const dummyArray = new Array(numberOfTimes).fill();
  const permittedPath = localStorage.getItem("permittedPath");

  return (
    <Grid spacing={2} container>
      {isDataAvailable ? (
        <>
          <CardComponent
            img={Total_Disclosures}
            label={"Total Disclosures"}
            value={Total}
            iconClassName={"total_disclosures_icon"}
            valueClassName={"total_disclosures"}
          />

          <CardComponent
            img={Completed_disclosures}
            label={"Completed Disclosures"}
            value={Completed}
            iconClassName={"completed_disclosures_icon"}
            valueClassName={"completed_disclosures"}
          />

          <CardComponent
            img={InProgress_disclosures}
            label={"In-Progress Disclosures"}
            value={Pending}
            iconClassName={"Inprogress_disclosures_icon"}
            valueClassName={"Inprogress_disclosures"}
          />
         {permittedPath === "ESG" &&  
         <CardComponent
            img={Goals_Achieved}
            label={"Goal V/S Achieved"}
            value={"80%"}
            iconClassName={"goals_achieved_icon"}
            valueClassName={"goals_achieved"}
          /> }
         
        </>
      ) : (
        <>
          {dummyArray.map((_, index) => {
            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Skeleton variant="rounded" height={"102px"} />
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default ESGDashboard;
