import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";

function Eighteeth(props) {
  const [Questions, SetQuestions] = useState();
  const [Isloading, setIsloading] = useState(false);
  //const [Question1,setQuestion1]=useState();
  const [Answer, setAnswer] = useState();
  const [QuestionNum, setQuestionNumber] = useState();

  // get table heading data
  const ThData = (TableData) => {
    // get table column
    const column = Object.keys(TableData[0]);
    //console.log(column);
    return column.map((data) => {
      if (data === "id" || data === "isNew") {
      } else {
        return <th key={data}>{data}</th>;
      }
    });
  };

  // get table row data
  const tdData = (TableData) => {
    // get table column
    const column = Object.keys(TableData[0]);
    // console.log(column);
    return TableData.map((data) => {
      return (
        <tr>
          {column.map((v) => {
            if (v === "id" || v === "isNew") {
            } else {
              return <td>{data[v]}</td>;
            }
          })}
        </tr>
      );
    });
  };

  useEffect(() => {
    const FetchData = async () => {
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber = [];
      //getting section 1b question in single array

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Control Persons"]).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Control Persons"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Control Persons"])[i].answer
        );
        QuestionNumber.push(
          Object.values(props.data.reportData["Control Persons"])[i].qno.substr(
            10,
            Object.values(props.data.reportData["Control Persons"])[i].qno
              .length
          )
        );
      }
      // console.log(questions_array_b);
      SetQuestions(questions_array_b);
      setAnswer(Answer_array_b);
      // console.log(Answer_array_b[0]);
      setQuestionNumber(QuestionNumber);
      //   ThData(Answer_array_b[0]);
      //  tdData(Answer_array_b[0])
    };
    FetchData();

    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <ul
                style={{
                  listStyleType: "square",
                  textAlign: "left",
                  fontSize: "23px",
                }}
              >
                <li>"Control Persons"</li>
              </ul>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                <div>
                  {Questions.map((x, index) => {
                    return (
                      <React.Fragment key={index}>
                        <b>{QuestionNum[index]}</b>
                        {"\u00A0"}
                        {"\u00A0"}
                        {x}
                        <br />
                        {index === 0 || index === 1 ? (
                          <table style={{ border: "1px solid black" }}>
                            <thead >
                              <tr style={{ border: "1px solid black" }}>{ThData(Answer[index])}</tr>
                            </thead>
                            <tbody>{tdData(Answer[index])}</tbody>
                          </table>
                        ) : index === 8 ? (
                          <>
                            {Answer[index].map((ele, index1) => (
                              <React.Fragment key={index1}>
                                {ele.newStreet}&nbsp;
                                {ele.newStreet2}&nbsp;
                                {ele.newCity}&nbsp;
                                {ele.newState}&nbsp;
                                {ele.newCountry.CountryName}&nbsp;
                                {ele.newZip}&nbsp;
                              </React.Fragment>
                            ))}
                          </>
                        ) : index === 11 ? (
                          <h6
                            dangerouslySetInnerHTML={{ __html: Answer[index] }}
                          ></h6>
                        ) : (
                          <h6>{Answer[index]}</h6>
                        )}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default Eighteeth;
