import React from "react";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../styles/Forms_Styles/Form_PeriodicDate.css";
import { useContext } from "react";
import NoteContext from "context/PageContext";

export default function Form_PeriodicDate({ onChange ,getAnswerDisable}) {
  const { Answer } = useContext(NoteContext);
  let data = [];
  try {
    data =
      typeof Answer.UpdatedAnswer === "string" &&
      Answer.UpdatedAnswer.length !== 0
        ? JSON.parse(Answer.UpdatedAnswer)
        : typeof Answer.UpdatedAnswer === "object" &&
          Answer.UpdatedAnswer.length !== 0
        ? Answer.UpdatedAnswer
        : [];
  } catch (error) {
    data = [];
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Start Date"
            value={(() => {
              try {
                const parsedAnswer = JSON.parse(Answer.UpdatedAnswer);
                if (
                  typeof parsedAnswer === "object" &&
                  parsedAnswer !== null &&
                  "startDate" in parsedAnswer
                ) {
                  return dayjs(parsedAnswer.startDate);
                }
              } catch (error) {
                console.error("Error parsing JSON:", error,Answer.UpdatedAnswer);
              }
              return null;
            })()}
            onChange={(newValue) =>
              onChange({ startDate: newValue, endDate: data.endDate })
            }
            format="DD/MM/YYYY"
            views={["day", "month", "year"]}
            disabled={getAnswerDisable}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="End Date"
            value={(() => {
              try {
                const parsedAnswer = JSON.parse(Answer.UpdatedAnswer);
                if (
                  typeof parsedAnswer === "object" &&
                  parsedAnswer !== null &&
                  "endDate" in parsedAnswer
                ) {
                  return dayjs(parsedAnswer.endDate);
                }
              } catch (error) {
                console.error("Error parsing JSON:", error);
              }
              return null;
            })()}
            onChange={(newValue) =>
              onChange({ startDate: data.startDate, endDate: newValue })
            }
            minDate={(() => {
              try {
                const parsedAnswer = JSON.parse(Answer.UpdatedAnswer);
                if (
                  typeof parsedAnswer === "object" &&
                  parsedAnswer !== null &&
                  "startDate" in parsedAnswer
                ) {
                  return dayjs(parsedAnswer.startDate);
                }
              } catch (error) {
                console.error("Error parsing JSON:", error);
              }
              return null;
            })()}
            format="DD/MM/YYYY"
            views={["day", "month", "year"]}
            disabled={getAnswerDisable}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
