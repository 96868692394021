import React, { useState } from "react";
import "../ESG/styles/Tree/Tree.css"
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Icon from '@mui/material/Icon';
import Collapse from '@mui/material/Collapse';
import { Box } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';


function findPath(obj, target) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === target) {
        return [key];  // found the target, return its name
      } else if (typeof obj[key] === 'object') {
        var result = findPath(obj[key], target);  // recursive call
        if (result) {
          return [key].concat(result);  // prepend current key to result
        }
      }
    }
  }
  return null;  // target not found
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CheckboxDropdownList = (props) => {
 
  const [ExpandedNodes, setExpandedNodes] = useState([]);
  const arrdata = Object.entries(props.data);
  const data = props.data && arrdata[0][1].value;
  const [FormSection, setFormSection] = useState([]);


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormSection(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


//on clicking a node which has childrens
  const onNodeToggle = (node)=>{
    var path = findPath(data, node);
    setExpandedNodes((prevExpandedNodes) => {
      if (prevExpandedNodes.includes(node)) {
        return prevExpandedNodes.filter((i) => i !== node);
      } else {
        return path.filter((value, index) => !(index % 2));
      }
    });
  }

  // Function to handle changes to selected items
  const clickHandler = (id,haschildren) => {
    haschildren && onNodeToggle(id);
    props.selectHandler(id);
    props.handleSelect(id);
  };

  // console.log(props.selected);

  // Recursive function to render nested lists
  const renderNestedList = (listData) => {
    return (
      <List className="list" >

        {Object.entries(listData).map((item) => {
          const hasSubList = Object.keys(item[1].value).length !== 0;
          const isSelected = ExpandedNodes.includes(item[1].key.id);
          return (
            <React.Fragment >
               
              <ListItemButton disableTouchRipple divider
                  onClick={() => clickHandler(item[1].key.id,hasSubList)} 
                  selected={props.selected === item[1].key.id}
                  className={`ListItem ${ExpandedNodes.includes(item[1].key.id)?'ExpandedListItem':''}`}
               >
                <Checkbox  />
                      {
                        item[1].key.properties.Icon ?
                        <Icon className="iconRoot" fontSize="small" >
                          <img className="listCategoryIcon" src={item[1].key.properties.Icon[0].value} alt="listCategoryIcon" />
                        </Icon> :
                        <CircleIcon className="listItemIcon" />
                      }

                      <ListItemText  className="listItemLabel" primary={item[1].key.properties.name[0].value}/>
                      
                      {
                       hasSubList ? ExpandedNodes.includes(item[1].key.id) ? 
                      <KeyboardArrowDownIcon className="ExpandCollapseIcon" fontSize="small"  /> : 
                      <KeyboardArrowRightIcon className="ExpandCollapseIcon" fontSize="small"/> : null
                      }

              </ListItemButton>

              {hasSubList && (
                <Collapse component="li" in={isSelected} timeout="auto" unmountOnExit
                  className={`NestedList ${item[1].key.id===ExpandedNodes[ExpandedNodes.length-1]?'Expanded':''}`} >
                        {renderNestedList(item[1].value)}
                </Collapse>
              )}

          </React.Fragment>
          );
        })}</List>
    );
  };



  return (
      <Box className="ListContainer" sx={{margin:10}}>
           <FormControl sx={{ m: 1, width: 300 }}>
           <InputLabel id="CheckBoxDropdownListLabel">Select</InputLabel>
           <Select
            labelId="CheckBoxDropdownListLabel"
            id="CheckBoxDropdownListForm"
            multiple
            value={FormSection}
            onChange={handleChange}
            input={<OutlinedInput label="Select" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
        {data && renderNestedList(data)}
        </Select>
        </FormControl>
      </Box>
  );
};

export default CheckboxDropdownList;
