import React, { useState,useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { Box } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import "../styles/Forms_Styles/Form_CheckBox.css";
import { useContext } from "react";
 
import NoteContext from "context/PageContext";
function CheckBox({FieldValues,onChange,getAnswerDisable}) {
  const {Answer}=useContext(NoteContext);
  const arr = FieldValues.split(",").map(value => value.trim());
  let Anss=[];
  try {
     Anss=  typeof Answer.UpdatedAnswer==='object'? Answer.UpdatedAnswer :typeof Answer.UpdatedAnswer==='string' ? JSON.parse(Answer.UpdatedAnswer):[];
  } catch (error) {
    Anss=[];
  }
 
  const [other, setOther] = React.useState(false);
  // const [text, settext] = React.useState(Anss[0]);
  // Anss[0]=text
 
  const HandleChange = (event) => {
    const index = Anss.indexOf(event.target.value)
   
     if(!Anss.includes('Other')){
    setOther(true);
 
   }else{
    setOther(false);
   }
 
    if (index === -1) {
      onChange([...Anss, event.target.value]);
   
   
    } else {
     
      onChange(Anss.filter((Anss) => Anss !== event.target.value));
    }
  }
 
 
  return (
    <Box
    >
      {arr?.map((item, index) => (
        <FormGroup row key={index}>
          <FormControlLabel
            control={
              <Checkbox checked={Anss.includes(item)} color="success" onChange={HandleChange} disabled={getAnswerDisable} />
            }
            value={item}
            label={item}
            labelPlacement="end"
          />
        </FormGroup>
      ))}
   {other===true ?(
      <Box >
{/*
      <TextField  
        value={text}
        disabled = {isChecked}
        onChange={(e) => {
        settext(e.target.value);
        }}
        id="standard-basic"
        label="Enter Other"
        variant="standard" /> */}
       
      </Box>
      ):''}
    </Box>
 
  );
}
export default CheckBox;