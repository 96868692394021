import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { useContext } from 'react';
import NoteContext from 'context/PageContext';
import { getUserRole } from 'Compliance/services/CaseFetch';
const RoleSwitch = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedOption, setSelectedOption] = useState({ id: '', displayName: '' });
    const ContextData = useContext(NoteContext);
    const [Reload, setReload] = useState(true);
    const [caseGroupsData, setCaseGroupsData] = useState([]);

    useEffect(() => {
        fetchUserRole()
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Reload]);
   const fetchUserRole=async()=>{
    var response=await getUserRole()
    const rolesDataString = response?.data?.values;
    if (rolesDataString) {
        try {
            
            const groups = rolesDataString;

            if (groups.length > 0) {
                setCaseGroupsData(groups);
                if(ContextData.CaseRole===''||ContextData.CaseRole===undefined){
                setSelectedOption(groups[0]);
                ContextData.setCaseRole(groups[0]);
                }
                else{
               setSelectedOption(ContextData?.CaseRole);
                ContextData.setCaseRole(ContextData?.CaseRole); 
                }
            }
        } catch (error) {
            console.error("Error parsing JSON from local storage:", error);
        }
    }
   }
    const handleOptionChange = (event) => {
        const selectedId = event.target.value;
        const selectedGroup = caseGroupsData.find((group) => group.id === selectedId);
        setSelectedOption(selectedGroup);
        ContextData.setCaseRole(selectedGroup);
        handleCaseGroupSelect(selectedGroup);
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setReload(false);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleCaseGroupSelect = (selectedGroup) => {
        localStorage.setItem("Selected_Group", JSON.stringify(selectedGroup));
    };

    const shouldShowDropdown = caseGroupsData.length > 0;

    return (
        <div>
            <Button
                aria-controls="directory-menu"
                aria-haspopup="true"
                onClick={handlePopoverOpen}
                variant="text"
                startIcon={<SwitchAccountIcon />}
                disabled={!shouldShowDropdown}
            >
                Role
            </Button>
            <Popover
                id="directory-menu"
                open={open && shouldShowDropdown}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {shouldShowDropdown && (
                    <Select
                        value={selectedOption.id}
                        onChange={handleOptionChange}
                        fullWidth
                        label="Case Groups"
                    >
                        {caseGroupsData.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                                {group.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </Popover>
        </div>
    );
};

export default RoleSwitch;
