/* eslint-disable array-callback-return */
import React from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import "../ESG/styles/Home/home.css"; // Add your custom CSS here

const BorderLinearProgress = ({ value }) => (
  <LinearProgress className="progress" variant="determinate" value={value} />
);

export default function DisclosureInfoList({ data,SearchData }) {
  const navigate = useNavigate();
   
  return (
    <TableContainer className="Table-DisclosureInfoList" component={Box} >
      <Table stickyHeader aria-label="sticky table" >
        <TableHead>
          <TableRow>
            <TableCell className="table-header">Document</TableCell>
            <TableCell className="table-header">Status</TableCell>
            <TableCell className="table-header">Start Date</TableCell>
            <TableCell className="table-header">End Date</TableCell>
            <TableCell className="table-header">Deadline</TableCell>
            <TableCell className="table-header">Environment</TableCell>
            <TableCell className="table-header">Social</TableCell>
            <TableCell className="table-header">Governance</TableCell>
            <TableCell className="table-header">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.filter((val) => {
          if (SearchData=== "") {
            return val;
          } else if (
            val.filing
              .toLowerCase()
              .includes(SearchData.trim().toLowerCase())
          ) {
            return val;
          }
        })?.map((item, i) => (
            <TableRow key={i}>
              <TableCell className="table-cell">
                <Grid container alignItems="center">
                  <Grid item>
                    <DescriptionIcon className="fileIcon" />               
                  </Grid>
                  <Grid item>
                  <strong>{item.filing}</strong>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className="table-cell">
                  Status: {item.status}
                  <BorderLinearProgress value={Math.round(item.progress)} />
              </TableCell>
              <TableCell className="table-cell">{item.start}</TableCell>
              <TableCell className="table-cell">{item.end}</TableCell>
              <TableCell className="table-cell">{item.deadline}</TableCell>
              <TableCell className="table-cell">{ (Math.round(item.environmental * 100) / 100).toFixed(2) + "%"}</TableCell>
              <TableCell className="table-cell">{(Math.round(item.social * 100) / 100).toFixed(2) + "%"}</TableCell>
              <TableCell className="table-cell">{(Math.round(item.governance * 100) / 100).toFixed(2) +"%"}</TableCell>
              <TableCell className="table-cell">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    navigate("/ViewReport", {
                      state: { disclosure: item.filing, startdate: item.start },
                    })
                  }
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
