import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function TenthPage(props) {
  const [Questionset1, SetQuestionset1] = useState();
  const [Questionset2, SetQuestionset2] = useState();
  const [Questionset3, SetQuestionset3] = useState();
  const [Isloading, setIsloading] = useState(false);
  const [QuestionNum2, setQuestionNumber2] = useState();
  const [QuestionNum3, setQuestionNumber3] = useState();
  //const [Question1,setQuestion1]=useState();
  const [Answerset1, setAnswerset1] = useState();
  const [Answerset2, setAnswerset2] = useState();
  const [Answerset3, setAnswerset3] = useState();
  const [QuestionNum1, setQuestionNumber1] = useState();

  useEffect(() => {
    const FetchData = () => {
      //getting section 1b question in single array
      const questions_array_a = [];
      const Answer_array_a = [];
      const QuestionNumber1 = [];

      for (
        let i = 0;
        i < Object.keys(props.data.reportData["Website Addresses"]).length;
        i++
      ) {
        questions_array_a.push(
          Object.keys(props.data.reportData["Website Addresses"])[i]
        );
        Answer_array_a.push(
          Object.values(props.data.reportData["Website Addresses"])[i].answer
        );
        QuestionNumber1.push(
          Object.values(props.data.reportData["Website Addresses"])[
            i
          ].qno.substr(
            8,
            Object.values(props.data.reportData["Website Addresses"])[i].qno
              .length
          )
        );
      }
      // console.log(questions_array_b);
      SetQuestionset1(questions_array_a);
      setAnswerset1(Answer_array_a);
      //console.log(Answer_array_a);
      setQuestionNumber1(QuestionNumber1);
    };
    const FetchData1 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber2 = [];

      for (
        let i = 0;
        i <
        Object.keys(props.data.reportData["Location of Books and Records"])
          .length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(props.data.reportData["Location of Books and Records"])[i]
        );
        Answer_array_b.push(
          Object.values(props.data.reportData["Location of Books and Records"])[
            i
          ].answer
        );
        QuestionNumber2.push(
          Object.values(props.data.reportData["Location of Books and Records"])[
            i
          ].qno.substr(
            8,
            Object.values(
              props.data.reportData["Location of Books and Records"]
            )[i].qno.length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset2(questions_array_b);
      setAnswerset2(Answer_array_b);
      //console.log(Answer_array_b);
      setQuestionNumber2(QuestionNumber2);
    };
    const FetchData2 = () => {
      //getting section 1b question in single array
      const questions_array_b = [];
      const Answer_array_b = [];
      const QuestionNumber3 = [];

      for (
        let i = 0;
        i <
        Object.keys(
          props.data.reportData[
            "Registration with Foreign Financial Regulatory Authorities"
          ]
        ).length;
        i++
      ) {
        questions_array_b.push(
          Object.keys(
            props.data.reportData[
              "Registration with Foreign Financial Regulatory Authorities"
            ]
          )[i]
        );
        Answer_array_b.push(
          Object.values(
            props.data.reportData[
              "Registration with Foreign Financial Regulatory Authorities"
            ]
          )[i].answer
        );
        QuestionNumber3.push(
          Object.values(
            props.data.reportData[
              "Registration with Foreign Financial Regulatory Authorities"
            ]
          )[i].qno.substr(
            8,
            Object.values(
              props.data.reportData[
                "Registration with Foreign Financial Regulatory Authorities"
              ]
            )[i].qno.length
          )
        );
      }

      // console.log(questions_array_b);
      SetQuestionset3(questions_array_b);
      setAnswerset3(Answer_array_b);
    //  console.log(Answer_array_b)
      setQuestionNumber3(QuestionNumber3);
    };

    FetchData();
    FetchData1();
    FetchData2();

    setIsloading(true);
  }, [props]);

  return (
    <div>
      {Isloading && (
        <Grid container spacing={1}>
          <Grid container>
            <Box
              sx={{
                backgroundColor: "lightgray",
                width: "15%",
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  bgcolor: "lightgray",
                  position: "sticky",
                  top: "0",
                  padding: "2px",
                  height: "100",
                }}
              >
                <ul
                  style={{
                    listStyleType: "square",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  <li>Website Addresses</li>
                  <li>Books and Records</li>
                  <li>
                    Registration with Foreign Financial Regulatory Authorities
                  </li>
                </ul>
              </Grid>
            </Box>

            <Grid item xs={12} md={10} container paddingLeft={1} paddingTop={1}>
              <Box
                style={{
                  fontSize: "1rem",
                }}
              >
                {Questionset1.map((x, index) => {
                  return (
                    <React.Fragment key={`question1-${index}`}>
                      <b>{QuestionNum1[index]}</b>
                      {"\u00A0"}
                      {"\u00A0"}
                      {x}
                      <br />
                      <ol>
                        {Answerset1[index].map((items, subIndex) => {
                          return (
                            <li key={`answer1-${index}-${subIndex}`}>
                              {items}
                            </li>
                          );
                        })}
                      </ol>
                    </React.Fragment>
                  );
                })}

                {Questionset2.map((x, index) => {
                  return (
                    <React.Fragment key={`question2-${index}`}>
                      <b>{QuestionNum2[index]}</b>
                      {"\u00A0"}
                      {"\u00A0"}
                      {x}
                      <br />

                      {index === 0 ? (
                        <React.Fragment>
                          {Answerset2[index].map((itimes) => {
                            return itimes;
                          })}
                        </React.Fragment>
                      ) : index === 1 ? (
                        <>
                          {Answerset2[index].map((ele, index1) => (
                            <React.Fragment key={index1}>
                              {ele.newStreet}&nbsp;
                              {ele.newStreet2}&nbsp;
                              {ele.newCity}&nbsp;
                              {ele.newState}&nbsp;
                              {ele.newCountry.CountryName}&nbsp;
                              {ele.newZip}&nbsp;
                            </React.Fragment>
                          ))}
                        </>
                      ) : index === 3 ? (
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: Answerset2[index],
                          }}
                        ></h6>
                      ) : (
                        <React.Fragment>{Answerset2[index]}</React.Fragment>
                      )}

                      <br />
                    </React.Fragment>
                  );
                })}

                {Questionset3.map((x, index) => {
                  return (
                    <React.Fragment key={`question3-${index}`}>
                      <b>{QuestionNum3[index]}</b>
                      {"\u00A0"}
                      {"\u00A0"}
                      {x}
                      <br />
                      {/* {Answerset2[index]} */}
                      {index === 0 ? (
                        <React.Fragment>
                          {Answerset3[index].map((n) => {
                            return n;
                          })}
                        </React.Fragment>
                      ) : index === 2 ? (
                        <React.Fragment>
                          Country Name : {Object.values(Answerset3[index])}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>{Answerset3[index]}</React.Fragment>
                      )}
                      <br />
                    </React.Fragment>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default TenthPage;
