import React, { useState, useEffect } from "react";
import {
  Popover,
  Typography,
  FormControlLabel,
  Button,
  TextField,
  Grid,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import "../styles/Case_Managment/Case_Filter.css";
import Switch from '@mui/material/Switch';
import Chip from "@mui/material/Chip";
import cross from './../../images/cross.png';

import { useContext } from "react";
import NoteContext from "context/PageContext";
import { getCheckList,getAllCaseCreator } from "Compliance/services/CaseFetch";
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';

const ChildFilterPopover = ({
  anchorEl,
  onApplyFilter,
  onClose,
  //parentid
}) => {
  const ContextData = useContext(NoteContext);
  const isOpen = Boolean(anchorEl);
  const [checkTypes, setCheckType] = useState([]);
  const [caseStatus, setCaseStatus] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [creator,setCreator]=useState([]);
  const [creatorinput,setCreatorinput]=useState(null)
  const [checkedCaseTypes, setcheckedCaseTypes] = useState([]);
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const[caseCreators,setCaseCreators]= useState();
  const handleChange = (event) => {
    setcheckedCaseTypes(
      [...checkedCaseTypes,
      event.target.value]
    );
  };

  const handleResetFilter = () => {
   setCaseStatus("")
   setFromDate("");
   setToDate("");
   setCreator([]);
  setCreatorinput()
  setcheckedCaseTypes([]);
  setSelectedOptions([])
  }

  useEffect(() => {
    const fetchCheckTypes = async () => {
      try {
        const checktype = await getCheckList(ContextData.selectedCaseType);
        setCheckType(checktype.data.values);
      } catch (error) {
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        console.error(error);
      }
    };
    const fetchCaseCreators = async () => {
      try {
        const caseCreator = await getAllCaseCreator();
        setCaseCreators(caseCreator.data.values);
      } catch (error) {
        ContextData.setUserLogInError({
          message: error.message,
          Errors: true
        });
        console.error(error);
      }
    };
    
    fetchCheckTypes();
    fetchCaseCreators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




    const handleApplyFilter = async () => {
    try {
      const filterOptions = {
        //parentid:parentid,
        caseType:checkedCaseTypes.length>0?checkedCaseTypes:"",
        caseStatus:caseStatus,
        // priority,
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
        creator:selectedOptions.length>0?selectedOptions:""
      };
      onApplyFilter(filterOptions);
      onClose();
      handleResetFilter();
    } catch (error) {
      console.error("Error applying filter:", error);
    }
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${year}-${month}-${day}`;
  };
  const handleInputChange = (event) => {
    setCreatorinput(event.target.value);
  };
  const handleAddCreator = () => {
    if (creatorinput && creatorinput.trim() !== "") {
      setCreator([...creator, creatorinput.trim()]);
    }
  };
  //function Delete creatoe
  const handleDeleteCreator= (deluser) => {
    setCreator(creator.filter((user) => user !== deluser));
  };
  const CancelClose=()=>{
    onClose()
    handleResetFilter()
  }
  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);
    setToDate('');
    setToDateDisabled(false);
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCreatorChange = (event) => {
    setSelectedOptions(event.target.value);
  };
  return (
    <Grid style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'auto !important'}}>
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorReference="anchorPosition"
        anchorPosition={{ top: '50%', left: '50%' }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
      style={{width:'50%',display:'flex',justifyContent:'center',alignItems:'center',margin:'auto'}}
    >
      <div style={{ padding: 16, minWidth: 300 }}>
        <Grid style={{display:'flex',justifyContent:'space-between'}}>
          <Grid>
        <Typography className="filterheading">
          Filter
        </Typography>
        <Typography className="filtersubhead">
        Select The Filter You Want To Apply.
        </Typography>
        </Grid>
        <Button id="post-button" onClick={CancelClose}><img src={cross}/></Button>
        </Grid>
        <FormControlLabel  labelPlacement="start" label="AI Search" control={<Switch />} style={{ margin: '0px'}}  />
        <Grid style={{display:'flex'}} className="filtermarginbottom">
        <FormControl fullWidth>
      <InputLabel id="multi-select-dropdown-label">Select Creators</InputLabel>
      <Select
        labelId="multi-select-dropdown-label"
        id="multi-select-dropdown"
        multiple
        value={selectedOptions}
        onChange={handleCreatorChange}
        renderValue={(selected) => {
          const selectedCreators = selected.map(id => {
            const creator = caseCreators.find(creator => creator.creatorId === id);
            return creator ? creator.creator : '';
          });
          return selectedCreators.join(', ');
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, 
            },
          },
        }}
        style={{ marginTop: '8px' }}
      >
        {caseCreators?.map((option) => (
          <MenuItem key={option.creatorId} value={option.creatorId}>
            <Checkbox checked={selectedOptions.indexOf(option.creatorId) > -1} />
            {option.creator}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
       
        </Grid>
       
                    <Grid className="filtermarginbottom">
                      <InputLabel className="filterlabel">Status</InputLabel>
                  <TextField
                  size="small"
                  select
                  fullWidth
                  onChange={(e) => {setCaseStatus(e.target.value)}}
                  >
 <MenuItem value="open">Open</MenuItem>
 <MenuItem value="closed">Close</MenuItem>
                  </TextField>
                  </Grid>
        

       
      
        <Grid container spacing={2} className="filtermarginbottom">
          <Grid item xs={6}>
            <InputLabel className="filterlabel">From Date</InputLabel>
            <TextField
              //label="From Date"
              size="small"
              type="date"
              value={fromDate}
              onChange={(e) => handleFromDateChange(e)}
              fullWidth
              //margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="filterlabel">To Date</InputLabel>
            <TextField
             // label="To Date"
             size="small"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              fullWidth
             // margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={toDateDisabled}
              inputProps={{
                min: fromDate, 
              }}
            />
          </Grid>
          <Grid style={{marginLeft:'15px',marginTop: '13px'}}>
          <Typography className="filtercasetype">Check Type</Typography>
          <Typography className="filtersubhead">Please Select Your Interest(s)</Typography>
          </Grid>
          <Grid container spacing={2}>
      {checkTypes.map((checkbox) => (
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '-15px' }} key={checkbox}>
          <Checkbox
            // checked={checkedCaseTypes[checkbox.title] || false}
            onChange={handleChange}
            name={checkbox}
            style={{ marginRight: '-8px' }} 
            value={checkbox}
          />
          <Typography variant="body1" style={{paddingTop: '8px'}}>{checkbox}</Typography>
        </Grid>
      ))}
    </Grid>
        </Grid>
        <Grid
        className="btncontainer"
        >
          <Button
            variant="outlined"
            onClick={handleApplyFilter}
            style={{ marginRight: 8 }}
            className="applybtn"
          >
            Apply
          </Button>
          <Button
            //variant="contained"
            onClick={CancelClose}
           className="cancelbtn"
          >
            Cancel
          </Button>
        </Grid>
      </div>
    </Popover>
    </Grid>
  );
};

export default ChildFilterPopover;
