import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import "../styles/Real_Time_Data/Real_Time_Data.css";
import withSnackbar from "../../components/Alert/withSnackbar"; // Adjust the path
import { Getbusinessareas } from "../../ESG/services/Fetch"; // Adjust the import path
import { useContext } from "react";
import NoteContext from "context/PageContext";
function getObjectKeysAndValues(obj, prefix = "") {
  const keysAndValues = [];

  for (const key in obj) {
    if (key === "details") {
      // Handle 'details' property differently
      let value;
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          value = {};
        } else if (obj[key] === "{}" && obj[key].length > 1) {
          value = JSON.parse(obj[key]);
        } else {
          value = JSON.parse(obj[key].length > 0 ? obj[key] : obj[key]);
        }
        const newPrefix = prefix ? `${key}` : key;

        if (typeof value === "object") {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    } else {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const newPrefix = prefix ? `${prefix}->${key}` : key;
        if (typeof value === "object") {
          keysAndValues.push(...getObjectKeysAndValues(value, newPrefix));
        } else {
          keysAndValues.push({ key: newPrefix, value });
        }
      }
    }
  }
  return keysAndValues;
}

function ObjectViewer(props) {
  const { data } = props;

  const keysAndValues = getObjectKeysAndValues(data);

  return (
    <ul>
      {keysAndValues.map(({ key, value }) => (
        <li key={key}>
          <strong>{key}: </strong>
          {value}
        </li>
      ))}
    </ul>
  );
}

const columns = [
  { id: "Area", label: "Area", minWidth: "50px", align: "left" },
  { id: "Catagary", label: "Catagary", minWidth: "50px", align: "left" },
  { id: "Code", label: "Code", minWidth: "50px", align: "left" },
  { id: "Description", label: "Description", minWidth: "50px", align: "left" },
];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function BusinessAreas({ Search, Refresh, showSnackbar }) {
  const ContextData = useContext(NoteContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [TotalPage, setTotalPage] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [tempData, setTempData] = useState([]);
  const [BusinessAreas, Setbusinessareas] = useState([]);
  const [UpdateCompanyDetails, setUpdateCompanyDetails] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const GetDetails = async () => {
    try {
      showSnackbar("Loading data, please wait...", "info");
      const data = await Getbusinessareas(page, rowsPerPage);
      setTempData(data?.businessareas);
      if (BusinessAreas?.length > 0) {
        Setbusinessareas((prevBusinessAreas) => [
          ...prevBusinessAreas,
          ...tempData,
        ]);
      } else {
        Setbusinessareas(data?.businessareas);
      }
      setTotalPage(data?.rows);
      showSnackbar("Data loaded successfully", "success");
    } catch (error) {
      console.error("Error loading data:", error);
      ContextData.setUserLogInError({
        message: error.message,
        Errors: true
      });
      showSnackbar("Error loading data. Please try again.", "error");
    }
  };

  useEffect(() => {
    GetDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, Refresh]);

  // handle Company Details in Card
  const handleUpdate = (row, i) => {
    setUpdateCompanyDetails(row);
    setSelectedRowIndex(i);
  };

  // Pagination for table handle change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Pagination for table handle Change Rows Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={8}>
        <TableContainer className="TableContainer">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="Table_Head"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {BusinessAreas &&
                BusinessAreas.filter((val) => {
                  if (Search === "" || Search === null) {
                    return val;
                  } else if (
                    val.area
                      .toLowerCase()
                      .includes(Search.trim().toLowerCase())
                  ) {
                    return val;
                  }
                })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <TableRow
                      style={{
                        backgroundColor: i === selectedRowIndex ? "#c4c4ff" : "",
                      }}
                      onClick={() => handleUpdate(row, i)}
                      key={i}
                      className={i % 2 === 0 ? "TableRowOdd" : "TableRowEven"}
                    >
                      <TableCell component="th" scope="row">
                        {row?.area ?? "undefined"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.category ?? "undefined"}
                      </TableCell>
                      <TableCell align="left">{row?.code ?? "undefined"}</TableCell>
                      <TableCell align="left">
                        {row?.description ?? "undefined"}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={Math.floor(TotalPage)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <Grid item xs={4}>
        {UpdateCompanyDetails && (
          <>
            <Typography className="More_Details">
              {UpdateCompanyDetails?.area}
            </Typography>
            <Card className="CompanyDetails_Card">
              <CardContent className="CardContent">
                <ObjectViewer data={UpdateCompanyDetails} />
              </CardContent>
            </Card>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default withSnackbar(BusinessAreas, "BusinessAreas paage", "success");
