import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Avatara from "../images/Avatar.jpg";
import { getUserProfile } from "../ESG/services/Fetch";
import "../ESG/styles/MyProfile/MyProfile.css";
import withSnackbar from "../components/Alert/withSnackbar";

function MyProfile({ showSnackbar }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDashboardData = async () => {
      // showSnackbar("Data loading please wait...", "info");
      try {
        const response = await getUserProfile();
        setAccountInfo(response.data);
        setLoading(false);
        showSnackbar("Profile data loaded Successfully", "success");
      } catch (error) {
        // showSnackbar("Unable to fetch data, something went wrong", "error");
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };
    getDashboardData();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="MainBox" style={{ width: "70%", height: "50%" }}>
      <Button
        aria-describedby={id}
        variant="text"
        className="MyProfile_Button"
        onClick={handleClick}
        startIcon={<ManageAccountsIcon />}
      >
        My profile
      </Button>
      <Popover
        className="Popover"
        style={{ padding: "50px" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 100, left: 400 }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: 600,
            height: 500,
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          component="form"
          autoComplete="off"
          className="paper"
        >
          {loading ? (
            <Typography>Loading...</Typography>
          ) : accountInfo?.endUser ? (
            <>
              <Stack direction="row" spacing={2}>
                <Avatar className="Avatar" src={Avatara} />
                <Typography className="Page_Title" variant="h6">
                  Your Account Information.
                </Typography>
              </Stack>
              <TextField
                disabled={true}
                required
                id="Name"
                label="Name"
                placeholder="Enter..."
                variant="outlined"
                value={accountInfo?.endUser?.displayName ?? "Undefined"}
              />
              <TextField
                disabled={true}
                required
                id="Email"
                variant="outlined"
                placeholder="Enter..."
                label="Email"
                value={accountInfo?.endUser?.mail ?? "Undefined"}
              />
              <TextField
                disabled={true}
                required
                id="Job Title"
                variant="outlined"
                placeholder="Enter..."
                label="Job Title"
                value={accountInfo?.endUser?.jobTitle ?? "Undefined"}
              />
              <TextField
                disabled={true}
                required
                id="preferredLanguage"
                placeholder="Ex: English"
                label="Preferred Language"
                variant="outlined"
                value={accountInfo?.endUser?.preferredLanguage ?? "Undefined"}
              />
              <TextField
                disabled={true}
                required
                id="mobilePhone"
                label="Mobile Phone"
                variant="outlined"
                value={accountInfo?.endUser?.mobilePhone ?? "Undefined"}
              />
              <TextField
                disabled={true}
                id="officeLocation"
                placeholder="Ex: London, United Kingdom"
                label="Office Location"
                variant="outlined"
                value={accountInfo?.endUser?.officeLocation ?? "Undefined"}
              />
            </>
          ) : (
            <Typography>No user profile data found.</Typography>
          )}
        </Box>
      </Popover>
    </div>
  );
}

export default withSnackbar(MyProfile, "MyProfile", "success");
